import { useContract } from "../../Contracts/ListContractProvider";

function CategoryHeader() {
  const context = useContract();

  return (
    context && (
      <>
        {/* <div className=" overflow-scroll   pt-4 nestedtab Category-width">
          <ul
            className="d-flex   list-unstyled"
            style={{ width: "fit-content" }}
          >
            <li>
              <h6
                className="contract-title pb-1 m-0"
                onClick={() => navigate(routes.contracts)}
              >
                
              </h6>
            </li>
          </ul>
        </div> */}
        <div className="d-flex mt-4 mb-3 mx-2 ">
          <h6 className="contract-title pb-1 m-0">Contracts</h6>
        </div>
      </>
    )
  );
}

export default CategoryHeader;
