import Avatar from "react-avatar";
import { Badge } from "react-bootstrap";

export const columns = [
  {
    name: "Avatar",
    minWidth: "100px",
    cell: (row: any) => {
      return (
        <Avatar
          name={row?.first_name}
          src={row?.avatar}
          size="50"
          className="avatar_css_members"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={row?.email?.charAt(0)}
        />
      );
    },
    grow: 0,
    reorder: true,
    sortable: true,
  },
  {
    name: "Name",
    minWidth: "150px",
    cell: (row: any) => {
      return (
        <span>
          {[row.first_name, row.last_name]
            .filter((str) => str && str.length > 1)
            .join(" ")}
        </span>
      );
    },
    grow: 0,
    reorder: true,
    sortable: true,
  },
  {
    name: "Email address",
    width: "250px",
    cell: (row: any) => {
      return <span>{row?.email}</span>;
    },
    grow: 0,
    reorder: true,
    sortable: true,
  },
  {
    name: "Contact number",
    width: "250px",
    cell: (row: any) => {
      return <span>{row?.contact_number ?? "-"}</span>;
    },
    grow: 0,
    reorder: true,
    sortable: false,
  },
  {
    name: "Role",
    width: "140px",
    cell: (row: any) => {
      return <span>{row?.roles?.join(", ") ?? "-"}</span>;
    },
    grow: 0,
    reorder: true,
    sortable: true,
  },
  {
    name: "Department",
    width: "140px",
    cell: (row: any) => {
      return <span>{row?.department ?? "-"}</span>;
    },
    grow: 0,
    reorder: true,
    sortable: true,
  },
  {
    name: "Status",
    width: "190px",
    grow: 0,
    reorder: true,
    sortable: true,
    cell: (row: any) => {
      return (
        <Badge
          className={
            row?.is_active === "false" ? "draft-badge" : "complete-badge"
          }
          bg="success"
        >
          {row?.emailVerified === "false" && row?.is_active === "false"
            ? "INVITED"
            : row?.emailVerified === "true" && row?.is_active === "false"
            ? "BLOCKED"
            : "ACTIVE"}
        </Badge>
      );
    },
  },
  // {
  //   name: "Status",
  //   width: "140px",
  //   cell: (row: any) => {
  //     return (
  //       <span>
  //         {row?.emailVerified === "false" && row?.is_active === "false"
  //           ? "INVITED"
  //           : row?.emailVerified === "true" && row?.is_active === "false"
  //           ? "BLOCKED"
  //           : "ACTIVE"}
  //       </span>
  //     );
  //   },
  //   grow: 0,
  //   reorder: true,
  //   sortable: true,
  // },
  // {
  //   name: "Last login at",
  //   width: "210px",
  //   cell: (row: any) => (
  //     <span>{moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A")}</span>
  //   ),
  //   grow: 0,
  //   reorder: true,
  //   sortable: true,
  // },
];
