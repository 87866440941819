import { ProgressBar } from "react-bootstrap";
import { Contract } from "../../../lib/Contract";
import Avatar from "react-avatar";
import { Fragment, useEffect, useState } from "react";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { Collaborator } from "../../../lib/Collaborator";
import { StatusEnum } from "../../../interfaces/StatusEnum";
import { updateText } from "../../../utils";

function RecentContractList({ contract }: { contract: Contract }) {
  const loggedInContext = useAuthentication();

  const [approvalUserDetail, setApprovalUserDetail]: [
    Contract | undefined,
    Function
  ] = useState();
  const [collaboratorsDetail, setcollaboratorsDetail]: [
    Collaborator | undefined,
    Function
  ] = useState();

  useEffect(() => {
    if (contract?.approvers?.length && loggedInContext?.currentUser) {
      let users = contract?.approvers?.find((user: Contract) => {
        return user?.user?.sub === loggedInContext?.currentUser?.sub;
      });

      let collab = contract?.collaborators?.find((coll: Collaborator) => {
        return coll?.user?.sub == loggedInContext?.currentUser?.sub;
      });

      setcollaboratorsDetail(collab);
      setApprovalUserDetail(users);
    }
  }, [contract?.approvers?.length, loggedInContext?.currentUser]);

  return (
    <>
      <div className=" py-1 card-width">
        <div className="bg-contract contract-division p-4 br-4">
          <div>
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={contract?.name}
            >
              <span className="card-contract-name">
                {contract?.formattedName(15)}
              </span>
            </div>

            <div className=" py-2 d-flex justify-content-between align-items-center">
              <div className="contract-team-name m-0">
                <span className="pro-names p-0">{contract?.department}</span>
              </div>

              <div className="  ">
                <span className="profileAvatar mx-1">
                  {contract?.collaborators &&
                    !!contract?.collaborators?.length && (
                      <>
                        {contract?.collaborators?.map(
                          (collaboratorsName: Collaborator, index: number) => {
                            return (
                              index <= 1 && (
                                <Fragment key={index}>
                                  <Avatar
                                    name={collaboratorsName?.user?.name?.charAt(
                                      0
                                    )}
                                    className="profile-avatars"
                                    size="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={updateText(
                                      `${collaboratorsName?.user?.name}${" "}${
                                        collaboratorsName?.user?.last_name
                                      }`
                                    )}
                                  />
                                </Fragment>
                              )
                            );
                          }
                        )}
                      </>
                    )}
                  &nbsp;&nbsp;
                  <span className="profileAvatar">
                    {contract?.collaborators &&
                      contract?.collaborators?.length > 2 && (
                        <>
                          {"+"}
                          {contract?.collaborators?.length - 2}
                        </>
                      )}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className=" card-signature mt-2 d-flex justify-content-start">
            {contract?.status === StatusEnum?.pending_negotitation_approval ? (
              "Pending negotiation approval"
            ) : contract?.status === "EXPIRED" ? (
              "Expired"
            ) : contract?.status === StatusEnum?.stamped &&
              loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
              "Stamped"
            ) : contract?.status === StatusEnum?.stamped &&
              loggedInContext?.currentUser?.sub === contract?.owner?.sub ? (
              "Sign initiated"
            ) : contract?.status === StatusEnum?.approved &&
              contract?.stamp_required === false &&
              loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
              "Sign required"
            ) : contract?.status === StatusEnum?.published ? (
              "Published"
            ) : contract?.status === "COMPLETED" ? (
              "Signed"
            ) : contract?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub === contract?.owner?.sub ? (
              "Sign initiated"
            ) : contract?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
              "Sign required"
            ) : contract?.status === StatusEnum?.stamp_initiated ? (
              "Stamp initiated"
            ) : contract?.status === StatusEnum?.stamp_failed ? (
              "E-stamp failed"
            ) : contract?.status === "PENDING" ? (
              "Paused"
            ) : contract?.status === StatusEnum?.rejected ? (
              "Rejected"
            ) : contract?.status === StatusEnum?.approved &&
              contract?.stamp_required === true &&
              loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
              "E-stamp initiated"
            ) : contract?.status === StatusEnum?.approved &&
              contract?.stamp_required === false &&
              loggedInContext?.currentUser?.sub === contract?.owner?.sub ? (
              "Sign initiated"
            ) : contract?.status === StatusEnum?.approved &&
              contract?.stamp_required === true &&
              loggedInContext?.currentUser?.sub === contract?.owner?.sub ? (
              "E-stamp required"
            ) : contract?.status === StatusEnum?.pending_negotitation &&
              loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
              "Negotiation ongoing"
            ) : contract?.status === StatusEnum?.pending_negotitation &&
              loggedInContext?.currentUser?.sub === contract?.owner?.sub ? (
              "Negotiation initiated"
            ) : (
              <>
                {approvalUserDetail?.status === StatusEnum?.approved &&
                loggedInContext?.currentUser?.sub !== contract?.owner?.sub ? (
                  "Approved"
                ) : (
                  <>
                    {(loggedInContext?.currentUser?.sub !==
                      contract?.owner?.sub && approvalUserDetail?.status) ===
                      StatusEnum?.approved && "Approved"}
                    {loggedInContext?.currentUser?.sub !==
                      contract?.owner?.sub &&
                    contract?.status === StatusEnum?.pending_approve
                      ? "Approval required"
                      : loggedInContext?.currentUser?.sub !==
                          contract?.owner?.sub &&
                        approvalUserDetail?.status === "PENDING" &&
                        "Approval required"}
                  </>
                )}
              </>
            )}

            {contract?.status !== StatusEnum?.draft && collaboratorsDetail && (
              <>&nbsp;</>
            )}
            {loggedInContext?.currentUser?.sub === contract?.owner?.sub &&
              contract?.status === StatusEnum?.pending_approve &&
              "Pending for approval"}

            {contract?.status === StatusEnum?.draft && "Draft"}
          </div>
          <div className=" mb-2 row ">
            <div className=" col-12">
              <ProgressBar
                now={
                  contract?.status === StatusEnum?.draft
                    ? 0
                    : contract?.status === StatusEnum?.published
                    ? 10
                    : contract?.status === "PENDING"
                    ? 20
                    : contract?.status === StatusEnum?.pending_negotitation
                    ? 30
                    : contract?.status === StatusEnum?.pending_approve
                    ? 30
                    : contract?.status === StatusEnum?.approved
                    ? 50
                    : contract?.status === StatusEnum?.sign_initiated
                    ? 50
                    : contract?.status === StatusEnum?.rejected
                    ? 50
                    : contract?.status === "COMPLETED"
                    ? 100
                    : contract?.status === StatusEnum?.stamped
                    ? 70
                    : contract?.status === StatusEnum?.stamp_initiated
                    ? 90
                    : contract?.status === StatusEnum?.stamp_failed
                    ? 90
                    : 0
                }
                className={`progress-bar-css ${
                  contract?.status === StatusEnum?.draft
                    ? "draft_bar"
                    : contract?.status === StatusEnum?.published
                    ? "publish_bar"
                    : contract?.status === "PENDING"
                    ? "pending_bar"
                    : contract?.status === StatusEnum?.pending_approve
                    ? "pending_approval_bar"
                    : contract?.status === StatusEnum?.approved
                    ? "approval"
                    : contract?.status === StatusEnum?.sign_initiated
                    ? "stamp_bar"
                    : contract?.status === "COMPLETED"
                    ? "complete_bar"
                    : contract?.status === StatusEnum?.stamped
                    ? "stamp_bar"
                    : contract?.status === StatusEnum?.stamp_failed
                    ? "stamp_initiated_bar"
                    : contract?.status === StatusEnum?.stamp_initiated
                    ? "stamp_initiated_bar"
                    : contract?.status === StatusEnum?.rejected
                    ? "rejected_bar"
                    : contract?.status === StatusEnum?.pending_negotitation
                    ? "negotiation_bar"
                    : ""
                }`}
              />
            </div>
          </div>

          <div className=" row">
            <div className="card-clock mt-3 mb-3 col-12">
              <div className="card-update-date px-3 py-2 ">
                Last Updated :
                <span className="textBlack ms-1">
                  {""}
                  {contract?.formattedUpdatedAt()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentContractList;
