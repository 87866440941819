import { useLocation } from "react-router-dom";
import { ITemplateCategory } from "../interfaces/ITemplateCategory";
import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";
import CommonAsyncSelect from "./CommonAsyncSelect";

function Category({
  defaultCategories,
  categoriesAutocomplete,
  setFilterParams,
  filterParams,
}: {
  defaultCategories: ITemplateCategory[];
  categoriesAutocomplete: Function;
  setFilterParams: Function;
  filterParams: ITemplateFilterParams;
}) {
  const location = useLocation();

  return (
    <>
      {/* category */}
      {!location.pathname.includes("folder") && (
        <>
          <div className="row status-title mb-3 mt-3">Category</div>
          <CommonAsyncSelect
            placeholder="Select"
            className={"select-wrapper"}
            isMulti={false}
            cacheOptions
            value={
              (filterParams?.category && {
                label: filterParams?.category,
                value: filterParams?.category,
              }) || { label: "", value: "" } || { label: "", value: "" }
            }
            options={(defaultCategories || [])?.map(
              (category: ITemplateCategory) => ({
                label: category?.category,
                value: category?.category,
              })
            )}
            loadOptionValue={async (search: string) => {
              const res = await categoriesAutocomplete(search);
              const _categories = res.map((category: ITemplateCategory) => ({
                label: category?.category,
                value: category?.category,
              }));
              return _categories;
            }}
            updateField={(option: any) => {
              setFilterParams({
                ...filterParams,
                category: option.value,
              });
            }}
          />
        </>
      )}
    </>
  );
}

export default Category;
