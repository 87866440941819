import Avatar from "react-avatar";
import { Badge, Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";

export const columns = ({
  setId,
  setShow,
  setAlertToast,
  setBlockId,
  setUnBlockId,
  setIsResend,
  activeUser,
}: any) => {
  return [
    {
      name: "Avatar",
      minWidth: "100px",
      cell: (row: any) => {
        return (
          <Avatar
            name={row?.first_name}
            size="50"
            className="avatar_css_members"
            src={row?.avatar}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.email?.charAt(0)}
          />
        );
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: any) => {
        return (
          <span>
            {[row.first_name, row.last_name]
              .filter((str) => str && str.length > 1)
              .join(" ")}
          </span>
        );
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Email address",
      width: "250px",
      cell: (row: any) => {
        return <span>{row?.email}</span>;
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Contact number",
      width: "250px",
      cell: (row: any) => {
        return <span>{row?.contact_number || "-"}</span>;
      },
      grow: 0,
      reorder: true,
      sortable: false,
    },
    {
      name: "Role",
      width: "140px",
      cell: (row: any) => {
        return <span>{row?.roles || "-"}</span>;
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Department",
      width: "140px",
      cell: (row: any) => {
        return <span>{row?.department || "-"}</span>;
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Status",
      width: "190px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <Badge
            className={
              row?.is_active === "false" ? "draft-badge" : "complete-badge"
            }
            bg="success"
          >
            {row?.emailVerified === "false" && row?.is_active === "false"
              ? "INVITED"
              : row?.emailVerified === "true" && row?.is_active === "false"
              ? "BLOCKED"
              : "ACTIVE"}
          </Badge>
        );
      },
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <>
            <div className="pt-2 Actions">
              <Dropdown drop="end">
                <Dropdown.Toggle
                  variant="success"
                  className="Actions_title"
                  id="dropdown-basic"
                >
                  <BsThreeDots fontSize={24} />{" "}
                </Dropdown.Toggle>

                <Dropdown.Menu className="create-dropdown-css">
                  {row?.emailVerified === "false" ? (
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        setId(row?.id);
                        setShow(true);
                        // setMembersDetail(row);
                        setIsResend(true);
                      }}
                    >
                      Resend Invitation
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        setId(row?.id);
                        setShow(true);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                  )}
                  {activeUser?.email !== row?.email && (
                    <>
                      {row?.is_active === "true" &&
                        row?.emailVerified === "true" && (
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              setBlockId(row?.id);
                              setAlertToast(true);

                              setUnBlockId(null);
                            }}
                          >
                            Block
                          </Dropdown.Item>
                        )}
                      {row?.is_active === "false" &&
                        row?.emailVerified === "true" && (
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              setUnBlockId(row?.id);
                              setBlockId(null);
                              setAlertToast(true);
                            }}
                          >
                            UnBlock
                          </Dropdown.Item>
                        )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];
};
