import { Button, Form, Modal } from "react-bootstrap";
import { useContract } from "./ContractProvider";
import PDFViewer from "../../components/PDFViewer";
import Select from "react-select";
import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../utils/routes";
import { TiDelete } from "react-icons/ti";
import DeleteToast from "../../ui/DeleteToast";

function PdfPopup({
  show,
  onHide,
  viewCoordinates,
}: {
  show: boolean;
  onHide: Function;
  viewCoordinates: boolean;
}) {
  const context = useContract();

  const navigate = useNavigate();
  const { id } = useParams();
  const [signatureType, setSignatureType]: [boolean, Function] =
    useState(false);
  const [isNameREquired, setIsNameRequired]: [boolean, Function] =
    useState(false);
  const [isDateRequired, setIsDateRequired]: [boolean, Function] =
    useState(false);
  const [activePage, setACtivePage]: [string, Function] = useState("all");
  const [locationRequired, setLocationRequired]: [boolean, Function] =
    useState(false);
  const [isCoordinateAdded, setIsCoordinateAdded]: [boolean, Function] =
    useState(false);

  const [isDesiginationRequired, setIsDesiginationRequired] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDefaultCoordinates, setCoordinates]: [boolean, Function] =
    useState(false);
  const [pageDetails, setPagesDetails]: [[], Function] = useState([]);

  const [pagesTypeNumber, setPagesTypeNUmber] = useState<any>([]);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [selectedCoordinate, setSelectedCoordinate]: [string, Function] =
    useState("");

  const populatePagesType = () => {
    if (context?.data?.pages) {
      let page: any = context?.data?.pages;

      const newPagesType: any = [];
      for (let i = 1; i <= page; i++) {
        newPagesType.push({ value: i.toString(), label: i.toString() });
      }

      if (activePage === "all") {
        setPagesTypeNUmber(newPagesType);
      } else if (activePage === "last") {
        setPagesTypeNUmber([newPagesType[newPagesType.length - 1]]);
      } else if (activePage === "custom") {
        setPagesTypeNUmber(newPagesType);
      }
    }
  };

  useEffect(() => {
    populatePagesType();
  }, [activePage]);

  const handleConfirmCoordinate = async (type: string) => {
    let allPagesValue: any;
    if (activePage === "custom") {
      allPagesValue = selectedOptions?.map((el: any) => ({
        page: el?.value,
      }));
    } else {
      allPagesValue = pagesTypeNumber?.map((el: any) => ({
        page: el?.value,
      }));
    }
    const inputArray: any = context?.data?.internalSingers
      ?.map((item: any) => item)
      .concat(context?.data?.externalUser?.map((item: any) => item));

    const outputArray = inputArray?.map((item: any) => {
      const co_ordinates = item?.co_ordinates?.map((coords: any) => ({
        page: coords?.page,
        x: coords?.x,
        y: coords?.y,
        height: coords?.height,
        width: coords?.width,
        initial_required: coords?.initial_required,
        name_required: coords?.name_required,
        date_required: coords?.date_required,
        designation_required: coords?.designation_required,
      }));

      return {
        co_ordinates,
        user: item._id,
      };
    });

    const updatedCoordinatesArray = context?.coordinate.map((item: any) => {
      const updatedCo_ordinates = item.co_ordinates.flatMap((coordinate: any) =>
        allPagesValue.map((page: any) => ({
          ...coordinate,
          page: parseInt(page.page),
          initial_required: signatureType,
          name_required: isNameREquired,
          date_required: isDateRequired,
          designation_required: isDesiginationRequired,
          location_required: locationRequired,
        }))
      );

      return {
        ...item,
        co_ordinates: updatedCo_ordinates,
      };
    });

    let groupedByUser: any;
    if (context?.data?.internalSingers?.map((el: any) => el?.co_ordinates)) {
      const mergedArray: any = [...updatedCoordinatesArray, ...outputArray];

      groupedByUser = mergedArray?.reduce((result: any, item: any) => {
        const existingUser = result?.find(
          (user: any) => user?.user === item?.user
        );
        if (existingUser) {
          item.co_ordinates?.forEach((coord: any) => {
            const existingCoord: any = existingUser?.co_ordinates.find(
              (existingCoord: any) => existingCoord?.page === coord?.page
            );
            if (!existingCoord) {
              existingUser?.co_ordinates?.push(coord);
            }
          });
        } else {
          result?.push(item);
        }
        return result;
      }, []);
    }

    try {
      let response = await context?.handleAddCoordinate(id, {
        signing: context?.data?.internalSingers?.map(
          (el: any) => el?.co_ordinates
        )
          ? groupedByUser
          : updatedCoordinatesArray,
      });
      context?.refresh();
      if (type === "new") {
        setIsCoordinateAdded(true);
        handlePageDetail(
          response?.data?.contract?.internal_signer,
          response?.data?.contract?.external_users
        );
        setSignatureType(false);
        setIsNameRequired(false);
        setIsDateRequired(false);
        setSelectedOptions([]);
      }
      if (type === "redirect") {
        if (response?.data?.success) {
          handlePageDetail(
            response?.data?.contract?.internal_signer,
            response?.data?.contract?.external_users
          );
          navigate(
            generatePath(routes.workflowContracts, {
              id: id!,
            })
          );
          onHide();
        }
      }
    } catch (error) {}
  };

  const handlePageDetail = (internalUser: [], externalUser: []) => {
    if (
      internalUser?.filter((item: any) => item?.co_ordinates)?.length ||
      externalUser?.filter((item: any) => item?.co_ordinates)?.length
    ) {
      const inputArray: any = internalUser
        ?.map((items: any) => items?.co_ordinates)
        .concat(externalUser?.map((items: any) => items?.co_ordinates));

      const uniqueItems = new Map();

      for (const subArray of inputArray) {
        for (const item of subArray) {
          const key = JSON.stringify({
            page: item?.page?.toString(),
            initial_required: item?.initial_required,
            name_required: item?.name_required,
            date_required: item?.date_required,
            designation_required: item?.designation_required,
          });
          uniqueItems.set(key, item);
        }
      }

      const resultArray = Array.from(uniqueItems.values());
      setPagesDetails(resultArray);
    }
  };

  useEffect(() => {
    if (context?.data?.externalUser || context?.data?.internalSingers) {
      handlePageDetail(
        context?.data?.internalSingers,
        context?.data?.externalUser
      );
    }
  }, [context?.data?.internalSingers, context?.data?.externalUser]);

  const onHandleDeleteCoordinate = async () => {
    const inputArray: any = context?.data?.internalSingers
      ?.map((item: any) => item)
      .concat(context?.data?.externalUser?.map((item: any) => item));

    const outputArray = inputArray?.map((item: any) => {
      const co_ordinates = item?.co_ordinates?.map((coords: any) => ({
        page: coords?.page,
        x: coords?.x,
        y: coords?.y,
        height: coords?.height,
        width: coords?.width,
        initial_required: coords?.initial_required,
        name_required: coords?.name_required,
        date_required: coords?.date_required,
        designation_required: coords?.designation_required,
      }));

      return {
        co_ordinates,
        user: item._id,
      };
    });

    // Filter out entries with page value equal to 1
    const filteredArray = outputArray.map((item: any) => ({
      ...item,
      co_ordinates: item.co_ordinates.filter(
        (coord: any) => coord.page !== selectedCoordinate
      ),
    }));

    try {
      let response = await context?.handleAddCoordinate(id, {
        signing: filteredArray,
      });
      setShowDeleteToast(false);
      context?.refresh();
      setIsCoordinateAdded(true);
      handlePageDetail(
        response?.data?.contract?.internal_signer,
        response?.data?.contract?.external_users
      );
      setSignatureType(false);
      setIsNameRequired(false);
      setACtivePage("all");
      setIsDateRequired(false);
      setSelectedOptions([]);
    } catch (error) {}
  };

  return (
    <div>
      <Modal show={show} onHide={() => onHide()} centered size="xl">
        <Modal.Header closeButton>
          <h6 className=" fw-semibold m-0"> Add Co-ordinates</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-auto ">
              {context?.data?.filePath && (
                <PDFViewer
                  file={context?.data?.filePath}
                  pdfPopup={isCoordinateAdded}
                  setIsMouseEnter={() => {}}
                />
              )}
            </div>

            <div className=" col-md-5 col-12">
              {!viewCoordinates && (
                <Form>
                  <Form.Group
                    className="mb-3 pdf_form"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="col-md-12 col-12 mt-2">
                      <label className="input_label">Signature info</label>
                      <br></br>
                      <Form.Check
                        inline
                        type="checkbox"
                        id={`name-${1}`}
                        label="Name"
                        checked={isNameREquired}
                        onChange={() => setIsNameRequired(!isNameREquired)}
                      />
                      <Form.Check
                        inline
                        type="checkbox"
                        id={`date-${1}`}
                        label="Date"
                        checked={isDateRequired}
                        onChange={() => setIsDateRequired(!isDateRequired)}
                      />
                      <Form.Check
                        inline
                        type="checkbox"
                        id={`designation-${1}`}
                        label="Designation"
                        checked={isDesiginationRequired}
                        onChange={() =>
                          setIsDesiginationRequired(!isDesiginationRequired)
                        }
                      />
                      <Form.Check
                        inline
                        type="checkbox"
                        id={`initials-${1}`}
                        label="Initials"
                        checked={signatureType}
                        onChange={() => setSignatureType(!signatureType)}
                      />
                      <Form.Check
                        inline
                        type="checkbox"
                        id={`location-${1}`}
                        label="Location"
                        checked={locationRequired}
                        onChange={() => setLocationRequired(!locationRequired)}
                      />
                    </div>

                    <div className="col-md-12 col-12 mt-2">
                      <label className="input_label">Pages</label>
                      <br></br>
                      <Form.Check
                        inline
                        type="radio"
                        id={`all-${1}`}
                        label="All Pages"
                        checked={activePage === "all"}
                        onChange={() => setACtivePage("all")}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        id={`last-${1}`}
                        label="Last Page"
                        checked={activePage === "last"}
                        onChange={() => setACtivePage("last")}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        id={`custom-${1}`}
                        label="Select Pages"
                        checked={activePage === "custom"}
                        onChange={() => setACtivePage("custom")}
                      />

                      {activePage === "custom" && (
                        <>
                          <div className="col-md-12 col-12 mt-2">
                            <label className="input_label">
                              Selected Pages
                            </label>
                            <Select
                              className="basic-single "
                              isMulti
                              defaultValue={selectedOptions[0]}
                              options={pagesTypeNumber}
                              onChange={(data: any) => {
                                setSelectedOptions(data);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12 col-12 mt-2">
                      <label className="input_label">Co-ordinates</label>
                      <br></br>
                      <Form.Check
                        inline
                        type="radio"
                        id={`coordinate-${1}`}
                        label="At the end"
                        checked={!isDefaultCoordinates}
                        onChange={() => setCoordinates(!isDefaultCoordinates)}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        id={`coordinate-rearrange-${1}`}
                        label="Choose co-ordinates"
                        checked={isDefaultCoordinates}
                        onChange={() => setCoordinates(!isDefaultCoordinates)}
                      />
                    </div>
                  </Form.Group>

                  <span
                    className=" d-flex justify-content-end mb-4 new_coordinate"
                    onClick={() => {
                      handleConfirmCoordinate("new");
                    }}
                  >
                    + Add
                  </span>

                  <div className=" d-flex justify-content-end mb-4">
                    <Button
                      className="cancelbtn me-3 "
                      size="sm"
                      onClick={() => onHide()}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="sharebtndanger"
                      size="sm"
                      onClick={() => {
                        handleConfirmCoordinate("redirect");
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Form>
              )}

              <div
                style={{
                  maxHeight: !viewCoordinates ? "42vh" : "70vh",
                  overflowY: "scroll",
                }}
                className="px-4"
              >
                <Form>
                  {pageDetails?.map((details: any) => {
                    return (
                      <div className=" position-relative mt-3">
                        {!viewCoordinates && (
                          <div className=" deleteBtn">
                            <TiDelete
                              color="#e15554"
                              fontSize={24}
                              onClick={() => {
                                setSelectedCoordinate(details?.page);
                                setShowDeleteToast(true);
                              }}
                            />
                          </div>
                        )}

                        <Form.Group
                          className="mb-3 pdf_form"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <div className="col-md-12 col-12 mt-2">
                            <label className="input_label">
                              Page:{details?.page} details
                            </label>
                            <br></br>
                            <Form.Check
                              inline
                              type="checkbox"
                              id={`name-${1}`}
                              label="Name"
                              checked={details?.name_required}
                            />
                            <Form.Check
                              inline
                              type="checkbox"
                              id={`date-${1}`}
                              label="Date"
                              checked={details?.date_required}
                            />
                            <Form.Check
                              inline
                              type="checkbox"
                              id={`designation-${1}`}
                              label="Designation"
                              checked={details?.name_required}
                            />
                            <Form.Check
                              inline
                              type="checkbox"
                              id={`initials-${1}`}
                              label="Initials"
                              checked={details?.initial_required}
                            />
                            <Form.Check
                              inline
                              type="checkbox"
                              id={`location-${1}`}
                              label="Location"
                              checked={details?.location_required}
                            />
                          </div>
                          {/* <div className="col-md-12 col-12 mt-2">
                          <label className="input_label">Selected Pages</label>
                          <Select
                            className="basic-single "
                            isMulti
                            defaultValue={selectedOptions[0]}
                            options={pagesTypeNumber}
                            onChange={(data: any) => {

                              setSelectedOptions(data);
                            }}
                          />
                        </div> */}
                        </Form.Group>
                      </div>
                    );
                  })}
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => {
            onHandleDeleteCoordinate();
          }}
          onClose={() => setShowDeleteToast(false)}
          title="Do you want to delete this co_ordinate?"
          closeText="No"
          submitText="Yes"
        />
      )}
    </div>
  );
}

export default PdfPopup;
