import { Formik } from "formik";
import { useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import * as Yup from "yup";
import {
  ContractSortEnum,
  TemplateSortEnum,
} from "../../../interfaces/SortEnum";
import DocumentFilter from "../../../ui/DocumentFilter";
import { ListContractContextInterface } from "../../Contracts/ListContractProvider";
import { ListTemplateContextInterface } from "../ListTemplatesProvider";

function MobileTemplateFooter({
  enumType,
  context,
}: {
  enumType: string;
  context: ListContractContextInterface | ListTemplateContextInterface;
}) {
  const [isSortByActive, setIsSortByActive] = useState<boolean>(false);
  const [activeSortByName, setActiveSortByName] =
    useState<string>("Recently Added");
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

  const initialValues: {
    name: string;
    templateTitle: string;
    templateTitleId: string;
  } = { name: "", templateTitle: "", templateTitleId: "" };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    templateTitle: Yup.string().required("Name is required"),
    templateTitleId: Yup.string().required("Name is required"),
  });

  const SortEnum =
    enumType === "TemplateSortEnum" ? TemplateSortEnum : ContractSortEnum;

  return (
    <div>
      <div className="sort-filter-mobile row m-0 sort-filters">
        <div
          className="col p-0 d-flex justify-content-center align-items-center border-end border-2 "
          onClick={() => {
            setIsSortByActive(!isSortByActive);
          }}
        >
          <div className=" text-center d-flex align-items-center  ">
            <span className="mobileFilterSortName">{activeSortByName}</span>
            <span className="px-2">
              <BiSortAlt2 fontSize={16} color="#a8aab7" />
            </span>
          </div>

          <div
            className={`dropdownsortMobile ${
              isSortByActive ? "active" : "inactive"
            }`}
          >
            <div className="d-flex flex-column mb-3 ">
              <div className="py-2 sort-by-title">Sort By</div>

              <div
                className="p-2 filter-background-color"
                onClick={() => {
                  setActiveSortByName("Recently Added");
                  setIsSortByActive(false);
                  context?.changeFilter({
                    ...context.filterParams,
                    sort: "-createdAt",
                  });
                }}
              >
                {SortEnum.recentlyAdded}
              </div>
              <div
                className="p-2"
                onClick={() => {
                  setActiveSortByName("Recently Updated");
                  setIsSortByActive(false);
                  context?.changeFilter({
                    ...context.filterParams,
                    sort: "-updatedAt",
                  });
                }}
              >
                {SortEnum?.recentlyUpdated}
              </div>
              <div
                className="p-2"
                onClick={() => {
                  setActiveSortByName("Templates - A to Z");
                  setIsSortByActive(false);
                  context?.changeFilter({
                    ...context.filterParams,
                    sort: "name",
                  });
                }}
              >
                {SortEnum?.nameAtoZ}
              </div>
              <div
                className="p-2"
                onClick={() => {
                  setActiveSortByName("Templates - Z to A");
                  setIsSortByActive(false);
                  context?.changeFilter({
                    ...context.filterParams,
                    sort: "-name",
                  });
                }}
              >
                {SortEnum?.nameZtoA}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col p-0 d-flex justify-content-center align-items-center"
          onClick={() => setIsFilterActive(!isFilterActive)}
        >
          <div className="d-flex">
            <span className="   mobileFilterSortName d-flex align-items-center text-center">
              Filter
            </span>
            <span className="px-2">
              {" "}
              <FiFilter fontSize={16} color="#a8aab7" />
            </span>
          </div>
          <div
            className={`dropdowncustomMobile dropdowncustomMobileNormal ${
              isFilterActive ? "active" : "inactive"
            }`}
          >
            <div className="d-flex flex-column ">
              <div className="py-2 sort-by-title">Filter By</div>
              <div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={() => {}}
                >
                  {() => (
                    <>
                      <DocumentFilter
                        params={context.filterParams}
                        changeFilter={context.changeFilter}
                        resetFilter={context.resetFilter}
                        categoriesAutocomplete={context.categoriesAutocomplete}
                        defaultCategories={context.defaultCategories}
                        setIsFilterActive={setIsFilterActive}
                        isFilterActive={isFilterActive}
                        context={context}
                        status={true}
                      />
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTemplateFooter;
