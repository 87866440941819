import { Button, Form, Modal } from "react-bootstrap";
import { ITemplateCategory } from "../../../interfaces/ITemplateCategory";
import { ContractContextInterface, useContract } from "../ContractProvider";
import {
  TemplateContextInterface,
  useTemplate,
} from "../../Templates/TemplateProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

function PublishDocuentPopup({
  show,
  onHide,
  context,
  setExpiryDate,
  expiryDate,
  setShowPublishToast,
}: {
  show: boolean;
  onHide: Function;
  context: ContractContextInterface | TemplateContextInterface | null;
  setExpiryDate: Function;
  expiryDate: any;
  setShowPublishToast: Function;
}) {
  const location = useLocation();
  const templateContext = useTemplate();
  const { id } = useParams();
  const contractContext = useContract();

  const [department, setDepartment]: [any, Function] = useState();
  const [category, setCategory]: [any, Function] = useState();

  const [isPublishActive, setIsPublishActive]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    if (location.pathname.includes("contracts")) {
      if (
        department !== undefined &&
        category !== undefined &&
        expiryDate !== undefined
      ) {
        setIsPublishActive(true);
      }
    } else if (location.pathname.includes("templates")) {
      if (category) {
        setIsPublishActive(true);
      }
    }
  }, [department, category, expiryDate]);

  const onUpdateDocument = async () => {
    try {
      let requestBody = {
        name: !!context?.data && context?.data?.name,
        category: category ? category : "",
        department: department ? department : "",
        expiry_date: expiryDate ? moment(expiryDate).format("YYYY-MM-DD") : "",
      };
      const response = await context?.publishDocument(id, requestBody);
      if (response) {
        onHide();
        context?.refresh();
        setShowPublishToast(true);
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal show={show} onHide={() => onHide} centered>
        <Modal.Header closeButton onClick={() => onHide()}>
          <div className="pt-3">
            <h6 className=" fw-semibold"> Update document </h6>
          </div>
        </Modal.Header>

        <Modal.Body className="pt-2 p-4">
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="row status-title mb-3 mt-3">Department</div>
              <Select
                defaultValue={
                  templateContext
                    ? {
                        label: templateContext?.template.department!,
                        value: templateContext?.template.department!,
                      }
                    : {
                        label: contractContext?.contract.department!,
                        value: contractContext?.contract.department!,
                      }
                }
                className={"select-wrapper"}
                options={context?.departmentList?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(option: any) => {
                  setDepartment(option?.value);
                }}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="row status-title mb-3 mt-3">Category</div>
              <CreatableSelect
                options={(templateContext
                  ? context?.defaultCategories
                  : contractContext?.categoryList || []
                )?.map((category: ITemplateCategory) => ({
                  label: category?.category,
                  value: category?.category,
                }))}
                defaultValue={
                  templateContext
                    ? {
                        label: templateContext?.template.category!,
                        value: templateContext?.template.category!,
                      }
                    : {
                        label: contractContext?.contract.category!,
                        value: contractContext?.contract.category!,
                      }
                }
                onChange={(option: any) => {
                  setCategory(option?.value);
                }}
              />
            </Form.Group>
            {!location.pathname.includes("templates") && (
              <>
                <Form.Group>
                  <div className="row status-title mb-3 mt-3">Expiry Date</div>
                  <DatePicker
                    className="date_picker_css"
                    value={expiryDate ? moment(expiryDate).toDate() : ""}
                    onChange={(date: any) => {
                      setExpiryDate(date);
                    }}
                    minDate={moment().toDate()}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant={"none"}
            className={`me-3  px-3 py-2 fw-semibold cancelbtn " `}
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className=" updatebtndanger  me-2"
            disabled={!isPublishActive}
            variant="primary"
            size="sm"
            onClick={() => {
              onUpdateDocument();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PublishDocuentPopup;
