import { useState, useEffect } from 'react'


function usePopup(popupRef: any) {
    const [showPopup,setShowPopup] = useState<boolean>(false);
    
    useEffect(()=>{
        const handleDocumentClick = (e: any)=>{
            const clickedComponent = e.target;
            if(!popupRef?.current?.contains(clickedComponent)){
                setShowPopup(false);
            }
        }
        document.addEventListener('click',handleDocumentClick);

        return ()=>{
            document.removeEventListener('click',handleDocumentClick)
        }
         
    }, [])

    return [showPopup,setShowPopup] as const;
}

export default usePopup

