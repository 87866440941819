import { Dropdown } from "react-bootstrap";
import { routes } from "../../../utils/routes";
import noData from "../../../assets/noData.png";
import {
  matchPath,
  useLocation,
  useNavigate,
  generatePath,
} from "react-router";
import { useContract } from "../ListContractProvider";
import CreateContractPopup from "./CreateContractPopup";
import { useEffect, useState } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

const initialValue = { name: "Untitled Contract", description: "" };

function NoListContractData({
  isActive,
  search,
}: {
  isActive?: string;
  search?: any;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContract();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();
  const [showCreateTemplate, setShowCreateTemplate]: [boolean, Function] =
    useState(false);

  const isActiveTemplates: boolean =
    !!matchPath(location.pathname, routes.contracts) ||
    !!matchPath(routes.contractsByDepartment, location.pathname);

  const handleCreateContracts = async () => {
    try {
      let response = await context?.createDocuments(initialValue);
      if (response) {
        navigate(
          generatePath(routes.editContract, {
            id: response?.id,
          })
        );
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.CONTRACTS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      <div className=" mt-2 mx-2 contract-title ">
        {!search && (
          <span
            className="breadcrumbs-nodata"
            onClick={async () => {
              {
                await context?.changeFilter({
                  ...context.filterParams,
                  search: "",
                  department: "",
                  collaborators: "",
                });
                navigate(routes.root);
              }
            }}
          >
            {isActive === "createContractPopup" ? (
              ""
            ) : (
              <> {isActiveTemplates && "Contracts"}</>
            )}
          </span>
        )}
      </div>
      <div className="File404">
        <div className="d-flex flex-column align-items-center">
          <div className={"font404 d-flex justify-content-center"}>
            <img src={noData} alt="no-data" width={200} />
          </div>
          <div className="no-data-found">No Data Found</div>
          {context?.allData?.length === 0 && (
            <>
              {" "}
              <div>
                {context?.recentContracts?.length === 0 && (
                  <div className="p-2 bd-highlight  create-template-btn">
                    {isActive === "createContractPopup" ? (
                      <>
                        {roles === "CREATE" && (
                          <>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="new-contact-btn create-template contract-css ms-lg-3"
                                id="dropdown-basic"
                                onClick={() => handleCreateContracts()}
                              >
                                New Contract
                              </Dropdown.Toggle>
                            </Dropdown>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {roles === "CREATE" && (
                          <>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between"
                                id="dropdown-basic"
                              >
                                New Contract{" "}
                                <span className="ps-2">
                                  <MdOutlineArrowDropDown fontSize={18} />
                                </span>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="create-dropdown-css">
                                <Dropdown.Item
                                  onClick={() => handleCreateContracts()}
                                  className="sort-by"
                                >
                                  New Contract
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setShowCreateTemplate(true)}
                                  className="sort-by"
                                >
                                  View Templates
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {showCreateTemplate && (
        <CreateContractPopup
          show={showCreateTemplate}
          onHide={() => setShowCreateTemplate(false)}
        />
      )}
    </>
  );
}

export default NoListContractData;
