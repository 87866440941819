import { ReactEditor, useSlate } from "slate-react";
import { useCallback, useEffect, useRef } from "react";

import { Card } from "react-bootstrap";
// import { Button, Form } from "react-bootstrap";

export default function NodePopover({
  header,
  node,
  children,
  editorOffsets,
  className,
  // isBodyFullWidth,
  onClickOutside,
}: any) {
  const popoverRef: any = useRef(null);
  const editor = useSlate();

  useEffect(() => {
    const editorEl: any = popoverRef.current;
    if (editorEl == null) {
      return;
    }
    // const domNode = ReactEditor?.toDOMNode(editor, node);
    let domNode: any;
    if (node) {
      domNode = ReactEditor?.toDOMNode(editor, node);
    } else {
      console.log("invalid node or undefined node in PROD");
      window.location.reload();
    }
    const {
      // x: _nodeX,
      height: nodeHeight,
      y: nodeY,
    } = domNode?.getBoundingClientRect();
    editorEl.style.display = "block";
    editorEl.style.top = `${nodeY + nodeHeight - editorOffsets.y}px`;
    editorEl.style.left = `${-20.5}vw`;
    editorEl.scrollIntoView(false);
  }, [editor, editorOffsets?.x, editorOffsets?.y, node]);

  const onMouseDown = useCallback(
    (event: any) => {
      if (
        popoverRef.current != null &&
        !popoverRef.current.contains(event.target) &&
        onClickOutside != null
      ) {
        onClickOutside(event);
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  if (editorOffsets == null) {
    return null;
  }

  return (
    <>
      <Card
        ref={popoverRef}
        className={className}
        style={{
          minWidth: "300px",
          border: "0px",
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
          marginTop: "10px",
          borderRadius: "0px",
          zIndex: 9999,
        }}
      >
        {header != null ? (
          <Card.Header style={{ background: "transparent" }}>
            {header}
          </Card.Header>
        ) : null}
        {/* <Card.Body style={isBodyFullWidth ? { padding: 0 } : undefined}> */}
        {children}
        {/* </Card.Body> */}
      </Card>

      {/* <div
        className=" w-100 p-3"
        style={{
          backgroundColor: "#fefefe",
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
          maxHeight: "30vh",
          // position: "absolute",
          zIndex: 1,
          maxWidth: "700px",
        }}
        ref={popoverRef}
      >
        <div className=" pb-3">
          Consider linking your Privacy Policy with your Terms of
          <span
            style={{
              color: "#e15554",
              textDecorationLine: "line-through",
            }}
          >
            Use to ensure cohesive application of relevant laws.{" "}
          </span>{" "}
          <span style={{ color: "#2abf86" }}>
            {" "}
            If you would like to be more specific with your users,
          </span>
          consider a Data Usage Policy or Data Usage Statement.
        </div>
        <div className=" border-top d-flex justify-content-between align-items-center pt-3">
          <div className=" d-flex align-items-center">
            <div className=" d-flex align-items-center">
              <Form>
                <Form.Group>
                  <Form.Check inline type="checkbox" defaultChecked />
                </Form.Group>
              </Form>
              <span>Public</span>
            </div>
            <div className=" d-flex align-items-center border-start border-end px-3 mx-3">
              <Form>
                <Form.Group>
                  <Form.Check inline type="checkbox" />
                </Form.Group>
              </Form>
              <span>Notify</span>
            </div>
            <div>Click save to commit your changes</div>
          </div>
          <div className="d-flex align-items-center">
            {" "}
            <Button className="cancelbtn me-3 " size="sm">
              Cancel
            </Button>
            <Button className="sharebtndanger" size="sm">
              Save
            </Button>
          </div>
        </div>
      </div> */}
    </>
  );
}
