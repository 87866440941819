import { ReactNode, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  MdArticle,
  MdAssignmentAdd,
  MdKey,
  MdLogout,
  MdPersonOutline,
} from "react-icons/md";
import { Link, matchPath, useLocation } from "react-router-dom";
import { routes } from "../utils/routes";
import logo from "../assets/logo12.png";
import { BsArrowRight, BsGraphUp } from "react-icons/bs";
import { adminRoutes } from "../utils/adminRoutes";

function AdminHeaderPopup({
  show,
  onHide,
  setShowHeaderPopup,
}: {
  show: boolean;
  onHide: Function;
  setShowHeaderPopup: Function;
}) {
  const MenuItem = ({
    url,
    icon,
    text,
    active,
  }: {
    url: string;
    icon: ReactNode;
    text: string;
    active?: boolean;
  }) => (
    <Link
      to={url}
      className={`menu-item text-decoration-none ${!!active && "active"}`}
    >
      <li className="d-flex align-items-center p-3 text-white">
        {icon && <span className="me-2">{icon}</span>}
        {text}
      </li>
    </Link>
  );
  const location = useLocation();
  const [isProfileActive, setProfileActive]: [boolean, Function] =
    useState(true);

  const isActiveAnalytics: boolean = !!matchPath(
    location.pathname,
    routes.analytics
  );

  return (
    <>
      <Modal
        className="headerpopup"
        show={show}
        onHide={() => {
          onHide();
          setShowHeaderPopup(false);
        }}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <div className=" d-flex align-items-center flex-grow-1">
            <div className="pp ">
              <img src={logo} alt="logo" width={80} />
            </div>
            <div className=" d-flex flex-grow-1 align-items-center justify-content-evenly">
              <div
                className="header-css"
                onClick={() => setProfileActive(true)}
              >
                Home
              </div>
              <div
                className="header-css"
                onClick={() => setProfileActive(false)}
              >
                Profile
              </div>
            </div>
          </div>
        </Modal.Header>

        {isProfileActive ? (
          <ul className="bg-primary text-white  list-unstyled flex-grow-1 headerli m-0">
            <div className="Home-css">
              <MenuItem
                url={adminRoutes.root}
                active={isActiveAnalytics}
                icon={<BsGraphUp />}
                text={"Analytics"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div className="Home-css">
              <MenuItem
                url={adminRoutes.members}
                icon={<MdArticle />}
                text={"Members"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div className="Home-css">
              <MenuItem
                url={adminRoutes.accounts}
                icon={<MdAssignmentAdd />}
                text={"Accounts"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
          </ul>
        ) : (
          <ul className="bg-primary text-white  list-unstyled flex-grow-1 headerli m-0">
            <div className="Home-css">
              <MenuItem
                url={adminRoutes.adminprofile}
                icon={<MdPersonOutline />}
                text={" Personal Profile"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            {/* <div className="Home-css">
              <MenuItem
                url={routes.companyProfile}
                icon={<MdBusiness />}
                text={" Company Profile"}
              />
              <BsArrowRight className="arrow-css" />
            </div> */}
            <div className="Home-css">
              <MenuItem
                url={adminRoutes.changepassword}
                icon={<MdKey />}
                text={"Change Password"}
              />
              <BsArrowRight className="arrow-css" />
            </div>

            <div className="Home-css">
              <MenuItem
                url={routes?.root}
                icon={<MdLogout />}
                text={"Logout"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
          </ul>
        )}
      </Modal>
    </>
  );
}

export default AdminHeaderPopup;
