import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { isTablet } from "react-device-detect";
import { BiSortAlt2 } from "react-icons/bi";
import {
  ContractSortEnum,
  MemberSortEnum,
  TemplateSortEnum,
} from "../interfaces/SortEnum";
import { ListContractContextInterface } from "../modules/Contracts/ListContractProvider";
import { ListTemplateContextInterface } from "../modules/Templates/ListTemplatesProvider";
import { MemberContextInterface } from "../modules/Members/MemberProvider";
function DocumentSortBy({
  context,
  enumType,
}: {
  context?:
    | ListTemplateContextInterface
    | ListContractContextInterface
    | MemberContextInterface;
  enumType: string;
}) {
  const [selectedValue, setSelectedValue] = useState<string>("Recently Added");

  const SortEnum =
    enumType === "TemplateSortEnum"
      ? TemplateSortEnum
      : ContractSortEnum || MemberSortEnum;

  return (
    <>
      {isTablet ? (
        <>
          <Dropdown
            onSelect={async (eventKey: string | null) => {
              await context?.changeFilter({
                ...context.filterParams,
                sort: eventKey || "",
              });
            }}
            className="sort-by-filters"
          >
            <Dropdown.Toggle
              className="filter-css ms-lg-3 px-2 ps-2"
              id="dropdown-basic"
            >
              {selectedValue}
              <span className="px-2">
                <BiSortAlt2 />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="sort-dropdown-css">
              <Dropdown.Item
                eventKey={"-createdAt"}
                className="sort-by-tablet"
                onClick={() => setSelectedValue("Recently Added")}
              >
                {SortEnum?.recentlyAdded}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"-updatedAt"}
                className="sort-by-tablet"
                onClick={() => setSelectedValue(" Recently Updated")}
              >
                {SortEnum?.recentlyUpdated}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"name"}
                className="sort-by-tablet"
                onClick={() =>
                  setSelectedValue(
                    enumType === "ContractSortEnum"
                      ? "Contracts - A to Z"
                      : "Templates - A to Z"
                  )
                }
              >
                {SortEnum?.nameAtoZ}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"-name"}
                className="sort-by-tablet"
                onClick={() =>
                  setSelectedValue(
                    enumType === "ContractSortEnum"
                      ? "Contracts - Z to A"
                      : "Templates - Z to A"
                  )
                }
              >
                {SortEnum?.nameZtoA}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <Dropdown
          onSelect={async (e) => {
            await context?.changeFilter({
              ...context.filterParams,
              sort: e as string,
            });
          }}
          className="sort-by-filter"
        >
          <Dropdown.Toggle className="filter-css ms-lg-3" id="dropdown-basic">
            {selectedValue}
            <span className="px-2">
              <BiSortAlt2 />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sort-dropdown-css">
            <Dropdown.Item
              eventKey={"-createdAt"}
              className="sort-by"
              onClick={() => setSelectedValue("Recently Added")}
            >
              {SortEnum?.recentlyAdded}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"-updatedAt"}
              className="sort-by"
              onClick={() => setSelectedValue(" Recently Updated")}
            >
              {SortEnum?.recentlyUpdated}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"name"}
              className="sort-by"
              onClick={() =>
                setSelectedValue(
                  enumType === "ContractSortEnum"
                    ? "Contracts - A to Z"
                    : "Templates - A to Z"
                )
              }
            >
              {SortEnum?.nameAtoZ}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"-name"}
              className="sort-by"
              onClick={() =>
                setSelectedValue(
                  enumType === "ContractSortEnum"
                    ? "Contracts - Z to A"
                    : "Templates - Z to A"
                )
              }
            >
              {SortEnum?.nameZtoA}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default DocumentSortBy;
