import React, { ReactNode, useEffect, useState } from "react";
import { api } from "../../utils/api";
import { Folder } from "../../lib/Folder";
import { useLocation, useParams } from "react-router-dom";
import { User } from "../../lib/User";
import { IUserResponse } from "../../interfaces/response/IUserResponse";

interface ListFoldersContextInterface {
  folderList: Folder | undefined;
  handleAddFolders: Function;
  getDepartmentList: Function;
  departmentList: [];
  handleFolderList: Function;
  subFolders: [];
  setFolderList: Function;
  handleMoveToFolderTemplate: Function;
  handleFolderById: Function;
  setSubFolders: Function;
  handleDeleteFolder: Function;
  handleUpdateDocument: Function;
  handleMoveToFolderContract: Function;
  selectedFolderIdForMove: string;
  setSeletedFolderIdForMove: Function;
  handleFolderMove: Function;
  usersAutoComplete: Function;
  getUserDetails: Function;
  handleShareFolder: Function;
  setShareFolderId: Function;
  shareFolderId: string;
  collaborators: User[];
}

const FoldersContext = React.createContext<ListFoldersContextInterface | null>(
  null
);

interface FoldersProviderProps {
  children: ReactNode;
}

const FoldersProvider = ({ children }: FoldersProviderProps) => {
  const { id } = useParams();
  const [folderList, setFolderList]: [Folder | undefined, Function] =
    useState();
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);

  const [subFolders, setSubFolders]: [[], Function] = useState([]);
  const [selectedFolderIdForMove, setSeletedFolderIdForMove]: [
    string,
    Function
  ] = useState("");
  const [collaborators, setCollaborators]: [User[], Function] = useState([]);

  const [shareFolderId, setShareFolderId]: [string, Function] = useState("");
  const location = useLocation();

  const handleFolderList = async () => {
    try {
      let response = await api.getListFolder();

      if (location.pathname.includes("private")) {
        let privateList = response?.folder?.sub_folders?.filter(
          (folder: Folder) => {
            return folder?.access === "PRIVATE";
          }
        );

        setSubFolders(privateList);
        setFolderList(response?.folder);
      } else if (location.pathname.includes("public")) {
        let privateList = response?.folder?.sub_folders?.filter(
          (folder: Folder) => {
            return folder?.access === "PUBLIC";
          }
        );

        setSubFolders(privateList);
        setFolderList(response?.folder);
      } else {
        setSubFolders(response?.folder?.sub_folders);
        setFolderList(response?.folder);
      }

      return response;
    } catch (error) {}
  };

  const getDepartmentList = async () => {
    try {
      let response = await api.getDepartments();
      setDepartmentList(response?.data?.departments);
    } catch (error) {}
  };

  const handleAddFolders = async (params: any) => {
    try {
      let response = await api.addFolders(params);
      return response;
    } catch (error) {}
  };

  const handleMoveToFolderTemplate = async (params: any, id: string) => {
    try {
      let response = await api.moveToFolderTemplate(params, id);
      return response;
    } catch (error) {}
  };

  const handleMoveToFolderContract = async (params: any, id: string) => {
    try {
      let response = await api.moveToFolderContract(params, id);
      return response;
    } catch (error) {}
  };

  const handleFolderById = async (id: string, params: any) => {
    try {
      let response = await api.getFolderById(id, params);

      return response;
    } catch (error) {}
  };

  const handleDeleteFolder = async (id: string) => {
    try {
      let response = await api.deleteFolder(id);
      return response;
    } catch (error) {}
  };

  const getUserDetails = async () => {
    return await api.getCollaborators({ limit: 100 });
  };

  const handleUsersAutocomplete = async (params: Object) => {
    return await api.getUsersAutocomplete(params);
  };
  const handleUpdateDocument = async (id: string, params: any) => {
    try {
      let response = await api.updateFolder(id, params);
      return response;
    } catch (error) {}
  };

  const handleFolderMove = async (params: any) => {
    try {
      let response = await api.moveFolder(params);
      return response;
    } catch (error) {}
  };

  const handleShareFolder = async (id: string, params: any) => {
    try {
      let response = await api.shareFolder(id, params);
      return response;
    } catch (error) {}
  };

  // ................collaborators..............

  const getCollaborators = async () => {
    const res = await api.getCollaborators({ limit: 100 });
    const _collaborators: User[] = res.map(
      (collaboratorsParams: IUserResponse) => new User(collaboratorsParams)
    );
    setCollaborators(_collaborators);
    return _collaborators;
  };

  useEffect(() => {
    if (!id) {
      handleFolderList();
    }

    getDepartmentList();
    getCollaborators();
  }, [location.pathname.includes("private")]);

  const value: ListFoldersContextInterface = {
    handleFolderList,
    folderList,
    handleAddFolders,
    getDepartmentList,
    departmentList,
    subFolders,
    setFolderList,
    handleMoveToFolderTemplate,
    handleFolderById,
    setSubFolders,
    handleDeleteFolder,
    handleUpdateDocument,
    handleMoveToFolderContract,
    setSeletedFolderIdForMove,
    selectedFolderIdForMove,
    handleFolderMove,
    usersAutoComplete: handleUsersAutocomplete,
    getUserDetails,
    handleShareFolder,
    setShareFolderId,
    shareFolderId,
    collaborators,
  };

  return (
    <FoldersContext.Provider value={value}>{children}</FoldersContext.Provider>
  );
};

const useFolders = () => {
  return React.useContext(FoldersContext);
};

export { FoldersProvider, useFolders, type ListFoldersContextInterface };
// s;
