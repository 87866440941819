import { Button, Modal, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import AutoCompleteInput from "../../../ui/AutoCompleteInput";
import { useFolders } from "../FoldersProvider";
import { useEffect, useState } from "react";
import AvatarSection from "../../../ui/Avatar";
import { IUserResponse } from "../../../interfaces/response/IUserResponse";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import CollaboratorCheckbox from "../../../ui/CollaboratorCheckbox";
import { Collaborator } from "../../../lib/Collaborator";
import { User } from "../../../lib/User";

function ShareFolderPopup({
  show,
  onHide,
  setShowFailureToast,
  setBodyMessage,
  setShowSuccessToast,
}: {
  show: boolean;
  onHide: Function;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  setShowSuccessToast: Function;
}) {
  const authContext = useAuthentication();
  const context = useFolders();
  const [values, setValues] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [accessOption, setAccessOption] = useState<string>("EDITOR");
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false);
  const [sendMessage, setSendMessage] = useState<string>("");
  const [folderCreatedBy, setFolderCretedBy]: [User | undefined, Function] =
    useState();

  const getFolderDetailById = async () => {
    try {
      let response = await context?.handleFolderById(context?.shareFolderId);

      if (response?.success) {
        setFolderCretedBy(response?.folder?.created_by);
        let users = response?.folder?.users?.filter((el: IUserResponse) => {
          return el?.sub !== authContext?.currentUser?.sub;
        });

        setUserList(users);
      }
    } catch (error) {}
  };

  const onShareFolder = async () => {
    try {
      let ids = values?.map((el: any) => {
        return el?.value;
      });

      let requestBody = {
        user_ids: ids,
        message: sendMessage,
        notify: notifyPeople,
        access: accessOption,
      };
      let response = await context?.handleShareFolder(
        context?.shareFolderId,
        requestBody
      );

      if (response?.status === 201) {
        let users = response?.folder?.users?.filter((el: IUserResponse) => {
          return el?.sub !== authContext?.currentUser?.sub;
        });
        setUserList(users);
        setShowSuccessToast(true);
        setBodyMessage("File shared.");
        onHide();
      } else {
        setShowFailureToast(true);
        setBodyMessage("Duplicate collaborator");
        onHide();
      }
    } catch (error) {}
  };
  useEffect(() => {
    getFolderDetailById();
  }, []);

  return (
    <>
      <Modal show={show} centered style={isMobile ? {} : { height: "100vh" }}>
        <Modal.Header closeButton onClick={() => onHide()}>
          {/* <Stack> */}
          <h5 className="fw-semibold">Share Folder</h5>
          {/* </Stack> */}
          {/* <Modal.Title className="fw-semibold py-2">Share Folder</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="p-4">
          <div>
            <AutoCompleteInput
              values={values}
              setValues={setValues}
              context={context}
            />
          </div>
          <Stack>
            <h6 className="fw-semibold pb-2 pt-3">Existing Collaborators</h6>
          </Stack>
          <ul
            className="list-unstyled overflow-scroll m-0 p-0"
            style={isMobile ? { height: "40dvh" } : { height: "20vh" }}
          >
            <li key={authContext?.currentUser?.id}>
              <div className="border-0">
                <div className="p-0">
                  <div className=" d-flex align-items-center justify-content-between colabBody py-3 ">
                    <div className=" d-flex align-items-center">
                      <div>
                        <AvatarSection
                          imageData={""}
                          name={folderCreatedBy?.name}
                        />
                      </div>
                      <div className="ms-3">
                        <h6 className="fw-semibold m-0 ">
                          {folderCreatedBy?.name}&nbsp;
                          {folderCreatedBy?.last_name}
                        </h6>
                        <h6 className="fw-lighter m-0 ">
                          {folderCreatedBy?.email}
                        </h6>
                      </div>
                    </div>
                    <div>
                      <h6 className="fw-lighter m-0">Owner</h6>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {usersList?.map((users: Collaborator) => {
              return (
                <>
                  <li key={users?.user?.id}>
                    <div className="border-0">
                      <div className="p-0">
                        <div className=" d-flex align-items-center justify-content-between colabBody py-3 ">
                          <div className=" d-flex align-items-center">
                            <div>
                              <AvatarSection
                                imageData={""}
                                name={users?.user?.name}
                              />
                            </div>
                            <div className="ms-3">
                              <h6 className="fw-semibold m-0 ">
                                {users?.user?.name}&nbsp;
                                {users?.user?.last_name}
                              </h6>
                              <h6 className="fw-lighter m-0 ">
                                {users?.user?.email}
                              </h6>
                            </div>
                          </div>
                          <div>
                            <h6 className="fw-lighter m-0">{users?.access}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
          {values.length > 0 && (
            <CollaboratorCheckbox
              accessOption={accessOption}
              setAccessOption={setAccessOption}
              setNotifyPeople={setNotifyPeople}
              sendMessage={sendMessage}
              setSendMessage={setSendMessage}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger btn_margin"
            size="sm"
            onClick={onShareFolder}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShareFolderPopup;
