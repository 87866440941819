import { Formik } from "formik";
import * as Yup from "yup";
import { adminApi } from "../../../utils/adminApi";
import SettingsLayout from "./SettingsLayout";
import FormikField from "../../../ui/FormikField";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import { isMobile } from "react-device-detect";
import { Button, Form } from "react-bootstrap";
import SuccessToast from "../../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../../ui/FailureToast";
import { useState } from "react";
import { useAdminAuth } from "../Auth/AdminAuthenticationProvider";

interface changePasswordInterface {
  old_password: string | "";
  password: string | "";
  confirm_password: string | "";
}

const AdminChangePassword: React.FC = () => {
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [passwordOldIcon, setPasswordOldIcon]: [boolean, Function] =
    useState(false);
  const [passwordNewIcon, setPasswordNewIcon]: [boolean, Function] =
    useState(false);
  const [passwordConfirmIcon, setPasswordConfirmIcon]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const context = useAdminAuth();

  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object({
    old_password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("old password is required")
      .min(8, "Password is too short- should be 8 characters  minimum")
      .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*?[!"#$%&'()*+,-./:;<=>?@_`{}~])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("password is required")
      .min(8, "Password is too short- should be 8 characters  minimum")
      .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*?[!"#$%&'()*+,-./:;<=>?@_`{}~])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Password doesn't match"),
  });

  const handleSubmit = async (
    _values: changePasswordInterface,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    try {
      setLoader(true);
      let res = await adminApi.adminChangepassword(_values);
      setLoader(false);
      console.log(res, "fsdfsdfsjdk");

      if (res?.data?.success) {
        setShowSuccessToast(true);
        context?.handleLogout();
        setBodyMessage("Updated Password");
      } else {
        setShowFailureToast(true);
        setBodyMessage(res?.data?.error?.[0]);
      }
    } catch (error) {}
    setSubmitting(false);
  };

  return (
    <SettingsLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, resetForm, values }) => (
          <Form onSubmit={handleSubmit} validated={!!errors}>
            <div className={`${isMobile ? " pt-4" : "pt-4"}`}>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4">
                  <FormikField
                    name="old_password"
                    type={passwordOldIcon ? "text" : "password"}
                    validationSchema={validationSchema}
                    label="Old Password"
                    errors={values?.old_password?.length > 0 ? errors : {}}
                    placeholder="Enter your old Password"
                    value={values?.old_password}
                    autoFocus
                    isPassword={true}
                    setPasswordIcon={setPasswordOldIcon}
                    passwordIcon={passwordOldIcon}
                    className="passwordIconHide"
                  />
                </div>
                <div className="col-md-6 col-12 ps-md-5">
                  <FormikField
                    name="password"
                    type={passwordNewIcon ? "text" : "password"}
                    validationSchema={validationSchema}
                    label="New Password"
                    errors={values?.password?.length > 0 ? errors : {}}
                    value={values?.password}
                    autoFocus
                    placeholder="Enter your new password"
                    isPassword={true}
                    setPasswordIcon={setPasswordNewIcon}
                    passwordIcon={passwordNewIcon}
                    className="passwordIconHide"
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12  pe-md-4">
                  <FormikField
                    name="confirm_password"
                    type={passwordConfirmIcon ? "text" : "password"}
                    validationSchema={validationSchema}
                    label="Confirm Password"
                    errors={values?.confirm_password?.length > 0 ? errors : {}}
                    value={values?.confirm_password}
                    autoFocus
                    placeholder="Enter confirm your password"
                    isPassword={true}
                    setPasswordIcon={setPasswordConfirmIcon}
                    passwordIcon={passwordConfirmIcon}
                    className="passwordIconHide"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {" "}
              <Button
                className="cancel-hallowbtn me-3"
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
              <FormikSubmitButton
                isSubmitting={isSubmitting}
                className="updatebtndanger "
                size="md"
              >
                <span className="SignText">
                  Update Password {loader && <span className="loader"></span>}
                </span>
              </FormikSubmitButton>
            </div>
          </Form>
        )}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </SettingsLayout>
  );
};

export default AdminChangePassword;
