import { useState } from "react";
import Avatar from "react-avatar";
import { Button, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { api } from "../../utils/api";
import { customStyles } from "../../ui/CustomStyles";
import { useApprovals } from "./WorkflowProvider";
import { isMobileOnly } from "react-device-detect";
import { useContract } from "../Contracts/ContractProvider";

const Signer = ({
  setShowFailureToast,
  setBodyMessage,
  setSignerClick,
  setCounterpartyClick,
  setApprovalAddClick,
  setAddTemplateApprovers,
  setEstampclick,
  setNegotiationWorkFlowClick,
}: {
  setShowFailureToast: Function;
  setBodyMessage: Function;
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setApprovalAddClick: Function;
  setAddTemplateApprovers: Function;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
}) => {
  const [signer, setSigner] = useState([]);

  const [cc, setCC] = useState<any>();
  const { id } = useParams();
  const context = useApprovals();

  const contractContext = useContract();

  const handleSubmit = async () => {
    if (!signer || !id) return;
    const res = await api.addSigner(id, {
      signer: signer.map((user: any, index) => ({
        user_id: user?.value,
        sequence: context && context?.signeeList?.length + 1 + index,
        is_optional: false,
        cc: cc?.email,
      })),
    });
    if (res?.data?.success) {
      setApprovalAddClick(false);
      setAddTemplateApprovers(false);

      setSignerClick(false);
      setCounterpartyClick(false);
      context?.getContractDetails(id);
      contractContext?.refresh();

      setEstampclick(false);
      setNegotiationWorkFlowClick(false);
    } else if (!res?.data?.success) {
      setShowFailureToast(true);
      setBodyMessage(res?.data?.error?.[0]);
      setEstampclick(false);
      setNegotiationWorkFlowClick(false);
    }
  };

  return (
    <>
      <div>
        <div
          className=" d-flex  p-4 flex-column justify-content-between"
          style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
        >
          <div className="">
            <Stack className="my-2">
              <label className="mb-2 fw-semibold">Select a Signer</label>

              <UserAutocomplete
                isMulti
                placeholder="select a signer"
                onSelect={(data: any) => {
                  setSigner(data);
                }}
              />
            </Stack>

            <Stack className="my-2">
              <label className="mb-2 fw-semibold">CC</label>

              <UserAutocomplete
                placeholder="select a signer"
                onSelect={(data: any) => {
                  setCC(data);
                }}
              />
            </Stack>
          </div>
          <div className=" d-flex justify-content-end">
            <Button
              className="cancelbtn me-3 "
              size="sm"
              onClick={() => {
                setSignerClick(false);
                setCounterpartyClick(false);

                setApprovalAddClick(false);
                setAddTemplateApprovers(false);

                setEstampclick(false);
                setNegotiationWorkFlowClick(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="sharebtndanger"
              size="sm"
              onClick={handleSubmit}
              disabled={signer?.length == 0}
            >
              Add signer
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signer;

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="border-0">
        <div className="p-0">
          <div className=" d-flex align-items-center justify-content-between  ">
            <div className=" d-flex align-items-center">
              <div className="me-3">
                {props?.data?.avatar !== null &&
                props?.data?.avatar !== undefined ? (
                  <img
                    src={props?.data?.avatar}
                    alt=""
                    width={40}
                    className="form_img_collb"
                  />
                ) : (
                  <Avatar
                    name={props?.data?.label.charAt(0)}
                    className="profile-avatars"
                    size="30"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={props?.data?.label}
                  />
                )}
              </div>
              <div style={{ paddingInlineStart: ".7rem" }}>
                <h6 className="fw-semibold m-0 ">{props?.data?.label}</h6>
                <h6 className="fw-lighter m-0 ">{props?.data?.email}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

function UserAutocomplete({
  placeholder,
  onSelect,
  isMulti = false,
}: {
  isMulti?: boolean;
  placeholder: string;
  onSelect: Function;
}) {
  const context = useApprovals();

  const handleAutoUsers = async (params: any) => {
    try {
      let response = await api.getUsersAutocomplete(params);

      let res = response.map(({ name, id, email, roles, is_active }: any) => ({
        name,
        id,
        email,
        roles,
        is_active,
      }));
      return res;
    } catch (error) {}
  };

  return (
    <>
      <AsyncSelect
        classNamePrefix="custommultiselect"
        isMulti={isMulti}
        styles={customStyles}
        components={{ Option }}
        defaultOptions={context?.defaultApprovals
          ?.filter(
            (el: any) =>
              el?.roles?.[0] === "AUTHORIZED_SIGNATORIES" &&
              el?.is_active === "true"
          )
          .map((item: any) => ({
            label: item?.name,
            value: item?.id,
            email: item?.email,
            avatar: item?.avatar,
          }))}
        cacheOptions={false}
        loadOptions={async (search: string) => {
          const res = await handleAutoUsers({ search });
          const _categories = res
            ?.filter(
              (el: any) =>
                el?.roles?.[0] === "AUTHORIZED_SIGNATORIES" &&
                el?.is_active === "true"
            )
            .map((user: any) => ({
              label: user?.name,
              value: user?.id,
              email: user?.email,
              avatar: user?.avatar,
            }));
          return _categories;
        }}
        onChange={(e) => {
          onSelect(e);
        }}
        placeholder={placeholder}
      />
    </>
  );
}
