import { isMobileOnly } from "react-device-detect";
import BaseToast from "./BaseToast";
import { RxCross2 } from "react-icons/rx";

const DeleteToast = ({
  onSubmit,
  onClose,
  onReject,
  title,
  subTitle,
  closeText,
  submitText,
  loader,
  onCross,
  showThirdButton,
  showThirdButtonText,
  type,
}: {
  onSubmit: Function;
  onClose: Function;
  onReject?: Function;
  title: string;
  subTitle?: string;
  closeText?: string;
  submitText: string;
  loader?: boolean;
  onCross: Function;
  showThirdButton?: boolean;
  showThirdButtonText?: string;
  type?: string;
}) => {
  return (
    <>
      <BaseToast>
        {title === "Are you sure you want to sign this document?" && (
          <>
            <div className="d-flex justify-content-end">
              <RxCross2
                fontSize={20}
                style={{ cursor: "pointer" }}
                onClick={() => onCross()}
              />
            </div>
          </>
        )}

        <div className="deleteblock px-5 py-2">
          <div className="pt-3">
            <h4
              className={
                isMobileOnly
                  ? " fw-semibold text-center text-black fs-6"
                  : " fw-semibold text-center text-black"
              }
            >
              {/* <h5>{title}</h5> */}
              <h5 style={type === "suggestion" ? { textAlign: "left" } : {}}>
                {title}
              </h5>

              <h6 style={{ fontSize: "14px", color: "#a8aab7" }}>{subTitle}</h6>
            </h4>
          </div>
          <div
            className={`${
              isMobileOnly
                ? " d-flex align-items-center justify-content-center  py-3"
                : " d-flex align-items-center justify-content-center py-3"
            }`}
          >
            {showThirdButton && (
              <>
                <h6
                  className={isMobileOnly ? " mx-2 my-0 p-2 " : " m-0  mx-2"}
                  style={{ color: "#4a48c8", cursor: "pointer" }}
                  onClick={() => onClose()}
                >
                  {showThirdButtonText}
                </h6>
              </>
            )}
            {closeText && (
              <>
                <span
                  className={
                    isMobileOnly ? "nobtn mx-2 my-0 p-2 " : "nobtn m-0  mx-2"
                  }
                  onClick={() =>
                    type === "suggestion" ? onReject?.() : onClose()
                  }
                >
                  {closeText}
                </span>
              </>
            )}

            <h6
              className={
                isMobileOnly ? "yesbtn mx-2 my-0 p-2 " : "yesbtn m-0  mx-2"
              }
              onClick={() => onSubmit()}
            >
              {loader ? (
                <>
                  {submitText}
                  <span
                    className="loader"
                    style={{ width: "20px", height: "20px" }}
                  ></span>
                </>
              ) : (
                <>{submitText}</>
              )}
            </h6>
          </div>
        </div>
      </BaseToast>
    </>
  );
};

export default DeleteToast;
