import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useAdminContext } from "./ContractProvider";
import { useParams } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import NoDataFound from "../Templates/NoDataFound";

function Contracts() {
  const context: any = useAdminContext();
  const [search, setSearch] = useState("");
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);
  const { accountId: id }: any = useParams();

  const noData = (department: string) => {
    if (department) {
      return department;
    } else {
      return <span className="text-align-center">{"-"}</span>;
    }
  };

  const columns: any = [
    {
      name: "Contract name",
      minWidth: "250px",
      selector: (row: any) => row?.name,
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Department",
      width: "140px",
      selector: (row: any) => noData(row?.department),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Category",
      width: "300px",
      selector: (row: any) => noData(row?.category),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Updated at",
      width: "210px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },

    // {
    //   name: "Actions",
    //   width: "130px",
    //   grow: 0,
    //   reorder: true,
    //   sortable: true,
    //   cell: () => {
    //     return (
    //       <div className="pt-2 Actions">
    //         <Dropdown drop="end">
    //           <Dropdown.Toggle
    //             variant="success"
    //             className="Actions_title"
    //             id="dropdown-basic"
    //           >
    //             View
    //           </Dropdown.Toggle>
    //         </Dropdown>
    //       </div>
    //     );
    //   },
    // },
  ];

  // const params = {
  //   search: search ? search : null,
  //   limit: limit,
  //   page: page,
  //   sort: "-createdAt",
  // };

  useEffect(() => {
    context?.handleGetContract(id, {
      search: search ? search : null,
      limit: limit,
      page: page,
      sort: "-createdAt",
    });
  }, [search, limit, page]);
  return (
    <>
      <div className="d-flex pt-2 px-4">
        <div className="pt-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch className="search-icon-css" />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
            />
          </InputGroup>
        </div>
      </div>
      <div className="d-flex pt-4  px-4">
        <h6 className="contract-title"> All Contracts</h6>
      </div>
      {context?.loading ? (
        <div className="d-flex justify-content-center">Loading...</div>
      ) : (
        <div className=" mb-5 px-4">
          <div className=" mt-4 "></div>
          {context?.recentContracts?.length > 0 ? (
            <DataTable
              className="data-table"
              columns={columns}
              selectableRows
              selectableRowsHighlight
              data={context?.recentContracts}
              highlightOnHover
              progressComponent={<>Loading...</>}
              paginationDefaultPage={
                parseInt(context?.metaData?.page?.toString()) || 1
              }
              onChangeRowsPerPage={(currentRowsPerPage: number) => {
                setLimit(currentRowsPerPage);
              }}
              onChangePage={(page: number) => {
                setPage(page);
                setLimit(10);
              }}
              pagination
              paginationServer
              paginationPerPage={10} // Set the number of rows per page
              paginationTotalRows={context?.metaData?.total_count}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      )}
    </>
  );
}

export default Contracts;
