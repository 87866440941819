import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { ResponsiveLine } from "@nivo/line";

const SignedLineChart = ({ filterData }: { filterData: boolean }) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [[], Function] = useState([]);

  const LineChartDataDetails = async () => {
    try {
      let response = await context?.signedChartDataDetails(
        filterData ? filterData : ""
      );

      const chartData = [
        {
          id: "counts",
          data: response?.data?.map(({ days, count }: any) => ({
            x: days,
            y: count,
          })),
        },
      ];

      if (response?.success) {
        setChartData(
          response?.data?.reduce(
            (sum: any, countItem: any) => sum + countItem?.count,
            0
          ) > 0
            ? chartData
            : []
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    LineChartDataDetails();
  }, [filterData]);

  return <>{<LineChartGraph data={chartData} />}</>;
};

export default SignedLineChart;

const LineChartGraph = ({ data }: { data: any }) => {
  return (
    <div style={{ width: "100%", height: "532px" }}>
      {data?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <>
          <div style={{ height: "390px" }}>
            <ResponsiveLine
              data={data}
              margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                legend: "Days",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                legend: "Count",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              colors={{ scheme: "category10" }}
              lineWidth={2}
              pointSize={8}
              pointBorderWidth={2}
              pointBorderColor={{ from: "color" }}
              pointLabelYOffset={-12}
              enablePointLabel={true}
              enableArea={true}
              animate={true}
              useMesh={true}
              tooltip={({ point }: any) => (
                <div>
                  <strong>{point.serieId}</strong>
                  <br />
                  {point.data.x}: {point.data.y}
                </div>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};
