import { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { isMobileOnly, isTablet } from "react-device-detect";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";
import { MemberContextInterface, useMember } from "../MemberProvider";
import { AiOutlineSearch } from "react-icons/ai";
import { Member } from "../../../lib/Member";

import InviteMember from "../InviteMember";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

function MemberHeader({
  params,
  setShowSuccessToast,
  setShowFailureToast,
  setBodyMessage,
  getListMembers,
  searchText,
  setSearchText,
}: any) {
  const navigate = useNavigate();
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const context = useMember();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();

  useEffect(() => {
    let role: any =
      authContext?.currentUser?.roles?.[0] === "Admin"
        ? "ADMIN"
        : authContext?.currentUser?.roles?.[0];

    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.MEMBERS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  const createDocuments = async () => {
    try {
      setLoader(true);
      const member: Member | null | undefined = null;
      if (member) {
        navigate(generatePath(routes.members, { id: member }));
      }
    } catch (error) {}
  };
  const onSearchClick = async () => {
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
  };
  const queryParams = new URLSearchParams(window.location.search);

  const [filterParams, setFilterParams]: any = useState(
    queryParams
      ? {
          department: queryParams.get("department") || "",
        }
      : params
  );
  return (
    context && (
      <>
        {isTablet && (
          <TabletContractHeader
            context={context}
            createDocuments={createDocuments}
            loader={loader}
            onSearchClick={onSearchClick}
            setSearchText={setSearchText}
            searchText={searchText}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            roles={roles}
          />
        )}
        {isMobileOnly && (
          <MobileContractHeader
            createDocuments={createDocuments}
            loader={loader}
            context={context}
            onSearchClick={onSearchClick}
            setSearchText={setSearchText}
            searchText={searchText}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            roles={roles}
          />
        )}
        {!isTablet && !isMobileOnly && (
          <DesktopContractHeader
            setSearchText={setSearchText}
            context={context}
            searchText={searchText}
            createDocuments={createDocuments}
            loader={loader}
            onSearchClick={onSearchClick}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            roles={roles}
          />
        )}
      </>
    )
  );
}

export default MemberHeader;

const TabletContractHeader = ({
  context,
  onSearchClick,
  setSearchText,
  searchText,
  setShowSuccessToast,
  setShowFailureToast,
  setBodyMessage,
  getListMembers,
  roles,
}: {
  context: MemberContextInterface;
  createDocuments: Function;
  loader: boolean;
  onSearchClick: Function;
  setSearchText: Function;
  searchText: string;
  filterParams: any;
  setFilterParams: any;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  getListMembers: Function;
  roles: any;
}) => {
  const navigate = useNavigate();
  const authContext = useAuthentication();
  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      navigate(routes?.templateFiltersUrl({ search: searchText }));
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    }
  };
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="p-2">
          {authContext?.currentUser?.roles?.find(
            (el: any) => el === "ADMIN"
          ) === "ADMIN" && (
            <>
              {roles === "CREATE" && (
                <>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3"
                      onClick={() => {
                        setShow(!show);
                      }}
                      id="dropdown-basic"
                      disabled={
                        authContext?.currentUser?.roles?.find(
                          (el: any) => el === "ADMIN"
                        ) !== "ADMIN"
                      }
                    >
                      Invite Member&nbsp;
                    </Dropdown.Toggle>
                  </Dropdown>
                </>
              )}
            </>
          )}
        </div>

        {show && (
          <InviteMember
            onHide={() => {}}
            show={show}
            setShow={setShow}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            setIsResendInvite={() => {}}
            isResendInvite={false}
          />
        )}
      </div>
      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Templates"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                setSearchText(e?.target?.value);
              }}
              onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
    </>
  );
};

const MobileContractHeader = ({
  context,
  onSearchClick,
  setSearchText,
  searchText,
  setShowSuccessToast,
  setShowFailureToast,
  setBodyMessage,
  getListMembers,
  roles,
}: {
  createDocuments: Function;
  loader: boolean;
  context: MemberContextInterface;
  onSearchClick: Function;
  setSearchText: Function;
  searchText: string;
  filterParams: any;
  setFilterParams: any;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  getListMembers: Function;
  roles: any;
}) => {
  const [show, setShow] = useState(false);
  // const authContext = useAuthentication();

  const navigate = useNavigate();
  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      navigate(routes?.templateFiltersUrl({ search: searchText }));
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        {
          // authContext?.currentUser?.roles?.find((el: any) => el === "ADMIN") ===
          //   "ADMIN" &&
          <>
            {roles === "CREATE" && (
              <>
                <div className="p-2">
                  {}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3"
                      onClick={() => {
                        setShow(!show);
                      }}
                      id="dropdown-basic"
                      // disabled={
                      //   authContext?.currentUser?.roles?.find(
                      //     (el: any) => el === "ADMIN"
                      //   ) !== "ADMIN"
                      // }
                    >
                      Invite Member&nbsp;
                      {/* {loader ? <span className="loader"></span> : ""} */}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </>
            )}
          </>
        }

        {/* {show && (
          <InviteMember context={context} show={show} setShow={setShow} />
        )} */}
        {show && (
          <InviteMember
            onHide={() => {}}
            show={show}
            setShow={setShow}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            setIsResendInvite={() => {}}
            isResendInvite={false}
          />
        )}
      </div>
      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Templates"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                setSearchText(e?.target?.value);
              }}
              onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
      {/* <MobileTemplateFooter enumType={"TemplateSortEnum"} context={context} /> */}
    </>
  );
};

const DesktopContractHeader = ({
  setSearchText,
  context,
  searchText,
  onSearchClick,
  setShowSuccessToast,
  setShowFailureToast,
  setBodyMessage,
  getListMembers,
  roles,
}: {
  setSearchText: Function;
  context: MemberContextInterface;
  searchText: string;
  createDocuments: Function;
  loader: boolean;
  onSearchClick: Function;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  getListMembers: Function;
  roles: any;
}) => {
  // const navigate = useNavigate();
  const [show, setShow] = useState(false);
  // const authContext = useAuthentication();

  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      // navigate(routes?.templateFiltersUrl({ search: searchText }));
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    }
  };

  return (
    <div className="d-flex ">
      <div className="pt-2 flex-fill">
        <InputGroup className="br-4 search-css focus-input">
          <AiOutlineSearch
            className="search-icon-css"
            onClick={() => onSearchClick()}
          />
          <Form.Control
            id="doc_searchQueryInput1"
            placeholder="Search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            className="ps-3 fs-14 search-input"
            onChange={(e) => {
              setSearchText(e?.target?.value);
            }}
            onKeyDown={onSearchSubmit}
          />
        </InputGroup>
      </div>
      {/* <div className="pt-2">
        <DocumentSortBy context={context} enumType={"MemberSortEnum"} />
      </div> */}
      {/* <div className="pt-2">
        <MemberFilter
          params={context.filterParams}
          changeFilter={context.changeFilter}
          context={context}
        />
      </div> */}

      <div className="pt-2 bd-highlight">
        {
          // authContext?.currentUser?.roles?.find((el: any) => el === "ADMIN") ===
          //   "ADMIN" &&
          //  (
          <>
            {roles === "CREATE" && (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="new-contact-btn create-template contract-css ms-lg-3"
                    onClick={() => {
                      setShow(true);
                    }}
                    id="dropdown-basic"
                  >
                    Invite Member&nbsp;
                  </Dropdown.Toggle>
                </Dropdown>
              </>
            )}
          </>
          // )
        }

        {/* ------------------------------------------------------------------------ */}

        {show && (
          <InviteMember
            show={show}
            onHide={() => {}}
            setShow={setShow}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailureToast={setShowFailureToast}
            setBodyMessage={setBodyMessage}
            getListMembers={getListMembers}
            setIsResendInvite={() => {}}
            isResendInvite={false}
          />
        )}
      </div>
    </div>
  );
};
