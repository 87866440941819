import {
  useCallback,
  // useEffect,
  useMemo,
} from "react";
import { createEditor } from "slate";
import { Editable, withReact } from "slate-react";
import { Leaf } from "../../Editable/Leaf";
import { useEditor } from "../../EditorProvider";
import { Slate } from "slate-react";
import { RenderElement } from "../../Editable/Element";
import EditableVoidsExample from "../PageHeader/editable-voids";
import initialValue from "../../initialValue";

function Footer() {
  let type = "Footer";
  // const [showFooterEditingOption, setShowFooterEditingOption] = useState(false);
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editorContext = useEditor();

  return (
    <>
      <div
        contentEditable={false}
        title="Double click to Edit Footer"
        style={{
          display: "flex",
          height: "1px",
          width: "100%",
          backgroundColor: "transparent",
          justifyContent: "center",
        }}
        onDoubleClick={() =>
          editorContext?.setShowFooterEditingOption(!editorContext?.showFooterEditingOption)
        }
      >
        <Slate
          initialValue={initialValue}
          editor={editor}
          onChange={(e) => editorContext?.setFooterContent(e)}
        >
          <Editable
            renderElement={RenderElement}
            renderLeaf={renderLeaf}
            disabled={true}
            style={{ outline: "none" }}
          />
          {/* <span style={{ color: "#969998" }}>Double click to Edit Footer</span> */}
        </Slate>
      </div>
      {editorContext?.showFooterEditingOption && <EditableVoidsExample type={type} />}
    </>
  );
}

export default Footer;
