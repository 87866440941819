import { ICollaboratorResponse } from "../interfaces/response/ICollaboratorResponse";
import { IUserResponse } from "../interfaces/response/IUserResponse";
import { Collaborator } from "./Collaborator";

export class User {
  id: string;
  name: string;
  email: string;
  emailVerfied: boolean;
  contactNumber: string;
  department: string;
  organizationName: string;
  organizationId: string;
  collaborators: Collaborator[];
  location: string;
  sub: string;
  first_name: string;
  last_name: string;
  industry: string;
  job_title: string;
  roles: string[];
  avatar: string;

  constructor(params: IUserResponse) {
    this.id = params.id || params.sub;
    this.name = params.name;
    this.email = params.email;
    this.emailVerfied = params.email_verified;
    this.contactNumber = params.contact_number;
    this.department = params.department;
    this.organizationName = params.organization;
    this.organizationId = params.organization_id;
    this.location = params.location;
    this.sub = params.sub;
    this.first_name = params.first_name;
    this.last_name = params.last_name;
    this.industry = params.industry;
    this.job_title = params.job_title;
    this.roles = params.roles;
    this.avatar = params.avatar;

    this.collaborators =
      params?.collaborators?.map(
        (params: ICollaboratorResponse) => new Collaborator(params)
      ) || [];
  }
}
