import { Formik } from "formik";
import { useState } from "react";
import { Stack } from "react-bootstrap";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layouts/AuthLayout";
import FailureToast from "../../ui/FailureToast";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import SuccessToast from "../../ui/SuccessToast";
import { routes } from "../../utils/routes";
import { useAuthentication } from "./AuthenticationProvider";

interface SignInFormProps {
  email: string;
  password: string;
}
const SignInPage = () => {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);

  const context = useAuthentication();

  const initialValues: SignInFormProps = { email: "", password: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
        "Invalid email address"
      )
      // .email("Invalid email address")
      .required("Email address is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });

  const handleSubmit = async (
    values: any,

    {
      setSubmitting,
      validateForm,
    }: { setSubmitting: Function; validateForm: Function }
  ) => {
    const { email, password } = values;
    validateForm(values);
    const user = await context?.login(email, password);
    if (user) {
      setShowSuccessToast(true);
    } else {
      setShowFailureToast(true);
    }
    setSubmitting(false);
  };

  return (
    context && (
      <AuthLayout
        pageTitle="CLM - Sign In"
        title="Welcome!"
        subTitle="Sign in to your account"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {({ errors, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormikField
                  name="email"
                  type="email"
                  validationSchema={validationSchema}
                  label="Email ID"
                  errors={errors}
                  placeholder="Email ID"
                  autoFocus
                />
                <FormikField
                  name="password"
                  type={passwordIcon ? "text" : "password"}
                  validationSchema={validationSchema}
                  label="Password"
                  placeholder="Password"
                  errors={errors}
                  isPassword={true}
                  setPasswordIcon={setPasswordIcon}
                  passwordIcon={passwordIcon}
                  className="passwordIconHide"
                />
                <Stack className="my-3">
                  <Link
                    to={routes.forgetPassword}
                    className="text-decoration-none text-primary"
                  >
                    Forgot password?
                  </Link>
                </Stack>
                <div className=" d-flex justify-content-end">
                  <FormikSubmitButton
                    isSubmitting={isSubmitting}
                    className="my-3 w-100"
                    size="md"
                    submittingLabel={<span>Logging in...</span>}
                  >
                    <span className="SignText">Log in</span>
                  </FormikSubmitButton>
                </div>
              </form>
            );
          }}
        </Formik>
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message="Login Successful"
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
              // navigate(routes.root);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message="Invalid email or password."
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </AuthLayout>
    )
  );
};

export default SignInPage;
