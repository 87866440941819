import { Button, Form, Modal } from "react-bootstrap";
import { useFolders } from "../FoldersProvider";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { IFolderResponse } from "../../../interfaces/response/IFolderResponse";
import { Folder } from "../../../lib/Folder";

function RenameFolder({
  show,
  onHide,
  folderDetail,
  setShowFailureToast,
  setBodyMessage,
}: {
  show: boolean;
  onHide: Function;
  folderDetail?: IFolderResponse;
  setShowFailureToast: Function;
  setBodyMessage: Function;
}) {
  const context = useFolders();
  const { id } = useParams();
  const location = useLocation();
  const [documentName, setUpdatedName]: [string, Function] = useState("");

  const onUpdateName = async () => {
    try {
      let requestBody = {
        name: documentName,
      };
      let response = await context?.handleUpdateDocument(
        folderDetail?.id,
        requestBody
      );

      if (response?.data?.success === true) {
        let res = await context?.handleFolderById(
          id! ? id : context?.folderList?.id
        );
        if (res?.success) {
          if (location.pathname.includes("private")) {
            let privateList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PRIVATE";
              }
            );

            context?.setSubFolders(privateList);
          } else if (location.pathname.includes("public")) {
            let publicList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PUBLIC";
              }
            );
            context?.setSubFolders(publicList);
            context?.setFolderList(res?.folder);
          }

          onHide();
        }
      } else if (response?.data?.success === false) {
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
        onHide();
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal show={show} onHide={() => onHide} centered>
        <Modal.Header className="modal-header-bottom">
          <div className="pt-3">
            <h6 className=" fw-semibold modal-title-name">Rename</h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="col-md-12 col-12">
                <label className="input_label">Name</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={folderDetail?.name}
                  onChange={(e) => setUpdatedName(e?.target?.value)}
                  className="form-control"
                  placeholder="Name"
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
          <Button className="sharebtndanger" size="sm" onClick={onUpdateName}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RenameFolder;
