import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Stack } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import DocumentFilter from "../../../ui/DocumentFilter";
import DocumentSortBy from "../../../ui/DocumentSortBy";
import { api } from "../../../utils/api";
import { routes } from "../../../utils/routes";
import {
  ListTemplateContextInterface,
  ListTemplatesProvider,
  useTemplates,
} from "../../Templates/ListTemplatesProvider";
import { ProgressBar } from "react-bootstrap";
import { StatusEnum } from "../../../interfaces/StatusEnum";

function CreateContractPopup({
  show,
  onHide,
}: {
  show: boolean;
  onHide: Function;
}) {
  return (
    <>
      <ListTemplatesProvider>
        <TemplateLists show={show} onHide={onHide} />
      </ListTemplatesProvider>
    </>
  );
}

export default CreateContractPopup;

const TemplateLists = ({
  show,
  onHide,
}: {
  show: boolean;
  onHide: Function;
}) => {
  const context: ListTemplateContextInterface | null = useTemplates();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [searchText, setSearchText]: [string, Function] = useState("");

  // .... pagination
  const [currentPage]: [number | undefined, Function] = useState(1);
  const [onSelectTemplateId, setOnSelectTemplateId]: [string, Function] =
    useState("");

  const initialValue = {
    name: "Untitled Contract",
    description: "",
    template: onSelectTemplateId ? onSelectTemplateId : "",
  };

  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    }
  };

  const createContract = async () => {
    if (location.pathname.includes("overview")) {
      try {
        let requestBody = {
          name: "Untitled Contract",
          description: "",
          parent_contract: id,
          template: initialValue?.template,
          is_renewal: true,
        };
        let response = await api.createContract(requestBody);
        onHide();
        if (response?.data?.success) {
          navigate(
            generatePath(routes.editContract, {
              id: response?.data?.contract?.id,
            })
          );
        } else {
        }
      } catch (error) {}
    } else {
      try {
        let response = await api.createContract(initialValue);
        onHide();
        if (response?.data?.success) {
          navigate(
            generatePath(routes.editContract, {
              id: response?.data?.contract?.id,
            })
          );
        } else {
        }
      } catch (error) {}
    }
  };

  const onCancelPopup = async () => {
    onHide();
  };

  useEffect(() => {
    context?.changeFilter({
      ...context.filterParams,
      page: currentPage,
      limit: 50,
    });
  }, [currentPage]);

  return (
    <Modal show={show} size="xl">
      <Modal.Header closeButton onClick={() => onHide()}>
        <div>
          <h5 className="fw-semibold py-2 create-cont-from-temp">
            Create contract from templates
          </h5>
        </div>
      </Modal.Header>
      <Modal.Body className="px-4" style={{ background: "#fafafa" }}>
        <div>
          {isMobileOnly ? (
            <div className="pb-3">
              <Stack>
                <InputGroup className="br-4 search-css input-border">
                  <Form.Control
                    id="doc_searchQueryInput1"
                    placeholder="Search Templates"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="ps-3 fs-14 search-input "
                    onChange={(e: any) => {
                      setSearchText(e?.target?.value);
                    }}
                    onKeyDown={onSearchSubmit}
                  />
                </InputGroup>
              </Stack>
              <div className=" d-flex justify-content-between align-items-center flex-wrap mt-3">
                <div className=" ">
                  {context && (
                    <div className="create-contract-popup-filter ">
                      <DocumentSortBy
                        context={context}
                        enumType={"TemplateSortEnum"}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {context && (
                    <div className="create-contract-popup-filter ">
                      <DocumentFilter
                        params={context.filterParams}
                        changeFilter={context.changeFilter}
                        resetFilter={context.resetFilter}
                        categoriesAutocomplete={context.categoriesAutocomplete}
                        defaultCategories={context.defaultCategories}
                        context={context}
                        status={false}
                        isCreateContractPopup={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="pb-3 d-flex flex-wrap">
              <div className="flex-grow-1 mt-3">
                <InputGroup className="br-4 search-css input-border">
                  <Form.Control
                    id="doc_searchQueryInput1"
                    placeholder="Search Templates"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="ps-3 fs-14 search-input "
                    onChange={(e: any) => {
                      setSearchText(e?.target?.value);
                    }}
                    onKeyDown={onSearchSubmit}
                  />
                </InputGroup>
              </div>
              <div className="  d-flex align-items-center flex-wrap  mt-3 justify-content-end ">
                <div>
                  {context && (
                    <div className="create-contract-popup-filter ">
                      <DocumentSortBy
                        context={context}
                        enumType={"TemplateSortEnum"}
                      />
                    </div>
                  )}
                </div>

                <div>
                  {context && (
                    <div className="create-contract-popup-filter ">
                      <DocumentFilter
                        params={context.filterParams}
                        changeFilter={context.changeFilter}
                        resetFilter={context.resetFilter}
                        categoriesAutocomplete={context.categoriesAutocomplete}
                        defaultCategories={context.defaultCategories}
                        context={context}
                        status={false}
                        isCreateContractPopup={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <ul
            className="list-unstyled overflow-scroll m-0 p-0 "
            style={isMobileOnly ? { height: "35vh" } : {}}
          >
            <li className=" d-flex mb-5">
              {context?.recentTemplates
                ?.filter((template) => template?.status === "PUBLISHED")
                ?.map((template: any) => {
                  console.log(template, "template");

                  return (
                    <>
                      <div className=" me-3 ms-1">
                        <div
                          className={` py-1  `}
                          onDoubleClick={() => createContract()}
                        >
                          <div
                            className={` p-4 br-4  ${
                              template?.id === onSelectTemplateId
                                ? "isActiveCard"
                                : ""
                            }`}
                            style={{
                              minWidth: "300px",
                              background: "#fefefe",
                              boxShadow: " 0 0px 4px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={() => setOnSelectTemplateId(template?.id)}
                          >
                            <div
                              className="card-contract-name"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={template?.name}
                            >
                              {/* <span>{template?.name}</span> */}
                              <span>{template?.formattedName(20)}</span>
                            </div>
                            <div className=" py-2 d-flex justify-content-between align-items-center">
                              <div className="contract-team-name m-0 p-0">
                                <span className="pro-names p-0">
                                  {template?.department}
                                </span>
                              </div>
                            </div>

                            <div className=" card-signature mt-2 d-flex justify-content-start">
                              {template?.status === StatusEnum?.pending_approve
                                ? "PENDING APPROVAL"
                                : template?.status ===
                                  StatusEnum?.sign_initiated
                                ? "SIGN INITIATED"
                                : template?.status}
                            </div>
                            <div className="mb-2 row">
                              <div className=" col-12">
                                <ProgressBar
                                  now={
                                    template?.status === StatusEnum?.draft
                                      ? 0
                                      : template?.status ===
                                        StatusEnum?.published
                                      ? 100
                                      : template?.status ===
                                        StatusEnum?.pending_approve
                                      ? 50
                                      : template?.status ===
                                        StatusEnum?.approved
                                      ? 70
                                      : template?.status ===
                                        StatusEnum?.sign_initiated
                                      ? 85
                                      : template?.status === "COMPLETED"
                                      ? 100
                                      : 0
                                  }
                                  className={`progress-bar-css ${
                                    template?.status === StatusEnum?.draft
                                      ? "draft_bar"
                                      : template?.status ===
                                        StatusEnum?.published
                                      ? "publish_bar"
                                      : template?.status ===
                                        StatusEnum?.pending_approve
                                      ? "pending_approval_bar"
                                      : template?.status ===
                                        StatusEnum?.approved
                                      ? "approval"
                                      : template?.status ===
                                        StatusEnum?.sign_initiated
                                      ? "sign_bar"
                                      : template?.status === "COMPLETED"
                                      ? "complete_bar"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </li>
          </ul>
        </div>

        <div className=" d-flex justify-content-end align-items-center mt-2">
          <Button
            className={`me-3  px-3  fw-semibold  cancelbtn`}
            variant={"none"}
            size="sm"
            onClick={onCancelPopup}
          >
            Cancel
          </Button>
          <Button
            className=" updatebtndanger  fw-semibold"
            size="sm"
            disabled={
              // context?.recentTemplates &&
              context?.recentTemplates?.filter(
                (list) => list?.status === "PUBLISHED"
              )?.length === 0
            }
            onClick={() => createContract()}
          >
            Create contract
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
