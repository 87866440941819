import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor, Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";
import { slateDiff } from "../../editor/slate-diff-versions";
import { IUserResponse } from "../../interfaces/response/IUserResponse";
import { User } from "../../lib/User";
import { api } from "../../utils/api";
import { routes } from "../../utils/routes";
import { adminRoutes } from "../../utils/adminRoutes";
// import { withTable } from "../../editor/components/Table";
import { CustomEditor } from "../../editor/types/custom-types";
export interface AuthenticationContextInterface {
  [x: string]: any;
  currentUser: User | null;
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<User | null>;
  logout: () => void;
  updateProfileData: Function;
  getUser: Function;
  setCurrentUser: Function;
  getCurrentUser: Function;
  init: boolean;
  publichClick: boolean;
  setPublishClick: Function;
  setCurrentVersionId: Function;
  setDocumentId: Function;
  currentVersionId: string;
  documentId: string;
  getDiff: Function;
  setCurrentVersionValue: Function;
  setSelectedVersionValue: Function;
  setLoading: Function;
  editor: any;
  loading: boolean;
  currentVersionValue: any;
  selectedVersionValue: any;
  setIsGuestLoggedIn: Function;
  isGuestLoggedIn: boolean;
  handleUploadUserSignature: Function;
  handleGetUserSinature: Function;
  handleDeleteUserSignature: Function;
  handleUpdateFcmToken: Function;
  handleVerifyToken: Function;
  getConfigRoles: Function;
  getRolesDetails: Function;
  updateRoles: Function;
  setCompanyDetails: Function;
  companyDetails: any;
  getCompanyProfile: Function;
  departmentList: [];
  rolesAndAccess: any;
  forgetPassword: Function;
  resetPassword: Function;
  handleMemberBlock: Function;
  handleMemberUnBlock: Function;
}

const AuthContext = React.createContext<AuthenticationContextInterface | null>(
  null
);

type AuthenticationProviderProps = {
  children?: ReactNode;
};
const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const navigate = useNavigate();
  let authToken,
    authRefreshToken: string = "";

  try {
    authToken = localStorage.getItem("auth-token") || "";
  } catch {
    authToken = "";
  }
  try {
    authRefreshToken = localStorage.getItem("refresh-auth-token") || "";
  } catch {
    authRefreshToken = "";
  }

  const [token, setToken] = useState<string | null>(authToken);
  const [currentUser, setCurrentUser]: [User | null, Function] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [isLoggedIn, setIsLoggedIn]: [boolean, Function] = useState(false);
  const [refreshToken, setRefreshToken]: [string, Function] =
    useState(authRefreshToken);
  const [init, setInit]: [boolean, Function] = useState(false);
  const [publichClick, setPublishClick]: [boolean, Function] = useState(false);
  const [currentVersionId, setCurrentVersionId]: [string, Function] =
    useState("");
  const [documentId, setDocumentId]: [string, Function] = useState("");
  const [rolesAndAccess, setRolesAndAccess]: [any, Function] = useState();

  const location = useLocation();

  const logoutUser = () => {
    setCurrentUser(null);
    setIsLoggedIn(false);
    setToken(null);
    localStorage.removeItem("auth-token");
    localStorage.removeItem("refresh-auth-token");
    navigate(routes.signIn);
    return;
  };

  const getUser = async (): Promise<User> => {
    const res = await api.getUser();
    const user = new User(res as any);
    return user;
  };

  const getCompanyProfile = async (): Promise<any> => {
    const res = await api.getCompanyDetails();
    setCompanyDetails(res?.account);
    return res;
  };

  const getDepartments = async () => {
    const res = await api.getDepartments();
    setDepartmentList([...res?.data?.departments, "TECHNICIAN"]);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const getCurrentUser = useCallback(async () => {
    if (token) {
      try {
        const user = await getUser();
        if (user.emailVerfied) {
          setCurrentUser(user);
          setIsLoggedIn(true);
        } else {
          logoutUser();
        }
      } catch (e) {
        logoutUser();
      }
    }
  }, [token]);

  const handleMemberBlock = async (id: any) => {
    try {
      let response = await api?.memberBlock(id);
      return response;
    } catch (error) {}
  };

  const handleMemberUnBlock = async (id: any) => {
    try {
      let response = await api?.memberUnBlock(id);
      return response;
    } catch (error) {}
  };
  // ............................version history editor diff.............................
  const [currentVersionValue, setCurrentVersionValue]: any = useState();
  const [selectedVersionValue, setSelectedVersionValue]: any = useState();
  const [loading, setLoading] = useState(true);
  const [isGuestLoggedIn, setIsGuestLoggedIn]: [boolean, Function] =
    useState(false);

  const editor: CustomEditor = useMemo(
    () => withReact(withHistory(createEditor())),
    []
  );

  const printChanges = (item: any) => {
    // consol
    // const path = {
    //   anchor: { path: [0, 0], offset: 10 },
    //   focus: { path: [0, 0], offset: 15 },
    // };
    // Transforms.insertText(editor, "12345", {
    //   at: { path: [0, 0], offset: 10 },
    // });
    // Transforms.select(editor, Editor.range(editor, path));
    // Editor.addMark(editor, "bgColor", "#66b966");
    if (item.type === "insert_text") {
      console.log(item.offset, "chnages from the version history");
      console.log(item.path, "chnages from the version history");
      console.log(item.path, item.offset, "chnages from the version history");

      Transforms.insertText(editor, item.text, {
        at: { path: item.path, offset: item.offset },
      });
      const path = {
        anchor: { path: item.path, offset: item.offset + item?.text?.length },
        focus: { path: item.path, offset: item.offset + item?.text?.length },
      };
      Transforms.select(editor, Editor.range(editor, path));
      Editor.addMark(editor, "bgColor", "#66b966");
      // alert(item.text);
      // Editor.insertText(editor,"ra"{at:});

      // alert(item.offset);
      // alert(item.text.length);
      // alert(item.offset + item.text.length);
      // Transforms.select(editor, {
      //   anchor: { path: [item.path[0], item.path[1]], offset: item.offset },
      //   focus: {
      //     path: [item.path[0], item.path[1]],
      //     offset: item.offset + item.text.length - 1,
      //   },
      // });
      // alert(item.offset);
      // alert(item.text.length - 1);
      // Editor.addMark(editor, "bgColor", "#66b966");
    } else if (item.type === "remove_text") {
      const path = {
        anchor: { path: [0, 0], offset: item.offset },
        focus: { path: [0, 0], offset: item.offset },
      };
      Transforms.insertText(editor, item.text, {
        at: { path: item.path, offset: item.offset },
      });
      Transforms.select(editor, Editor.range(editor, path));
      Editor.addMark(editor, "bgColor", "#fc8b8b");
      Editor.addMark(editor, "strikeThrough", true);

      // Transforms.select(editor, {
      //   anchor: { path: [item.path[0], item.path[1]], offset: item.offset },
      //   focus: { path: [item.path[0], item.path[1]], offset: 15 },
      // });
      // Editor.addMark(editor, "bgColor", "#fc8b8b");
      // Editor.addMark(editor, "strikeThrough", true);
      // Editor.insertText(editor, item.text);
    } else if (item.type === "remove_node") {
      // return;
      Object.assign(item.node.children[0], { bgColor: "#fc8b8b" });
      Object.assign(item.node.children[0], { strikeThrough: true });
      console.log(item);
      // Transforms.insertNodes(editor, item.node, {
      //   at: { path: [item.path[0], 0], offset: 0 },
      // }) as any;

      Transforms.select(editor, {
        anchor: { path: [item.path[0], 0], offset: 0 },
        focus: {
          path: [item.path[0], 0],
          offset: item.node.children[0].text?.length,
        },
      });
      Editor.addMark(editor, "bgColor", "#fc8b8b");
      Editor.addMark(editor, "strikeThrough", true);
    } else if (item.type === "insert_node") {
      // alert(item.path+"insertNode")
      Object.assign(item.node.children[0], { bgColor: "#66b966" });
      Editor.insertNode(editor, item.node, {
        at: { path: [item.path[0], 0], offset: 0 },
        // at: item.path,
      });
    }
  };

  const getDiff = (selectedValue?: any, currentValue?: any) => {
    if (selectedValue && currentValue) {
      setTimeout(() => {
        let diff: any = slateDiff(selectedValue, currentValue);
        diff.map((item: any) => printChanges(item));
      }, 200);
    }
  };

  // ..............................profile section.........................
  const updateProfileData = async (params: IUserResponse) =>
    api.updateprofile(params);

  const handleUpdateFcmToken = async (params: any) =>
    api.UpdateDeviceToken(params);

  const handleVerifyToken = async (params: any) => api?.verifyToken(params);

  useEffect(() => {
    const nonAutRoutes = [
      routes.root,
      routes.forgetPassword,
      routes.resetPassword,
      routes.invitation,
      routes.signUp,
      routes?.privacyPolicy,
      adminRoutes.root,
      adminRoutes.login,
      adminRoutes.forgetPassword,
      adminRoutes.invitation,
      adminRoutes.resetPassword,
    ];

    if (init && token) {
      getCurrentUser();
    } else if (!nonAutRoutes.includes(location.pathname)) {
      if (!token) {
        logoutUser();
      }
    }

    setInit(true);
  }, [init, token]);

  const handleLogin = useCallback(
    async (username: string, password: string): Promise<User | null> => {
      const res = await api.signIn({ username, password });
      if (res.success) {
        setToken(res.token as string);
        localStorage.setItem("auth-token", res.token);
        setRefreshToken(res.refresh_token);
        localStorage.setItem("refresh-auth-token", res.refresh_token);
        const user = await getUser();
        if (user.emailVerfied) {
          setCurrentUser(user);
          setIsLoggedIn(true);
          handleUpdateFcmToken({
            fcm: localStorage.getItem("device-token"),
          });
        } else {
          logoutUser();
        }
        return user;
      }
      return null;
    },
    []
  );

  const forgetPassword = (params: any) => api.forgetPassword(params);

  const resetPassword = (params: any, token: string) =>
    api.resetPassword(params, token);

  const handleLogout = async () => {
    const response = await api.signOut({
      refresh_token: refreshToken,
    });
    if (response?.success) {
      navigate(routes.signIn);
      logoutUser();
    }
  };

  const handleGetUserSinature = () => api.getUserSignature();

  const handleUploadUserSignature = (params: any) =>
    api.uploadUserSignature(params);

  const handleDeleteUserSignature = async (params: any) =>
    api.removeMySignature(params);

  const getConfigRoles = async () => {
    const { success, role, modules, features } = await api.getUserRolesConfig();
    return { success, role, modules, features };
  };

  const getRolesDetails = async () => {
    const res = await api.getRolesDetails();
    if (res?.success) {
      setRolesAndAccess(res?.roles);
    }
    return res;
  };

  useEffect(() => {
    getRolesDetails();
  }, []);

  const updateRoles = async (params: any) => api.updateRoles(params);

  const value = useMemo(
    () => ({
      currentUser,
      isLoggedIn,
      login: handleLogin,
      logout: handleLogout,
      updateProfileData,
      getUser,
      setCurrentUser,
      getCurrentUser,
      init,
      publichClick,
      setPublishClick,
      setCurrentVersionId,
      setDocumentId,
      currentVersionId,
      documentId,
      getDiff,
      setSelectedVersionValue,
      setCurrentVersionValue,
      setLoading,
      editor,
      loading,
      currentVersionValue,
      selectedVersionValue,
      setIsGuestLoggedIn,
      isGuestLoggedIn,
      handleGetUserSinature,
      handleUploadUserSignature,
      handleDeleteUserSignature,
      handleUpdateFcmToken,
      getConfigRoles,
      getRolesDetails,
      updateRoles,
      handleVerifyToken,
      setCompanyDetails,
      companyDetails,
      getCompanyProfile,
      departmentList,
      rolesAndAccess,
      forgetPassword,
      resetPassword,
      handleMemberBlock,
      handleMemberUnBlock,
    }),
    [
      currentUser,
      isLoggedIn,
      handleLogout,
      handleLogin,
      companyDetails,
      departmentList,
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthentication = () => {
  return React.useContext(AuthContext);
};

export { AuthenticationProvider, useAuthentication };
