import AppHeader from "../../layouts/AppHeader";
import { useParams } from "react-router-dom";
import VersionHistoryHeader from "./VersionHistoryHeader";
import VersionRightPannel from "./VersionRightPannel";
import VersionHistoryEditorSection from "./VersionHistoryEditorSection";
import { useEffect, useState } from "react";
import { VersionProvider, useVersionHistory } from "./VersionHisoryProvider";
import { Version } from "../../lib/VersionHistory";

const VersionHistoryDeatils = () => {
  return (
    <>
      <VersionProvider>
        <VersionDtail />
      </VersionProvider>
    </>
  );
};

export default VersionHistoryDeatils;

const VersionDtail = () => {
  const context = useVersionHistory();
  const { versionId, documentId } = useParams();

  const [versionDetail, SetVersionDetail]: [Version | undefined, Function] =
    useState();

  const getVersionDetailById = async () => {
    try {
      let response = await context?.handleVersionById(documentId, versionId);

      if (response?.success) {
        SetVersionDetail(response?.document_version);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (versionId && documentId) {
      getVersionDetailById();
    }
  }, [versionId]);
  return (
    <div style={{ background: "#fefefe", height: "100vh" }}>
      <AppHeader>
        <div className="VersionHistoryDeatils " style={{ paddingTop: "60px" }}>
          <div className="d-flex">
            <div className=" flex-grow-1">
              <VersionHistoryHeader versionDetail={versionDetail} />

              <div className=" border-top"></div>
              <VersionHistoryEditorSection />
            </div>
            <VersionRightPannel />
          </div>
        </div>
      </AppHeader>
    </div>
  );
};
