import { ContractProvider } from "../../../Contracts/ContractProvider";
import HierarchyTree from "./HierarchyTree";

const Hierarchy = () => {
  return (
    <>
      <ContractProvider>
        <div className="hierarchy ">
          <div className=" d-flex align-items-center">
            <ContractHierarchy />
          </div>
        </div>
      </ContractProvider>
    </>
  );
};

export default Hierarchy;

const ContractHierarchy = () => {
  return (
    <>
      <HierarchyTree />
    </>
  );
};
