import { useCallback, useEffect, useState } from "react";
import { Button, Form, InputGroup, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import CurrencyVariableInput from "../../../components/variables/CurrencyVariableInput";
import DateVariableInput from "../../../components/variables/DateVariableInput";
import FloatVariableInput from "../../../components/variables/FloatVariableInput";
import TextVariableInput from "../../../components/variables/TextVariableInput";
import {
  VariablesProvider,
  getVariableType,
  useVariables,
} from "../../../components/variables/VariableProvider";
import { IVariableResponse } from "../../../interfaces/response/IVariableResponse";
import { api } from "../../../utils/api";
import SuccessToast from "../../../ui/SuccessToast";
import FailureToast from "../../../ui/FailureToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useTemplate } from "../TemplateProvider";
import { useContract } from "../../Contracts/ContractProvider";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

const SaveButton = ({
  setShowSuccessToast,
  setBodyMessage,
  loadData,
  variables,
}: {
  setShowSuccessToast: Function;
  setBodyMessage: Function;
  loadData: Function;
  variables: any;
}) => {
  const context = useVariables();
  const contractContext = useContract();
  const authContext = useAuthentication();
  const [isOwner, setIsOwner]: [boolean, Function] = useState(false);

  useEffect(() => {
    let editorAccess = contractContext?.data?.collaborators?.find(
      (el: any) => el?.access === "EDITOR"
    );

    let ownerAccess = contractContext?.data?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );
    if (
      ownerAccess?.user?.email === authContext?.currentUser?.email ||
      editorAccess?.user?.email === authContext?.currentUser?.email
    ) {
      setIsOwner(true);
    }
  }, [contractContext?.data?.collaborators]);

  return (
    <>
      {context && (
        <>
          {isOwner && (
            <>
              <Button
                className="savebtn"
                disabled={
                  (context?.canSave() && !isOwner) || variables?.length === 0
                }
                onClick={async () => {
                  await context.save();
                  setShowSuccessToast(true);
                  setBodyMessage("Variable updated.");
                  loadData();
                }}
              >
                Save
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

const Variables = ({
  isTemplate,
  documentId,
}: {
  isTemplate: boolean;
  documentId: string;
}) => {
  const templateContext = useTemplate();
  const contractContext = useContract();
  const context = isTemplate ? templateContext : contractContext;

  const [variables, setVariables]: [IVariableResponse[], Function] = useState(
    []
  );
  const [searchText, setSearchText]: [string, Function] = useState("");

  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const [searchParams] = useSearchParams();

  const [filter] = useState({
    sort: "-createdAt",
    limit: 10,
    page: searchParams.get("page") || 1,
  });

  const loadData = useCallback(async () => {
    const { success, variables } = await api.getDocumentVariables(
      documentId,
      filter
    );
    if (success) {
      setVariables(variables);
    }
  }, [documentId, filter]);

  useEffect(() => {
    loadData();
  }, [filter, loadData]);

  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      await context?.changeFilter({
        search: searchText,
      });
    }
  };

  return (
    <>
      <div
        className={`${isMobile ? "container-fluid position-relative " : " "}`}
      >
        <div
          className={`${isMobile ? " d-none" : "  d-flex align-items-center "}`}
        >
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <InputGroup className="br-4 search-css focus-input">
              <AiOutlineSearch className="search-icon-css" />
              <Form.Control
                id="doc_searchQueryInput1"
                placeholder="Search Variable"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="ps-3 fs-14 search-input"
                onChange={(e) => {
                  setSearchText(e?.target?.value);
                }}
                onKeyDown={onSearchSubmit}
              />
            </InputGroup>
          </div>
        </div>

        <div>
          <VariablesProvider documentId={documentId} variables={variables}>
            <>
              {variables?.length === 0 ? (
                <div className="mt-5 no_variable">No variables found</div>
              ) : (
                variables.map((variable: IVariableResponse) => (
                  <div className=" p-3 varibles_card  mt-4 row align-items-center mx-0">
                    <div className="col-lg-4 col-12">
                      <h6 className=" fw-semibold ">{variable.name}</h6>
                      <h6 className=" fw-light" color="#a8aab7">
                        {getVariableType(variable.data_type)?.text}
                        {variable.data_type === "DATE" &&
                          `- ${variable.format}`}
                        {variable.data_type === "ICURRENCY" &&
                          `- ${variable.currency_type}`}
                      </h6>
                    </div>
                    <div className="col-lg-8 col-12">
                      {variable.data_type === "FLOAT" && (
                        <FloatVariableInput variable={variable} />
                      )}
                      {variable.data_type === "STRING" && (
                        <TextVariableInput variable={variable} />
                      )}
                      {variable.data_type === "DATE" && (
                        <DateVariableInput variable={variable} />
                      )}
                      {variable.data_type === "ICURRENCY" && (
                        <CurrencyVariableInput variable={variable} />
                      )}
                    </div>
                  </div>
                ))
              )}
              <Stack
                direction="horizontal"
                className="justify-content-end my-3"
              >
                {
                  <SaveButton
                    setShowSuccessToast={setShowSuccessToast}
                    setBodyMessage={setBodyMessage}
                    loadData={loadData}
                    variables={variables}
                  />
                }
              </Stack>
            </>
          </VariablesProvider>
        </div>
      </div>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};

export default Variables;
