import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useAnalytics } from "./AnalyticsProvider";
import Select from "react-select";

const CustomAnalytics = ({
  setOpenCustom,
  setShowSuccessToast,
  setBodyMessage,
  setShowFailureToast,
  updatedValue,
  setIsUpdate,
  getMatricsData,
  openCustom,
}: {
  setOpenCustom: Function;
  setShowSuccessToast: Function;
  setBodyMessage: Function;
  setShowFailureToast: Function;
  updatedValue?: any;
  setIsUpdate: Function;
  getMatricsData: Function;
  openCustom: boolean;
}) => {
  const context = useAnalytics();
  const [isTabActive, setIsTabActive]: [string, Function] = useState("Summary");
  const [updatedList, setUpdatedList] = useState<any>();
  const [checkedItem, setCheckedItem] = useState("");
  const [isOptionUpdated, setOptionUpdated]: [boolean, Function] =
    useState(false);

  const [graphName, setGraphName]: [
    [{ name: any; type: any; is_enabled: boolean }],
    Function
  ] = useState([{ name: "", type: "", is_enabled: true }]);
  const [deleteGraphData, setdeleteGraphData]: [
    [{ name: any; type: any; is_enabled: boolean }],
    Function
  ] = useState([{ name: "", type: "", is_enabled: false }]);

  useEffect(() => {
    const filteredArray: any = graphName.filter((item) => item.name !== "");

    const uniqueArray: any = [];
    const seenNames: any = {};

    filteredArray.forEach((item: any) => {
      if (!seenNames[item?.name]) {
        uniqueArray.push(item);
        seenNames[item.name] = true;
      }
    });
  }, [graphName]);

  const [summaryGraphNames, setSummaryGraphName]: [any, Function] = useState([
    "Contract Type",
    "Signed Contracts",
    "Top 10 Contracts by Contract Value",
    "Average Contract Expiry Based on Each Type of Contract",
  ]);

  const handleUpdateMatrics = async () => {
    try {
      const filteredArray: any = graphName.filter((item) => item.name !== "");

      const uniqueArray: any = [];
      const seenNames: any = {};

      filteredArray.forEach((item: any) => {
        if (!seenNames[item?.name]) {
          uniqueArray.push(item);
          seenNames[item.name] = true;
        }
      });

      let requestBody = {
        metrics: uniqueArray,
      };

      let response = await context?.updateMatricsData(requestBody);

      if (response?.data?.success) {
        setShowSuccessToast(true);
        setBodyMessage("Graph updated.");
        setOptionUpdated(false);

        setOpenCustom(false);
        setIsUpdate(true);
        context?.getMatrics();
      } else if (response?.data?.success === false) {
        setShowFailureToast(true);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setOpenCustom(false);
  };

  const handleDelete = async () => {
    let requestBody = {
      metrics: updatedList,
    };

    try {
      let response = await context?.updateMatricsData(requestBody);
      if (response?.data?.success) {
        getMatricsData();
        setOptionUpdated(false);
        setOpenCustom(false);
        setIsUpdate(true);
        context?.getMatrics();
      }
    } catch (error) {}
  };

  function handleCustomizeData() {
    if (openCustom) {
      if (!isOptionUpdated) {
        handleUpdateMatrics();
      } else if (isOptionUpdated) {
        handleDelete();
      }

      setCheckedItem("");
      setSummaryGraphName([
        "Contract Type",
        "Signed Contracts",
        "Top 10 Contracts by Contract Value",
        "Average Contract Expiry Based on Each Type of Contract",
      ]);
    }
  }

  return (
    <div className=" py-3 ">
      <div className=" d-flex justify-content-between align-items-center border-bottom pb-4">
        <h6 className=" m-0 fw-semibold">Customise Dashboard</h6>
      </div>
      <div className=" d-flex mt-4 justify-content-between align-items-center flex-wrap">
        <h6
          className={`py-2 customAnalyticsbtn ${
            isTabActive === "Summary" ? "active" : ""
          }`}
          onClick={() => setIsTabActive("Summary")}
        >
          Summary
        </h6>
        <h6
          className={`py-2 customAnalyticsbtn ${
            isTabActive === "Turnaround Time" ? "active" : ""
          }`}
          onClick={() => setIsTabActive("Turnaround Time")}
        >
          Turnaround Time
        </h6>{" "}
        <h6
          className={`py-2 customAnalyticsbtn ${
            isTabActive === "Teams" ? "active" : ""
          }`}
          onClick={() => setIsTabActive("Teams")}
        >
          Teams
        </h6>{" "}
        <h6
          className={`py-2 customAnalyticsbtn ${
            isTabActive === " Contract Value" ? "active" : ""
          }`}
          onClick={() => setIsTabActive("Contract Value")}
        >
          Contract Value
        </h6>
      </div>
      <hr></hr>
      {isTabActive === "Summary" && (
        <SummaryGraphName
          updatedValue={updatedValue}
          setCheckedItem={setCheckedItem}
          checkedItem={checkedItem}
          summaryGraphNames={summaryGraphNames}
          setGraphName={setGraphName}
          graphName={graphName}
          setdeleteGraphData={setdeleteGraphData}
          deleteGraphData={deleteGraphData}
          setUpdatedList={setUpdatedList}
          updatedList={updatedList}
          setOptionUpdated={setOptionUpdated}
        />
      )}
      {isTabActive === "Turnaround Time" && (
        <TurnaroundTime
          updatedValue={updatedValue}
          setCheckedItem={setCheckedItem}
          checkedItem={checkedItem}
          setGraphName={setGraphName}
          graphName={graphName}
          setdeleteGraphData={setdeleteGraphData}
          deleteGraphData={deleteGraphData}
          setUpdatedList={setUpdatedList}
          updatedList={updatedList}
          setOptionUpdated={setOptionUpdated}
        />
      )}
      {isTabActive === "Teams" && (
        <TeamsGraphName
          updatedValue={updatedValue}
          setCheckedItem={setCheckedItem}
          checkedItem={checkedItem}
          setGraphName={setGraphName}
          graphName={graphName}
          setdeleteGraphData={setdeleteGraphData}
          deleteGraphData={deleteGraphData}
          setUpdatedList={setUpdatedList}
          updatedList={updatedList}
          setOptionUpdated={setOptionUpdated}
        />
      )}
      {isTabActive === "Contract Value" && (
        <ContractValueGraph
          updatedValue={updatedValue}
          setCheckedItem={setCheckedItem}
          checkedItem={checkedItem}
          setGraphName={setGraphName}
          graphName={graphName}
          setdeleteGraphData={setdeleteGraphData}
          deleteGraphData={deleteGraphData}
          setUpdatedList={setUpdatedList}
          updatedList={updatedList}
          setOptionUpdated={setOptionUpdated}
        />
      )}
      <div className=" d-flex justify-content-end align-items-center my-5">
        <Button
          className="cancelbtn me-3 "
          size="sm"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="sharebtndanger "
          size="sm"
          onClick={() => {
            handleCustomizeData();
          }}
        >
          Customise
        </Button>
      </div>
    </div>
  );
};

export default CustomAnalytics;

const SummaryGraphName = ({
  updatedValue,
  setCheckedItem,
  checkedItem,
  summaryGraphNames,
  setGraphName,
  graphName,
  setdeleteGraphData,
  deleteGraphData,
  setUpdatedList,
  updatedList,
  setOptionUpdated,
}: {
  setCheckedItem: Function;
  updatedValue: any;
  checkedItem: any;
  summaryGraphNames: any;
  setGraphName: Function;
  graphName: any;
  setdeleteGraphData: Function;
  deleteGraphData: any;
  setUpdatedList: Function;
  updatedList: any;
  setOptionUpdated: Function;
}) => {
  const context = useAnalytics();

  useEffect(() => {
    let selNames: any = [];
    if (updatedValue?.length > 0) {
      let names: any = updatedValue.map((item: any) => item.name);
      let sel: any = context?.summaryGraphNames?.map((item: any) => item);
      for (let i = 0; i < sel?.length; i++) {
        if (names.includes(sel[i])) {
          selNames.push(sel[i]);
        }
      }
    }
  }, [updatedValue]);

  function handleOnCheck(name: any) {
    if (checkedItem == "" || checkedItem != name) setCheckedItem(name);
    else setCheckedItem("");
  }

  useEffect(() => {
    if (updatedValue) {
      setUpdatedList(updatedValue);
    }
  }, [updatedValue]);

  function handleChangeIsEnabled(name: any) {
    const item = updatedList?.find((item: any) => item?.name === name);

    if (item) {
      item.is_enabled = !item.is_enabled;
    }
    const indexToRemove = updatedList.findIndex(
      (item: any) => item.name === name
    );

    if (indexToRemove !== -1) {
      updatedList.splice(indexToRemove, 1);

      updatedList.push(item);
    }
    setUpdatedList(updatedList);
  }

  return (
    <>
      {summaryGraphNames && updatedList && (
        <>
          {summaryGraphNames?.map((item: any) => {
            let selectedItem = updatedList?.find((items: any) => {
              return items?.name === item;
            });

            return (
              <div key={item}>
                <div className=" row py-3 border-bottom px-3 ">
                  <div className=" form-check col-md-6 col-12">
                    <input
                      type="checkbox"
                      checked={selectedItem?.is_enabled}
                      className="form-check-input"
                      name={item}
                      onChange={() => {
                        handleChangeIsEnabled(item);
                        setdeleteGraphData([
                          {
                            name: item,
                            type: selectedItem?.type,
                            is_enabled: false,
                          },
                          ...deleteGraphData,
                        ]);
                        setOptionUpdated(true);
                        handleOnCheck(item);
                      }}
                    />
                    <label
                      className="form-check-label ms-2 fw-semibold"
                      htmlFor=""
                    >
                      {item}
                    </label>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                    <div className="d-flex justify-content-end">
                      <div style={{ width: "150px" }}>
                        {item === "Contract Type" && (
                          <Select
                            defaultValue={
                              selectedItem?.name === item
                                ? {
                                    value: selectedItem?.type,
                                    label: selectedItem?.type,
                                  }
                                : null
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Pie",
                              "Donut",
                              "Horizontal Bar",
                              "Vertical Bar",
                              "Funnel",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        )}

                        {item === "Top 10 Contracts by Contract Value" && (
                          <Select
                            defaultValue={
                              selectedItem !== undefined
                                ? {
                                    value: selectedItem?.type,
                                    label: selectedItem?.type,
                                  }
                                : ""
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                              setCheckedItem(item);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Tree Map",
                              "Pie",
                              "Donut",
                              "Horizontal Bar",
                              "Vertical Bar",
                              // "Funnel",
                              "Scatter Plot",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        )}

                        {item ===
                          "Average Contract Expiry Based on Each Type of Contract" && (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                              setCheckedItem(item);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Diverging Stacked",
                              "Horizontal Bar",
                              "Vertical Bar",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        )}

                        {item === "Signed Contracts" && (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setCheckedItem(item);
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Tree Map",
                              "Circle Packing",
                              "Stacked Chart",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

const TurnaroundTime = ({
  updatedValue,
  checkedItem,
  setCheckedItem,
  setGraphName,
  graphName,
  setdeleteGraphData,
  deleteGraphData,
  setUpdatedList,
  updatedList,
  setOptionUpdated,
}: {
  updatedValue: any;
  checkedItem: any;
  setCheckedItem: Function;
  setGraphName: Function;
  graphName: any;
  setdeleteGraphData: Function;
  deleteGraphData: any;
  setUpdatedList: Function;
  updatedList: any;
  setOptionUpdated: Function;
}) => {
  const context = useAnalytics();
  function handleChangeIsEnabled(name: any) {
    const item = updatedList?.find((item: any) => item?.name === name);

    if (item) {
      item.is_enabled = !item.is_enabled;
    }
    const indexToRemove = updatedList.findIndex(
      (item: any) => item.name === name
    );

    if (indexToRemove !== -1) {
      updatedList.splice(indexToRemove, 1);

      updatedList.push(item);
    }
    setUpdatedList(updatedList);
  }

  useEffect(() => {
    let selNames: any = [];
    if (updatedValue?.length > 0) {
      let names: any = updatedValue.map((item: any) => item.name);
      let sel: any = context?.summaryGraphNames?.map((item: any) => item);
      for (let i = 0; i < sel?.length; i++) {
        if (names.includes(sel[i])) {
          selNames.push(sel[i]);
        }
      }
    }
  }, [updatedValue]);

  function handleOnCheck(name: any) {
    if (checkedItem == "" || checkedItem != name) setCheckedItem(name);
    else setCheckedItem("");
  }

  return (
    <>
      {context && updatedList && (
        <>
          {context?.turnaroundTimeGraphName?.map((item) => {
            let selectedItem = updatedList?.find((items: any) => {
              return items?.name === item;
            });

            return (
              <div>
                <div className=" row py-3 border-bottom px-3 ">
                  <div className=" form-check col-md-6 col-12">
                    <input
                      type="checkbox"
                      checked={selectedItem?.is_enabled}
                      className="form-check-input"
                      name={item}
                      onChange={() => {
                        handleChangeIsEnabled(item);
                        setdeleteGraphData([
                          {
                            name: item,
                            type: selectedItem?.type,
                            is_enabled: false,
                          },
                          ...deleteGraphData,
                        ]);
                        setOptionUpdated(true);
                        handleOnCheck(item);
                      }}
                    />
                    <label
                      className="form-check-label ms-2 fw-semibold"
                      htmlFor=""
                    >
                      {item}
                    </label>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                    <div className="d-flex justify-content-end">
                      <div style={{ width: "150px" }}>
                        {item === "Contract Cycle Time" ? (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Line",
                              "Horizontal Bar",
                              "Vertical Bar",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        ) : item === "Turnaround Time For Approvals" ? (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Tree Map",
                              "Horizontal Bar",
                              "Vertical Bar",
                              "Pie",
                              "Donut",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        ) : item === "Turnaround Time for Signed Contracts" ? (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={[
                              "Line",
                              "Horizontal Bar",
                              "Vertical Bar",
                            ]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        ) : item === "Days in Negotiation" ? (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={["Scatter Plot", "Circle Packing"]?.map(
                              (type) => ({
                                label: type,
                                value: type,
                              })
                            )}
                            classNamePrefix={"select-wrapper"}
                          />
                        ) : (
                          <Select
                            defaultValue={
                              selectedItem && {
                                value: selectedItem?.type,
                                label: selectedItem?.type,
                              }
                            }
                            onChange={(e: any) => {
                              setGraphName([
                                {
                                  name: item,
                                  type: e?.value,
                                  is_enabled: true,
                                },
                                ...graphName,
                              ]);
                            }}
                            placeholder={"Select value"}
                            options={["Pie", "Donut", "Bar"]?.map((type) => ({
                              label: type,
                              value: type,
                            }))}
                            classNamePrefix={"select-wrapper"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

const ContractValueGraph = ({
  updatedValue,
  checkedItem,
  setCheckedItem,
  setGraphName,
  graphName,
  setdeleteGraphData,
  deleteGraphData,
  setUpdatedList,
  updatedList,
  setOptionUpdated,
}: {
  updatedValue: any;
  checkedItem: any;
  setCheckedItem: Function;
  setGraphName: Function;
  graphName: any;
  setdeleteGraphData: Function;
  deleteGraphData: any;
  setUpdatedList: Function;
  updatedList: any;
  setOptionUpdated: Function;
}) => {
  const context = useAnalytics();

  function handleChangeIsEnabled(name: any) {
    const item = updatedList?.find((item: any) => item?.name === name);

    if (item) {
      item.is_enabled = !item.is_enabled;
    }
    const indexToRemove = updatedList.findIndex(
      (item: any) => item.name === name
    );

    if (indexToRemove !== -1) {
      updatedList.splice(indexToRemove, 1);

      updatedList.push(item);
    }
    setUpdatedList(updatedList);
  }

  useEffect(() => {
    let selNames: any = [];
    if (updatedValue?.length > 0) {
      let names: any = updatedValue.map((item: any) => item.name);
      let sel: any = context?.summaryGraphNames?.map((item: any) => item);
      for (let i = 0; i < sel?.length; i++) {
        if (names.includes(sel[i])) {
          selNames.push(sel[i]);
        }
      }
    }
  }, [updatedValue]);

  function handleOnCheck(name: any) {
    if (checkedItem == "" || checkedItem != name) setCheckedItem(name);
    else setCheckedItem("");
  }

  return (
    <>
      {context && (
        <>
          {context?.contractValueGraphName?.map((item) => {
            let selectedItem = updatedList?.find((items: any) => {
              return items?.name === item;
            });

            return (
              <div>
                <div className=" row py-3 border-bottom px-3 ">
                  <div className=" form-check col-md-6 col-12">
                    <input
                      type="checkbox"
                      checked={selectedItem?.is_enabled}
                      className="form-check-input"
                      name={item}
                      onChange={() => {
                        handleChangeIsEnabled(item);
                        setdeleteGraphData([
                          {
                            name: item,
                            type: selectedItem?.type,
                            is_enabled: false,
                          },
                          ...deleteGraphData,
                        ]);
                        setOptionUpdated(true);
                        handleOnCheck(item);
                      }}
                    />
                    <label
                      className="form-check-label ms-2 fw-semibold"
                      htmlFor=""
                    >
                      {item}
                    </label>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                    <div className="d-flex justify-content-end">
                      <div style={{ width: "150px" }}>
                        <Select
                          defaultValue={
                            selectedItem && {
                              value: selectedItem?.type,
                              label: selectedItem?.type,
                            }
                          }
                          onChange={(e: any) => {
                            setGraphName([
                              {
                                name: item,
                                type: e?.value,
                                is_enabled: true,
                              },
                              ...graphName,
                            ]);
                          }}
                          placeholder={"Select value"}
                          options={[
                            "Pie",
                            "Donut",
                            "Horizontal Bar",
                            "Vertical Bar",
                          ]?.map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          classNamePrefix={"select-wrapper"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

const TeamsGraphName = ({
  updatedValue,
  checkedItem,
  setCheckedItem,
  setGraphName,
  graphName,
  setdeleteGraphData,
  deleteGraphData,
  setUpdatedList,
  updatedList,
  setOptionUpdated,
}: {
  updatedValue: any;
  checkedItem: any;
  setCheckedItem: Function;
  setGraphName: Function;
  graphName: any;
  setdeleteGraphData: Function;
  deleteGraphData: any;
  setUpdatedList: Function;
  updatedList: any;
  setOptionUpdated: Function;
}) => {
  const context = useAnalytics();

  useEffect(() => {
    let selNames: any = [];
    if (updatedValue?.length > 0) {
      let names: any = updatedValue.map((item: any) => item.name);
      let sel: any = context?.summaryGraphNames?.map((item: any) => item);
      for (let i = 0; i < sel?.length; i++) {
        if (names.includes(sel[i])) {
          selNames.push(sel[i]);
        }
      }
    }
  }, [updatedValue]);

  function handleOnCheck(name: any) {
    if (checkedItem == "" || checkedItem != name) setCheckedItem(name);
    else setCheckedItem("");
  }

  function handleChangeIsEnabled(name: any) {
    const item = updatedList?.find((item: any) => item?.name === name);

    if (item) {
      item.is_enabled = !item.is_enabled;
    }
    const indexToRemove = updatedList.findIndex(
      (item: any) => item.name === name
    );

    if (indexToRemove !== -1) {
      updatedList.splice(indexToRemove, 1);

      updatedList.push(item);
    }
    setUpdatedList(updatedList);
  }

  return (
    <>
      {context && (
        <>
          {context?.teamsGraphName?.map((item) => {
            let selectedItem = updatedList?.find((items: any) => {
              return items?.name === item;
            });
            return (
              <div>
                <div className=" row py-3 border-bottom px-3 ">
                  <div className=" form-check col-md-6 col-12">
                    <input
                      type="checkbox"
                      checked={selectedItem?.is_enabled}
                      className="form-check-input"
                      name={item}
                      onChange={() => {
                        handleChangeIsEnabled(item);
                        setdeleteGraphData([
                          {
                            name: item,
                            type: selectedItem?.type,
                            is_enabled: false,
                          },
                          ...deleteGraphData,
                        ]);
                        setOptionUpdated(true);
                        handleOnCheck(item);
                      }}
                    />
                    <label
                      className="form-check-label ms-2 fw-semibold"
                      htmlFor=""
                    >
                      {item}
                    </label>
                  </div>
                  <div className="col-md-6 col-12 p-0">
                    <div className="d-flex justify-content-end">
                      <div style={{ width: "150px" }}>
                        <Select
                          defaultValue={
                            selectedItem && {
                              value: selectedItem?.type,
                              label: selectedItem?.type,
                            }
                          }
                          onChange={(e: any) => {
                            setGraphName([
                              {
                                name: item,
                                type: e?.value,
                                is_enabled: true,
                              },
                              ...graphName,
                            ]);
                          }}
                          placeholder={"Select value"}
                          options={[
                            "Pie",
                            "Donut",
                            "Horizontal Bar",
                            "Vertical Bar",
                          ]?.map((type) => ({
                            label: type,
                            value: type,
                          }))}
                          classNamePrefix={"select-wrapper"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
