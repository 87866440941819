import imageExtensions from "image-extensions";
import isUrl from "is-url";

import { Transforms } from "slate";
import {
  useSlateStatic,
  useSelected,
  useFocused,
  ReactEditor,
} from "slate-react";
import { css } from "@emotion/css";
import _ from "lodash";
import { CustomEditor } from "./types/custom-types";
import { Icon, Button } from "./components/components";
import { MdDelete, MdImage } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
type ImageElement = {
  type: "image";
  url: string;
  children: any;
};

export const withImages = (editor: CustomEditor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url: string | undefined | any = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertImage = (editor: CustomEditor, url: string) => {
  const text = { text: "" };
  const image: ImageElement | any = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
};

const renderTooltip = (props: any) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {_.capitalize(props)}
    </Tooltip>
  );
};

export const Image = ({ attributes, children, element }: any) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
        `}
      >
        <img
          src={element.url}
          className={css`
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? "0 0 0 3px #B4D5FF" : "none"};
          `}
          width={element?.width || ""}
          height={element?.height || ""}
        />
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={css`
            display: ${selected && focused ? "inline" : "none"};
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
          `}
        >
          <Icon>
            <MdDelete />
          </Icon>
        </Button>
      </div>
    </div>
  );
};

export const isValidURL = (url: string) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const InsertImageButton = () => {
  const editor = useSlateStatic();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("image")}
    >
      <Button
        onMouseDown={(event: any) => {
          event.preventDefault();
          const url: any = window.prompt("Enter the URL of the image:");
          // Ar per image URL the URL is supposed to end with .png or such image extension
          // which in most of the case doesnt match well
          if (url && !isValidURL(url)) {
            alert("Not a valid URL");
            return;
          }
          url && insertImage(editor, url);
        }}
      >
        <Icon size={28}>
          <MdImage className="text-dark" />
        </Icon>
      </Button>
    </OverlayTrigger>
  );
};

const isImageUrl = (url: string) => {
  if (!url || !isUrl(url)) {
    return false;
  }
  const ext: string | undefined = new URL(url).pathname.split(".").pop();
  return ext ? imageExtensions.includes(ext) : false;
};

export default Image;
