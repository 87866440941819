import "./CommentSidebar.css";
import {
  activeCommentThreadIDAtom,
  commentThreadIDsState,
  commentThreadsState,
} from "../utils/CommentState";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilStateLoadable, useRecoilValue } from "recoil";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import CommentRow from "./CommentRow";
import { Row, Dropdown } from "react-bootstrap";
import classNames from "classnames";

import { Editor, Path, Text, Transforms } from "slate";
import { getCommentThreadsOnTextNode } from "../utils/EditorCommnentUtils";
import { MdFilterList } from "react-icons/md";
import { useAuthentication } from "../../../../../modules/Authentication/AuthenticationProvider";
// import { useEditor as context } from "../../../../EditorProvider";
import { useSlate } from "slate-react";

export interface commentThread {
  id?: string | null | any;
  filterComments?: string;
  commentSearch?: string | undefined | any;
  setFilterComment?: Function | undefined | any;
  setCommentExist?: Function | undefined | any;
}

export default function CommentsSidebar() {
  const allCommentThreadIDs = useRecoilValue(commentThreadIDsState);
  const [filterComments, setFilterComment] = useState("ALLCOMMENTS");
  const [commentSearch, setCommentSearch] = useState("");
  const [commentExists, setCommentExist] = useState(true);

  return (
    <>
      {Array.from(allCommentThreadIDs).length >= 1 && (
        <div style={{ background: "#fefefe", minHeight: "60vh" }}>
          <>
            <Card className={"comments-sidebar p-0"}>
              <div className=" border-bottom p-3">
                <h6 className=" py-2 ">Comments</h6>
                <div className=" d-flex align-items-center">
                  <div className=" d-flex align-items-center flex-grow-1 pe-3">
                    <input
                      className="doc_searchQueryInput form-control"
                      type="text"
                      name="doc_searchQueryInput"
                      placeholder="Search.."
                      maxLength={50}
                      onChange={(e) => setCommentSearch(e.target.value)}
                    />
                  </div>
                  <div className=" Actions">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="Actions_title"
                        id="dropdown-basic"
                      >
                        <MdFilterList fontSize={24} color="#c5d0de" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="create-dropdown-css">
                        <>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("ONLYTOME")}
                          >
                            <span>Only to me</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("MYTEAM")}
                          >
                            <span>My Team</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("EXTERNAL")}
                          >
                            <span>External</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("YOURCOMMENTS")}
                          >
                            <span>Your comments</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("RESOLVEDCOMMENTS")}
                          >
                            <span>Resolved comments</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setFilterComment("ALLCOMMENTS")}
                          >
                            <span>All Comments</span>
                          </Dropdown.Item>
                        </>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <Card.Body
                className="p-0"
                style={{ height: "700px", overflow: "scroll" }}
              >
                {Array.from(allCommentThreadIDs)
                  .reverse()
                  .map((id) => (
                    <Row key={id}>
                      <Col>
                        <CommentThread
                          id={id}
                          filterComments={filterComments}
                          commentSearch={commentSearch}
                          setFilterComment={setFilterComment}
                          setCommentExist={setCommentExist}
                        />
                      </Col>
                    </Row>
                  ))}
                <>
                 
                  {commentExists === false && (
                    <div>No Comments Available</div>
                  )}
                </>
              </Card.Body>
            </Card>
          </>
        </div>
      )}
    </>
  );
}

function CommentThread({
  id,
  filterComments,
  commentSearch,
  setFilterComment,
  setCommentExist,
}: commentThread) {
  const editor = useSlate();
  const { comments, status }: any = useRecoilValue(commentThreadsState(id));
  const authContext = useAuthentication();

  const [activeCommentThreadID, setActiveCommentThreadID] = useRecoilState(
    activeCommentThreadIDAtom
  );
  const [shouldShowReplies, setShouldShowReplies] = useState(false);
  const onBtnClick = useCallback(() => {
    setShouldShowReplies(!shouldShowReplies);
  }, [shouldShowReplies, setShouldShowReplies]);

  const [, setThreadIds] = useRecoilStateLoadable(commentThreadIDsState);
  // const editorContext = context();

  useEffect(() => {
    const textNodesWithThread = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) => Text?.isText(n) && getCommentThreadsOnTextNode(n)?.has(id),
    });
    let textNodeEntry: any = textNodesWithThread.next().value;

    // Above u get the RefId as id
    // if the textNodeEntry is undefined
    // filter from the recoil
    // And hit the delete API
    if (textNodeEntry === undefined) {
      setThreadIds((prevState: any) => {
        const arrayFromSet = [...prevState];
        const filteredArray = arrayFromSet.filter((item) => item !== id);
        return new Set(filteredArray as any);
      });
    }
  }, [editor?.children]);

  const onClick = useCallback(() => {
    const textNodesWithThread = Editor.nodes(editor, {
      at: [],
      mode: "lowest",
      match: (n) => Text?.isText(n) && getCommentThreadsOnTextNode(n)?.has(id),
    });

    let textNodeEntry: any = textNodesWithThread.next().value;

    if (textNodeEntry === undefined) {
      return;
    }

    const allTextNodePaths = [];
    while (textNodeEntry != null) {
      allTextNodePaths.push(textNodeEntry[1]);
      textNodeEntry = textNodesWithThread.next().value;
    }

    allTextNodePaths.sort((p1, p2) => Path.compare(p1, p2));

    Transforms.select(editor, {
      anchor: Editor.point(editor, allTextNodePaths[0], { edge: "start" }),
      focus: Editor.point(
        editor,
        allTextNodePaths[allTextNodePaths.length - 1],
        { edge: "end" }
      ),
    });

    setActiveCommentThreadID(id);
  }, [editor, id, setActiveCommentThreadID]);

  if (comments.length === 0) {
    return null;
  }

  const [firstComment, ...otherComments] = comments;

  return (
    <>
      <Card
        body={true}
        className={classNames({
          "comment-thread-container border-0": true,
          "is-resolved": status === true,
          "is-active": activeCommentThreadID === id,
        })}
        
      >
        {filterComments?.length === 0 &&
          commentSearch?.length === 0 &&
          setFilterComment("ALLCOMMENTS")}
        {commentSearch?.length > 0 && setFilterComment("")}

        {commentSearch?.length > 0 &&
        comments[0]?.text
          .toLowerCase()
          .includes(commentSearch.toLowerCase()) ? (
          <div>
            <div onClick={onClick}>
              <CommentRow
                comment={firstComment}
                showConnector={false}
                background={true}
                showVerticalDots={false}
              />
            </div>
            <div className="  mt-2 ms-3 pb-3">
              {comments.length > 1 ? (
                <span className=" Replies fw-semibold" onClick={onBtnClick}>
                  {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                </span>
              ) : null}
            </div>

            {setCommentExist(true)}
          </div>
        ) : (
          <>
            {setCommentExist(false)}
          </>
        )}

        {filterComments === "ONLYTOME" ? (
          comments[0]?.authorID === authContext?.currentUser?.sub &&
          comments[0]?.access === "PRIVATE" && (
            <>
              <div onClick={onClick}>
                <CommentRow
                  comment={firstComment}
                  showConnector={false}
                  k={0}
                />
              </div>
              <div className="  mt-2 ms-3 pb-3">
                {comments.length > 1 ? (
                  <span className=" Replies fw-semibold" onClick={onBtnClick}>
                    {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                  </span>
                ) : null}
              </div>
            </>
          )
        ) : filterComments === "MYTEAM" ? (
          comments[0]?.access === "TEAMS" && (
            <>
              <div onClick={onClick}>
                <CommentRow
                  comment={firstComment}
                  showConnector={false}
                  k={0}
                />
              </div>
              <div className="  mt-2 ms-3 pb-3">
                {comments.length > 1 ? (
                  <span className=" Replies fw-semibold" onClick={onBtnClick}>
                    {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                  </span>
                ) : null}
              </div>
            </>
          )
        ) : filterComments === "EXTERNAL" ? (
          comments[0]?.access === "PUBLIC" && (
            <>
              <div onClick={onClick}>
                <CommentRow
                  comment={firstComment}
                  showConnector={false}
                  k={0}
                />
              </div>
              <div className="  mt-2 ms-3 pb-3">
                {comments.length > 1 ? (
                  <span className=" Replies fw-semibold" onClick={onBtnClick}>
                    {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                  </span>
                ) : null}
              </div>
            </>
          )
        ) : filterComments === "YOURCOMMENTS" ? (
          comments[0]?.authorID === authContext?.currentUser?.sub && (
            <>
              <div onClick={onClick}>
                <CommentRow
                  comment={firstComment}
                  showConnector={false}
                  k={0}
                />
              </div>
              <div className="  mt-2 ms-3 pb-3">
                {comments.length > 1 ? (
                  <span className=" Replies fw-semibold" onClick={onBtnClick}>
                    {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                  </span>
                ) : null}
              </div>
            </>
          )
        ) : filterComments === "RESOLVEDCOMMENTS" ? (
          comments[0]?.resolved && (
            <>
              <div onClick={onClick}>
                <CommentRow
                  comment={firstComment}
                  showConnector={false}
                  k={0}
                />
              </div>
              <div className="  mt-2 ms-3 pb-3">
                {comments.length > 1 ? (
                  <span className=" Replies fw-semibold" onClick={onBtnClick}>
                    {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                  </span>
                ) : null}
              </div>
            </>
          )
        ) : filterComments === "ALLCOMMENTS" ? (
          <>
            <div onClick={onClick}>
              <CommentRow comment={firstComment} showConnector={false} k={0} />
              {setCommentExist(true)}
            </div>
            <div className="  mt-2 ms-3 pb-3">
              {comments.length > 1 ? (
                <span className=" Replies fw-semibold" onClick={onBtnClick}>
                  {shouldShowReplies ? "Hide Replies" : "Show Replies"}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
        {shouldShowReplies
          ? otherComments.map((comment: any, index: any) => {
              return (
                <div className="ps-5" onClick={onClick}>
                  <CommentRow
                    key={index}
                    comment={comment}
                    showConnector={true}
                    background={
                      commentSearch?.length > 0 &&
                      comment?.text
                        .toLowerCase()
                        .includes(commentSearch.toLowerCase()) &&
                      true
                    }
                  />
                </div>
              );
            })
          : null}
      </Card>
    </>
  );
}
