import { CustomBaseElement, Transforms } from "slate";
import { jsx } from "slate-hyperscript";
// import { insertSuggestionThread } from "../components/Suggestions/utils/EditorSuggestionUtils";
// import { useCallback } from "react";
// import useAddSuggestionThreadCallback from "../components/comments/src/hooks/useAddCommentThreadCallback";
// import { useSetRecoilState } from "recoil";
// import { activeSuggestionThreadIDAtom } from "../components/Suggestions/utils/SuggestionState";

const ELEMENT_TAGS = {
  A: (el: any) => ({
    type: "link",
    url: el.getAttribute("href"),
    style: createStyleObject(el, true),
  }),
  BLOCKQUOTE: (el: any) => ({ type: "quote", style: createStyleObject(el) }),
  H1: (el: any) => ({ type: "heading-one", style: createStyleObject(el) }),
  H2: (el: any) => ({ type: "heading-two", style: createStyleObject(el) }),
  H3: (el: any) => ({ type: "heading-three", style: createStyleObject(el) }),
  H4: (el: any) => ({ type: "heading-four", style: createStyleObject(el) }),
  H5: (el: any) => ({ type: "heading-five", style: createStyleObject(el) }),
  H6: (el: any) => ({ type: "heading-six", style: createStyleObject(el) }),
  IMG: (el: any) => ({
    type: "image",
    url: el.getAttribute("src"),
    style: createStyleObject(el),
    width: el.getAttribute("width"),
    height: el.getAttribute("height"),
  }),
  UL: (el: any) => ({ type: "bulleted-list", style: createStyleObject(el) }),
  LI: (el: any) => ({ type: "list-item", style: createStyleObject(el) }),
  OL: (el: any) => ({ type: "numbered-list", style: createStyleObject(el) }),
  P: (el: any) => ({ type: "paragraph", style: createStyleObject(el) }),
  PRE: (el: any) => ({ type: "code", style: createStyleObject(el) }),
  TABLE: (el: any) => ({ type: "table", style: createStyleObject(el) }),
  TR: (el: any) => ({ type: "table-row", style: createStyleObject(el) }),
  TH: (el: any) => ({ type: "table-head", style: createStyleObject(el) }),
  TD: (el: any) => ({ type: "table-cell", style: createStyleObject(el) }),
  TBODY: (el: any) => ({ type: "table-body", style: createStyleObject(el) }),
  THEAD: (el: any) => ({ type: "table-header", style: createStyleObject(el) }),
  SUP: (el: any) => ({ type: "sup", style: createStyleObject(el) }),
  SUB: (el: any) => ({ type: "sub", style: createStyleObject(el) }),
  DIV: (el: any) => ({ type: "div", style: createStyleObject(el) }),
  NAV: (el: any) => ({ type: "nav", style: createStyleObject(el) }),
  CODE: (el: any) => ({ type: "code", style: createStyleObject(el) }),
  // SPAN: (el: any) => ({ type: "text", style: createStyleObject(el) }),
  COLGROUP: (el: any) => ({ type: "colgroup", style: createStyleObject(el) }),
  COL: (el: any) => ({ type: "col", style: createStyleObject(el) }),
};

const createStyleObject = (element: any, isApplyImportant?: boolean) => {
  let objectData: any = {};
  element
    .getAttribute("style")
    ?.split(";")
    ?.map((item: any) => item?.split(":"))
    ?.forEach((item: any) => {
      const [key, value] = item;
      if (key?.length > 0) {
        const formattedKey = key
          ?.trim()
          .split("-")
          .map((part: any, index: any) =>
            index > 0 ? part?.charAt(0)?.toUpperCase() + part?.slice(1) : part
          )
          .join("");
        objectData[formattedKey] =
          value?.replace(`"`, "")?.trim() +
          (isApplyImportant ? " !important" : "");
      }
    });
  return objectData || "";
};

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  // B: () => ({ bold: true }), preferably not to use B tag as it wraps the entire content with b
  U: () => ({ underline: true }),
  BODY: () => ({}),
  HR: () => ({ type: "horizontal-rule" }),
};

export const deserialize = (el: HTMLElement) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }
  const { nodeName } = el;

  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0]) {
    parent = el.childNodes[0] as HTMLElement;
  }

  let children: any = Array.from(parent.childNodes)
    .map(deserialize as any)
    .flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if ((ELEMENT_TAGS as any)[nodeName]) {
    const attrs = (ELEMENT_TAGS as any)[nodeName](el);
    return jsx("element", attrs, children);
  }

  if ((TEXT_TAGS as any)[nodeName]) {
    const attrs = (TEXT_TAGS as any)[nodeName](el);
    return children?.map((child: any) => jsx("fragment", attrs, child));
  }
  return children;
};

// const AddBoldProperty = (data: any,editor:any) => {
//   console.log(data, "ewdwedewdewdewdwededw");
//   alert("11");
//   console.log(editor,"dwedewdxewdxewdx")
//   alert("12");
//   const addSuggestionThread = useAddSuggestionThreadCallback();
//   alert("13");
//   const setActiveSuggestionThreadID = useSetRecoilState(
//     activeSuggestionThreadIDAtom
//   );
//   alert("14");
//   const onInsertSuggestion = useCallback(() => {
//     const newSuggestionThreadID: any = insertSuggestionThread(
//       editor,
//       addSuggestionThread
//     );
//     setActiveSuggestionThreadID(newSuggestionThreadID);
//   }, [editor, addSuggestionThread, setActiveSuggestionThreadID]);
//   alert("15");
//   alert("12");
//   console.log(
//     insertSuggestionThread(editor, onInsertSuggestion),
//     "insertSuggestionThread(editor, onInsertSuggestion)"
//   );
//   return data.map((paragraph: any) => {
//     return {
//       ...paragraph,
//       children: paragraph.children.map((child: any) => ({
//         ...child,
//         color: "green",
//         SuggestionAdd: true,
//       })),
//     };
//   });
// };

export function WithHtml(editor: any) {
  // export const WithHtml = (editor: any) => {

  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element: CustomBaseElement) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isVoid = (element: CustomBaseElement) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data: any) => {
    if (data.types.includes("application/x-slate-fragment")) {
      const html = data.getData("application/x-slate-fragment");
      if (html) {
        let atobConvSemiRaw = atob(html);
        var fullConvertedToSlateJs: any = decodeURIComponent(atobConvSemiRaw);
        let parsedJSONData = JSON.parse(fullConvertedToSlateJs);
        // if (suggession === "Editing") {
        // insertSuggestionThread(editor, onInsertSuggestion);
        Transforms.insertFragment(editor, parsedJSONData);
        // alert("1");
        // const outputData = AddBoldProperty(parsedJSONData,editor);
        // alert("2");
        // const strigifiedData = JSON.stringify(outputData, null, 2);
        // console.log(
        //   JSON.parse(strigifiedData),
        //   "strigifiedDatastrigifiedDatastrigifiedData"
        // );
        // Transforms.insertFragment(editor, JSON.parse(strigifiedData));
        // } else {
        //   const outputData = AddBoldProperty(parsedJSONData,editor);
        //   const strigifiedData = JSON.stringify(outputData, null, 2);
        //   console.log(
        //     JSON.parse(strigifiedData),
        //     "strigifiedDatastrigifiedDatastrigifiedData"
        //   );
        // }
        // alert("hai");
        // alert(selection);
        // console.log(selection, "selectionewdewdewdew");

        // // const path = {
        // //   anchor: { path: [7, 0], offset: 1 },
        // //   focus: { path: [7, 0], offset: 30 },
        // // };
        // // Transforms.select(editor, Editor.range(editor, path));
        // // Editor.addMark(editor, "color", "green");
        // // Editor.addMark(editor, "SuggestionAdd", true);
        // // Editor.insertText(editor, "TESTEST");

        // // alert("hai1");
        // let parsedJSONData = JSON.parse(fullConvertedToSlateJs);
        // const markRange = Editor.range(editor, parsedJSONData);
        // // Transforms.select(editor, { markRange } as any);
        // Transforms.select(editor, {
        //   anchor: Editor.start(editor, []),
        //   focus: Editor.end(editor, []),
        // })
        // console.log(parsedJSONData, "parsedJSONDataparsedJSONData");
        // console.log(markRange, "parsedJSONDataparsedJSONData");
        // Editor.addMark(editor, "bgColor", "#fc8b8b");
        // Editor.addMark(editor, "strikeThrough", true);
        // Transforms.insertFragment(editor, parsedJSONData);
        return;
      }
    } else {
      const html = data.getData("text/html");
      if (html) {
        // const parsed = new DOMParser().parseFromString(html, "text/html");
        // const fragment = deserialize(parsed.body);
        // Transforms.insertFragment(editor, fragment);
        return;
      }
    }

    insertData(data);
  };

  return editor;
}
