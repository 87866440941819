import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";
import { updateText } from "../../../utils";

function ContractRequiringApproval({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [graphData, setGraphData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  // const getMatrics = async () => {
  //   try {
  //     let response = await context?.getMatrics();

  //     if (response?.success) {
  //       setUpdatedMatricsList(response?.metrics);
  //     }
  //     return response;
  //   } catch (error) {}
  // };
  const handleContractRequiredApprovalData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleCountRequiringApproval(
        filterData ? filterData : ""
      );
      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[1] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          setNoData(false);
          const convertedArray = response?.data.map((item: any) => ({
            count: item?.count,
            name: updateText(`${item?.user?.name} ${item?.user?.last_name}`),
          }));

          setGraphData(convertedArray);
          // let res = await context?.getMatrics();
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );

          setGraphType(selectedGraphType?.type);
          setLoader(false);
        } else {
          setNoData(true);
          setLoader(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleContractRequiredApprovalData();
    // getMatrics();
    setUpdatedMatricsList(updatedValue);
  }, [isUpdate, updatedValue]);
  return (
    <>
      <div style={{ height: "390px" }}>
        {loader ? (
          <span>loading....</span>
        ) : (
          <>
            {updatedMatricsList?.length === 0 ? (
              <>
                {" "}
                {noData ? (
                  <AnalyticsNoDataFound />
                ) : (
                  <>
                    {graphType === "Horizontal Bar" ? (
                      <HorizontailBarChartData data={graphData} />
                    ) : graphType === "Pie" ? (
                      <PirChart data={graphData} />
                    ) : graphType === "Donut" ? (
                      <DonutChart data={graphData} />
                    ) : graphType === "Vertical Bar" ? (
                      <VerticalBarChartData data={graphData} />
                    ) : (
                      <HorizontailBarChartData data={graphData} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {updatedMatricsList?.filter(
                  (item: any) =>
                    item.name === title && item?.is_enabled === true
                )?.length === 0 ? (
                  <AnalyticsNoGraph />
                ) : (
                  <>
                    {noData ? (
                      <AnalyticsNoDataFound />
                    ) : (
                      <>
                        {graphType === "Horizontal Bar" ? (
                          <HorizontailBarChartData data={graphData} />
                        ) : graphType === "Pie" ? (
                          <PirChart data={graphData} />
                        ) : graphType === "Donut" ? (
                          <DonutChart data={graphData} />
                        ) : graphType === "Vertical Bar" ? (
                          <VerticalBarChartData data={graphData} />
                        ) : (
                          <HorizontailBarChartData data={graphData} />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ContractRequiringApproval;

const HorizontailBarChartData = ({ data }: { data: [] }) => {
  const CustomTooltip = ({ id, value }: any) => (
    <div style={{ padding: "12px", background: "#f0f0f0" }}>
      <strong>
        {id}:{value}
      </strong>
    </div>
  );

  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const dataWithRandomColors = data.map((item: any) => ({
    count: item?.count,
    name: item?.name,
    color: stringToHexColor(item?.name),
  }));

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={dataWithRandomColors}
          keys={["count"]}
          indexBy="name"
          margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
          padding={0.5}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Count",
            legendPosition: "middle",
            legendOffset: 42,
          }}
          groupMode="grouped"
          layout="horizontal"
          enableGridY={false}
          enableGridX={true}
          colors={{ datum: "data.color" }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -60,
            // legend: "Name",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          tooltip={CustomTooltip}
          theme={{
            axis: {
              legend: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  const CustomTooltip = ({ id, value }: any) => (
    <div style={{ padding: "12px", background: "#f0f0f0" }}>
      <strong>{id}</strong>
      <br />
      Count: {value}
    </div>
  );

  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const dataWithRandomColors = data.map((item: any) => ({
    count: item?.count,
    name: item?.name,
    color: stringToHexColor(item?.name),
  }));

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={dataWithRandomColors}
          keys={["count"]}
          indexBy="name"
          margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
          padding={0.5}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -25,
            // legend: "Name",
            legendPosition: "middle",
            legendOffset: 42,
          }}
          groupMode="grouped"
          enableGridY={false}
          enableGridX={true}
          colors={{ datum: "data.color" }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Count",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          tooltip={CustomTooltip}
          theme={{
            axis: {
              legend: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
};

const PirChart = ({ data }: { data: [] }) => {
  const transformedData: any = data.map((item: any) => ({
    id: item?.name,
    value: item?.count,
    color: getRandomColor(),
  }));

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={transformedData}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }) => (
            <div
              style={{
                padding: 12,
                background: "#fafafa",
              }}
            >
              <strong>{datum.id}</strong>
              <br />
              <strong>count: {datum.value}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: [] }) => {
  const transformedData: any = data.map((item: any) => ({
    id: item?.name,
    value: item?.count,
    color: getRandomColor(),
  }));

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={transformedData}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          innerRadius={0.6}
          tooltip={({ datum }) => {
            return (
              <>
                <div
                  style={{
                    padding: 12,
                    background: "#fafafa",
                  }}
                >
                  <strong>{datum.id}</strong>
                  <br />
                  <strong>count: {datum.value}</strong>
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
};
