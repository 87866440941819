import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";
import { ListContractContextInterface } from "../modules/Contracts/ListContractProvider";
import { ListFoldersContextInterface } from "../modules/Folders/FoldersProvider";
import { ListTemplateContextInterface } from "../modules/Templates/ListTemplatesProvider";
import CommonReactSelect from "./CommonReactSelect";

function Collaborator({
  context,
  setFilterParams,
  filterParams,
}: {
  context?:
    | ListContractContextInterface
    | ListTemplateContextInterface
    | ListFoldersContextInterface;
  setFilterParams: Function;
  filterParams: ITemplateFilterParams;
}) {
  return (
    <>
      <div className="row status-title mb-1 mt-3">Collaborators</div>

      <CommonReactSelect
        className={"select-wrapper"}
        options={context?.collaborators?.map((item: any) => ({
          label: item?.name?.charAt(0)?.toUpperCase() + item?.name.slice(1),
          value: item?.id,
        }))}
        values={[
          {
            label: filterParams?.collaboratorName || "",
            value: filterParams?.collaboratorName || "",
          },
        ]}
        updateField={(option: any) => {
          setFilterParams({
            ...filterParams,
            collaborators: option.value,
            collaboratorName: option.label,
          });
        }}
      />
    </>
  );
}

export default Collaborator;
