import { useState } from "react";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { useContract } from "../Contracts/ContractProvider";

function EsignPopup({
  show,
  onHide,
  setpdfPopup,
}: {
  show: boolean;
  onHide: Function;
  setpdfPopup: Function;
}) {
  const { id } = useParams();
  const context = useContract();
  const [documentSignatureWay, setDocumentSignatureWay]: [string, Function] =
    useState("Aadhaar");

  const onHandleSignature = async () => {
    let requestBody = {
      name: context?.data?.name,
      sign_type: documentSignatureWay,
    };
    try {
      let response = await api.updateContractName(id!, requestBody);

      if (response?.success) {
        setpdfPopup(true);
        onHide();
      }
    } catch (error) {}
  };
  return (
    <>
      <Modal show={show} centered onHide={() => onHide()}>
        <Modal.Body className="p-4">
          <Stack>
            <h5 className="fw-semibold py-2">Esign </h5>
          </Stack>
          <div className="">
            <Stack className="my-2">
              <label className="mb-2 fw-semibold">
                Add E-sign coordinates before starting the workflow.
                <br></br>
                <span style={{ color: "gray" }}>(Choose the E-sign type)</span>
              </label>
            </Stack>
            <Form.Group
              className="mt-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className=" d-flex justify-content-between">
                <div className="py-3">
                  <span>
                    <input
                      type="radio"
                      checked={documentSignatureWay === "e-Signature"}
                      className="form-check-input"
                      onChange={() => {
                        setDocumentSignatureWay("e-Signature");
                      }}
                    />
                    <span className="ps-2 me-3">E-Signature</span>
                  </span>
                  <span>
                    <input
                      type="radio"
                      className="form-check-input"
                      checked={documentSignatureWay === "Aadhaar"}
                      onChange={() => {
                        setDocumentSignatureWay("Aadhaar");
                      }}
                    />
                    <span className="ps-2 me-3">Aadhar</span>
                  </span>
                  <span>
                    <input
                      type="radio"
                      className="form-check-input"
                      checked={documentSignatureWay === "DSC"}
                      onChange={() => {
                        setDocumentSignatureWay("DSC");
                      }}
                    />
                    <span className="ps-2 me-3">DSC</span>
                  </span>
                </div>
              </div>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            size="sm"
            onClick={() => onHandleSignature()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EsignPopup;
