import "./SuggestedText.css";
import classNames from "classnames";
import { useSlate } from "slate-react";
// import { useAuthentication } from "../../../../../modules/Authentication/AuthenticationProvider";
import { CustomEditor } from "../../../types/custom-types";
import {
  getSmallestSuggestionThreadAtTextNode,
  // getSuggestionThreadsOnTextNode,
} from "../utils/EditorSuggestionUtils";
import { useRecoilState, 
  // useRecoilStateLoadable
 } from "recoil";
import {
  activeSuggestionThreadIDAtom,
  // suggestionThreadsState,
} from "../utils/SuggestionState";
// import { Editor } from "slate";
// import { Text } from "slate";

export default function SuggestedText(props: any) {
  const editor: CustomEditor = useSlate();
  // const authContext = useAuthentication();
  const { suggestionThreads, textNode, ...otherProps } = props;

  const [activeSuggestionThreadID, setActiveSuggestionThreadID] =
    useRecoilState(activeSuggestionThreadIDAtom);

  // const [threadDataLoadable] = useRecoilStateLoadable(
  //   suggestionThreadsState(
  //     getSmallestSuggestionThreadAtTextNode(editor, textNode)
  //   )
  // );

  const onClick = () => {
    // const textNodesWithThread = Editor.nodes(editor, {
    //   at: [],
    //   mode: "lowest",
    //   match: (n) =>
    //     Text?.isText(n) &&
    //     getSuggestionThreadsOnTextNode(n)?.has(
    //       "7bf65d60-4995-49fb-9312-1b6857a5aca9"
    //     ),
    // });
    // let textNodeEntry: any = textNodesWithThread.next().value;
    // console.log(textNodeEntry, "textNodesWithThread");
    setActiveSuggestionThreadID(
      getSmallestSuggestionThreadAtTextNode(editor, textNode)
    );
  };

  return (
    <>
      <span
        {...otherProps}
        className={classNames({
          suggestion: true,
          "is-active": suggestionThreads?.has(activeSuggestionThreadID),
        })}
        onClick={onClick}
      >
        {props.children}
      </span>
    </>
  );
}
