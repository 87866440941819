import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";

const PublishBarChart = ({ filterData }: { filterData: boolean }) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [any, Function] = useState();
  const ChartDataDetails = async () => {
    try {
      let response = await context?.publishBarDetails(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setChartData(
          response?.data?.reduce(
            (sum: any, countItem: any) => sum + countItem?.count,
            0
          ) > 0
            ? response?.data
            : []
        );
        // setChartData(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);

  return <> {chartData && <BarChart data={chartData} />}</>;
};

export default PublishBarChart;

function BarChart({ data }: any) {
  const CustomTooltip = (data: any) => {
    return (
      <div>
        <strong>{data.data?.days}</strong>: {data.value}
      </div>
    );
  };
  return (
    <div style={{ height: "400px" }}>
      {data?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <>
          <ResponsiveBar
            data={data}
            keys={["count"]}
            indexBy="days"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.2}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 45,
            }}
            groupMode="grouped"
            layout="vertical"
            enableGridY={false}
            enableGridX={true}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            theme={{
              axis: {
                legend: {
                  text: {
                    fontSize: 12,
                  },
                },
              },
            }}
            tooltip={CustomTooltip}
          />
        </>
      )}
    </div>
  );
}
