import {
  commentThreadIDsState,
  commentThreadsState,
} from "../utils/CommentState";

import { useRecoilCallback } from "recoil";

export default function useAddSuggestionThreadCallback() {
  return useRecoilCallback(
    ({ set }) => (id : any, threadData) => {
      set(commentThreadIDsState, (ids) => new Set([...Array.from(ids), id] as any));
      set((commentThreadsState(id) as any), threadData);
    } ,
    []
  );
}
