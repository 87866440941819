import { Badge, Dropdown, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "../../../utils/adminRoutes";
import { useAdminAccounts } from "./AdminAccountProvider";
import moment from "moment";
import { adminApi } from "../../../utils/adminApi";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import AccountFormModal from "./AccountFormModal";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import SuccessToast from "../../../ui/SuccessToast";
import FailureToast from "../../../ui/FailureToast";
import NoTemplateFound from "./NoDataFound";

function AccountLists() {
  const navigate = useNavigate();
  const context: any = useAdminAccounts();
  const [search, setSearch] = useState("");
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);

  const params = {
    search: search ? search : null,
    limit: limit && limit,
    page: page && page,
    sort: "-createdAt",
  };

  const blockAcount = async (id: any) => {
    const res: any = await adminApi.blockAccount(id);
    if (res?.data?.success) {
      await context?.getAccounts(params);
    }
  };

  const unblockAcount = async (id: any) => {
    const res: any = await adminApi.unBlockAccount(id);
    if (res?.data?.success) {
      await context?.getAccounts(params);
    }
  };

  useEffect(() => {
    context?.getAccounts({ page: page, limit: limit, search: search });
  }, [search, limit, page]);

  const columns = [
    {
      name: "Account name",
      selector: (row: any) => row.name,
    },
    {
      name: "Industry",
      selector: (row: any) => row.industry || "-",
    },
    {
      name: "Email ID",
      selector: (row: any) =>
        row.members.find((member: any) => member.is_primary === "true")?.email,
    },
    {
      name: "Team Members",
      selector: (row: any) => row.members.length || "-",
    },

    {
      name: "Total Contracts",
      selector: (row: any) => row.total_contracts || "-",
    },
    {
      name: "Total Templates",
      selector: (row: any) => row.total_templates || "-",
    },
    {
      name: "last login on",
      selector: (row: any) => {
        return (
          <span>
            {row?.last_login
              ? moment(row?.last_login).format("DD MMM YYYY, h:mm:ss A")
              : "-"}
          </span>
        );
      },
    },

    {
      name: "Status",
      width: "190px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <Badge
            className={
              row?.status === "ACTIVE"
                ? "complete-badge"
                : row?.status === "INVITED"
                ? "publish-badge"
                : "draft-badge"
            }
            bg="success"
          >
            {row?.status}
          </Badge>
        );
      },
    },
    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="pt-2 Actions">
            <Dropdown drop="end">
              <Dropdown.Toggle
                variant="success"
                className="Actions_title"
                id="dropdown-basic"
              >
                <BsThreeDots fontSize={24} />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                <Dropdown.Item
                  className="sort-by"
                  onClick={() =>
                    navigate(adminRoutes.urlAdminAccountView(row?.id))
                  }
                >
                  Edit
                </Dropdown.Item>
                {row?.status === "ACTIVE" && (
                  <Dropdown.Item
                    className="sort-by"
                    onClick={() => blockAcount(row?.id)}
                  >
                    Block
                  </Dropdown.Item>
                )}
                {row?.status === "BLOCKED" && (
                  <Dropdown.Item
                    className="sort-by"
                    onClick={() => unblockAcount(row?.id)}
                  >
                    UnBlock
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <AccountsHeader setSearch={setSearch} search={search} />
      <div className="d-flex mt-4 mb-1 mx-2 ps-3">
        <h6 className="contract-title pb-1 m-0"> All accounts</h6>
      </div>
      {context?.loading ? (
        <div className="d-flex justify-content-center">Loading...</div>
      ) : (
        <>
          {context?.accounts?.length > 0 ? (
            <div className="mt-4 ps-3  mx-2 px-3">
              <DataTable
                className="data-table"
                columns={columns}
                data={context?.accounts || []}
                selectableRows
                selectableRowsHighlight
                highlightOnHover
                paginationServer
                progressComponent={<>Loading...</>}
                paginationDefaultPage={
                  parseInt(context?.meta?.page?.toString()) || 1
                }
                onChangeRowsPerPage={(currentRowsPerPage: number) => {
                  setLimit(currentRowsPerPage);
                }}
                onChangePage={(page: number) => {
                  setPage(page);
                  setLimit(10);
                }}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30]}
                paginationTotalRows={context?.meta?.total_count}
              />
            </div>
          ) : (
            <NoTemplateFound />
          )}
        </>
      )}
    </>
  );
}

export default AccountLists;

function AccountsHeader({ setSearch, search }: any) {
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [show, setShow]: [boolean, Function] = useState(false);
  const context = useAdminAccounts();
  return (
    <>
      <div className="d-flex  px-4" style={{ paddingTop: "90px" }}>
        <div className="pt-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              onClick={() => {
                context?.setFilters({ ...context.filters, search });
              }}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </InputGroup>
        </div>
        <div className="pt-2 ">
          {/* <DocumentSortBy context={undefined} enumType={"ContractSortEnum"} /> */}
        </div>
        <div className="pt-2 ps-3">
          {/* <DocumentFilter
            params={undefined}
            changeFilter={() => {}}
            resetFilter={() => {}}
            categoriesAutocomplete={() => {}}
            defaultCategories={[]}
            context={undefined}
            status={true}
          /> */}
        </div>
        <>
          <div className="pt-2 bd-highlight">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="new-contact-btn create-template contract-css ms-lg-3"
                id="dropdown-basic"
                onClick={() => setShow(true)}
              >
                Add account
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          <AccountFormModal
            context={context}
            setShowSuccessToast={setShowSuccessToast}
            setBodyMessage={setBodyMessage}
            setShow={setShow}
            setShowFailureToast={setShowFailureToast}
            show={show}
          />
          {showSuccessToast && (
            <SuccessToast
              icon={
                <MdCheckCircle
                  className="text-success"
                  style={{ fontSize: "64px" }}
                />
              }
              message={bodyMessage}
              title={"Success!"}
              show={showSuccessToast}
              close={() => {
                setShowSuccessToast(false);
              }}
            />
          )}
          {showFailureToast && (
            <FailureToast
              message={bodyMessage}
              title={"Failure!"}
              show={showFailureToast}
              close={() => {
                setShowFailureToast(false);
              }}
              icon={
                <MdCancel
                  className="text-danger"
                  style={{ fontSize: "64px" }}
                />
              }
            />
          )}
        </>
      </div>
    </>
  );
}
