import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiFilter } from "react-icons/fi";
import AdminAnalyticsFilter from "./AdminAnalyticsFilter";
import { adminRoutes } from "../../../utils/adminRoutes";
import Select from "react-select";
import { customStyles } from "../../../ui/CustomStyles";

function AdminAnalyticsHeader({ setFilterData }: { setFilterData: Function }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCustom, setOpenCustom] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [statusValue, setSatusValue]: [any, Function] = useState({});
  const isActiveanalyticsSummary: boolean = !!matchPath(
    location.pathname,
    adminRoutes.adminAnalyticsSummary
  );

  const isActiveanalyticsContactValue: boolean = !!matchPath(
    adminRoutes.adminAnalyticsContracts,
    location.pathname
  );
  const isActiveanalyticsTurnaround: boolean = !!matchPath(
    adminRoutes.adminAnalyticsTurnaround,
    location.pathname
  );
  const isActiveanalyticsTeams: boolean = !!matchPath(
    adminRoutes.adminAnalyticsOrganisations,
    location.pathname
  );

  const status = [
    // { value: "1", label: "1 Days" },
    { value: "30", label: "30 Days" },
    { value: "60", label: "60 Days" },
    { value: "90", label: "90 Days" },
    { value: "120", label: "120 Days" },
    { value: "180", label: "180 Days" },
    { value: "365", label: "365 Days" },
  ];

  useEffect(() => {
    let filterData = {
      createdAt: statusValue?.option?.value || "30",
    };
    setFilterData(filterData);
  }, [statusValue]);

  return (
    <div className="border-bottom px-3" style={{ paddingTop: "90px" }}>
      <div className=" d-flex justify-content-between align-items-center ">
        <h6 className=" fw-semibold">Dashboard</h6>

        <div className=" d-flex">
          <div className=" filter-z  ms-3 ">
            <div className=" position-relative">
              <div className="filterdpr">
                <Select
                  styles={customStyles}
                  classNamePrefix="selectCustom"
                  className={` ${openCustom ? "active" : "inactive"}`}
                  value={
                    statusValue?.option?.label && statusValue?.option?.value
                      ? {
                          label: statusValue?.option?.label,
                          value: statusValue?.option?.value,
                        }
                      : {
                          label: "30 Days",
                          value: 30,
                        }
                  }
                  options={(status || [])?.map((status: any) => ({
                    label: status?.label,
                    value: status?.value,
                  }))}
                  onChange={(option: any) => {
                    setSatusValue({ option });
                  }}
                  placeholder="Select here"
                />
              </div>
            </div>
          </div>
          <div className=" filter-z d-none d-lg-block mx-3">
            <div className=" position-relative">
              <div
                onClick={() => {
                  setOpenFilter(!openFilter);
                  setOpenCustom(false);
                }}
                className={`m-0 px-3 py-2 mobileFilterSortName border ${
                  openFilter ? "active" : ""
                }`}
              >
                Filter{" "}
                <span className="ps-2">
                  <FiFilter fontSize={22} />
                </span>
              </div>
              <div
                className={`dropdownFilter adminFilter ${
                  openFilter ? "active" : "inactive"
                }`}
              >
                <AdminAnalyticsFilter
                  setOpenFilter={setOpenFilter}
                  setFilterData={setFilterData}
                  openFilter={openFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "scroll" }} className=" mt-1">
        <div className="d-flex mb-2" style={{ maxWidth: "450px" }}>
          <div style={{ minWidth: "90px" }}>
            <span
              className={` header-css me-4 ${
                isActiveanalyticsSummary ? " active" : ""
              }`}
              onClick={() => navigate(adminRoutes?.adminAnalyticsSummary)}
            >
              Summary
            </span>
          </div>
          <div
            style={{ minWidth: "95px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTeams ? " active" : ""
            }`}
            onClick={() => navigate(adminRoutes?.adminAnalyticsOrganisations)}
          >
            <span>Organizations</span>
          </div>
          <div
            style={{ minWidth: "60px" }}
            className={` header-css me-4 ${
              isActiveanalyticsContactValue ? " active" : ""
            }`}
            onClick={() => navigate(adminRoutes?.adminAnalyticsContracts)}
          >
            Contracts
          </div>
          <div
            style={{ minWidth: "120px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTurnaround ? " active" : ""
            }`}
            onClick={() => navigate(adminRoutes?.adminAnalyticsTurnaround)}
          >
            Turnaround Time
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAnalyticsHeader;
