import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { updateText } from "../../../../../utils/index";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { ResponsiveLine } from "@nivo/line";

const LineContractChart = ({ filterData }: { filterData: boolean }) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [[], Function] = useState([]);

  const LineChartDataDetails = async () => {
    try {
      let response = await context?.lineChartDataDetails(
        filterData ? filterData : ""
      );

      if (response?.success) {
        if (response?.data?.length > 0) {
          setChartData(response?.data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    LineChartDataDetails();
  }, [filterData]);

  return <>{<LineChartGraph data={chartData} />}</>;
};

export default LineContractChart;

const LineChartGraph = (data: any) => {
  const modifiedData = data?.data.map((entry: any) => ({
    id: entry.month,
    data: Object.entries(entry.count[0]).map(([key, value]) => ({
      x: updateText(key),
      y: value,
    })),
  }));

  return (
    <div style={{ width: "100%", height: "532px" }}>
      {data?.data?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <ResponsiveLine
          data={modifiedData}
          margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            legend: "Category",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            legend: "Count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          colors={{ scheme: "category10" }}
          lineWidth={2}
          pointSize={8}
          pointBorderWidth={2}
          pointBorderColor={{ from: "color" }}
          pointLabelYOffset={-12}
          enablePointLabel={true}
          enableArea={true}
          animate={true}
          useMesh={true}
          tooltip={({ point }: any) => (
            <div>
              <strong>{point.serieId}</strong>
              <br />
              {point?.data.x}: {point.data.y}
            </div>
          )}
        />
      )}
    </div>
  );
};
