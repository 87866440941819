import AppLayout from "../../layouts/AppLayout";
import CategoryHeader from "../Templates/components/CategoryHeader";
import ContractHeader from "./Components/ContractHeader";
import Contracts from "./Components/Contracts";
import NoListContractData from "./Components/NoListContractData";
import RecentContracts from "./Components/RecentContracts";
import {
  ListContractContextInterface,
  ListContractsProvider,
  useContract,
} from "./ListContractProvider";
import { useEffect, useState } from "react";
import { Contract } from "../../lib/Contract";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { FoldersProvider } from "../Folders/FoldersProvider";
import MoveToFolderPopup from "../Templates/components/MoveToFolderPopup";
import { IFolderResponse } from "../../interfaces/response/IFolderResponse";
import SuccessToast from "../../ui/SuccessToast";
import { MdCheckCircle } from "react-icons/md";

function ListContracts() {
  return (
    <>
      <AppLayout>
        <ListContractsProvider>
          <FoldersProvider>
            <NoContractsData />
          </FoldersProvider>
        </ListContractsProvider>
      </AppLayout>
    </>
  );
}

export default ListContracts;

const NoContractsData = () => {
  const context: ListContractContextInterface | null = useContract();
  const location = useLocation();
  const [moveToFolders, setMoveToFolders]: [boolean, Function] =
    useState(false);
  const [selectedValues, setSelectedValues]: [Contract[], Function] = useState(
    []
  );

  const [privateFolderList, setPrivateFolderList]: [Contract[], Function] =
    useState([]);
  const [publicFolderList, setPublicFolderList]: [Contract[], Function] =
    useState([]);

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);

  const [selectedId, setSelectedId]: [string, Function] = useState("");

  const onHandleRootFolder = async () => {
    try {
      let response = await context?.handleRootFolder();
      if (response?.folder?.sub_folders) {
        let privateList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PRIVATE";
          }
        );
        let publicFoldersList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PUBLIC";
          }
        );

        setPrivateFolderList(privateList);
        setPublicFolderList(publicFoldersList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    onHandleRootFolder();
  }, []);

  return (
    <>
      {!context?.recentContracts?.length ? (
        <>
          <ContractHeader setMoveToFolders={setMoveToFolders} />
          {context?.loader ? (
            <Loader />
          ) : (
            <NoListContractData search={location.search} />
          )}
        </>
      ) : (
        <>
          {!context?.recentContracts?.length ? (
            <NoListContractData />
          ) : (
            <>
              <ContractHeader setMoveToFolders={setMoveToFolders} />
              <RecentContracts
                setSelectedValues={setSelectedValues}
                moveToFolders={moveToFolders}
                selectedValues={selectedValues}
              />
              <div className="">
                <CategoryHeader />
                <Contracts
                  setMoveToFolders={setMoveToFolders}
                  setSelectedId={setSelectedId}
                />
              </div>
            </>
          )}
        </>
      )}
      {moveToFolders && (
        <MoveToFolderPopup
          show={moveToFolders}
          // selectedValues={selectedValues}
          privateFolderList={privateFolderList}
          publicFolderList={publicFolderList}
          setShowSuccessToast={setShowSuccessToast}
          setBodyMessage={setBodyMessage}
          onHide={() => setMoveToFolders(false)}
          setPrivateFolderList={setPrivateFolderList}
          setPublicFolderList={setPublicFolderList}
          onHandleRootFolder={onHandleRootFolder}
          context={context}
          selectedId={selectedId}
        />
      )}

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
    </>
  );
};
