import DataTable from "react-data-table-component";
import { useAdminListMembers } from "./ListAdminMembersProvider";
import { columns } from "./columns";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import MemberFormModal from "./memberFormModal";
import SuccessToast from "../../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../../ui/FailureToast";
import { AiOutlineSearch } from "react-icons/ai";
import { adminApi } from "../../../utils/adminApi";
import NoDataFound from "../Accounts/Templates/NoDataFound";
import DeleteToast from "../../../ui/DeleteToast";

const ListAdminMembers = () => {
  const context = useAdminListMembers();
  const [show, setShow]: [boolean, Function] = useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [id, setId] = useState(null);
  const [search, setSearch] = useState("");
  const [memberList, setMemberList]: [[], Function] = useState([]);
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);
  const [metaData, setMetaData]: [any, Function] = useState();
  const [alertToast, setAlertToast]: [any, Function] = useState();
  // const [membersDetail, setMembersDetail]: [any, Function] = useState();
  const [isResend, setIsResend]: [boolean, Function] = useState(false);
  const [activeUser, setActiveUser]: [any, Function] = useState();

  const handleLoggedInUser = async () => {
    try {
      let response = await adminApi?.currentAdmin();
      if (response) {
        setActiveUser(response);
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleLoggedInUser();
  }, []);

  // const resendinvitation = async (info: any) => {
  //   const response: any = await context?.reSendInviteMember(
  //     {
  //       email: info?.email,
  //       phone_number: info?.contact_number,
  //       firstName: info?.first_name,
  //       lastName: info?.last_name,
  //       roles: ["SUPERADMIN"],
  //       department: [info?.department],
  //     },
  //     info?.id
  //   );
  //   if (response?.success) {
  //     setShowSuccessToast(true);
  //     setBodyMessage("Invitation Resent");
  //   }
  // };

  const getListMembers = async () => {
    try {
      let response = await adminApi?.getMembers({
        search: search ? search : null,
        limit: limit ? limit : 10,
        page: page ? page : 1,
        sort: "-createdAt",
      });
      if (response?.success) {
        setMemberList(response?.users);
        setMetaData(response?.meta);
      }
    } catch (error) {}
  };

  const [bloackId, setBlockId]: [any, Function] = useState();
  const [unblockId, setUnBlockId]: [any, Function] = useState();

  const blockMember = async () => {
    const res: any = await adminApi.blockMember(bloackId);
    if (res?.data?.success) {
      getListMembers();
      setAlertToast(false);
    }
  };

  const unblockMember = async () => {
    const res: any = await adminApi.unBlockMember(unblockId);
    if (res?.data?.success) {
      getListMembers();
      setAlertToast(false);
    }
  };

  const handleSuccess = () => {
    getListMembers();
  };

  useEffect(() => {
    getListMembers();
  }, [search, limit, page]);
  return (
    context && (
      <div style={{ paddingTop: "90px" }}>
        <PageMenu
          context={context}
          show={show}
          setShow={setShow}
          id={id}
          setBodyMessage={setBodyMessage}
          setShowSuccessToast={setShowSuccessToast}
          setShowFailureToast={setShowFailureToast}
          setSearch={setSearch}
          search={search}
          onSuccess={handleSuccess}
          setId={setId}
          isResend={isResend}
          getListMembers={getListMembers}
          setIsResend={setIsResend}
        />
        {context?.loading ? (
          <div className="d-flex justify-content-center">Loading...</div>
        ) : (
          <>
            {memberList?.length > 0 ? (
              <div className="pt-4 ">
                <DataTable
                  columns={columns({
                    setId,
                    setShow,
                    setAlertToast,
                    setBlockId,
                    setUnBlockId,
                    setIsResend,
                    activeUser,
                  })}
                  data={memberList || []}
                  pagination={true}
                  paginationPerPage={metaData?.limit}
                  paginationTotalRows={metaData?.totalCount}
                  paginationServer={true}
                  paginationDefaultPage={
                    parseInt(context?.meta.page.toString()) || 1
                  }
                  onSort={(column, sortDirection) => {
                    context.setFilters({
                      ...context?.filters,
                      sort: `${
                        sortDirection === "desc" ? "-" : ""
                      }${column?.name?.toLocaleString()?.toLocaleLowerCase()}`,
                    });
                  }}
                  onChangeRowsPerPage={(currentRowsPerPage: number) => {
                    setLimit(currentRowsPerPage);
                  }}
                  onChangePage={(page: number) => {
                    setPage(page);
                    setLimit(10);
                  }}
                  highlightOnHover
                  responsive
                />
              </div>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {alertToast && (
          <>
            <DeleteToast
              onCross={() => {}}
              onSubmit={() => {
                if (bloackId) {
                  blockMember();
                } else {
                  unblockMember();
                }
              }}
              title={`Are you sure you want to ${
                bloackId ? "block" : "unblock"
              } this member?`}
              closeText="No"
              submitText="Yes"
              onClose={() => {
                setAlertToast(false);
              }}
            />
          </>
        )}
        {showFailureToast && (
          <FailureToast
            message={bodyMessage}
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
          />
        )}
      </div>
    )
  );
};

export default ListAdminMembers;

const PageMenu = ({
  context,
  show,
  setShow,
  id,
  setId,
  setShowSuccessToast,
  setBodyMessage,
  setShowFailureToast,
  setSearch,
  search,
  onSuccess,
  isResend,
  getListMembers,
  setIsResend,
}: any) => {
  return (
    context && (
      <>
        <div className=" d-flex">
          <div className="d-flex w-100">
            <div className="py-2 flex-fill">
              <InputGroup className="br-4 search-css focus-input">
                <AiOutlineSearch
                  className="search-icon-css"
                  onClick={() => {
                    context?.setFilters({ ...context.filters, search });
                  }}
                />
                <Form.Control
                  id="doc_searchQueryInput1"
                  placeholder="Search by Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="ps-3 fs-14 search-input"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  // onKeyDown={onSearchSubmit}
                />
              </InputGroup>
            </div>
          </div>

          <div className="pt-2 bd-highlight ms-2 ms-md-0">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="new-contact-btn create-template contract-css ms-lg-3"
                onClick={() => {
                  setId(null);
                  setShow(true);
                  setIsResend(false);
                }}
                id="dropdown-basic"
              >
                Invite Member&nbsp;
              </Dropdown.Toggle>
            </Dropdown>
          </div>

          <MemberFormModal
            id={id}
            setId={setId}
            context={context}
            setShowSuccessToast={setShowSuccessToast}
            setBodyMessage={setBodyMessage}
            setShow={setShow}
            setShowFailureToast={setShowFailureToast}
            show={show}
            onSuccess={onSuccess}
            isResend={isResend}
            getListMembers={getListMembers}
          />
        </div>
        <div className=" pt-3">
          <h6 className="contract-title"> All Members</h6>
        </div>
      </>
    )
  );
};
