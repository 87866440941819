import moment from "moment";

import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Avatar from "react-avatar";
import { Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Fragment, useEffect, useState } from "react";
import { useVersionHistory } from "./VersionHisoryProvider";
import { Version } from "../../lib/VersionHistory";
import VersionRenamePopup from "./VersionRenamePopup";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../ui/FailureToast";
import DeleteToast from "../../ui/DeleteToast";
import { IVersionHistoryResponse } from "../../interfaces/response/IVersionHistoryResponse";
import { routes } from "../../utils/routes";
import { useContract } from "../Contracts/ContractProvider";
import { useTemplate } from "../Templates/TemplateProvider";
import { useAuthentication } from "../Authentication/AuthenticationProvider";

export default function VersionDocumentCard({
  documentDetail,
  setDocumentDetail,
  getCurrentDocumentVersion,
  currentVersionDetail,
  headDocumentVersion,
  handleGetVersionHistory,
  setPreviousVersion,
  previousVersion,
  headDocumentId,
}: {
  documentDetail: Version;
  setDocumentDetail: Function;
  getCurrentDocumentVersion: Function;
  currentVersionDetail: IVersionHistoryResponse | undefined;
  headDocumentVersion: string;
  handleGetVersionHistory: Function;
  setPreviousVersion: Function;
  previousVersion: string;
  headDocumentId: string | undefined;
}) {
  const context = useVersionHistory();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const contractContext = useContract();
  const tempContext = useTemplate();
  const authContext = useAuthentication();

  const [versionRenamePopup, setversionRenamePopup]: [boolean, Function] =
    useState(false);
  const [currentDocument, setCurrentDocument]: [any, Function] = useState();

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [isRestoreActive, setIsRestoreActive]: [boolean, Function] =
    useState(false);
  const [isDocPublished, setIsDocPublished]: [boolean, Function] =
    useState(false);

  const handleDocument = async () => {
    try {
      let response = await context?.handleGetVersionDetail(
        documentDetail?.document
      );
      if (response) {
        setDocumentDetail(response?.document_versions);
      }
    } catch (error) {}
  };

  const handleRestoreVersion = async () => {
    try {
      let response = await context?.RestoreDocumentVersion(
        documentDetail?.document,
        documentDetail?.id
      );

      if (response) {
        handleDocument();
        setShowDeleteToast(false);
        setShowSuccessToast(true);
        setBodyMessage("Document restored.");
        handleGetVersionHistory();
      }
    } catch (error) {}
  };

  const handleDocumentById = async () => {
    try {
      await context?.handleVersionById(
        headDocumentId,
        documentDetail?.previous_version
      );
    } catch (error) {}
  };

  const handleCloneDocument = async () => {
    if (location.pathname.includes("contracts")) {
      try {
        let response = await context?.handleCloneVersionContract(
          documentDetail?.id
        );
        if (response?.status === 201) {
          setShowDeleteToast(false);
          setShowSuccessToast(true);
          setBodyMessage("Document cloned.");
          navigate(routes.contracts);
        } else {
          setShowDeleteToast(false);
          setShowFailureToast(true);
          setBodyMessage(response?.data?.error?.[0]);
        }
      } catch (error) {}
    } else if (location.pathname.includes("templates")) {
      try {
        let response = await context?.handleCloneVersionTemplate(
          documentDetail?.id
        );
        if (response?.status === 201) {
          setShowDeleteToast(false);
          setShowSuccessToast(true);
          setBodyMessage("Document cloned.");
          navigate(routes.templates);
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    if (documentDetail?.previous_version) {
      handleDocumentById();
    }
  }, []);

  useEffect(() => {
    if (documentDetail?.previous_version) {
      setPreviousVersion(documentDetail?.previous_version);
    }
  }, [documentDetail]);

  useEffect(() => {
    if (contractContext?.data?.status === "PUBLISHED") {
      setIsDocPublished(true);
    } else if (tempContext?.data?.status === "PUBLISHED") {
      setIsDocPublished(true);
    }
  }, [contractContext?.data?.workflow_status]);

  return (
    <>
      <Link
        to={""}
        className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
        key={"version"}
      >
        <div className=" py-1 card-width position-relative">
          {
            // contractContext?.data?.status === "EXPIRED" ||
            // tempContext?.data?.status === "EXPIRED" ? (
            //   <></>
            // ) :
            <>
              <div className="position-absolute dot_corner_version">
                <div id="delete-icon">
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{ border: "none" }}
                      variant="default"
                      id="dropdown-basic"
                    >
                      <BiDotsVerticalRounded color="#5957df" fontSize={21} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="create-dropdown-css">
                      {contractContext?.data?.status === "EXPIRED" ||
                      tempContext?.data?.status === "EXPIRED" ? (
                        <>
                          {" "}
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() =>
                              navigate(
                                location.pathname.includes("contracts")
                                  ? generatePath(
                                      routes.VersionHistoryContractDeatils,
                                      {
                                        id: id,
                                        versionId: documentDetail?.id,
                                        documentId: headDocumentId,
                                      }
                                    )
                                  : generatePath(
                                      routes.VersionHistoryTemplateDeatils,
                                      {
                                        id: id,
                                        versionId: documentDetail?.id,
                                        documentId: headDocumentId,
                                      }
                                    )
                              )
                            }
                          >
                            View
                          </Dropdown.Item>
                          {(
                            contractContext?.data?.collaborators ||
                            tempContext?.data?.collaborators
                          )?.find(
                            (el: any) =>
                              el?.user?.email ===
                              authContext?.currentUser?.email
                          )?.access === "OWNER" && (
                            <>
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  setversionRenamePopup(true);
                                  setIsRestoreActive(false);
                                }}
                              >
                                Rename
                              </Dropdown.Item>
                            </>
                          )}
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setShowDeleteToast(true)}
                          >
                            Clone
                          </Dropdown.Item>
                        </>
                      ) : (
                        <>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() =>
                              navigate(
                                location.pathname.includes("contracts")
                                  ? generatePath(
                                      routes.VersionHistoryContractDeatils,
                                      {
                                        id: id,
                                        versionId: documentDetail?.id,
                                        documentId: headDocumentId,
                                      }
                                    )
                                  : generatePath(
                                      routes.VersionHistoryTemplateDeatils,
                                      {
                                        id: id,
                                        versionId: documentDetail?.id,
                                        documentId: headDocumentId,
                                      }
                                    )
                              )
                            }
                          >
                            View
                          </Dropdown.Item>
                          {(
                            contractContext?.data?.collaborators ||
                            tempContext?.data?.collaborators
                          )?.find(
                            (el: any) =>
                              el?.user?.email ===
                              authContext?.currentUser?.email
                          )?.access === "OWNER" && (
                            <>
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  setversionRenamePopup(true);
                                  setIsRestoreActive(false);
                                }}
                              >
                                Rename
                              </Dropdown.Item>
                            </>
                          )}
                          {(
                            contractContext?.data?.collaborators ||
                            tempContext?.data?.collaborators
                          )?.find(
                            (el: any) =>
                              el?.user?.email ===
                              authContext?.currentUser?.email
                          )?.access === "OWNER" &&
                            !isDocPublished && (
                              <>
                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => setShowDeleteToast(true)}
                                >
                                  Clone
                                </Dropdown.Item>

                                {headDocumentVersion !== documentDetail?.id && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      setShowDeleteToast(true);
                                      setCurrentDocument(documentDetail?.name);
                                      getCurrentDocumentVersion();
                                      setIsRestoreActive(true);
                                    }}
                                  >
                                    {"Restore"}
                                  </Dropdown.Item>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          }

          <div className="bg-contract contract-division p-4 br-4">
            <div>
              <div
                className="card-contract-name"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={documentDetail?.name}
              >
                <span>
                  {documentDetail?.name === undefined ? (
                    "Name this version"
                  ) : documentDetail?.name?.length >= 15 ? (
                    <>
                      {documentDetail?.name?.charAt(0).toUpperCase() +
                        documentDetail?.name?.slice(0)?.substr(1, 15)}
                      {"..."}
                    </>
                  ) : (
                    <>
                      {documentDetail?.name?.charAt(0).toUpperCase() +
                        documentDetail?.name?.slice(1)}
                    </>
                  )}
                </span>
              </div>

              <div className=" py-2 d-flex justify-content-between align-items-center">
                <div className="contract-team-name m-0">
                  <span className="pro-names p-0">
                    {documentDetail?.updated_by?.name ? (
                      documentDetail?.updated_by?.name
                        ?.charAt(0)
                        .toUpperCase() +
                      documentDetail?.updated_by?.name?.slice(1).toLowerCase()
                    ) : (
                      <>
                        {documentDetail?.updated_by?.type
                          ?.charAt(0)
                          .toUpperCase() +
                          documentDetail?.updated_by?.type
                            ?.slice(1)
                            .toLowerCase()}
                        {" generated"}
                      </>
                    )}
                  </span>
                </div>

                <div className="  ">
                  <span className="profileAvatar mx-1">
                    {documentDetail?.edited_by &&
                    documentDetail?.edited_by?.length === 0 ? (
                      <>
                        <Avatar
                          name={
                            documentDetail?.updated_by?.name
                              ? documentDetail?.updated_by?.name
                              : documentDetail?.updated_by?.type
                          }
                          className="profile-avatars"
                          size="30"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={
                            documentDetail?.updated_by?.name
                              ? documentDetail?.updated_by?.name
                              : documentDetail?.updated_by?.name
                          }
                        />
                      </>
                    ) : (
                      <>
                        {documentDetail?.edited_by?.map((editedBy, index) => {
                          return (
                            index <= 1 && (
                              <Fragment key={index}>
                                <Avatar
                                  name={editedBy?.name?.charAt(0)}
                                  className="profile-avatars"
                                  size="30"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={editedBy?.name}
                                />
                              </Fragment>
                            )
                          );
                        })}
                      </>
                    )}
                    &nbsp;&nbsp;
                    <span className="profileAvatar mt-2">
                      &nbsp;&nbsp;
                      {documentDetail?.edited_by &&
                        documentDetail?.edited_by?.length > 2 && (
                          <>
                            {"+"}
                            {documentDetail?.edited_by?.length - 2}
                          </>
                        )}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className=" card-signature mt-2 d-flex justify-content-start">
              {headDocumentVersion === documentDetail?.id ? (
                "Current version"
              ) : (
                <>&nbsp;</>
              )}
              {previousVersion === documentDetail?.id && (
                <>{`Restored version `}</>
              )}
            </div>

            <div className=" row">
              <div className="card-clock mt-3 mb-3 col-12">
                <div className="card-update-date px-3 py-2 ">
                  Last Updated :
                  <span className="textBlack ms-1">
                    {""}
                    {moment(documentDetail?.updatedAt).format(
                      "DD MMM YYYY,hh:mm A"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {versionRenamePopup && (
        <VersionRenamePopup
          show={versionRenamePopup}
          onHide={() => setversionRenamePopup(false)}
          versionName={documentDetail?.name}
          documentDetail={documentDetail}
          handleDocument={handleDocument}
          setBodyMessage={setBodyMessage}
          setShowSuccessToast={setShowSuccessToast}
          contractDocId={contractContext?.data?.documentId}
        />
      )}

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
      {showDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => {
            isRestoreActive ? handleRestoreVersion() : handleCloneDocument();
          }}
          onClose={() => setShowDeleteToast(false)}
          title={
            isRestoreActive
              ? `Do you want to restore "${
                  currentDocument ? currentDocument : "Name this version"
                }" version with ${
                  currentVersionDetail?.name
                    ? currentVersionDetail?.name
                    : moment(currentVersionDetail?.updatedAt).format(
                        "DD MMM , hh:mm"
                      )
                } version?`
              : "Do you want to clone this document?"
          }
          closeText="No, don't !"
          submitText={
            isRestoreActive ? "Yes, restore them !" : "Yes, clone it !"
          }
        />
      )}
    </>
  );
}
