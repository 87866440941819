import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Stack,
} from "react-bootstrap";
import { useFolders } from "../FoldersProvider";
import { useEffect, useState } from "react";
import Select from "react-select";
import { customStyles } from "../../../ui/CustomStyles";
import { useLocation, useParams } from "react-router-dom";
import { Folder } from "../../../lib/Folder";

function CreateFolderPopup({
  show,
  onHide,
  setAddFolderPopup,
  setBodyMessage,
  setShowSuccessToast,
  setACtiveFolder,
  setShowFailureToast,
}: {
  show: boolean;
  onHide: Function;
  setAddFolderPopup: Function;
  setBodyMessage: Function;
  setShowSuccessToast: Function;
  setACtiveFolder: Function;
  setShowFailureToast: Function;
}) {
  const context = useFolders();
  const [folderName, setFolderName]: [string, Function] = useState("");
  const [statusValue, setStatusValue]: [string, Function] = useState("");
  const [department, setDepartment]: [string, Function] = useState("");
  const [departmentName, setDepartmentNmae]: [string, Function] = useState("");
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const { id } = useParams();
  const location = useLocation();

  const getDocument = async () => {
    try {
      let response = await context?.handleFolderById(
        id! ? id : context?.folderList?.id
      );

      if (response?.success) {
        setDepartmentNmae(response?.folder?.department);
      }
    } catch (error) {}
  };

  const handleAddFolders = async () => {
    setLoader(true);
    if (context?.folderList?.id) {
      try {
        let requestBody = {
          name: folderName,
          parent_folder: id! ? id : context?.folderList?.id,
          access: location.pathname.includes("private")
            ? "PRIVATE"
            : location.pathname.includes("public")
            ? "PUBLIC"
            : statusValue,
          department: id ? departmentName : department,
        };
        let response = await context?.handleAddFolders(requestBody);
        if (response?.data?.success) {
          setLoader(false);

          let res = await context?.handleFolderById(
            id! ? id : context?.folderList?.id
          );

          if (res?.success) {
            if (location.pathname.includes("private")) {
              let privateList = res?.folder?.sub_folders?.filter(
                (folder: Folder) => {
                  return folder?.access === "PRIVATE";
                }
              );

              context?.setSubFolders(privateList);
              setACtiveFolder(privateList);
            } else if (location.pathname.includes("public")) {
              let publicList = res?.folder?.sub_folders?.filter(
                (folder: Folder) => {
                  return folder?.access === "PUBLIC";
                }
              );
              context?.setSubFolders(publicList);
              context?.setFolderList(res?.folder);
              setACtiveFolder(publicList);
            }
          }

          setBodyMessage("Folder created.");
          setShowSuccessToast(true);
          onHide();
        } else {
          setShowFailureToast(true);
          setBodyMessage("Department should not be empty");
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getDocument();
  }, []);
  return (
    <>
      <Modal show={show} centered onHide={() => onHide()}>
        <Modal.Body className="p-4">
          <Stack>
            <h5 className="fw-semibold py-2">Add folder </h5>
          </Stack>

          {id && (
            <>
              <div className="fw-semibold py-2">Department</div>
              <input
                type="text"
                name="name"
                disabled
                defaultValue={departmentName}
                className="form-control"
                placeholder="Name"
              />
            </>
          )}

          <FormGroup className="my-3 position-relative">
            <div className="fw-semibold py-2">Folder name</div>
            <FormControl
              type={"text"}
              onChange={(e) => setFolderName(e?.target?.value)}
            />
          </FormGroup>
          {!location.pathname.includes("private") &&
            !location.pathname.includes("public") && (
              <>
                <div className="fw-semibold py-2">Status</div>
                <div className="my-2 mb-4">
                  <div className="d-flex flex-wrap">
                    <Form.Check
                      type="radio"
                      className="me-3"
                      label={"Private"}
                      value="PRIVATE"
                      onChange={(e) => setStatusValue(e?.target?.value)}
                    />
                    <Form.Check
                      type="radio"
                      className="me-3"
                      label={"Public"}
                      value="PUBLIC"
                      onChange={(e) => setStatusValue(e?.target?.value)}
                    />
                  </div>
                </div>
              </>
            )}

          {!id && (
            <>
              <div className="fw-semibold py-2">Department</div>
              <Select
                styles={customStyles}
                classNamePrefix="select"
                className={` basic-multi-select `}
                options={context?.departmentList?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(option: any) => {
                  setDepartment(option?.value);
                }}
              />
            </>
          )}

          <div
            className="fw-semibold py-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAddFolderPopup(true);
            }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            disabled={folderName?.length === 0}
            className="sharebtndanger"
            size="sm"
            onClick={() => {
              handleAddFolders();
            }}
          >
            Add
            {loader ? (
              <>
                <span className="btn_loader"></span>
              </>
            ) : (
              ""
            )}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateFolderPopup;
