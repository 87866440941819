import { generatePath, useNavigate } from "react-router-dom";
import ClmEditor from "../../editor/Editable/Editor";
import { EditorProvider } from "../../editor/EditorProvider";
import { TemplateProvider, useTemplate } from "./TemplateProvider";
import { useEffect } from "react";
import { routes } from "../../utils/routes";
import { StatusEnum } from "../../interfaces/StatusEnum";

const EditTemplate = () => {
  return (
    <TemplateProvider>
      <TemplateEditor />
    </TemplateProvider>
  );
};
export default EditTemplate;

const TemplateEditor = () => {
  const context = useTemplate();
  const navigate = useNavigate();

  useEffect(() => {
    if (context?.data && context?.data?.status === StatusEnum?.published) {
      navigate(
        generatePath(routes.overview, {
          id: context?.data?.id,
        })
      );
    }
  }, [context, navigate]);
  return (
    <>
      {context && context?.template?.documentId && (
        <EditorProvider documentId={context?.template?.documentId} readOnly={false}>
          <ClmEditor parentContext={context} />
        </EditorProvider>
      )}
    </>
  );
};
