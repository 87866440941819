import { ReactNode, useEffect } from "react";
import "./AdminAuthlayout.scss";
import { isMobile } from "react-device-detect";
import logo from "../assets/logo12.png";
interface AuthLayoutProps {
  pageTitle?: string;
  title: string;
  subTitle: string;
  children: ReactNode;
}
const AdminAuthlayout = ({ pageTitle, title, children }: AuthLayoutProps) => {
  useEffect(() => {
    document.title = pageTitle || "CLM";
  }, [pageTitle]);

  return (
    <div className="vh-100 d-flex flex-column bg-transparent">
      <div className="container my-auto text-truncate">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-5 mx-auto ">
            <div className="bg-white shadow-md  p-4 px-sm-5 mt-4">
              <div className={` ${isMobile ? " py-0" : "pt-4"}`}>
                <img src={logo} alt="logo" width={80} className="logo" />
              </div>
              <h3 className="pb-1 pt-5 fw-bold my-1">{title}</h3>
              <div className="pb-3 fw-normal">{pageTitle}</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAuthlayout;
