import { Editor, BaseEditor, Transforms } from "slate";

import { useRef, useState } from "react";
import { MdFormatColorText, MdFormatColorFill, MdCheck } from "react-icons/md";
import styles from "./ColorPicker.module.css";
import { colors } from "./defaultColors";
import usePopup from "./usePopup";
import { ReactEditor } from "slate-react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
import { CustomEditor } from "../../types/slate-react";
import { useEditor } from "../../EditorProvider";

export const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    {_.capitalize(props)}
  </Tooltip>
);

const logo: any = {
  color: <MdFormatColorText size={20} />,
  bgColor: <MdFormatColorFill size={20} />,
};

// export const colorPickerRef = useRef(null);

const ColorPicker = ({ format, editor, className }: any) => {
  const editorContext = useEditor();

  const [selection, setSelection] = useState<any>();
  const [hexValue, setHexValue] = useState("");
  const [validHex, setValidHex] = useState<any>();
  const colorPickerRef = useRef(null);
  const [showOptions, setShowOptions] = usePopup(colorPickerRef);

  const isValideHexSix = new RegExp("^#[0-9A-Za-z]{6}");
  const isValideHexThree = new RegExp("^#[0-9A-Za-z]{3}");

  const changeColor = (e: any) => {
    const clickedColor = e.target.getAttribute("data-value");
    selection && Transforms.select(editor, selection);
    addMarkData(editor, { format, value: clickedColor });

    ReactEditor.focus(editor);
    setShowOptions(false);
  };

  const toggleOption = () => {
    setSelection(editor.selection);
    setShowOptions((prev: any) => !prev);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (!validHex) return;
    selection && Transforms.select(editor, selection);
    addMarkData(editor, { format, value: hexValue });
    setShowOptions(false);
    setValidHex("");
    setHexValue("");
  };
  const handleHexChange = (e: any) => {
    alert("hai");
    e.preventDefault();
    const newHex = e.target.value;
    setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
    setHexValue(newHex);
  };

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(format)}
    >
      <div className=" position-relative">
        <Button
          style={{
            color: showOptions ? "black" : activeMark(editor, format),
            opacity: "1",
            background: activeMark(editor, format) === "#ffffff" ? "black" : "",
          }}
          ref={colorPickerRef}
          className={
            (showOptions ? styles.clicked : "") +
            " " +
            styles.colorPickerBtnPopupWrapper +
            " " +
            className
          }
          onClick={toggleOption}
          // onKeyDown={editorContext?.setShowColorPicker(!editorContext?.showColorPicker)}
          variant="link"
        >
          {logo[format]}
        </Button>
        <div className="addcolor">
          {showOptions
            ? editorContext?.setShowColorPicker(true)
            : editorContext?.setShowColorPicker(false)}
          {showOptions && (
            <div
              className={styles.popupColor + " border"}
              style={{
                marginTop: "10px",
                width: "auto",
                display: "grid",
                backgroundColor: "white",
              }}
            >
              <div className={styles.colorOptions}>
                {colors.map((color, index) => {
                  return (
                    <div
                      key={index}
                      data-value={color}
                      onClick={changeColor}
                      className={styles.option}
                      style={{
                        background: color,
                        borderRadius: "20px",
                        width: "25px",
                        height: "25px",
                        borderStyle: "solid",
                        borderColor: "lightgrey",
                        borderWidth: "1px",
                      }}
                    ></div>
                  );
                })}
              </div>
              <p
                style={{
                  textAlign: "center",
                  opacity: "0.7",
                  width: "100%",
                }}
              >
                OR
              </p>
              <form>
                <div
                  className={styles.hexPreview}
                  style={{ background: validHex ? hexValue : "#000000" }}
                ></div>

                <input
                  type="text"
                  placeholder="#000000"
                  value={hexValue}
                  onChange={(e: any) => handleHexChange(e)}
                  style={{
                    border:
                      validHex === false
                        ? "1px solid red"
                        : "1px solid lightgray",
                    width: "120px",
                  }}
                />
                <button
                  style={{ color: validHex ? "green" : "" }}
                  type="button"
                  onClick={handleFormSubmit}
                >
                  <MdCheck size={20} />
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default ColorPicker;

export const activeMark = (editor: any, format: string | number) => {
  const defaultMarkData: any = {
    color: "black",
    bgColor: "black",
    fontSize: "normal",
    fontFamily: "sans",
  };
  const marks: any = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ? marks?.[format] : defaultValue;
};

export const addMarkData = (
  editor: BaseEditor | CustomEditor,
  data: { format: any; value: any }
) => {
  if (data.value === "heading-one") {
    Transforms.setNodes(editor, {
      type: "heading-one",
    } as any);
  } else if (data.value === "heading-two") {
    Transforms.setNodes(editor, {
      type: "heading-two",
    } as any);
  } else if (data.value === "heading-three") {
    Transforms.setNodes(editor, {
      type: "heading-three",
    } as any);
  } else if (data.value === "heading-four") {
    Transforms.setNodes(editor, {
      type: "heading-four",
    } as any);
  } else if (data.value === "heading-five") {
    Transforms.setNodes(editor, {
      type: "heading-five",
    } as any);
  } else if (data.value === "heading-six") {
    Transforms.setNodes(editor, {
      type: "heading-six",
    } as any);
  } else if (data.value === "normal") {
    Transforms.setNodes(editor, {
      type: "normal",
    } as any);
  } else {
    Editor.addMark(editor, data.format, data.value);
  }
};
