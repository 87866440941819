import { usePdf } from "@mikecousins/react-pdf";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { PDFDocumentProxy, getDocument } from "pdfjs-dist";
import { useContract } from "../modules/Contracts/ContractProvider";
import { useParams } from "react-router-dom";
import { MdCancel } from "react-icons/md";

function AddCoordinatePdfViewer({
  file,
  color,
  userDetail,
  page,
  saveDetailClick,
  isNameREquired,
  setIsNameRequired,
  isDateRequired,
  setIsDateRequired,
  isInitialRequired,
  setIsInitialRequired,
  isEmailRequired,
  setIsEmailRequired,
  isDesiginationRequired,
  setIsDesiginationRequired,
  setSignatureType,
  signatureType,
  setLocationRequired,
  locationRequired,
  setSaveDetailClick,
  activePage,
  selectedOptions,
  isCoordinateAdded,
  setIsCoordinateAdded,
  setNumberOfPages,
}: any) {
  const canvasRef: any = useRef(null);
  const context = useContract();

  const { id } = useParams();
  const [pdfHeightandWidth, setPdfHeightAndWidth]: [any, Function] = useState();
  const [namePosition, setNamePosition]: [any, Function] = useState({
    x: 450,
    y: 750,
  });

  const [signPosition, setSignPosition]: [any, Function] = useState({
    x: 450,
    y: 700,
  });

  const [initialsPosition, setinitialsPosition]: [any, Function] = useState({
    x: 450,
    y: 660,
  });
  const [datePosition, setdatePosition]: [any, Function] = useState({
    x: 450,
    y: 560,
  });
  const [emailPosition, setEmailPosition]: [any, Function] = useState({
    x: 450,
    y: 600,
  });
  const [desiginationPosition, setDesiginationPosition]: [any, Function] =
    useState({
      x: 450,
      y: 600,
    });
  const [locationPosition, setLocationPosition]: [any, Function] = useState({
    x: 450,
    y: 580,
  });
  const [recentPosition, setRecentPosition]: [any, Function] = useState();
  const [coordinateDeleteDetail, setCoordinateDelete]: [any, Function] =
    useState();

  useEffect(() => {
    let externalCoordinates: any = context?.data?.externalUser
      .filter((item: any) => item.access === "SIGNER")
      .map((el: any) => ({
        name: el?.name,
        id: el?._id,
        email: el?.email,
        coordinates: el?.co_ordinates,
        status: el?.status,
      }));

    let internalCoordinates = context?.data?.internalSingers?.map(
      (el: any) => ({
        name: el?.user?.name,
        id: el?._id,
        email: el?.user?.email,
        coordinates: el?.co_ordinates,
        status: el?.status,
      })
    );
    setRecentPosition(internalCoordinates?.concat(externalCoordinates));
  }, [context?.data?.internalSingers, context?.data?.externalUser]);

  const { pdfDocument } = usePdf({
    scale: 1,
    file: file,
    page,
    canvasRef,
  });

  function mergeDataById(data1: any, data2: any) {
    let newData = data1?.map((el: any) => ({
      ...el,
      coordinates: el?.coordinates === undefined ? [] : el?.coordinates,
    }));

    const mergedData: any = [];

    function coordinateExists(coordinates: any, coord: any) {
      return coordinates.some(
        (c: any) => c.page === coord.page && c.type === coord.type
      );
    }

    for (const item1 of newData) {
      const userExists = mergedData.some(
        (mergedItem: any) => mergedItem.user === item1.id
      );
      if (userExists) {
        const userIndex = mergedData.findIndex(
          (mergedItem: any) => mergedItem.user === item1.id
        );
        for (const coord1 of item1.coordinates) {
          if (!coordinateExists(mergedData[userIndex].coordinates, coord1)) {
            mergedData[userIndex].coordinates.push(coord1);
          }
        }
      } else {
        mergedData.push({
          user: item1.id,
          coordinates: [...item1.coordinates],
        });
      }
    }

    for (const item2 of data2) {
      const userExists = mergedData.some(
        (mergedItem: any) => mergedItem.user === item2.user
      );
      if (userExists) {
        const userIndex = mergedData.findIndex(
          (mergedItem: any) => mergedItem.user === item2.user
        );
        for (const coord2 of item2.co_ordinates) {
          const existingCoordIndex = mergedData[
            userIndex
          ].coordinates.findIndex(
            (coord: any) =>
              coord.page === coord2.page && coord.type === coord2.type
          );
          if (existingCoordIndex !== -1) {
            mergedData[userIndex].coordinates[existingCoordIndex] = coord2;
          } else if (
            !coordinateExists(mergedData[userIndex].coordinates, coord2)
          ) {
            mergedData[userIndex].coordinates.push(coord2);
          }
        }
      } else {
        mergedData.push({
          user: item2.user,
          coordinates: [...item2.co_ordinates],
        });
      }
    }

    return mergedData;
  }

  const handleAddCoordinateDetails = async () => {
    try {
      let data = {
        signing: [
          {
            co_ordinates: [
              {
                page: 1,
                x: signPosition?.x,
                y: pdfHeightandWidth?.height - 50 - signPosition?.y,
                height: 30,
                width: 100,
                type: "SIGN",
              },
              {
                page: 1,
                x: datePosition?.x,
                y: pdfHeightandWidth?.height - 50 - datePosition?.y,
                height: 30,
                width: 100,
                type: "DATE",
              },
              {
                page: 1,
                x: namePosition?.x,
                y: pdfHeightandWidth?.height - 50 - namePosition?.y,
                height: 30,
                width: 100,
                type: "NAME",
              },
              {
                page: 1,
                x: emailPosition?.x,
                y: pdfHeightandWidth?.height - 50 - emailPosition?.y,
                height: 30,
                width: 100,
                type: "EMAIL",
              },
              {
                page: 1,
                x: desiginationPosition?.x,
                y: pdfHeightandWidth?.height - 50 - desiginationPosition?.y,
                height: 30,
                width: 100,
                type: "DESIGNATION",
              },
              {
                page: 1,
                x: initialsPosition?.x,
                y: pdfHeightandWidth?.height - 50 - initialsPosition?.y,
                height: 30,
                width: 100,
                type: "INITIAL",
              },
              {
                page: 1,
                x: locationPosition?.x,
                y: pdfHeightandWidth?.height - 50 - locationPosition?.y,
                height: 30,
                width: 100,
                type: "ADRESS",
              },
            ],
            user: userDetail?.id,
          },
        ],
      };

      if (activePage === "all") {
        data = {
          signing: [
            {
              co_ordinates: [],
              user: userDetail?.id,
            },
          ],
        };

        for (let page = 1; page <= context?.data?.pages!; page++) {
          data.signing[0].co_ordinates.push(
            {
              page,
              x: signPosition.x,
              y: pdfHeightandWidth?.height - 50 - signPosition.y,
              height: 30,
              width: 100,
              type: "SIGN",
            },
            {
              page,
              x: datePosition.x,
              y: pdfHeightandWidth?.height - 50 - datePosition.y,
              height: 30,
              width: 100,
              type: "DATE",
            },
            {
              page,
              x: namePosition.x,
              y: pdfHeightandWidth?.height - 50 - namePosition.y,
              height: 30,
              width: 100,
              type: "NAME",
            },
            {
              page,
              x: emailPosition.x,
              y: pdfHeightandWidth?.height - 50 - emailPosition.y,
              height: 30,
              width: 100,
              type: "EMAIL",
            },
            {
              page,
              x: desiginationPosition.x,
              y: pdfHeightandWidth?.height - 50 - desiginationPosition.y,
              height: 30,
              width: 100,
              type: "DESIGNATION",
            },
            {
              page,
              x: initialsPosition.x,
              y: pdfHeightandWidth?.height - 50 - initialsPosition.y,
              height: 30,
              width: 100,
              type: "INITIAL",
            },
            {
              page,
              x: locationPosition.x,
              y: pdfHeightandWidth?.height - 50 - locationPosition.y,
              height: 30,
              width: 100,
              type: "ADRESS",
            }
          );
        }
      } else if (activePage === "last") {
        data = {
          signing: [
            {
              co_ordinates: [
                {
                  page: context?.data?.pages!,
                  x: signPosition?.x,
                  y: pdfHeightandWidth?.height - 50 - signPosition?.y,
                  height: 30,
                  width: 100,
                  type: "SIGN",
                },
                {
                  page: context?.data?.pages!,
                  x: datePosition?.x,
                  y: pdfHeightandWidth?.height - 50 - datePosition?.y,
                  height: 30,
                  width: 100,
                  type: "DATE",
                },
                {
                  page: context?.data?.pages!,
                  x: namePosition?.x,
                  y: pdfHeightandWidth?.height - 50 - namePosition?.y,
                  height: 30,
                  width: 100,
                  type: "NAME",
                },
                {
                  page: context?.data?.pages!,
                  x: emailPosition?.x,
                  y: pdfHeightandWidth?.height - 50 - emailPosition?.y,
                  height: 30,
                  width: 100,
                  type: "EMAIL",
                },
                {
                  page: context?.data?.pages!,
                  x: desiginationPosition?.x,
                  y: pdfHeightandWidth?.height - 50 - desiginationPosition?.y,
                  height: 30,
                  width: 100,
                  type: "DESIGNATION",
                },
                {
                  page: context?.data?.pages!,
                  x: initialsPosition?.x,
                  y: pdfHeightandWidth?.height - 50 - initialsPosition?.y,
                  height: 30,
                  width: 100,
                  type: "INITIAL",
                },
                {
                  page: context?.data?.pages!,
                  x: locationPosition?.x,
                  y: pdfHeightandWidth?.height - 50 - locationPosition?.y,
                  height: 30,
                  width: 100,
                  type: "ADRESS",
                },
              ],
              user: userDetail?.id,
            },
          ],
        };
      } else if (activePage === "custom") {
        data = {
          signing: [
            {
              co_ordinates: [],
              user: userDetail?.id,
            },
          ],
        };

        selectedOptions.forEach((pageItem: any) => {
          const page = parseInt(pageItem.value);

          data?.signing?.[0].co_ordinates.push(
            {
              page,
              x: signPosition.x,
              y: pdfHeightandWidth?.height - 50 - signPosition.y,
              height: 30,
              width: 100,
              type: "SIGN",
            },
            {
              page,
              x: datePosition.x,
              y: pdfHeightandWidth?.height - 50 - datePosition.y,
              height: 30,
              width: 100,
              type: "DATE",
            },
            {
              page,
              x: namePosition.x,
              y: pdfHeightandWidth?.height - 50 - namePosition.y,
              height: 30,
              width: 100,
              type: "NAME",
            },
            {
              page,
              x: emailPosition.x,
              y: pdfHeightandWidth?.height - 50 - emailPosition.y,
              height: 30,
              width: 100,
              type: "EMAIL",
            },
            {
              page,
              x: desiginationPosition.x,
              y: pdfHeightandWidth?.height - 50 - desiginationPosition.y,
              height: 30,
              width: 100,
              type: "DESIGNATION",
            },
            {
              page,
              x: initialsPosition.x,
              y: pdfHeightandWidth?.height - 50 - initialsPosition.y,
              height: 30,
              width: 100,
              type: "INITIAL",
            },
            {
              page,
              x: locationPosition.x,
              y: pdfHeightandWidth?.height - 50 - locationPosition.y,
              height: 30,
              width: 100,
              type: "ADRESS",
            }
          );
        });
      }

      const filteredCoordinates = data?.signing?.[0]?.co_ordinates.filter(
        (coordinate: any) => {
          if (
            (signatureType && coordinate.type === "SIGN") ||
            (isDateRequired && coordinate.type === "DATE") ||
            (locationRequired && coordinate.type === "ADRESS") ||
            (isEmailRequired && coordinate.type === "EMAIL") ||
            (isNameREquired && coordinate?.type === "NAME") ||
            (isInitialRequired && coordinate?.type === "INITIAL") ||
            (isDesiginationRequired && coordinate?.type === "DESIGNATION")
          ) {
            return true;
          }
          return false;
        }
      );

      let requestBody;
      requestBody = {
        signing: mergeDataById(recentPosition, [
          { co_ordinates: filteredCoordinates, user: userDetail?.id },
        ])?.map((el: any) => ({
          co_ordinates: el?.coordinates,
          user: el?.user,
        })),
      };

      if (coordinateDeleteDetail) {
        const userIdToRemove = coordinateDeleteDetail?.id;
        const typeToRemove = coordinateDeleteDetail?.coordinates?.[0]?.type;
        const pageToRemove = coordinateDeleteDetail?.coordinates?.[0]?.page;

        const updatedData = requestBody?.signing.map((entry: any) => {
          if (entry.user === userIdToRemove) {
            const filteredCoordinates = entry?.co_ordinates.filter(
              (coord: any) =>
                !(coord.type === typeToRemove && coord.page === pageToRemove)
            );

            return { ...entry, co_ordinates: filteredCoordinates };
          }
          return entry;
        });

        let data = updatedData.filter((entry: any) => entry.user !== undefined);
        requestBody = {
          signing: data,
        };
      }

      let response = await context?.handleAddCoordinate(id, requestBody);

      if (response?.data?.success) {
        setSaveDetailClick(false);
        setIsCoordinateAdded(true);
        setIsNameRequired(false);
        setIsDateRequired(false);
        setIsInitialRequired(false);
        setLocationRequired(false);
        setSignatureType(false);
        setIsEmailRequired(false);
        setIsDesiginationRequired(false);
        setCoordinateDelete("");
        setNamePosition({
          x: 450,
          y: 750,
        });
        setSignPosition({
          x: 450,
          y: 700,
        });
        setinitialsPosition({
          x: 450,
          y: 660,
        });
        setdatePosition({
          x: 450,
          y: 560,
        });
        setEmailPosition({
          x: 450,
          y: 600,
        });
        setDesiginationPosition({
          x: 450,
          y: 600,
        });
        setLocationPosition({
          x: 450,
          y: 580,
        });

        context?.refresh();
      } else {
        setSaveDetailClick(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (coordinateDeleteDetail) {
      handleAddCoordinateDetails();
    }
  }, [coordinateDeleteDetail]);

  const handleOnDeleteCoordinate = (coordinate: any, userDetail: any) => {
    let details = {
      name: userDetail?.name,
      id: userDetail?.id,
      email: userDetail?.email,
      coordinates: coordinate,
      status: userDetail?.status,
    };
    setCoordinateDelete(details);
  };

  useEffect(() => {
    const fetchPdfAndCalculateDimensions = async () => {
      try {
        const loadingTask = getDocument(file);
        const pdf: PDFDocumentProxy = await loadingTask.promise;

        // Assuming the PDF only contains one page (index 1)
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        // Retrieve the dimensions of the PDF page
        const { width, height } = viewport;

        setPdfHeightAndWidth({ width: width, height: height });
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchPdfAndCalculateDimensions();
  }, [file]);

  useEffect(() => {
    if (saveDetailClick) {
      handleAddCoordinateDetails();
    }
  }, [saveDetailClick]);

  useEffect(() => {
    setNumberOfPages(pdfDocument?.numPages);
  }, [pdfDocument]);

  return (
    <>
      {!pdfDocument && <span>Loading...</span>}
      <div className=" position-relative d-inline-block">
        {recentPosition?.map((el: any) => {
          return (
            <>
              <NameRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <SignatureRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <InitialsRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <DateRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <EmailRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <DesiginationRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
              <LocationRecentPosition
                position={el}
                page={page}
                pdfHeightandWidth={pdfHeightandWidth}
                handleOnDeleteCoordinate={handleOnDeleteCoordinate}
              />
            </>
          );
        })}
        {isCoordinateAdded === false && (
          <>
            {isNameREquired && (
              <>
                <DraggableName
                  color={color}
                  setNamePosition={setNamePosition}
                  namePosition={namePosition}
                  setIsNameRequired={setIsNameRequired}
                />
              </>
            )}
            {signatureType && (
              <>
                <DraggableSignature
                  color={color}
                  setSignPosition={setSignPosition}
                  signPosition={signPosition}
                  setSignatureType={setSignatureType}
                />
              </>
            )}

            {isInitialRequired && (
              <>
                <DraggableInitials
                  color={color}
                  setinitialsPosition={setinitialsPosition}
                  initialsPosition={initialsPosition}
                  setIsInitialRequired={setIsInitialRequired}
                />
              </>
            )}

            {isDateRequired && (
              <>
                <DraggableDate
                  color={color}
                  setdatePosition={setdatePosition}
                  datePosition={datePosition}
                  setIsDateRequired={setIsDateRequired}
                />
              </>
            )}
            {isEmailRequired && (
              <>
                <DraggableEmail
                  color={color}
                  setEmailPosition={setEmailPosition}
                  emailPosition={emailPosition}
                  setIsEmailRequired={setIsEmailRequired}
                />
              </>
            )}
            {isDesiginationRequired && (
              <>
                <DraggableDesigination
                  color={color}
                  setDesiginationPosition={setDesiginationPosition}
                  desiginationPosition={desiginationPosition}
                  setIsDesiginationRequired={setIsDesiginationRequired}
                />
              </>
            )}
            {locationRequired && (
              <>
                <DraggableLocation
                  color={color}
                  setLocationPosition={setLocationPosition}
                  locationPosition={locationPosition}
                  setLocationRequired={setLocationRequired}
                />
              </>
            )}
          </>
        )}

        <canvas ref={canvasRef} />
      </div>
    </>
  );
}

export default AddCoordinatePdfViewer;

const NameRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "NAME" && el?.page === page
  );

  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  cursor: "not-allowed",
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  // boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  <div
                    className="d-flex justify-content-end cross_active_esign"
                    onClick={() =>
                      handleOnDeleteCoordinate(positionDetail, position)
                    }
                  >
                    <MdCancel color="red" />
                  </div>
                  <span>{"Name"}</span>
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const SignatureRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "SIGN" && el?.page === page
  );

  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                fontWeight: "600",
                cursor: "not-allowed",

                fontSize: "15px",
                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                className=" p-2 position-relative"
                style={{
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                <span>{"Sign"}</span>
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};
const InitialsRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "INITIAL" && el?.page === page
  );

  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                cursor: "not-allowed",

                fontWeight: "600",
                fontSize: "15px",
                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                style={{
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                Initials
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DateRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "DATE" && el?.page === page
  );
  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };
  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                fontWeight: "600",
                fontSize: "15px",
                cursor: "not-allowed",

                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                style={{
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                Date
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const EmailRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "EMAIL" && el?.page === page
  );

  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                fontWeight: "600",
                fontSize: "15px",
                cursor: "not-allowed",

                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                style={{
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                Email
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DesiginationRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "DESIGNATION" && el?.page === page
  );

  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                fontWeight: "600",
                fontSize: "15px",
                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                cursor: "not-allowed",

                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                style={{
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                Designation
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const LocationRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
  handleOnDeleteCoordinate,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "ADRESS" && el?.page === page
  );
  const stringToHexColor = () => {
    let name =
      position?.name?.charAt(0).toUpperCase() +
      position?.name?.slice(1).toLowerCase();

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 && positionDetail?.length !== 0 && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: positionDetail?.[0]?.x,
              y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
            }}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: stringToHexColor(),
                textAlign: "initial",
                fontWeight: "600",
                fontSize: "15px",
                margin: "20px",
                cursor: "not-allowed",

                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `2px solid ${stringToHexColor()}`,
              }}
            >
              <div
                style={{
                  paddingTop: "9px",
                  paddingBottom: "10px",
                  backgroundColor: hexToRgba(),
                }}
              >
                <div
                  className="d-flex justify-content-end cross_active_esign"
                  onClick={() =>
                    handleOnDeleteCoordinate(positionDetail, position)
                  }
                >
                  <MdCancel color="red" />
                </div>
                Location
              </div>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableName = ({
  color,
  setNamePosition,
  namePosition,
  setIsNameRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setNamePosition({ x: data?.x, y: data?.y });
  };

  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{ x: namePosition?.x, y: namePosition?.y }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                cursor: "move",
                margin: "20px",
                position: "absolute",
                paddingBottom: "40px",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setIsNameRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Name
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableSignature = ({
  color,
  signPosition,
  setSignPosition,
  setSignatureType,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setSignPosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{ x: signPosition?.x, y: signPosition?.y }}
            onDrag={handleDrag}
          >
            <div
              className=""
              style={{
                width: 100,
                height: 30,
                cursor: "move",
                color: color,
                margin: "20px",
                paddingBottom: "40px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setSignatureType(false)}
              >
                <MdCancel color="red" />
              </div>
              <span>Signature</span>
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableInitials = ({
  color,
  setinitialsPosition,
  initialsPosition,
  setIsInitialRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setinitialsPosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{ x: initialsPosition?.x, y: initialsPosition?.y }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                cursor: "move",
                margin: "20px",
                paddingBottom: "40px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setIsInitialRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Initials
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableDate = ({
  color,
  datePosition,
  setdatePosition,
  setIsDateRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setdatePosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{ x: datePosition?.x, y: datePosition?.y }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                margin: "20px",
                cursor: "move",

                paddingBottom: "40px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setIsDateRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Date
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableEmail = ({
  color,
  emailPosition,
  setEmailPosition,
  setIsEmailRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setEmailPosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          {" "}
          <Draggable
            bounds="parent"
            position={{ x: emailPosition?.x, y: emailPosition?.y }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                margin: "20px",
                cursor: "move",

                paddingBottom: "40px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setIsEmailRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Email
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableDesigination = ({
  color,
  desiginationPosition,
  setDesiginationPosition,
  setIsDesiginationRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setDesiginationPosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{
              x: desiginationPosition?.x,
              y: desiginationPosition?.y,
            }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                cursor: "move",

                paddingBottom: "40px",
                margin: "20px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setIsDesiginationRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Designation
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};

const DraggableLocation = ({
  color,
  locationPosition,
  setLocationPosition,

  setLocationRequired,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    setLocationPosition({ x: data?.x, y: data?.y });
  };
  return (
    <>
      {color && (
        <>
          <Draggable
            bounds="parent"
            position={{ x: locationPosition?.x, y: locationPosition?.y }}
            onDrag={handleDrag}
          >
            <div
              style={{
                width: 100,
                height: 30,
                color: color,
                margin: "20px",
                cursor: "move",

                paddingBottom: "40px",
                position: "absolute",
                borderBottom: `3px solid ${color}`,
              }}
            >
              <div
                className="d-flex justify-content-end cross_active_esign"
                onClick={() => setLocationRequired(false)}
              >
                <MdCancel color="red" />
              </div>
              Location
            </div>
          </Draggable>
        </>
      )}
    </>
  );
};
