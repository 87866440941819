import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { stringToHexColor, updateText } from "../../../../../utils";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";

const AdminBarChart = ({ filterData }: { filterData: boolean }) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [any, Function] = useState();

  const ChartDataDetails = async () => {
    try {
      let response = await context?.barChartDataDetails(
        filterData ? filterData : ""
      );
      if (response?.success) {
        var data = response?.data.map(function (obj: any) {
          if (obj.category === null || obj.category === "") {
            obj.category = "No category";
          }
          return obj;
        });
        const dataWithColors = data?.map((item: any) => ({
          title: item?.category?.substring(0, 3),
          color: stringToHexColor(item?.category),
          count: item?.count,
          category: item?.category,
          percent: item?.percent,
        }));
        setChartData(dataWithColors);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);
  return <>{chartData && <HorizontailBarChartData data={chartData} />}</>;
};

export default AdminBarChart;

function HorizontailBarChartData({ data }: any) {
  const CustomTooltip = (data: any) => {
    return (
      <div>
        <strong>{updateText(data?.data?.category?.replace("_", " "))}</strong>:{" "}
        {parseFloat(data?.data?.percent).toFixed(2) + ` %`}
      </div>
    );
  };

  return (
    <div style={{ height: "400px" }} className="barchstr">
      {data?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <ResponsiveBar
          data={data}
          keys={["count"]}
          indexBy="title"
          margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
          padding={0.2}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickRotation: 0,
            legend: "Contract Stage",
            legendPosition: "middle",
            legendOffset: 45,
          }}
          groupMode="grouped"
          layout="vertical"
          enableGridY={false}
          enableGridX={true}
          colors={{ datum: "data.color" }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Count",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          theme={{
            axis: {
              legend: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
          tooltip={CustomTooltip}
        />
      )}
    </div>
  );
}
