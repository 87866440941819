import { FiMail } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { isMobile } from "react-device-detect";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup } from "react-bootstrap";
import * as Yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { BsTelephone } from "react-icons/bs";
import AppLayout from "../layouts/AppLayout";
import FormikField from "./FormikField";
import { useState } from "react";
import { routes } from "../utils/routes";

const HelpCenter = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: Yup.string()
      .matches(
        /^([_A-Za-z0-9+]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/,
        "Invalid email address or phone number"
      )
      .min(13, "Invalid email address or phone number")
      .required("E-mail Address or phone number is required"),
    contactNumber: Yup.string()
      .typeError("That doesn't look like a phone number")
      .min(8, "Contact Number must be atleast 8 characters")
      .matches(phoneRegExp, "That doesn't look like a Contact Number")
      .required("Contact Number is required"),

    biography: Yup.string().min(2, "Too Short!").max(1000),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      biography: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const {} = values;
    },
  });
  const { errors, isValid, values } = formik;

  return (
    <AppLayout>
      <div className="position-relative p-3" style={{ background: "#fafafa" }}>
        <div
          className={`${
            isMobile ? "container-fluid position-relative " : "container"
          }`}
          style={{ height: "100%" }}
        >
          <div className=" row" style={{ height: "100%" }}>
            <div
              className={isMobile ? " d-none" : "col-4 p-4 rounded-1"}
              style={{
                background: "#4a48c8",
                color: "#fefefe",
              }}
            >
              <h5 className=" fw-bold">Get In touch</h5>
              <h6 className=" fw-light">
                We' love to hear from you. Our friendly team is always here to
                chat.
              </h6>
              <div className="py-3 d-flex ">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <FiMail color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className=" fw-semibold mt-1 ">Chat to us</h6>
                  <h6 className=" fw-light">
                    Our friendly team team is here to help.{" "}
                  </h6>
                  <h6 className=" fw-semibold ">clm@clm.com</h6>
                </div>
              </div>

              <div className="py-3 d-flex ">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <HiOutlineLocationMarker color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className=" fw-semibold mt-1 ">Office</h6>
                  <h6 className=" fw-light">
                    Come say hello to our office HQ.{" "}
                  </h6>
                  <h6 className=" fw-semibold ">
                    100 Smith Street Collingwood VIC 3006 AU{" "}
                  </h6>
                </div>
              </div>

              <div className="py-3 d-flex ">
                <div style={{ paddingInlineEnd: ".7rem" }}>
                  <BsTelephone color="#fefefe" fontSize={24} />
                </div>
                <div>
                  <h6 className=" fw-semibold mt-1 ">Phone</h6>
                  <h6 className=" fw-light">Mon - Fri from 8 AM to 5 PM </h6>
                  <h6>+1(555) 000-0000</h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 col-12 py-4"
              style={
                isMobile
                  ? {
                      background: "#fefefe",
                      height: "100%",
                    }
                  : {
                      background: "#fefefe",
                      height: "100%",
                      paddingInlineStart: "3rem",
                    }
              }
            >
              <div>
                <div>
                  <h2 className=" fw-semibold ">Level up your brand</h2>
                  <h6 style={{ color: "#858780" }} className="fw-light">
                    You can reach us anytime via
                    <span style={{ color: "#3c3ada" }} className="ms-2">
                      care@clm.in
                    </span>
                  </h6>
                  {/* ------------------------------------from felid------------------------------- */}

                  <FormikProvider value={formik}>
                    <Form noValidate={true}>
                      <FormikField
                        name="name"
                        type="text"
                        validationSchema={validationSchema}
                        label="Name"
                        errors={errors}
                        autoFocus
                        value={values?.name}
                      />
                      <FormikField
                        name="email"
                        type="text"
                        validationSchema={validationSchema}
                        label="Eamil Address"
                        errors={errors}
                        autoFocus
                        value={values?.email}
                      />
                      <div className="">
                        <label className="text-dark mt-3">
                          Contact Number <span className="">*</span>
                        </label>
                        <FormGroup className="mt-2 ">
                          <div className="phone-input-css ">
                            <PhoneInput
                              // placeholder="Enter phone number"
                              value={phoneNumber}
                              international
                              name="contact_number"
                              country={"in"}
                              onChange={setPhoneNumber}
                              error={
                                phoneNumber
                                  ? isValidPhoneNumber(phoneNumber)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                          </div>
                          <span className="field-error">
                            {phoneNumber
                              ? isValidPhoneNumber(phoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : ""}
                          </span>

                          <ErrorMessage name="contact_number" />
                        </FormGroup>
                      </div>

                      <div className="formprofile my-5">
                        <FormGroup className="">
                          <label>How can we help</label>
                          <Field
                            name="biography"
                            type="text"
                            className={`${"disabled-field"} form-control`}
                            as="textarea"
                            maxLength={250}
                            placeholder=" Tell us a little about the project....."
                          />
                          <Form.Control.Feedback
                            style={{
                              display: !!errors.biography ? "block" : "none",
                            }}
                            type={!!errors.biography ? "invalid" : "valid"}
                          >
                            <ErrorMessage name="biography" />
                          </Form.Control.Feedback>
                        </FormGroup>
                      </div>
                    </Form>
                  </FormikProvider>

                  {/* ------------------------------------from felid------------------------------- */}
                  <div className="helpcenter d-flex align-items-center">
                    <div>
                      <input type="checkbox" className="" />
                    </div>
                    <h6
                      style={{ color: "#858780", paddingInlineStart: ".7rem" }}
                      className="fw-light m-0"
                    >
                      You agree to our friendly
                      <a
                        href={routes.privacyPolicy}
                        className=" text-decoration-underline"
                      >
                        privacy policy
                      </a>
                    </h6>
                  </div>

                  <div>
                    <Button
                      type="submit"
                      id="signin"
                      variant="primary"
                      className="my-4 px-5 py-2 rounded-1 d-flex justify-content-center align-items-center pointer w-100"
                    >
                      <span className={` ${!isValid ? "" : "fw-semibold"}`}>
                        Send message
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default HelpCenter;
