import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { Line } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";
function TurnaroundTimeForSIgned({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [graphData, setGraphData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  // const getMatrics = async () => {
  //   try {
  //     let response = await context?.getMatrics();

  //     if (response?.success) {
  //       setUpdatedMatricsList(response?.metrics);
  //     }
  //     return response;
  //   } catch (error) {}
  // };
  const handleTurnaroundForSignedData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleSignedContractTurnaround(
        filterData ? filterData : ""
      );
      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[3] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          setGraphData(response?.data);
          setNoData(false);
          // let res = await context?.getMatrics();
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );
          setGraphType(selectedGraphType?.type);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleTurnaroundForSignedData();
    setUpdatedMatricsList(updatedValue);
  }, [isUpdate, updatedValue]);

  return (
    <div style={{ height: "390px" }}>
      {loader ? (
        <span>loading...</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Line" ? (
                    <LineChart data={graphData} />
                  ) : graphType === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={graphData} />
                  ) : graphType === "Vertical Bar" ? (
                    <VerticalBarChartData data={graphData} />
                  ) : (
                    <VerticalBarChartData data={graphData} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {updatedMatricsList?.filter(
                (item: any) => item.name === title && item?.is_enabled === true
              )?.length === 0 ? (
                <AnalyticsNoGraph />
              ) : (
                <>
                  {noData ? (
                    <AnalyticsNoDataFound />
                  ) : (
                    <>
                      {graphType === "Line" ? (
                        <LineChart data={graphData} />
                      ) : graphType === "Horizontal Bar" ? (
                        <HorizontailBarChartData data={graphData} />
                      ) : graphType === "Vertical Bar" ? (
                        <VerticalBarChartData data={graphData} />
                      ) : (
                        <VerticalBarChartData data={graphData} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default TurnaroundTimeForSIgned;

const LineChart = ({ data }: { data: any }) => {
  const convertedData = [
    {
      id: "Contract Cycle Time",
      data: data.map((item: any) => ({
        x: item.days,
        y: item.count,
      })),
    },
  ];

  return (
    <div>
      <Line
        data={convertedData}
        width={750}
        height={400}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridX={false}
        enableGridY={true}
        enablePoints={false}
        enableArea={true}
        colors={{ scheme: "category10" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        tooltip={({ point }) => (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}
          >
            <div>
              <strong>{point.data.xFormatted}</strong>
            </div>
            <div>
              {point.serieId}: <strong>{point.data.yFormatted}</strong>
            </div>
          </div>
        )}
      />
    </div>
  );
};
const HorizontailBarChartData = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ height: "400px" }}>
        <ResponsiveBar
          data={data}
          keys={["count"]}
          layout="horizontal"
          indexBy="days"
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          padding={0.5}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
          }}
          enableGridY={true}
          labelTextColor="inherit:darker(1.2)"
          labelSkipWidth={16}
          labelSkipHeight={16}
          colors={{ scheme: "category10" }}
          tooltip={({ id, value }) => (
            <strong>
              {id}: {value}
            </strong>
          )}
        />
      </div>
    </>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  return (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={data}
        keys={["count"]}
        indexBy="days"
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        padding={0.5}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridY={true}
        labelTextColor="inherit:darker(1.2)"
        labelSkipWidth={16}
        labelSkipHeight={16}
        colors={{ scheme: "category10" }}
        tooltip={({ id, value }) => (
          <strong>
            {id}: {value}
          </strong>
        )}
      />
    </div>
  );
};
