import { FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import FormikField from "../../../ui/FormikField";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import * as Yup from "yup";
import { roleOptions } from "../../Members/Interface";
import { adminApi } from "../../../utils/adminApi";
import { useEffect, useState } from "react";

export default function MemberFormModal({
  context,
  setShowSuccessToast,
  setBodyMessage,
  setShow,
  setShowFailureToast,
  show,
  id,
  setId,
  onSuccess,
  isResend,
  getListMembers,
}: any) {
  const [memberInfo, setMemberInfo]: any = useState({});
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .required("Phone Number is required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters  are allowed")
      .required("Last Name is required"),
    roles: Yup.string().required("Role is required"),
    department: Yup.string().required("Department is required"),
  });
  const handleSubmitData = async (values: any) => {
    if (isResend) {
      const response: any = await context?.reSendInviteMember(
        {
          email: values?.email,
          firstName: values?.firstName,
          lastName: values?.lastName,
          phone_number: values?.phone_number,
          department: [values?.department],
          roles: ["SUPERADMIN"],
        },
        id
      );

      if (response?.success) {
        setShowSuccessToast(true);
        getListMembers();
        setShow(false);
        setBodyMessage("Invitation Resent");
      } else {
        setShowFailureToast(true);
        setBodyMessage(response?.error?.[0]);
      }
    } else {
      const response = id
        ? await context?.editAdminMember(id, {
            first_name: values?.firstName,
            last_name: values?.lastName,
            contact_number: values?.phone_number,
            department: values?.department,
          })
        : await context?.handleInviteMember({
            firstName: values?.firstName?.trim().replace(/\s+/g, " "),
            lastName: values?.lastName,
            roles: values?.roles,
            email: values?.email,
            department: [values?.department],
            phone_number: values?.phone_number,
          });

      if (response?.success || response?.data?.success) {
        await context?.getMembers();
        // getadminMemberinfo();
        setShowSuccessToast(true);
        setBodyMessage(id ? "Member Updated" : "Member invited");
        formik.resetForm();
        setShow(false);
        setId(null);
        onSuccess();
      } else {
        if (response.error == 409) {
          setShowFailureToast(true);
          setBodyMessage("Duplicate User");
        }
      }
    }
  };

  const getadminMemberinfo = async () => {
    const response = await adminApi.getadminMemberDetails(id);
    setMemberInfo(response);
  };
  useEffect(() => {
    if (id) {
      getadminMemberinfo();
    }
    setMemberInfo({});
  }, [id]);

  const initialValues = {
    email: id ? memberInfo?.email : "",
    phone_number: id ? memberInfo?.contact_number : "",
    firstName: id ? memberInfo?.first_name : "",
    lastName: id ? memberInfo?.last_name : "",
    roles: ["SUPERADMIN"] || [],
    department: id ? memberInfo?.department : [],
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      try {
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });
  const {
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    setFieldValue,
  } = formik;
  const isAllFieldsFilled =
    values.firstName && values.lastName && values.phone_number && values.email;

  return (
    show && (
      <div>
        <Modal show={show} centered size="xl">
          <Modal.Header closeButton onClick={() => setShow(false)}>
            <div className="pt-3">
              <h6 className=" fw-semibold">
                {id ? (
                  <>{isResend ? "Resend Invitiation" : "Edit Member"}</>
                ) : (
                  "Invite Member"
                )}
              </h6>
            </div>
          </Modal.Header>

          <Modal.Body className="pt-2 p-4">
            <FormikProvider value={formik}>
              <Form noValidate={true} onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="firstName"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="First Name"
                      errors={errors}
                      value={values?.firstName}
                    />
                  </div>{" "}
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="lastName"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Last Name"
                      errors={errors}
                      value={values?.lastName}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="email"
                      type="email"
                      validationSchema={validationSchema}
                      label="Email address "
                      placeholder="Email address "
                      errors={errors}
                      value={values?.email}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">
                      Phone Number <span className="">*</span>
                    </label>
                    <FormGroup className="mt-2 ">
                      <div className="phone-input-css ">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values?.phone_number}
                          country={"in"}
                          defaultCountry="IN"
                          name="phone_number"
                          onChange={(phoneNumber: any) => {
                            setFieldValue("phone_number", phoneNumber);
                          }}
                          error={
                            values?.phone_number
                              ? isValidPhoneNumber(values?.phone_number)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                      <span className="field-error">
                        {values?.phone_number
                          ? isValidPhoneNumber(values?.phone_number)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>

                      {/* <ErrorMessage name="phone_number" /> */}
                    </FormGroup>
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2 mb-2">Department</label>
                    <Select
                      value={
                        values?.department?.length !== 0 && {
                          label: values?.department,
                          value: values?.department,
                        }
                      }
                      placeholder="Select.."
                      className="variable_Select"
                      options={context?.departmentList?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(e: any) => {
                        setFieldValue("department", e?.value);
                      }}
                    />
                    {/* <Select
                      value={
                        values?.department && {
                          label: values?.department,
                          value: values?.department,
                        }
                      }
                      placeholder="Select.."
                      className="variable_Select mt-2"
                      options={context?.departmentList?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(e: any) => {
                        setFieldValue("department", e?.value);
                      }}
                    /> */}
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">
                      Role <span className="">*</span>
                    </label>
                    <Select
                      className="variable_Select mt-2"
                      options={[
                        ...roleOptions,
                        ...[{ label: "SUPERADMIN", value: "SUPERADMIN" }],
                      ]}
                      isMulti={false} // or isMulti={false} depending on your needs
                      value={[
                        ...roleOptions,
                        ...[{ label: "SUPERADMIN", value: "SUPERADMIN" }],
                      ]?.filter((item: { value: string }) =>
                        values?.roles?.includes(item?.value)
                      )}
                      onChange={(e: any) => {
                        setFieldValue(
                          "roles",
                          e?.map((item: { value: string }) => item?.value)
                        );
                      }}
                      isDisabled={true}
                    />
                  </div>
                </div>

                {/* ---------------------------------- */}
              </Form>
            </FormikProvider>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancel-hallowbtn me-3"
              onClick={() => {
                resetForm();
                setShow(!show);
                setId(null);
              }}
            >
              Cancel
            </Button>

            <Button
              className="SignText updatebtndanger"
              size="sm"
              disabled={isSubmitting || !isAllFieldsFilled}
              onClick={() => {
                handleSubmitData(formik?.values);
              }}
            >
              {id ? <>{isResend ? "Send Invite" : "Submit"}</> : "Send Invite"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}
