import { Form, InputGroup } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { VersionProvider, useVersionHistory } from "./VersionHisoryProvider";
import { TemplateContextInterface } from "../Templates/TemplateProvider";
import { ContractContextInterface } from "../Contracts/ContractProvider";
import { Fragment, useEffect, useState } from "react";
import VersionDocumentCard from "./VersionDocumentCard";
import { Version } from "../../lib/VersionHistory";
import { IVersionHistoryResponse } from "../../interfaces/response/IVersionHistoryResponse";
import { MdCancel, MdCheckCircle, MdOutlineFileUpload } from "react-icons/md";
import { api } from "../../utils/api";
import SuccessToast from "../../ui/SuccessToast";
import FailureToast from "../../ui/FailureToast";
import { useAuthentication } from "../Authentication/AuthenticationProvider";

const VersionHistory = ({
  context,
}: {
  context: TemplateContextInterface | ContractContextInterface;
}) => {
  return (
    <>
      <VersionProvider>
        <VersionHIstoryOverview context={context} />
      </VersionProvider>
    </>
  );
};

export default VersionHistory;

const VersionHIstoryOverview = ({
  context,
}: {
  context: TemplateContextInterface | ContractContextInterface;
}) => {
  const location = useLocation();
  const authContext = useAuthentication();
  const [documentDetail, setDocumentDetail]: [Version[], Function] = useState(
    []
  );
  const [previousVersion, setPreviousVersion]: [string, Function] =
    useState("");

  const [headDocumentVersion, setHeadDocumentVersion]: [string, Function] =
    useState("");
  const [searchText, setSearchText] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [bodyMessage, setBodyMessage]: [any, Function] = useState("");

  const [currentVersionDetail, setCurrentVersionDeatil]: [
    IVersionHistoryResponse | undefined,
    Function
  ] = useState();

  const versionContext = useVersionHistory();

  const handleGetVersionHistory = async () => {
    try {
      let response = await versionContext?.handleGetVersionDetail(
        context?.data?.documentId,
        { search: searchText ? searchText : "" }
      );
      if (response?.success) {
        setDocumentDetail(response?.document_versions);
        let res = await versionContext?.getCurrentVersion(
          context?.data?.documentId
        );

        if (res) {
          setHeadDocumentVersion(res?.head_document_version);
          let currentVersion = response?.document_versions?.filter(
            (id: any) => {
              return id?.id === res?.head_document_version;
            }
          );

          if (currentVersion?.length > 0) {
            setCurrentVersionDeatil(res);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetVersionHistory();
  }, [searchText]);

  useEffect(() => {
    if (location.pathname.includes("version")) {
      handleGetVersionHistory();
    }
  }, [location.pathname.includes("version")]);

  const getCurrentDocumentVersion = async () => {
    try {
      let response = await versionContext?.getCurrentVersion(
        context?.data?.documentId
      );

      if (response) {
        let currentVersion = documentDetail?.filter((id) => {
          return id?.id === response?.head_document_version;
        });

        if (currentVersion?.length > 0) {
          setCurrentVersionDeatil(response);
        }
      }
    } catch (error) {}
  };

  const UploadFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadVersion(selectedFile);
    }
  };

  const UploadVersion = async (file: any) => {
    const formData = new FormData();
    formData.set("file", file);

    try {
      let response = await api?.UploadVersion(
        context?.data?.documentId,
        formData
      );

      if (response?.data?.success) {
        setShowSuccessToast(true);
        handleGetVersionHistory();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className={`${isMobile ? "container-fluid position-relative " : " "}`}
      >
        <div
          className={`${isMobile ? " d-none" : "  d-flex align-items-center "}`}
        >
          <div
            className={`${
              isMobile ? "d-none " : "container-fluid p-0 forinput"
            }`}
          >
            <InputGroup className="br-4 search-css focus-input">
              <AiOutlineSearch className="search-icon-css" />
              <Form.Control
                id="doc_searchQueryInput1"
                placeholder="Search version"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="ps-3 fs-14 search-input"
                onChange={(e) => setSearchText(e?.target?.value)}
              />
            </InputGroup>
          </div>
        </div>
        <div className={`${isMobile ? "" : "  d-none "}`}>
          <div className="  d-flex justify-content-end pb-4"></div>
          <div
            className={`${
              isMobile ? "container-fluid p-0 forinput " : " d-none"
            }`}
          ></div>
        </div>

        <div className="d-flex mt-4 mb-3 mx-2 justify-content-between">
          <h6 className="contract-title pb-1 m-0"> Recent version</h6>
          {/* <div>
            <span className=" rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white btn btn-primary btn-sm">
              Upload Version
              <span className=" ps-2 ">
                <MdOutlineFileUpload fontSize={18} />
              </span>
            </span>
            <input
              type="file"
              className=" uploadContractFile position-absolute"
              onChange={(e) => UploadFile(e)}
            />{" "}
          </div> */}
          {context?.data?.collaborators?.find(
            (el: any) => el?.user?.email === authContext?.currentUser?.email
          )?.access === "OWNER" &&
            context?.data?.status === "DRAFT" && (
              <>
                <div className="input-file-wrap-version ms-3 d-flex align-items-center justify-content-center  ">
                  <span className=" rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white btn btn-primary btn-sm">
                    Upload Version
                    <span className=" ps-2">
                      <MdOutlineFileUpload fontSize={18} />
                    </span>
                  </span>
                  <input
                    type="file"
                    accept=".docx"
                    className=" uploadContractFile position-absolute"
                    onChange={(e) => UploadFile(e)}
                  />
                </div>
              </>
            )}

          {/* <Stack direction="horizontal" className="align-items-center"> */}

          {/* </Stack> */}
        </div>

        <div
          className="recent-temp-div"
          style={{ maxHeight: "66vh", minHeight: "30vh" }}
        >
          <div
            className={
              isMobileOnly
                ? " d-flex  flex-column align-items-center"
                : "d-flex  flex-wrap "
            }
          >
            {documentDetail &&
              documentDetail?.map((doc: Version) => {
                return (
                  <Fragment key={doc?.id}>
                    <VersionDocumentCard
                      documentDetail={doc}
                      setDocumentDetail={setDocumentDetail}
                      getCurrentDocumentVersion={getCurrentDocumentVersion}
                      currentVersionDetail={currentVersionDetail}
                      headDocumentVersion={headDocumentVersion}
                      handleGetVersionHistory={handleGetVersionHistory}
                      setPreviousVersion={setPreviousVersion}
                      previousVersion={previousVersion}
                      headDocumentId={context?.data?.documentId}
                    />
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="File Uploaded Successfully"
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};
