import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import FailureToast from "../../ui/FailureToast";
import FormikField from "../../ui/FormikField";
import SuccessToast from "../../ui/SuccessToast";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import SettingsLayout from "./SettingsLayout";

interface ProfileInterface {
  first_name?: string | "";
  department?: any | "";
  roles?: any | [];
  contact_number?: string | "";
  industry?: string | "";
  last_name?: string | "";
  location?: string | "";
}

const ProfilePage = () => {
  const context = useAuthentication();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);

  // [TODO] Role support needed from backend
  const initialValues: ProfileInterface = {
    first_name: context?.currentUser?.first_name || "",
    last_name: context?.currentUser?.last_name || "",
    department: context?.currentUser?.department || "",
    contact_number: context?.currentUser?.contactNumber || "",
    location: context?.currentUser?.location || "",
    // industry: context?.currentUser?.industry || "",
    roles: context?.currentUser?.roles || "",
    // job_title: context?.currentUser?.job_title || "",
    // roles: context?.currentUser?.roles || "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("first Name is required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is required"),
    // industry: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    department: Yup.string().required("department is required"),
    // roles: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    contact_number: Yup.string()
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .typeError("That doesn't look like a phone number"),
    location: Yup.string().min(2, "Too Short!").max(100),

    // job_title: Yup.string().min(2, "Too Short!").max(100),
  });
  console.log(context?.currentUser?.roles, "context?.currentUser?.roles");

  const handleSubmit = async (values: any) => {
    setLoader(true);
    let res = await context?.updateProfileData({
      ...values,
      avatar: context?.currentUser?.avatar,
      roles: context?.currentUser?.roles,
    });
    context?.getCurrentUser();
    setLoader(false);
    if (res?.data?.success) {
      setShowSuccessToast(true);
      setBodyMessage("Personal Profile Updated");
    }
  };

  return (
    <SettingsLayout>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={`${isMobile ? " pt-2" : "pt-4"}`}>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="first_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="Name"
                      errors={errors}
                      autoFocus
                      value={values?.first_name}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="last_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Name"
                      errors={errors}
                      autoFocus
                      value={values?.last_name}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">Role</label>
                    {/* <Field
                      as="select"
                      id="mySelect"
                      name="roles"
                      className="form-control mt-2"
                      disabled
                    >
                      <option value="" label="Select an option" />
                      <option>
                        {context?.currentUser?.roles?.map((item) =>
                          item?.replaceAll("_", " ")
                        )}
                      </option>
                    </Field> */}
                    <Field
                      name="roles"
                      className="form-control mt-2"
                      value={context?.currentUser?.roles?.map((item: any) =>
                        item?.replace("_", " ")
                      )}
                      disabled
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">Department</label>
                    <Field
                      as="select"
                      id="selectedOption"
                      className="form-control mt-2"
                      name="department"
                      disabled
                    >
                      {context?.departmentList?.map((option): any => (
                        <option key={option} value={option}>
                          {context
                            ?.currentUser!?.department?.charAt(0)
                            ?.toUpperCase() +
                            context?.currentUser?.department
                              ?.slice(1)
                              ?.toLowerCase()}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="location"
                      type="text"
                      validationSchema={validationSchema}
                      label="Location"
                      placeholder="Location"
                      errors={errors}
                      autoFocus
                      value={values?.location}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">Contact Number</label>
                    <FormGroup className="mt-2 ">
                      <div className="phone-input-css ">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values?.contact_number}
                          name="contact_number"
                          country={"in"}
                          defaultCountry="IN"
                          onChange={(phoneNumber: any) => {
                            setFieldValue("contact_number", phoneNumber);
                          }}
                          error={
                            values?.contact_number
                              ? isValidPhoneNumber(values?.contact_number)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>

                      {errors?.contact_number && (
                        <small className="form-text text-danger">
                          <MdCancel className="me-1" />
                          {errors?.contact_number}
                        </small>
                      )}
                      <span className="field-error">
                        {values?.contact_number
                          ? isValidPhoneNumber(values?.contact_number)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>

                      <ErrorMessage name="contact_number" />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="cancel-hallowbtn me-3"
                  onClick={() => resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  className="updatebtndanger"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Update Profile {loader && <span className="loader"></span>}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </SettingsLayout>
  );
};

export default ProfilePage;
