import DataTable from "react-data-table-component";
import moment from "moment";
import { useAdminTemplateContext } from "./TemplateProvider";
import { useParams } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import NoTemplateFound from "./NoDataFound";

function Templates() {
  const context: any = useAdminTemplateContext();
  const { accountId: id }: any = useParams();
  const [search, setSearch] = useState("");
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);

  const handleRecentContract = async () => {
    try {
      await context?.handleGetTemplates(id, {
        limit: 10,
        page: 1,
        sort: "-createdAt",
        //  department: filterParams?.department,
        //        ? filterParams?.department?.toUpperCase()
        //        : "",
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleRecentContract();
  }, [id]);

  const noData = (department: string) => {
    if (department) {
      return department;
    } else {
      return <span className="text-align-center">{"-"}</span>;
    }
  };

  const columns: any = [
    {
      name: "Template name",
      minWidth: "250px",
      selector: (row: any) =>
        row?.name?.charAt(0)?.toUpperCase() + row?.name?.slice(1),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Department",
      width: "140px",
      selector: (row: any) => noData(row?.department),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Category",
      width: "300px",
      selector: (row: any) => noData(row?.category),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Updated at",
      width: "210px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
  ];
  const params = {
    search: search ? search : null,
    limit: limit ? limit : 10,
    page: page ? page : 1,
    sort: "-createdAt",
  };

  useEffect(() => {
    context?.handleGetTemplates(id, params);
  }, [search, limit, page]);
  return (
    <>
      <div className="d-flex pt-2 px-4">
        <div className="pt-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch className="search-icon-css" />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                setSearch(e?.target?.value);
              }}
            />
          </InputGroup>
        </div>
      </div>
      <div className="d-flex pt-4  px-4">
        <h6 className="contract-title"> All Templates</h6>
      </div>
      {context?.loading ? (
        <div className="d-flex justify-content-center">Loading...</div>
      ) : (
        <>
          {context?.recentTemplates?.length > 0 ? (
            <div className=" mb-5 px-4 mt-3">
              <DataTable
                className="data-table"
                columns={columns}
                selectableRows
                selectableRowsHighlight
                data={context?.recentTemplates || []}
                highlightOnHover
                progressPending={!context?.recentTemplates}
                progressComponent={<>Loading...</>}
                paginationDefaultPage={
                  parseInt(context?.metaData?.page?.toString()) || 1
                }
                onChangeRowsPerPage={(currentRowsPerPage: number) => {
                  setLimit(currentRowsPerPage);
                }}
                onChangePage={(page: number) => {
                  setPage(page);
                  setLimit(10);
                }}
                pagination
                paginationServer
                paginationPerPage={10} // Set the number of rows per page
                paginationRowsPerPageOptions={[10, 20, 30]} // Set the options for rows per page
                paginationTotalRows={context?.metaData?.total_count}
              />
            </div>
          ) : (
            <NoTemplateFound />
          )}
        </>
      )}
    </>
  );
}

export default Templates;
