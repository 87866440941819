import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { SUPPORTED_CURRENCIES, useVariables } from "./VariableProvider";
import { useLocation } from "react-router-dom";

const CurrencyVariableInput = ({
  variable,
  currencyType,
  value,
  onChange,
}: {
  variable?: IVariableResponse;
  currencyType?: string;
  value?: string;
  onChange?: Function;
}) => {
  const context = useVariables();
  const params = useLocation();
  const selectedVariable = context?.values.find(
    ({ id }) => variable?.id === id
  );

  const selectedCurrency = SUPPORTED_CURRENCIES.find(
    (currency) =>
      currency.type ===
      (selectedVariable?.currency_type ||
        variable?.currency_type ||
        currencyType)
  );

  const onCurrencyTypeChange = (currency: {
    type: string;
    symbol: string;
    format: string;
  }) => {
    if (variable) {
      context?.update(variable?.id, {
        value: selectedVariable?.value || variable.value,
        currency_type: currency?.type,
      });
    }
  };

  return (
    <>
      {context && params?.pathname?.includes("contracts") && (
        <InputGroup>
          <InputGroup.Text id="basic-addon2">
            <DropdownButton
              variant="outline-default"
              title={selectedCurrency?.symbol}
              id="input-group-dropdown-1"
              style={{ border: "none" }}
            >
              {SUPPORTED_CURRENCIES.map((currency) => (
                <Dropdown.Item
                  key={currency.type}
                  disabled={
                    currency.type ===
                    (selectedVariable?.currency_type ||
                      variable?.currency_type ||
                      currencyType)
                  }
                  onClick={() => {
                    onChange
                      ? onChange({ value, currencyType: currency.type })
                      : onCurrencyTypeChange(currency);
                  }}
                >
                  {currency.format}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup.Text>
          <input
            type="number"
            defaultValue={variable?.value || value}
            className="form-control"
            disabled={params?.search === "?suggestion=true"}
            min="0"
            onChange={(e) => {
              let value = 0;
              if (parseFloat(e.target.value) > 1) {
                value = parseFloat(e.target.value);
              }
              if (onChange) {
                onChange({ value: value, currencyType });
              } else {
                if (variable) {
                  context.update(variable.id, {
                    value: value.toString(),
                    currency_type:
                      selectedVariable?.currency_type || variable.currency_type,
                  });
                }
              }
            }}
            maxLength={250}
          />
        </InputGroup>
      )}
    </>
  );
};

export default CurrencyVariableInput;
