import { ReactNode, useRef, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import sigin_bg from "../../assets/sigin_bg.jpg";
import AppLayout from "../../layouts/AppLayout";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import ProfileHeader from "./ProfileHeader";
import { api } from "../../utils/api";
import FailureToast from "../../ui/FailureToast";
import SuccessToast from "../../ui/SuccessToast";
import { useLocation } from "react-router-dom";

const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const context = useAuthentication();
  const location = useLocation();
  const imageRef: any = useRef();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const handleFileChange = async (files: any) => {
    const formData = new FormData();
    URL.createObjectURL(files[0]);
    formData.append("file", files[0]);
    try {
      const res = location.pathname.includes("company_profile")
        ? await api.updateCompanyProfile(formData)
        : await api.updateProfileimage(formData);
      {
        location.pathname.includes("company_profile")
          ? context?.getCompanyProfile()
          : context?.getCurrentUser();
      }
      if (res?.data?.success || res?.status === 200) {
        setShowSuccessToast(true);
        setBodyMessage(
          location.pathname.includes("company_profile")
            ? "Company Logo Updates"
            : "Profile Picture Updated"
        );
      }
    } catch (error) {}
  };
  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  return (
    <AppLayout>
      <div className="py-2 ">
        <div className="profile">
          <div className=" d-flex align-items-center ">
            <div className=" position-relative p-2">
              <div className="circle ">
                <img
                  className="profile-pic"
                  src={
                    location.pathname.includes("company_profile")
                      ? context?.companyDetails?.logo || sigin_bg
                      : context?.currentUser?.avatar || sigin_bg
                  }
                  alt={"avatar"}
                />
              </div>
              <div className="p-image position-absolute p-2 ">
                <label htmlFor="fileInput " className="font ">
                  <MdOutlineModeEditOutline
                    color="#000000"
                    fontSize={20}
                    onClick={showOpenFileDialog}
                  />
                </label>
                <input
                  type="file"
                  id="fileInput"
                  ref={imageRef}
                  style={{ display: "none" }}
                  onChange={(e: any) => {
                    handleFileChange(e.target.files);
                  }}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </div>
            </div>
            <div className="mb-4">
              <span>
                <IoIosArrowForward fontSize={24} />
              </span>
            </div>
            <div className="p-2 mb-4 ">
              <span className="profile-username">
                {location.pathname.includes("company_profile")
                  ? context?.companyDetails?.name
                  : context?.currentUser?.name}
              </span>
              <br />
              <span>
                {location.pathname.includes("company_profile")
                  ? context?.companyDetails?.website
                  : context?.currentUser?.email}
              </span>
            </div>
          </div>
        </div>
        <div className="row nav bg-white py-4">
          <ProfileHeader />
          {children}
        </div>
      </div>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </AppLayout>
  );
};

export default SettingsLayout;
