import { FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import * as Yup from "yup";
import FormikField from "../../../../ui/FormikField";
import FormikSubmitButton from "../../../../ui/FormikSubmitButton";
import { roleOptions } from "../../Members/Interface";

export default function MemberFormModal({
  context,
  setShowSuccessToast,
  setBodyMessage,
  setShow,
  setShowFailureToast,
  show,
}: any) {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .required("Phone Number is required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is required"),
    roles: Yup.string().required("Role is required"),
  });

  const handleSubmitData = async (values: any) => {
    const response = await context?.handleInviteMember({
      ...values,
    });
    if (response?.success) {
      context?.getMembers();
      setShowSuccessToast(true);
      setBodyMessage("Member invited successfully..");
      formik.resetForm();
      setShow(false);
    } else {
      setShowFailureToast(true);
      setBodyMessage("Error");
    }
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: "",
      phone_number: "",
      firstName: "",
      lastName: "",
      roles: ["SUPERADMIN"] || [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      try {
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });
  const {
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    setFieldValue,
  } = formik;

  return (
    show && (
      <div>
        <Modal show={show} centered size="xl">
          <Modal.Header>
            <div className="pt-3">
              <h6 className=" fw-semibold">vIevime Memberber</h6>
            </div>
          </Modal.Header>

          <Modal.Body className="pt-2 p-4">
            <FormikProvider value={formik}>
              <Form noValidate={true} onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="firstName"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="First Name"
                      errors={errors}
                    />
                  </div>{" "}
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="lastName"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Last Name"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="email"
                      type="email"
                      validationSchema={validationSchema}
                      label="Email address "
                      placeholder="Email address "
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">
                      Phone Number <span className="">*</span>
                    </label>
                    <FormGroup className="mt-2 ">
                      <div className="phone-input-css ">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values?.phone_number}
                          country={"in"}
                          defaultCountry="IN"
                          name="phone_number"
                          onChange={(phoneNumber: any) => {
                            setFieldValue("phone_number", phoneNumber);
                          }}
                          error={
                            values?.phone_number
                              ? isValidPhoneNumber(values?.phone_number)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                      <span className="field-error">
                        {values?.phone_number
                          ? isValidPhoneNumber(values?.phone_number)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>

                      {/* <ErrorMessage name="phone_number" /> */}
                    </FormGroup>
                  </div>
                </div>

                {/* ---------------------------------- */}
              </Form>
            </FormikProvider>

            <div className="row">
              <div className="col-md-6 col-12">
                <label className="text-dark mt-2">
                  Role <span className="">*</span>
                </label>
                <Select
                  className="variable_Select"
                  options={[
                    ...roleOptions,
                    ...[{ label: "SUPERADMIN", value: "SUPERADMIN" }],
                  ]}
                  isMulti={true} // or isMulti={false} depending on your needs
                  value={[
                    ...roleOptions,
                    ...[{ label: "SUPERADMIN", value: "SUPERADMIN" }],
                  ]?.filter((item: { value: string }) =>
                    values?.roles?.includes(item?.value)
                  )}
                  onChange={(e: any) => {
                    setFieldValue(
                      "roles",
                      e?.map((item: { value: string }) => item?.value)
                    );
                  }}
                  isDisabled={true}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancel-hallowbtn me-3"
              onClick={() => {
                resetForm();
                setShow(!show);
              }}
            >
              Cancel
            </Button>

            <FormikSubmitButton
              isSubmitting={isSubmitting}
              className="updatebtndanger"
              size="md"
            >
              <Button
                className="SignText"
                size="sm"
                disabled={isSubmitting}
                onClick={() => {
                  handleSubmitData(formik?.values);
                }}
              >
                Send Invite
              </Button>
            </FormikSubmitButton>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}
