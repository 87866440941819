import RecentFolderList from "./RecentFolderList";
import { useFolders } from "../FoldersProvider";
import { IFolderResponse } from "../../../interfaces/response/IFolderResponse";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../../utils/routes";
import { Folder } from "../../../lib/Folder";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import NoFolderFound from "./NoFolderFound";
import { MdOutlineKeyboardArrowRight, MdOutlinePeople } from "react-icons/md";

interface FolderPathProps {
  id: string;
  name: string;
  parentFolder: string;
}

function RecentFolders({
  setACtiveFolder,
  setFolderClick,
  onFolderCLick,
  setAddFolderPopup,
  setmoveFolders,
  setShareFolder,
  setMoveToFolders,
  setIsActiveMove,
}: {
  setACtiveFolder: Function;
  setFolderClick: Function;
  onFolderCLick: Folder | undefined;
  setAddFolderPopup: Function;
  setmoveFolders: Function;
  setShareFolder: Function;
  setMoveToFolders: Function;
  setIsActiveMove: Function;
}) {
  const context = useFolders();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [folderPath, setFolderPath]: [[], Function] = useState([]);
  const [isPathActive, setIsPathActive]: [boolean, Function] = useState(false);
  const [activePathName, setActivePathName]: [string, Function] = useState("");
  const [activePathid, setActivePathid]: [string, Function] = useState("");

  const [isActiveSEction, setIsActiveSection]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(true);

  const getDocumentDataById = async () => {
    try {
      let response = await context?.handleFolderById(id);
      if (response?.success) {
        context?.setSubFolders(response?.folder?.sub_folders);
        context?.setFolderList(response?.folder);
        setFolderClick(response?.folder);
        setFolderPath(response?.folder?.path);
        setACtiveFolder(response?.folder?.sub_folders);
      }
    } catch (error) {}
  };

  const handleFolderList = async () => {
    if (location.pathname.includes("private")) {
      try {
        navigate(routes.privateFolders);
        let response = await context?.handleFolderList();
        if (response) {
          if (location.pathname.includes("private")) {
            let privateList = response?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PRIVATE";
              }
            );

            context?.setSubFolders(privateList);
          } else if (location.pathname.includes("public")) {
            let publicList = response?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PUBLIC";
              }
            );
            context?.setSubFolders(publicList);
          }
          handleRecentFolder();
          setFolderClick([]);
          setFolderPath([]);
        }
      } catch (error) {}
    } else if (location.pathname.includes("public")) {
      try {
        navigate(routes.publicFolder);
        let response = await context?.handleFolderList();
        if (location.pathname.includes("private")) {
          let privateList = response?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PRIVATE";
            }
          );
          context?.setSubFolders(privateList);
        } else if (location.pathname.includes("public")) {
          let publicList = response?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PUBLIC";
            }
          );
          context?.setSubFolders(publicList);
        }
        setFolderClick([]);
        setFolderPath([]);
      } catch (error) {}
    }
  };

  const onPathClick = async (documentId: string) => {
    try {
      let response = await context?.handleFolderById(documentId);

      setFolderPath(response?.folder?.path);
      setACtiveFolder(response?.folder?.sub_folders);
      context?.setSubFolders(response?.folder?.sub_folders);
      context?.setFolderList(response?.folder);
    } catch (error) {}
  };

  const handleRecentFolder = async () => {
    try {
      await context?.handleFolderList();
    } catch (error) {}
  };

  useEffect(() => {
    if (id!) {
      getDocumentDataById();
    }
  }, [id!]);

  useEffect(() => {
    setFolderClick([]);
    setFolderPath([]);
  }, [
    location.pathname.includes("private"),
    location.pathname.includes("public"),
  ]);

  useEffect(() => {
    if (
      context?.folderList?.contracts?.length === 0 &&
      context?.folderList?.templates?.length === 0 &&
      context?.subFolders?.length === 0
    ) {
      setIsActiveSection(true);
    } else {
      setIsActiveSection(false);
    }
  }, [
    context?.folderList?.contracts?.length,
    context?.folderList?.templates?.length,
    context?.subFolders?.length,
  ]);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [context?.subFolders]);

  return (
    <>
      <div className="d-flex mt-4 mb-1 mx-2 ">
        <h6 className="contract-title pb-1 m-0" onClick={handleFolderList}>
          All folders
        </h6>
        {folderPath?.length >= 5 ? (
          <>
            <MdOutlineKeyboardArrowRight color="black" fontSize={"20px"} />

            <Dropdown>
              <Dropdown.Toggle
                variant="default"
                id="dropdown-basic"
                style={{ border: "none" }}
              >
                <BsThreeDots />
              </Dropdown.Toggle>

              <Dropdown.Menu className="">
                {folderPath?.map((path: FolderPathProps) => {
                  return (
                    <>
                      {path?.name !== "/" && (
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            onPathClick(path?.id);
                            setIsPathActive(true);
                            setActivePathName(path?.name);
                            setActivePathid(path?.id);
                            navigate(
                              location.pathname.includes("private")
                                ? generatePath(routes.privatefolderId, {
                                    id: path?.id,
                                  })
                                : generatePath(routes.publicfolderId, {
                                    id: path?.id,
                                  })
                            );
                          }}
                        >
                          {path?.name}
                        </Dropdown.Item>
                      )}
                    </>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </>
        ) : (
          <>
            {folderPath?.map((path: FolderPathProps) => {
              return (
                <>
                  {path?.name === "/" ? (
                    ""
                  ) : (
                    <>
                      <MdOutlineKeyboardArrowRight
                        color="black"
                        fontSize={"20px"}
                      />
                      <span
                        className="contract-title pb-1 m-0"
                        onClick={() => {
                          onPathClick(path?.id);
                          setIsPathActive(true);
                          setActivePathName(path?.name);
                          setActivePathid(path?.id);
                        }}
                      >
                        {path?.name}
                      </span>
                    </>
                  )}
                </>
              );
            })}
          </>
        )}
        {onFolderCLick?.id ? (
          <>
            <MdOutlineKeyboardArrowRight color="black" fontSize={"20px"} />
            <h6 className="contract-title pb-1 m-0">
              {!isPathActive ? onFolderCLick?.name : activePathName}
            </h6>
          </>
        ) : (
          ""
        )}
        {(onFolderCLick?.id || activePathid) && (
          <>
            <span className="share_folder">
              <MdOutlinePeople
                fontSize={20}
                onClick={() => {
                  setShareFolder(true);
                  context?.setShareFolderId(onFolderCLick && onFolderCLick?.id);
                }}
              />
            </span>
          </>
        )}
      </div>

      {(isActiveSEction || (!id && context?.subFolders?.length === 0)) && (
        <>
          <NoFolderFound
            setAddFolderPopup={setAddFolderPopup}
            setMoveToFolders={setMoveToFolders}
            setIsActiveMove={setIsActiveMove}
          />
        </>
      )}

      {context?.subFolders?.length !== 0 && (
        <>
          {loader ? (
            <span>loading...</span>
          ) : (
            <>
              {" "}
              <div className="recent-temp-div">
                {context?.subFolders == undefined ||
                context?.subFolders.length === 0 ? (
                  <>
                    <NoFolderFound
                      setAddFolderPopup={setAddFolderPopup}
                      setMoveToFolders={setMoveToFolders}
                      setIsActiveMove={setIsActiveMove}
                    />
                  </>
                ) : (
                  <div className={"d-flex template-width"}>
                    {context?.subFolders &&
                      context?.subFolders?.map((folder: IFolderResponse) => {
                        return (
                          <>
                            <div
                              className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
                              key={folder.id}
                            >
                              <RecentFolderList
                                folder={folder}
                                setFolderClick={setFolderClick}
                                setIsPathActive={setIsPathActive}
                                setmoveFolders={setmoveFolders}
                                // setShareFolder={setShareFolder}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default RecentFolders;
