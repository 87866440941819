import { Button } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DownloadButton = ({ title }: { title: any }) => {
  const generatePDF = async (title: any) => {
    const input: any = document.getElementById("pdf-content");
    const canvas = await html2canvas(input);
    const pdf = new jsPDF();
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      0,
      0,
      pdf.internal.pageSize.getWidth() - 10,
      pdf.internal.pageSize.getHeight() - 10
    );
    pdf.save(title);
  };

  return (
    <div className="d-none  d-lg-flex justify-content-end cursor-pointer ">
      <Button onClick={() => generatePDF(title)} style={{ fontSize: "14px" }}>
        Download as PDF
      </Button>
    </div>
  );
};

export default DownloadButton;
