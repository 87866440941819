import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ITemplateFilterParams } from "../../interfaces/ITemplateFilterParams";
import { IActivityFeedResponse } from "../../interfaces/response/IActivityFeedResponse";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { ActivityFeed } from "../../lib/ActivityFeed";
import { Template } from "../../lib/Template";
import { Variable } from "../../lib/Variable";
import { api } from "../../utils/api";
import { Collaborator } from "../../lib/Collaborator";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
export interface TemplateContextInterface {
  isTemplate: boolean;
  setTemplate: Function;
  data: Template;
  refresh: Function;
  template: Template;
  usersAutoComplete: Function;
  getUserDetails: Function;
  inviteCollaborator: Function;
  accessOption: string;
  setAccessOption: Function;
  notifyPeople: boolean;
  setNotifyPeople: Function;
  sendMessage: string;
  setSendMessage: Function;
  values: Array<{}>;
  setValues: Function;
  setInitialValue: Function;
  show: boolean;
  setShow: Function;
  showSuccessToast: boolean;
  setShowSuccessToast: Function;
  showFailureToast: boolean;
  setShowFailureToast: Function;
  handleDeleteTemplate: Function;
  activities: Array<ActivityFeed> | null;
  templateActivities: Array<ActivityFeed> | null;
  setTemplateActivity: Function;
  documentId: string | undefined;
  setDocumentId: Function;
  variables: Array<Variable> | null;
  setVariables: Function;
  addDocumentVariables: Function;
  variableTypes: any;
  setvariableTypes: Function;
  getDocumentVariables: Function;
  handleDeleteVariable: Function;
  handleUpdateDocumentVariable: Function;
  filterParams: ITemplateFilterParams;
  changeFilter: (params: ITemplateFilterParams) => void;
  updateName: Function;
  publishDocument: Function;
  loading: boolean;
  setLoading: Function;
  showToolbar: boolean;
  setShowToolbar: Function;
  waterMarkText: string;
  setWaterMarktext: Function;
  getTemplateOwnerId: () => string | void;
  isOwner: (id: string) => boolean;
  updateCollaboratorsAccess: (collaborator_access: any) => Promise<void>;
  handleTemplateDownload: Function;
  downloadStatus: boolean;
  setDownloadStatus: Function;
  removeAccess: (collaborator_id: string) => Promise<void>;
  handleRefresh: Function;
  transferOwnership: Function;
  departmentList: [];
  defaultCategories: ITemplateCategory[];
  categoriesAutocomplete: Function;
  updateDocumentName: Function;
}

const TemplateContext = React.createContext<TemplateContextInterface | null>(
  null
);
interface TemplatesProviderProps {
  children: ReactNode;
}
const TemplateProvider = ({ children }: TemplatesProviderProps) => {
  const { id } = useParams();
  const initialFilter: ITemplateFilterParams = useMemo(() => {
    const params: ITemplateFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    };
    return params;
  }, [id]);
  const [template, setTemplate] = useState<Template | null>(null);
  const [templateActivities, setTemplateActivity]: [
    ActivityFeed | null,
    Function
  ] = useState(null);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [documentId, setDocumentId]: [string, Function] = useState("");
  const [variables, setVariables]: [Variable | null, Function] = useState(null);
  const [accessOption, setAccessOption] = useState<string>("");
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false);
  const [sendMessage, setSendMessage] = useState<string>("");
  const [values, setValues] = useState([]);
  const [show, setShow] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [variableTypes, setvariableTypes]: [any, Function] = useState();
  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);
  const [loading, setLoading] = useState<boolean>(false);
  const [showToolbar, setShowToolbar] = useState<boolean>(true);
  const [waterMarkText, setWaterMarktext]: [string, Function] = useState("");
  const [downloadStatus, setDownloadStatus] = useState<boolean>(false);
  const [defaultCategories, setDefaultCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);
  const navigate = useNavigate();

  const isOwner = (id: string) => {
    if (!template) return false;
    return id === template?.createdBy?.sub ? true : false;
  };

  const getTemplateOwnerId = () => {
    if (!template) return;
    if (
      template.collaborators?.length === 1 &&
      !!template.collaborators?.length
    ) {
      return template.collaborators[0].user.sub;
    } else {
      return template.collaborators?.find(
        (s: Collaborator) => s.user.sub === template.createdBy?.sub
      );
    }
  };

  const updateCollaboratorsAccess = async (collaborator_access: any) => {
    await api.updateCollaboratorsinTemplate(id!, { collaborator_access });
  };

  const removeAccess = async (collaborator_id: string) => {
    await api.removeCollaboratorAccessinTemplate(id!, collaborator_id);
    getTemplate(id!);
  };

  // ...................Department..............
  const getDepartments = async () => {
    const res = await api.getDepartments();

    setDepartmentList(res?.data?.departments);
  };

  const updateDocumentName = async (id: string, params: any) => {
    try {
      let res = await api.updateTemplateName(id, params);
      return res;
    } catch (error) {}
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (id) {
      getTemplate(id);
      handleTemplateActivities(id);
      getVariablesType();
    }
  }, [id]);
  const handleDeleteVariable = async (docId: string, varId: string) => {
    return await api.removeDocumentVariables(docId, varId);
  };
  const getVariablesType = async () => {
    const res = await api.getVariableTypes();
    if (res.success) {
      setvariableTypes(res);
    }
  };
  const handleAddDocumentVariables = async (documentId: string, params: {}) => {
    try {
      const response = await api.documentVariables(documentId, params);
      return response;
    } catch (error) {}
  };
  const handleUpdateDocumentVariable = async (
    documentId: string,
    varId: string,
    params: {}
  ) => {
    try {
      const response = await api.updateDocumentVariables(
        documentId,
        varId,
        params
      );
      return response;
    } catch (error) {}
  };

  const handleCategoriesAutocomplete = useCallback(
    async (search: string): Promise<string[]> =>
      await api.getTemplatesCategoriesAutocomplete({ search: search }),
    []
  );

  useEffect(() => {
    const loadData = async () => {
      const categories = await handleCategoriesAutocomplete("");
      setDefaultCategories(categories);
    };
    loadData();
  }, [handleCategoriesAutocomplete]);

  useEffect(() => {
    if (documentId) {
      getDocumentVariables(documentId, filterParams);
    }
  }, [documentId, filterParams]);
  useEffect(() => {
    const loadData = async () => handleDocumentVariables(documentId);
    if (!!handleDocumentVariables && documentId) {
      loadData();
    }
  }, [initialFilter, documentId]);
  const getDocumentVariables = async (
    id: string,
    filterParams: ITemplateFilterParams
  ) => {
    let response = await api.getDocumentVariables(id, {
      ...filterParams,
      name: filterParams?.name ? filterParams?.name : "",
    });
    const _variable: Variable[] = response?.variables?.map(
      (variables: IVariableResponse) => new Variable(variables)
    );
    response.success && setVariables(_variable);
    return _variable;
  };
  const handleDocumentVariables = useCallback(
    async (id: string) => {
      const variable = await getDocumentVariables(id, {
        ...initialFilter,
        limit: 10,
        sort: "-updatedAt",
        name: initialFilter?.name ? initialFilter?.name : "",
      });
      setVariables(variable);
      return variable;
    },
    [initialFilter]
  );

  const handleTemplateActivities = async (id: string) => {
    let response = await api.getTemplateActivities(id);
    const templateActivities: ActivityFeed[] = response.activities.map(
      (activityLogs: IActivityFeedResponse) => new ActivityFeed(activityLogs)
    );

    setTemplateActivity(templateActivities);
  };
  const getTemplate = async (id: string) => {
    setLoading(true);
    try {
      let response = await api.getTemplate(id);
      const template: Template = new Template(response);
      setTemplate(template);
    } catch (e) {
      navigate("/templates");
    }
    setLoading(false);
  };
  const handleDeleteTemplate = async (id: string) => {
    return await api.deleteTemplate(id);
  };
  const handleUsersAutocomplete = async (params: Object) => {
    return await api.getUsersAutocomplete(params);
  };
  const getUserDetails = async () => {
    return await api?.getCollaborators({ limit: 100 });
  };
  const handleInviteCollaborator = async (
    id: string,
    params: {
      collaborators_id: [];
      message: string;
      notify: boolean;
      access: string;
    }
  ) => {
    try {
      const response = await api.inviteCollaborators(id, params);
      return response;
    } catch (error) {}
  };

  const handleUpdateTemplateName = async (id: string, name: string) => {
    try {
      const response = await api.updateTemplateName(id, {
        name,
      });

      return response;
    } catch (error) {}
  };
  const setInitialValue = () => {
    setShow(false);
    setAccessOption("");
    setNotifyPeople(false);
    setSendMessage("");
    setValues([]);
  };
  const handleRefresh = async () => {
    await api.getTemplate(id!);
  };

  const publishDocument = async (id: string, params: ITemplateFilterParams) => {
    const response = await api.publishDocuments(id!, params);
    return response;
  };

  const handleTemplateDownload = async (id: string) => {
    try {
      let response = await api.downloadTemplate(id);
      fetch(response.file_path)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "downloaded-pdf.pdf";
          link.click();
        });
      return response;
    } catch (error) {}
  };

  const transferOwnership = async (userId: string, params: any) => {
    try {
      let response = await api.TemplateTransferOwnerShip(userId, params);
      return response;
    } catch (error) {}
  };
  const value: TemplateContextInterface | null = !!template
    ? {
        setTemplate,
        departmentList,
        defaultCategories,
        isTemplate: true,
        data: template,
        template,
        categoriesAutocomplete: handleCategoriesAutocomplete,
        updateDocumentName,

        inviteCollaborator: handleInviteCollaborator,
        usersAutoComplete: handleUsersAutocomplete,
        getUserDetails,
        accessOption,
        setAccessOption,
        notifyPeople,
        setNotifyPeople,
        sendMessage,
        setSendMessage,
        values,
        setValues,
        setInitialValue,
        show,
        setShow,
        showSuccessToast,
        setShowSuccessToast,
        showFailureToast,
        setShowFailureToast,
        handleDeleteTemplate,
        templateActivities,
        activities: templateActivities,
        setTemplateActivity,
        documentId,
        setDocumentId,
        variables,
        setVariables,
        addDocumentVariables: handleAddDocumentVariables,
        variableTypes,
        setvariableTypes,
        getDocumentVariables,
        handleDeleteVariable,
        handleUpdateDocumentVariable,
        filterParams,
        changeFilter: (params: ITemplateFilterParams) =>
          setFilterParams(params),
        updateName: handleUpdateTemplateName,
        refresh: () => {
          if (id) {
            getTemplate(id);
          }
        },
        publishDocument,
        loading,
        setLoading,
        showToolbar,
        setShowToolbar,
        waterMarkText,
        setWaterMarktext,
        getTemplateOwnerId,
        isOwner,
        updateCollaboratorsAccess,
        handleTemplateDownload,
        downloadStatus,
        setDownloadStatus,
        removeAccess,
        handleRefresh,
        transferOwnership,
      }
    : null;
  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};
const useTemplate = () => {
  return React.useContext(TemplateContext);
};
export { TemplateProvider, useTemplate };
