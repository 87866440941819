import { isMobileOnly } from "react-device-detect";
import ActivityActions from "./components/ActivityActions";
import { api } from "../../../utils/api";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ActivityDownloadPdf from "./ActivityFeedDownloadPdf";
import { updateText } from "../../../utils";
import { useContract } from "../../Contracts/ContractProvider";

const ActivityFeeds = () => {
  const { id } = useParams();
  const location = useLocation();
  const context = useContract();
  const [activityFeed, setActivityFeed]: [any, Function] = useState();
  const [activeActivityDetail, setActiveActivityDetail]: [any, Function] =
    useState();
  const [selectedAcitivityId, setSelectedActiveId]: [string | null, Function] =
    useState(null);

  const handleActivity = async () => {
    try {
      let response;
      if (location?.pathname?.includes("templates")) {
        response = await api?.getTemplateActivities(id!);
      } else {
        response = await api?.getContractActivities(id!);
      }

      if (response?.success) {
        setActivityFeed(response?.activities);
        setActiveActivityDetail(response?.activities?.[0]);
      }
    } catch (error) {}
  };
  useEffect(() => {
    let activeDetail = activityFeed?.find(
      (el: any) => el?.id === selectedAcitivityId
    );
    setActiveActivityDetail(activeDetail);
  }, [selectedAcitivityId]);

  useEffect(() => {
    if (location.pathname.includes("activities")) {
      handleActivity();
    }
  }, [location.pathname.includes("activities")]);

  const getSignatories = (activityFeed: any) => {
    const internalSingers = activityFeed?.contract_id?.internal_signer?.map(
      (el: any) => updateText(`${el?.user?.first_name} ${el?.user?.last_name}`)
    );
    const externalUser = activityFeed?.contract_id?.external_users
      ?.filter((el: any) => el?.access !== "OWNER")
      ?.map((el: any) => updateText(el?.name));
    return [...internalSingers, ...externalUser]?.join(", ") || "-";
  };

  const getCurrentUserName = () => {
    const currentUser = activeActivityDetail?.contract_id?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );

    return updateText(
      `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`
    );
  };

  return (
    <>
      <div className=" d-flex justify-content-end">
        <div
          className={isMobileOnly ? "fordashboard " : "fordashboard px-3"}
        ></div>
      </div>

      <div className=" pt-3 row">
        <div className=" col-12 col-md-4">
          {activityFeed
            ?.filter((el: any) => el?.action !== "CHANGED")
            ?.map((activity: any) => {
              return (
                <div>
                  <div
                    onClick={() =>
                      selectedAcitivityId === activity?.id
                        ? setSelectedActiveId(null)
                        : setSelectedActiveId(activity?.id)
                    }
                    className={`${
                      selectedAcitivityId === activity?.id
                        ? "activityfeed"
                        : "activityfeedInActive"
                    }`}
                  >
                    <ActivityActions
                      item={activity}
                      activeId={selectedAcitivityId}
                      setActiveId={setSelectedActiveId}
                    />
                  </div>
                </div>
              );
            })}
        </div>

        {selectedAcitivityId === null ? (
          <div className=" col-12 col-md-8">
            <div className=" activityFeedDetails p-3">
              <div className="d-flex justify-content-end cursor-pointer">
                <PDFDownloadLink
                  style={{ fontSize: "14px" }}
                  className="btn btn-primary"
                  document={<ActivityDownloadPdf data={activeActivityDetail} />}
                  fileName={`ActivityFeed.pdf`}
                >
                  {({ loading }): boolean | string =>
                    loading ? "Loading document..." : "Download as PDF"
                  }
                </PDFDownloadLink>
              </div>
              <div>
                <h6 className=" contract-title pb-1">Summary</h6>
                <div className=" row pt-4 ">
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        {location?.pathname?.includes("templates")
                          ? "Template Name"
                          : "Contract Name"}
                      </h6>
                      <div
                        className=" fw-light"
                        style={{ maxWidth: "300px", wordBreak: "break-all" }}
                      >
                        {activityFeed?.[0]?.contract_id
                          ? activityFeed?.[0]?.contract_id?.name || "-"
                          : activityFeed?.[0]?.template_id?.name || "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        {location?.pathname?.includes("templates")
                          ? "Template ID"
                          : "Contract ID"}
                      </h6>
                      <p>
                        {activityFeed?.[0]?.contract_id
                          ? activityFeed?.[0]?.contract_id?.id || "-"
                          : activityFeed?.[0]?.template_id?.id || "-"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" row pt-4 ">
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        Owner
                      </h6>
                      <p>
                        {activityFeed?.[0]?.contract_id ? (
                          <>
                            {updateText(
                              `${context?.data?.owner?.name} ${context?.data?.owner?.last_name}`
                            )}
                            {/* {activityFeed?.[0]?.contract_id?.created_by?.name ||
                              "-"}{" "}
                            {
                              activityFeed?.[0]?.contract_id?.created_by
                                ?.last_name
                            } */}
                          </>
                        ) : (
                          <>
                            {activityFeed?.[0]?.template_id?.created_by?.name ||
                              "-"}{" "}
                            {
                              activityFeed?.[0]?.template_id?.created_by
                                ?.last_name
                            }
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        Created Date
                      </h6>
                      <p>
                        {moment(
                          activityFeed?.[0]?.contract_id
                            ? activityFeed?.[0]?.contract_id?.createdAt
                            : activityFeed?.[0]?.template_id?.createdAt
                        ).format("DD MMM YYYY, hh:mm A") || "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        Status
                      </h6>
                      {location?.pathname?.includes("templates") ? (
                        <>
                          <span className=" fw-light">
                            <p>
                              <span className=" fw-light">
                                {activityFeed?.[0]?.template_id?.status
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                  activityFeed?.[0]?.template_id?.status
                                    ?.slice(1)
                                    ?.toLowerCase()}
                              </span>
                            </p>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className=" fw-light">
                            <p>
                              <span className=" fw-light">
                                {activityFeed?.[0]?.contract_id?.status ===
                                "SIGN_INITIATED"
                                  ? "Sign Initiated"
                                  : activityFeed?.[0]?.contract_id?.status ===
                                    "PENDING_APPROVAL"
                                  ? "Pending for approval"
                                  : activityFeed?.[0]?.contract_id?.status ===
                                    "STAMPING_FAILED"
                                  ? "E-stamp failed"
                                  : activityFeed?.[0].contract_id?.status ===
                                    "PENDING_NEGOTIATION_APPROVAL"
                                  ? "Pending for negotiation approval"
                                  : activityFeed?.[0]?.contract_id?.status ===
                                    "PENDING_NEGOTIATION"
                                  ? "Pending for negotiation"
                                  : activityFeed?.[0]?.contract_id?.status ===
                                    "COMPLETED"
                                  ? "Signed"
                                  : activityFeed?.[0]?.contract_id?.status
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    activityFeed?.[0]?.contract_id?.status
                                      ?.slice(1)
                                      ?.toLowerCase()}
                              </span>
                            </p>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        Status Date
                      </h6>
                      <p>
                        {moment(activityFeed?.[0]?.updatedAt).format(
                          "DD MMM YYYY, hh:mm A"
                        ) || "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-12 col-md-6">
                    <div>
                      <h6
                        className=" fw-semibold "
                        style={{ fontSize: "14px" }}
                      >
                        Collaborators
                      </h6>
                      <p>
                        {activityFeed?.[0]?.contract_id ? (
                          <>
                            {activityFeed?.[0]?.contract_id?.collaborators
                              ?.length === 1 ? (
                              <>
                                {
                                  activityFeed?.[0]?.contract_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.name
                                }{" "}
                                {
                                  activityFeed?.[0]?.contract_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.last_name
                                }
                              </>
                            ) : (
                              <>
                                {
                                  activityFeed?.[0]?.contract_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.name
                                }{" "}
                                {
                                  activityFeed?.[0]?.contract_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.last_name
                                }
                                {", "}
                                {activityFeed?.[0]?.contract_id?.collaborators
                                  ?.filter((el: any) => el?.access !== "OWNER")
                                  // ?.map((el: any) => el?.user)
                                  ?.map((el: any) =>
                                    updateText(
                                      `${el?.user?.first_name} ${el?.user?.last_name}`
                                    )
                                  )
                                  ?.join(", ")}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {activityFeed?.[0]?.template_id?.collaborators
                              ?.length === 1 ? (
                              <>
                                {
                                  activityFeed?.[0]?.template_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.name
                                }{" "}
                                {
                                  activityFeed?.[0]?.template_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.last_name
                                }
                              </>
                            ) : (
                              <>
                                {
                                  activityFeed?.[0]?.template_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.name
                                }{" "}
                                {
                                  activityFeed?.[0]?.template_id?.collaborators?.find(
                                    (el: any) => el?.access === "OWNER"
                                  )?.user?.last_name
                                }
                                {" , "}
                                {activityFeed?.[0]?.template_id?.collaborators
                                  ?.filter((el: any) => el?.access !== "OWNER")
                                  ?.map((el: any) => el?.user)
                                  ?.map((el: any) =>
                                    updateText(
                                      `${el?.first_name} ${el?.last_name}`
                                    )
                                  )
                                  ?.join(" , ")}
                              </>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  {activityFeed?.[0]?.contract_id && (
                    <>
                      {" "}
                      <div className="col-12 col-md-6">
                        <div>
                          <h6
                            className=" fw-semibold "
                            style={{ fontSize: "14px" }}
                          >
                            Signatories
                          </h6>
                          <p>
                            {activityFeed?.[0]?.contract_id &&
                              getSignatories(activityFeed?.[0])}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          selectedAcitivityId && (
            <div className=" col-12 col-md-8">
              <div className=" activityFeedDetails p-3">
                <div className="d-flex justify-content-end cursor-pointer">
                  <PDFDownloadLink
                    style={{ fontSize: "14px" }}
                    className="btn btn-primary"
                    document={
                      <ActivityDownloadPdf data={activeActivityDetail} />
                    }
                    fileName={`ActivityFeed.pdf`}
                  >
                    {({ loading }): boolean | string =>
                      loading ? "Loading document..." : "Download as PDF"
                    }
                  </PDFDownloadLink>
                </div>
                <div>
                  <h6 className=" contract-title pb-1">Summary</h6>
                  <div className=" row pt-4 ">
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          {location?.pathname?.includes("templates")
                            ? "Template Name"
                            : "Contract Name"}
                        </h6>
                        <div
                          className=" fw-light "
                          style={{ maxWidth: "300px", wordBreak: "break-all" }}
                        >
                          {activeActivityDetail?.contract_id
                            ? activeActivityDetail?.contract_id?.name || "-"
                            : activeActivityDetail?.template_id?.name || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          {location?.pathname?.includes("templates")
                            ? "Template ID"
                            : "Contract ID"}
                        </h6>
                        <p>
                          {activeActivityDetail?.contract_id
                            ? activeActivityDetail?.contract_id?.id || "-"
                            : activeActivityDetail?.template_id?.id || "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" row pt-4 ">
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          Owner
                        </h6>
                        <p>
                          {activeActivityDetail?.contract_id ? (
                            <>
                              {updateText(
                                `${context?.data?.owner?.name} ${context?.data?.owner?.last_name}`
                              )}
                              {/* {activeActivityDetail?.contract_id?.created_by
                                ?.name || "-"}{" "}
                              {
                                activeActivityDetail?.contract_id?.created_by
                                  ?.last_name
                              } */}
                            </>
                          ) : (
                            <>
                              {activeActivityDetail?.template_id?.created_by
                                ?.name || "-"}{" "}
                              {
                                activeActivityDetail?.template_id?.created_by
                                  ?.last_name
                              }
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          Created Date
                        </h6>
                        <p>
                          {moment(
                            activeActivityDetail?.contract_id
                              ? activeActivityDetail?.contract_id?.createdAt
                              : activeActivityDetail?.template_id?.createdAt
                          ).format("DD MMM YYYY, hh:mm A") || "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-4">
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          Status
                        </h6>
                        {location?.pathname?.includes("templates") ? (
                          <>
                            <span className=" fw-light">
                              <p>
                                <span className=" fw-light">
                                  {activeActivityDetail?.template_id?.status
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                    activeActivityDetail?.template_id?.status
                                      ?.slice(1)
                                      ?.toLowerCase()}
                                </span>
                              </p>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className=" fw-light">
                              <p>
                                <span className=" fw-light">
                                  {activeActivityDetail?.contract_id?.status ===
                                  "SIGN_INITIATED"
                                    ? "Sign Initiated"
                                    : activeActivityDetail?.contract_id
                                        ?.status === "PENDING_APPROVAL"
                                    ? "Pending for approval"
                                    : activeActivityDetail?.contract_id
                                        ?.status === "STAMPING_FAILED"
                                    ? "E-stamp failed"
                                    : activeActivityDetail?.contract_id
                                        ?.status ===
                                      "PENDING_NEGOTIATION_APPROVAL"
                                    ? "Pending for negotiation approval"
                                    : activeActivityDetail?.contract_id
                                        ?.status === "PENDING_NEGOTIATION"
                                    ? "Pending for negotiation"
                                    : activeActivityDetail?.contract_id
                                        ?.status === "COMPLETED"
                                    ? "Signed"
                                    : activeActivityDetail?.contract_id?.status
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      activeActivityDetail?.contract_id?.status
                                        ?.slice(1)
                                        ?.toLowerCase()}
                                </span>
                              </p>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          Status Date
                        </h6>
                        <p>
                          {moment(activeActivityDetail?.updatedAt).format(
                            "DD MMM YYYY, hh:mm A"
                          ) || "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-4">
                    <div className="col-12 col-md-6">
                      <div>
                        <h6
                          className=" fw-semibold "
                          style={{ fontSize: "14px" }}
                        >
                          Collaborators
                        </h6>
                        <p>
                          {activeActivityDetail?.contract_id ? (
                            <>
                              {activeActivityDetail?.contract_id?.collaborators
                                ?.length === 1 ? (
                                <>{getCurrentUserName()}</>
                              ) : (
                                <>
                                  {
                                    activeActivityDetail?.contract_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.name
                                  }{" "}
                                  {
                                    activeActivityDetail?.contract_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.last_name
                                  }
                                  {", "}
                                  {activeActivityDetail?.contract_id?.collaborators
                                    ?.filter(
                                      (el: any) => el?.access !== "OWNER"
                                    )
                                    ?.map((el: any) => el?.user)
                                    ?.map((el: any) =>
                                      updateText(
                                        `${el?.first_name} ${el?.last_name}`
                                      )
                                    )
                                    ?.join(", ")}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {activeActivityDetail?.template_id?.collaborators
                                ?.length === 1 ? (
                                <>
                                  {
                                    activeActivityDetail?.template_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.name
                                  }{" "}
                                  {
                                    activeActivityDetail?.template_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.last_name
                                  }
                                </>
                              ) : (
                                <>
                                  {
                                    activeActivityDetail?.template_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.name
                                  }{" "}
                                  {
                                    activeActivityDetail?.template_id?.collaborators?.find(
                                      (el: any) => el?.access === "OWNER"
                                    )?.user?.last_name
                                  }
                                  {" , "}
                                  {activeActivityDetail?.template_id?.collaborators
                                    ?.filter(
                                      (el: any) => el?.access !== "OWNER"
                                    )
                                    ?.map((el: any) => el?.user)
                                    ?.map((el: any) =>
                                      updateText(
                                        `${el?.first_name} ${el?.last_name}`
                                      )
                                    )
                                    ?.join(" , ")}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    {activeActivityDetail?.contract_id && (
                      <>
                        {" "}
                        <div className="col-12 col-md-6">
                          <div>
                            <h6
                              className=" fw-semibold "
                              style={{ fontSize: "14px" }}
                            >
                              Signatories
                            </h6>
                            <p>
                              {activeActivityDetail?.contract_id &&
                                getSignatories(activeActivityDetail)}
                              {/* {activeActivityDetail?.contract_id && (
                                <>
                                  {activeActivityDetail?.contract_id
                                    ?.internal_signer?.length === 0 ? (
                                    <>_</>
                                  ) : (
                                    activeActivityDetail?.contract_id?.internal_signer
                                      ?.map((el: any) => el?.user?.name)
                                      ?.join(", ")
                                  )}
                                  {activeActivityDetail?.contract_id
                                    ?.external_users?.length === 0 ? (
                                    <>_</>
                                  ) : (
                                    activeActivityDetail?.contract_id?.external_users
                                      ?.map((el: any) => el?.name)
                                      ?.join(", ")
                                  )}
                                </>
                              )} */}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default ActivityFeeds;
