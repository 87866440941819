import { useState } from "react";
import styles from "../components/ColorPicker/ColorPicker.module.css";
import { colors } from "../components/ColorPicker/defaultColors";
import { MdCheck } from "react-icons/md";
import { Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { addMarkData } from "../components/ColorPicker/ColorPicker";
import { useEditor } from "../EditorProvider";

const Addcolor = ({ }: any) => {
  const editorContext = useEditor();
  // const showOptions = true
  const [validHex, setValidHex] = useState<any>();
  const [hexValue, setHexValue] = useState("");
  const isValideHexSix = new RegExp("^#[0-9A-Za-z]{6}");
  const isValideHexThree = new RegExp("^#[0-9A-Za-z]{3}");
  const [selection] = useState<any>();
  const editor = useSlate();
  const [showOptions, setShowOptions] = useState(true);

  const handleHexChange = (e: any) => {
    e.preventDefault();
    const newHex = e.target.value;
    setValidHex(isValideHexSix.test(newHex) || isValideHexThree.test(newHex));
    setHexValue(newHex);
  };

  let format = "bgcolor";

  const changeColor = (e: any) => {
    const clickedColor = e.target.getAttribute("data-value");
    alert(clickedColor);
    selection && Transforms.select(editor, selection);
    addMarkData(editor, { format, value: clickedColor });

    ReactEditor.focus(editor);
    setShowOptions(false);
  };

  return (
    <>
      {editorContext?.showColorPicker && (
        <div className=" position-relative">
          {/* <div className="addcolor p-4"> */}+
          {/* <TwitterPicker /> */}
          <div className="addcolor">
            {showOptions && (
              <div
                className={styles.popup + " border"}
                style={{
                  marginTop: "10px",
                  width: "auto",
                  display: "grid",
                  backgroundColor: "white",
                }}
              >
                <div className={styles.colorOptions}>
                  {colors.map((color, index) => {
                    return (
                      <div
                        key={index}
                        data-value={color}
                        onClick={changeColor}
                        className={styles.option}
                        style={{
                          background: color,
                          borderRadius: "20px",
                          width: "25px",
                          height: "25px",
                          borderStyle: "solid",
                          borderColor: "lightgrey",
                          borderWidth: "1px",
                        }}
                      ></div>
                    );
                  })}
                </div>
                <p
                  style={{
                    textAlign: "center",
                    opacity: "0.7",
                    width: "100%",
                  }}
                >
                  OR
                </p>
                <form>
                  <div
                    className={styles.hexPreview}
                    style={{ background: validHex ? hexValue : "#000000" }}
                  ></div>

                  <input
                    type="text"
                    placeholder="#000000"
                    value={hexValue}
                    onChange={(e: any) => handleHexChange(e)}
                    style={{
                      border:
                        validHex === false
                          ? "1px solid red"
                          : "1px solid lightgray",
                      width: "120px",
                    }}
                  />
                  <button
                    style={{ color: validHex ? "green" : "" }}
                    type="button"
                  // onClick={handleFormSubmit}
                  >
                    <MdCheck size={20} />
                  </button>
                </form>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default Addcolor;
