import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { updateText } from "../../../utils";

export default function ActivityDownloadPdf({ data }: any) {
  const getSignatories = (activityFeed: any) => {
    const internalSingers = activityFeed?.contract_id?.internal_signer?.map(
      (el: any) => updateText(`${el?.user?.name} ${el?.user?.last_name}`)
    );
    const externalUser = activityFeed?.contract_id?.external_users
      ?.filter((el: any) => el?.access !== "OWNER")

      ?.map((el: any) => updateText(`${el?.name}`));
    return [...internalSingers, ...externalUser]?.join(", ") || "-";
  };
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
            right: 50,
            marginTop: 20,
          }}
        >
          <Image
            style={{ height: 40 }}
            src={require("../../../../src/assets/logo12.png")}
          />
        </View>
        <View
          style={{
            margin: 30,
            marginTop: 30,
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "grey",
            padding: 10,
          }}
        >
          <View style={{ width: 60, marginTop: 20 }}>
            <Text
              style={{
                fontSize: 13,
                fontWeight: 800,
                borderBottomColor: "black",
                borderBottomWidth: "2px",
              }}
            >
              Summary
            </Text>
          </View>
          <View
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View>
                <Text style={{ color: "black", fontSize: 13, fontWeight: 800 }}>
                  {window.location.href.includes("templates")
                    ? "Template Name"
                    : "Contract Name"}
                </Text>
                <Text style={{ color: "black", fontSize: 10, marginTop: 10 }}>
                  {data?.contract_id
                    ? data?.contract_id?.name
                    : data?.template_id?.name}
                </Text>
              </View>
              <View style={{ marginTop: "20" }}>
                <Text style={{ color: "black", fontSize: 13, fontWeight: 800 }}>
                  Owner
                </Text>
                <Text style={{ color: "black", fontSize: 10, marginTop: 10 }}>
                  {data?.contract_id ? (
                    <>
                      {data?.contract_id?.created_by?.name}{" "}
                      {data?.contract_id?.created_by?.last_name}
                    </>
                  ) : (
                    <>
                      {data?.template_id?.created_by?.name}{" "}
                      {data?.template_id?.created_by?.last_name}
                    </>
                  )}
                </Text>
              </View>
              <View style={{ marginTop: "20" }}>
                <Text style={{ color: "black", fontSize: 13, fontWeight: 800 }}>
                  Status
                </Text>
                {window.location.href.includes("templates") ? (
                  <>
                    {" "}
                    <Text
                      style={{ color: "black", fontSize: 10, marginTop: 10 }}
                    >
                      {data?.template_id?.status?.charAt(0)?.toUpperCase() +
                        data?.template_id?.status?.slice(1)?.toLowerCase()}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={{ color: "black", fontSize: 10, marginTop: 10 }}
                    >
                      {data?.contract_id?.status === "SIGN_INITIATED"
                        ? "Sign Initiated"
                        : data?.contract_id?.status === "PENDING_APPROVAL"
                        ? "Pending for approval"
                        : data?.contract_id?.status === "STAMPING_FAILED"
                        ? "E-stamp failed"
                        : data?.contract_id?.status ===
                          "PENDING_NEGOTIATION_APPROVAL"
                        ? "Pending for negotiation approval"
                        : data?.contract_id?.status === "PENDING_NEGOTIATION"
                        ? "Pending for negotiation"
                        : data?.contract_id?.status === "COMPLETED"
                        ? "Signed"
                        : data?.contract_id?.status?.charAt(0)?.toUpperCase() +
                          data?.contract_id?.status?.slice(1)?.toLowerCase()}
                    </Text>
                  </>
                )}
              </View>

              <View style={{ marginTop: 20 }}>
                <Text style={{ color: "black", fontSize: 13, fontWeight: 800 }}>
                  Collaborators
                </Text>
                <Text style={{ color: "black", fontSize: 10, marginTop: 10 }}>
                  {data?.contract_id
                    ? data?.contract_id?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.name
                    : data?.template_id?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.name}{" "}
                  {data?.contract_id
                    ? data?.contract_id?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.last_name
                    : data?.template_id?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.last_name}
                  {data?.contract_id?.collaborators?.length !== 1 && (
                    <>{", "}</>
                  )}
                  {data?.contract_id
                    ? data?.contract_id?.collaborators
                        ?.filter((el: any) => el?.access !== "OWNER")
                        ?.map((el: any) => el?.user)
                        ?.map((el: any) =>
                          updateText(`${el?.name} ${el?.last_name}`)
                        )
                        ?.join(", ")
                    : data?.template_id?.collaborators
                        ?.filter((el: any) => el?.access !== "OWNER")
                        ?.map((el: any) => el?.user)
                        ?.map((el: any) => {
                          updateText(`${el?.name} ${el?.last_name}`);
                        })
                        ?.join(", ")}
                </Text>
              </View>
            </View>
            <View style={{ right: 100 }}>
              <View>
                <Text
                  style={{
                    color: "black",
                    fontSize: 13,
                    fontWeight: 800,
                  }}
                >
                  {/* Contract ID */}
                  {window.location.href.includes("templates")
                    ? "Template ID"
                    : "Contract ID"}
                </Text>
                <Text
                  style={{
                    color: "black",
                    fontSize: 10,
                    marginTop: 10,
                  }}
                >
                  {data?.contract_id
                    ? data?.contract_id?.id
                    : data?.template_id?.id}
                </Text>
              </View>
              <View style={{ marginTop: "20" }}>
                <Text
                  style={{
                    color: "black",
                    fontSize: 13,
                    fontWeight: 800,
                  }}
                >
                  Created Date
                </Text>
                <Text
                  style={{
                    color: "black",
                    fontSize: 10,
                    marginTop: 10,
                  }}
                >
                  {moment(
                    data?.contract_id
                      ? data?.contract_id?.createdAt
                      : data?.template_id?.createdAt
                  ).format("DD MMM YYYY, hh:mm A") || "-"}
                </Text>
              </View>
              <View style={{ marginTop: "20" }}>
                <Text
                  style={{
                    color: "black",
                    fontSize: 13,
                    fontWeight: 800,
                  }}
                >
                  Status Date
                </Text>
                <Text
                  style={{
                    color: "black",
                    fontSize: 10,
                    marginTop: 10,
                  }}
                >
                  {moment(data?.updatedAt).format("DD MMM YYYY, hh:mm A") ||
                    "-"}
                </Text>
              </View>
              <View style={{ marginTop: "20" }}>
                {data?.contract_id && (
                  <View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 13,
                        fontWeight: 800,
                      }}
                    >
                      Signatories
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        marginTop: 10,
                      }}
                    >
                      {data?.contract_id && getSignatories(data)}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            margin: 30,
            marginTop: 400,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={{ height: 14, width: 14 }}>
            <Image
              style={{ height: 14 }}
              src={require("../../../../src/assets/copyright.png")}
            />
          </View>
          <Text
            style={{
              color: "black",
              fontSize: 13,
              fontWeight: 800,
            }}
          >
            2024, CLM Private Limited. All Rights Reserved.
          </Text>
        </View>
      </Page>
    </Document>
  );
}
