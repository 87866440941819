import { ResponsiveBar } from "@nivo/bar";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { ResponsiveFunnel } from "@nivo/funnel";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";
import { useLocation } from "react-router-dom";

const BarChart = ({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: boolean;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) => {
  const context = useAnalytics();
  const location = useLocation();
  const [data, setData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loading, setLoading]: [boolean, Function] = useState(false);

  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const handleContractTypeData = async () => {
    setLoading(true);
    try {
      let response = await context?.handleContractType(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[1] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          var data = response?.data.map(function (obj: any) {
            if (obj.category === null) {
              obj.category = "No category";
            }
            return obj;
          });
          setNoData(false);

          // let res = await context?.getMatrics();
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );

          setGraphType(selectedGraphType);
          if (
            selectedGraphType?.type === "Pie" ||
            selectedGraphType?.type === "Donut" ||
            selectedGraphType?.type === "Funnel"
          ) {
            const dataWithColors = data.map((item: any) => ({
              ...item,
              id: item?.category?.substring(0, 3),
              color: stringToHexColor(item?.category),
              value: item?.count,
            }));

            setData(dataWithColors);
          } else if (
            selectedGraphType?.type === "Horizontal Bar" ||
            selectedGraphType?.type === "Vertical Bar"
          ) {
            const dataWithColors = data.map((item: any) => ({
              ...item,
              title: item?.category?.substring(0, 3),
              color: stringToHexColor(item?.category),
            }));

            setData(dataWithColors);
          } else {
            const dataWithColors = data.map((item: any) => ({
              ...item,
              title: item?.category?.substring(0, 3),
              color: stringToHexColor(item?.category),
            }));

            setData(dataWithColors);
          }
          setLoading(false);
        } else {
          setLoading(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location?.pathname?.includes("/summary")) {
      handleContractTypeData();
      setUpdatedMatricsList(updatedValue);
    }
  }, [isUpdate, filterData, updatedValue]);

  return (
    <div style={{ height: "390px" }}>
      {loading ? (
        <span className="">loading....</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType?.type === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={data} />
                  ) : graphType?.type === "Pie" ? (
                    <PirChart data={data} />
                  ) : graphType?.type === "Donut" ? (
                    <DonutChart data={data} />
                  ) : graphType?.type === "Vertical Bar" ? (
                    <VerticalBarChartData data={data} />
                  ) : graphType?.type === "Funnel" ? (
                    <FunnelChart data={data} />
                  ) : (
                    <HorizontailBarChartData data={data} />
                  )}
                </>
              )}
            </>
          ) : updatedMatricsList?.filter(
              (item: any) => item.name === title && item?.is_enabled === true
            )?.length === 0 ? (
            <AnalyticsNoGraph />
          ) : (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType?.type === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={data} />
                  ) : graphType?.type === "Pie" ? (
                    <PirChart data={data} />
                  ) : graphType?.type === "Donut" ? (
                    <DonutChart data={data} />
                  ) : graphType?.type === "Vertical Bar" ? (
                    <VerticalBarChartData data={data} />
                  ) : graphType?.type === "Funnel" ? (
                    <FunnelChart data={data} />
                  ) : (
                    <HorizontailBarChartData data={data} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BarChart;

const HorizontailBarChartData = ({ data }: { data: [] }) => {
  return (
    <>
      <ResponsiveBar
        data={data}
        keys={["count"]}
        indexBy="title"
        margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
        padding={0.5}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Count",
          legendPosition: "middle",
          legendOffset: 45,
        }}
        groupMode="grouped"
        layout="horizontal"
        enableGridY={false}
        enableGridX={true}
        colors={{ datum: "data.color" }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Category",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: 12,
              },
            },
          },
        }}
        tooltip={CustomTooltip}
      />
    </>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  return (
    <>
      <ResponsiveBar
        data={data}
        keys={["count"]}
        indexBy="title"
        margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
        padding={0.5}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Category",
          legendPosition: "middle",
          legendOffset: 42,
        }}
        groupMode="grouped"
        enableGridY={false}
        enableGridX={true}
        colors={{ datum: "data.color" }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Count",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: 12,
              },
            },
          },
        }}
        tooltip={CustomTooltip}
      />
    </>
  );
};

const PirChart = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          tooltip={({ datum }: any) => {
            return (
              <div style={{ color: "black" }}>
                <span>
                  {datum?.data?.category?.replace("_", " ")}: {datum.value}
                </span>
              </div>
            );
          }}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
            {
              match: {
                id: "go",
              },
              id: "dots",
            },
            {
              match: {
                id: "python",
              },
              id: "dots",
            },
            {
              match: {
                id: "scala",
              },
              id: "lines",
            },
            {
              match: {
                id: "lisp",
              },
              id: "lines",
            },
            {
              match: {
                id: "elixir",
              },
              id: "lines",
            },
            {
              match: {
                id: "javascript",
              },
              id: "lines",
            },
          ]}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          tooltip={({ datum }: any) => {
            return (
              <div style={{ color: "black" }}>
                <span>
                  {datum?.data?.category?.replace("_", " ")}: {datum.value}
                </span>
              </div>
            );
          }}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
            {
              match: {
                id: "go",
              },
              id: "dots",
            },
            {
              match: {
                id: "python",
              },
              id: "dots",
            },
            {
              match: {
                id: "scala",
              },
              id: "lines",
            },
            {
              match: {
                id: "lisp",
              },
              id: "lines",
            },
            {
              match: {
                id: "elixir",
              },
              id: "lines",
            },
            {
              match: {
                id: "javascript",
              },
              id: "lines",
            },
          ]}
          theme={{
            fontFamily: "Arial, sans-serif",
            textColor: "#333333",
          }}
          margin={{
            bottom: 80,
            left: 120,
            right: 120,
            top: 80,
          }}
        />
      </div>
    </>
  );
};

const FunnelChart = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveFunnel
          data={data}
          direction={"horizontal"}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          colors={{ scheme: "set3" }}
          borderWidth={20}
          enableLabel={true}
          isInteractive={true}
          tooltip={({ part }: any) => (
            <div
              style={{
                padding: 12,
                color: "#fff",
                background: "#222222",
              }}
            >
              <strong>{part.data.category}</strong>
              <br />
              <strong>count: {part.data.count}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const CustomTooltip = ({ data }: any) => (
  <div
    style={{
      background: "white",
      padding: "10px",
      border: "1px solid #ccc",
    }}
  >
    <strong>{data.category?.replace("_", " ")}</strong>
    <br />
    Count: {data.count}
  </div>
);
