import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AdminAccountsPage from "./modules/Admin/AdminAccountsPage";
import { useAdminAuth } from "./modules/Admin/Auth/AdminAuthenticationProvider";
import AdminSignIn from "./modules/Admin/Auth/AdminSignIn";
import AdminMembersPage from "./modules/Admin/Members/AdminMembersPage";
import { adminRoutes } from "./utils/adminRoutes";
import AdminForgotPassword from "./modules/Admin/Auth/AdminForgotPassword";
import AdminResetPasswordPage from "./modules/Admin/Auth/AdminResetPassword";
import InvitationPage from "./modules/Admin/Auth/AdminInvitaionPage";
import AdminChangePassword from "./modules/Admin/AdminProfile/ChangePassword";
import ProfilePage from "./modules/Admin/AdminProfile/ProfilePage";
import AdminAnalytics from "./modules/Admin/Analytics/AdminAnalytics";
import AccountView from "./modules/Admin/Accounts/AccountView";
import ContractOverview from "./modules/Admin/Accounts/Contracts/ContractOverview";
import AdminRolesAndAccess from "./modules/Admin/AdminProfile/AdminRolesAndAccess";

const AdminRoutes = () => {
  const location = useLocation();
  const context = useAdminAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const openRoutes = [
      adminRoutes.login,
      adminRoutes.forgetPassword,
      adminRoutes.resetPassword,
      adminRoutes.invitation,
      adminRoutes.changepassword,
      adminRoutes.roleAndAccess,
      adminRoutes.adminprofile,
    ];
    if (
      !openRoutes.includes(location.pathname) &&
      !localStorage.getItem("admin-auth-token")
    ) {
      navigate("/admin/login");
    }
  }, [context?.isLoggedIn, location?.pathname, navigate]);
  let authToken: string = localStorage.getItem("admin-auth-token") || "";

  return (
    context && (
      <Routes>
        <Route path={adminRoutes.login} element={<AdminSignIn />} />
        {/* <Route
          path={adminRoutes.root}
          element={
            <Navigate
              replace
              to={
                !authToken
                  ? adminRoutes.login
                  : adminRoutes.adminAnalyticsSummary
              }
            />
          }
        /> */}

        <Route
          path={adminRoutes.root}
          element={
            <Navigate
              replace
              to={
                !authToken
                  ? adminRoutes.login
                  : adminRoutes.adminAnalyticsSummary
              }
            />
          }
        />
        <Route
          path={adminRoutes.adminAnalyticsContracts}
          element={<AdminAnalytics />}
        />

        <Route
          path={adminRoutes.adminAnalyticsSummary}
          element={<AdminAnalytics />}
        />
        <Route
          path={adminRoutes.adminAnalyticsOrganisations}
          element={<AdminAnalytics />}
        />
        <Route
          path={adminRoutes.adminAnalyticsTurnaround}
          element={<AdminAnalytics />}
        />
        <Route path={adminRoutes.members} element={<AdminMembersPage />} />
        <Route path={adminRoutes.accounts} element={<AdminAccountsPage />} />
        <Route
          path={adminRoutes.forgetPassword}
          element={<AdminForgotPassword />}
        />
        <Route
          path={adminRoutes.resetPassword}
          element={<AdminResetPasswordPage />}
        />
        <Route path={adminRoutes.invitation} element={<InvitationPage />} />
        <Route
          path={adminRoutes.changepassword}
          element={<AdminChangePassword />}
        />
        <Route
          path={adminRoutes.roleAndAccess}
          element={<AdminRolesAndAccess />}
        />
        <Route path={adminRoutes.adminprofile} element={<ProfilePage />} />
        <Route path={adminRoutes.adminAccountView} element={<AccountView />} />
        <Route path={adminRoutes.adminContracts} element={<AccountView />} />
        <Route path={adminRoutes.adminTemplates} element={<AccountView />} />
        <Route path={adminRoutes.adminFolders} element={<AccountView />} />
        <Route
          path={adminRoutes.adminAccountInformation}
          element={<AccountView />}
        />
        <Route
          path={adminRoutes.adminAccountMembers}
          element={<AccountView />}
        />
        <Route
          path={adminRoutes.overviewContracts}
          element={<ContractOverview />}
        />
      </Routes>
    )
  );
};

export default AdminRoutes;
