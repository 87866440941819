import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IMetaResponse } from "../../../interfaces/response/IMetaResponse";
import { adminApi } from "../../../utils/adminApi";
import { useParams } from "react-router-dom";
import { api } from "../../../utils/api";

export interface AdminAccountsFilterInterface {
  page: number;
  limit: number;
  sort: string;
  search?: string;
}

interface ListAdminAccountsContextInterface {
  loading: boolean;
  accounts: any;
  meta: IMetaResponse;
  getAccounts: Function;
  filters: AdminAccountsFilterInterface;
  setFilters: (params: AdminAccountsFilterInterface) => void;
  getAccountInfo: Function;
  accountInfo: any;
  setAccountInfo: Function;
  departmentList: [];
  handleAddAccount: Function;
}

const ListAdminAccountsContext =
  createContext<ListAdminAccountsContextInterface | null>(null);

const ListAdminAccountsProvider = ({ children }: { children: ReactNode }) => {
  const { accountId } = useParams();
  const [accounts, setAccounts] = useState<any>([]);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [accountInfo, setAccountInfo] = useState<any>();
  const [meta, setMeta] = useState<IMetaResponse>({
    total_count: 0,
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminAccountsFilterInterface>({
    page: 1,
    limit: 10,
    sort: "-createdAt",
  });

  const getAccountInfo = async () => {
    if (accountId) {
      try {
        setLoading(true);
        const res = await adminApi.getAccountInfo(accountId, {});
        if (res?.success) {
          setAccountInfo(res?.account);
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const getDepartments = async () => {
    const res = await api.getDepartments();
    setDepartmentList([...res?.data?.departments, "TECHNICIAN"]);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    getAccountInfo();
  }, [accountId]);

  const handleAddAccount = async (params: any) => {
    setLoading(true);
    const res = await adminApi.addAccount(params);
    setLoading(false);
    return res?.data;
  };

  const getAccounts = async (params: any) => {
    try {
      let response = await adminApi?.getAccounts({
        search: params?.search ? params?.search : null,
        limit: params?.limit ? params?.limit : null,
        page: params?.page ? params?.page : null,
        sort: "-createdAt",
      });
      if (response?.success) {
        setAccounts(response?.accounts);
        setMeta(response?.meta);
      }
    } catch (error) {}
  };

  const value: ListAdminAccountsContextInterface = {
    accounts,
    meta,
    loading,
    getAccounts,
    filters,
    setFilters: (params: AdminAccountsFilterInterface) => setFilters(params),
    getAccountInfo,
    accountInfo,
    setAccountInfo,
    departmentList,
    handleAddAccount,
  };

  return (
    <ListAdminAccountsContext.Provider value={value}>
      {children}
    </ListAdminAccountsContext.Provider>
  );
};

const useAdminAccounts = () => useContext(ListAdminAccountsContext);

export { ListAdminAccountsProvider, useAdminAccounts };
