import { useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineMore } from "react-icons/ai";

import { RxCross2 } from "react-icons/rx";
import { useEditor } from "../EditorProvider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import FindAndReplace from "../components/FindAndReplace";

function SearchBar({ show, setShow }: any) {
  const [replaceText, setReplaceText] = useState<string>("");
  const context = useEditor();

  const replaceFunctionHandler = (e: any) => {
    setReplaceText(e.target.value);
  };

  const [replaceMore, setReplaceMore] = useState<any>("");

  const handleReplace = () => {
    FindAndReplace(context?.editor, context?.searchText, replaceText);
    context?.setSearchText("");
    setReplaceText("");
  };

  return (
    <div className=" position-relative ">
      {show && (
        <div className=" d-flex align-items-center editorSearchSmall p-4 justify-content-between">
          <div className=" position-relative flex-grow-1 ">
            <input
              type="search"
              placeholder="Find in document"
              className=" form-control w-100"
              onChange={(e) => context?.setSearchText(e.target.value)}
            />
          </div>
          <div className=" d-flex align-items-center">
            <div className=" ps-2 d-flex align-items-center">
              <div className="position-relative">
                <AiOutlineMore
                  size={24}
                  onClick={() => setReplaceMore(true)}
                  style={{ cursor: "pointer" }}
                  color="gray"
                  data-tooltip-id="More"
                />
                <ReactTooltip id="More" place="bottom">
                  Find & replace
                </ReactTooltip>
              </div>
              <RxCross2
                size={20}
                onClick={() => setShow(false) && context?.setSearchText(null)}
                style={{ cursor: "pointer" }}
                color="gray"
                className="ms-2"
              />
            </div>
          </div>
        </div>
      )}

      {replaceMore && (
        <div className="editorSearch p-4 rounded-2">
          <div className=" d-flex justify-content-between align-items-center pb-4">
            <h6 className=" fw-semibold m-0">Find and replace</h6>
            <div>
              <RxCross2
                size={20}
                onClick={() => setReplaceMore(false)}
                style={{ cursor: "pointer" }}
                color="gray"
              />
            </div>
          </div>
          <div className=" d-flex align-items-center pb-3">
            <label className=" pe-3" style={{ minWidth: "100px" }}>
              Find
            </label>
            <div className=" flex-grow-1">
              <input
                type="search"
                placeholder="Search the text..."
                className=" form-control w-100"
                onChange={(e) => context?.setSearchText(e.target.value)}
                value={context?.searchText}
              />
            </div>
          </div>
          <div className=" d-flex align-items-center pb-3">
            <label className=" pe-3" style={{ minWidth: "100px" }}>
              Replace with
            </label>
            <div className=" flex-grow-1">
              <input
                type="search"
                placeholder="Replace the text..."
                className=" form-control w-100"
                value={replaceText}
                onChange={(e) => replaceFunctionHandler(e)}
              />
            </div>
          </div>
          <div className=" d-flex justify-content-between align-items-center py-3">
            <div>
              <Button
                onClick={() => handleReplace()}
                disabled={replaceText.length === 0}
              >
                Replace
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
