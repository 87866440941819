import { ReactElement, ReactNode, useState } from "react";
import { MdArticle, MdAssignmentAdd, MdPeople } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { routes } from "../utils/routes";
import { isMobile } from "react-device-detect";
import { BsFileEarmarkRichtextFill, BsGraphUp } from "react-icons/bs";
import { FiArrowDownCircle } from "react-icons/fi";

interface AppLayoutProps {
  children: ReactElement;
}
const AdminAppSideBar = ({ children }: AppLayoutProps) => {
  const context = useAuthentication();

  return (
    context && (
      <div
        className=" d-flex position-relative"
        style={
          isMobile
            ? {
                minHeight: "85vh",
              }
            : { minHeight: "94vh" }
        }
      >
        <div className="SideBarBg " style={{ paddingTop: "68px" }}>
          <SidebarMenuItems />
        </div>
        <div
          className=" flex-grow-1 px-4 pb-3"
          style={
            isMobile
              ? {
                  maxHeight: "85vh",
                }
              : {
                  background: "#fafafa",
                  paddingTop: "80px",
                  maxHeight: "100vh",
                  overflow: "scroll",
                }
          }
        >
          {children}
        </div>
      </div>
    )
  );
};

const MenuItem = ({
  url,
  icon,
  text,
  active,
}: {
  url: string;
  icon: ReactNode;
  text: string;
  active?: boolean;
}) => (
  <Link
    to={url}
    className={`menu-item text-decoration-none ${active && "nav-active"}`}
  >
    <li className="d-flex align-items-center p-4 ">
      {icon && <span className="me-2">{icon}</span>}
      <span className="sidebar_text">{text}</span>
    </li>
  </Link>
);

const SidebarMenuItems = () => {
  const [open, setOpen] = useState(true);
  const [privateFolderActive, setPrivateFolderACtive]: [boolean, Function] =
    useState(false);
  const [publicFolderActive, setPublicFolderACtive]: [boolean, Function] =
    useState(false);

  const location = useLocation();

  const isActiveTemplates: boolean =
    !!matchPath(location.pathname, routes.templates) ||
    !!matchPath(routes.templatesByDepartment, location.pathname);

  const isActiveContracts: boolean =
    !!matchPath(location.pathname, routes.contracts) ||
    !!matchPath(routes.contractsByDepartment, location.pathname);

  const isActiveAnalytics: boolean =
    !!matchPath(location.pathname, routes.analytics) ||
    !!matchPath(location.pathname, routes.analyticsTurnaround) ||
    !!matchPath(location.pathname, routes.analyticsTeams) ||
    !!matchPath(location.pathname, routes.analyticsContactValue);

  const isActiveMembers: boolean =
    !!matchPath(location.pathname, routes.members) ||
    !!matchPath(routes.MembersByDepartment, location.pathname);

  return (
    <ul className=" m-0 list-unstyled flex-grow-1 ">
      <MenuItem
        url={routes.root}
        active={isActiveAnalytics}
        icon={<BsGraphUp fontSize={20} />}
        text={"Analytics"}
      />
      <MenuItem
        url={routes.contracts}
        active={isActiveContracts}
        icon={<MdArticle fontSize={20} />}
        text={"Contracts"}
      />
      <MenuItem
        url={routes.templates}
        active={isActiveTemplates}
        icon={<MdAssignmentAdd fontSize={20} />}
        text={"Templates"}
      />
      <MenuItem
        url={routes.members}
        active={isActiveMembers}
        icon={<MdPeople fontSize={20} />}
        text={"Members"}
      />

      <Link
        to={""}
        className={`menu-item text-decoration-none 
      
        `}
      >
        <li
          className={open ? "sidebar-item open   " : "sidebar-item  "}
          style={{ cursor: "default" }}
        >
          <div
            className="sidebar-title d-flex justify-content-between align-items-center p-4 cursor-pointer"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div>
              <span className="me-2">
                <BsFileEarmarkRichtextFill color="#fefefe" fontSize={17} />
              </span>
              <span className="sidebar_text">Folders</span>
            </div>

            <div className=" toggle-btn mt-1 ">
              <FiArrowDownCircle />
            </div>
          </div>
        </li>
      </Link>

      {open && (
        <>
          <Link
            to={routes.privateFolders}
            className={`menu-item text-decoration-none ${
              (!!matchPath(location.pathname, routes.privateFolders) ||
                !!matchPath(routes.privatefolderId, location.pathname)) &&
              "nav-active"
            }
        `}
          >
            <li
              className={
                privateFolderActive ? "sidebar-item open   " : "sidebar-item  "
              }
            >
              <div
                className={`"sidebar-title d-flex justify-content-between align-items-center p-4 " `}
                onClick={() => setPrivateFolderACtive(!privateFolderActive)}
              >
                <div className="inner-sidebar">
                  <span className="me-2">
                    <BsFileEarmarkRichtextFill color="#fefefe" fontSize={17} />
                  </span>
                  <span className="sidebar_text">Private </span>
                </div>
              </div>
            </li>
          </Link>

          <Link
            to={routes.publicFolder}
            className={`menu-item text-decoration-none ${
              (!!matchPath(location.pathname, routes.publicFolder) ||
                !!matchPath(routes.publicfolderId, location.pathname)) &&
              "nav-active"
            }
        `}
          >
            <li
              className={
                publicFolderActive ? "sidebar-item open   " : "sidebar-item  "
              }
            >
              <div
                className={`"sidebar-title d-flex justify-content-between align-items-center p-4 " `}
                onClick={() => setPublicFolderACtive(!publicFolderActive)}
              >
                <div className="inner-sidebar">
                  <span className="me-2">
                    <BsFileEarmarkRichtextFill color="#fefefe" fontSize={17} />
                  </span>
                  <span className="sidebar_text">Public </span>
                </div>
              </div>
            </li>
          </Link>
        </>
      )}
    </ul>
  );
};

export default AdminAppSideBar;
