import { TemplateProvider } from "./TemplateProvider";
import Templates from "./Templates";

function ListTemplatesDetail() {
  return (
    <>
      <TemplateProvider>
        <Template />
      </TemplateProvider>
    </>
  );
}

export default ListTemplatesDetail;

const Template = () => {
  return (
    <>
      <Templates />
    </>
  );
};
