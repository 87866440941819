import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import IAdmin from "../../../interfaces/response/IAdmin";
import { IMetaResponse } from "../../../interfaces/response/IMetaResponse";
import { adminApi } from "../../../utils/adminApi";
import { api } from "../../../utils/api";

export interface AdminMembersFilterInterface {
  page: number;
  limit: number;
  sort: string;
  search?: string;
}

interface ListAdminMembersContextInterface {
  loading: boolean;
  members: IAdmin[];
  meta: IMetaResponse;
  getMembers: () => void;
  filters: AdminMembersFilterInterface;
  setFilters: (params: AdminMembersFilterInterface) => void;
  handleInviteMember: (params: any) => void;
  reSendInviteMember: (params: any, id: string) => void;
  departmentList: [];
  editAdminMember: Function;
}

const ListAdminMembersContext =
  createContext<ListAdminMembersContextInterface | null>(null);

const ListAdminMembersProvider = ({ children }: { children: ReactNode }) => {
  const [members, setMembers] = useState<IAdmin[]>([]);
  const [meta, setMeta] = useState<IMetaResponse>({
    total_count: 0,
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminMembersFilterInterface>({
    page: 1,
    limit: 10,
    sort: "-createdAt",
  });
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const getMembers = async () => {
    try {
      setLoading(true);
      const { success, meta, users } = await adminApi.getMembers(filters);
      if (success) {
        setMeta(meta);
        setMembers(users);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  const handleInviteMember = async (params: any) => {
    setLoading(true);
    const res = await adminApi.addMember(params);
    setLoading(false);
    return res?.data;
  };

  const reSendInviteMember = async (params: any, id: string) => {
    setLoading(true);
    const res = await adminApi.resendInvitation(params, id);
    setLoading(false);
    return res?.data;
  };
  const getDepartments = async () => {
    const res = await api.getDepartments();
    setDepartmentList([...res?.data?.departments, "TECHNICIAN"]);
  };

  const editAdminMember = async (id: string, params: any) => {
    const res = await adminApi?.editAdminMember(id, params);
    return res;
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const value: ListAdminMembersContextInterface = {
    members,
    meta,
    loading,
    getMembers,
    filters,
    setFilters: (params: AdminMembersFilterInterface) => setFilters(params),
    handleInviteMember,
    departmentList,
    reSendInviteMember,
    editAdminMember,
  };

  return (
    <ListAdminMembersContext.Provider value={value}>
      {children}
    </ListAdminMembersContext.Provider>
  );
};

const useAdminListMembers = () => useContext(ListAdminMembersContext);

export { ListAdminMembersProvider, useAdminListMembers };
