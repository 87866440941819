import { Link, matchPath, useLocation } from "react-router-dom";
import { adminRoutes } from "../../../utils/adminRoutes";

const ProfileHeader = () => {
  const location = useLocation();

  const isActiveProfile: boolean = !!matchPath(
    location.pathname,
    adminRoutes.adminprofile
  );

  const isActiveChangePassword: boolean = !!matchPath(
    adminRoutes.changepassword,
    location.pathname
  );

  return (
    <div style={{ overflowX: "scroll" }}>
      {!location?.pathname?.includes("roles_access") && (
        <>
          <div className="d-flex mb-2" style={{ maxWidth: "600px" }}>
            <div style={{ minWidth: "130px" }}>
              <Link
                to={adminRoutes?.adminprofile}
                className={`border-0 header-css me-4 ${
                  isActiveProfile ? " active" : ""
                }`}
              >
                Personal Profile
              </Link>
            </div>
            <div style={{ minWidth: "120px" }}>
              <Link
                to={adminRoutes?.changepassword}
                className={`border-0 d-inline-block header-css me-4 ${
                  isActiveChangePassword ? " active" : ""
                }`}
              >
                Change Password
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileHeader;
