import { useEffect, useState } from "react";
// import { MdOutlineModeEdit } from "react-icons/md";
import { TiTimes } from "react-icons/ti";
import { useLocation, useParams } from "react-router-dom";
import { Editor, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import {
  ContractContextInterface,
  useContract,
} from "../modules/Contracts/ContractProvider";
import { useEditor } from "./EditorProvider";
import { toggleFormat } from "./components/ToolbarMark";

enum Heading {
  "heading-one" = 1,
  "heading-two" = 2,
  "heading-three" = 3,
  "heading-four" = 4,
  "heading-five" = 5,
  "heading-six" = 6,
}

const TableOfContents = () => {
  const context = useEditor();
  const editor: any = useSlate();
  const [editorHeadings, setEditorHeadings] = useState<any[]>([]);
  // console.log(editor, "EditopPPPPPPPPPPPPPPPPPPPPPPPppppppppPPPP");

  const [showDelete, setShowDelete] = useState<number | null>(null);
  const { id } = useParams();
  const location = useLocation();
  const contractContext: ContractContextInterface | null = useContract();

  useEffect(() => {
    if (editor.children.length > 0) {
      const a = Array.from(
        Editor.nodes(editor, {
          at: [],
          match: (node: any) => {
            return node?.type?.includes("heading");
          },
        })
      );
      // console.log(a, "match");
      setEditorHeadings(
        a.map((s: any) => ({
          value: s[0].children[0].text,
          type: s[0].type,
          path: s[1],
        }))
      );
    }
  }, [editor.children]);

  const handleDelete = (index: number) => {
    editorHeadings.splice(index, 1);
    toggleFormat(editor, "shown");
  };

  const moveCursor = (path: any) => {
    ReactEditor.focus(context?.editor);
    Transforms.select(editor, { path: [path[0], 0], offset: 0 });
  };

  return (
    <div className="px-3" style={{ height: "500px", overflow: "scroll" }}>
      <div className="pb-4 border-bottom">
        <div className=" d-flex justify-content-between align-items-center pb-3">
          <h6 className=" fw-semibold m-0">Summary</h6>
        </div>
        <div>
          <input
            style={{ minWidth: "100%", background: "transparent" }}
            className="m-0 d-inline-block px-2 border h6 fw-normal py-1 text-title"
            defaultValue={context?.contractDescription}
            onChange={(e: any) => {
              context?.setContractDescription(e?.target?.value);
            }}
            onBlur={() => {
              let params = {
                name: contractContext?.data?.name,
                description: context?.contractDescription,
              };
              location.pathname.includes("contracts") &&
                context?.handleUpdateContractDetail(id, params);
            }}
            placeholder="Enter the document summary here."
          />
        </div>
      </div>
      <h6
        className=" fw-semibold my-4"
        style={{ fontSize: "13px", color: "#6c757d" }}
      >
        Outline
      </h6>
      <div style={{ height: "600px" }}>
        {editorHeadings.map((h: any, i: number) => {
          const dynamicWidth: string = `${
            20 * (parseInt(Heading[h.type]) - 1)
          }px`;
          return (
            <div
              key={i}
              onMouseEnter={() => setShowDelete(i)}
              onMouseLeave={() => setShowDelete(null)}
              className=" d-flex justify-content-between p-2 py-1"
              onClick={() => moveCursor(h.path)}
              style={{
                cursor: "pointer",
                wordWrap: "break-word",
              }}
            >
              <div style={{ marginLeft: dynamicWidth }}>{h.value}</div>
              {showDelete === i && (
                <span
                  onClick={() => handleDelete(i)}
                  className="px-1"
                  style={{ cursor: "pointer" }}
                >
                  <TiTimes />
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableOfContents;
