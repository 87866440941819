import moment from "moment";
import { IFolderResponse } from "../../../interfaces/response/IFolderResponse";
import { Fragment, useEffect, useState } from "react";
import Avatar from "react-avatar";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { routes } from "../../../utils/routes";
import { ListFoldersContextInterface, useFolders } from "../FoldersProvider";
import DeleteToast from "../../../ui/DeleteToast";
import FailureToast from "../../../ui/FailureToast";
import { MdCancel } from "react-icons/md";
import RenameFolder from "./RenameFolder";
import { Folder } from "../../../lib/Folder";
import { RiFolderSharedFill } from "react-icons/ri";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

function RecentFolderList({
  folder,
  setFolderClick,
  setIsPathActive,
  setmoveFolders,
}: // setShareFolder,
{
  folder: IFolderResponse;
  setFolderClick: Function;
  setIsPathActive: Function;
  setmoveFolders: Function;
  // setShareFolder: Function;
}) {
  const context: ListFoldersContextInterface | null = useFolders();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [updateFolderName, setupdateFolderName]: [boolean, Function] =
    useState(false);

  const authContext = useAuthentication();
  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesUpdate(guestRole?.FOLDERS?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.FOLDERS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  const onDeleteFolder = async () => {
    try {
      let response = await context?.handleDeleteFolder(folder?.id);

      if (response?.success) {
        let res = await context?.handleFolderById(
          id ? id : context?.folderList?.id
        );

        if (location.pathname.includes("private")) {
          let privateList = res?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PRIVATE";
            }
          );

          context?.setSubFolders(privateList);
        } else if (location.pathname.includes("public")) {
          let publicList = res?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PUBLIC";
            }
          );
          context?.setSubFolders(publicList);
        }
        setShowDeleteToast(false);
      } else {
        setShowDeleteToast(false);
        setShowFailureToast(true);
        setBodyMessage("Folder is not empty");
      }
    } catch (error) {}
  };

  return (
    <>
      <div className=" py-1 card-width position-relative">
        <div className="position-absolute dotCorner">
          <div id="delete-icon">
            <Dropdown>
              <Dropdown.Toggle
                style={{ border: "none" }}
                variant="default"
                id="dropdown-basic"
              >
                {(rolesUpdate === "UPDATE" || rolesDelete === "DELETE") && (
                  <>
                    <RiFolderSharedFill color="#5957df" fontSize={30} />
                  </>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                {rolesUpdate === "UPDATE" && (
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => setupdateFolderName(true)}
                    >
                      Rename
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="sort-by"
                      disabled={
                        !!context?.subFolders &&
                        context?.subFolders?.length <= 1
                      }
                      onClick={() => {
                        setmoveFolders(true);
                        context?.setSeletedFolderIdForMove(folder?.id);
                      }}
                    >
                      Add to folder
                    </Dropdown.Item>
                  </>
                )}

                {rolesDelete === "DELETE" && (
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        setShowDeleteToast(true);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div
          onClick={() => {
            navigate(
              location.pathname.includes("private")
                ? generatePath(routes.privatefolderId, {
                    id: folder?.id,
                  })
                : generatePath(routes.publicfolderId, {
                    id: folder?.id,
                  })
            );
          }}
        >
          <div
            className={`bg-contract contract-division p-4 br-4 ${
              location.pathname.includes("folder") ? "recent_folder" : ""
            }`}
            onClick={() => {
              setFolderClick(folder);
              setIsPathActive(false);
            }}
          >
            <div className=" position-relative ">
              <div className="d-flex justify-content-between align-items-center">
                <div data-bs-toggle="tooltip" data-bs-placement="top">
                  <div
                    className="card-contract-name"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={folder?.name}
                  >
                    <span>
                      {folder?.name?.length >= 15 ? (
                        <>
                          {folder?.name?.charAt(0).toUpperCase() +
                            folder?.name?.slice(0)?.substr(1, 13)}
                          {"..."}
                        </>
                      ) : (
                        <>
                          {folder?.name?.charAt(0).toUpperCase() +
                            folder?.name?.slice(1)}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className=" py-2 d-flex justify-content-between align-items-center">
                <div className="contract-team-name m-0">
                  <span className="pro-names p-0">
                    {folder?.department ? folder?.department : "-"}
                  </span>
                </div>

                <div className="  ">
                  <span className="profileAvatar mx-1">
                    {folder?.created_by && (
                      <>
                        <Fragment>
                          <Avatar
                            name={folder?.created_by?.name?.charAt(0)}
                            className="profile-avatars"
                            size="30"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={folder?.created_by?.name}
                          />
                        </Fragment>
                      </>
                    )}
                    &nbsp;&nbsp;
                  </span>
                </div>
              </div>
            </div>

            <div
              className={` d-flex justify-content-between py-4 ${
                location.pathname.includes("folder") ? "no_of_folders" : ""
              }`}
            >
              <div>Folder : {folder?.sub_folders?.length}</div>
              <div>
                Document :{" "}
                {folder?.contracts?.length + folder?.templates?.length}
              </div>
            </div>

            <div className=" row">
              <div className="card-clock mt-3  col-12">
                <div
                  className={`card-update-date px-3 py-2 ${
                    location.pathname.includes("folder")
                      ? "folder_update_date"
                      : ""
                  }`}
                >
                  Updated on :
                  <span className="textBlack ms-1">
                    {moment(folder?.updatedAt).format("D MMM YYYY")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => {
            onDeleteFolder();
          }}
          onClose={() => setShowDeleteToast(false)}
          title="Do you want to delete this document ?"
          closeText="No, don't !"
          submitText="Yes, delete it !"
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}

      {updateFolderName && (
        <RenameFolder
          show={updateFolderName}
          onHide={() => setupdateFolderName(false)}
          folderDetail={folder}
          setShowFailureToast={setShowFailureToast}
          setBodyMessage={setBodyMessage}
        />
      )}
    </>
  );
}

export default RecentFolderList;
