import { ReactNode } from "react";
import { useBootStrapLayout } from "../utils/BrowserLayoutProvider";

const BaseToast = ({
  children,
  toastType,
}: {
  children: ReactNode;
  toastType?: string;
}) => {
  const { isMobile } = useBootStrapLayout();

  return (
    <div className="popup">
      <div
        className={`${
          toastType === "DeleteToast" ? "delete_toast_inner" : "toast_inner"
        }  bg-white p-4 ${isMobile && "m-3"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default BaseToast;
