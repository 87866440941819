import DataTable from "react-data-table-component";
import NoTemplateFound from "../../Accounts/NoDataFound";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../AdminAnalyticsProvider";
import { updateText } from "../../../../utils";

function OrganisationTable(filterData: any) {
  const context = useAdminAnalytics();
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);
  const [accountsDetail, setAccountsDetail]: [any, Function] = useState();
  const [metaData, setMetaDeta]: [any, Function] = useState();

  const getOrganistaion = async () => {
    try {
      let response = await context?.getOrganistaionInfo({
        pages: page,
        limit: limit,
        filterData: filterData ? filterData : "",
      });
      if (response?.success) {
        setAccountsDetail(response?.accounts);
        setMetaDeta(response?.metaData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getOrganistaion();
  }, [page, limit, filterData]);

  const columns = [
    {
      name: "Organization name",
      selector: (row: any) => row.name,
    },
    // {
    //   name: "Active Users",
    //   selector: (row: any) =>
    //     row.members?.filter((item: any) => item?.email_verified)?.length,
    // },
    {
      name: "No. of Users",
      selector: (row: any) => row.members?.length || "-",
    },
    {
      name: "No. of Department",
      selector: (row: any) =>
        Object.entries(
          row.members?.reduce((countMap: any, item: any) => {
            const { department } = item;
            countMap[department] = (countMap[department] || 0) + 1;
            return countMap;
          }, {})
        )?.length || "-",
    },
    {
      name: "No. of Contracts",
      selector: (row: any) => row.total_contracts || "-",
    },
    {
      name: "No. of Templates",
      selector: (row: any) => row.total_templates || "-",
    },
    {
      name: "Admin",
      selector: (row: any) =>
        updateText(
          `${row.members[0]?.first_name} ${row.members[0]?.last_name}`
        ) || "-",
    },
  ];

  return (
    <>
      {accountsDetail?.length > 0 ? (
        <div className="mt-4 ps-3  mx-2 px-3">
          <DataTable
            className="data-table"
            columns={columns}
            data={accountsDetail || []}
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            paginationServer
            progressComponent={<>Loading...</>}
            paginationDefaultPage={1}
            onChangeRowsPerPage={(currentRowsPerPage: number) => {
              setLimit(currentRowsPerPage);
            }}
            onChangePage={(page: number) => {
              setPage(page);
              setLimit(10);
            }}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationTotalRows={metaData?.total_count}
          />
        </div>
      ) : (
        <NoTemplateFound />
      )}
    </>
  );
}

export default OrganisationTable;
