import { useState } from "react";
import profileAvatar from "../assets/profile-avatar.png";
import Avatar from "react-avatar";

function AvatarSection({
  imageData,
  name,
}: {
  imageData: string | undefined;
  name?: string;
}) {
  const [isImageValid] = useState<boolean>(true);
  const [imageUrl] = useState<string>("");
  return (
    <>
      {isImageValid && (!imageUrl || imageUrl === "") && (
        <Avatar
          name={name?.charAt(0)}
          size="40"
          className="avatarCss"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={name}
        />
      )}
      {!isImageValid && (
        <Avatar
          src={profileAvatar}
          size={"40"}
          className=" avatarIMG px-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={imageData}
        />
      )}
    </>
  );
}
export default AvatarSection;
