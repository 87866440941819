import { useCallback, useMemo } from "react";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor } from "slate";
import { RenderElement } from "../../Editable/Element";
import { Leaf } from "../../Editable/Leaf";
import { useEditor } from "../../EditorProvider";
import EditableVoidsExample from "./editable-voids";
import initialValue from "../../initialValue";

function Header() {
  let type = "Header";
  // const [showHeaderEditingOption, setShowHeaderEditingOption] = useState(false);
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editorContext = useEditor();
  return (
    <>
      <div
        title="Double click to Edit Header"
        style={{
          display: "flex",
          height: "1px",
          width: "100%",
          backgroundColor: "transparent",
          justifyContent: "center",
        }}
        onDoubleClick={() =>
          // alert('hai')
          editorContext?.setShowHeaderEditingOption(!editorContext?.showHeaderEditingOption)
        }
      >
        <Slate
          initialValue={initialValue}
          editor={editor}
        >
          <Editable
            renderElement={RenderElement}
            renderLeaf={renderLeaf}
            disabled={true}
          />
        </Slate>
        {/* <span style={{ color: "#969998" }}>Double click to Edit Header</span> */}
      </div>
      {/* {editorContext?.showHeaderEditingOption && <EditableVoidsExample type={type} />} */}
      {editorContext?.showHeaderEditingOption && <EditableVoidsExample type={type} />}
    </>
  );
}

export default Header;
