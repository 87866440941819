import AsyncSelect from "react-select/async";
import { customStyles } from "./CustomStyles";

interface FormikAsyncSelectProps {
  isFieldDisabled?: boolean;
  isMulti: boolean;
  values?:
    | {
        label: string;
        value: string;
      }[]
    | {
        label: string;
        value: string;
      };
  value?:
    | {
        label: string;
        value: string;
      }
    | {
        label: string;
        value: string;
      };
  loadOptionValue: any;
  autoFocus?: boolean;
  updateField: Function;
  options?: any;
  className?: string;
  cacheOptions?: boolean;
  placeholder?: string;
  defaultValue?: {
    label: string;
    value: string;
  };
}

function CommonAsyncSelect({
  updateField,
  values,
  value,
  loadOptionValue,
  isMulti = false,
  isFieldDisabled = false,
  options = {},
  className,
  cacheOptions = false,
  placeholder,
  defaultValue,
}: FormikAsyncSelectProps) {
  return (
    <AsyncSelect
      styles={customStyles}
      placeholder={placeholder}
      classNamePrefix="select"
      className={`${className} basic-multi-select `}
      isDisabled={isFieldDisabled}
      isMulti={isMulti}
      value={values || value}
      defaultOptions={options}
      cacheOptions={cacheOptions}
      loadOptions={loadOptionValue}
      defaultValue={defaultValue}
      onChange={(option: any) => {
        updateField(option);
      }}
    />
  );
}

export default CommonAsyncSelect;
