// import { IApproval } from "../../interfaces/IApprovals";
import { useApprovals } from "./WorkflowProvider";
import Avatar from "react-avatar";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
// import { useAuthentication } from "../Authentication/AuthenticationProvider";

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="border-0">
        <div className="p-0">
          <div className=" d-flex align-items-center justify-content-between  ">
            <div className=" d-flex align-items-center">
              <div className="me-3">
                {props?.data?.avatar !== null &&
                props?.data?.avatar !== undefined ? (
                  <img
                    src={props?.data?.avatar}
                    alt=""
                    width={40}
                    className="form_img_collb"
                  />
                ) : (
                  <Avatar
                    name={props?.data?.label.charAt(0)}
                    className="profile-avatars"
                    size="30"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={props?.data?.label}
                  />
                )}
              </div>
              <div style={{ paddingInlineStart: ".7rem" }}>
                <h6 className="fw-semibold m-0 ">{props?.data?.label}</h6>
                <h6 className="fw-lighter m-0 ">{props?.data?.email}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

function AddApprovals({ placeholder }: { placeholder: string }) {
  const context = useApprovals();

  return (
    <>
      <AsyncSelect
        classNamePrefix="custommultiselect"
        isMulti={true}
        components={{ Option }}
        defaultOptions={context?.defaultApprovals
          .filter(
            (el: any) =>
              (el?.roles?.[0] === "APPROVER" ||
                el?.roles?.[0] === "AUTHORIZED_SIGNATORIES") &&
              el?.is_active === "true"
          )
          ?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
            email: item?.email,
            avatar: item?.avatar,
            contact_number: item?.contact_number,
            organization: item?.organization,
          }))}
        cacheOptions={false}
        loadOptions={async (search: string) => {
          const res = await context?.handleAutoUsers({ search });
          const _categories = res
            .filter(
              (el: any) =>
                (el?.roles?.[0] === "APPROVER" ||
                  el?.roles?.[0] === "AUTHORIZED_SIGNATORIES") &&
                el?.is_active === "true"
            )
            ?.map((user: any) => ({
              label: user?.name,
              value: user?.id,
              email: user?.email,
              avatar: user?.avatar,
              contact_number: user?.contact_number,
              organization: user?.organization,
            }));
          return _categories;
        }}
        onChange={(e) => {
          context?.setOnSelectApprovalId(
            e?.map(
              ({ label, value, email, organization, contact_number }: any) => ({
                label,
                value,
                email,
                contact_number,
                organization,
              })
            )
          );
        }}
        placeholder={placeholder}
      />
    </>
  );
}

export default AddApprovals;
