import Draggable from "react-draggable";

const DraggableRectangle = ({
  position,
  size,
  onDrag,
  userName,
  backgroundColor,
}: any) => {
  const handleDrag = (_: any, data: any) => {
    onDrag(data.x, data.y, position.id, position?.name, position?.email);
  };

  return (
    <>
      <Draggable
        position={{ x: position.x, y: position.y }}
        onDrag={handleDrag}
        bounds="parent" // Allow dragging only within the parent element (canvas)
      >
        <input
          type="text"
          value={userName}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: size.width,
            color: "white",
            height: size.height,
            border: `1px solid ${backgroundColor}`,
            backgroundColor: `${backgroundColor}`,
            textAlign: "center",
          }}
        />
      </Draggable>
    </>
  );
};

export default DraggableRectangle;
