import { ListTemplateContextInterface } from "../ListTemplatesProvider";

function TemplateCategoryNav({
  context,
}: {
  context: ListTemplateContextInterface;
}) {
  return (
    context && (
      <>
        <div className="d-flex mt-4 mb-3 mx-2 ">
          <h6 className="contract-title pb-1 m-0">All Templates</h6>
        </div>
        {/* <Navbar className="p-0">
          <Navbar.Collapse id="navbarScroll" className=" overflow-scroll p-0">
            <Nav className="me-auto category-navbar-css ">
              <Nav.Link
                className={`nav-border active-border active-text nav-text-css p-0  ${
                  !!matchPath(location.pathname, routes.templates)
                    ? "nav-text-active"
                    : ""
                }`}
              >
             
              </Nav.Link>
              {context?.defaultCategories &&
                context?.defaultCategories?.map(
                  (category: ITemplateCategory) => {
                    return (
                      <div key={category.category} className=" me-4">
                        <div
                          className={` edit_link_routes ${
                            !!matchPath(
                              location.pathname,
                              generatePath(routes.templatesByDepartment, {
                                department: category.category,
                              })
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className="link-css"
                            to={generatePath(routes.templatesByDepartment, {
                              department: slugify(category.category),
                            })}
                          >
                            {category.category} ({category.count})
                          </Link>
                        </div>
                      </div>
                    );
                  }
                )}
            </Nav>
          </Navbar.Collapse>
        </Navbar> */}
      </>
    )
  );
}

export default TemplateCategoryNav;
