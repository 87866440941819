import { Button, Form, Modal } from "react-bootstrap";
import { ListContractContextInterface } from "../ListContractProvider";
import { useState } from "react";
import { ListTemplateContextInterface } from "../../Templates/ListTemplatesProvider";

function UpdateName({
  show,
  onHide,
  documentName,
  context,
  setShowSuccessToast,
  setBodyMessage,
  documentId,
}: {
  show: boolean;
  onHide: Function;
  documentName: string;
  context: ListContractContextInterface | ListTemplateContextInterface;
  setShowSuccessToast: Function;
  setBodyMessage: Function;
  documentId: string;
}) {
  const [updatedName, setUpdatedName]: [string, Function] = useState("");

  const onUpdateName = async () => {
    try {
      let res = await context?.updateDocumentName(documentId, {
        name: updatedName,
      });

      if (res) {
        context?.getRecentDocuments();
        onHide();
        setShowSuccessToast(true);
        setBodyMessage("Document renamed.");
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal show={show} onHide={() => onHide} centered>
        <Modal.Header className="modal-header-bottom">
          <div className="pt-3">
            <h6 className=" fw-semibold modal-title-name">
              Rename document name
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="col-md-12 col-12">
                <label className="input_label">Name</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={documentName}
                  onChange={(e) => setUpdatedName(e?.target?.value)}
                  className="form-control"
                  placeholder="Name"
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            disabled={updatedName?.length === 0}
            size="sm"
            onClick={onUpdateName}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateName;
