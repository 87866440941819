import { IActivityFeedResponse } from "../interfaces/response/IActivityFeedResponse";
import { IContractResponse } from "../interfaces/response/IContractResponse";
import { ITemplateResponse } from "../interfaces/response/ITemplateResponse";
import { IUserResponse } from "../interfaces/response/IUserResponse";
import BaseDocument from "./BaseDocument";

export class ActivityFeed extends BaseDocument {
  action: string;
  category: string;
  createdAt: Date;
  created_by: IUserResponse;
  id: string;
  template_id: ITemplateResponse;
  type: string;
  updatedAt: Date;
  contractId: IContractResponse;

  constructor(params: IActivityFeedResponse) {
    super();
    this.action = params.action;
    this.category = params.category;
    this.createdAt = new Date(params.createdAt);
    this.created_by = params.created_by;
    this.id = params.id;
    this.template_id = params.template_id;
    this.type = params.type;
    this.contractId = params.contract_id;
    this.updatedAt = new Date(params.updatedAt);
  }
}
