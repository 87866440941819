import { useEffect, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
// import { routes } from "../../utils/routes";
import { api } from "../../utils/api";
import { useContract } from "../Contracts/ContractProvider";

function Coordinate({
  setCoordinateRequired,
  setApprovalAddClick,
  setSignerClick,
  setCounterpartyClick,
  setAddTemplateApprovers,
  setNegotiationWorkFlowClick,
  setEstampclick,
}: {
  setCoordinateRequired: Function;
  setApprovalAddClick: Function;
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setAddTemplateApprovers: Function;
  setNegotiationWorkFlowClick: Function;
  setEstampclick: Function;
}) {
  const context = useContract();

  // const navigate = useNavigate();
  const { id } = useParams();
  const [isCoordinateReruired, setIsCoordinateRequired]: [boolean, Function] =
    useState(false);
  const [documentSignatureWay, setDocumentSignatureWay]: [string, Function] =
    useState("Aadhaar");

  useEffect(() => {
    if (isCoordinateReruired) {
      setCoordinateRequired(true);
    }
  }, [isCoordinateReruired]);

  const onHandleSignature = async () => {
    let requestBody = {
      name: context?.data?.name,
      sign_type: documentSignatureWay,
    };
    try {
      let response = await api.updateContractName(id!, requestBody);

      if (response?.data?.success) {
        setApprovalAddClick(false);
        setSignerClick(false);
        setCounterpartyClick(false);
        setAddTemplateApprovers(false);
        setNegotiationWorkFlowClick(false);
        context?.refresh();
        setEstampclick(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        className=" d-flex  p-4 flex-column justify-content-between"
        style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
      >
        <div className="">
          <Stack className="my-2">
            <label className="mb-2 fw-semibold">
              Please select the type of signature
            </label>
          </Stack>
          <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
            <div className=" d-flex justify-content-between">
              <div className="py-3">
                <span>
                  <input
                    type="radio"
                    checked={documentSignatureWay === "e-Signature"}
                    className="form-check-input"
                    onChange={() => {
                      setIsCoordinateRequired(true);
                      setDocumentSignatureWay("e-Signature");
                    }}
                  />
                  <span className="ps-2 me-3">E-Signature</span>
                </span>
                <span>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={documentSignatureWay === "Aadhaar"}
                    onChange={() => {
                      setIsCoordinateRequired(false);
                      setDocumentSignatureWay("Aadhaar");
                    }}
                  />
                  <span className="ps-2 me-3">Aadhar</span>
                </span>
                <span>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={documentSignatureWay === "DSC"}
                    onChange={() => {
                      setIsCoordinateRequired(false);
                      setDocumentSignatureWay("DSC");
                    }}
                  />
                  <span className="ps-2 me-3">DSC</span>
                </span>
              </div>
            </div>
          </Form.Group>
        </div>
        <div className=" d-flex justify-content-end">
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              setApprovalAddClick(false);
              setSignerClick(false);
              setCounterpartyClick(false);
              setAddTemplateApprovers(false);
              setNegotiationWorkFlowClick(false);
              setEstampclick(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            size="sm"
            onClick={() => {
              onHandleSignature();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}

export default Coordinate;
