import { Button, Form, Modal } from "react-bootstrap";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { useState } from "react";

function UpdateDocumentName({
  show,
  onHide,
  context,
  setDcumentName,
}: {
  show: boolean;
  onHide: Function;
  setDcumentName: Function;
  context: TemplateContextInterface | ContractContextInterface | null;
}) {
  const [updatedName, setUpdatedName]: [string, Function] = useState("");
  const [eroorMsg, setError]: [string, Function] = useState("");

  const onUpdateName = async () => {
    if (context?.data?.id && updatedName) {
      const response = await context?.updateName(
        context?.data?.id!,
        updatedName
      );

      if (response?.data?.success) {
        // context?.updateName(context?.data?.id!, updatedName);
        context?.refresh();
        setDcumentName(context?.data?.name);
        onHide();
      } else {
        setError(response?.error?.[0]);
      }
    }
  };

  return (
    <Modal show={show} onHide={() => onHide} centered>
      <Modal.Header className="modal-header-bottom">
        <div className="pt-3">
          <h6 className=" fw-semibold modal-title-name">
            {/* Rename document name */}
            Name the document
          </h6>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <div className="col-md-12 col-12">
              <label className="input_label">Name</label>
              <input
                type="text"
                name="name"
                defaultValue={context?.data?.name}
                onChange={(e) => {
                  setUpdatedName(e?.target?.value);
                  setError("");
                }}
                className="form-control"
                placeholder="Name"
              />
            </div>
            {eroorMsg && <div className="counterparty_error">{eroorMsg}</div>}
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="cancelbtn me-3 "
          size="sm"
          onClick={() => {
            onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          className="sharebtndanger"
          disabled={updatedName?.length === 0}
          size="sm"
          onClick={onUpdateName}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateDocumentName;
