import { Button, FloatingLabel, Form, Modal, Stack } from "react-bootstrap";
import { api } from "../../utils/api";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useEffect, useState } from "react";
import { useTemplate } from "../Templates/TemplateProvider";
import { useContract } from "./ContractProvider";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { StatusEnum } from "../../interfaces/StatusEnum";

function RejectPopup({
  show,
  onHide,
  isApprovedActive,
  setShowSuccessToastWorkFlow,
  setWorkFlowBodyMessage,
  setWorkFlowShowFailureToast,
  setIsApproved,
  isTemplateACtive,
}: {
  show: boolean;
  onHide: Function;
  isApprovedActive: string;
  setShowSuccessToastWorkFlow: Function;
  setWorkFlowBodyMessage: Function;
  setWorkFlowShowFailureToast: Function;
  setIsApproved: Function;
  isTemplateACtive?: any;
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const authContext = useAuthentication();
  const templateContext = useTemplate();
  const context = useContract();
  const [message, setMessage]: [string, Function] = useState("");
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [isViewerAccess, setIsViewerAccess]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    let viewer: any = context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );

    if (viewer?.access === "VIEWER") {
      setIsViewerAccess(true);
    }
  }, [context?.data?.externalUser]);

  const onApprove = async () => {
    if (isViewerAccess) {
      try {
        if (!id) return;
        const response = await api.negotiationViewerApprove(id, {
          approve: true,
          message: message,
          suggest: false,
        });

        if (response) {
          setLoader(false);
          onHide();
          templateContext?.refresh();
          navigate(routes.contracts);
          setShowSuccessToastWorkFlow(true);
          setWorkFlowBodyMessage("Approved!");
          setIsApproved(true);
        } else {
          setLoader(false);
          setWorkFlowShowFailureToast(true);
          setWorkFlowBodyMessage(response?.data?.error?.[0]);
          onHide();
        }
      } catch (error) {}
    } else {
      if (context?.data?.status === StatusEnum?.pending_negotitation) {
        if (
          context?.data?.approvers?.filter(
            (el: any) =>
              el?.is_optional === true &&
              el?.user?.sub === authContext?.currentUser?.sub
          ).length !== 0
        ) {
          setLoader(true);
          if (!id) return;
          const res = await api.approveContract(id, {
            approve: true,
            message: message,
            suggest: false,
          });

          if (res?.data?.success) {
            setLoader(false);
            navigate(generatePath(routes.overviewContracts, { id: id }));
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Approved!");
            setIsApproved(true);
            context?.refresh();
            onHide();
          } else if (!res?.data?.success) {
            setLoader(false);
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
          }
        } else {
          setLoader(true);
          try {
            if (!id) return;
            const response = await api.UpdateNegotiationWorkflow(id, {
              approve: true,
              message: message,
              suggest: false,
            });

            if (response?.data?.success) {
              setLoader(false);
              onHide();
              templateContext?.refresh();
              navigate(routes.contracts);
              setShowSuccessToastWorkFlow(true);
              setWorkFlowBodyMessage("Approved!");
              setIsApproved(true);
            } else {
              setLoader(false);
              setWorkFlowShowFailureToast(true);
              setWorkFlowBodyMessage(response?.data?.error?.[0]);
              onHide();
              context?.refresh();
            }
          } catch (error) {}
        }
      } else {
        if (isTemplateACtive) {
          setLoader(true);
          if (!id) return;
          const res = await api.approveTemplate(id);

          if (res) {
            setLoader(false);
            onHide();
            templateContext?.refresh();
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Approved!");
            navigate(routes.contracts);
            setIsApproved(true);
          } else {
            setLoader(false);
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
          }
        } else {
          setLoader(true);
          if (!id) return;
          const res = await api.approveContract(id, {
            approve: true,
            message: message,
            suggest: false,
          });

          if (res?.data?.success) {
            setLoader(false);
            navigate(generatePath(routes.overviewContracts, { id: id }));
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Approved!");
            setIsApproved(true);
            context?.refresh();
            onHide();
          } else if (!res?.data?.success) {
            setLoader(false);
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
          }
        }
      }
    }
  };

  const onReject = async () => {
    if (isViewerAccess) {
      try {
        if (!id) return;
        const response = await api.negotiationViewerApprove(id, {
          approve: false,
          message: message,
          suggest: false,
        });

        if (response) {
          setLoader(false);
          onHide();
          templateContext?.refresh();
          navigate(routes.contracts);
          setShowSuccessToastWorkFlow(true);
          setWorkFlowBodyMessage("Approved!");
          setIsApproved(true);
        } else {
          setLoader(false);
          setWorkFlowShowFailureToast(true);
          setWorkFlowBodyMessage(response?.data?.error?.[0]);
          onHide();
        }
      } catch (error) {}
    } else {
      if (context?.data?.status === StatusEnum?.pending_negotitation) {
        if (
          context?.data?.approvers?.filter(
            (el: any) =>
              el?.is_optional === true &&
              el?.user?.sub === authContext?.currentUser?.sub
          ).length !== 0
        ) {
          if (!id) return;
          const res = await api.approveContract(id, {
            approve: false,
            message: message,
            suggest: false,
          });

          if (res?.data?.success) {
            navigate(generatePath(routes.overviewContracts, { id: id }));
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Contract rejected.");
            onHide();
            setIsApproved(true);
            context?.refresh();
          } else {
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
            context?.refresh();
          }
        } else {
          try {
            if (!id) return;
            const response = await api.UpdateNegotiationWorkflow(id, {
              approve: false,
              message: message,
              suggest: false,
            });

            if (response) {
              context?.refresh();

              navigate(generatePath(routes.overviewContracts, { id: id }));
              setShowSuccessToastWorkFlow(true);
              setWorkFlowBodyMessage("Contract rejected.");
              setIsApproved(true);
              onHide();
            } else {
              setWorkFlowShowFailureToast(true);
              setWorkFlowBodyMessage(response?.data?.error?.[0]);
              onHide();
              context?.refresh();
            }
          } catch (error) {}
        }
      } else {
        if (isTemplateACtive) {
          if (!id) return;
          const res = await api.rejectTemplate(id);
          if (res?.data?.success) {
            templateContext?.refresh();
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Template rejected.");
            setIsApproved(true);
            onHide();
          } else {
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
          }
        } else {
          if (!id) return;
          const res = await api.approveContract(id, {
            approve: false,
            message: message,
            suggest: false,
          });

          if (res?.data?.success) {
            navigate(generatePath(routes.overviewContracts, { id: id }));
            setShowSuccessToastWorkFlow(true);
            setWorkFlowBodyMessage("Contract rejected.");
            onHide();
            setIsApproved(true);
            context?.refresh();
          } else {
            setWorkFlowShowFailureToast(true);
            setWorkFlowBodyMessage(res?.data?.error?.[0]);
            onHide();
          }
        }
      }
    }
  };
  return (
    <>
      <Modal show={show} centered onHide={() => onHide()}>
        <Modal.Body className="p-4">
          <Stack>
            <h5 className="fw-semibold py-2">{isApprovedActive} </h5>
          </Stack>
          <div className="textAreafrom">
            <FloatingLabel controlId="floatingTextarea2" label="">
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                onChange={(e) => setMessage(e?.target?.value)}
              />
            </FloatingLabel>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            disabled={message?.length === 0 ? true : false}
            size="sm"
            onClick={() => {
              isApprovedActive === "Approve" ? onApprove() : onReject();
            }}
          >
            {isApprovedActive}{" "}
            {loader ? <span className="btn_loader"></span> : ""}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RejectPopup;
