import { ResponsiveBar } from "@nivo/bar";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { useEffect, useState } from "react";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { stringToHexColor } from "../../../../../utils";

const AdminMonthlyOrganisation = ({ filterData }: any) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [[], Function] = useState([]);
  const [keyValues, SetKeyValues] = useState<any>([]);

  const ChartDataDetails = async () => {
    try {
      let response = await context?.monthlyStackedBarChartDetails(
        filterData ? filterData : ""
      );
      if (response?.success) {
        const data: any = response?.data?.map(
          ({ month, organizations }: any) => {
            const organizationCount = organizations.reduce(
              (acc: any, org: any) => {
                acc[org] = (acc[org] || 0) + 1;
                return acc;
              },
              {}
            );
            return { month, ...organizationCount };
          }
        );
        const allOrganizations = response?.data.reduce(
          (allOrgs: any, { organizations }: any) => {
            return allOrgs.concat(organizations);
          },
          []
        );
        SetKeyValues([...new Set(allOrganizations)]);
        setChartData(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);

  return (
    <>
      <div style={{ height: "400px" }}>
        {chartData?.length === 0 ? (
          <AnalyticsNoDataFound />
        ) : (
          <>
            <ResponsiveBar
              data={chartData}
              keys={keyValues}
              indexBy="month"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              groupMode="stacked"
              colors={keyValues?.map((item: string) => stringToHexColor(item))}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Month",
                legendPosition: "middle",
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AdminMonthlyOrganisation;
