import { useAdminContext } from "./ContractProvider";
import Contracts from "./Contracts";

function ListContractsDetail() {
  const context = useAdminContext();
  return context && <Contract />;
}

export default ListContractsDetail;

const Contract = () => {
  return (
    <>
      <Contracts />
    </>
  );
};
