import { usePdf } from "@mikecousins/react-pdf";
import { useEffect, useRef, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import DraggableRectangle from "./DraggableRectangle";
import { useContract } from "../modules/Contracts/ContractProvider";
import { PDFDocumentProxy, getDocument } from "pdfjs-dist";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { useLocation } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";

const PDFViewer = ({
  file,
  pdfPopup,
  setIsMouseEnter,
}: {
  file: string;
  pdfPopup: boolean;
  setIsMouseEnter: Function;
}) => {
  const context = useContract();
  const authContext = useAuthentication();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const canvasRef: any = useRef(null);

  const [pdfHeightandWidth, setPdfHeightAndWidth]: [any, Function] = useState();
  const [rectSize] = useState({ width: 100, height: 50 });

  const [rectPosition, setRectPosition]: [any, Function] = useState(
    context?.data?.externalUser
      ?.filter((user: any) => user?.access === "SIGNER")
      ?.map((el: any, idx: any) => ({
        type: "counterparty",
        name: el?.name,
        email: el?.email,
        id: el?._id,
        x: 450,
        y: 0 - idx * 60,
      }))
      .concat(
        context?.data?.internalSingers?.map((el: any, idx: any) => ({
          type: "signer",
          name: el?.user?.name,
          email: el?.user?.email,
          id: el?._id,
          x: 0,
          y: 0 - idx * 60,
        }))
      )
      ?.map((item: any) => ({
        type: item?.type,
        x: 12 + item?.x,
        y: 775 + item?.y,
        id: item?.id,
        email: item?.email,
        name: item?.name,
      }))
  );

  const { pdfDocument } = usePdf({
    scale: 1,
    file: file,
    page,
    canvasRef,
  });

  const handleDrag = (x: any, y: any, id: any, name: any, email: any) => {
    let pos = rectPosition.map((item: any) => {
      if (item.id == id) {
        return {
          x: x,
          y: y,
          id: id,
          name: name,
          email: email,
        };
      } else {
        return item;
      }
    });

    setRectPosition([...pos]);
  };

  const currentUserAccess = (position: any) => {
    handleLggedInDifference(position);
    let data: any;
    const combineArray: any = context?.data?.internalSingers?.concat(
      context?.data?.externalUser
    );

    const currentPostionDetails = combineArray?.find(
      (data: any) => data?._id === position?.id
    );

    const findCurrentUserIndex: any = combineArray?.findIndex(
      (item: { _id: string }) => item?._id === position?.id
    );

    if (findCurrentUserIndex > -1) {
      data = combineArray[findCurrentUserIndex];
    }

    return (findCurrentUserIndex > -1 && data?.status === "PENDING") ||
      context?.data?.createdBy?.sub === authContext?.currentUser?.sub
      ? currentPostionDetails?.status === "PENDING"
      : false;
  };

  const handleLggedInDifference = (position: any) => {
    const combineArray: any = context?.data?.internalSingers?.concat(
      context?.data?.externalUser
    );

    const currentPostionDetails = combineArray?.find(
      (data: any) => data?._id === position?.id
    );

    return currentPostionDetails?._id;
  };

  const updateCoordinate = () => {
    if (context?.data?.workflow_status === "PUBLISHED" || pdfPopup) {
      let signer: any = context?.data?.internalSingers?.map((item: any) => ({
        ...item,
        type: "signer",
      }));

      let counterparty: any = context?.data?.externalUser?.map((item: any) => ({
        ...item,
        type: "counterparty",
      }));

      const filteredData: any = [];

      signer.forEach((item: any) => {
        const coordinate = item.co_ordinates.find(
          (coordinate: any) => coordinate.page === page
        );
        if (coordinate) {
          filteredData.push({
            x: coordinate.x,
            y: pdfHeightandWidth?.height - coordinate.y - 50,
            id: item._id,
            email: item.user?.email,
            name: item.user?.name,
            type: item.type,
          });
        }
      });

      counterparty.forEach((item: any) => {
        const coordinate = item.co_ordinates.find(
          (coordinate: any) => coordinate.page === page
        );
        if (coordinate) {
          filteredData.push({
            x: coordinate.x,
            y: pdfHeightandWidth?.height - coordinate.y - 50,
            id: item._id,
            email: item.email,
            name: item.name,
            type: item.type,
          });
        }
      });
      if (filteredData?.length === 0) {
        setRectPosition(
          context?.data?.externalUser
            ?.filter((user: any) => user?.access === "SIGNER")
            ?.map((el: any, idx: any) => ({
              type: "counterparty",
              name: el?.name,
              email: el?.email,
              id: el?._id,
              x: 450,
              y: 0 - idx * 60,
            }))
            .concat(
              context?.data?.internalSingers?.map((el: any, idx: any) => ({
                type: "signer",
                name: el?.user?.name,
                email: el?.user?.email,
                id: el?._id,
                x: 0,
                y: 0 - idx * 60,
              }))
            )
            ?.map((item: any) => ({
              type: item?.type,
              x: 12 + item?.x,
              y: 775 + item?.y,
              id: item?.id,
              email: item?.email,
              name: item?.name,
            }))
        );
      } else {
        setRectPosition(filteredData);
      }
    }
  };

  useEffect(() => {
    const updatedArray = rectPosition.map((item: any) => {
      const co_ordinates = [
        {
          page: 1,
          x: item.x,
          y: pdfHeightandWidth?.height - item?.y - 50,
          height: 25,
          width: 100,
        },
      ];

      return {
        co_ordinates: co_ordinates,
        user: item.id,
      };
    });

    context?.setCoordinate(updatedArray);
  }, [rectPosition]);

  useEffect(() => {
    updateCoordinate();
  }, [pdfHeightandWidth?.height, page]);

  useEffect(() => {
    context?.setCoordinate(
      rectPosition?.map((el: any) => ({
        co_ordinates: [
          {
            page: 1,
            x: el?.x,
            y: pdfHeightandWidth?.height - el?.y - 50,
            height: 25,
            width: 100,
          },
        ],
        user: el?.id,
      }))
    );
  }, [pdfHeightandWidth]);

  useEffect(() => {
    const fetchPdfAndCalculateDimensions = async () => {
      try {
        const loadingTask = getDocument(file);
        const pdf: PDFDocumentProxy = await loadingTask.promise;

        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        const { width, height } = viewport;

        setPdfHeightAndWidth({ width: width, height: height });
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchPdfAndCalculateDimensions();
  }, [file]);

  return (
    <>
      {location?.pathname?.includes("overview") ? (
        <Stack className="w-md-50" style={{ margin: "0px auto" }}>
          {!pdfDocument && <span>Loading...</span>}
          <div className=" position-relative">
            <canvas ref={canvasRef} />
            {rectPosition?.map((position: any) => {
              return (
                currentUserAccess(position) && (
                  <DraggableRectangle
                    position={position}
                    size={rectSize}
                    userName={position?.name}
                    onDrag={
                      context?.data?.workflow_status === "PUBLISHED"
                        ? ""
                        : handleDrag
                    }
                    backgroundColor={
                      context?.data?.internalSingers?.filter(
                        (data: any) => data?._id === position?.id
                      )?.length !== 0
                        ? "#3c3ada"
                        : "gray"
                    }
                  />
                )
              );
            })}
          </div>
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Stack
              direction="horizontal"
              className="justify-content-center my-2"
            >
              <Button
                variant="outline-dark"
                size="sm"
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                  context?.refresh();
                }}
                className="mx-2"
              >
                Previous
              </Button>
              {page}
              {"/"}
              {context?.data?.pages}
              <Button
                variant="outline-dark"
                size="sm"
                className="mx-2"
                disabled={page === pdfDocument?.numPages}
                onClick={() => {
                  setPage(page + 1);
                  context?.refresh();
                }}
              >
                Next
              </Button>
            </Stack>
          )}
        </Stack>
      ) : (
        <>
          {!pdfDocument && <span>Loading...</span>}
          <div
            className=" position-relative d-inline-block"
            onMouseEnter={() => setIsMouseEnter(true)}
          >
            <canvas ref={canvasRef} />

            {rectPosition?.map((position: any) => {
              return (
                currentUserAccess(position) && (
                  <>
                    <DraggableRectangle
                      position={position}
                      size={rectSize}
                      userName={position?.name}
                      onDrag={
                        context?.data?.workflow_status === "PUBLISHED" ||
                        location?.pathname?.includes("view")
                          ? ""
                          : handleDrag
                      }
                      backgroundColor={
                        context?.data?.internalSingers?.filter(
                          (data: any) => data?._id === position?.id
                        )?.length !== 0
                          ? "#3c3ada"
                          : "gray"
                      }
                    />
                  </>
                )
              );
            })}

            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <Stack
                direction="horizontal"
                className="justify-content-between my-2"
              >
                <Button
                  size="sm"
                  disabled={page === 1}
                  onClick={() => {
                    setPage(page - 1);
                    updateCoordinate();
                  }}
                  className="pbtn "
                >
                  <span className=" me-2">
                    <MdKeyboardArrowLeft color="#3c3ada" fontSize={24} />
                  </span>{" "}
                  Previous
                </Button>
                <span
                  style={{ background: "#ced4da" }}
                  className="py-2 px-3 rounded-5"
                >
                  <span className="me-2">
                    <BsCheckCircleFill color="#3c3ada" fontSize={24} />
                  </span>
                  Page &nbsp;
                  {page}
                  {" of "}
                  {context?.data?.pages}
                </span>
                <Button
                  size="sm"
                  disabled={page === pdfDocument?.numPages}
                  onClick={() => {
                    setPage(page + 1);
                    updateCoordinate();
                  }}
                  className="pbtn"
                >
                  Next{" "}
                  <span className=" ms-2">
                    <MdKeyboardArrowRight color="#3c3ada" fontSize={24} />
                  </span>
                </Button>
              </Stack>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PDFViewer;
