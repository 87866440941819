import "./CommentThreadPopover.css";
import { ReactEditor, useSlate } from "slate-react";
import {
  activeCommentThreadIDAtom,
  commentThreadIDsState,
  commentThreadsState,
} from "../utils/CommentState";
import { useCallback, useEffect, useState } from "react";
import { useRecoilStateLoadable, useSetRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import CommentRow from "./CommentRow";
import { Editor, Text } from "slate";
import NodePopover from "./NodePopover";
import { getFirstTextNodeAtSelection } from "../utils/EditorUtils";
import { getCommentThreadsOnTextNode } from "../utils/EditorCommnentUtils";
import { useAuthentication } from "../../../../../modules/Authentication/AuthenticationProvider";
import { useEditor as context } from "../../../../EditorProvider";
import { IUserResponse } from "../../../../../interfaces/response/IUserResponse";
import { useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineGroups } from "react-icons/md";
import { BiCheckCircle, BiLock, BiSolidSend, BiWorld } from "react-icons/bi";
import AvatarSection from "../../../../../ui/Avatar";
import { MentionsInput } from "react-mentions";
import { Mention } from "react-mentions";

export default function CommentThreadPopover({
  editorOffsets,
  selection,
  threadID,
}: any) {
  const editor = useSlate();
  const { id } = useParams();
  const auth = useAuthentication();
  const editorContext = context();
  const authContext = useAuthentication();
  const [currentAccess, setCurrentAccess] = useState("ME");
  const [notify, setNotify] = useState(false);
  const [accessShow, setAccessShow] = useState(false);
  const [collaboratorsLoading, setCollaboratorsLoading] = useState(false);
  const [collaborators, setCollaborators] = useState<any>([{}]);
  const [externalUsers, setExternalUsers] = useState<any>([{}]);

  useEffect(() => {
    getCollaboratorsFromContract();
  }, []);

  const [mentionsCollaboration, setMentionsCollaboration] = useState<
    IUserResponse | undefined | any
  >();

  const getCollaboratorsFromContract = async () => {
    setCollaboratorsLoading(true);
    const res = await editorContext?.getContractCollaborators(id);
    const { contract, success } = res || {};
    if (success) {
      const combinedArray = [
        ...contract?.collaborators,
        ...contract?.approvers,
        ...contract?.external_users,
        // ...contract?.internal_signer,
        // ...contract?.verifiers,
      ];
      let combinedMapArray = combinedArray?.map((user: any) => ({
        id: user?.user?.sub,
        display: user?.user?.name,
      }));
      setCollaborators(contract?.collaborators);
      setExternalUsers(contract?.external_users);

      uniqueArray(combinedMapArray);
    }
    setCollaboratorsLoading(false);
  };

  const uniqueArray = (users: any) => {
    let uniqueArrays = users.filter((obj: any, index: number) => {
      return index === users.findIndex((o: any) => obj.id === o.id);
    });
    setMentionsCollaboration(uniqueArrays);
    setMentionsUUID(mentionsCollaboration?.map((item: any) => item?.id));
  };

  var mentions = mentionsCollaboration?.map((user: any) => ({
    id: user.id,
    display: user.display,
  }));

  const textNode = getFirstTextNodeAtSelection(editor, selection);
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const [threadDataLoadable, setCommentThreadData] = useRecoilStateLoadable(
    commentThreadsState(threadID)
  );
  const [threadIds, setThreadIds] = useRecoilStateLoadable(
    commentThreadIDsState
  );
  const [commentText, setCommentText] = useState("");
  const [mentionsUUID, setMentionsUUID] = useState<(string | undefined)[]>();
  const [cleanedText, setCleanedText] = useState("");

  useEffect(() => {
    getUUIDFromCommentText();
    filterOnlyNameFromCommentedText();
    threadData?.comments.length === 0
      ? setAccessShow(true)
      : setAccessShow(false);

    threadData?.comments[0]?.access === "TEAMS" && setCurrentAccess("MYTEAM");

    threadData?.comments[0]?.access === "PUBLIC" &&
      setCurrentAccess("EXTERNAL");

    threadData?.comments[0]?.access === "PRIVATE" && setCurrentAccess("ME");
  }, [commentText]);

  const getUUIDFromCommentText = () => {
    const text = commentText;
    const uuidRegex = /\(([^)]+)\)/g;

    const uuids = [];
    let match;
    while ((match = uuidRegex.exec(text))) {
      const uuid = match[1];
      uuids.push(uuid);
    }
    uuids.push(auth?.currentUser?.id);
  };

  const filterOnlyNameFromCommentedText = () => {
    const cleanedText1 = commentText.replace(/\[|\]|\([^)]+\)/g, "");
    setCleanedText(cleanedText1);
  };

  const onClick = () => {
    setCommentThreadData((threadData: any) => ({
      ...threadData,
      comments: [
        ...threadData?.comments,
        {
          text: commentText,
          author: auth?.currentUser?.name,
          creationTime: new Date(),
        },
      ],
    }));
    setCommentText("");
    onCommentAdd();
    setActiveCommentThreadID(null);
  };

  const onCommentAdd = async () => {
    try {
      const params = {
        value: [cleanedText],
        ref_id: threadID,
        parent_id: threadID,
        access:
          currentAccess === "MYTEAM"
            ? "TEAMS"
            : currentAccess === "ME"
            ? "PRIVATE"
            : "PUBLIC",
        mentions: currentAccess === "ME" ? [] : mentionsUUID,
        is_notify: notify,
      };
      await editorContext?.addComment(params);
      setNotify(false);
    } catch (error) {}
  };

  useEffect(() => {}, [threadIds]);

  const commentDelete = async () => {
    setActiveCommentThreadID(null);

    setThreadIds((prevState: any) => {
      const arrayFromSet = [...prevState];
      const filteredArray = arrayFromSet.filter((item) => item !== threadID);
      return new Set(filteredArray as any);
    });
    Editor.removeMark(editorContext?.editor, "commentThread_" + threadID);
    try {
      await editorContext?.deleteComment(threadID);
    } catch (error) {}
  };

  const onCommentResolved = async () => {
    try {
      const params = {
        is_resolved: true,
      };
      await editorContext?.resolveComment(threadID, params);
    } catch (error) {}
  };

  const onCommentReOpen = async () => {
    try {
      const params = {
        is_resolved: false,
      };
      await editorContext?.resolveComment(threadID, params);
    } catch (error) {}
  };

  const onToggleStatus = useCallback(() => {
    const currentStatus = threadDataLoadable.contents.status;
    setCommentThreadData((threadData: any) => ({
      ...threadData,
      status: currentStatus === false ? true : false,
    }));
    currentStatus === false && onCommentResolved();
    currentStatus === true && onCommentReOpen();
  }, [setCommentThreadData, threadDataLoadable?.contents?.status]);

  const onCommentTextChange = useCallback(
    (event: any) => setCommentText(event.target.value),

    [setCommentText]
  );

  const onClickOutside = useCallback(
    (event: any) => {
      const slateDOMNode = event.target.hasAttribute("data-slate-node")
        ? event.target
        : event.target.closest(`[data-slate-node]`);

      // The click event was somewhere outside the Slate hierarchy

      // if (slateDOMNode == null) {
      //   setActiveCommentThreadID(null);
      //   return;
      // }

      const slateNode = ReactEditor?.toSlateNode(editor, slateDOMNode);

      // Click is on another commented text node => do nothing.
      if (
        Text.isText(slateNode) &&
        getCommentThreadsOnTextNode(slateNode).size > 0
      ) {
        return;
      }

      setActiveCommentThreadID(null);
    },
    [editor, setActiveCommentThreadID]
  );

  const hasThreadData = threadDataLoadable.state === "hasValue";
  const threadData = threadDataLoadable?.contents;

  return (
    <>
      {collaboratorsLoading ? (
        <span>Loading...</span>
      ) : (
        <NodePopover
          editorOffsets={editorOffsets}
          isBodyFullWidth={true}
          node={textNode}
          className={""}
          header={
            <Header
              externalUsers={externalUsers}
              collaborators={collaborators}
              status={threadData?.status ?? null}
              shouldAllowStatusChange={
                hasThreadData && threadData?.comments?.length > 0
              }
              onToggleStatus={onToggleStatus}
              currentCommentID={threadID}
              context={editorContext}
              commentDelete={commentDelete}
              currentAccess={currentAccess}
              setCurrentAccess={setCurrentAccess}
              accessShow={accessShow}
              authContext={authContext}
              owner={threadData?.comments[0]?.authorID}
            />
          }
          onClickOutside={onClickOutside}
        >
          <div className="comment-list ">
            <div
              className=" w-100 px-2"
              style={{
                maxHeight: "40vh",
                zIndex: 1,
                overflowY: "scroll",
                paddingBottom: "90px",
              }}
            >
              {threadData?.comments?.map((comment: any, index: any) => {
                return (
                  // comment?.mentions?.includes(auth?.currentUser?.id) && (
                  <div>
                    <CommentRow
                      k={index}
                      count={10}
                      comment={comment}
                      threadID={threadID}
                      context={editorContext}
                      authContext={authContext}
                      mentions={mentions}
                      status={threadData?.status}
                      onToggleStatus={onToggleStatus}
                      commentDelete={commentDelete}
                      showVerticalDots={true}
                    />
                  </div>
                  // )
                );
              })}
            </div>
            {!threadData?.status && (
              <div className=" commentBoxSnd px-3 border-top">
                <div className="  d-flex  align-items-center py-3 position-relative">
                  <div className="me-3">
                    <AvatarSection
                      imageData={""}
                      name={authContext?.currentUser?.name}
                    />
                  </div>
                  <div
                    className=" d-flex align-items-center flex-grow-1"
                    style={{ width: "290px" }}
                  >
                    {currentAccess === "ME" ? (
                      <>
                        <textarea
                          className="doc_searchQueryInput form-control"
                          name="doc_searchQueryInput"
                          value={commentText}
                          onChange={onCommentTextChange}
                          placeholder="Enter your text"
                          maxLength={2048}
                          style={{ minHeight: "10px" }}
                        />
                      </>
                    ) : (
                      <div className=" w-100 mb-4">
                        <MentionsInput
                          className="  mentionsInput form-control"
                          type="text"
                          bsPrefix={"comment-input"}
                          value={commentText}
                          onChange={onCommentTextChange}
                          placeholder="Enter your text"
                          a11ySuggestionsListlabel={"Suggested Mentions"}
                        >
                          <Mention data={mentions} trigger={"@"} />
                        </MentionsInput>
                      </div>
                    )}
                  </div>
                  <div className=" resolve commentSndBtn ms-2 ">
                    <Button
                      className="resolvebtn p-0"
                      size="sm"
                      disabled={commentText.length === 0}
                      onClick={onClick}
                    >
                      <BiSolidSend color="" fontSize={24} />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {hasThreadData ? (
            <>
              <div>
                {/* <div>
                <MentionsInput
                  style={{ minHeight: "40px", width: "250px" }}
                  bsPrefix={"comment-input form-control"}
                  value={commentText}
                  onChange={onCommentTextChange}
                  placeholder={"Comment or add other with @"}
                  a11ySuggestionsListlabel={"Suggested Mentions"}
                >
                  <Mention data={mentions} trigger={"@"} />
                </MentionsInput>
              </div>
              <InputGroup>
                <InputGroup.Text>
                  <select
                    onChange={(e) =>
                      e.target.value === "Private"
                        ? setAccessControl("PRIVATE")
                        : setAccessControl("PUBLIC")
                    }
                  >
                    <option>Public</option>
                    <option>Private</option>
                  </select>
                </InputGroup.Text>
              </InputGroup>
              <Button
                size="sm"
                variant="primary"
                disabled={commentText.length === 0}
                onClick={onClick}
              >
                Comment
              </Button> */}
              </div>
            </>
          ) : (
            "Loading"
          )}
        </NodePopover>
      )}
    </>
  );
}

function Header({
  externalUsers,
  collaborators,
  onToggleStatus,
  // shouldAllowStatusChange,
  status,
  commentDelete,
  currentAccess,
  setCurrentAccess,
  accessShow,
  authContext,
  owner,
}: any) {
  return (
    <div className="comment-thread-popover-header d-flex align-items-center">
      <div className=" Actions">
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            className="Actions_title"
            id="dropdown-basic"
          >
            <span className="commentbage rounded-4 px-2 ms-2 fw-semibold me-2">
              {currentAccess === "MYTEAM"
                ? "My Team"
                : currentAccess === "ME"
                ? "Only to me"
                : currentAccess === "EXTERNAL"
                ? "External"
                : null}
            </span>

            {currentAccess === "MYTEAM" ? (
              <span title="My Team">
                <MdOutlineGroups fontSize={20} color="#c5d0de" />
              </span>
            ) : currentAccess === "EXTERNAL" ? (
              <span title="External">
                <BiWorld fontSize={20} color="#c5d0de" />
              </span>
            ) : currentAccess === "ME" ? (
              <span title="Only to me">
                <BiLock fontSize={20} color="#c5d0de" />
              </span>
            ) : null}
          </Dropdown.Toggle>
          {accessShow && (
            <Dropdown.Menu className="create-dropdown-css">
              <>
                <Dropdown.Item className="sort-by">
                  <span
                    className=" d-flex align-items-center"
                    onClick={() => setCurrentAccess("ME")}
                  >
                    <span>
                      <BiLock fontSize={20} color="#c5d0de" />
                    </span>
                    <span className="ms-2">Only to me</span>
                  </span>
                </Dropdown.Item>
                {collaborators.length > 1 && (
                  <Dropdown.Item className="sort-by">
                    <span
                      className=" d-flex align-items-center"
                      onClick={() => setCurrentAccess("MYTEAM")}
                    >
                      <span>
                        <MdOutlineGroups fontSize={24} color="#c5d0de" />
                      </span>
                      <span className="ms-2">My Team</span>
                    </span>
                  </Dropdown.Item>
                )}{" "}
                {collaborators?.length > 1 && externalUsers?.length > 0 && (
                  <Dropdown.Item className="sort-by">
                    <span
                      className=" d-flex align-items-center"
                      onClick={() => setCurrentAccess("EXTERNAL")}
                    >
                      <span>
                        <BiWorld fontSize={20} color="#c5d0de" />
                      </span>
                      <span className="ms-2">External</span>
                    </span>
                  </Dropdown.Item>
                )}
              </>
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>
      {authContext?.currentUser?.id === owner && (
        <div className="resolve">
          <Button
            size="sm"
            variant="resolvebtn p-0 mx-3"
            onClick={onToggleStatus}
            // disabled={status === true}
          >
            {status === false ? (
              <span>
                <BiCheckCircle color="#c5d0de" fontSize={20} />
              </span>
            ) : (
              <span>
                <BiCheckCircle color="#2abf86" fontSize={20} />
              </span>
            )}
          </Button>
        </div>
      )}
      {authContext?.currentUser?.id === owner && (
        <div className=" Actions">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              className="Actions_title"
              id="dropdown-basic"
            >
              <BsThreeDotsVertical fontSize={20} color="#c5d0de" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="create-dropdown-css">
              <>
                <Dropdown.Item
                  className="sort-by"
                  onClick={() => commentDelete()}
                >
                  Delete
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
}
