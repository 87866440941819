import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { stringToHexColor, updateText } from "../../../../../utils";

const AdminContractsVolume = ({ filterData, isShowValue }: any) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [any, Function] = useState();
  const ContractsVolumeDetails = async () => {
    try {
      let response = await context?.dountChartDataDetails(
        filterData ? filterData : ""
      );
      if (response?.success) {
        var data = response?.data.map(function (obj: any) {
          if (obj.category === null || obj.category === "") {
            obj.category = "No category";
          }
          return obj;
        });
        const dataWithColors = data.map((item: any) => ({
          ...item,
          id: item?.category?.substring(0, 3),
          color: stringToHexColor(item?.category),
          volume: item?.volume,
          value: isShowValue ? item?.value : item?.volume,
        }));
        setChartData(dataWithColors);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ContractsVolumeDetails();
  }, [filterData]);
  return (
    <>
      {chartData && <DountChart data={chartData} isShowValue={isShowValue} />}
    </>
  );
};

export default AdminContractsVolume;

const DountChart = ({ data, isShowValue }: any) => {
  return (
    <div style={{ height: "450px" }}>
      {data?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <>
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            tooltip={({ datum }: any) => {
              return (
                <div style={{ color: "black" }}>
                  <span>
                    {updateText(datum?.data?.category?.replace("_", " "))}:
                    {isShowValue
                      ? datum?.value?.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      : datum?.value}
                  </span>
                </div>
              );
            }}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "python",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "javascript",
                },
                id: "lines",
              },
            ]}
          />
        </>
      )}
    </div>
  );
};
