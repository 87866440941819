import { useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate } from "slate-react";
import {
  //   Editor,
  //   Transforms,
  createEditor,
  Descendant,
  //   Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";

import { Toolbar } from "../components";
import { toggleMark } from "../ToolbarMark";
import { BlockButton, MarkButton } from "../ToolbarButton";
import {
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatItalic,
  MdFormatUnderlined,
  MdLooksOne,
  MdLooksTwo,
  MdOutlineFormatBold,
} from "react-icons/md";
import { RenderElement } from "../../Editable/Element";
import { Leaf } from "../../Editable/Leaf";
import { InsertImageButton } from "../../Image";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEditor } from "../../EditorProvider";

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const RichTextExample = (props: any) => {
  const renderElement = useCallback(
    (props: any) => <RenderElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const editorContext = useEditor();
  // const [show, setShow] = useState(true);
  console.log(
    props?.type?.type === "Header"
      ? editorContext?.headerContent
      : editorContext?.footerContent,
    "editorContext?.headerContenteditorContext?.footerContent"
  );

  return (
    <>
      {editorContext?.showHeaderEditingOption && (
        <div>
          <Slate
            editor={editor}
            initialValue={
              props?.type?.type === "Header"
                ? editorContext?.headerContent
                : editorContext?.footerContent
            }
            onChange={(e) =>
              props?.type?.type === "Header"
                ? editorContext?.setHeaderContent(e)
                : editorContext?.setFooterContent(e)
            }
          >
            <div
              className="modal show"
              style={{ display: "block", position: "initial" }}
            >
              <Modal.Dialog className="modaldialog">
                <Modal.Header
                  // closeButton
                  className="justify-content-center"
                  style={{
                    backgroundColor: "#f7f3e6",
                    // width: "700px",
                    height: "75px",
                  }}
                >
                  <div>
                    {" "}
                    <Toolbar>
                      <MarkButton
                        format="bold"
                        icon={<MdOutlineFormatBold size={24} />}
                      />
                      <MarkButton
                        format="italic"
                        icon={<MdFormatItalic size={24} />}
                      />
                      <MarkButton
                        format="underline"
                        icon={<MdFormatUnderlined size={24} />}
                      />
                      <BlockButton
                        format="heading-one"
                        icon={<MdLooksOne size={24} />}
                      />
                      <BlockButton
                        format="heading-two"
                        icon={<MdLooksTwo size={24} />}
                      />
                      <BlockButton
                        format="left"
                        icon={<MdFormatAlignLeft size={24} />}
                      />
                      <BlockButton
                        format="center"
                        icon={<MdFormatAlignCenter size={24} />}
                      />
                      <BlockButton
                        format="right"
                        icon={<MdFormatAlignRight size={24} />}
                      />
                      <BlockButton
                        format="justify"
                        icon={<MdFormatAlignJustify size={24} />}
                      />
                      <div className="bg-white imageBtnModal">
                        <InsertImageButton />
                      </div>
                    </Toolbar>
                  </div>
                </Modal.Header>

                <Modal.Body style={{ backgroundColor: "#f7f3e6" }}>
                  <Editable
                    disabled={true}
                    style={{ outline: "none" }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={
                      props?.type?.type === "Header"
                        ? "Start Editing Header..."
                        : "Start Editing Footer..."
                    }
                    spellCheck
                    autoFocus
                    onKeyDown={(event) => {
                      for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event as any)) {
                          event.preventDefault();
                          const mark = HOTKEYS[hotkey];
                          toggleMark(editor, mark);
                        }
                      }
                    }}
                  />
                </Modal.Body>

                <Modal.Footer style={{ backgroundColor: "#f7f3e6" }}>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      editorContext?.setShowHeaderEditingOption(false)
                    }
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      editorContext?.handleUpdateDocument(
                        editorContext?.headerContent,
                        editorContext?.footerContent
                      )
                    }
                  >
                    Save changes
                  </Button>
                </Modal.Footer>
              </Modal.Dialog>
            </div>
          </Slate>
        </div>
      )}
    </>
  );
};

export const initialValueFromRichText: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
] as any;

export default RichTextExample;
