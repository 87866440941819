import { Field } from "formik";
import InputText from "./InputText";

interface FormikFieldProps {
  name: string;
  errors: any;
  validationSchema: any;
  label: string;
  type: string;
  autoFocus?: boolean;
  value?: any;
  isDisabled?: boolean;
  isPassword?: boolean;
  setPasswordIcon?: Function;
  passwordIcon?: boolean;
  placeholder?: string;
  className?: string;
  defaultValue?: any;
}
const FormikField = ({
  validationSchema,
  errors,
  label,
  type,
  name,
  autoFocus,
  isDisabled = false,
  isPassword,
  setPasswordIcon,
  passwordIcon,
  value,
  placeholder,
  className,
  defaultValue,
}: FormikFieldProps) => {
  return (
    <Field name={name}>
      {({ field }: any) => (
        <InputText
          validationSchema={validationSchema}
          label={label}
          error={errors[name]}
          type={type}
          autoFocus={autoFocus}
          field={field}
          placeholder={placeholder}
          className={className}
          isValid={!errors[name]}
          isDisabled={isDisabled}
          isPassword={isPassword}
          setPasswordIcon={setPasswordIcon}
          passwordIcon={passwordIcon}
          value={value}
          defaultValue={defaultValue}
        />
      )}
    </Field>
  );
};

export default FormikField;
