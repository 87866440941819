import { Dropdown } from "react-bootstrap";
import noData from "../../../assets/noData.png";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { useEffect, useState } from "react";

function NoFolderFound({
  setAddFolderPopup,
  setMoveToFolders,
  setIsActiveMove,
}: {
  setAddFolderPopup: Function;
  setMoveToFolders: Function;
  setIsActiveMove: Function;
}) {
  const { id } = useParams();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();
  const [updateRoles, setUpdateRoles]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.FOLDERS?.find((el: any) => el === "CREATE"));
      setUpdateRoles(guestRole?.FOLDERS?.find((el: any) => el === "UPDATE"));
    }
  }, [authContext?.rolesAndAccess]);
  return (
    <>
      <div className=" mt-2 mx-2 contract-title "></div>
      <div className={`File404  file_no_data_found`}>
        <div className="d-flex flex-column align-items-center">
          <div className={"font404 d-flex justify-content-center"}>
            <img src={noData} alt="no-data" width={200} />
          </div>
          {/* <div className="no-data-found">No folder found</div> */}
          <div>
            <>
              {(roles === "CREATE" ||
                (updateRoles === "UPDATE" && id !== undefined)) && (
                <>
                  <div className="p-2 bd-highlight  create-template-btn">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="new-contact-btn create-template contract-css ms-lg-3"
                        id="dropdown-basic"
                      >
                        {/* Add New */}
                        Organize
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="create-dropdown-css">
                        {roles === "CREATE" && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => setAddFolderPopup(true)}
                            >
                              Create new folder
                            </Dropdown.Item>
                            {id !== undefined && <Dropdown.Divider />}
                          </>
                        )}
                        {updateRoles === "UPDATE" && (
                          <>
                            {" "}
                            {id && (
                              <>
                                {/* <Dropdown.Divider /> */}

                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => {
                                    setMoveToFolders(true);
                                    setIsActiveMove("template");
                                  }}
                                >
                                  Add templates
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => {
                                    setMoveToFolders(true);
                                    setIsActiveMove("contract");
                                  }}
                                >
                                  Add contracts
                                </Dropdown.Item>
                              </>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoFolderFound;
