import { ReactNode, useEffect } from "react";
import { Col, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import logo from "../assets/logo12.png";
import bgimage from "../assets/sigin_bg.jpg";
import "./AuthLayout.scss";

import { useBootStrapLayout } from "../utils/BrowserLayoutProvider";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils/routes";

interface AuthLayoutProps {
  pageTitle?: string;
  title: string;
  subTitle: string;
  children: ReactNode;
}
const AuthLayout = ({
  pageTitle,
  title,
  subTitle,
  children,
}: AuthLayoutProps) => {
  useEffect(() => {
    document.title = pageTitle || "CLM";
  }, [pageTitle]);

  const context = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (context?.isLoggedIn) {
      navigate(routes.contracts);
    }
  }, [context?.isLoggedIn]);

  return (
    context && (
      <div
        id="auth-layout"
        className={isMobile ? "py-5 container-fluid" : "py-5 container"}
        style={isMobile ? { background: "#fefefe" } : {}}
      >
        <div className="d-flex justify-content-between h-100">
          <LeftPanel title={title} subTitle={subTitle}>
            {children}
          </LeftPanel>
          {!isMobile && <RightPanel />}
        </div>
      </div>
    )
  );
};

export default AuthLayout;

interface LeftPanelProps {
  title: string;
  subTitle: string;
  children: ReactNode;
}
const LeftPanel = ({ title, subTitle, children }: LeftPanelProps) => {
  const { isDesktop, isMobile } = useBootStrapLayout();

  return (
    <Col
      className="px-4 px-md-0 overflow-scroll"
      style={isDesktop ? { background: "#fefefe" } : undefined}
    >
      <div className="px-md-5 h-100">
        <div className=" d-flex justify-content-between flex-column h-100">
          <div>
            <div
              className={` d-flex align-items-center justify-content-between ${
                isMobile ? " py-0" : "pt-5"
              }`}
            >
              <a href="https://provider.clm.in" target="_blank">
                <img
                  src={logo}
                  alt="logo"
                  width={80}
                  className="logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                />
              </a>
            </div>
            <div className="mt-5">
              {!!title && title.trim().length > 0 && (
                <h3 className="fw-bold">{title}</h3>
              )}
              {!!subTitle && subTitle.trim().length > 0 && (
                <h6 className="fw-normal my-1">{subTitle}</h6>
              )}
              <div className="my-3">{children}</div>
            </div>
          </div>

          <div className="pb-md-5 ">
            <label className="  pb-2 d-block">
              © 2024, CLM Private Limited. All Rights Reserved.
            </label>
            <label htmlFor="">
              {" "}
              Terms and Conditions |{" "}
              <a
                href={routes.privacyPolicy}
                target="_blank"
                style={{ color: "#4a48c8" }}
                className="cursor-pointer"
              >
                Privacy Policy
              </a>
            </label>
          </div>
        </div>
      </div>
    </Col>
  );
};
const RightPanel = () => {
  return (
    <Col className="d-none d-sm-none d-md-block p-0">
      <div
        className="bgimage"
        style={{
          backgroundImage: `url(${bgimage})`,
        }}
      >
        <div className="overlay">
          <Stack className="align-items-center justify-content-end text-white h-100 px-3 py-5 mx-5  text-center">
            <p className="h1 fw-bold">Contracts made simple</p>
            <div className="border_line"></div>
            <p className="h6 mt-2 pt-3 w-75 opacity-70">
              The online collaborative platform to bring teams together,
              anytime, anywhere.
            </p>
          </Stack>
        </div>
      </div>
    </Col>
  );
};
