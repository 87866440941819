import { Formik } from "formik";
import AdminAuthlayout from "../../../layouts/AdminAuthLayout";
import FormikField from "../../../ui/FormikField";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import { Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { adminRoutes } from "../../../utils/adminRoutes";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import SuccessToast from "../../../ui/SuccessToast";
import FailureToast from "../../../ui/FailureToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useAdminAuth } from "./AdminAuthenticationProvider";

const AdminSignIn = () => {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);
  const navigate = useNavigate();
  const context = useAdminAuth();

  useEffect(() => {
    if (context?.isLoggedIn) {
      navigate(adminRoutes.root);
    }
  }, [context?.isLoggedIn]);

  const initialValues: { email: string; password: string } = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required(""),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });

  const handleSubmit = async (
    values: any,

    {
      setSubmitting,
      validateForm,
    }: { setSubmitting: Function; validateForm: Function }
  ) => {
    if (!context) {
      return;
    }

    const { email, password } = values;
    validateForm(values);
    const { success }: { success: boolean; message?: string } =
      await context.login(email, password);

    if (success) {
      setShowFailureToast(false);
      setShowSuccessToast(true);
    } else {
      setShowSuccessToast(false);
      setShowFailureToast(true);
    }
    setSubmitting(false);
  };
  return (
    <AdminAuthlayout
      title="Welcome!"
      pageTitle={showSuccessToast ? "CLM - Admin" : "Sign in your account"}
      subTitle="Hey ,Enter your to sign in to your account"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ errors, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormikField
                name="email"
                type="email"
                validationSchema={validationSchema}
                label="Email address "
                errors={errors}
                autoFocus
              />
              <FormikField
                name="password"
                type={passwordIcon ? "text" : "password"}
                validationSchema={validationSchema}
                label="Password"
                errors={errors}
                isPassword={true}
                setPasswordIcon={setPasswordIcon}
                passwordIcon={passwordIcon}
              />

              <div className=" d-flex justify-content-end my-2">
                <FormikSubmitButton
                  isSubmitting={isSubmitting}
                  className="my-2 w-100"
                  size="md"
                  submittingLabel={<span>Logging in...</span>}
                >
                  <span className="SignText">Login</span>
                </FormikSubmitButton>
              </div>
              <Stack>
                <Link
                  to={adminRoutes.forgetPassword}
                  className="text-decoration-none d-flex  text-primary d-flex justify-content-center my-3"
                >
                  I forgot my password
                </Link>
              </Stack>
            </form>
          );
        }}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="Logging in..."
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
            navigate(adminRoutes.root);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </AdminAuthlayout>
  );
};

export default AdminSignIn;
