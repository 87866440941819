import { useState } from "react";
import AdminAnalyticsHeader from "../Analytics/AdminAnalyticsHeader";
import AdminAnalyticsOverview from "../Analytics/AdminAnalyticsOverview";
import { AdminAnalyticsProvider } from "./AdminAnalyticsProvider";
import AdminHeader from "../../../layouts/AdminHeader";

const AdminAnalytics = () => {
  const [filterParams, setFilterParams]: [any, Function] = useState();

  return (
    <AdminAnalyticsProvider>
      <AdminHeader>
        <div>
          <AdminAnalyticsHeader setFilterData={setFilterParams} />
          <AdminAnalyticsOverview filterData={filterParams} />
        </div>
      </AdminHeader>
    </AdminAnalyticsProvider>
  );
};

export default AdminAnalytics;
