import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { api } from "../../utils/api";
import { Editor, Transforms, createEditor } from "slate";
import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import { slateDiff } from "../../editor/slate-diff-versions";

interface VersionHistoryContextInterface {
  handleGetVersionDetail: Function;
  UpdateVersionName: Function;
  RestoreDocumentVersion: Function;
  getCurrentVersion: Function;
  handleVersionById: Function;
  handleCloneVersionTemplate: Function;
  handleCloneVersionContract: Function;
  setVersionSelectedDetail: Function;
  versionSelectedDetail: any;
  zoom: any;
  setZoom: Function;
  onZoomClick: Function;

  getDiff: Function;
  setCurrentVersionValue: Function;
  setSelectedVersionValue: Function;
  setLoading: Function;
  editor: any;
  loading: boolean;
  currentVersionValue: any;
  selectedVersionValue: any;
  getVariables: Function;
}

const versionHistoryContext =
  React.createContext<VersionHistoryContextInterface | null>(null);

interface ListVersionProviderProps {
  children: ReactNode;
}

const VersionProvider = ({ children }: ListVersionProviderProps) => {
  const [versionSelectedDetail, setVersionSelectedDetail]: [any, Function] =
    useState();
  const [zoom, setZoom] = useState<any>(0.9);

  const handleGetVersionDetail = async (id: string, params: any) => {
    try {
      let response = await api.getDocumentVersion(id, params);
      return response;
    } catch (error) {}
  };

  const UpdateVersionName = async (
    documentId: string,
    versionId: string,
    params: any
  ) => {
    try {
      let response = await api.updateDocumentName(
        documentId,
        versionId,
        params
      );
      return response;
    } catch (error) {}
  };

  const RestoreDocumentVersion = async (
    documentId: string,
    versionId: string
  ) => {
    try {
      let response = await api.restoreDocumentVersion(documentId, versionId);
      return response;
    } catch (error) {}
  };

  const getCurrentVersion = async (documentId: string) => {
    try {
      let response = await api.currentVersion(documentId);

      return response;
    } catch (error) {}
  };

  const handleVersionById = async (documentId: string, versionId: string) => {
    try {
      let response = await api.getVersionById(documentId, versionId);

      return response;
    } catch (error) {}
  };

  const handleCloneVersionTemplate = async (versionId: string) => {
    try {
      let response = await api.CreateNewVersionForTemplate(versionId);
      return response;
    } catch (error) {}
  };

  const handleCloneVersionContract = async (versionId: string) => {
    try {
      let response = await api.CreateNewVersionForContract(versionId);
      return response;
    } catch (error) {}
  };
  let textContent: any;
  useEffect(() => {
    textContent = document.getElementById("versionHistoryZoomContainer");
    // onZoomClick(0.1)
  }, []);

  const onZoomClick = (zoom: any) => {
    textContent.style.transform = `scale(${zoom})`;
  };

  // ............................version history editor diff.............................
  const [currentVersionValue, setCurrentVersionValue]: any = useState();
  const [selectedVersionValue, setSelectedVersionValue]: any = useState();
  const [loading, setLoading] = useState(true);

  const editor: any = useMemo(() => {
    const editor = withReact(withHistory(createEditor()));
    return editor;
  }, []);

  const printChanges = (item: any) => {
    if (item.type === "insert_text") {
      Transforms.select(editor, {
        anchor: { path: [item.path[0], item.path[1]], offset: item.offset },
        focus: { path: [item.path[0], item.path[1]], offset: item.offset },
      });
      Editor.addMark(editor, "bgColor", "#66b966");
      Editor.insertText(editor, item.text);
    } else if (item.type === "remove_text") {
      Transforms.select(editor, {
        anchor: { path: [item.path[0], item.path[1]], offset: item.offset },
        focus: { path: [item.path[0], item.path[1]], offset: 15 },
      });
      Editor.addMark(editor, "bgColor", "#fc8b8b");
      Editor.addMark(editor, "strikeThrough", true);
      Editor.insertText(editor, item.text);
    } else if (item.type === "remove_node") {
      Transforms.insertNodes(editor, item.node, {
        at: { path: [item.path[0], 0], offset: 0 },
      });

      Transforms.select(editor, {
        anchor: { path: [item.path[0], 0], offset: 0 },
        focus: { path: [item.path[0], 0], offset: 1000 },
      });
      Editor.addMark(editor, "bgColor", "#fc8b8b");
    } else if (item.type === "insert_node") {
      Transforms.insertNodes(editor, item.node, {
        at: { path: [item.path[0], 1], offset: 0 },
      });

      Transforms.select(editor, {
        anchor: { path: [item.path[0], 0], offset: 0 },
        focus: { path: [item.path[0], 0], offset: 1000 },
      });
      Editor.addMark(editor, "bgColor", "#66b966");
    }
  };

  const getDiff = (selectedValue?: any, currentValue?: any) => {
    if (selectedValue && currentValue) {
      setTimeout(() => {
        let diff: any = slateDiff(selectedValue, currentValue);
        diff.map((item: any) => printChanges(item));
      }, 200);
    }
  };

  const getVariables = async (documentId: string) => {
    try {
      let response = await api.getDocumentVariables(documentId, {
        page: 1,
        limit: 250,
        sort: "-createdAt",
      });
      return response;
    } catch (error) {}
  };

  const value: VersionHistoryContextInterface = useMemo(
    () => ({
      handleGetVersionDetail,
      UpdateVersionName,
      RestoreDocumentVersion,
      getCurrentVersion,
      handleVersionById,
      handleCloneVersionTemplate,
      handleCloneVersionContract,
      setVersionSelectedDetail,
      versionSelectedDetail,
      zoom,
      setZoom,
      onZoomClick,

      getDiff,
      setSelectedVersionValue,
      setCurrentVersionValue,
      setLoading,
      editor,
      loading,
      currentVersionValue,
      selectedVersionValue,
      getVariables,
    }),
    []
  );

  return (
    <versionHistoryContext.Provider value={value}>
      {children}
    </versionHistoryContext.Provider>
  );
};

const useVersionHistory = () => {
  return React.useContext(versionHistoryContext);
};

export { VersionProvider, useVersionHistory };
