import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PDFViewerContract from "../../../components/PDFViewerContract";
import { EditorProvider } from "../../../editor/EditorProvider";
import { StatusEnum } from "../../../interfaces/StatusEnum";
import { Collaborator } from "../../../lib/Collaborator";
import { routes } from "../../../utils/routes";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { ContractContextInterface } from "../../Contracts/ContractProvider";
import VersionHistory from "../../VersionHistory/VersionHistoryOverview";
import Approval from "../../WorkFlow/Workflow";
import { TemplateContextInterface } from "../TemplateProvider";
import ActivityFeed from "./ActivityFeed";
import Overview from "./Overview";
import Variables from "./Variables";
import Hierarchy from "./hierarchy/Hierarchy";

const OverviewNestedBar = ({
  isActiveOverView,
  startWorkFlow,
  setStartWorkflow,
  documentId,
  context,
  setShowSuccessToastWorkFlow,
  setWorkFlowBodyMessage,
  setWorkFlowShowFailureToast,
  setIsWorkflowListAdded,
  startFromBegnning,
  resumeBtnClick,
  setResumeWorkflowIsActive,
  setRejectStatus,
  startTemplateWorkflow,
  editWorkflow,
  setEditWorkflowStarted,
  SetEditWorkflow,
  setIsCounterpartySigner,
}: {
  isActiveOverView: string;
  startWorkFlow: boolean;
  setStartWorkflow: Function;
  documentId: string;
  setShowSuccessToastWorkFlow: Function;
  setWorkFlowBodyMessage: Function;
  setWorkFlowShowFailureToast: Function;
  context: TemplateContextInterface | ContractContextInterface;
  setIsWorkflowListAdded: Function;
  startFromBegnning?: boolean;
  resumeBtnClick?: boolean;
  setResumeWorkflowIsActive: Function;
  setRejectStatus: Function;
  startTemplateWorkflow: boolean;
  editWorkflow: boolean;
  setEditWorkflowStarted: Function;
  SetEditWorkflow: Function;
  setIsCounterpartySigner: Function;
}) => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthentication();
  const [isVersionOwner, setIsVersionOwner]: [any, Function] = useState();
  const [rolesRead, setRolesRead]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesRead(guestRole?.WORKFLOWS?.find((el: any) => el === "READ"));
    }
  }, [authContext?.rolesAndAccess]);

  useEffect(() => {
    if (context?.data?.collaborators) {
      let versionOwner = context?.data?.collaborators?.find(
        (owner: Collaborator) => {
          return owner?.user?.sub === authContext?.currentUser?.sub;
        }
      );
      setIsVersionOwner(versionOwner);
    }
  }, [context?.data]);

  return (
    <div
      style={
        !isMobile
          ? { background: "#FEFEFE", minHeight: "80vh", overflow: "auto" }
          : { background: "#FEFEFE" }
      }
      className={isDesktop ? " mt-4 rounded-1 " : " mt-4 p-0"}
    >
      <div
        className={`${
          isMobile ? "container-fluid p-0 " : "container-fluid p-0"
        }`}
      >
        <div
          className={`${
            isMobile
              ? " overflow-scroll  px-4 pt-4 pb-2 nestedtab"
              : " overflow-scroll  px-4 pt-4 pb-2 nestedtab"
          }`}
        >
          <ul
            className="d-flex   list-unstyled"
            style={{ width: "fit-content" }}
          >
            <li
              className={
                isActiveOverView === "templates"
                  ? !!matchPath(
                      location.pathname,
                      generatePath(routes.overview, {
                        id: urlParams.id,
                      })
                    )
                    ? authContext?.publichClick === false
                      ? "edit_link_routes me-4 active"
                      : "edit_link_routes me-4 "
                    : "edit_link_routes me-4 "
                  : !!matchPath(
                      location.pathname,
                      generatePath(routes.overviewContracts, {
                        id: urlParams.id,
                      })
                    )
                  ? "edit_link_routes me-4 active"
                  : "edit_link_routes me-4 "
              }
              onClick={() =>
                isActiveOverView === "templates"
                  ? navigate(
                      generatePath(routes.overview, {
                        id: urlParams.id,
                      })
                    )
                  : navigate(
                      generatePath(routes.overviewContracts, {
                        id: urlParams.id,
                      })
                    )
              }
            >
              Overview
            </li>
            <li
              className={
                isActiveOverView === "templates"
                  ? !!matchPath(
                      location.pathname,
                      generatePath(routes.variables, {
                        id: urlParams.id,
                      })
                    )
                    ? "edit_link_routes me-4 active"
                    : "edit_link_routes me-4 "
                  : !!matchPath(
                      location.pathname,
                      generatePath(routes.variablesContracts, {
                        id: urlParams.id,
                      })
                    )
                  ? "edit_link_routes me-4 active"
                  : context?.data?.status === "DRAFT"
                  ? "edit_link_routes me-4 "
                  : " me-4"
              }
              onClick={() =>
                isActiveOverView === "templates"
                  ? navigate(
                      generatePath(routes.variables, {
                        id: urlParams.id,
                      })
                    )
                  : context?.data?.status === "DRAFT"
                  ? navigate(
                      generatePath(routes.variablesContracts, {
                        id: urlParams.id,
                      })
                    )
                  : ""
              }
            >
              Variables
            </li>
            <li
              className={
                isActiveOverView === "templates"
                  ? context?.data?.status === StatusEnum?.published &&
                    !context?.data?.approvers?.length
                    ? "me-4"
                    : !!matchPath(
                        location.pathname,
                        generatePath(routes.workflow, {
                          id: urlParams.id,
                        })
                      )
                    ? "edit_link_routes me-4 active"
                    : "edit_link_routes me-4 "
                  : !!matchPath(
                      location.pathname,
                      generatePath(routes.workflowContracts, {
                        id: urlParams.id,
                      })
                    ) && context?.data?.status !== StatusEnum?.draft
                  ? "edit_link_routes me-4 active"
                  : context?.data?.status === StatusEnum?.draft
                  ? "me-4 "
                  : rolesRead === "READ"
                  ? "edit_link_routes me-4"
                  : "me-4"
              }
              onClick={() =>
                isActiveOverView === "templates"
                  ? context?.data?.status === StatusEnum?.published &&
                    !context?.data?.approvers
                    ? ""
                    : navigate(
                        generatePath(routes.workflow, {
                          id: urlParams.id,
                        })
                      )
                  : context?.data?.status !== StatusEnum?.draft &&
                    rolesRead === "READ" &&
                    navigate(
                      routes?.workflowContractInfo(urlParams.id, {
                        contractInfo: true,
                      })
                    )
              }
            >
              Workflow
            </li>

            <li
              className={
                isActiveOverView === "templates"
                  ? !!matchPath(
                      location.pathname,
                      generatePath(routes.activityFeed, {
                        id: urlParams.id,
                      })
                    )
                    ? "edit_link_routes me-4 active"
                    : "edit_link_routes me-4 "
                  : !!matchPath(
                      location.pathname,
                      generatePath(routes.activityFeedContracts, {
                        id: urlParams.id,
                      })
                    )
                  ? "edit_link_routes me-4 active"
                  : "edit_link_routes me-4 "
              }
              onClick={() =>
                isActiveOverView === "templates"
                  ? navigate(
                      generatePath(routes.activityFeed, {
                        id: urlParams.id,
                      })
                    )
                  : navigate(
                      generatePath(routes.activityFeedContracts, {
                        id: urlParams.id,
                      })
                    )
              }
              style={{ minWidth: "63px" }}
            >
              Audit trail
            </li>

            <li
              className={
                isActiveOverView === "templates"
                  ? context?.data?.createdBy?.sub ===
                      authContext?.currentUser?.sub &&
                    (isVersionOwner?.access === "EDITOR" ||
                      isVersionOwner?.access === "OWNER") &&
                    !!matchPath(
                      location.pathname,
                      generatePath(routes.versionHistory, {
                        id: urlParams.id,
                      })
                    )
                    ? "edit_link_routes me-4 active"
                    : "edit_link_routes me-4 "
                  : isVersionOwner?.access !== "VIEWER" &&
                    (context?.data?.createdBy?.sub ===
                      authContext?.currentUser?.sub ||
                      isVersionOwner?.access === "EDITOR" ||
                      isVersionOwner?.access === "OWNER") &&
                    !!matchPath(
                      location.pathname,
                      generatePath(routes.versionHistoryContracts, {
                        id: urlParams.id,
                      })
                    )
                  ? "edit_link_routes me-4 active"
                  : isVersionOwner?.access !== "VIEWER" &&
                    (context?.data?.createdBy?.sub ===
                      authContext?.currentUser?.sub ||
                      isVersionOwner?.access === "EDITOR" ||
                      isVersionOwner?.access === "OWNER")
                  ? "edit_link_routes me-4"
                  : "me-4"
              }
              style={{ minWidth: "100px" }}
            >
              <span
                onClick={() =>
                  isActiveOverView === "templates"
                    ? context?.data?.createdBy?.sub ===
                        authContext?.currentUser?.sub &&
                      (isVersionOwner?.access === "EDITOR" ||
                        isVersionOwner?.access === "OWNER")
                      ? navigate(
                          generatePath(routes.versionHistory, {
                            id: urlParams.id,
                          })
                        )
                      : ""
                    : isVersionOwner?.access !== "VIEWER" &&
                      (context?.data?.createdBy?.sub ===
                        authContext?.currentUser?.sub ||
                        isVersionOwner?.access === "EDITOR" ||
                        isVersionOwner?.access === "OWNER")
                    ? navigate(
                        generatePath(routes.versionHistoryContracts, {
                          id: urlParams.id,
                        })
                      )
                    : ""
                }
              >
                Version history
              </span>
            </li>
            {isActiveOverView !== "templates" && (
              <>
                <li
                  className={
                    isActiveOverView === "templates"
                      ? !!matchPath(
                          location.pathname,
                          generatePath(routes.hierarchy, {
                            id: urlParams.id,
                          })
                        )
                        ? "edit_link_routes me-4 active"
                        : "edit_link_routes me-4 "
                      : !!matchPath(
                          location.pathname,
                          generatePath(routes.hierarchyContracts, {
                            id: urlParams.id,
                          })
                        )
                      ? "edit_link_routes me-4 active"
                      : "edit_link_routes me-4 "
                  }
                  onClick={() =>
                    isActiveOverView === "templates"
                      ? navigate(
                          generatePath(routes.hierarchy, {
                            id: urlParams.id,
                          })
                        )
                      : navigate(
                          generatePath(routes.hierarchyContracts, {
                            id: urlParams.id,
                          })
                        )
                  }
                  style={{ minWidth: "65px" }}
                >
                  Hierarchy
                </li>
              </>
            )}
          </ul>
        </div>
        <div className=" d-flex flex-grow-1">
          <div
            className={
              urlParams.module === "overview"
                ? "  active-content p-2 p-md-4 d-flex flex-grow-1 flex-column overflowYScroll container  "
                : "content p-4 container  "
            }
            style={{ height: "77vh" }}
          >
            {context &&
            !context.isTemplate &&
            [
              StatusEnum?.published,
              StatusEnum?.pending_approve,
              StatusEnum?.sign_initiated,
              "COMPLETED",
              StatusEnum?.stamped,
              StatusEnum?.stamp_initiated,
              StatusEnum?.pending_negotitation,
              StatusEnum?.rejected,
              "PENDING",
              StatusEnum?.approved,
            ].includes(context?.data?.status || "") &&
            context?.data?.filePath ? (
              <PDFViewerContract file={context?.data?.filePath} />
            ) : (
              <EditorProvider documentId={documentId} readOnly={true}>
                <Overview />
              </EditorProvider>
            )}
          </div>
          <div
            className={
              urlParams.module === "variables"
                ? "  active-content p-2 p-md-4  d-flex flex-grow-1 flex-column overflowYScroll container   "
                : "content p-4 container  "
            }
            style={{ height: "77vh" }}
          >
            <Variables
              documentId={documentId}
              isTemplate={isActiveOverView === "templates"}
            />
          </div>
          <div
            className={
              urlParams.module === "workflow"
                ? "  active-content p-4 d-flex flex-grow-1 flex-column overflowYScroll container  "
                : "content p-4 container  "
            }
            style={{ height: "77vh" }}
          >
            <Approval
              isActiveOverView={isActiveOverView}
              startWorkFlow={startWorkFlow}
              setStartWorkflow={setStartWorkflow}
              setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
              setWorkFlowBodyMessage={setWorkFlowBodyMessage}
              setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
              setIsWorkflowListAdded={setIsWorkflowListAdded}
              resumeBtnClick={resumeBtnClick}
              startFromBegnning={startFromBegnning}
              setResumeWorkflowIsActive={setResumeWorkflowIsActive}
              setRejectStatus={setRejectStatus}
              startTemplateWorkflow={startTemplateWorkflow}
              editWorkflow={editWorkflow}
              setEditWorkflowStarted={setEditWorkflowStarted}
              SetEditWorkflow={SetEditWorkflow}
              setIsCounterpartySigner={setIsCounterpartySigner}
            />
          </div>
          <div
            className={
              urlParams.module === "versions"
                ? "  active-content p-sm-4 d-flex flex-grow-1 flex-column overflowYScroll container  "
                : "content p-4 container  "
            }
          >
            <VersionHistory context={context} />
          </div>
          <div
            className={
              urlParams.module === "activities"
                ? "  active-content p-4 d-flex flex-grow-1 flex-column overflowYScroll container  "
                : "content p-4 container  "
            }
            style={{ height: "77vh" }}
          >
            <ActivityFeed />
          </div>
          <div
            className={
              urlParams.module === "hierarchy"
                ? "  active-content p-4 d-flex flex-grow-1 flex-column overflowYScroll container  "
                : "content p-4 container  "
            }
            style={{ height: "77vh" }}
          >
            <Hierarchy />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverviewNestedBar;
