import { ICollaboratorResponse } from "../interfaces/response/ICollaboratorResponse";
import { ITemplateResponse } from "../interfaces/response/ITemplateResponse";
import BaseDocument from "./BaseDocument";
import { Collaborator } from "./Collaborator";
import { User } from "./User";
export class Template extends BaseDocument {
  approvers: [];

  constructor(params: ITemplateResponse) {
    super();
    const {
      id,
      name,
      description,
      is_deleted,
      document,
      status,
      account_id,
      category,
      createdAt,
      updatedAt,
      created_by,
      search,
      collaborators,
      last_edited_on,
      department,
      approvers,
      workflow_status,
      is_optional,
    } = params;

    this.id = id;
    this.name = name;
    this.description = description;
    this.isDeleted = is_deleted;
    this.documentId = document;
    this.department = department;
    this.category = category;
    this.search = search;
    this.is_optional = is_optional;
    this.approvers = approvers;
    this.workflow_status = workflow_status;
    this.collaborators =
      collaborators?.map(
        (params: ICollaboratorResponse) => new Collaborator(params)
      ) || [];
    this.status = status;
    this.accountId = account_id;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt) || "";
    this.createdBy = new User(created_by);
    this.lastEditedOn = new Date(last_edited_on);
  }
}
