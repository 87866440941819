import { ICollaboratorResponse } from "../interfaces/response/ICollaboratorResponse";
import { IContractResponse } from "../interfaces/response/IContractResponse";
import BaseDocument from "./BaseDocument";
import { Collaborator } from "./Collaborator";
import { User } from "./User";
export class Contract extends BaseDocument {
  internalSingers: [];
  approvers: [];
  externalUser: [];
  counterparty_owner: [];
  signatory: [];
  constructor(params: IContractResponse) {
    super();

    const {
      id,
      name,
      description,
      is_deleted,
      document_id,
      status,
      account_id,
      category,
      createdAt,
      updatedAt,
      created_by,
      search,
      collaborators,
      file_path,
      internal_signer,
      approvers,
      workflow_status,
      external_users,
      counterparty_owner,
      signatory,
      user,
      department,
      expiry_date,
      stamp_required,
      is_stamped,
      sign_type,
      pages,
      contract_value,
      start_date,
      end_date,
      updated_by,
      currency_type,
      last_edited_on,
      last_sync_at,
      status_reason,
      negotiation_owners,
      relationship,
      owner,
    } = params;
    this.id = id;
    this.pages = pages;
    this.last_edited_on = last_edited_on;
    this.currency_type = currency_type;
    this.start_date = start_date;
    this.end_date = end_date;
    this.updated_by = updated_by;
    this.contract_value = contract_value;
    this.name = name;
    this.sign_type = sign_type;
    this.description = description;
    this.isDeleted = is_deleted;
    this.documentId = document_id;
    this.category = category;
    this.expiry_date = expiry_date;
    this.stamp_required = stamp_required;
    this.is_stamped = is_stamped;
    this.search = search;
    this.last_sync_at = last_sync_at;
    this.status_reason = status_reason;
    this.negotiation_owners = negotiation_owners;
    this.relationship = relationship;
    this.collaborators =
      collaborators?.map(
        (params: ICollaboratorResponse) => new Collaborator(params)
      ) || [];
    this.status = status;
    this.accountId = account_id;
    this.user = user;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
    this.createdBy = new User(created_by);
    this.filePath = file_path;
    this.internalSingers = internal_signer;
    this.approvers = approvers;
    this.workflow_status = workflow_status;
    this.externalUser = external_users;
    this.counterparty_owner = counterparty_owner;
    this.signatory = signatory;
    this.department = department;
    this.owner = owner;
  }
}
