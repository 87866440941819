import { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { isMobileOnly, isTablet } from "react-device-detect";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { Template } from "../../../lib/Template";
import DocumentFilter from "../../../ui/DocumentFilter";
import DocumentSortBy from "../../../ui/DocumentSortBy";
import { routes } from "../../../utils/routes";
import {
  ListTemplateContextInterface,
  useTemplates,
} from "../ListTemplatesProvider";
import MobileTemplateFooter from "./MobileTemplateFooter";
import { AiOutlineSearch } from "react-icons/ai";
import SuccessToast from "../../../ui/SuccessToast";
import { MdCancel, MdCheckCircle, MdOutlineFileUpload } from "react-icons/md";
import FailureToast from "../../../ui/FailureToast";
import FileUpload from "../../Contracts/Components/FileUpload";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

const initialValue = { name: "Untitled Document", description: "" };

function TemplateHeader({ setMoveToFolders }: { setMoveToFolders: Function }) {
  const navigate = useNavigate();
  const context = useTemplates();
  const location = useLocation();
  const authContext = useAuthentication();
  // state
  const [loader, setLoader]: [boolean, Function] = useState(false);
  // const [searchText, setSearchText]: [string, Function] = useState("");
  const [isFolderActive, setIsFolderActive]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [uploadFile, setUploadFile]: [boolean, Function] = useState(false);
  const [roles, setRoles]: [any, Function] = useState();

  const createDocuments = async () => {
    try {
      setLoader(true);
      const template: Template | null | undefined =
        await context?.createDocuments(initialValue);
      if (template) {
        navigate(generatePath(routes.editTemplate, { id: template?.id }));
        setLoader(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    let role: any =
      authContext?.currentUser?.roles?.[0] === "Admin"
        ? "ADMIN"
        : authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.TEMPLATES?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  const UploadContract = async (file: any) => {
    setUploadFile(true);

    const formData = new FormData();
    formData.set("file", file);
    try {
      let response = await context?.handleUploadTemplate(formData);

      if (response?.status === 201) {
        setBodyMessage("Template uploaded successfully! ");
        setShowSuccessToast(true);
        setUploadFile(false);
        navigate(
          generatePath(routes.editTemplate, {
            id: response?.data?.id,
          })
        );

        // navigate(generatePath(routes.overview, { id: response?.data?.id }));
      } else {
        setBodyMessage(response?.data?.error[0]);
        setShowFailureToast(true);
        setUploadFile(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location.pathname.includes("folder")) {
      setIsFolderActive(true);
    }
  }, []);
  return (
    context && (
      <>
        {isTablet && (
          <TabletContractHeader
            context={context}
            createDocuments={createDocuments}
            loader={loader}
            roles={roles}
            // onSearchClick={onSearchClick}
            // setSearchText={setSearchText}
            // searchText={searchText}
            isFolderActive={isFolderActive}
            UploadContract={UploadContract}
          />
        )}
        {isMobileOnly && (
          <MobileContractHeader
            createDocuments={createDocuments}
            loader={loader}
            context={context}
            roles={roles}
            // onSearchClick={onSearchClick}
            // setSearchText={setSearchText}
            // searchText={searchText}
            isFolderActive={isFolderActive}
            UploadContract={UploadContract}
          />
        )}
        {!isTablet && !isMobileOnly && (
          <DesktopContractHeader
            roles={roles}
            // setSearchText={setSearchText}
            context={context}
            // searchText={searchText}
            createDocuments={createDocuments}
            loader={loader}
            // onSearchClick={onSearchClick}
            setMoveToFolders={setMoveToFolders}
            UploadContract={UploadContract}
          />
        )}
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            message={bodyMessage}
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
          />
        )}
        {uploadFile && (
          <FileUpload show={uploadFile} onHide={() => setUploadFile(false)} />
        )}
      </>
    )
  );
}

export default TemplateHeader;

const TabletContractHeader = ({
  context,
  createDocuments,
  loader,
  roles,
  // onSearchClick,
  // setSearchText,
  // searchText,
  isFolderActive,
  UploadContract,
}: {
  context: ListTemplateContextInterface;
  createDocuments: Function;
  loader: boolean;
  roles: any;
  // onSearchClick: Function;
  // setSearchText: Function;
  // searchText: string;
  isFolderActive: boolean;
  UploadContract: Function;
}) => {
  const navigate = useNavigate();
  const onSearchSubmit = async (searchText: any) => {
    // if (e.keyCode === 13) {
    navigate(routes?.templateFiltersUrl({ search: searchText }));
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
    // }
  };
  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="p-2 ">
          <DocumentSortBy context={context} enumType={"TemplateSortEnum"} />
        </div>
        <div className="pt-2">
          <DocumentFilter
            params={context.filterParams}
            changeFilter={context.changeFilter}
            resetFilter={context.resetFilter}
            categoriesAutocomplete={context.categoriesAutocomplete}
            defaultCategories={context.defaultCategories}
            context={context}
            status={true}
          />
        </div>
        {!isFolderActive && (
          <>
            {roles === "CREATE" && (
              <>
                {" "}
                <div className="p-2">
                  <Dropdown className="upload-template-css">
                    <Dropdown.Toggle
                      className="filter-css ms-lg-3  position-relative overflow-hidden"
                      id="dropdown-basic"
                    >
                      <input
                        type="file"
                        className=" position-absolute uploadContractFile"
                        onChange={(e) => UploadContractFile(e)}
                      />
                      Upload Template
                      <span className=" ps-2">
                        <MdOutlineFileUpload fontSize={18} />
                      </span>
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Dropdown>
                <Dropdown.Toggle
                  className="filter-css ms-lg-3"
                  id="dropdown-basic"
                  disabled
                >
                  Upload Template
                </Dropdown.Toggle>
              </Dropdown> */}
                </div>
              </>
            )}

            <div className="p-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className=" create-template contract-css ms-lg-3"
                  onClick={() => {
                    createDocuments();
                  }}
                  id="dropdown-basic"
                >
                  Create Template
                  {loader ? <span className="loader"></span> : ""}
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </>
        )}
      </div>
      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              // onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Templates"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
              // onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
    </>
  );
};

const MobileContractHeader = ({
  createDocuments,
  loader,
  context,
  roles,
  // onSearchClick,
  // setSearchText,
  // searchText,
  isFolderActive,
  UploadContract,
}: {
  createDocuments: Function;
  loader: boolean;
  context: ListTemplateContextInterface;
  roles: any;

  // onSearchClick: Function;
  // setSearchText: Function;
  // searchText: string;
  isFolderActive: boolean;
  UploadContract: Function;
}) => {
  const navigate = useNavigate();
  const onSearchSubmit = async (searchText: any) => {
    // if (e.keyCode === 13) {
    navigate(routes?.templateFiltersUrl({ search: searchText }));
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
    // }
  };
  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  return (
    <>
      {!isFolderActive && (
        <>
          {roles === "CREATE" && (
            <>
              {" "}
              <div className="d-flex justify-content-between">
                <div className="p-2">
                  <Dropdown className="upload-template-css">
                    <Dropdown.Toggle
                      className="filter-css ms-lg-3  position-relative overflow-hidden"
                      id="dropdown-basic"
                    >
                      <input
                        type="file"
                        className=" position-absolute uploadContractFile"
                        onChange={(e) => UploadContractFile(e)}
                      />
                      Upload Template
                      <span className=" ps-2">
                        <MdOutlineFileUpload fontSize={18} />
                      </span>
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Dropdown>
                <Dropdown.Toggle
                  className="mobile-filter-css"
                  id="dropdown-basic"
                  disabled
                >
                  Upload Template
                </Dropdown.Toggle>
              </Dropdown> */}
                </div>
                <div className="p-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3"
                      onClick={() => {
                        createDocuments();
                      }}
                      id="dropdown-basic"
                    >
                      Create Template
                      {loader && <span className="loader"></span>}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              // onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Templates"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
              // onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
      <MobileTemplateFooter enumType={"TemplateSortEnum"} context={context} />
    </>
  );
};

const DesktopContractHeader = ({
  // setSearchText,
  context,
  // searchText,
  createDocuments,
  loader,
  roles,
  // onSearchClick,
  setMoveToFolders,
  UploadContract,
}: {
  // setSearchText: Function;
  context: ListTemplateContextInterface;
  // searchText: string;
  createDocuments: Function;
  roles: any;
  loader: boolean;
  // onSearchClick: Function;
  setMoveToFolders: Function;
  UploadContract: Function;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onSearchSubmit = async (searchText: any) => {
    // if (e.keyCode === 13) {
    navigate(routes?.templateFiltersUrl({ search: searchText }));
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
    // }
  };
  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  return (
    <>
      <div className="d-flex ">
        <div className="pt-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              // onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
              // onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
        <div className="pt-2">
          <DocumentSortBy context={context} enumType={"TemplateSortEnum"} />
        </div>
        <div className="pt-2">
          <DocumentFilter
            params={context.filterParams}
            changeFilter={context.changeFilter}
            // resetFilter={context.resetFilter}
            categoriesAutocomplete={context.categoriesAutocomplete}
            defaultCategories={context.defaultCategories}
            context={context}
            status={true}
          />
        </div>
        {!location.pathname.includes("folder") && (
          <>
            {roles === "CREATE" && (
              <>
                <div className="pt-2">
                  <Dropdown className="upload-template-css">
                    <Dropdown.Toggle
                      className="filter-css ms-lg-3  position-relative overflow-hidden"
                      id="dropdown-basic"
                    >
                      <input
                        type="file"
                        accept=".docx,.pdf"
                        className=" position-absolute uploadContractFile"
                        onChange={(e) => UploadContractFile(e)}
                      />
                      Upload Template
                      <span className=" ps-2">
                        <MdOutlineFileUpload fontSize={18} />
                      </span>
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Dropdown className="upload-template-css">
                <Dropdown.Toggle
                  className="filter-css ms-lg-3"
                  id="dropdown-basic"
                  disabled
                >
                  Upload Template
                </Dropdown.Toggle>
              </Dropdown> */}
                </div>
                <div className="pt-2 bd-highlight">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3"
                      onClick={() => {
                        createDocuments();
                      }}
                      id="dropdown-basic"
                    >
                      Create Template&nbsp;
                      {loader ? <span className="loader"></span> : ""}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </>
            )}
          </>
        )}
        {location.pathname.includes("folder") && (
          <>
            <div className="pt-2 bd-highlight">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="new-contact-btn create-template contract-css ms-lg-3"
                  id="dropdown-basic"
                  onClick={() => setMoveToFolders(true)}
                >
                  Move to folders&nbsp;
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </>
  );
};
