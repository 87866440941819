import { useEffect, useState } from "react";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import DeleteToast from "../../../ui/DeleteToast";
import SuccessToast from "../../../ui/SuccessToast";
import { routes } from "../../../utils/routes";
import { useContract } from "../../Contracts/ListContractProvider";
import { useTemplate } from "../TemplateProvider";
import OverviewNestedBar from "./TemplateNavBar";
import DocumentCollaborators from "../../../components/DocumentCollaborators";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { api } from "../../../utils/api";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import RejectPopup from "../../Contracts/RejectPopup";
import FailureToast from "../../../ui/FailureToast";
import { IApprovalResponse } from "../../../interfaces/response/IApprovalResponse";
import { BiDotsVertical } from "react-icons/bi";
import TransferOwnerShipPopup from "../../../ui/TransferOwnerShipPopup";
import { Collaborator } from "../../../lib/Collaborator";
import PublishDocuentPopup from "../../Contracts/Components/PublishDocuentPopup";
import { StatusEnum } from "../../../interfaces/StatusEnum";
import UpdateDocumentName from "../../../layouts/UpdateDocumentName";
import { FaArrowLeft } from "react-icons/fa";
import { ApproveButton } from "./ApproveButton";

const TemplateOverviewBody = ({
  isActiveOverView,
}: {
  isActiveOverView: string;
}) => {
  const context: any = useTemplate();

  const contextContract = useContract();
  const authContext = useAuthentication();
  const [contractDetail, setContractDetail] = useState<any>();
  const [startWorkFlow, setStartWorkflow]: [boolean, Function] =
    useState(false);
  const [startTemplateWorkflow, setStartTemplateWorkflow]: [boolean, Function] =
    useState(false);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [isEditorAccess, setIsEditorAccess]: [string, Function] = useState("");

  const [isLoggedInUser, setIsLoggedInUser]: [boolean, Function] =
    useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [showPublishToast, setShowPublishToast]: [boolean, Function] =
    useState(false);
  const [successToast, setSuccessToast]: [boolean, Function] = useState(false);
  const [bodyMessage, setBoddyMessage]: [string, Function] = useState("");
  const [isWorkflowListAdded, setIsWorkflowListAdded]: [boolean, Function] =
    useState(false);
  const [editWorkflow, setEditWorkflow]: [boolean, Function] = useState(false);
  const [editWorkflowStarted, setEditWorkflowStarted]: [boolean, Function] =
    useState(false);
  const [rejectApproval, setRejectApproval]: [boolean, Function] =
    useState(false);
  const [isApprovedActive, setIsApprovedACtive]: [string, Function] =
    useState("");
  const [bodyMessages, setWorkFlowBodyMessage]: [string, Function] =
    useState("");
  const [showSuccessToast, setShowSuccessToastWorkFlow]: [boolean, Function] =
    useState(false);
  const [showWorkflowFailureToast, setWorkFlowShowFailureToast]: [
    boolean,
    Function
  ] = useState(false);
  const [showStartworkflow, setShowStartworkflow]: [boolean, Function] =
    useState(false);
  const [publishPopup, setPublishPopup]: [boolean, Function] = useState(false);
  const [isActiveTransferOwnerShip, setIsActiveTransferOwnership]: [
    boolean,
    Function
  ] = useState(false);

  const [renamePopup, setRenamePopup]: [boolean, Function] = useState(false);

  const [isOwner, setIsOwner]: [any, Function] = useState();

  const [isApproved, setIsApproved]: [boolean, Function] = useState(false);

  const [startFromBegnning, setStartFRomBegnning]: [boolean, Function] =
    useState(false);
  const [resumeWorkflowIsActive, setResumeWorkflowIsActive]: [
    boolean,
    Function
  ] = useState(false);
  const [resumeBtnClick, setResumeBtnClick]: [boolean, Function] =
    useState(false);
  const [rejectStatus, setRejectStatus]: [boolean, Function] = useState(false);
  const [ownerDetail, setOwnerDetail]: [Collaborator | undefined, Function] =
    useState();

  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesUpdate(guestRole?.TEMPLATES?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.TEMPLATES?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  useEffect(() => {
    if (context?.template?.approvers) {
      let reject = context?.template?.approvers?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );

      if (reject?.length === 0) {
        setResumeWorkflowIsActive(true);
      } else {
        setResumeWorkflowIsActive(false);
      }
    }
  }, [context?.template]);

  const onPublishDocument = async () => {
    try {
      let requestBody = {
        status: StatusEnum?.published,
        name: context?.data?.name,
      };
      const response = await api.publishDocuments(id!, requestBody);
      if (response?.data?.success) {
        setSuccessToast(true);
        setShowPublishToast(false);
        setBoddyMessage("Document published.");
      }
    } catch (error) {}
  };

  const [commentsLength, setCommentsLength]: [number | undefined, Function] =
    useState();

  const handleComments = async () => {
    try {
      let response = await api.getComments(context?.data?.documentId!, {
        is_resolved: false,
      });
      if (response?.success) {
        setCommentsLength(response?.comments?.length);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleComments();
  }, [context?.data?.documentId]);

  function handleClick() {
    navigate(generatePath(routes.editTemplate, { id: id }));
  }

  const handleDelete = async () => {
    const res = await context?.handleDeleteTemplate(id);

    if (res.success) {
      context?.setShowSuccessToast(true);
      navigate(generatePath(routes.root));
    }
  };

  const currentUserDetail = async () => {
    try {
      if (authContext?.currentUser?.sub === context?.data?.createdBy?.id) {
        setIsLoggedInUser(true);
        setIsEditorAccess("EDITOR");
      }
      if (authContext?.currentUser?.sub !== context?.data?.createdBy?.id) {
        let x = context?.data?.collaborators?.filter((el: Collaborator) => {
          return el?.user?.id === authContext?.currentUser?.sub;
        });

        setIsEditorAccess(x?.[0]?.access);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (context) {
      currentUserDetail();
    }
  }, [context]);

  useEffect(() => {
    const recentContract = contextContract?.recentContracts?.filter(
      (contractId) => id == contractId?.id
    );
    setContractDetail(recentContract);
  }, [contextContract]);

  useEffect(() => {
    if (context?.template?.status === StatusEnum?.pending_approve) {
      setEditWorkflowStarted(true);
    }
    if (context?.template?.status === StatusEnum?.published) {
      setEditWorkflowStarted(false);
      setStartWorkflow(false);
    }
    if (context?.template?.status === "REJECTED") {
      setEditWorkflowStarted(true);
    }
  }, [context?.template]);

  useEffect(() => {
    if (context?.template?.collaborators) {
      let ownerDetail = context?.template?.collaborators?.filter(
        (owner: Collaborator) => {
          return owner?.user?.sub === authContext?.currentUser?.sub;
        }
      );
      setIsOwner(ownerDetail);
      setOwnerDetail(ownerDetail?.[0]);
    }
  }, [context?.template?.collaborators]);

  const [unPublishDocument, setUnPublishedDocument]: [boolean, Function] =
    useState(false);

  const onUnPublishedDocument = async () => {
    try {
      let requestBody = {
        status: StatusEnum?.draft,
        name: !!context?.data && context?.data?.name,
      };
      const response = await context?.publishDocument(id, requestBody);
      if (response?.data?.success) {
        context?.refresh();
        setUnPublishedDocument(false);
        setShowSuccessToastWorkFlow(true);
        setWorkFlowBodyMessage("Document unpublished.");
      }
      if (!response?.data?.success) {
        setWorkFlowShowFailureToast(true);
        setWorkFlowBodyMessage("Something went wrong!");
        setUnPublishedDocument(false);
      }
    } catch (error) {}
  };

  const [_roleAccess, setRoleAccess]: [boolean, Function] = useState(false);

  useEffect(() => {
    let owner: any = context?.template?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );

    if (owner?.user?.email === authContext?.currentUser?.email) {
      setRoleAccess(true);
    }
  }, [context?.template?.collaborators]);

  return (
    <>
      <div className=" container-fluid " style={{ marginTop: "60px" }}>
        <div className=" d-flex justify-content-between align-items-center pt-4">
          {context?.loading ? (
            "Loading"
          ) : (
            <div
              style={{ cursor: "default" }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={context?.template?.name}
            >
              <span>
                <FaArrowLeft
                  fontSize={15}
                  style={{ marginBottom: "-45px", cursor: "pointer" }}
                  onClick={() => navigate(routes.templates)}
                />
              </span>
              <h5 className="m-0 fw-bold overview_title px-4">
                {(isActiveOverView === "templates"
                  ? context?.loading
                    ? "Loading"
                    : context?.template.formattedName(61)
                  : contractDetail?.[0]?.name?.charAt(0).toUpperCase() +
                    contractDetail?.[0]?.name.slice(1)) || "Untitled Document"}
              </h5>

              <div className="text-dark small fw-lighter  LastEdited px-4">
                Last edited on&nbsp;
                {isActiveOverView === "templates" ? (
                  <>
                    {moment(context?.template?.updatedAt).format(
                      "DD MMMM YYYY, h:mm:ss A"
                    )}
                  </>
                ) : (
                  <>
                    {moment(contractDetail?.[0]?.updatedAt).format(
                      "DD MMMM YYYY, h:mm:ss A"
                    )}
                  </>
                )}
                &nbsp;by
                <span className=" ms-1 LastEdited">
                  {ownerDetail?.user?.name
                    ? ownerDetail?.user?.name
                    : context?.data?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.name}{" "}
                  {ownerDetail?.user?.name
                    ? ownerDetail?.user?.last_name
                    : context?.data?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.last_name}
                </span>
                &nbsp;&nbsp;
                {isLoggedInUser ? (
                  <Badge className="publish-badge" bg="success">
                    {ownerDetail?.access}
                  </Badge>
                ) : (
                  <>
                    <Badge className="publish-badge" bg="success">
                      {isEditorAccess
                        ? isEditorAccess
                        : context?.data?.collaborators?.find(
                            (el: any) => el?.access === "OWNER"
                          )?.access}
                    </Badge>
                  </>
                )}
              </div>
            </div>
          )}
          {show && (
            <DeleteToast
              onCross={() => {}}
              onSubmit={handleDelete}
              title="Are you sure you want to delete this ?"
              closeText="No"
              submitText="Yes"
              onClose={() => setShow(false)}
            />
          )}
          {showPublishToast && (
            <DeleteToast
              onCross={() => {}}
              onSubmit={() => {
                onPublishDocument();
              }}
              onClose={() => setShowPublishToast(false)}
              title={
                commentsLength !== 0
                  ? "There are unresolved comments are you sure you want to publish?"
                  : "Do you want to publish this document?"
              }
              closeText="No"
              submitText="Yes"
            />
          )}

          <div className={isMobile ? " d-none" : "d-flex align-items-center"}>
            {!window.location.pathname.includes("workflow") && (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="default"
                    className="new-contact-btn create-template contract-css ms-lg-3"
                    id="dropdown-basic"
                    style={{ border: "none" }}
                  >
                    <BiDotsVertical color="gray" fontSize={20} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="create-dropdown-css">
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() =>
                        isActiveOverView === "templates" &&
                        context?.handleTemplateDownload(context?.template.id)
                      }
                    >
                      Download
                    </Dropdown.Item>
                    {!window.location.pathname.includes("workflow") &&
                      ![
                        StatusEnum?.published,
                        StatusEnum?.pending_approve,
                        StatusEnum?.sign_initiated,
                        "COMPLETED",
                      ].includes(context?.data?.status || "") && (
                        <>
                          {isOwner?.[0]?.access === "OWNER" && (
                            <>
                              {context?.data?.status === StatusEnum?.draft && (
                                <>
                                  {rolesUpdate === "UPDATE" && (
                                    <>
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          context?.data?.department
                                            ? setShowPublishToast(true)
                                            : setPublishPopup(true);
                                        }}
                                      >
                                        Publish
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => setRenamePopup(true)}
                                      >
                                        Rename
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {renamePopup && (
                            <UpdateDocumentName
                              show={renamePopup}
                              onHide={() => setRenamePopup(false)}
                              context={context}
                              setDcumentName={() => {}}
                            />
                          )}

                          {context?.data?.status !==
                            StatusEnum?.stamp_initiated &&
                            context?.data?.status !==
                              StatusEnum?.stamp_failed && (
                              <>
                                {context?.data?.status !==
                                  StatusEnum?.stamped && (
                                  <>
                                    {rolesDelete === "DELETE" && (
                                      <>
                                        {isOwner?.[0]?.access === "OWNER" && (
                                          <Dropdown.Item
                                            className="sort-by"
                                            onClick={() =>
                                              setShowDeleteToast(true)
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                          {authContext?.currentUser?.sub ===
                            isOwner?.[0]?.user?.sub &&
                            isOwner?.[0]?.access === "OWNER" && (
                              <>
                                {rolesUpdate === "UPDATE" && (
                                  <>
                                    {" "}
                                    <Dropdown.Item
                                      className="sort-by"
                                      onClick={() =>
                                        setIsActiveTransferOwnership(true)
                                      }
                                    >
                                      Transfer ownership
                                    </Dropdown.Item>
                                  </>
                                )}
                              </>
                            )}
                        </>
                      )}

                    {isOwner?.[0]?.access === "OWNER" &&
                      (context?.data?.status === StatusEnum?.published ||
                        context?.data?.status === StatusEnum?.rejected) && (
                        <>
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              setUnPublishedDocument(true);
                            }}
                          >
                            Unpublish
                          </Dropdown.Item>
                        </>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            {!window.location.pathname.includes("workflow") &&
              ![
                StatusEnum?.published,
                StatusEnum?.pending_approve,
                StatusEnum?.sign_initiated,
                "COMPLETED",
              ].includes(context?.data?.status || "") && (
                <>
                  {isOwner?.[0]?.access === "OWNER" && (
                    <>
                      {(context || contextContract) && (
                        <>
                          {rolesUpdate === "UPDATE" && (
                            <>
                              <span style={{ cursor: "pointer" }}>
                                <DocumentCollaborators
                                  context={
                                    isActiveOverView === "templates"
                                      ? context
                                      : (contextContract as any)
                                  }
                                  outline
                                />
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {(window.location.pathname.includes("overview") ||
              window.location.pathname.includes("variables") ||
              window.location.pathname.includes("activities") ||
              window.location.pathname.includes("version")) && (
              <>
                {(isOwner?.[0]?.access === "OWNER" ||
                  isOwner?.[0]?.access === "EDITOR") &&
                  context?.data?.status === StatusEnum?.draft && (
                    <>
                      {rolesUpdate === "UPDATE" && (
                        <>
                          <Stack
                            direction="horizontal"
                            className="align-items-center"
                          >
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                              onClick={handleClick}
                            >
                              Go to editor
                            </Button>
                          </Stack>
                        </>
                      )}
                    </>
                  )}
              </>
            )}
            {unPublishDocument && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  onUnPublishedDocument();
                }}
                onClose={() => setUnPublishedDocument(false)}
                title="Do you want to unpublish this document?"
                closeText="No"
                submitText="Yes"
              />
            )}
            {isLoggedInUser &&
              window.location.pathname.includes("workflow") &&
              editWorkflowStarted && (
                <>
                  <Stack direction="horizontal" className="align-items-center">
                    <Button
                      size="sm"
                      disabled={editWorkflow}
                      className="me-3  px-3 py-2 fw-semibold d-flex text-align-center sharebtn "
                      onClick={() => setEditWorkflow(true)}
                    >
                      Edit Workflow
                    </Button>
                  </Stack>
                </>
              )}
            {context?.data.status === StatusEnum?.pending_approve &&
              !isApproved && (
                <ApproveButton
                  setRejectApproval={setRejectApproval}
                  setIsApprovedACtive={setIsApprovedACtive}
                />
              )}

            {successToast && (
              <SuccessToast
                icon={
                  <MdCheckCircle
                    className="text-success"
                    style={{ fontSize: "64px" }}
                  />
                }
                message={bodyMessage}
                title={"Success!"}
                show={successToast}
                close={() => {
                  setSuccessToast(false);
                  navigate(generatePath(routes.templates));
                }}
              />
            )}

            {isLoggedInUser &&
              ((context?.data?.approvers?.[0]?.status !== "REJECTED" &&
                !resumeWorkflowIsActive &&
                window.location.pathname.includes("workflow")) ||
                rejectStatus) && (
                <>
                  <Stack direction="horizontal" className="align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="me-3  px-3 fw-semibold d-flex text-align-center sharebtn"
                        id="dropdown-basic"
                      >
                        Reset Workflow
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="create-dropdown-css">
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => setResumeBtnClick(true)}
                        >
                          Resume
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            setStartFRomBegnning(true);
                          }}
                        >
                          From beginning
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Stack>
                </>
              )}
            {showStartworkflow && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  setStartWorkflow(true);
                  setStartTemplateWorkflow(true);
                  setTimeout(() => {
                    setShowStartworkflow(false);
                  }, 200);
                }}
                onClose={() => setShowStartworkflow(false)}
                title={
                  commentsLength !== 0
                    ? "There are unresolved comments are you sure you want to start the workflow?"
                    : "Do you want to publish this document?"
                }
                closeText="No"
                submitText="Yes"
              />
            )}
            {window.location.pathname.includes("workflow") &&
              isWorkflowListAdded &&
              context?.template?.workflow_status !== StatusEnum?.published && (
                <>
                  {isOwner?.[0]?.access === "OWNER" && (
                    <>
                      {!startWorkFlow && (
                        <>
                          <Stack
                            direction="horizontal"
                            className="align-items-center"
                          >
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                              onClick={() => {
                                setIsApproved(false);
                                commentsLength !== 0
                                  ? setShowStartworkflow(true)
                                  : setStartWorkflow(true);
                                setStartTemplateWorkflow(true);
                              }}
                            >
                              Start Workflow
                            </Button>
                          </Stack>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        <div
          className={
            isMobileOnly
              ? "d-flex align-items-center justify-content-end py-3"
              : "d-none"
          }
        >
          {(context || contextContract) && (
            <DocumentCollaborators
              context={
                isActiveOverView === "templates"
                  ? context
                  : (contextContract as any)
              }
              outline
            />
          )}
          <Stack direction="horizontal" className="align-items-center">
            <Button
              variant="success"
              size="sm"
              className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
            >
              Publish
            </Button>
          </Stack>
        </div>
        <div>
          {context?.template.documentId && (
            <OverviewNestedBar
              context={context}
              isActiveOverView={isActiveOverView}
              startWorkFlow={startWorkFlow}
              setStartWorkflow={setStartWorkflow}
              documentId={context?.template.documentId}
              setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
              setWorkFlowBodyMessage={() => {}}
              setWorkFlowShowFailureToast={() => {}}
              setIsWorkflowListAdded={setIsWorkflowListAdded}
              setResumeWorkflowIsActive={setResumeWorkflowIsActive}
              setRejectStatus={setRejectStatus}
              startTemplateWorkflow={startTemplateWorkflow}
              editWorkflow={editWorkflow}
              setEditWorkflowStarted={setEditWorkflowStarted}
              SetEditWorkflow={setEditWorkflow}
              startFromBegnning={startFromBegnning}
              resumeBtnClick={resumeBtnClick}
              setIsCounterpartySigner={() => {}}
            />
          )}
        </div>
      </div>
      {context?.showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="Successfully Deleted."
          title={"Success!"}
          show={context?.showSuccessToast}
          close={() => {
            context?.setShowSuccessToast(false);
            navigate(generatePath(routes.templates));
          }}
        />
      )}
      {showDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => isActiveOverView === "templates" && handleDelete()}
          onClose={() => setShowDeleteToast(false)}
          title="Do you want to delete the template?"
          closeText="No, don't !"
          submitText="Yes, delete it."
        />
      )}
      {rejectApproval && (
        <RejectPopup
          show={rejectApproval}
          onHide={() => setRejectApproval(false)}
          isApprovedActive={isApprovedActive}
          setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
          setWorkFlowBodyMessage={setWorkFlowBodyMessage}
          setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
          setIsApproved={setIsApproved}
          isTemplateACtive={true}
        />
      )}
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessages}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToastWorkFlow(false);
          }}
        />
      )}
      {showWorkflowFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showWorkflowFailureToast}
          close={() => {
            setWorkFlowShowFailureToast(false);
          }}
        />
      )}
      {isActiveTransferOwnerShip && (
        <TransferOwnerShipPopup
          show={isActiveTransferOwnerShip}
          onHide={() => setIsActiveTransferOwnership(false)}
          context={context}
          setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
          setWorkFlowBodyMessage={setWorkFlowBodyMessage}
          setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
        />
      )}
      {publishPopup && (
        <PublishDocuentPopup
          show={publishPopup}
          onHide={() => setPublishPopup(false)}
          context={context}
          setExpiryDate={() => {}}
          expiryDate={""}
          setShowPublishToast={setShowPublishToast}
        />
      )}
    </>
  );
};

export default TemplateOverviewBody;
