import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";

function ContractSignedByEachTeam({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [graphData, setGraphData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();

  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  // const getMatrics = async () => {
  //   try {
  //     let response = await context?.getMatrics();

  //     if (response?.success) {
  //       setUpdatedMatricsList(response?.metrics);
  //     }
  //     return response;
  //   } catch (error) {}
  // };

  const handleSignedCountContractByTeamsData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleSignedCountContractByTeams(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[2] = true;
        setIsLoadingData([...isLoadingData]);

        if (response?.data?.length > 0) {
          setGraphData(response?.data);

          // let res = await context?.getMatrics();
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );
          setGraphType(selectedGraphType?.type);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleSignedCountContractByTeamsData();
    setUpdatedMatricsList(updatedValue);
    // getMatrics();
  }, [isUpdate, updatedValue]);

  return (
    <>
      <div style={{ height: "390px" }}>
        {loader ? (
          <span>loading...</span>
        ) : (
          <>
            {updatedMatricsList?.length === 0 ? (
              <>
                {noData ? (
                  <AnalyticsNoDataFound />
                ) : (
                  <>
                    {graphType === "Horizontal Bar" ? (
                      <HorizontailBarChartData data={graphData} />
                    ) : graphType === "Pie" ? (
                      <PirChart data={graphData} />
                    ) : graphType === "Donut" ? (
                      <DonutChart data={graphData} />
                    ) : graphType === "Vertical Bar" ? (
                      <VerticalBarChartData data={graphData} />
                    ) : (
                      <DonutChart data={graphData} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {updatedMatricsList?.filter(
                  (item: any) =>
                    item.name === title && item?.is_enabled === true
                )?.length === 0 ? (
                  <AnalyticsNoGraph />
                ) : (
                  <>
                    {noData ? (
                      <AnalyticsNoDataFound />
                    ) : (
                      <>
                        {graphType === "Horizontal Bar" ? (
                          <HorizontailBarChartData data={graphData} />
                        ) : graphType === "Pie" ? (
                          <PirChart data={graphData} />
                        ) : graphType === "Donut" ? (
                          <DonutChart data={graphData} />
                        ) : graphType === "Vertical Bar" ? (
                          <VerticalBarChartData data={graphData} />
                        ) : (
                          <DonutChart data={graphData} />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ContractSignedByEachTeam;

const HorizontailBarChartData = ({ data }: { data: [] }) => {
  const CustomTooltip = ({ value }: any) => (
    <div style={{ padding: "12px", background: "#f0f0f0" }}>
      <strong> Total count: {value}</strong>
    </div>
  );
  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };
  const dataWithRandomColors = data.map((item: any) => ({
    department:
      item?.department?.charAt(0)?.toUpperCase() +
      item?.department?.slice(1).toLowerCase(),
    total_count: item?.count,
    color: stringToHexColor(item?.department),
  }));

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={dataWithRandomColors}
          keys={["total_count"]}
          indexBy="department"
          margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
          padding={0.5}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Total count",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          groupMode="grouped"
          layout="horizontal"
          enableGridY={false}
          enableGridX={true}
          colors={{ datum: "data.color" }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: "Department",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          theme={{
            axis: {
              legend: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
          tooltip={CustomTooltip}
        />
      </div>
    </>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  const CustomTooltip = ({ value }: any) => (
    <div style={{ padding: "12px", background: "#f0f0f0" }}>
      <strong> Total count: {value}</strong>
    </div>
  );

  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const dataWithRandomColors = data.map((item: any) => ({
    department:
      item?.department?.charAt(0)?.toUpperCase() +
      item?.department?.slice(1).toLowerCase(),
    total_count: item.count,
    color: stringToHexColor(item.department),
  }));
  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={dataWithRandomColors}
          keys={["total_count"]}
          indexBy="department"
          margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
          padding={0.5}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Department",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          groupMode="grouped"
          enableGridY={false}
          enableGridX={true}
          colors={{ datum: "data.color" }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Toatl count",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          theme={{
            axis: {
              legend: {
                text: {
                  fontSize: 12,
                },
              },
            },
          }}
          tooltip={CustomTooltip}
        />
      </div>
    </>
  );
};

const PirChart = ({ data }: { data: [] }) => {
  const updatedData: any = data.map((item: any) => ({
    id:
      item?.department?.charAt(0)?.toUpperCase() +
      item?.department?.slice(1).toLowerCase(),
    color: getRandomColor(),
    value: item.count,
  }));

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={updatedData}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                background: "#fafafa",
              }}
            >
              <strong>Department:{datum.data.id}</strong>
              <br />
              <strong>Total count: {datum.data.value}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: [] }) => {
  const updatedData: any = data.map((item: any) => ({
    id:
      item?.department?.charAt(0)?.toUpperCase() +
      item?.department?.slice(1).toLowerCase(),
    color: getRandomColor(),
    value: item.count,
  }));

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={updatedData}
          innerRadius={0.6}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                background: "#fafafa",
              }}
            >
              <strong>Department:{datum.data.id}</strong>
              <br />
              <strong>Total count: {datum.data.value}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};
