import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";

function DaysInNegotiation({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [noData, setNoData]: [string, Function] = useState("");
  const [graphData, setGraphData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const handleNegotiationData = async () => {
    setLoader(true);
    try {
      let response = await context?.handlegetDaysInNegotiation(
        filterData ? filterData : ""
      );
      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[0] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          setNoData(false);
          const data = response?.data;

          setGraphData(data);

          // let res = await context?.getMatrics();
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );
          setGraphType(selectedGraphType?.type);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleNegotiationData();
    setUpdatedMatricsList(updatedValue);
  }, [isUpdate, filterData, updatedValue]);
  return (
    <>
      {loader ? (
        <span>loading...</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Scatter Plot" ? (
                    <ScatterPlot data={graphData} />
                  ) : graphType === "Circle Packing" ? (
                    <CirclePacking data={graphData} />
                  ) : (
                    <ScatterPlot data={graphData} />
                  )}
                </>
              )}
            </>
          ) : updatedMatricsList?.filter(
              (item: any) => item.name === title && item?.is_enabled === true
            )?.length === 0 ? (
            <AnalyticsNoGraph />
          ) : (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Scatter Plot" ? (
                    <ScatterPlot data={graphData} />
                  ) : graphType === "Circle Packing" ? (
                    <CirclePacking data={graphData} />
                  ) : (
                    <ScatterPlot data={graphData} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DaysInNegotiation;

const ScatterPlot = ({ data }: { data: any }) => {
  const updatedData = data?.map((item: any) => ({
    id: item?.contracts[0]?.name,
    data: [
      {
        x: item?.days,
        y: item?.count,
      },
    ],
  }));
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveScatterPlot
        data={updatedData}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: 0, max: "auto" }}
        xFormat={(value) => String(value)}
        yFormat={(value) => `${value}`}
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Days",
          legendPosition: "middle",
          legendOffset: 46,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Total Contract",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: "left-to-right",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const CirclePacking = ({ data }: { data: any }) => {
  const modifieddata = {
    name: "root",
    children: data.map((item: any, index: any) => ({
      name: `Category ${index + 1}`,
      children: item?.contracts?.map((contract: any) => ({
        name: contract?.name,
        value: contract?.contract_value,
        days: item?.days,
      })),
    })),
  };

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveCirclePacking
          data={modifieddata}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          colors={{ scheme: "nivo" }}
          leavesOnly={true}
          padding={1}
          enableLabels={true}
          tooltip={(node: any) => (
            <>
              <strong>{node.data.name}</strong>
              <br />
              Value: {node.data.value}
              <br />
              Days in Negotiation :{node.data.days}
            </>
          )}
        />
      </div>
    </>
  );
};
