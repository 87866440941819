// import { ResponsiveBullet } from "@nivo/bullet";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { ResponsiveLine } from "@nivo/line";
import { updateText } from "../../../../../utils";

function AdminWeeklyTrendChart({ filterData }: any) {
  const context = useAdminAnalytics();
  const [newData, setNewData]: [[], Function] = useState([]);

  const transformData = (originalData: any) => {
    const transformedData = originalData.map((week: any) => {
      const newData = [
        { x: "Approved", y: 0 },
        { x: "Draft", y: 0 },
        { x: "Negotiation", y: 0 },
        { x: "Executed", y: 0 },
      ];

      week.data.forEach((item: any) => {
        const { x, y } = item;
        const index = newData.findIndex((entry) => entry.x === updateText(x));
        if (index !== -1) {
          newData[index].y = y;
        }
      });

      return {
        id: week.id.replace(/\s+/g, ""),
        color: week.color,
        data: newData,
      };
    });

    return transformedData;
  };
  const convertData = (originalData: any) => {
    return originalData.map((item: any) => {
      const id = `${item.week}`;
      const color = "hsl(240, 70%, 50%)";
      const data = Object.keys(item.count[0]).map((key) => ({
        x: updateText(key),
        y: item.count[0][key],
      }));
      return {
        id,
        color,
        data,
      };
    });
  };

  const ChartDataDetails = async () => {
    try {
      let response = await context?.bulletChartDataDetailsWeek(
        filterData ? filterData : ""
      );

      if (response?.success) {
        let newDatas = convertData(response.data);
        const data = transformData(newDatas);

        setNewData(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);

  return (
    <div style={{ height: "400px" }}>
      {newData?.length === 0 ? (
        <AnalyticsNoDataFound />
      ) : (
        <ResponsiveLine
          data={newData}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Contract Status",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Count of Contracts",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </div>
  );
}

export default AdminWeeklyTrendChart;
