import { ReactElement, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { BsBuildings } from "react-icons/bs";
import { FaSignature } from "react-icons/fa";
import { FiHelpCircle, FiUserCheck } from "react-icons/fi";
import {
  MdLogout,
  MdOutlineNotificationsActive,
  MdPassword,
} from "react-icons/md";
import { RiMenu3Line, RiProfileLine } from "react-icons/ri";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo12.png";
import sigin_bg from "../assets/sigin_bg.jpg";
import { Dropdown } from "react-bootstrap";
import { adminRoutes } from "../utils/adminRoutes";
import AdminHeaderPopup from "./AdminHeaderPopup";
import { useAdminAuth } from "../modules/Admin/Auth/AdminAuthenticationProvider";

interface AppLayoutProps {
  children: ReactElement;
}

const AdminHeader = ({ children }: AppLayoutProps) => {
  const [open, setOpen] = useState(false);
  const context: any = useAdminAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [showHeaderPopup, setShowHeaderPopup]: [boolean, Function] =
    useState(false);

  return (
    // context && (
    <div>
      <header className="header  px-3 py-2 border-bottom ">
        <div className="d-flex align-items-center">
          {isDesktop && (
            <div className=" d-flex  align-items-center flex-grow-1 justify-content-between">
              <div
                className="logoSide "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(adminRoutes.root);
                }}
              >
                <img src={logo} alt="logo" width={80} />
              </div>

              <div
              // className="overflow-scroll px-2"
              >
                <ul
                  className="d-flex justify-content-between nestedulInventory pt-3"
                  style={isMobile ? { paddingInlineStart: "1.5rem" } : {}}
                >
                  <li className="me-4 list-unstyled">
                    <Link
                      className={` edit_link_routes ${
                        location.pathname === adminRoutes.root ||
                        location.pathname ===
                          adminRoutes.adminAnalyticsContracts ||
                        location.pathname ===
                          adminRoutes.adminAnalyticsOrganisations ||
                        location.pathname ===
                          adminRoutes.adminAnalyticsSummary ||
                        location.pathname ===
                          adminRoutes.adminAnalyticsTurnaround
                          ? "active"
                          : ""
                      }`}
                      to={adminRoutes.root}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="me-4 list-unstyled">
                    <Link
                      className={`edit_link_routes ${
                        location.pathname === adminRoutes.members
                          ? "active"
                          : ""
                      }`}
                      to={adminRoutes.members}
                    >
                      <span>Members</span>
                    </Link>
                  </li>{" "}
                  <li className="me-4 list-unstyled">
                    <Link
                      to={adminRoutes.accounts}
                      className={`edit_link_routes ${
                        location.pathname?.includes("admin/accounts")
                          ? "active"
                          : ""
                      }`}
                    >
                      <span>Accounts</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className=" d-flex align-items-center position-relative ">
                <div
                  className=" fs-6 text-center fw-semibold me-3"
                  style={{ cursor: "default" }}
                >
                  <span>
                    {context?.currentAdmin?.name?.length &&
                    context?.currentAdmin?.name.length > 15
                      ? `${context?.currentAdmin?.name.slice(0, 14)}...`
                      : context?.currentAdmin?.name.charAt(0).toUpperCase() +
                        context?.currentAdmin?.name.slice(1)}
                  </span>
                </div>
                {/* <div>
                  <div className=" Actions ">
                    <HeaderNotification />
                  </div>
                </div> */}
                <div className="memberimageshapeNav">
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      variant="default"
                      style={{ border: "none" }}
                    >
                      <div
                        className="memberimageshape   me-3"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={context?.currentAdmin?.avatar || sigin_bg}
                          alt="logo"
                          width={40}
                          // onClick={() => {
                          //   setOpen(!open);
                          // }}
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="create-dropdown-css">
                      <div
                        className={`dropdown_menu active`}
                        style={{ top: 0 }}
                      >
                        <ul className="px-3 pt-2">
                          <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={adminRoutes.adminprofile}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <RiProfileLine fontSize={20} />
                              </span>
                              Personal profile
                            </NavLink>
                          </li>
                          {/* <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={""}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <BsBuildings fontSize={20} />
                              </span>
                              Company Profile
                            </NavLink>
                          </li> */}
                          <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={adminRoutes.changepassword}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <MdPassword fontSize={20} />
                              </span>
                              Change Password
                            </NavLink>
                          </li>
                          <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={adminRoutes.roleAndAccess}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <FiUserCheck fontSize={20} />
                              </span>
                              Roles And Access
                            </NavLink>
                          </li>
                          {/* <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={""}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <MdOutlineNotificationsActive fontSize={20} />
                              </span>
                              Notifications{" "}
                            </NavLink>
                          </li> */}
                          {/* <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={""}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <FiUserCheck fontSize={20} />
                              </span>
                              Roles And Access
                            </NavLink>
                          </li> */}
                          {/* <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={""}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <FaSignature fontSize={20} />
                              </span>
                              Signature
                            </NavLink>
                          </li> */}
                          {/* <li className="d-flex align-items-center px-3">
                            <NavLink
                              to={""}
                              onClick={() => setOpen(false)}
                              className="text-decoration-none"
                            >
                              <span className="me-3">
                                <FiHelpCircle fontSize={20} />
                              </span>
                              Help Center
                            </NavLink>
                          </li> */}
                          <li
                            className="d-flex align-items-center justify-content-center px-3 border-bottom-0 rounded-1 mt-3"
                            style={{
                              background: "#3c3adae8",
                            }}
                          >
                            <NavLink
                              to={""}
                              onClick={() => {
                                setOpen(false);
                                context.handleLogout();
                              }}
                              className="text-decoration-none"
                              style={{
                                color: "#fefefe",
                              }}
                            >
                              <span className="me-3">
                                <MdLogout fontSize={20} />
                              </span>
                              Logout
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* -----------------------------------profile drop down------------------------------- */}
                <div
                  className={`dropdown_menu ${open ? "active" : "inactive"}`}
                >
                  <ul className="px-3 pt-2">
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <RiProfileLine fontSize={20} />
                        </span>
                        Personal profile
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <BsBuildings fontSize={20} />
                        </span>
                        Company Profile
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <MdPassword fontSize={20} />
                        </span>
                        Change Password
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <MdOutlineNotificationsActive fontSize={20} />
                        </span>
                        Notifications{" "}
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <FiUserCheck fontSize={20} />
                        </span>
                        Roles And Access
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <FaSignature fontSize={20} />
                        </span>
                        Signature
                      </NavLink>
                    </li>
                    <li className="d-flex align-items-center px-3">
                      <NavLink
                        to={""}
                        onClick={() => setOpen(false)}
                        className="text-decoration-none"
                      >
                        <span className="me-3">
                          <FiHelpCircle fontSize={20} />
                        </span>
                        Help Center
                      </NavLink>
                    </li>
                    <li
                      className="d-flex align-items-center justify-content-center px-3 border-bottom-0 rounded-1 mt-3"
                      style={{
                        background: "#3c3adae8",
                      }}
                    >
                      <NavLink
                        to={""}
                        onClick={() => {
                          setOpen(false);
                          context.handleLogout();
                        }}
                        className="text-decoration-none"
                        style={{
                          color: "#fefefe",
                        }}
                      >
                        <span className="me-3">
                          <MdLogout fontSize={20} />
                        </span>
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              {/* --------------------------------------------------------------profile header new ------------------------------ */}
            </div>
          )}
        </div>

        <div className=" d-flex justify-content-between align-items-center">
          {isMobile && (
            <div>
              <img src={logo} alt="logo" width={80} />
            </div>
          )}
          <div className={isMobile ? " " : "d-none"}>
            {isMobile && !showHeaderPopup && (
              <RiMenu3Line
                onClick={() => {
                  setShowHeaderPopup(true);
                }}
                className="baslist"
                color="#3c3ada"
                fontSize={24}
              />
            )}
          </div>

          {showHeaderPopup && (
            <AdminHeaderPopup
              show={showHeaderPopup}
              onHide={() => setShowHeaderPopup(false)}
              setShowHeaderPopup={setShowHeaderPopup}
            />
          )}
        </div>
      </header>
      <div
        style={{
          background: "#fafafa",
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
    </div>
    // )
  );
};

export default AdminHeader;
