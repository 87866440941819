import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { IMemberFilterParams } from "../../interfaces/IMemberFilterParams";
import { useParams } from "react-router-dom";
import { Member } from "../../lib/Member";
import { api } from "../../utils/api";
import { IRole } from "./Interface";
import { IMetaResponse } from "../../interfaces/response/IMetaResponse";

interface MembersProviderProps {
  children: ReactElement;
}

export interface MemberContextInterface {
  filterParams: IMemberFilterParams;
  recentMember: Member[] | null;
  departmentList: [];
  member: Member[] | null;
  memberTableLists: Member[] | null;
  metaData: IMetaResponse;
  changeFilter: (params: IMemberFilterParams) => void;
  resetFilter: () => void;
  handleInviteMember: Function;
  getMember: Function;
  getListMember: Function;
  deleteUser: Function;
  handleEditMember: Function;
  handleResendInvite: Function;
}

export interface InviteMemberProps {
  email: string;
  department: string;
  role: IRole;
  phone_number: "";
  firstName: "";
  lastName: "";
  industr: "";
  job_title: "";
}

const MembersContext = React.createContext<MemberContextInterface | null>(null);

const MembersProvider = ({ children }: MembersProviderProps) => {
  const { id } = useParams();

  const initialFilter: IMemberFilterParams = useMemo(() => {
    const params: IMemberFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    };
    if (id) {
      params.department = id;
    }
    return params;
  }, [id]);
  const [filterParams, setFilterParams]: [IMemberFilterParams, Function] =
    useState(initialFilter);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [recentMember]: [Member[] | null, Function] = useState(null);
  const [member, setMember] = useState<any>({});
  const [memberTableLists]: [Member[] | null, Function] = useState(null);
  // -------------------------------------------------------------------------get departments---------------------------------------------

  const getDepartments = async () => {
    const res = await api.getDepartments();
    setDepartmentList([...res?.data?.departments, "TECHNICIAN"]);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  // -------------------------------------------------------------------------Invite Members---------------------------------------------

  const handleInviteMember = async (params: InviteMemberProps) => {
    const res = await api.inviteMember(params);
    return res;
  };

  const handleEditMember = async (id: string, params: any) => {
    const res = await api.editMember(id, params);
    return res;
  };

  // -------------------------------------------------------------------------get Members---------------------------------------------

  const getListMember = async (params: InviteMemberProps) => {
    const res = await api.listmember(params);
    return res;
  };

  const [metaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 0,
    limit: 0,
  });

  const getMember = async () => {
    const { success, user } = {
      success: true,

      user: [
        {
          id: 1,
          name: "usama",
          email: "usama@coducer.com",
          contact: "+919673827382",
          createdAt: "2023-01-10T12:00.000",
          status: "ACTIVE",
        },
        {
          id: 2,
          name: "jebas",
          email: "jebas@coducer.com",
          contact: "+919673827382",
          createdAt: "2023-02-10T12:00.000",
          status: "PENDING",
        },
        {
          id: 3,
          name: "Jaikeerthi",
          email: "jai@coducer.com",
          contact: "+917760054546",
          createdAt: "2023-04-10T12:00.000",
          status: "BLOCKED",
        },
        {
          id: 4,
          name: "parikshith",
          email: "parikshith@coducer.com",
          contact: "+919673827382",
          createdAt: "2023-05-30T12:23.000",
          status: "ACTIVE",
        },
        {
          id: 5,
          name: "elakiya",
          email: "elakiya@coducer.com",
          contact: "+919673827382",
          createdAt: "2023-05-31T19:00.000",
          status: "PENDING",
        },
      ],
    };

    if (success) {
      setMember(user);
    }
  };

  const deleteUser = async (id: string) => {
    const res = await api.deleteUser(id);
    return res;
  };

  const handleResendInvite = async (id: any, params: any) => {
    try {
      let response = await api?.resendInviteMember(id, params);
      return response;
    } catch (error) {}
  };

  const value: MemberContextInterface = {
    departmentList,
    filterParams,
    recentMember,
    member,
    metaData,
    memberTableLists,
    handleInviteMember,
    handleEditMember,
    getMember,
    getListMember,
    changeFilter: (params: IMemberFilterParams) => setFilterParams(params),
    resetFilter: () => setFilterParams(initialFilter),
    deleteUser,
    handleResendInvite,
  };

  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  );
};

const useMember = () => React.useContext(MembersContext);

export { MembersProvider, useMember };
