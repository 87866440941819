import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import AdminAuthlayout from "../../../layouts/AdminAuthLayout";
import FailureToast from "../../../ui/FailureToast";
import FormikField from "../../../ui/FormikField";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import SuccessToast from "../../../ui/SuccessToast";
import { useAdminAuth } from "./AdminAuthenticationProvider";
import { FormGroup } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import signin_bg from "../../../assets/sigin_bg.jpg";
import { adminApi } from "../../../utils/adminApi";
import { adminRoutes } from "../../../utils/adminRoutes";
import Select from "react-select";
import { roleOptions } from "../../Members/Interface";

interface InvitationProps {
  name: string | "";
  password: string | "";
  contactNumber: string | "";
  department: string | "";
  roles: string | "";
}
const InvitationPage = () => {
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);
  const [userDetail, setUserDetail]: [any, Function] = useState();
  const [bodyMessage, setBodyMessage]: [any, Function] = useState("");
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const context = useAdminAuth();
  const navigate = useNavigate();

  const initialValues: InvitationProps = {
    name: userDetail?.name,
    password: "",
    contactNumber: userDetail?.contact_number,
    department: userDetail?.department,
    roles: userDetail?.roles,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("name is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    contactNumber: Yup.string()
      .typeError("That doesn't look like a phone number")
      .min(8, "Contact Number must be atleast 8 characters")
      .required(""),
  });

  const handleSubmitData = async (values: InvitationProps) => {
    const user = await adminApi?.adminVerifyTokenpost(token, {
      email: userDetail ? userDetail?.email : null,
      phone_number: values?.contactNumber,
      firstName: values?.name,
      lastName: userDetail?.last_name,
      roles: userDetail ? userDetail?.roles : null,
      password: values?.password,
      department: userDetail?.department,
    });

    if (user?.data?.success) {
      setShowSuccessToast(true);
      navigate(adminRoutes.login);
    } else if (user?.data?.error?.[0]) {
      setShowFailureToast(true);
      setBodyMessage(user?.data?.error?.[0]?.replace("_", " "));
    }
  };

  const getUserDetail = async () => {
    setLoader(true);
    try {
      let response = await adminApi?.adminVerifyToken(token);

      if (response) {
        setUserDetail(response);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
      setBodyMessage(error?.response?.data?.error?.[0]);
      // setShowFailureToast(true);
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: InvitationProps) => {
      try {
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });
  const { errors, handleSubmit, isSubmitting, setFieldValue, values } = formik;

  return (
    context && (
      <AdminAuthlayout
        pageTitle={bodyMessage === "Invalid token" ? "" : "CLM - Invitation"}
        title={
          bodyMessage === "Invalid token"
            ? "Invalid token"
            : "You are invited here!"
        }
        subTitle="Complete the invitation"
      >
        {loader ? (
          <>loading...</>
        ) : (
          bodyMessage !== "Invalid token" && (
            <>
              <div className=" d-flex align-items-center py-4 justify-content-between">
                <div className="d-flex align-items-center">
                  <div
                    className="memberimageshape   me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={signin_bg} alt="logo" width={40} />
                  </div>
                  <div>
                    <h6 className=" fw-semibold m-0 userEmail">
                      {userDetail?.email ? userDetail?.email : ""}
                    </h6>
                    <h6 className=" fw-light m-0 invite">
                      {userDetail?.department[0]?.charAt(0)?.toUpperCase() +
                        userDetail?.department[0]?.slice(1)?.toLowerCase()}
                    </h6>
                  </div>
                </div>
                <h6 className=" fw-semibold m-0 userEmail">
                  {userDetail?.roles ? userDetail?.roles : ""}
                </h6>
              </div>
              <div
                style={{
                  height: "48vh",
                  overflowY: "scroll",
                  paddingRight: "5px",
                }}
              >
                <FormikProvider value={formik}>
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    <FormikField
                      name="name"
                      type="text"
                      validationSchema={validationSchema}
                      label="Name"
                      placeholder="Name"
                      value={values?.name}
                      errors={errors}
                      autoFocus
                    />
                    <FormikField
                      name="password"
                      type={passwordIcon ? "text" : "password"}
                      validationSchema={validationSchema}
                      label="Password"
                      placeholder="Password"
                      errors={errors}
                      isPassword={true}
                      setPasswordIcon={setPasswordIcon}
                      passwordIcon={passwordIcon}
                    />
                    <div>
                      <label className="text-dark mt-2">
                        Role <span className="">*</span>
                      </label>
                      <Select
                        isDisabled
                        value={{
                          label: values?.roles,
                          value: values?.roles,
                        }}
                        className="variable_Select mt-1"
                        options={roleOptions}
                        isMulti={false} // or isMulti={true} depending on your needs
                        onChange={(e: any) => {
                          setFieldValue("roles", [e?.value]);
                        }}
                      />
                    </div>

                    <div className="text-dark mt-2">
                      <label className="text-dark mt-2">
                        Department <span className="">*</span>
                      </label>
                      <Select
                        isDisabled
                        value={
                          values?.department && {
                            label: values?.department,
                            value: values?.department,
                          }
                        }
                        className="variable_Select mt-1"
                        options={context?.departmentList?.map((item: any) => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={(e: any) => {
                          setFieldValue("department", e?.value);
                        }}
                      />
                    </div>
                    <div className=" pb-5">
                      <label className="text-dark mt-3">
                        Contact Number <span className="">*</span>
                      </label>
                      <FormGroup className="mt-2 ">
                        <div className="phone-input-css ">
                          <PhoneInput
                            placeholder="Enter contact number"
                            value={userDetail?.contact_number}
                            name="contactNumber"
                            country={"in"}
                            defaultCountry="IN"
                            onChange={(e: any) =>
                              formik.setFieldValue("contactNumber", e)
                            }
                            error={
                              formik?.values?.contactNumber
                                ? isValidPhoneNumber(
                                    formik?.values?.contactNumber
                                  )
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                        </div>
                        <span className="field-error">
                          {formik?.values?.contactNumber
                            ? isValidPhoneNumber(formik?.values?.contactNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"}
                        </span>

                        <ErrorMessage name="contactNumber" />
                      </FormGroup>
                    </div>

                    <div className=" d-flex justify-content-end">
                      <FormikSubmitButton
                        isSubmitting={isSubmitting}
                        className="my-3 w-100"
                        size="md"
                        submittingLabel={<span>Compliting....</span>}
                      >
                        <span
                          className="SignText"
                          onClick={() => {
                            handleSubmitData(formik?.values);
                          }}
                        >
                          Complete Invitation
                        </span>
                      </FormikSubmitButton>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </>
          )
        )}

        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message="Invitation accepted"
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message={bodyMessage ? bodyMessage : "Invalid email or password."}
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </AdminAuthlayout>
    )
  );
};

export default InvitationPage;
