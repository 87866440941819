import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";
import { Icon } from "./components";
import { LuUndo2, LuRedo2 } from "react-icons/lu";
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "../utils/constants";
import { isLinkActive, unwrapLink } from "../Link";
import { isMarkActive, toggleMark } from "./ToolbarMark";
import _ from "lodash";
import Indent from "./Indent";

export const renderTooltip = (props: any) => {
  return (
    <Tooltip id="button-tooltip" {...props}>
      {_.capitalize(props)}
    </Tooltip>
  );
};

export const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(format)}
    >
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        style={
          isMarkActive(editor, format) ? { color: "blue" } : { color: "black" }
        }
        className="btn-mark-editor"
      >
        <Icon>{icon}</Icon>
      </Button>
    </OverlayTrigger>
  );
};

export const HistoryButton: React.FC<any> = ({ format }) => {
  const editor: any = useSlate();

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        format === "undo" ? renderTooltip("Undo") : renderTooltip("Redo")
      }
    >
      <Button
        className={
          "text-dark " + (format.includes("redo") && "border-end pe-1 br-0")
        }
        onClick={() =>
          format.includes("undo") ? editor.undo() : editor.redo()
        }
        variant="link"
      >
        {format.includes("undo") ? <LuUndo2 /> : <LuRedo2 />}
      </Button>
    </OverlayTrigger>
  );
};

export const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(format)}
    >
      <Button
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )}
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        style={
          isBlockActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
          )
            ? { color: "blue" }
            : { color: "black" }
        }
        className="btn-mark-editor"
      >
        <Icon>{icon}</Icon>
      </Button>
    </OverlayTrigger>
  );
};

export const FormatButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(format)}
    >
      <Button
        // reversed
        active={isMarkActive(editor, format)}
        onClick={() => toggleMark(editor, format)}
        style={
          isMarkActive(editor, format) ? { color: "blue" } : { color: "black" }
        }
        className="btn-mark-editor"
      >
        <Icon>{icon}</Icon>
      </Button>
    </OverlayTrigger>
  );
};

export const LinkButton: React.FC<any> = ({
  format,
  icon,
  setLinkShow,
  setVariableShow,
  setSearchShow,
}) => {
  const editor = useSlate();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(format)}
    >
      <Button
        variant="link"
        size="lg"
        // className={`${
        //   isMarkActive(editor, format)
        //     ?
        //     : styles.textDarkOverride
        // } ${className}`}
        active={isLinkActive(editor)}
        onClick={() => {
          if (format === "remove-link") {
            unwrapLink(editor);
          } else if (format === "variable") {
            setVariableShow(true);
          } else if (format === "searchText") {
            setSearchShow(true);
          } else {
            setLinkShow(true);
          }
        }}
      >
        {icon}
      </Button>
    </OverlayTrigger>
  );
};

export const toggleBlock = (editor: any, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: any) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes((n as any).type as any) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block: any = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isBlockActive = (
  editor: any,
  format: string,
  blockType: any = "type"
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as any)[blockType] === format,
    })
  );

  return !!match;
};

export const IndentButton: React.FC<any> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        format === "indentLeft"
          ? renderTooltip("Left Indent")
          : renderTooltip("Right Indent")
      }
    >
      <Button
        variant="link"
        size="lg"
        onMouseDown={(event: React.MouseEvent) => {
          event.preventDefault();
          indentBlock(editor, format);
        }}
        style={{ color: "black" }}
      >
        {icon}
      </Button>
    </OverlayTrigger>
  );
};

export const indentBlock = (editor: Editor, format: string): void => {
  Indent(editor, format);
};

export const DropdownButton = ({ format, options, context }: any) => {
  const editor = useSlate();

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip("Headings")}
    >
      <>
        {format === "Mode" && (
          <Form.Select
            size="sm"
            onChange={(e: any) => {
              e.preventDefault();
              context?.setMode(e.target.value);
            }}
            className={" border-0 d-inline bg-transparent editorSelect"}
            style={{ minWidth: "130px" }}
          >
            {options.map((item: any, index: number) => (
              <option key={index} value={item.value}>
                {item}
              </option>
            ))}
          </Form.Select>
        )}
        {format === "heading" && (
          <Form.Select
            size="sm"
            onChange={(e: any) => {
              e.preventDefault();
              toggleBlock(editor, e.target.value.toLowerCase());
            }}
            className={" border-0 d-inline bg-transparent editorSelect"}
            style={{ minWidth: "130px" }}
          >
            {options.map((item: any, index: number) => (
              <option key={index} value={item.value}>
                {item === "Paragraph" ? item : "Heading-" + item}
              </option>
            ))}
          </Form.Select>
        )}
        {format === "color" && (
          <Form.Select
            size="sm"
            onChange={(e: any) => {
              e.preventDefault();
              toggleBlock(editor, e.target.value.toLowerCase());
            }}
            className={" border-0 d-inline bg-transparent editorSelect"}
            style={{ minWidth: "130px" }}
          >
            <input type="color" id="favcolor" name="favcolor" value="#ff0000" />

            {options.map((item: any) => (
              <option value={item.value}>
                <div
                  style={{
                    backgroundColor: item,
                    width: "20px",
                    height: "20px",
                  }}
                >
                  {" "}
                  {item}
                </div>
              </option>
            ))}
          </Form.Select>
        )}
      </>
    </OverlayTrigger>
  );
};
