import { IoIosAddCircleOutline } from "react-icons/io";
import { useApprovals } from "./WorkflowProvider";
import { useTemplate } from "../Templates/TemplateProvider";
import { useLocation } from "react-router-dom";
import { StatusEnum } from "../../interfaces/StatusEnum";
import { useEffect, useState } from "react";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { useContract } from "../Contracts/ContractProvider";

const WorkflowHeader = ({
  setToggleState,
  setApprovalAddClick,
  approversAddClick,
  setSignerClick,
  setCounterpartyClick,
  setEstampclick,
  isNegotiationWOrkflowOwner,
  setNegotiationClick,
  setNegotiationApprovalAdd,
}: {
  setToggleState: Function;
  setApprovalAddClick: Function;
  approversAddClick?: boolean;
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setEstampclick: Function;
  isNegotiationWOrkflowOwner: boolean;
  setNegotiationClick: Function;
  setNegotiationApprovalAdd: Function;
}) => {
  const context: any = useApprovals();
  const location = useLocation();
  const contractContext: any = useContract();
  const templateContext = useTemplate();
  const authContext = useAuthentication();

  const onAddWorkflow = (type: string) => {
    setApprovalAddClick(!approversAddClick);
    setCounterpartyClick(false);
    setSignerClick(false);
    setEstampclick(false);
    if (type === "negotiation-approval") {
      setToggleState("negotiation-approval");
    }
    if (type == "approvers") {
      setToggleState("approvers");
    }
  };

  const isContractActive =
    context?.contractDetail?.status === "PENDING" ||
    context?.contractDetail?.status === StatusEnum?.published;

  const isTemplateActive =
    templateContext?.template?.workflow_status === StatusEnum?.draft ||
    templateContext?.template?.status === StatusEnum?.draft;

  const [rolesWrite, setRolesWrite]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesWrite(guestRole?.WORKFLOWS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      <div className=" Approver_box_new row">
        <div className="d-flex justify-content-center px-3 py-3">
          <div className=" d-flex align-items-center">
            <div>
              <h6 className="m-0 fw-semibold">Start Workflow</h6>
            </div>
          </div>
        </div>
      </div>
      {rolesWrite === "CREATE" && (
        <>
          {isNegotiationWOrkflowOwner ? (
            <>
              {contractContext?.data?.negotiation_owners[
                contractContext?.data?.counterparty_owner?.find(
                  (el: any) => el?.email === authContext?.currentUser?.email
                )?.id
              ]?.negotiation_status !== "COMPLETED" &&
                contractContext?.data?.negotiation_owners[
                  contractContext?.data?.counterparty_owner?.find(
                    (el: any) => el?.email === authContext?.currentUser?.email
                  )?.id
                ]?.negotiation_status !== "STARTED" &&
                contractContext?.data?.counterparty_owner?.find(
                  (el: any) => el?.email === authContext?.currentUser?.email
                )?.status !== "REJECTED" && (
                  <>
                    <div className="">
                      <div className=" d-flex justify-content-center ">
                        <div className="  linemiddle"></div>
                      </div>

                      <>
                        <div className=" d-flex justify-content-center ">
                          <div className="fordesign">
                            <IoIosAddCircleOutline
                              fontSize={30}
                              color="#c5d0de"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setApprovalAddClick(false);
                                onAddWorkflow("negotiation-approval");
                                setNegotiationClick(false);
                                setNegotiationApprovalAdd(false);
                                setCounterpartyClick(false);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                )}
            </>
          ) : (
            <>
              {(context?.contractDetail?.collaborators?.find(
                (el: any) => el?.access === "OWNER"
              )?.user?.email === authContext?.currentUser?.email ||
                context?.templateDetail?.collaborators?.find(
                  (el: any) => el?.access === "OWNER"
                )?.user?.email === authContext?.currentUser?.email) && (
                <>
                  {((location.pathname.includes("contracts") &&
                    isContractActive) ||
                    (location.pathname.includes("templates") &&
                      isTemplateActive)) && (
                    <>
                      <div className="">
                        <div className=" d-flex justify-content-center ">
                          <div className="  linemiddle"></div>
                        </div>
                        {/* {roleAccess && ( */}
                        <>
                          <div className=" d-flex justify-content-center ">
                            <div className="fordesign">
                              <IoIosAddCircleOutline
                                fontSize={30}
                                color="#c5d0de"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  onAddWorkflow("approvers");
                                }}
                              />
                            </div>
                          </div>
                        </>
                        {/* )} */}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default WorkflowHeader;
