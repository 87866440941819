import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { IInviteCollaborator } from "../interfaces/IInviteCollaborators";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import AutoCompleteInput from "./AutoCompleteInput";
import CollaboratorCheckbox from "./CollaboratorCheckbox";
import FailureToast from "./FailureToast";
import ListCollaborators from "./ListCollaborators";
import SuccessToast from "./SuccessToast";
import { isMobile } from "react-device-detect";
import { api } from "../utils/api";
import { StatusEnum } from "../interfaces/StatusEnum";
const InviteDocumentCollaborator = ({
  context,
  show,
  setShow,
  isActiveNegotiationWorkflow,
}: {
  context: TemplateContextInterface | ContractContextInterface;
  show: boolean;
  setShow: Function;
  isActiveNegotiationWorkflow?: any;
}) => {
  const { id } = useParams();
  const [apiResponseMessage, setApiResponseMessage]: [string, Function] =
    useState("");
  const [values, setValues] = useState([]);
  const [accessOption, setAccessOption] = useState<string>("EDITOR");
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false);
  const [sendMessage, setSendMessage] = useState<string>("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [collaboratorRoles, setCollaboratorRoles] = useState<any>([]);

  const sendInvitation = async () => {
    if (context?.data?.status === StatusEnum?.pending_negotitation) {
      try {
        let requestBody = {
          counter_parties: values?.map((counterparty: any) => ({
            name: counterparty?.label,
            email: counterparty?.email,
            contact_number: counterparty?.contact_number,
            organization: counterparty?.organization,
            sequence: 1,
            is_optional: false,
          })),
          access: "VIEWER",
        };

        const res = await api.AddNegotiationCollaborators(id!, requestBody);
        if (res) {
          context.refresh();
          setShowSuccessToast(true);
        } else {
          setShowFailureToast(true);
          setApiResponseMessage(res.error);
        }
      } catch (error) {}
    } else {
      if (!id) return;
      handleUpdateCollaboratorsAccess();
      const response = await context?.inviteCollaborator(id, {
        collaborators_id: values.map((item: IInviteCollaborator) => {
          return item?.value;
        }),
        message: sendMessage,
        notify: notifyPeople,
        access: accessOption,
      });

      if (response.success) {
        context.refresh();
        setShowSuccessToast(true);
      } else {
        setShowFailureToast(true);
        setApiResponseMessage(response.error);
      }
      return response;
    }
  };

  const setInitialValue = () => {
    setShow(false);
    // setAccessOption("EDITOR");
    setNotifyPeople(false);
    setSendMessage("");
    setValues([]);
  };

  const handleUpdateCollaboratorsAccess = () => {
    if (!!collaboratorRoles.length && context?.updateCollaboratorsAccess) {
      context?.updateCollaboratorsAccess(collaboratorRoles);
    }
  };

  useEffect(() => {
    if (!values?.length) {
      setAccessOption("EDITOR");
      setNotifyPeople(false);
      setSendMessage("");
    }
  }, [values]);


  return (
    <>
      <Modal show={show} centered style={isMobile ? {} : { height: "100vh" }}>
        <Modal.Header closeButton onClick={() => setShow(false)}>
          <Stack>
            <h5 className="fw-semibold py-2">Share '{context?.data.name}'</h5>
          </Stack>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div>
            <AutoCompleteInput
              values={values}
              setValues={setValues}
              context={context}
            />
          </div>
          {!values.length && (
            <>
              <Stack>
                <h6 className="fw-semibold pb-2 pt-3">
                  Existing Collaborators
                </h6>
              </Stack>
              <ListCollaborators
                collaboratorRoles={collaboratorRoles}
                setCollaboratorRoles={setCollaboratorRoles}
                context={context}
                isActiveNegotiationWorkflow={isActiveNegotiationWorkflow}
              />
            </>
          )}
          {values.length > 0 && (
            <CollaboratorCheckbox
              accessOption={accessOption}
              setAccessOption={setAccessOption}
              setNotifyPeople={setNotifyPeople}
              sendMessage={sendMessage}
              setSendMessage={setSendMessage}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              setShow(false);
              setInitialValue();
            }}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger btn_margin"
            size="sm"
            disabled={!values?.length}
            onClick={() => sendInvitation()}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="Access updated."
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
            setInitialValue();
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={apiResponseMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
            setInitialValue();
            setShow(true);
          }}
        />
      )}
    </>
  );
};
export default InviteDocumentCollaborator;
