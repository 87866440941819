import { CustomRenderLeafProps } from "slate-react";
import Caret from "../Caret";
import { css } from "@emotion/css";
import { fontFamilyMap, lineHeightMap, sizeMap } from "../utils/constants";
import { getCommentThreadsOnTextNode } from "../components/comments/src/utils/EditorCommnentUtils";
import CommentedText from "../components/comments/src/component/CommentedText";
import { useLocation } from "react-router-dom";
import { getSuggestionThreadsOnTextNode } from "../components/Suggestions/utils/EditorSuggestionUtils";
import SuggestedText from "../components/Suggestions/component/SuggestedText";

export const Leaf = (props: CustomRenderLeafProps) => {
  // let mode;
  let newChildren = props?.children;

  const location = useLocation();

  if (props?.leaf.bold) {
    newChildren = <strong {...props?.attributes}>{newChildren}</strong>;
  }

  if (props?.leaf.code) {
    newChildren = <code {...props?.attributes}>{newChildren}</code>;
  }

  if (props?.leaf.italic) {
    newChildren = <em {...props?.attributes}>{newChildren}</em>;
  }

  if (props?.leaf.underline) {
    newChildren = <u {...props?.attributes}>{newChildren}</u>;
  }
  if (props?.leaf.strikeThrough) {
    newChildren = (
      <span {...props?.attributes} style={{ textDecoration: "line-through" }}>
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.suggestionStikeThrough) {
    newChildren = (
      <span {...props?.attributes} className="suggestionStikeThrough">
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.fontFamily) {
    let family: any = fontFamilyMap[props.leaf.fontFamily];
    newChildren = (
      <span
        {...props?.attributes}
        // className="textpara"
        className={
          classNameByFont(family)
          // family === "Times New Roman"
          //   ? "textTimesNewRoman"
          //   : "Georgia"
          //   ? "textGeorgia"
          //   : "Calibri"
          //   ? "textCalibri"
          //   : "textpara"
        }
        // style={{ fontFamily: family }}
      >
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.fontSize) {
    let size;
    size = sizeMap[props?.leaf.fontSize];
    if (size === undefined) {
      size = props?.leaf.fontSize;
    }
    newChildren = (
      <span {...props?.attributes} style={{ fontSize: size + "px" }}>
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.lineHeight) {
    let lineHeightt;
    lineHeightt = lineHeightMap[props?.leaf.lineHeight];
    if (lineHeightt === undefined) {
      lineHeightt = props?.leaf.lineHeight;
    }
    newChildren = (
      <p {...props?.attributes} style={{ lineHeight: lineHeightt + "px" }}>
        {newChildren}
      </p>
    );
  }
  if (props?.leaf.letterSpacing) {
    let letterSpacingG;
    letterSpacingG = lineHeightMap[props?.leaf.letterSpacing];
    if (letterSpacingG === undefined) {
      letterSpacingG = props?.leaf.letterSpacing;
    }
    newChildren = (
      <span
        {...props?.attributes}
        style={{ letterSpacing: letterSpacingG + "px" }}
      >
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.subscript) {
    newChildren = <sub {...props?.attributes}>{newChildren}</sub>;
  }
  if (props?.leaf.superscript) {
    newChildren = <sup {...props?.attributes}>{newChildren}</sup>;
  }
  if (props?.leaf.color) {
    newChildren = (
      <span {...props?.attributes} style={{ color: props.leaf.color }}>
        {newChildren}
      </span>
    );
  }
  if (props?.leaf.bgColor) {
    newChildren = (
      <span
        {...props?.attributes}
        style={{ backgroundColor: props.leaf.bgColor }}
      >
        {newChildren}
      </span>
    );
  }
  if (props?.leaf?.text?.includes("t".toLowerCase())) {
    newChildren = (
      <span {...props?.attributes} style={{ backgroundColor: "transparent" }}>
        {newChildren}
      </span>
    );
  }

  if (!location.pathname.includes("versions")) {
    const commentThreads = getCommentThreadsOnTextNode(props?.leaf);

    if (commentThreads.size > 0) {
      return (
        <>
          {!location.pathname.includes("versions") && (
            <CommentedText
              {...props?.attributes}
              commentThreads={commentThreads}
              textNode={props?.leaf}
            >
              {newChildren}
            </CommentedText>
          )}
        </>
      );
    }
  }

  if (!location.pathname.includes("versions")) {
    const suggestionThreads = getSuggestionThreadsOnTextNode(props?.leaf);

    if (suggestionThreads.size > 0) {
      return (
        <>
          {!location.pathname.includes("versions") && (
            <SuggestedText
              {...props?.attributes}
              suggestionThreads={suggestionThreads}
              textNode={props?.leaf}
            >
              {newChildren}
            </SuggestedText>
          )}
        </>
      );
    }
  }

  const data = props?.leaf.data as any;
  return (
    <span
      {...props?.attributes}
      {...(props?.leaf.highlight && { "data-cy": "search-highlighted" })}
      className={css`
        font-weight: ${props?.leaf.bold && "bold"};
        background-color: ${props?.leaf.highlight && "yellow"};
      `}
      style={{
        position: "relative",
        backgroundColor: data?.alphaColor,
        marginTop: "-17px",
      }}
    >
      {props?.leaf.isCaret ? <Caret {...(props?.leaf as any)} /> : null}
      {newChildren}
    </span>
  );
};

export const classNameByFont = (status: string) => {
  switch (status) {
    case "Times New Roman":
      return "textTimesNewRoman";
    case "Georgia":
      return "textGeorgia";
    case "Calibri":
      return "textCalibri";
    case "Arimo":
      return "textArial";
    case "San serif":
      return "textSansserif";
    case "Open Sans":
      return "textOpenSans";
      case "Verdana":
        return "textVerdana";
      case "Pacifico":
        return "textpacifico"
      case "Times":
        return "textTimesCG"
    default:
      return "textpara";
  }
};
