import "firebase/messaging";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import { AuthenticationProvider } from "./modules/Authentication/AuthenticationProvider";
import { useLocation } from "react-router-dom";
import { AdminAuthenticationProvider } from "./modules/Admin/Auth/AdminAuthenticationProvider";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener, requestForToken } from "./firebase";
import { TiDelete } from "react-icons/ti";
import { CgTemplate } from "react-icons/cg";
const Notification = require("./assets/notification_audio.mp3");

const App = () => {
  const location = useLocation();

  const notificationSound = new Audio(Notification);

  const notify = (title: string, body: string) => (
    <ToastDisplay title={title} body={body} />
  );
  function ToastDisplay({ title, body }: { title: string; body: string }) {
    return (
      <div>
        <p>
          <b>{title}</b>
        </p>

        <p>{body}</p>
      </div>
    );
  }

  useEffect(() => {
    requestForToken();
  }, []);

  onMessageListener()
    .then((payload: any) => {
      notificationSound.play();
      toast.custom(() => (
        <div
          style={{
            background: "#fafafa",
            border: "1px solid #d5d5fb",
            color: "#333f52",
            maxWidth: "600px",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          className=" position-relative"
        >
          <div
            className="deleteBtnFornoti "
            style={{ cursor: "pointer" }}
            onClick={() => toast.dismiss()}
          >
            <TiDelete color="#e15554" fontSize={24} />
          </div>
          <div>
            <div className="d-flex align-items-center">
              <CgTemplate color="#c5d0de" fontSize={24} />
              <span className=" fw-semibold mx-2">
                {payload?.notification?.title}
              </span>
            </div>
            <div className="pt-3">
              <h6 className="fw-normal">{payload?.notification?.body}</h6>
            </div>
          </div>
        </div>
      ));

      notify(payload?.notification?.title, payload?.notification?.body);
    })
    .catch((err) => console.log("failed: ", err));

  return location.pathname.startsWith("/admin") ? (
    <AdminAuthenticationProvider>
      <AdminRoutes />
    </AdminAuthenticationProvider>
  ) : (
    <AuthenticationProvider>
      <React.Fragment>
        <UserRoutes />
      </React.Fragment>
      <Toaster />
    </AuthenticationProvider>
  );
};

export default App;
