import { useState } from "react";
import DownloadButton from "../DownloadButton";
import ContractINitiatedByEachTeam from "./ContractINitiatedByEachTeam";
import ContractRequiringApproval from "./ContractRequiringApproval";
import ContractSignedByEachTeam from "./ContractSignedByEachTeam";

function Teams({
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
}: {
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
}) {
  const [isLoadingData, setIsLoadingData] = useState([false, false, false]);
  return (
    <>
      {!isLoadingData?.includes(false) && (
        <DownloadButton title={"Teams_report"} />
      )}
      <div className="analyticsBody " id="pdf-content">
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Contract signed by each team
              </h6>
              <ContractSignedByEachTeam
                title={"Contracts Signed By Each Team"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Number of contracts requiring approval
              </h6>
              <ContractRequiringApproval
                title={"Number of contracts requiring approval"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-12 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Number of contracts initiated by each team
              </h6>
              <ContractINitiatedByEachTeam
                title={"Number of contracts initiated by each team"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teams;
