import noDataa from "../../assets/IHX_Empty_Dashboard.png";

const AnalyticsNoGraph = () => {
  return (
    <div
      className="noDataForChart d-flex justify-content-center align-items-center flex-column"
      style={{ minHeight: "445px" }}
    >
      <div
        style={{ cursor: "not-allowed" }}
        className=" d-flex justify-content-center align-items-center flex-column"
      >
        <div className={"font404 d-flex justify-content-center"}>
          <img src={noDataa} alt="no-data" width={100} />
        </div>
        {/* <div
          className="no-data-found"
          style={{ fontSize: "12px", color: "#a8aab7" }}
        >
          No Data Found
        </div> */}
      </div>
    </div>
  );
};

export default AnalyticsNoGraph;
