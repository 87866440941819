import { useCallback, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { BsBell } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import Moment from "react-moment";
import { INotification } from "../../interfaces/INotification";
import { api } from "../../utils/api";
import moment from "moment";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

const HeaderNotification = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [meta, setMeta] = useState<{ totalCount: number; totalUnRead: number }>(
    { totalCount: 0, totalUnRead: 0 }
  );
  const [canMarkRead, setCanMarkRead] = useState(false);
  const [lastLoadedAt, setLastLoadedAt] = useState<any>(null);

  const hasUnRead = () =>
    meta.totalUnRead > 0 ||
    notifications.some(({ is_read }: INotification) => !is_read);

  const canLoadMore = () => {
    return (
      notifications.length >= meta.totalCount ||
      page >= Math.round(meta.totalCount / 10)
    );
  };

  const loadData = useCallback(async () => {
    try {
      if (lastLoadedAt && moment().diff(lastLoadedAt, "seconds") < 30) {
        return;
      } else {
        setLastLoadedAt(moment());
      }

      setLoading(true);
      const res = await api.getNotificationData(page);
      const {
        success,
        notifications: data,
        total_count: totalCount,
      }: {
        success: true;
        notifications: INotification[];
        total_count: number;
      } = res;
      if (success) {
        const _data = data.map((obj: INotification) => {
          const notification = notifications.find(({ id }) => obj.id === id);
          return notification
            ? {
                ...notification,
                ...obj,
              }
            : { ...obj };
        });
        setNotifications(_data);
        setMeta({ ...meta, totalCount });
      }
    } finally {
      setLoading(false);
    }
  }, [page, lastLoadedAt]);

  useEffect(() => {
    const interval = setTimeout(() => {
      loadData();
    }, 60000);

    return () => {
      clearTimeout(interval);
    };
  }, [loadData]);

  useEffect(() => {
    page > 1 && loadData();
  }, [page, loadData]);

  useEffect(() => {
    const markRead = async () => {
      const id = notifications
        .filter(({ is_read }) => !is_read)
        .map(({ id }) => id);
      if (id.length > 0) {
        await api.updateNotificationData({
          id,
        });
        loadData();
      }
    };
    if (canMarkRead) {
      markRead();
    }
  }, [loadData, canMarkRead, notifications]);

  return (
    <Dropdown
      onToggle={(show) => {
        if (show) {
          loadData();
          setCanMarkRead(true);
        }
      }}
    >
      <Dropdown.Toggle
        variant="success"
        className="Actions_title position-relative"
        id="dropdown-basic"
      >
        {hasUnRead() && (
          <span
            className={`notficationCount rounded-circle is_active_notification`}
          ></span>
        )}
        <BsBell color="#c5d0de" fontSize={28} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="create-dropdown-css">
        <div className={`px-3 position-relative notiBox notiBoxheight`}>
          {notifications?.length === 0 ? (
            <h6
              className=" text-center fw-semibold mt-3"
              style={{ fontSize: "14px" }}
            >
              No Notification
            </h6>
          ) : (
            notifications?.map((notification: INotification) => (
              <div
                key={notification.id}
                className="notificationBox p-3 mt-3"
                style={{
                  backgroundColor: notification?.is_read ? "" : "#f1f1f5",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    generatePath(routes.overviewContracts, {
                      id: notification?.contract_id,
                    })
                  )
                }
              >
                <div className="d-flex align-items-center">
                  <GrMail color="#c5d0de" fontSize={24} />
                  <span className="fw-light ms-2 me-1">
                    {notification?.title}
                  </span>
                  <span className="fw-light">
                    <Moment fromNow>{notification?.createdAt}</Moment>
                  </span>
                </div>
                <div className="pt-3">
                  <h6 className="fw-normal">{notification?.message}</h6>
                </div>
              </div>
            ))
          )}
          <div
            className=" d-flex justify-content-center p-3 w-100 border-top"
            style={{
              background: "#fefefe",
              position: "fixed",
              bottom: "0px",
              left: "0px",
              width: "100%",
            }}
          >
            {loading ? (
              <FaSpinner className="spinner" />
            ) : (
              <Button
                variant="default"
                className="fw-semibold border-0"
                style={{ fontSize: "14px" }}
                disabled={canLoadMore()}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                View More
              </Button>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderNotification;
