import { useEffect } from "react";
import { ITemplateFilterParams } from "../../../interfaces/ITemplateFilterParams";
import moment from "moment";
import DatePicker from "react-date-picker";
import { Form } from "react-bootstrap";

function DateFilter({
  setFilterParams,
  filterParams,
  setEndValues,
  startValues,
  endValues,
  setStartValues,
}: {
  setEndValues: Function;
  setStartValues: Function;
  setFilterParams: Function;
  startValues: any;
  endValues: any;
  filterParams: ITemplateFilterParams;
}) {
  useEffect(() => {
    if (startValues || endValues) {
      setFilterParams({
        ...filterParams,
        start_date: moment(startValues).format("YYYY-MM-DD"),
        end_date: moment(endValues).format("YYYY-MM-DD"),
      });
    }
  }, [startValues, endValues]);

  return (
    <>
      <div className="row status-title mb-1 mt-2">Updated at</div>

      <span className="datepicker_box">
        <Form.Group>
          <div className="row status-title mb-3 ">Start date</div>
          <DatePicker
            className="date_picker_css_filter"
            value={startValues ? moment(startValues).toDate() : ""}
            onChange={(date: any) => {
              setStartValues(date);
            }}
          />
        </Form.Group>
      </span>

      <span className="datepicker_box">
        <Form.Group>
          <div className="row status-title mb-3 mt-3">End date</div>
          <DatePicker
            disabled={startValues === undefined}
            className="date_picker_css_filter mb-2"
            minDate={moment(startValues).toDate()}
            value={endValues ? moment(endValues).toDate() : ""}
            onChange={(date: any) => {
              setEndValues(date);
            }}
          />
        </Form.Group>
      </span>
    </>
  );
}

export default DateFilter;
