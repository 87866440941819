import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { VersionProvider, useVersionHistory } from "./VersionHisoryProvider";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Version } from "../../lib/VersionHistory";
import moment from "moment";
import { routes } from "../../utils/routes";
import VersionRenamePopup from "./VersionRenamePopup";
import FailureToast from "../../ui/FailureToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import SuccessToast from "../../ui/SuccessToast";
import DeleteToast from "../../ui/DeleteToast";
import { IVersionHistoryResponse } from "../../interfaces/response/IVersionHistoryResponse";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { api } from "../../utils/api";

function VersionRightPannel() {
  const { id, documentId, versionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthentication();
  const context = useVersionHistory();

  const [documentDetail, setDocumentDetail]: [Version[], Function] = useState(
    []
  );
  const [activeVersionDetail, setActiveVersionDetail]: [
    Version | undefined,
    Function
  ] = useState();
  const [allVersion, SetAllVersion]: [boolean, Function] = useState(false);
  const [versionNaming, SetVersionNaming]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [headDocumentVersion, setHeadDocumentVersion]: [string, Function] =
    useState("");
  const [isRestoreActive, setIsRestoreActive]: [boolean, Function] =
    useState(false);
  const [currentDocument, setCurrentDocument]: [any, Function] = useState();
  const [contractDetail, setContractDetail]: [any, Function] = useState();
  const [currentVersionDetail, setCurrentVersionDeatil]: [
    IVersionHistoryResponse | undefined,
    Function
  ] = useState();
  const [activeFilter, setActiveFilter]: [string, Function] =
    useState("All versions");

  const handleGetVersionHistory = async () => {
    try {
      let response = await context?.handleGetVersionDetail(documentId);

      if (response?.success) {
        if (allVersion && response?.document_versions) {
          let namedVersion = response?.document_versions?.filter(
            (versionName: Version) => {
              return versionName?.name;
            }
          );
          setDocumentDetail(namedVersion);
        } else {
          setDocumentDetail(response?.document_versions);
        }

        let res = await context?.getCurrentVersion(documentId);

        if (res) {
          setHeadDocumentVersion(res?.head_document_version);
          authContext?.setCurrentVersionId(res?.head_document_version);
          let currentVersion = response?.document_versions?.filter(
            (id: any) => {
              return id?.id === res?.head_document_version;
            }
          );

          if (currentVersion?.length > 0) {
            setCurrentVersionDeatil(res);
          }
        }
      }
    } catch (error) {}
  };

  const handleRestoreVersion = async () => {
    try {
      let response = await context?.RestoreDocumentVersion(
        documentId,
        versionId
      );

      if (response) {
        handleGetVersionHistory();
        setShowDeleteToast(false);
        setShowSuccessToast(true);
        setBodyMessage("Document restored.");
      }
    } catch (error) {}
  };

  const handleCloneDocument = async () => {
    if (location.pathname.includes("contracts")) {
      try {
        let response = await context?.handleCloneVersionContract(versionId);
        if (response?.status === 201) {
          setShowDeleteToast(false);
          setShowSuccessToast(true);
          setBodyMessage("Document cloned.");
          navigate(routes.contracts);
        }
      } catch (error) {}
    } else if (location.pathname.includes("templates")) {
      try {
        let response = await context?.handleCloneVersionTemplate(versionId);
        if (response?.status === 201) {
          setShowDeleteToast(false);
          setShowSuccessToast(true);
          setBodyMessage("Document cloned.");
          navigate(routes.templates);
        }
      } catch (error) {}
    }
  };

  const getContractById = async () => {
    if (location?.pathname?.includes("templates")) {
      try {
        let response = await api?.getTemplate(id!);

        if (response) {
          setContractDetail(response);
        }
      } catch (error) {}
    } else {
      try {
        let response = await api?.getContractsDetails(id!);
        if (response?.success) {
          setContractDetail(response?.contract);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    handleGetVersionHistory();
  }, [allVersion]);

  useEffect(() => {
    getContractById();
  }, []);

  return (
    <>
      <VersionProvider>
        <div className="right_box_version position-relative">
          <div className="p-3 border-bottom">
            <div>
              <h6 className=" fw-semibold mt-2"> Version history</h6>
            </div>
            <div className=" py-2 " style={{ width: "100%" }}>
              <div className="fordesign">
                <Dropdown className="">
                  <Dropdown.Toggle variant="success" style={{ width: "100%" }}>
                    {activeFilter}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        SetAllVersion(false);
                        setActiveFilter("All version");
                      }}
                    >
                      All versions
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        SetAllVersion(true);
                        setActiveFilter("Named version");
                      }}
                    >
                      Named version
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div
            className="mt-2"
            style={{ maxHeight: "76vh", overflow: "scroll" }}
          >
            <span className=" m-0 ps-3   fw-semibold yesterday">
              {moment(documentDetail?.[0]?.updatedAt)
                .startOf("day")
                .calendar(null, {
                  sameDay: "[Today]",
                  nextDay: "[Tomorrow]",
                  sameElse: "L",
                })}
            </span>
            {documentDetail?.map((version: Version) => {
              return (
                <Fragment key={version?.id}>
                  <div
                    className={` mt-2 position-relative  ${
                      versionId === version?.id ? "active_version" : ""
                    }`}
                  >
                    {((location?.pathname?.includes("template") &&
                      contractDetail?.collaborators?.find(
                        (el: any) =>
                          el?.user?.email === authContext?.currentUser?.email
                      )?.access === "OWNER" &&
                      contractDetail?.status === "DRAFT") ||
                      (location?.pathname?.includes("contract") &&
                        contractDetail?.collaborators?.find(
                          (el: any) =>
                            el?.user?.email === authContext?.currentUser?.email
                        )?.access === "OWNER" &&
                        contractDetail?.status === "DRAFT")) && (
                      <>
                        <div className="position-absolute dotVersion">
                          <div id="delete-icon">
                            <Dropdown
                              drop="start"
                              onClick={() => setActiveVersionDetail(version)}
                            >
                              <Dropdown.Toggle
                                style={{ border: "none" }}
                                variant="default"
                                id="dropdown-basic"
                              >
                                <BsThreeDotsVertical color="#000" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="create-dropdown-css">
                                {headDocumentVersion !== version?.id && (
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      setShowDeleteToast(true);
                                      setIsRestoreActive(true);
                                      setCurrentDocument(
                                        version?.name
                                          ? version?.name
                                          : moment(version?.updatedAt).format(
                                              "DD MMM, YYYY"
                                            )
                                      );
                                    }}
                                  >
                                    Restore this version
                                  </Dropdown.Item>
                                )}

                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => SetVersionNaming(true)}
                                >
                                  Name this version
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => {
                                    setIsRestoreActive(false);
                                    setCurrentDocument(
                                      version?.name
                                        ? version?.name
                                        : moment(version?.updatedAt).format(
                                            "DD MMM, YYYY"
                                          )
                                    );
                                    setShowDeleteToast(true);
                                  }}
                                >
                                  Make a copy
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className="tabs  mt-2"
                      onClick={() => {
                        navigate(
                          location.pathname.includes("contracts")
                            ? generatePath(
                                routes.VersionHistoryContractDeatils,
                                {
                                  id: id,
                                  versionId: version?.id,
                                  documentId: documentId,
                                }
                              )
                            : generatePath(
                                routes.VersionHistoryTemplateDeatils,
                                {
                                  id: id,
                                  versionId: version?.id,
                                  documentId: documentId,
                                }
                              )
                        );

                        window.location.reload();
                      }}
                    >
                      <div className="tab position-relative">
                        {/* <input
                          type="checkbox"
                          id="chck1"
                          className="checkbox_version"
                        /> */}
                        <label
                          className="tab-label border-top border-bottom"
                          htmlFor="chck1"
                        >
                          <div
                            tabIndex={1}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={version?.name}
                          >
                            <span
                              style={{ width: "70%" }}
                              className="m-0 d-inline-block px-2 border-0 h6 fw-normal py-1 text-title ms-4"
                            >
                              {version?.name ? (
                                <>
                                  {version?.name?.length >= 15 ? (
                                    <>
                                      {version?.name?.charAt(0).toUpperCase() +
                                        version?.name?.slice(1)?.substr(1, 15)}
                                      {"..."}
                                    </>
                                  ) : (
                                    <>
                                      {version?.name?.charAt(0).toUpperCase() +
                                        version?.name?.slice(1)}
                                    </>
                                  )}
                                </>
                              ) : (
                                moment(version?.updatedAt).format(
                                  "DD MMM YYYY , hh:mm A"
                                )
                              )}
                            </span>
                          </div>

                          <i className="ms-4 current_version">
                            {headDocumentVersion === version?.id ? (
                              "Current version"
                            ) : (
                              <>
                                {version?.name &&
                                  moment(version?.updatedAt).format(
                                    "DD MMM YYYY , hh:mm A"
                                  )}
                              </>
                            )}
                          </i>
                          {version?.edited_by?.length === 0 ? (
                            <>
                              <div className="ms-4">
                                <span>
                                  <RxDotFilled fontSize={20} color="red" />
                                </span>
                                {version?.created_by?.name ? (
                                  version?.created_by?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  version?.created_by?.name
                                    ?.slice(1)
                                    .toLowerCase()
                                ) : (
                                  <>
                                    {version?.created_by?.type
                                      .charAt(0)
                                      .toUpperCase() +
                                      version?.created_by?.type
                                        ?.slice(1)
                                        .toLowerCase()}
                                    {" generated"}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            version?.edited_by?.map((editedBy) => {
                              return (
                                <>
                                  <div className="ms-4">
                                    <span>
                                      {version?.created_by?.name ===
                                      editedBy?.name ? (
                                        <RxDotFilled
                                          fontSize={20}
                                          color={`green`}
                                        />
                                      ) : (
                                        <RxDotFilled
                                          fontSize={20}
                                          color={`#${Math.floor(
                                            Math.random() * 16777215
                                          ).toString(16)}`}
                                        />
                                      )}
                                    </span>
                                    {editedBy?.name.charAt(0).toUpperCase() +
                                      editedBy?.name?.slice(1).toLowerCase()}
                                  </div>
                                </>
                              );
                            })
                          )}
                        </label>

                        {/* <div className="tab-content ">
                          <div className="p-1 border-bottom ms-5">
                            <h6>14 June, 14:46</h6>
                            <h6>
                              {" "}
                              <span>
                                <RxDotFilled fontSize={20} color="green" />
                              </span>
                              Jaikeerthi k
                            </h6>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
          {/* <div
            className="show_changes p-3 border-top w-100"
            style={{ background: "#fefefe" }}
          >
            <div className=" d-flex align-items-center">
              <span className="pe-3 mt-1 show_changes_checkbox">
                <input type="checkbox" />
              </span>
              <h6 className="m-0 ">Show Changes</h6>
            </div>
          </div> */}
        </div>

        {versionNaming && (
          <VersionRenamePopup
            documentDetail={activeVersionDetail}
            versionName={activeVersionDetail?.name}
            show={versionNaming}
            onHide={() => SetVersionNaming(false)}
            handleDocument={handleGetVersionHistory}
            setShowSuccessToast={setShowSuccessToast}
            setBodyMessage={setBodyMessage}
          />
        )}

        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message={bodyMessage}
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
        {showDeleteToast && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => {
              isRestoreActive ? handleRestoreVersion() : handleCloneDocument();
            }}
            onClose={() => setShowDeleteToast(false)}
            title={
              isRestoreActive
                ? `Do you want to restore "${
                    currentDocument ? currentDocument : "Name this version"
                  }" version with ${
                    currentVersionDetail?.name
                      ? currentVersionDetail?.name
                      : moment(currentVersionDetail?.updatedAt).format(
                          "DD MMM , hh:mm"
                        )
                  } version?`
                : "Do you want to clone this document?"
            }
            closeText="No, don't !"
            submitText={
              isRestoreActive ? "Yes, restore them !" : "Yes, clone it !"
            }
          />
        )}
      </VersionProvider>
    </>
  );
}

export default VersionRightPannel;
