import { isMobileOnly } from "react-device-detect";
import { useState, useEffect } from "react";
import { useAdminAnalytics } from "../AdminAnalyticsProvider";
import { BiRupee } from "react-icons/bi";

const AdminContractStats = ({ filterData }: { filterData: any }) => {
  const context = useAdminAnalytics();
  const [contractStatsData, setcontractStatsData]: [any, Function] = useState();

  const contractStatsDataDetails = async () => {
    try {
      let response = await context?.contractStatsData(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setcontractStatsData(response?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    contractStatsDataDetails();
  }, [filterData]);

  return (
    <>
      {
        <>
          <div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center  my-2 flex-wrap">
              <h6 className="    m-0 my-2 "> No. Of Contracts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["no_of_contracts"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center  my-2 flex-wrap">
              <h6 className="    m-0 my-2 "> Active contracts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["active_contracts"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2"> Drafts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["draft_contracts"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                Contracts to be approved
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["contracts_to_be_approved"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts in negotiation
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["contracts_in_negotiation"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2"> Signatures pending</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["signatures_pending"]
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2"> Contracts signed</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData ? contractStatsData["signed_count"] || 0 : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts signed in the last 3 months
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData[
                      "contracts_signed_in_last_three_months"
                    ] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Renewals expected in the next 3 months
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData
                  ? contractStatsData["renewals_expected"] || 0
                  : 0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Average contract cycle time for executed contracts
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {contractStatsData ? contractStatsData["averageDays"] || 0 : 0}{" "}
                days
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Total Contract Value
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2 d-flex align-items-center"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                <span>
                  <BiRupee fontSize={16} />
                </span>
                {contractStatsData
                  ? contractStatsData["total_contract_value"] || 0
                  : 0}
              </h6>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default AdminContractStats;
