import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { ITemplateCategory } from "../interfaces/ITemplateCategory";
import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";
import { ListTemplateContextInterface } from "../modules/Templates/ListTemplatesProvider";
import FormikSubmitButton from "./FormikSubmitButton";
import { isMobileOnly } from "react-device-detect";
import { ListContractContextInterface } from "../modules/Contracts/ListContractProvider";
import Category from "./Category";
import Department from "./Department";
import Collaborator from "./Collaborators";
import Status from "./Status";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../utils/routes";
import { FaFilter } from "react-icons/fa";

interface DocumentFilterProps {
  params?: ITemplateFilterParams;
  changeFilter: Function;
  resetFilter?: Function;
  categoriesAutocomplete: Function;
  defaultCategories: ITemplateCategory[];
  setIsFilterActive?: Function;
  isFilterActive?: boolean;
  context?: ListContractContextInterface | ListTemplateContextInterface;
  status: boolean;
  isCreateContractPopup?: boolean;
}

const DocumentFilter = ({
  params,
  changeFilter,
  categoriesAutocomplete,
  defaultCategories,

  context,
  status,
  isCreateContractPopup,
}: DocumentFilterProps) => {
  const queryParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const location = useLocation();
  const [filterUpdate, setFilterUpdate]: [boolean, Function] = useState(false);

  const [filterParams, setFilterParams]: any = useState(
    queryParams
      ? {
          name: queryParams.get("name") || "",
          category: queryParams.get("category") || "",
          status: queryParams.get("status") || "",
          statusLabel: queryParams.get("status") || "",
          search: queryParams.get("search") || "",
          department: queryParams.get("department") || "",
          limit: 10,
        }
      : params
  );

  const initialValues: {
    category: string;
    status: string;
    limit: any;
  } = {
    category: filterParams?.category || "",
    status: filterParams?.status || "",
    limit: 10,
  };

  const isActiveTemplates: boolean = !!matchPath(
    location.pathname,
    routes.templates
  );

  useEffect(() => {
    if (
      filterParams?.category === undefined ||
      filterParams?.status === undefined ||
      filterParams?.department === undefined ||
      filterParams?.collaborators === undefined
    ) {
      setFilterUpdate(false);
    }
  }, [filterParams]);

  return (
    <>
      {isMobileOnly && !isCreateContractPopup ? (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={(_values, actions) => {
              changeFilter(filterParams);
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {location.pathname !== "/members" && (
                  <Category
                    defaultCategories={defaultCategories}
                    categoriesAutocomplete={categoriesAutocomplete}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                  />
                )}

                {location.pathname !== "/members" && (
                  <>
                    {status ? (
                      <Status
                        context={context}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                      />
                    ) : (
                      <div className="mt-2">&nbsp;</div>
                    )}
                    <Collaborator
                      context={context}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                    />
                  </>
                )}
                <Department
                  context={context}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                />

                <div className="d-flex my-2 justify-content-end">
                  <Button
                    variant="outline-danger"
                    className="px-4 bg-white text-danger me-3"
                    size="sm"
                    onClick={async () => {
                      {
                        await context?.changeFilter({
                          search: "",
                          department: "",
                          collaborators: "",
                          category: "",
                          status: "",
                        });
                        if (location.pathname === "/templates") {
                          navigate(routes.templates);
                        } else {
                          navigate(routes.root);
                        }
                      }
                    }}
                  >
                    Reset
                  </Button>
                  <div>
                    <FormikSubmitButton
                      isSubmitting={isSubmitting}
                      size="md"
                      variant="success"
                      className="text-white px-4"
                      submittingLabel="applying..."
                    >
                      Apply
                    </FormikSubmitButton>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Dropdown className="filter-dropdown">
          <Dropdown.Toggle className="filter-css ms-md-3" id="dropdown-basic">
            <span>
              Filter By
              <span className="px-2">
                {filterUpdate ? <FaFilter /> : <FiFilter />}
              </span>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="filter-dropdown-menu">
            <Formik
              initialValues={initialValues}
              onSubmit={(_values, actions) => {
                changeFilter(filterParams);
                if (!location.pathname.includes("overview")) {
                  if (isActiveTemplates) {
                    navigate(routes.templateFiltersUrl(filterParams));
                  } else {
                    navigate(routes.contractFiltersUrl(filterParams));
                  }
                }

                actions.setSubmitting(false);
                if (
                  filterParams?.category ||
                  filterParams?.status ||
                  filterParams?.department ||
                  filterParams?.collaborators
                ) {
                  setFilterUpdate(true);
                }
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {location.pathname !== "/members" && (
                    <Category
                      defaultCategories={defaultCategories}
                      categoriesAutocomplete={categoriesAutocomplete}
                      setFilterParams={setFilterParams}
                      filterParams={filterParams}
                    />
                  )}

                  {location.pathname !== "/members" && (
                    <>
                      {status ? (
                        <Status
                          context={context}
                          filterParams={filterParams}
                          setFilterParams={setFilterParams}
                        />
                      ) : (
                        <div className="mt-2">&nbsp;</div>
                      )}
                      <Collaborator
                        context={context}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                      />
                    </>
                  )}
                  <Department
                    context={context}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                  />

                  <div className="d-flex justify-content-start my-2">
                    <Button
                      variant="outline-danger"
                      className="px-4 bg-white text-danger me-3"
                      size="sm"
                      onClick={async () => {
                        {
                          await context?.changeFilter({
                            status: "",
                            search: "",
                            department: "",
                            collaborators: "",
                            category: "",
                            limit: 10,
                          });
                          setFilterParams({
                            status: "",
                            search: "",
                            department: "",
                            statusLabel: "",
                            collaborators: [],
                            category: "",
                            limit: 10,
                          });

                          // if (location.pathname === "/templates") {
                          //   navigate(routes.templates);
                          // } else {
                          //   navigate(routes.contracts);
                          // }
                          setFilterUpdate(false);
                        }
                      }}
                    >
                      Reset
                    </Button>
                    <FormikSubmitButton
                      isSubmitting={isSubmitting}
                      size="md"
                      variant="success"
                      className="text-white px-4"
                      submittingLabel="applying..."
                    >
                      Apply
                    </FormikSubmitButton>
                  </div>
                </form>
              )}
            </Formik>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default DocumentFilter;
