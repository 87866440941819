import { ReactNode, useRef, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { useAdminAuth } from "../Auth/AdminAuthenticationProvider";
import AdminAppLayout from "../../../layouts/AdminAppLayout";
import FailureToast from "../../../ui/FailureToast";
import SuccessToast from "../../../ui/SuccessToast";
import sign_in from "../../../assets/sigin_bg.jpg";
import ProfileHeader from "./ProfileHeader";
import { adminApi } from "../../../utils/adminApi";
import { useLocation } from "react-router-dom";

const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const context = useAdminAuth();
  const imageRef: any = useRef();
  const location = useLocation();
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const handleFileChange = async (files: any) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    try {
      const res = await adminApi?.updateAdminProfileimage(formData);
      if (res?.data?.success) {
        setShowSuccessToast(true);
        setBodyMessage("Profile Picture Updated");
        await context?.getAdmin();
      }
    } catch (error) {}
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  return (
    <AdminAppLayout>
      <div className="py-2 ">
        {!location?.pathname?.includes("roles_access") && (
          <>
            <div className="profile">
              <div className=" d-flex align-items-center ">
                <div className=" position-relative p-2">
                  <div className="circle ">
                    <img
                      className="profile-pic"
                      src={context?.currentAdmin?.avatar || sign_in}
                      alt={"avatar"}
                    />
                  </div>
                  <div className="p-image position-absolute p-2 ">
                    <label htmlFor="fileInput " className="font ">
                      <MdOutlineModeEditOutline
                        color="#000000"
                        fontSize={20}
                        onClick={showOpenFileDialog}
                      />
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      ref={imageRef}
                      style={{ display: "none" }}
                      onChange={(e: any) => {
                        handleFileChange(e.target.files);
                      }}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <span>
                    <IoIosArrowForward fontSize={24} />
                  </span>
                </div>
                <div className="p-2 mb-4 ">
                  <span className="profile-username">
                    {context?.currentAdmin?.name}
                  </span>
                  <br />
                  <span>{context?.currentAdmin?.email}</span>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="mx-4 bg-white p-4">
          <ProfileHeader />
          {children}
        </div>
      </div>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </AdminAppLayout>
  );
};

export default SettingsLayout;
