import { useState } from "react";
import CounterpartyByContractAmount from "./CounterpartyByContractAmount";
import DownloadButton from "../DownloadButton";

function ContractValue({
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
}: {
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
}) {
  const [isLoadingData, setIsLoadingData] = useState([false]);
  return (
    <>
      {!isLoadingData?.includes(false) && (
        <DownloadButton title={"Contractvalue_report"} />
      )}
      <div className="analyticsBody " id="pdf-content">
        <div className=" row">
          <div className=" col-lg-12 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title ">
                Top 10 counterparties by contract amount
              </h6>
              <CounterpartyByContractAmount
                title={"Top 10 counterparties by contract amount"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractValue;
