import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ClmEditor from "../../editor/Editable/Editor";
import { EditorProvider } from "../../editor/EditorProvider";
import { StatusEnum } from "../../interfaces/StatusEnum";
import { Contract } from "../../lib/Contract";
import { api } from "../../utils/api";
import { routes } from "../../utils/routes";
import {
  ContractContextInterface,
  ContractProvider,
  useContract,
} from "./ContractProvider";

const EditContract = () => {
  const { id } = useParams();
  const [contract, setContract] = useState<Contract | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (contract && contract.status === StatusEnum?.published) {
      navigate(
        generatePath(routes.overviewContracts, {
          id: contract.id,
        })
      );
    }
  }, [contract, navigate]);

  useEffect(() => {
    const getData = async (id: string) => {
      try {
        const { success, contract } = await api.getContractsDetails(id);
        if (success) {
          setContract(new Contract(contract));
        }
      } catch (e) {
        navigate("/contracts");
      }
    };

    if (id) {
      getData(id);
    }
  }, [id]);

  return contract && contract.documentId ? (
    <ContractProvider>
      <ContractEditor documentId={contract.documentId} />
    </ContractProvider>
  ) : (
    <>loading...</>
  );
};
export default EditContract;

const ContractEditor = ({ documentId }: { documentId: string }) => {
  const context: ContractContextInterface | null = useContract();
  return (
    <EditorProvider documentId={documentId} readOnly={false}>
      {context && <ClmEditor parentContext={context} />}
    </EditorProvider>
  );
};
