import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEditor } from "../../../editor/EditorProvider";
import WaterMark from "../../../editor/WaterMark";
import { MyEditable } from "../../../editor/Editable/MyEditable";
import { Leaf } from "../../../editor/Editable/Leaf";
import { RenderElement } from "../../../editor/Editable/Element";

const Overview = () => {
  const { id }: any = useParams();
  const context = useEditor();

  const [contents, setContents] = useState<any>([]);

  useEffect(() => {
    if (contents?.length > 0) {
      context?.setContents(contents);
    }
  }, [contents]);

  useEffect(() => {
    if (id?.length > 0) {
      getDocumentDetails();
    }
  }, [id]);

  const getDocumentDetails = async () => {
    const res = await context?.getDocumentDetails();
    setContents(res?.body);
  };

  const renderElement = useCallback(
    (props: any) => <RenderElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  return (
    <div
      className="overViewRight d-flex "
      style={{ pointerEvents: "none" }}
    >
      <div
        style={{
          pointerEvents: "none",
          minHeight: "800px",
        }}
      >
        <WaterMark />
        <MyEditable
          // placeholder="Type..."
          spellCheck
          autoFocus
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </div>
    </div>
  );
};

export default Overview;
