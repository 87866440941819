import { routes } from "../../utils/routes";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { CgMenuGridR } from "react-icons/cg";
import { useState } from "react";
import CustomAnalytics from "./CustomAnalytics";
import { FiFilter } from "react-icons/fi";
import AnalyticsFilter from "./AnalyticsFilter";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../ui/FailureToast";

function AnalyticsHeader({
  setIsUpdate,
  setFilterData,
  updatedValue,
  getMatricsData,
}: // captureScreenshot,
{
  setIsUpdate: Function;
  setFilterData: Function;
  updatedValue: any;
  getMatricsData: Function;
  // captureScreenshot: Function;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCustom, setOpenCustom] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const isActiveanalyticsSummary: boolean = !!matchPath(
    location.pathname,
    routes.analyticsSummary
  );

  const isActiveanalyticsContactValue: boolean = !!matchPath(
    routes.analyticsContactValue,
    location.pathname
  );
  const isActiveanalyticsTurnaround: boolean = !!matchPath(
    routes.analyticsTurnaround,
    location.pathname
  );
  const isActiveanalyticsTeams: boolean = !!matchPath(
    routes.analyticsTeams,
    location.pathname
  );

  return (
    <>
      <div className=" d-flex justify-content-between align-items-center">
        <h6 className=" fw-semibold">Dashboard</h6>
        {/* <h6
          className=" fw-semibold"
          onClick={() => captureScreenshot()}
          style={{ display: "none" }}
        >
          Download pdf
        </h6> */}

        <div className=" d-flex">
          <div className=" filter-z d-none d-lg-block">
            <div className=" position-relative">
              <div
                onClick={() => {
                  setOpenFilter(!openFilter);
                  setOpenCustom(false);
                }}
                className={`m-0 px-3 py-2 mobileFilterSortName border ${
                  openFilter ? "active" : ""
                }`}
              >
                Filter{" "}
                <span className="ps-2">
                  <FiFilter fontSize={22} />
                </span>
              </div>
              <div
                className={`dropdownFilter ${
                  openFilter ? "active" : "inactive"
                }`}
              >
                <AnalyticsFilter
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  setFilterData={setFilterData}
                />
              </div>
            </div>
          </div>

          <div className=" filter-z d-none d-lg-block ms-3">
            <div className=" position-relative">
              <div
                onClick={() => {
                  setOpenCustom(!openCustom);
                  setOpenFilter(false);
                }}
                className={`m-0 px-3 py-2 customizebtn ${
                  openCustom ? "active" : ""
                }`}
              >
                Customise{" "}
                <span className="ms-2">
                  {" "}
                  <CgMenuGridR fontSize={22} />
                </span>
              </div>
              <div
                className={`dropdownCustom ${
                  openCustom ? "active" : "inactive"
                }`}
              >
                <CustomAnalytics
                  updatedValue={updatedValue}
                  setOpenCustom={setOpenCustom}
                  setBodyMessage={setBodyMessage}
                  setShowSuccessToast={setShowSuccessToast}
                  setShowFailureToast={setShowFailureToast}
                  setIsUpdate={setIsUpdate}
                  getMatricsData={getMatricsData}
                  openCustom={openCustom}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "scroll" }} className=" mt-3">
        <div className="p-header mb-2" style={{ maxWidth: "450px" }}>
          <div style={{ minWidth: "90px" }}>
            <span
              className={` header-css me-4 ${
                isActiveanalyticsSummary ? " active" : ""
              }`}
              onClick={() => navigate(routes?.analyticsSummary)}
            >
              Summary
            </span>
          </div>
          <div
            style={{ minWidth: "120px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTurnaround ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsTurnaround)}
          >
            Turnaround Time
          </div>
          <div
            style={{ minWidth: "50px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTeams ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsTeams)}
          >
            <span>Teams</span>
          </div>
          <div
            style={{ minWidth: "110px" }}
            className={` header-css me-4 ${
              isActiveanalyticsContactValue ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsContactValue)}
          >
            Contract Value
          </div>
        </div>
      </div>

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
}

export default AnalyticsHeader;
