import axios from "axios";
import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";

const { REACT_APP_BASE_URL } = process.env;
export const BASE_URL = REACT_APP_BASE_URL;

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};

const generateAuthHeader = (config: any = {}) => {
  const token: string | null = localStorage.getItem("admin-auth-token");
  if (token && token.length > 0) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return config;
};

const post = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .post(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const put = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .put(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        return response.data;
      }
      return response;
    });
};

const get = async (url: string, _config: any = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return await axios.get(url, { ...config }).then((res: any) => res.data);
};

export const adminApi = {
  signIn: async (username: string, password: string) => {
    try {
      const res = await post(`${BASE_URL}/admin-auth/login`, {
        username,
        password,
      });
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      } else if (res?.statusCode === 401) {
        return {
          error: true,
          message: ["Incorrect Email Address or password."],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },
  currentAdmin: () => get(`${BASE_URL}/admins/me`),

  adminLogOut: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/admin-auth/logout`, params);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  AdminForgetPassword: (params: any) =>
    post(`${BASE_URL}/admins/forget_password`, params),

  AdminResetPassword: (params: any, token: string) =>
    post(`${BASE_URL}/admins/${token}/reset_password`, params),

  adminVerifyToken: (token: any) => {
    return get(`${BASE_URL}/admins/${token}/info`);
  },

  adminVerifyTokenpost: (token: any, values: any) =>
    post(`${BASE_URL}/admins/${token} /accept_invitation`, values),

  adminSignOut: (token: any) => {
    return get(`${BASE_URL}/admin-auth/logout`, token);
  },
  getMembers: (params: any) =>
    get(`${BASE_URL}/admins?${filterQueryParams(params)}`),
  addMember: (values: any) => post(`${BASE_URL}/admins/invite`, values),
  resendInvitation: (values: any, id: string) =>
    post(`${BASE_URL}/admins/resend-invite/${id}`, values),
  adminChangepassword: async (params: {
    old_password: string;
    password: string;
    confirm_password: string;
  }) => {
    try {
      const response = await post(
        `${BASE_URL}/admin-auth/changePassword`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  adminUpdateprofile: async (params: {
    first_name?: string;
    last_name?: string;
    contact_number?: string;
    department?: string;
    roles?: [];
  }) => {
    try {
      const response = await put(`${BASE_URL}/admins/me`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ...............roles and access...........
  getUserRoles: (role: string) => get(`${BASE_URL}/roles/${role}`),
  getUserRolesConfig: () => get(`${BASE_URL}/roles/config`),
  getRolesDetails: () => get(`${BASE_URL}/roles`),
  updateRoles: (params: any) => put(`${BASE_URL}/roles`, params),

  updateAdminProfileimage: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/admins/avatar`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getAdminOverallStats: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/overall_stats?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getAdminContractStats: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_overall_stats?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  ChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_stagewise?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  pieChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_stagewise?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  bulletChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/weekly_trend?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  bulletChartDataMonth: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/monthly_trend?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  stackedChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/EULA_acceptance?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  volumeChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_timeseries_volume_overall?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  valueChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_timeseries_value_overall?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  monthlyStackedChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/monthly_active_organization?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  loadStateWiseActiveOrganisationData: async (
    params: ITemplateFilterParams
  ) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/state_wise_active_organization?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  loadCityWiseActiveOrganisationData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/city_wise_active_organization?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  organisationBasedOnContractData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/top_ten_organization_by_volume?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  lineChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/monthly_trend?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  dountChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_value_volume_overall?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  publishChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_publish?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  negoChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_negotitation?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  approveChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_approve?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  executionChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_execution?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  signedChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_shared_to_sign?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  executionLineChartData: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/admin/stats/contract_TAT_initiation_to_execution?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  getAccounts: (params: any) => {
    return get(`${BASE_URL}/admins/oraganization?${filterQueryParams(params)}`);
  },
  getSignedContracts: (params: any) =>
    get(`${BASE_URL}/admins/contracts/signed?${filterQueryParams(params)}`),
  getContracts: (id: any, params: any) => {
    return get(
      `${BASE_URL}/admins/${id}/contracts?${filterQueryParams(params)}`
    );
  },
  getTemplates: (id: any, params: any) => {
    return get(
      `${BASE_URL}/admins/${id}/templates?${filterQueryParams(params)}`
    );
  },
  getRootFolder: (id: any) => {
    return get(`${BASE_URL}/admins/${id}/folders/root_folder`);
  },

  getFolderById: (
    accountId: string,
    id: string,
    params: ITemplateFilterParams
  ) => {
    return get(
      `${BASE_URL}/admins/${accountId}/folders/${id}?${filterQueryParams(
        params
      )}`
    );
  },
  getAccountMembers: (id: string, params: any) =>
    get(
      `${BASE_URL}/admins/oraganization/${id}/users?${filterQueryParams(
        params
      )}`
    ),
  getAccountInfo: (id: string, params: any) =>
    get(`${BASE_URL}/admins/oraganization/${id}`, params),
  addAccount: (values: any) => post(`${BASE_URL}/admins/oraganization`, values),

  updateAccountInfo: async (id: string, params: any) => {
    try {
      const response = await put(
        `${BASE_URL}/admins/oraganization/${id}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  blockAccount: async (id: string) => {
    try {
      const response = post(`${BASE_URL}/admins/oraganization/${id}/block`);
      return response;
    } catch (error) {
      return "";
    }
  },
  unBlockAccount: async (id: string) => {
    try {
      const response = post(`${BASE_URL}/admins/oraganization/${id}/unblock`);
      return response;
    } catch (error) {
      return "";
    }
  },
  getadminMemberDetails: (id: string) => get(`${BASE_URL}/admins/${id}`),
  editAdminMember: (id: string, params: any) =>
    put(`${BASE_URL}/admins/${id}`, params),

  blockMember: async (id: string) => {
    try {
      const response = post(`${BASE_URL}/admins/${id}/block`);
      return response;
    } catch (error) {
      return "";
    }
  },
  unBlockMember: async (id: string) => {
    try {
      const response = post(`${BASE_URL}/admins/${id}/unblock`);
      return response;
    } catch (error) {
      return "";
    }
  },
};
