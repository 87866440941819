import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveTreeMap } from "@nivo/treemap";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";
import { updateText } from "../../../utils/index";

function StackedAreaChart({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [graphDataOne, setGraphDataOne]: [[], Function] = useState([]);
  const [graphDataTwo, setGraphDataTwo]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loading, setLoading]: [boolean, Function] = useState(false);

  const handleCountSignedContract = async () => {
    setLoading(true);
    try {
      let response = await context?.handleCountSignedContractByType(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setNoData(false);
        setIsUpdate(false);
        isLoadingData[2] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          setGraphDataOne(response?.data);
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );
          setGraphType(selectedGraphType?.type);
          let responseContractType = await context?.handleContractType(
            filterData ? filterData : ""
          );
          setGraphDataTwo(responseContractType?.data);

          if (responseContractType?.data?.length > 0) {
            if (graphDataOne?.length === 0) {
              setLoading(false);
            } else if (responseContractType?.data?.length === 0) {
              setLoading(false);
            } else if (
              graphDataOne?.length !== 0 &&
              graphDataTwo?.length !== 0
            ) {
              setNoData(false);
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleCountSignedContract();
    setUpdatedMatricsList(updatedValue);
  }, [isUpdate, filterData, updatedValue]);

  return (
    <div>
      {loading ? (
        <span className="">loading....</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Circle Packing" ? (
                    <CirclePacking
                      graphDataOne={graphDataOne}
                      graphDataTwo={graphDataTwo}
                    />
                  ) : graphType === "Tree Map" ? (
                    <TreeMap
                      graphDataOne={graphDataOne}
                      graphDataTwo={graphDataTwo}
                    />
                  ) : graphType === "Stacked Chart" ? (
                    <StackedChart
                      graphDataOne={graphDataOne}
                      graphDataTwo={graphDataTwo}
                    />
                  ) : (
                    <CirclePacking
                      graphDataOne={graphDataOne}
                      graphDataTwo={graphDataTwo}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {updatedMatricsList?.filter(
                (item: any) => item.name === title && item?.is_enabled === true
              )?.length === 0 ? (
                <AnalyticsNoGraph />
              ) : (
                <>
                  {noData ? (
                    <AnalyticsNoDataFound />
                  ) : (
                    <>
                      {graphType === "Circle Packing" ? (
                        <CirclePacking
                          graphDataOne={graphDataOne}
                          graphDataTwo={graphDataTwo}
                        />
                      ) : graphType === "Tree Map" ? (
                        <TreeMap
                          graphDataOne={graphDataOne}
                          graphDataTwo={graphDataTwo}
                        />
                      ) : graphType === "Stacked Chart" ? (
                        <StackedChart
                          graphDataOne={graphDataOne}
                          graphDataTwo={graphDataTwo}
                        />
                      ) : (
                        <CirclePacking
                          graphDataOne={graphDataOne}
                          graphDataTwo={graphDataTwo}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default StackedAreaChart;

const StackedChart = ({
  graphDataOne,
  graphDataTwo,
}: {
  graphDataOne: [];
  graphDataTwo: [];
}) => {
  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };
  let transformedData2;
  let transformedData;
  if (graphDataOne?.length !== 0 && graphDataTwo?.length !== 0) {
    transformedData2 = graphDataOne?.map((item: any) => ({
      signed_count: item?.count,
      category: item?.category,
      signed_color: stringToHexColor(item?.category),
    }));

    transformedData = graphDataTwo?.map((item: any) => ({
      total_count: item?.count,
      category: item?.category,
      category_color: stringToHexColor(item?.category),
    }));
  }

  const convertData = (data1: any, data2: any) => {
    const convertedData: any = {};

    data1?.forEach((item: any) => {
      const { total_count, category, category_color } = item;
      if (category) {
        if (!convertedData[category]) {
          convertedData[category] = {};
        }
        convertedData[category]["Total number of contracts"] = total_count;
        convertedData[category]["category color"] = category_color || "";
      }
    });

    data2?.forEach((item: any) => {
      const { signed_count, category, signed_color } = item;
      if (category) {
        if (!convertedData[category]) {
          convertedData[category] = {};
        }
        convertedData[category]["Number of signed contracts"] = signed_count;
        convertedData[category]["signed color"] = signed_color || "";
      }
    });

    const result = Object.keys(convertedData).map((category) => ({
      country: category?.substring(0, 3),
      category: category,
      ...convertedData[category],
    }));

    return result;
  };

  const convertedData = convertData(transformedData, transformedData2);

  return (
    <div style={{ height: "400px" }}>
      <ResponsiveBar
        data={convertedData}
        keys={["Total number of contracts", "Number of signed contracts"]}
        indexBy="country"
        margin={{ top: 50, right: 190, bottom: 50, left: 60 }}
        padding={0.5}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "Category",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of signed contracts",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`
        }
        tooltip={(data: any) => (
          <div
            style={{
              padding: 12,
              color: data?.color,
              background: "#222222",
            }}
          >
            <strong>
              {updateText(data?.id)} - {data?.data.category?.replace("_", " ")}:{" "}
              {data?.value}
            </strong>
          </div>
        )}
      />
    </div>
  );
};

// ........................circle packing..............

const CirclePacking = ({
  graphDataOne,
  graphDataTwo,
}: {
  graphDataOne: [];
  graphDataTwo: [];
}) => {
  function getRandomColor() {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 70 + 30);
    const lightness = Math.floor(Math.random() * 40 + 30);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  function convertToHierarchy(graphDataTwo: any, graphDataOne: any) {
    const hierarchy: any = {
      name: "Total Contracts",
      color: getRandomColor(),
      children: [],
    };

    const categories: any = {};
    if (graphDataOne?.length > 0 && graphDataTwo?.length > 0) {
      graphDataOne.forEach((item: any) => {
        const categoryColor = getRandomColor();
        categories[item.category] = {
          name: item.category,
          color: categoryColor,
          children: [],
        };
      });

      graphDataTwo?.forEach((item: any) => {
        if (categories[item?.category]) {
          categories[item?.category].children.push({
            name: item?.category.substr(0, 3),
            title: item?.category,
            color: getRandomColor(),
            loc: item?.count,
          });
        }
      });
    }

    Object.values(categories).forEach((category) => {
      hierarchy.children.push(category);
    });

    return hierarchy;
  }

  const newData = convertToHierarchy(graphDataTwo, graphDataOne);

  const CustomTooltip = ({ node }: any) => {
    return (
      <div
        style={{
          background: "white",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "4px",
        }}
      >
        <strong>
          {!node.data.loc ? "Total signed contract" : node.data.title}
          {!node.data.loc && ":100%"}
        </strong>
        {node.data.loc && (
          <>
            <br />
            Signed Contract: {node.data.loc}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveCirclePacking
          data={graphDataOne?.length > 0 && graphDataTwo?.length > 0 && newData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          id="name"
          value="loc"
          padding={4}
          enableLabels={true}
          labelsFilter={(n) => 2 === n.node.depth}
          labelsSkipRadius={10}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.5]],
          }}
          leavesOnly
          defs={[
            {
              id: "lines",
              type: "patternLines",
              background: "none",
              color: "inherit",
              rotation: -45,
              lineWidth: 5,
              spacing: 8,
            },
          ]}
          fill={[
            {
              match: {
                depth: 1,
              },
              id: "lines",
            },
          ]}
          tooltip={(node: any) => <CustomTooltip node={node} />}
        />
      </div>
    </>
  );
};

// .............................tree chart...................
const TreeMap = ({
  graphDataOne,
  graphDataTwo,
}: {
  graphDataOne: [];
  graphDataTwo: [];
}) => {
  const updatedData1 = graphDataTwo.map((item: any) => ({
    ...item,
    category: item.category ? item.category.substr(0, 3) : "N/A",
    title: item.category,
  }));

  const updatedData2 = graphDataOne.map((item: any) => ({
    ...item,
    category: item.category ? item.category.substr(0, 3) : "N/A",
    title: item.category,
  }));
  function getRandomColor() {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 70 + 30);
    const lightness = Math.floor(Math.random() * 40 + 30);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  function convertToHierarchy(updatedData1: any, updatedData2: any) {
    const hierarchy: any = {
      name: "Total Contracts",
      color: getRandomColor(),
      children: [],
    };

    const categories: any = {};

    updatedData1.forEach((item: any) => {
      const categoryColor = getRandomColor();
      categories[item.category] = {
        name: item.category,
        color: categoryColor,
        children: [],
      };
    });

    updatedData2.forEach((item: any) => {
      if (categories[item.category]) {
        categories[item.category].children.push({
          name: item.title,
          title: item.category,
          color: getRandomColor(),
          loc: item.count,
        });
      }
    });

    Object.values(categories).forEach((category) => {
      hierarchy.children.push(category);
    });

    return hierarchy;
  }

  const newData: any = convertToHierarchy(updatedData1, updatedData2);

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveTreeMap
          data={newData}
          identity="name"
          value="loc"
          valueFormat=".02s"
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          labelSkipSize={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.2]],
          }}
          parentLabelPosition="top"
          parentLabelTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.1]],
          }}
        />
      </div>
    </>
  );
};
