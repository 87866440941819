import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useContract } from "../modules/Contracts/ContractProvider";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { StatusEnum } from "../interfaces/StatusEnum";

const CollaboratorCheckbox = ({
  setAccessOption,
  setNotifyPeople,
  sendMessage,
  setSendMessage,
  accessOption,
}: {
  setAccessOption: Function;
  setNotifyPeople: Function;
  sendMessage: string;
  setSendMessage: Function;
  accessOption: string;
}) => {
  const context = useContract();
  const authContext = useAuthentication();

  return (
    <>
      <div className=" d-flex justify-content-between  pb-3 py-3">
        <div className="py-3">
          {context?.data?.createdBy?.sub !== authContext?.currentUser?.sub &&
          context?.data?.status === StatusEnum?.pending_negotitation ? (
            ""
          ) : (
            <span>
              <input
                type="radio"
                defaultChecked={true}
                checked={accessOption === "EDITOR"}
                className="form-check-input"
                onChange={(e) => {
                  if (e?.target?.checked) {
                    setAccessOption("EDITOR");
                  }
                }}
              />
              <span className="ps-2 me-3">Editor</span>
            </span>
          )}

          <span>
            <input
              type="radio"
              checked={
                context?.data?.createdBy?.sub !==
                  authContext?.currentUser?.sub &&
                context?.data?.status === StatusEnum?.pending_negotitation
                  ? true
                  : accessOption === "VIEWER"
              }
              className="form-check-input"
              onChange={(e) => {
                if (e?.target?.checked) {
                  setAccessOption("VIEWER");
                }
              }}
            />
            <span className="ps-2 me-3">Viewer</span>
          </span>
        </div>

        <div className=" d-flex align-items-center">
          <div>
            <input
              type="checkbox"
              className="custom-control-input"
              onChange={(e) => {
                setNotifyPeople(e?.target?.checked);
              }}
            />
          </div>
          <h6 className="mb-1 px-2">Notify People</h6>
        </div>
      </div>
      <div className="textAreafrom">
        <FloatingLabel controlId="floatingTextarea2" label="">
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            onChange={(e) => setSendMessage(e?.target?.value)}
            value={sendMessage}
          />
        </FloatingLabel>
      </div>
    </>
  );
};

export default CollaboratorCheckbox;
