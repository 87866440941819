import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
// import { IoMdAdd } from "react-icons/io";
import noData from "../assets/noData.png";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "../utils/routes";
import { useTemplates } from "../modules/Templates/ListTemplatesProvider";
import { Template } from "../lib/Template";
import { matchPath, useLocation } from "react-router";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";

const initialValue = { name: "Untitled Document", description: "" };

function NoDataFound({
  isActive,
  search,
}: {
  isActive?: string;
  search?: any;
}) {
  const navigate = useNavigate();
  const context = useTemplates();
  const location = useLocation();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();

  const [loader, setLoader] = useState<boolean>(false);

  const createDocuments = async () => {
    try {
      setLoader(true);
      const template: Template | null | undefined =
        await context?.createDocuments(initialValue);
      if (template) {
        navigate(generatePath(routes.editTemplate, { id: template?.id }));
        setLoader(false);
      }
    } catch (error) {}
  };

  const isActiveTemplates: boolean =
    !!matchPath(location.pathname, routes.templates) ||
    !!matchPath(routes.templatesByDepartment, location.pathname);
  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.TEMPLATES?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      <div className=" mt-2 mx-2 contract-title ">
        {!search && (
          <span
            className="breadcrumbs-nodata"
            onClick={async () => {
              await context?.changeFilter({
                ...context.filterParams,
                search: "",
                department: "",
                collaborators: "",
                status: "",
              });
              navigate(routes.templates);
            }}
          >
            {isActiveTemplates && "Templates"}
          </span>
        )}
      </div>
      <div className="File404">
        <div className="d-flex flex-column align-items-center">
          <div className={"font404 d-flex justify-content-center"}>
            <img src={noData} alt="no-data" width={200} />
          </div>
          <div className="no-data-found">No Data Found</div>
          <div>
            {location.pathname !== "/members" && (
              <>
                {!search && (
                  <div className="p-2 bd-highlight  create-template-btn">
                    {isActive === "createContractPopup" ? (
                      <>
                        {roles === "CREATE" && (
                          <>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="new-contact-btn create-template contract-css ms-lg-3"
                                onClick={() => {
                                  createDocuments();
                                }}
                                id="dropdown-basic"
                              >
                                Create Document
                                {loader ? <span className="loader"></span> : ""}
                              </Dropdown.Toggle>
                            </Dropdown>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {roles === "CREATE" && (
                          <>
                            {" "}
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                className="new-contact-btn create-template contract-css ms-lg-3"
                                onClick={() => {
                                  createDocuments();
                                }}
                                id="dropdown-basic"
                              >
                                Create Template
                                {loader ? <span className="loader"></span> : ""}
                              </Dropdown.Toggle>
                            </Dropdown>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NoDataFound;
