import { ResponsiveBar } from "@nivo/bar";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { useEffect, useState } from "react";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { updateText } from "../../../../../utils";

const ContractValueMOM = ({ filterData }: any) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [[], Function] = useState([]);

  const ChartDataDetails = async () => {
    try {
      let response = await context?.valueMOMBarDetails(
        filterData ? filterData : ""
      );

      if (response?.success) {
        const formattedData: any = [];

        response?.data.forEach((entry: any) => {
          const formattedEntry: any = { date: entry.date };
          let noCategoryCount: any = 0;
          entry.total_value.forEach((categoryEntry: any) => {
            const category = categoryEntry.category || "No category";
            const count = categoryEntry.total_value || 0;
            formattedEntry[category] = count;
            if (category === "No category") {
              noCategoryCount += count;
            }
          });
          if (noCategoryCount > 0) {
            formattedEntry["No category"] = noCategoryCount;
          }

          formattedData.push(formattedEntry);
        });

        const formattedResult: any = { data: formattedData };

        const keys: any = [
          "Employment Contract",
          "MOU (memorandum of understanding)",
          "MSA (Master Services Agreement)",
          "NDA (Non-disclosure agreement)",
          "No category",
          "SLA (service-level agreement)",
        ];
        const data = formattedResult?.data?.map((entry: any) => {
          const chartEntry: any = { country: entry?.date };
          keys.forEach((key: any) => {
            chartEntry[key] = entry[key] || 0;
          });
          return chartEntry;
        });

        setChartData(
          response?.data?.reduce(
            (sum: any, countItem: any) => sum + countItem?.total_value?.length,
            0
          ) > 0
            ? data
            : []
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);

  return (
    <>
      <div style={{ height: "390px" }}>
        {chartData?.length === 0 ? (
          <AnalyticsNoDataFound />
        ) : (
          <>
            {" "}
            <ResponsiveBar
              data={chartData}
              keys={[
                "Employment Contract",
                "MOU (memorandum of understanding)",
                "MSA (Master Services Agreement)",
                "NDA (Non-disclosure agreement)",
                "No category",
                "SLA (service-level agreement)",
              ]}
              indexBy="country"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              tooltip={(datum: any) => {
                return (
                  <div style={{ color: "black" }}>
                    <span>
                      {updateText(datum?.label?.replace("_", " "))}:
                      {parseInt(datum?.formattedValue)?.toLocaleString(
                        "en-IN",
                        {
                          style: "currency",
                          currency: "INR",
                        }
                      )}
                    </span>
                  </div>
                );
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ContractValueMOM;
