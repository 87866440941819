import { Button, Modal, Table } from "react-bootstrap";
import { useEditor } from "../../editor/EditorProvider";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import AddVariableItem from "./AddVariableItem";
import VariableItem from "./VariableItem";
import { VariablesProvider, useVariables } from "./VariableProvider";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import SuccessToast from "../../ui/SuccessToast";
import { MdCheckCircle } from "react-icons/md";

interface VariableModalProps {
  show: boolean;
  setShow: Function;
}

const SaveButton = ({ closeModal }: { closeModal: Function }) => {
  const context = useVariables();
  const editorContext = useEditor();
  const location = useLocation();
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  return (
    <>
      {location?.pathname.includes("contracts") && context && editorContext && (
        <Button
          className="savebtn"
          // disabled={context?.canSave()}
          onClick={async () => {
            setShowSuccessToast(true);
            await context.save();
            await editorContext.getVariables();
            closeModal();
          }}
        >
          Update
        </Button>
      )}
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={"Variable Updated"}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
    </>
  );
};

const VariablesModal = ({ show, setShow }: VariableModalProps) => {
  const editorContext = useEditor();
  const location = useLocation();
  const isTemplate: boolean = location.pathname.includes("templates");

  return (
    editorContext && (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <b>Variables </b>
        </Modal.Header>
        {editorContext && (
          <VariablesProvider
            variables={editorContext?.variables}
            documentId={editorContext.docId}
          >
            <>
              <Modal.Body className="p-4">
                {location?.search !== "?suggestion=true" && (
                  <>
                    <AddVariableItem />
                    <hr className="mt-2" />
                  </>
                )}

                <div
                  style={{
                    height: "50vh",
                    overflowY: "scroll",
                  }}
                >
                  <Table
                    hover
                    borderless
                    style={{ maxHeight: "50px" }}
                    className="scroll-y"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th colSpan={isTemplate ? 2 : 1}>Format</th>
                        {!isTemplate && <th>Value</th>}
                        {location?.search !== "?suggestion=true" && (
                          <>
                            <th>Action</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {editorContext.variables.map(
                        (variable: IVariableResponse) => (
                          <VariableItem variable={variable} key={variable.id} />
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
              {location?.search !== "?suggestion=true" && (
                <>
                  <Modal.Footer>
                    <SaveButton closeModal={() => {}} />
                  </Modal.Footer>
                </>
              )}
            </>
          </VariablesProvider>
        )}
      </Modal>
    )
  );
};

export default VariablesModal;
