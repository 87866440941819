import { isMobileOnly } from "react-device-detect";
import { useAnalytics } from "../AnalyticsProvider";
import { useEffect, useState } from "react";
// import { IOverAllStatsResponse } from "../../../interfaces/response/IOverAllStatsResponse";
import { useLocation } from "react-router-dom";

const OverallStats = ({ setIsLoadingData, isLoadingData }: any) => {
  const context = useAnalytics();
  const location = useLocation();
  const [overallStatsData, setOverallStatsData]: [any, Function] = useState();

  const handleOverAllStatsData = async () => {
    try {
      let response = await context?.handleOverallStats();
      if (response?.success) {
        setOverallStatsData(response?.data);
        isLoadingData[0] = true;
        setIsLoadingData([...isLoadingData]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (location.pathname.includes("summary")) {
      handleOverAllStatsData();
    }
  }, []);

  return (
    <>
      {overallStatsData && (
        <>
          <div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center  my-2 flex-wrap">
              <h6 className=" fw-semibold   m-0 my-2 "> Active contracts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["active_contracts"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className=" fw-semibold   m-0 my-2"> Drafts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["draft_contracts"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className=" fw-semibold   m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts to be approved
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["contracts_to_be_approved"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className=" fw-semibold   m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts in negotiation
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["contracts_in_negotiation"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className=" fw-semibold   m-0 my-2"> Signatures pending</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["signatures_pending"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className=" fw-semibold   m-0 my-2"> Contracts signed</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["signed_count"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className=" fw-semibold   m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts signed in the last 3 months
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["contracts_signed_in_last_three_months"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className=" fw-semibold   m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Renewals expected in the next 3 months
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {0}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className=" fw-semibold   m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Average contract cycle time for executed contracts
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData?.average_contract_cycle?.[0]?.averageDays}{" "}
                days
              </h6>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OverallStats;
