import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";

function TurnaroundTimeForApprovals({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [approvalsData, setApprovalData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const handlegetContractsByCounterpartyData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleTurnaroundTimeForApproval(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[2] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          let data = response?.data;
          setNoData(false);
          setApprovalData(data);
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );
          setGraphType(selectedGraphType?.type);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    setUpdatedMatricsList(updatedValue);
    handlegetContractsByCounterpartyData();
  }, [isUpdate, updatedValue]);

  return (
    <>
      {loader ? (
        <span>loading...</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Tree Map" ? (
                    <TreeMapChart data={approvalsData} />
                  ) : graphType === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={approvalsData} />
                  ) : graphType === "Pie" ? (
                    <PieChart data={approvalsData} />
                  ) : graphType === "Donut" ? (
                    <DonutChart data={approvalsData} />
                  ) : graphType === "Vertical Bar" ? (
                    <VerticalBarChartData data={approvalsData} />
                  ) : (
                    <TreeMapChart data={approvalsData} />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {updatedMatricsList?.filter(
                (item: any) => item.name === title && item?.is_enabled === true
              )?.length === 0 ? (
                <AnalyticsNoGraph />
              ) : (
                <>
                  {noData ? (
                    <AnalyticsNoDataFound />
                  ) : (
                    <>
                      {graphType === "Tree Map" ? (
                        <TreeMapChart data={approvalsData} />
                      ) : graphType === "Horizontal Bar" ? (
                        <HorizontailBarChartData data={approvalsData} />
                      ) : graphType === "Pie" ? (
                        <PieChart data={approvalsData} />
                      ) : graphType === "Donut" ? (
                        <DonutChart data={approvalsData} />
                      ) : graphType === "Vertical Bar" ? (
                        <VerticalBarChartData data={approvalsData} />
                      ) : (
                        <TreeMapChart data={approvalsData} />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default TurnaroundTimeForApprovals;

const TreeMapChart = ({ data }: { data: [] }) => {
  const treemapData = {
    id: "Turnaround Time For Approvals",
    children:
      data &&
      data?.map((item: any) => ({
        id: item?.user?.name,
        value: item?.count?.reduce(
          (sum: any, countItem: any) => sum + countItem?.count,
          0
        ),
      })),
  };

  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsiveTreeMap
          data={treemapData}
          identity="id"
          value="value"
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          label={(node: any) => `${node.id}: ${node.formattedValue}`}
          labelSkipSize={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.2]] }}
        />
      </div>
    </>
  );
};

const HorizontailBarChartData = ({ data }: { data: [] }) => {
  let value = data;

  const CustomTooltip = ({ id, value }: any) => {
    return (
      <>
        {" "}
        <div
          style={{
            padding: "10px",
            background: "white",
            border: "1px solid #ccc",
          }}
        >
          <strong>{id}</strong>:Number of contracts: {value}
        </div>
      </>
    );
  };

  return (
    <div style={{ height: "390px" }}>
      <ResponsiveBar
        data={value.flatMap(({ user, count }: any) =>
          count.map(({ hours, count }: any) => ({
            id: user.name,
            [hours]: count,
          }))
        )}
        keys={["0-3 hours", "3-6 hours", "6-9 hours", ">9 hours"]}
        indexBy="id"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        layout="horizontal"
        colors={{ scheme: "category10" }}
        borderRadius={4}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of contracts",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        tooltip={({ id, value }) => <CustomTooltip id={id} value={value} />}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
      {/* <ResponsiveBar
        data={treemapData?.children}
        keys={["value"]}
        indexBy="id"
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        padding={0.5}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        layout="horizontal"
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -50,
        }}
        labelTextColor="inherit:darker(1.2)"
        colors={{ datum: "data.color" }}
        tooltip={({ value }) => <strong>count:{value}</strong>}
      /> */}
    </div>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  let value = data;

  const CustomTooltip = ({ id, value }: any) => {
    return (
      <>
        {" "}
        <div
          style={{
            padding: "10px",
            background: "white",
            border: "1px solid #ccc",
          }}
        >
          <strong>{id}</strong>:Number of contracts: {value}
        </div>
      </>
    );
  };

  return (
    <div style={{ height: "390px" }}>
      <ResponsiveBar
        data={value.flatMap(({ user, count }: any) =>
          count.map(({ hours, count }: any) => ({
            id: user.name,
            [hours]: count,
          }))
        )}
        keys={["0-3 hours", "3-6 hours", "6-9 hours", ">9 hours"]}
        indexBy="id"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={{ scheme: "category10" }}
        borderRadius={4}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Users",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number of contracts",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        tooltip={({ id, value }) => <CustomTooltip id={id} value={value} />}
        legends={[
          {
            dataFrom: "keys",
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
      {/* <ResponsiveBar
        data={treemapData?.children}
        keys={["value"]}
        indexBy="id"
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        padding={0.5}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        layout="horizontal"
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -50,
        }}
        labelTextColor="inherit:darker(1.2)"
        colors={{ datum: "data.color" }}
        tooltip={({ value }) => <strong>count:{value}</strong>}
      /> */}
    </div>
  );
};

const PieChart = ({ data }: { data: [] }) => {
  const treemapData: any = {
    id: "root",
    children: data?.map((item: any) => ({
      id: item?.user?.name,
      value: item?.count?.reduce(
        (sum: any, countItem: any) => sum + countItem?.count,
        0
      ),
    })),
  };

  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={treemapData?.children}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          padAngle={0.7}
          cornerRadius={3}
          colors={{ scheme: "category10" }} // You can choose different color schemes
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#333333"
          arcLabelsRadiusOffset={0.7}
          arcLinkLabelsDiagonalLength={10}
          arcLinkLabelsThickness={1}
          arcLinkLabelsColor={{ from: "color" }}
          animate={true}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: [] }) => {
  const treemapData: any = {
    id: "root",
    children:
      data &&
      data?.map((item: any) => ({
        id: item?.user?.name,
        value: item?.count?.reduce(
          (sum: any, countItem: any) => sum + countItem?.count,
          0
        ),
      })),
  };

  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={treemapData?.children}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={{ scheme: "category10" }} // You can choose different color schemes
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor="#333333"
          arcLabelsRadiusOffset={0.7}
          arcLinkLabelsDiagonalLength={10}
          arcLinkLabelsThickness={1}
          arcLinkLabelsColor={{ from: "color" }}
          animate={true}
        />
      </div>
    </>
  );
};
