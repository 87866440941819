import isUrl from "is-url";
// import { MdLink } from "react-icons/md";
import { Editor, Range, Transforms } from "slate";
// import { useFocused, useSelected } from "slate-react";

export interface LinkEditor extends Editor {
  insertData: (data: any) => void;
}

export const withLinks = <T extends Editor>(editor: T): T & LinkEditor => {
  const e = editor as T & LinkEditor;

  const { insertData, insertText, isInline } = e;

  e.isInline = (element: any) => {
    return element.type === "link" ? true : isInline(element);
  };

  e.insertText = (text: string): void => {
    if (text && isUrl(text)) {
      wrapLink(editor, text, text);
    } else {
      insertText(text);
    }
  };

  e.insertData = (data: DataTransfer): void => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text, text);
    } else {
      insertData(data);
    }
  };

  return e;
};

export const insertLink = (
  editor: Editor,
  href: string,
  text: string
): void => {
  if (editor.selection) {
    if (text) {
      wrapLink(editor, href, text);
    } else {
      wrapLink(editor, href, href);
    }
  }
};

export const isLinkActive = (editor: Editor): boolean => {
  const [link]: any = Editor.nodes(editor, {
    match: (n: any) => n.type === "link",
  });
  return !!link;
};

export const unwrapLink = (editor: Editor): void => {
  Transforms.unwrapNodes(editor, { match: (n: any) => n.type === "link" });
};

export const wrapLink = (editor: Editor, href: string, text: string): void => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link:any = {
    type: "link",
    href,
    children: isCollapsed ? [{ text: text ? text : href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};
