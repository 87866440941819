import { useLocation } from "react-router-dom";
import Summary from "./Summary/Summary";
import TurnAroundTime from "./TurnaroundTime/TurnAroundTime";
import Teams from "./Teams/Teams";
import ContractValue from "./ContractValue/ContractValue";

function AnalyticsOverview({
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  componentRef,
}: {
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: boolean;
  updatedValue: any;
  componentRef: any;
}) {
  const location = useLocation();
  return (
    <>
      <div ref={componentRef}>
        {location.pathname.includes("summary") && (
          <Summary
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            filterData={filterData}
            updatedValue={updatedValue}
          />
        )}
        {location.pathname.includes("turnaround") && (
          <TurnAroundTime
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            filterData={filterData}
            updatedValue={updatedValue}
          />
        )}
        {location.pathname.includes("teams") && (
          <Teams
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            filterData={filterData}
            updatedValue={updatedValue}
          />
        )}
      </div>

      {location.pathname.includes("contact") && (
        <ContractValue
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          updatedValue={updatedValue}
          filterData={filterData}
        />
      )}
    </>
  );
}

export default AnalyticsOverview;
