import { useState } from "react";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import InviteDocumentCollaborator from "../ui/InviteDocumentCollaborator";
import { MdPersonAddAlt } from "react-icons/md";
import { BsPersonAdd } from "react-icons/bs";
import { Button } from "react-bootstrap";

const DocumentCollaborators = ({
  context,
  isActiveNegotiationWorkflow,
}: {
  context: TemplateContextInterface | ContractContextInterface;
  outline?: boolean;
  isActiveNegotiationWorkflow?: any;
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className=" d-flex align-items-center">
      <div className=" d-flex align-items-center">
        <Button
          onClick={() => {
            setShow(true);
          }}
          style={{ color: "#3D70B2", padding: "6px 10px" }}
          className=" d-none d-lg-block cancelbtn me-3 "
        >
          <div className=" d-flex align-items-center ">
            <div className="me-1">
              <MdPersonAddAlt id="Add Collaborators" fontSize={24} />
            </div>
            <h6
              className=" m-0 fw-semibold"
              style={{ fontSize: "15px", minWidth: "145px" }}
            >
              Add Collaborators
            </h6>
          </div>
        </Button>
      </div>
      <div
        onClick={() => {
          setShow(true);
        }}
        className=" d-lg-none "
      >
        <BsPersonAdd fontSize={24} color="#516173" />
      </div>
      {show}
      <InviteDocumentCollaborator
        context={context}
        show={show}
        setShow={setShow}
        isActiveNegotiationWorkflow={isActiveNegotiationWorkflow}
      />
    </div>
  );
};

export default DocumentCollaborators;
