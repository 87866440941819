import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Template } from "../../../lib/Template";
import { ListTemplateContextInterface } from "../ListTemplatesProvider";
import { ListContractContextInterface } from "../../Contracts/ListContractProvider";
import { useLocation } from "react-router-dom";
import { FaFolder } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import {
  MdOutlineArrowForwardIos,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { IFolderResponse } from "../../../interfaces/response/IFolderResponse";
import { BsThreeDots } from "react-icons/bs";

function MoveToFolderPopup({
  show,
  onHide,
  // selectedValues,
  privateFolderList,
  publicFolderList,
  setShowSuccessToast,
  setBodyMessage,
  context,
  setPrivateFolderList,
  setPublicFolderList,
  onHandleRootFolder,
  selectedId,
}: {
  show: boolean;
  onHide: Function;
  // selectedValues: Template[];
  privateFolderList: Template[] | undefined;
  publicFolderList: Template[] | undefined;
  setShowSuccessToast: Function;
  setBodyMessage: Function;
  setPrivateFolderList: Function;
  setPublicFolderList: Function;
  onHandleRootFolder: Function;
  selectedId: any;
  context: ListTemplateContextInterface | ListContractContextInterface | null;
}) {
  const location = useLocation();
  const [folderPath, setFolderPath]: [[], Function] = useState([]);
  const [onFolderCLick, setFolderClick] = useState<any>([]);

  const [activePathName, setActivePathName]: [string, Function] = useState("");

  const [isPathActive, setIsPathActive]: [boolean, Function] = useState(false);

  const [activeFolder, setActiveFolder]: [string, Function] = useState("");
  const [folderActiveId, SetFolderActiveId]: [any, Function] = useState();

  const onHandleMoveToFolder = async () => {
    try {
      let requestBody;
      if (location.pathname.includes("contracts")) {
        requestBody = {
          contract_ids: [selectedId?.id],
        };
      } else {
        requestBody = {
          template_ids: [selectedId?.id],
        };
      }

      let response = await context?.handleMoveToFolderTemplate(
        requestBody,
        folderActiveId
      );

      if (response) {
        onHide();
        setShowSuccessToast(true);
        setBodyMessage(
          response?.data?.message
            ? response?.data?.message
            : response?.data?.error?.[0]
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (folderActiveId) {
      onHandleMoveToFolder();
    }
  }, [folderActiveId]);

  const handleFolderById = async (id: any) => {
    try {
      let response = await context?.handleFolderById(id);
      if (response?.folder?.sub_folders) {
        setFolderPath(response?.folder?.path);
        setFolderClick(response?.folder);
        setActivePathName(response?.folder?.name);

        let privateList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PRIVATE";
          }
        );
        let publicFoldersList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PUBLIC";
          }
        );

        setPrivateFolderList(privateList);
        setPublicFolderList(publicFoldersList);
      }
    } catch (error) {}
  };

  return (
    <Modal show={show} centered onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Move to folder</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="d-flex my-2 mx-2 ">
          <h6
            className="contract-title pb-1 m-0"
            onClick={() => {
              onHandleRootFolder();
              setFolderPath([]);
              setActivePathName("");
            }}
          >
            {activeFolder !== "" && (
              <>
                <div>
                  <IoArrowBack
                    fontSize={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActiveFolder("");
                      onHandleRootFolder();
                      setActivePathName("");
                      setFolderPath([]);
                      setFolderClick([]);
                    }}
                  />
                  <span className="ms-2 fw-semibold">{activeFolder}</span>
                </div>
              </>
            )}
          </h6>
          {folderPath?.length >= 5 ? (
            <>
              <MdOutlineKeyboardArrowRight color="black" fontSize={"20px"} />

              <Dropdown>
                <Dropdown.Toggle
                  variant="default"
                  id="dropdown-basic"
                  style={{ border: "none" }}
                >
                  <BsThreeDots />
                </Dropdown.Toggle>

                <Dropdown.Menu className="">
                  {folderPath?.map((path: any) => {
                    return (
                      <>
                        {path?.name !== "/" && (
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              handleFolderById(path?.id);
                              setIsPathActive(true);
                              setActivePathName(path?.name);
                            }}
                          >
                            {path?.name}
                          </Dropdown.Item>
                        )}
                      </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              {folderPath?.map((path: any) => {
                return (
                  <>
                    {path?.name === "/" ? (
                      ""
                    ) : (
                      <>
                        <MdOutlineKeyboardArrowRight
                          color="black"
                          fontSize={"20px"}
                        />
                        <span
                          className="contract-title pb-1 m-0"
                          onClick={() => {
                            handleFolderById(path?.id);
                            setIsPathActive(true);
                            setActivePathName(path?.name);
                          }}
                        >
                          {path?.name}
                        </span>
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
          {onFolderCLick?.id ? (
            <>
              <MdOutlineKeyboardArrowRight color="black" fontSize={"20px"} />
              <h6 className="contract-title pb-1 m-0">
                {!isPathActive ? onFolderCLick?.name : activePathName}
              </h6>
            </>
          ) : (
            ""
          )}
        </div>

        {/* folder */}
        {activeFolder === "" ? (
          <>
            <div
              className="d-flex  movesc p-2 myDIV"
              onClick={() => setActiveFolder("Private")}
            >
              <div className="d-flex">
                <div className="myDIV">
                  <FaFolder fontSize={20} />
                  <span className="ms-2">Private</span>
                </div>

                <div className="hide">
                  Move <MdOutlineArrowForwardIos />
                </div>
              </div>
            </div>
            <div
              className="d-flex  movesc p-2 myDIV"
              onClick={() => setActiveFolder("Public")}
            >
              <div className="d-flex">
                <div className="myDIV">
                  <FaFolder fontSize={20} />
                  <span className="ms-2">Public</span>
                </div>

                <div className="hide">
                  Move <MdOutlineArrowForwardIos />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {activeFolder === "Private" ? (
              <>
                {privateFolderList?.length === 0
                  ? "No folder found"
                  : privateFolderList?.map((privateList: any) => {
                      return (
                        <>
                          <div
                            className="d-flex  movesc p-2 myDIV mb-2"
                            onClick={() => {
                              privateList?.department ===
                                selectedId?.department &&
                                handleFolderById(privateList?.id);
                            }}
                            style={{
                              background:
                                privateList?.department !==
                                selectedId?.department
                                  ? "rgb(233 196 196)"
                                  : "",
                            }}
                          >
                            <div className="d-flex  position-relative ">
                              <div className="myDIV d-flex align-items-center">
                                <FaFolder fontSize={20} />
                                <span className="ms-2">
                                  {privateList?.name}
                                </span>
                              </div>
                              {privateList?.department ===
                                selectedId?.department && (
                                <>
                                  <div
                                    className="hide_folder"
                                    onClick={() => {
                                      privateList?.department ===
                                        selectedId?.department &&
                                        SetFolderActiveId(privateList?.id);
                                    }}
                                  >
                                    Move
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
              </>
            ) : (
              <>
                {publicFolderList?.length === 0
                  ? "No folder found"
                  : publicFolderList?.map((publicList: any) => {
                      return (
                        <>
                          <div
                            className="d-flex  movesc p-2 myDIV mb-2"
                            style={{
                              background:
                                publicList?.department !==
                                selectedId?.department
                                  ? "rgb(233 196 196)"
                                  : "",
                            }}
                            onClick={() => {
                              publicList?.department ===
                                selectedId?.department &&
                                handleFolderById(publicList?.id);
                            }}
                          >
                            <div className="d-flex">
                              <div className="myDIV">
                                <FaFolder fontSize={20} />
                                <span className="ms-2">{publicList?.name}</span>
                              </div>
                              {publicList?.department ===
                                selectedId?.department && (
                                <div
                                  className="hide_folder"
                                  onClick={() =>
                                    SetFolderActiveId(publicList?.id)
                                  }
                                >
                                  Move
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default MoveToFolderPopup;
