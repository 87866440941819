import { useLocation } from "react-router-dom";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { useVariables } from "./VariableProvider";

const FloatVariableInput = ({
  variable,
  onChange,
  value,
}: {
  variable?: IVariableResponse;
  onChange?: Function;
  value?: string;
}) => {
  const context = useVariables();

  const params = useLocation();

  const selectedVariable = context?.values.find(
    ({ id }) => variable?.id === id
  );

  const triggerOnChange = (e: any) => {
    if (variable) {
      context?.update(variable.id, {
        value: e.target.value,
      });
    }
  };

  return (
    context && (
      <>
        {params?.pathname?.includes("contracts") && (
          <input
            type="number"
            step="0.1"
            min="0"
            disabled={params?.search === "?suggestion=true"}
            defaultValue={parseFloat(
              selectedVariable?.value || variable?.value || value || ""
            )}
            className="form-control"
            onChange={(e) =>
              onChange ? onChange(e.target.value) : triggerOnChange(e)
            }
            maxLength={250}
          />
        )}
      </>
    )
  );
};

export default FloatVariableInput;
