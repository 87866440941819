import {
  HocuspocusProvider,
  HocuspocusProviderWebsocket,
} from "@hocuspocus/provider";
import { YjsEditor, withCursors, withYHistory, withYjs } from "@slate-yjs/core";
import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Editor, Range, createEditor } from "slate";
import { ReactEditor, Slate, withReact } from "slate-react";
import * as Y from "yjs";
import { IVariableConfigResponse } from "../interfaces/response/IVariableConfigResponse";
import { api } from "../utils/api";
import withVariable from "./components/Variables/withVariable";
import { RecoilRoot } from "recoil";
import randomColor from "randomcolor";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { withImages } from "./Image";
import {
  withTablePlug,
} from "@shabs21/slate-plugin-sample";

export interface EditorContextInterface {
  docContents: any;
  setDocContents: Function;
  docId: string;
  handleUpdateDocument: Function;
  getDocumentDetails: Function;
  addComment: Function;
  getComments: Function;
  variableConfig: IVariableConfigResponse | null;
  getVariables: Function;
  variables: any;
  addVariable: Function;
  deleteVariable: Function;
  updateVariable: Function;
  showSearch: boolean | null;
  setShowSearch: Function;
  editor: any;
  contents: any;
  setContents: Function;
  target: any;
  setTarget: Function;
  index: number | any;
  setIndex: Function;
  search: string | any;
  setSearch: Function;
  accessOption: Boolean;
  setAccessOption: Function;
  searchText: string | any;
  setSearchText: Function;
  replaceText: string;
  setReplaceText: Function;
  getDocumentsComments: any;
  activeCommentID: string | undefined | any;
  setActiveCommentID: Function;
  showCommentPopup: boolean;
  setShowCommentPopup: Function;
  commentThreads: any;
  setCommentThreads: Function;
  commentThreadIDsState: any;
  setCommentThreadIDsState: Function;
  deleteComment: Function;
  resolveComment: Function;
  updateComment: Function;
  getUsersDetails: Function;
  getContractCollaborators: Function;
  headerContent: any;
  setHeaderContent: Function;
  footerContent: any;
  setFooterContent: Function;
  collaborators: [{}] | undefined | any;
  handleUpdateContractDetail: Function;
  contractDescription: string | undefined;
  setContractDescription: Function;
  findReplacePopup: boolean;
  showFindReplacePopup: Function;
  fontSize: any | undefined;
  setFontSize: Function;
  documentVersion: any;
  readOnlyState: boolean;
  showColorPicker: any;
  setShowColorPicker: any;
  showHeaderEditingOption: boolean;
  setShowHeaderEditingOption: Function;
  showFooterEditingOption: boolean;
  setShowFooterEditingOption: Function;
  mode: string | undefined;
  setMode: Function;
}

const EditorContext = React.createContext<EditorContextInterface | null>(null);
interface EditorProviderProps {
  children: ReactNode;
  documentId: string;
  readOnly: boolean;
}

const EditorProvider = ({
  children,
  documentId,
  readOnly,
}: EditorProviderProps) => {
  const [readOnlyState] = useState<boolean>(readOnly);
  const [docId] = useState<string>(documentId);
  const [docContents, setDocContents] = useState<any>([]);
  const { id }: any = useParams();
  const [mode, setMode] = useState("Editing");

  const [variableConfig, setVariableConfig] =
    useState<IVariableConfigResponse | null>(null);
  const [initialValue] = useState([
    {
      type: "paragraph",
      children: [{ type: "text", text: "" }],
    },
  ]);

  const [variables, setVariables] = useState<any>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [contents, setContents] = useState<any>();
  const [target, setTarget] = useState<Range | any>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState<any>("");
  const [accessOption, setAccessOption] = useState<boolean | undefined | any>(
    false
  );
  const [showHeaderEditingOption, setShowHeaderEditingOption] = useState(false);
  const [showFooterEditingOption, setShowFooterEditingOption] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined | any>();
  const [replaceText, setReplaceText] = useState("");
  const [getDocumentsComments, setGetDocumentsComments] = useState<any>();
  // ----------------------comments
  const [activeCommentID, setActiveCommentID] = useState();
  const [showCommentPopup, setShowCommentPopup] = useState<boolean>(false);
  const authContext: any = useAuthentication();
  const [headerContent, setHeaderContent] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [footerContent, setFooterContent] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [collaborators, setCollaborators] = useState();
  const [socketToken, setSocketToken] = useState<boolean>(false);
  const [contractDescription, setContractDescription]: [
    string | undefined,
    Function
  ] = useState("");
  const [findReplacePopup, showFindReplacePopup] = useState(false);
  const [fontSize, setFontSize] = useState<any>(14);
  const [documentVersion, setDocumentVersion] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  // const AppSocket = new WebSocket(`wss://clm-wss.coducer.xyz/ws/documents/${docId}`);

  var versionHistorySocket: WebSocket | null;
  const [versionHistorySocketState, setVersionHistorySocketState] =
    useState<WebSocket | null>(null);

  const getDocumentDetails = async (docId = documentId) => {
    let response = await api.getDocument(docId);
    // console.log(response);

    setContents(response.body);
    response.header && setHeaderContent(response.header);
    response.footer && setFooterContent(response.footer);
    return response;
  };

  useEffect(() => {
    if (documentVersion !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      versionHistorySocket = new WebSocket(
        `wss://api-clm.coducer.xyz/ws/documents/${docId}`
      );
      setVersionHistorySocketState(versionHistorySocket);
    }
    return () => {
      if (documentVersion !== null) {
        versionHistorySocketState?.close();
      }
    };
  }, [documentVersion]);

  // versionHistorySocket.onMessage()

  const socket = useMemo(() => {
    setSocketToken(!socketToken);
    return new HocuspocusProviderWebsocket({
      url: `wss://api-clm.coducer.xyz/ws/collaboration/${docId}/${documentVersion}`,
      // url: `https://clm-wss.coducer.xyz/ws/collaboration/${docId}/${documentVersion}`,
      connect: false,
    });
  }, [documentVersion]);

  useEffect(() => {
    id && getContractCollaborators(id);
    id && getTemplateDetails(id);
  }, []);

  useEffect(() => {
    if (docId && docId?.length > 0) {
      getDocumentDetails(docId).then((response) => {
        setDocumentVersion(response.head_document_version);
      });

      getComments();
    }
  }, [docId]);
  // const addCommentThread = useAddCommentThreadCallback();

  useEffect(() => {
    if (versionHistorySocketState !== null) {
      versionHistorySocketState.addEventListener("message", (event) => {
        const parsedData = JSON.parse(event.data);

        switch (parsedData.event) {
          case "published": {
            window.location.reload();
            break;
          }
          case "updated-version": {
            if (parsedData.data.document === docId) {
              if (readOnly && docId === parsedData.data.document) {
                // window.location.reload();
              } else if (!readOnly) {
                getDocumentDetails(docId).then((response) => {
                  if (response.head_document_version !== documentVersion) {
                    setDocumentVersion(response.head_document_version);
                  }
                });
              }
            }
            break;
          }
          case "updated-variable": {
            getVariables();

            // if (parsedData) {
            //   const index = variables?.findIndex((item: any) => {
            //     return item?.id === parsedData?.data?.id;
            //   });
            //   if (index > -1 && index !== undefined) {
            //     variables[index] = parsedData?.data;
            //   } else {
            //     variables?.push(parsedData?.data);
            //   }
            // }
            // console.log(variables);

            // setVariables(variables);
            // const updatedVariable = variables?.map((variable: any) =>
            //   variable.id === parsedData?.data.id ? parsedData?.data : variable
            // );
            // console.log(parsedData, "parsedData");

            // setVariables([...updatedVariable]);
            break;
          }

          case "updated-comments": {
            if (parsedData) {
              const updatedComments = parsedData.data.map((comment: any) => {
                // Check if is_resolved is false, then update it to true
                if (comment.is_resolved === false) {
                  return {
                    ...comment,
                    is_resolved: true,
                  };
                }

                // If is_resolved is already true, return the comment as is
                return comment;
              });
              console.log(updatedComments);
            }
            break;
          }

          case "created-comment": {
            if (parsedData) {
              setGetDocumentsComments((prevComments: any) => {
                if (!Array.isArray(prevComments)) {
                  return [parsedData?.data];
                }
                return [...prevComments, parsedData?.data];
              });
              console.log(getDocumentsComments, "getDocumentsCommentsProvider");
            }
            break;
          }

          case "updated-comment": {
            setGetDocumentsComments((prevComments: any) => {
              if (!Array.isArray(prevComments)) {
                return [];
              }
              const updatedComments = prevComments.filter(
                (comment) => comment.id !== parsedData?.data.data._id
              );

              return updatedComments;
            });
            break;
          }
          //use this to additional events
          // case "<<EVENT_NAME>>": {
          //   console.log(parsedData);
          //   break;
          // }
          default: {
            break;
          }
        }
      });
    }
  }, [versionHistorySocketState]);

  // const apter = {
  //   event: "updated-version",
  //   data: {
  //     body: [
  //       {
  //         type: "paragraph",
  //         children: [{ text: "hfhy  jgjgju  jgjg  gkgh  jutgjugk  i" }],
  //       },
  //     ],
  //     document: "6581c2aa7c4c3d3acb241bcf",
  //     created_by: {
  //       sub: "07210cfc-da99-41af-a9f6-d7ac46cf72c0",
  //       email_verified: true,
  //       organization: "coducer",
  //       organization_id: "65815c2d7c4c3d3acb233ee0",
  //       name: "Tom",
  //       enabled: true,
  //       first_name: "Tom",
  //       last_name: "George",
  //       department: "PROCUREMENT",
  //       email: "tomgeorge@coducer.com",
  //       contact_number: "+918921792442",
  //       industry: "",
  //       job_title: "Tester",
  //       avatar:
  //         "https://ihx-clm.blr1.digitaloceanspaces.com/ihx-clm/images/coducer_27016c57-0db4-4302-8b5b-a399adff5835_download.jfif",
  //       is_primary: "",
  //       roles: ["ADMIN"],
  //       location: "",
  //     },
  //     updated_by: {
  //       sub: "07210cfc-da99-41af-a9f6-d7ac46cf72c0",
  //       email_verified: true,
  //       organization: "coducer",
  //       organization_id: "65815c2d7c4c3d3acb233ee0",
  //       name: "Tom",
  //       enabled: true,
  //       first_name: "Tom",
  //       last_name: "George",
  //       department: "PROCUREMENT",
  //       email: "tomgeorge@coducer.com",
  //       contact_number: "+918921792442",
  //       industry: "",
  //       job_title: "Tester",
  //       avatar:
  //         "https://ihx-clm.blr1.digitaloceanspaces.com/ihx-clm/images/coducer_27016c57-0db4-4302-8b5b-a399adff5835_download.jfif",
  //       is_primary: "",
  //       roles: ["ADMIN"],
  //       location: "",
  //     },
  //     edited_by: [],
  //     _id: "65842deafccb164bc9b42708",
  //     createdAt: "2023-12-21T12:22:02.782Z",
  //     updatedAt: "2023-12-21T12:22:02.782Z",
  //     __v: 0,
  //     id: "65842deafccb164bc9b42708",
  //   },
  // };

  const provider:any = useMemo(() => {
    return new HocuspocusProvider({
      websocketProvider: socket,
      name: docId,
      connect: false,
      token: localStorage.getItem("auth-token"),
      preserveConnection: false,
      parameters: {
        docId: docId,
        token: localStorage.getItem("auth-token"),
        documentVersion: documentVersion,
      },

      onConnect() {
        console.log(
          "++++++++++++++++++++++++ socketConnection ++++++++++++++++++++++++"
        );
        const ydoc = provider.document;
        const sharedType = provider.document.get(
          "content",
          Y.XmlText
        ) as Y.XmlText;
        ydoc.transact(() => {
          // Set the initial value
          sharedType.delete(0, sharedType.toString().length);
          sharedType.insert(0, initialValue[0].children[0].text);
        });
      },
    });
  }, [socketToken]);

  const editor: any = useMemo(() => {
    let sharedType: Y.XmlText;

    sharedType = provider.document.get("content", Y.XmlText) as Y.XmlText;

    return withImages(
      withTablePlug(
        withVariable(
          withReact(
            withCursors(
              withYHistory(
                withYjs(createEditor(), sharedType, {
                  autoConnect: false,
                })
              ),
              provider.awareness,
              {
                data: {
                  color: randomColor({
                    luminosity: "dark",
                    alpha: 1,
                    format: "hex",
                  }),
                  name: authContext?.currentUser?.name,
                },
              }
            )
          )
        )
      ),
    );
  }, [provider.awareness, provider.document, authContext?.currentUser?.name]);

  useEffect(() => {
    if (!readOnly && documentVersion !== null) {
      socket.connect();
    }

    const handleNetworkChange = () => {
      window.location.reload();
    };
    window.addEventListener("online", handleNetworkChange);
    return () => {
      window.removeEventListener("online", handleNetworkChange);
      if (!readOnly) {
        socket.disconnect();
      }
    };
  }, [documentVersion]);

  useEffect(() => {
    if (!readOnly && documentVersion !== null) {
      YjsEditor.connect(editor);
    }
    return () => {
      if (!readOnly) {
        try {
          YjsEditor.disconnect(editor);
        } catch (e) {
          console.error(e);
        }
      }
    };
  }, [documentVersion]);

  // why is the handleUpdateDocument called when the contents are updated
  const handleUpdateDocument = (header: any, footer: any) =>
    // console.log(header,footer,"header,footerheader,footerheader,footer")
    {
      api.UpdateDocument(documentId, {
        body: editor?.children,
        header: header,
        footer: footer,
      });
    };

  const handleUpdateContractDetail = (id: string, params: any) =>
    api.updateContractDetail(id, params);

  const getContractCollaborators = async (id: string) => {
    try {
      const res = await api.getContractsDetails(id);

      const { contract, success } = res || {};
      if (success) {
        setContractDescription(contract?.description);
        setCollaborators(
          contract?.collaborators?.map((user: any) => ({
            id: user?.user?.sub,
          }))
        );
        setDocContents(contract);
      }
      return res;
    } catch (error) {}
  };

  const getTemplateDetails = async (id: string) => {
    try {
      const res = await api.getTemplatesDetails(id);
      if (res) {
        setDocContents(res);
      }
      return res;
    } catch (error) {}
  };

  const getComments = async () => {
    try {
      const res = await api.documentComments(documentId);
      if (res.success) {
        setGetDocumentsComments(res);
      }
      return res;
    } catch (error) {}
  };

  const addComment = async (params: any) =>
    api.createDocumentComment(documentId, params);

  const deleteComment = async (commentThreadID: string) =>
    api.documentDeleteComment(documentId, commentThreadID);

  const resolveComment = (commentThreadID: string, params: any) =>
    api.documentUpdateCommentResolve(documentId, commentThreadID, params);

  const updateComment = async (commentID: string, params: any) =>
    api.documentUpdateCommentValue(documentId, commentID, params);

  // ###################### variables ######################## //

  const getVariableConfig = () => api.getVariableTypes();

  const getVariables = async () => {
    const res = await api.getDocumentVariables(documentId, {
      page: 1,
      limit: 250,
      sort: "-createdAt",
    });

    if (res?.success) {
      setVariables(res.variables);
    }
  };

  const addVariable = (params: any) =>
    api.createDocumentVariable(documentId, params);

  const updateVariable = (id: string, params: any) =>
    api.updateDocumentVariables(documentId, id, params);

  const deleteVariable = (id: string) =>
    api.removeDocumentVariables(documentId, id);

  useEffect(
    () => {
      // $$$$$$$$$$$$$$$$$$$$$$$$$$ this is called for every content update $$$$$$$$$$$$$$$$$$$$$$$$$$
      const loadVariables = async () => {
        const res = await getVariableConfig();
        res.data_types[0] = "Text";
        res.data_types[1] = "Date";
        res.data_types[3] = " Number";
        res.data_types[4] = " Currency";
        setVariableConfig(res);
      };

      loadVariables();
      getVariables();

      // AppSocket.on("updated-version", (data: any) => {
      //   console.log(data, "data234");
      // });

      // const interval = setInterval(() => {
      //   getVariables();
      // }, 10000);
      return () => {
        // clearInterval(interval);
        // versionHistorySocketState?.close();
        // AppSocket.off("");
      };
    },
    []
    /*  dependency to be changed to a variable and not the 
  content as content will be updated on every keydown  */
  );

  // AppSocket.addEventListener("message", (event) => {
  //   console.log('hai')
  //   console.log(event);
  // });

  const [commentThreads, setCommentThreads] = useState([]);

  const [commentThreadIDsState, setCommentThreadIDsState] = useState([]);

  const getUsersDetails = async () => {
    try {
      const res = await api?.getCollaborators({ limit: 100 });
      return res;
    } catch (error) {}
  };

  // const initialValue: any = [
  //   {
  //     type: "page",
  //     children: [
  //       {
  //         type: "paragraph",
  //         children: [{ type: "text", text: "this is from the getDocDetails" }],
  //       },
  //     ],
  //   } as any,
  // ];

  const value: EditorContextInterface | null = {
    docContents,
    setDocContents,
    docId,
    handleUpdateDocument,
    getDocumentDetails,
    addComment,
    getComments,
    variableConfig,
    getVariables,
    variables,
    addVariable,
    deleteVariable,
    updateVariable,
    showSearch,
    setShowSearch,
    editor,
    contents,
    setContents,
    target,
    setTarget,
    index,
    setIndex,
    search,
    setSearch,
    accessOption,
    setAccessOption,
    searchText,
    setSearchText,
    replaceText,
    setReplaceText,
    getDocumentsComments,
    activeCommentID,
    setActiveCommentID,
    showCommentPopup,
    setShowCommentPopup,
    commentThreads,
    setCommentThreads,
    commentThreadIDsState,
    setCommentThreadIDsState,
    deleteComment,
    resolveComment,
    updateComment,
    getUsersDetails,
    getContractCollaborators,
    headerContent,
    setHeaderContent,
    footerContent,
    setFooterContent,
    collaborators,
    handleUpdateContractDetail,
    contractDescription,
    setContractDescription,
    findReplacePopup,
    showFindReplacePopup,
    fontSize,
    setFontSize,
    documentVersion,
    readOnlyState,
    showColorPicker,
    setShowColorPicker,
    showHeaderEditingOption,
    setShowHeaderEditingOption,
    showFooterEditingOption,
    setShowFooterEditingOption,
    mode,
    setMode,
  };

  return (
    <div>
      {contents && (
        <EditorContext.Provider value={value}>
          <RecoilRoot>
            <Slate
              editor={editor as ReactEditor}
              initialValue={value.contents}
              onChange={() => {
                const { selection } = editor;
                if (selection && Range.isCollapsed(selection)) {
                  const [start] = Range.edges(selection);
                  const wordBefore = Editor.before(editor, start, {
                    unit: "word",
                  });
                  const beforeRange =
                    wordBefore && Editor.range(editor, wordBefore, start);
                  const beforeText =
                    beforeRange && Editor.string(editor, beforeRange);
                  const beforeMatch =
                    beforeText && beforeText.match(/^\$\w+|^\$/);
                  const after = Editor.after(editor, start);
                  const afterRange = Editor.range(editor, start, after);
                  const afterText = Editor.string(editor, afterRange);
                  const afterMatch = afterText.match(/^(\s|$)/);
                  if (beforeMatch && afterMatch) {
                    setTarget(beforeRange);
                    const search = beforeMatch[0].replace(/^\$/, "");
                    setSearch(search);
                    setIndex(0);
                    return;
                  }
                }
                setTarget(null);
              }}
            >
              <div>{children}</div>
            </Slate>
          </RecoilRoot>
        </EditorContext.Provider>
      )}
    </div>
  );
};

const useEditor = () => {
  return React.useContext(EditorContext);
};

export { EditorProvider, useEditor };
