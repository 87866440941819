import React, { useState } from "react";
import { Button, Container, Offcanvas } from "react-bootstrap";
import DocumentHeader from "./DocumentHeader";
import "./DocumentLayout.scss";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import TableOfContents from "../editor/TableOfContents";
import CommentsSidebar from "../editor/components/comments/src/component/CommentSidebar";
import { isMobile } from "react-device-detect";

// import { Slate } from "slate-react";
// import { initialValue } from "../editor/Editor";

type DocumentLayoutProps = React.PropsWithChildren<{
  pageTitle?: string;
  toolbar: JSX.Element | any;
  context: TemplateContextInterface | ContractContextInterface;
  contents: any;
}>;

const DocumentLayout = ({
  pageTitle,
  children,
  toolbar,
  context,
  contents,
}: DocumentLayoutProps) => {
  React.useEffect(() => {
    document.title = pageTitle || "CLM";
  }, [pageTitle]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showComments, setShowComments] = useState(false);

  const handleCloseComments = () => setShowComments(false);
  const handleShowComments = () => setShowComments(true);

  return (
    <div id="document-layout">
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          background: "#fefefe",
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
          zIndex: "11",
        }}
        className="pb-3"
      >
        <div style={{ display: "contents" }}>
          <DocumentHeader context={context} contents={contents} />
        </div>
        <div
          className="  d-flex rounded-1 mx-3"
          style={{ background: "#ecebfb" }}
        >
          {toolbar}
        </div>
      </div>
      <div className=" d-lg-none" style={{ marginTop: "175px" }}>
        <div className=" d-flex justify-content-between align-items-center p-3">
          <Button className="saveBtn" onClick={handleShow}>
            Summary
          </Button>
          <Button className="saveBtn" onClick={handleShowComments}>
            Comments
          </Button>
        </div>

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className=" border-0">Summary</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TableOfContents />
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          show={showComments}
          onHide={handleCloseComments}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className=" border-0">Comments</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CommentsSidebar />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div
        style={
          isMobile
            ? {
                minHeight: "550px",
              }
            : { minHeight: "550px", marginTop: "175px" }
        }
        className="row px-4 pb-5 pt-2"
      >
        <div className="col-lg-3  col-12 d-none d-lg-block position-relative">
          <Container
            style={{ marginTop: "20px" }}
            className="position-fixed w-25"
          >
            <TableOfContents />
          </Container>
        </div>

        <div className="col-lg-6 col-12 position-relative">
          <div className=" w-100" style={{ marginTop: "20px" }}>
            {children}
          </div>
        </div>

        <div
          className="col-lg-3 col-12 d-none d-lg-block position-relative"
          style={{ zIndex: "-99999999" }}
        >
          <div className={"sidebar-wrapper position-fixed"}>
            <Container style={{ marginTop: "20px" }}>
              <CommentsSidebar />
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentLayout;
