import Select from "react-select";
import { customStyles } from "./CustomStyles";

interface FormikAsyncSelectProps {
  values?:
    | {
        label: string;
        value: string;
      }
    | {
        label: string;
        value: string;
      }[];
  updateField: Function;
  options?: any[];
  className?: string;
  defaultValue?: any;
  isMulti?: boolean;
  components?: any;
  disabled?: boolean;
}

function CommonReactSelect({
  updateField,
  options = [],
  className,
  isMulti = false,
  components,
  defaultValue,
  disabled,
  values = { label: "", value: "" } || [
    {
      label: "",
      value: "",
    },
  ],
}: FormikAsyncSelectProps) {
  return (
    <Select
      isClearable={isMulti}
      placeholder=""
      styles={customStyles}
      classNamePrefix="select"
      className={`${className} basic-multi-select `}
      options={options}
      onChange={(option: any) => {
        updateField(option);
      }}
      inputValue={""}
      onInputChange={() => {}}
      onMenuOpen={() => {}}
      onMenuClose={() => {}}
      defaultValue={defaultValue}
      isMulti={isMulti}
      components={components}
      isDisabled={disabled}
      value={values}
    />
  );
}

export default CommonReactSelect;
