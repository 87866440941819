import { isMobile } from "react-device-detect";
import AvatarSection from "./Avatar";
import { MdDelete } from "react-icons/md";
import { useEffect, useState } from "react";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { api } from "../utils/api";
import { useParams } from "react-router-dom";
import { StatusEnum } from "../interfaces/StatusEnum";
import { Form } from "react-bootstrap";

const ListCollaborators = ({
  context,
  setCollaboratorRoles,
  collaboratorRoles,
  isActiveNegotiationWorkflow,
}: {
  context: any;
  setCollaboratorRoles: Function;
  collaboratorRoles: any;
  isActiveNegotiationWorkflow?: any;
}) => {
  const { id } = useParams();
  const authContext = useAuthentication();
  const [allCounterparty, setAllCounterParty]: [[], Function] = useState([]);

  useEffect(() => {
    let users = context?.data?.externalUser?.filter((user: any) => {
      return user?.access !== "SIGNER";
    });
    setAllCounterParty(users);
  }, [context?.data]);

  const onDeleteCollab = async (counterpartyId: string) => {
    try {
      let response = await api.deleteCounterparty(id!, counterpartyId);
      if (response?.data?.success) {
        context?.refresh();
      }
    } catch (error) {}
  };

  return (
    context && (
      <ul
        className="list-unstyled overflow-scroll m-0 p-0"
        style={isMobile ? { height: "40dvh" } : { height: "20vh" }}
      >
        {context?.data?.status === StatusEnum?.pending_negotitation &&
        isActiveNegotiationWorkflow?.email === authContext?.currentUser?.email
          ? (allCounterparty || []).map((collaborator: any) => {
              return (
                <li key={collaborator?._id}>
                  <div className="border-0">
                    <div className="p-0">
                      <div className=" d-flex align-items-center justify-content-between colabBody py-3  flex-wrap">
                        <div className=" d-flex align-items-center">
                          <div>
                            <AvatarSection
                              imageData={""}
                              name={collaborator && collaborator?.name}
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="fw-semibold m-0 ">
                              {collaborator?.name}
                            </h6>
                            <h6 className="fw-lighter m-0 ">
                              {collaborator?.email}
                            </h6>
                          </div>
                        </div>
                        <div>
                          <h6 className="fw-lighter m-0">
                            <div className="d-flex align-items-center">
                              {collaborator?.access}
                              {collaborator?.access !== "OWNER" && (
                                <MdDelete
                                  onClick={() => {
                                    onDeleteCollab(collaborator?._id);
                                  }}
                                  fontSize={20}
                                  className="ms-2 icon-fa"
                                  color="#b1b1b1"
                                />
                              )}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          : (context?.data?.collaborators || []).map((collaborator: any) => {
              return (
                <li key={collaborator.user.sub}>
                  <div className="border-0">
                    <div className="p-0">
                      <div className=" d-flex align-items-center justify-content-between colabBody py-3 ">
                        <div className=" d-flex align-items-center">
                          <div>
                            <AvatarSection
                              imageData={""}
                              name={collaborator && collaborator?.user?.name}
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="fw-semibold m-0 ">
                              {collaborator.user.name}{" "}
                              {collaborator.user?.last_name}
                            </h6>
                            <h6 className="fw-lighter m-0 ">
                              {collaborator.user.email}
                            </h6>
                          </div>
                        </div>
                        <div>
                          <h6 className="fw-lighter m-0">
                            {!!context.isOwner &&
                            context?.isOwner(collaborator.user.id) ? (
                              "OWNER"
                            ) : (
                              // collaborator.access
                              <div className="d-flex align-items-center">
                                <Form.Select
                                  aria-label="Default select example"
                                  defaultValue={collaborator.access}
                                  onChange={(e: any) => {
                                    const isAldredyPresent: any =
                                      collaboratorRoles.find(
                                        (s: any) =>
                                          s.collaborators_id ===
                                          collaborator.user.sub
                                      );
                                    if (isAldredyPresent) {
                                      let b = [...collaboratorRoles];
                                      b.map((s: any) => {
                                        if (
                                          isAldredyPresent.id ===
                                          s.collaborators_id
                                        ) {
                                          s.access = e.target.value;
                                        } else {
                                          s.access = e.target.value;
                                        }

                                        return s;
                                      });
                                      setCollaboratorRoles(b);
                                    } else {
                                      setCollaboratorRoles([
                                        ...collaboratorRoles,
                                        {
                                          collaborators_id:
                                            collaborator.user.sub,
                                          access: e.target.value,
                                        },
                                      ]);
                                    }
                                  }}
                                >
                                  <option value="VIEWER">VIEWER</option>
                                  <option value="EDITOR">EDITOR</option>
                                </Form.Select>
                                <MdDelete
                                  onClick={() => {
                                    context.removeAccess(collaborator.user.id);
                                  }}
                                  fontSize={28}
                                  className="ms-2 icon-fa"
                                  color="#b1b1b1"
                                />
                              </div>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
      </ul>
    )
  );
};
export default ListCollaborators;
