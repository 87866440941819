import BaseToast from "./BaseToast";
import { ReactNode, useEffect } from "react";

const SuccessToast = ({
  show,
  icon,
  title,
  message,
  close,
}: {
  show: boolean;
  icon: ReactNode;
  title: string;
  message: string;
  close: () => void;
}) => {
  // const [counter, setCounter] = useState(5);
  useEffect(() => {
    const id = setTimeout(() => {
      // if (counter <= 1) {
      clearTimeout(id);
      close();
      // }
      // setCounter(counter - 1);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [show, close]);

  return (
    <>
      {show && (
        <BaseToast>
          <div className="toastpop position-relative">
            <div className="position-absolute border-white rounded-circle-css px-2 py-1 toast-counter">
              {/* {counter} */}
              &nbsp;
            </div>
            <div className=" d-flex align-items-center justify-content-center">
              {icon}
            </div>
            <div className=" d-flex justify-content-center py-3">
              <h4 className="m-0 fw-semibold">{title}</h4>
            </div>
            <div className="pb-3">
              <h6 className=" text-center ff-questrial ">{message}</h6>
            </div>
          </div>
        </BaseToast>
      )}
    </>
  );
};

export default SuccessToast;
