import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";

const AnalyticsRange = ({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
}: any) => {
  return (
    <>
      <div className="AnalyticsRange">
        <div className="multi-range-slider-container">
          <MultiRangeSlider
            min={0}
            max={1000}
            step={5}
            minValue={minValue}
            maxValue={maxValue}
            barLeftColor={"#f0f0f0"}
            barRightColor={"#f0f0f0"}
            barInnerColor={"#f2f6fc"}
            thumbLeftColor={"#c5d0de"}
            thumbRightColor={"#c5d0de"}
            ruler={false}
            onInput={(e: ChangeResult) => {
              setMinValue(e.minValue);
              setMaxValue(e.maxValue);
            }}
          ></MultiRangeSlider>
          <div className=" d-flex justify-content-between mt-3">
            <div> ${minValue}K</div>
            <div>${maxValue}K</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticsRange;
