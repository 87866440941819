import randomColor from "randomcolor";
import { CursorData } from "../types/types";
import { useAuthentication } from "../../modules/Authentication/AuthenticationProvider";

export function RandomCursorData(): CursorData {
  const context: any = useAuthentication();
  return {
    color: randomColor({
      luminosity: "dark",
      alpha: 1,
      format: "hex",
    }),
    name: context?.currentUser?.name,
  };
}

export function addAlpha(hexColor: string, opacity: number): string {
  const normalized = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
  return hexColor + normalized.toString(16).toUpperCase();
}
