import moment from "moment";
import { useState } from "react";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
// import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { SUPPORTED_DATE_FORMATS, useVariables } from "./VariableProvider";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

const DateVariableInput = ({
  variable,
  format,
  value,
  onChange,
  type,
}: {
  variable?: any;
  format?: string;
  value?: string;
  onChange?: Function;
  type?: string;
}) => {
  const date = variable
    ? moment(variable?.value, "YYYY-MM-DD")
    : moment(value, "YYYY-MM-DD");

  const context = useVariables();
  const selectedVariable = context?.values.find(
    ({ id }) => variable?.id === id
  );
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [dateFormat, setDateFormat] = useState(
    variable?.["format"] === "DD/MM/YYYY" ? "dd/MM/yyyy" : "MM/dd/yyyy"
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const params = useLocation();

  const onDateChange = (value: string) => {
    if (variable) {
      context?.update(variable.id, {
        value: value,
        format: selectedVariable?.format || variable.format,
      });
    }
  };

  const onFormatChange = (format: any) => {
    if (variable) {
      context?.update(variable.id, {
        value: selectedVariable?.value || variable.value,
        format,
      });
    }
  };

  return (
    <>
      {context && params?.pathname?.includes("contracts") && (
        <div>
          <div>
            {location.pathname.includes("variables") || type === undefined ? (
              <div className="d-flex ">
                <div>
                  <InputGroup.Text id="basic-addon2" className=" me-3">
                    <DropdownButton
                      variant="outline-default"
                      title={
                        selectedVariable?.format || variable?.format || format
                      }
                      id="input-group-dropdown-1"
                      style={{ border: "none" }}
                    >
                      {SUPPORTED_DATE_FORMATS.map((_format: string) => (
                        <Dropdown.Item
                          key={
                            _format === "MM/DD/YYYY"
                              ? "MM/dd/yyyy"
                              : "dd/MM/yyyy"
                          }
                          href="#"
                          disabled={
                            _format ===
                            (selectedVariable?.format ||
                              variable?.format ||
                              format)
                          }
                          onClick={() => {
                            setDateFormat(
                              _format === "MM/DD/YYYY"
                                ? "MM/dd/yyyy"
                                : "dd/MM/yyyy"
                            );
                            onChange
                              ? onChange({
                                  value,
                                  format:
                                    _format === "MM/DD/YYYY"
                                      ? "MM/dd/yyyy"
                                      : "dd/MM/yyyy",
                                })
                              : onFormatChange(_format);
                          }}
                        >
                          {_format}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup.Text>
                </div>
                <div className=" flex-grow-1 variable_input">
                  {params?.pathname?.includes("contracts") && (
                    <DatePicker
                      disabled={params?.search === "?suggestion=true"}
                      selected={
                        selectedDate === null
                          ? new Date(
                              date.isValid()
                                ? date.format(
                                    "ddd DD MMM YYYY 00:00:00 [GMT]ZZ (z)"
                                  )
                                : moment().format(
                                    "ddd DD MMM YYYY 00:00:00 [GMT]ZZ (z)"
                                  )
                            )
                          : new Date(selectedDate)
                      }
                      className="form-control w-100"
                      dateFormat={dateFormat}
                      onChange={(e: any) => {
                        const newDateString = moment(e).format("YYYY-MM-DD");

                        setSelectedDate(e);
                        onChange
                          ? onChange({ value: e, format })
                          : onDateChange(newDateString);
                        if (e === "") {
                          setErrorMessage("can't be empty");
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                {type === "AddDate" && (
                  <>
                    <div className="d-flex ">
                      <div>
                        <InputGroup.Text id="basic-addon2" className=" me-3">
                          <DropdownButton
                            variant="outline-default"
                            title={
                              (selectedVariable?.format ||
                                variable?.format ||
                                format) === "dd/MM/yyyy"
                                ? "DD/MM/YYYY"
                                : "MM/DD/YYYY"
                            }
                            id="input-group-dropdown-1"
                            style={{ border: "none" }}
                          >
                            {SUPPORTED_DATE_FORMATS.map((_format: string) => (
                              <Dropdown.Item
                                key={
                                  _format === "MM/DD/YYYY"
                                    ? "MM/dd/yyyy"
                                    : "dd/MM/yyyy"
                                }
                                href="#"
                                disabled={
                                  _format ===
                                  (selectedVariable?.format ||
                                    variable?.format ||
                                    format)
                                }
                                onClick={() => {
                                  setDateFormat(
                                    _format === "MM/DD/YYYY"
                                      ? "MM/dd/yyyy"
                                      : "dd/MM/yyyy"
                                  );
                                  onChange
                                    ? onChange({
                                        value,
                                        format:
                                          _format === "MM/DD/YYYY"
                                            ? "MM/dd/yyyy"
                                            : "dd/MM/yyyy",
                                      })
                                    : onFormatChange(_format);
                                }}
                              >
                                {_format}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </InputGroup.Text>
                      </div>
                      <div className=" flex-grow-1 variable_input">
                        {params?.pathname?.includes("contracts") && (
                          <DatePicker
                            disabled={params?.search === "?suggestion=true"}
                            selected={
                              selectedDate === null
                                ? new Date(
                                    date.isValid()
                                      ? date.format(
                                          "ddd MMM DD YYYY 00:00:00 [GMT]ZZ (z)"
                                        )
                                      : moment().format(
                                          "ddd MMM DD YYYY 00:00:00 [GMT]ZZ (z)"
                                        )
                                  )
                                : new Date(selectedDate)
                            }
                            className="form-control w-100"
                            dateFormat={dateFormat}
                            onChange={(e: any) => {
                              const newDateString =
                                moment(e).format("YYYY-MM-DD");

                              setSelectedDate(e);
                              onChange
                                ? onChange({ value: e, format })
                                : onDateChange(newDateString);
                              if (e === "") {
                                setErrorMessage("can't be empty");
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {errorMessage.length > 0 && (
            <div className="text-danger errorMsg">{errorMessage}</div>
          )}
        </div>
      )}
    </>
  );
};

export default DateVariableInput;
