import { usePdf } from "@mikecousins/react-pdf";
import { useEffect, useRef, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useContract } from "../modules/Contracts/ContractProvider";
import Draggable from "react-draggable";
import { PDFDocumentProxy, getDocument } from "pdfjs-dist";

const PDFViewerContract = ({ file }: { file: string }) => {
  const context = useContract();
  const [recentPosition, setRecentPosition]: [any, Function] = useState();
  const [pdfHeightandWidth, setPdfHeightAndWidth]: [any, Function] = useState();

  const [page, setPage] = useState(1);
  const canvasRef: any = useRef(null);

  const { pdfDocument } = usePdf({
    scale: 1,
    file: file,
    page,
    canvasRef,
  });

  useEffect(() => {
    let externalCoordinates: any = context?.data?.externalUser?.map(
      (el: any) => ({
        name: el?.name,
        id: el?._id,
        email: el?.email,
        coordinates: el?.co_ordinates,
        status: el?.status,
      })
    );

    let internalCoordinates = context?.data?.internalSingers?.map(
      (el: any) => ({
        name: el?.user?.name,
        id: el?._id,
        email: el?.user?.email,
        coordinates: el?.co_ordinates,
        status: el?.status,
      })
    );
    setRecentPosition(internalCoordinates?.concat(externalCoordinates));
  }, [context?.data?.internalSingers, context?.data?.externalUser]);

  useEffect(() => {
    const fetchPdfAndCalculateDimensions = async () => {
      try {
        const loadingTask = getDocument(file);
        const pdf: PDFDocumentProxy = await loadingTask.promise;

        // Assuming the PDF only contains one page (index 1)
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        // Retrieve the dimensions of the PDF page
        const { width, height } = viewport;

        setPdfHeightAndWidth({ width: width, height: height });
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    fetchPdfAndCalculateDimensions();
  }, [file]);

  return (
    <>
      {
        <Stack className="w-md-50" style={{ margin: "0px auto" }}>
          {!pdfDocument && <span>Loading...</span>}
          <div className=" position-relative">
            {recentPosition?.map((el: any) => {
              return (
                <>
                  <NameRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <SignatureRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <InitialsRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <DateRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <EmailRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <DesiginationRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                  <LocationRecentPosition
                    position={el}
                    page={page}
                    pdfHeightandWidth={pdfHeightandWidth}
                  />
                </>
              );
            })}
            <canvas ref={canvasRef} />
          </div>
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <Stack
              direction="horizontal"
              className="justify-content-center my-2"
            >
              <Button
                variant="outline-dark"
                size="sm"
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                  context?.refresh();
                }}
                className="mx-2"
              >
                Previous
              </Button>
              {page}
              {"/"}
              {context?.data?.is_stamped ? (
                <>{context?.data?.pages! + 1}</>
              ) : (
                <>{context?.data?.pages}</>
              )}

              <Button
                variant="outline-dark "
                size="sm"
                disabled={page === pdfDocument?.numPages}
                onClick={() => {
                  setPage(page + 1);
                  context?.refresh();
                }}
                className="mx-2"
              >
                Next
              </Button>
            </Stack>
          )}
        </Stack>
      }
    </>
  );
};

export default PDFViewerContract;

const NameRecentPosition = ({ position, page, pdfHeightandWidth }: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "NAME" && el?.page === page
  );
  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  // boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  <span>{"Name"}</span>
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const SignatureRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "SIGN" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  <span>{"Sign"}</span>
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};
const InitialsRecentPosition = ({ position, page, pdfHeightandWidth }: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "INITIAL" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  Initials
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const DateRecentPosition = ({ position, page, pdfHeightandWidth }: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "DATE" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };
  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  Date
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const EmailRecentPosition = ({ position, page, pdfHeightandWidth }: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "EMAIL" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  Email
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const DesiginationRecentPosition = ({
  position,
  page,
  pdfHeightandWidth,
}: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "DESIGNATION" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  Designation
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};

const LocationRecentPosition = ({ position, page, pdfHeightandWidth }: any) => {
  let positionDetail = position?.coordinates?.filter(
    (el: any) => el?.type === "ADRESS" && el?.page === page
  );

  const stringToHexColor = () => {
    let hash = 0;
    for (let i = 0; i < position?.name.length; i++) {
      hash = position?.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  function hexToRgba() {
    let hex: any = stringToHexColor().replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const alpha = Math.min(1, Math.max(0, 0.1));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return (
    <>
      {position?.coordinates?.length > 0 &&
        positionDetail?.length !== 0 &&
        position?.status !== "SIGNED" && (
          <>
            <Draggable
              bounds="parent"
              position={{
                x: positionDetail?.[0]?.x,
                y: pdfHeightandWidth?.height - 50 - positionDetail?.[0]?.y,
              }}
            >
              <div
                style={{
                  width: 100,
                  height: 30,
                  color: stringToHexColor(),
                  textAlign: "initial",
                  fontWeight: "600",
                  fontSize: "15px",
                  margin: "20px",
                  position: "absolute",
                  paddingBottom: "40px",
                  borderBottom: `2px solid ${stringToHexColor()}`,
                }}
              >
                <div
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "10px",
                    backgroundColor: hexToRgba(),
                  }}
                >
                  Location
                </div>
              </div>
            </Draggable>
          </>
        )}
    </>
  );
};
