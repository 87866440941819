import { Form, OverlayTrigger } from "react-bootstrap";
import { activeMark, changeMarkData } from "./ToolbarMark";
import { renderTooltip } from "./ColorPicker/ColorPicker";

export const EditorDropdown = ({ format, options, editor, className }: any) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      // overlay={renderTooltip(format)}
      overlay={
        format === "fontFamily"
          ? renderTooltip("Font")
          : format === "fontSize"
          ? renderTooltip("Font Size")
          : format === "lineHeight"
          ? renderTooltip("Line spacing")
          : format === "letterSpacing"
          ? renderTooltip("Character spacing")
          : renderTooltip("Line Height")
      }
    >
      <Form.Select
        value={activeMark(editor, format)}
        size="sm"
        onChange={(e) => changeMarkData(e, format, editor)}
        className={className + " border-0 d-inline bg-transparent "}
        style={{
          minWidth: format === "fontSize" ? "1px" : "130px",
          cursor: "pointer",
        }}
      >
        {options.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </Form.Select>
    </OverlayTrigger>
  );
};
