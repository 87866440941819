export const capitalize = (str: string = "") =>
  str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const slugify = (str: string = "") =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const toTitleCase = (str: string) =>
  str.replace(/^_*(.)|_+(.)/g, (s, c, d) => {
    console.info(s);
    return c ? c.toUpperCase() : " " + d.toUpperCase();
  });

export const stringToHexColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - color.length) + color;
};

export const updateText = (data: string) => {
  return data
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
