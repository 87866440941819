export const roleOptions = [
  { value: "ADMIN", label: "Admin" },
  { value: "APPROVER", label: "Approver" },
  { value: "AUTHORIZED_SIGNATORIES", label: "Authorized Signatory" },
  { value: "GUEST", label: "Guest" },
  { value: "OWNER", label: "Owner" },
  { value: "EDITOR", label: "Editor" },
];

export enum IRole {
  Admin = "ADMIN",
  Approver = "APPROVER",
  "Authorized Signatory" = "AUTHORIZED_SIGNATORIES",
  Guest = "GUEST",
  Owner = "OWNER",
  Editor = "EDITOR",
}
