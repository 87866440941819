import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import IAdmin from "../../../interfaces/response/IAdmin";
import { adminApi } from "../../../utils/adminApi";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "../../../utils/adminRoutes";
import { api } from "../../../utils/api";

interface AdminAuthenticationProps {
  children: ReactNode;
}

interface AdminAuthenticationContextProps {
  isLoggedIn: boolean;
  currentAdmin: IAdmin | null;
  getAdmin: Function;
  handleLogout: Function;
  departmentList: any;
  rolesAndAccess: any;
  updateRoles: Function;
  getConfigRoles: Function;
  getRolesDetails: Function;
  handleVerifyToken: Function;
  login: (
    email: string,
    password: string
  ) => Promise<{ success: boolean; message?: string }>;
}

const AdminAuthenticationContext =
  createContext<AdminAuthenticationContextProps | null>(null);

const AdminAuthenticationProvider = ({
  children,
}: AdminAuthenticationProps) => {
  let authRefreshToken: string = "";

  try {
    authRefreshToken = localStorage.getItem("admin-refresh-auth-token") || "";
  } catch {
    authRefreshToken = "";
  }
  const navigate = useNavigate();
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [currentAdmin, setCurrentAdmin] = useState<IAdmin | null>(null);
  const [rolesAndAccess, setRolesAndAccess]: [any, Function] = useState();
  const [refreshToken, setRefreshToken]: [string, Function] =
    useState(authRefreshToken);
  useEffect(() => {
    const loadAdmin = async () => {
      await getAdmin();
    };
    loadAdmin();
  }, []);

  const logout = () => {
    localStorage.removeItem("admin-auth-token");
    localStorage.removeItem("admin-refresh-auth-token");
    setCurrentAdmin(null);
    setIsLoggedIn(false);
  };

  const getAdmin = async () => {
    const accessToken = localStorage.getItem("admin-auth-token");
    if (!accessToken) return;
    try {
      const admin: IAdmin = await adminApi.currentAdmin();
      if (admin) {
        setIsLoggedIn(true);
        setCurrentAdmin(admin);
      } else {
        logout();
      }
    } catch {
      logout();
    }
  };
  // ...........roles and access............
  const getConfigRoles = async () => {
    const { success, role, modules, features } =
      await adminApi.getUserRolesConfig();
    return { success, role, modules, features };
  };

  const getRolesDetails = async () => {
    const res = await adminApi.getRolesDetails();

    if (res?.success) {
      setRolesAndAccess(res?.roles);
    }
    return res;
  };
  const handleVerifyToken = async (params: any) =>
    adminApi?.adminVerifyToken(params);

  useEffect(() => {
    getRolesDetails();
  }, []);

  const updateRoles = async (params: any) => adminApi.updateRoles(params);

  const handleLogin = async (email: string, password: string) => {
    const res = await adminApi.signIn(email, password);
    if (!res.error) {
      // setToken(res.token as string);
      localStorage.setItem("admin-auth-token", res.token);
      setRefreshToken(res.refresh_token);
      localStorage.setItem("admin-refresh-auth-token", res.refresh_token);
      await getAdmin();
      return { success: true };
    }
    return { success: false };
  };

  const handleLogout = async () => {
    const response = await adminApi.adminLogOut({
      refresh_token: refreshToken,
    });
    if (response?.success) {
      navigate(adminRoutes.login);
      logout();
    }
  };
  const getDepartments = async () => {
    const res = await api.getDepartments();
    setDepartmentList([...res?.data?.departments, "TECHNICIAN"]);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const value: AdminAuthenticationContextProps = {
    isLoggedIn,
    currentAdmin,
    login: handleLogin,
    getAdmin,
    handleLogout,
    departmentList,
    getConfigRoles,
    updateRoles,
    rolesAndAccess,
    getRolesDetails,
    handleVerifyToken,
  };
  return (
    <AdminAuthenticationContext.Provider value={value}>
      {children}
    </AdminAuthenticationContext.Provider>
  );
};

const useAdminAuth = () => useContext(AdminAuthenticationContext);

export { AdminAuthenticationProvider, useAdminAuth };
