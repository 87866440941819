import { IUserResponse } from "../interfaces/response/IUserResponse";
import { IVariableResponse } from "../interfaces/response/IVariableResponse";

export class Variable {
  createdAt: Date;
  created_by: IUserResponse;
  data_type: string;
  document_version: string;
  format: string;
  id: string;
  name: string;
  updatedAt: Date;
  updated_by: IUserResponse;
  value: string;
  currency_type?: string;

  constructor(params: IVariableResponse) {
    this.createdAt = new Date(params.createdAt);
    this.created_by = params.created_by;
    this.data_type = params.data_type;
    this.document_version = params.document_version;
    this.format = params.format;
    this.id = params.id;
    this.name = params.name;
    this.updatedAt = new Date(params.updatedAt);
    this.updated_by = params.updated_by;
    this.value = params.value;
    this.currency_type = params.currency_type;
  }
}
