import { useMemo } from "react";
import { createEditor, Descendant } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import RichTextExample from "./Richtext";

const EditableVoidsExample = (type:any) => {
  const editor = useMemo(
    () => withEditableVoids(withHistory(withReact(createEditor()))),
    []
  );

  return (
    <Slate editor={editor} initialValue={initialValue}>
      <Editable
        renderElement={(props) => <Element {...props} type={type}/>}
        placeholder="Enter some text..."
        disabled={true}
      />
    </Slate>
  );
};

const withEditableVoids = (editor: any) => {
  const { isVoid } = editor;

  editor.isVoid = (element: any) => {
    return element.type === "editable-void" ? true : isVoid(element);
  };

  return editor;
};
const Element = (props: any) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "editable-void":
      return <EditableVoid {...props}/>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const EditableVoid = ({ attributes, children, type }: any) => {
  return (
    <div {...attributes} contentEditable={false}>
      <div>
        <div>
          <RichTextExample type={type}/>
        </div>
      </div>
      {children}
    </div>
  );
};

const initialValue: Descendant[] = [
  {
    type: "editable-void",
    children: [{ text: "" }],
  },
] as any;

export default EditableVoidsExample;
