import { Button, Dropdown, ListGroup, Modal, Stack } from "react-bootstrap";
import { useFolders } from "../FoldersProvider";
import { IFolderResponse } from "../../../interfaces/response/IFolderResponse";
import { useEffect, useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { useLocation, useParams } from "react-router-dom";
import { Folder } from "../../../lib/Folder";

interface FolderPathProps {
  id: string;
  name: string;
  parentFolder: string;
}

function MoveFoldersPopup({
  show,
  onHide,
}: {
  show: boolean;
  onHide: Function;
}) {
  const context = useFolders();
  const location = useLocation();
  const { id } = useParams();
  const [isListActive, setIsListACtive]: [string, Function] = useState("");
  const [folderList, setFolderList]: [[] | undefined, Function] = useState(
    context?.subFolders
  );
  const [activePathName, setactivePathName]: [string, Function] = useState("");
  const [folderPath, setFolderPath]: [[], Function] = useState([]);
  const [folderSelected, setFolderSelected]: [string, Function] = useState("");
  const [onFolderSelect, setOnFolderSelect]: [string, Function] = useState("");

  const handleMoveFolderById = async () => {
    let response = await context?.handleFolderById(
      folderSelected ? folderSelected : isListActive
    );

    if (response?.success) {
      setactivePathName(response?.folder?.name);
      setFolderList(response?.folder?.sub_folders);
      setFolderPath(response?.folder?.path);
    }
  };

  const onMoveClick = async (destinationFolderId: string) => {
    try {
      let requestBody = {
        source_folder: context?.selectedFolderIdForMove,
        destination_folder: destinationFolderId,
      };
      let response = await context?.handleFolderMove(requestBody);

      if (response?.status === 201) {
        onHide();
        let res = await context?.handleFolderById(
          id! ? id : context?.folderList?.id
        );
        if (res?.success) {
          if (location.pathname.includes("private")) {
            let privateList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PRIVATE";
              }
            );

            context?.setSubFolders(privateList);
          } else if (location.pathname.includes("public")) {
            let publicList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PUBLIC";
              }
            );
            context?.setSubFolders(publicList);
            context?.setFolderList(res?.folder);
          }
        }
      }
    } catch (error) {}
  };

  const handleRootFolder = async () => {
    try {
      let response = await context?.handleFolderList();
      if (location.pathname.includes("private")) {
        let privateList = response?.folder?.sub_folders?.filter(
          (list: IFolderResponse) => {
            return list?.access === "PRIVATE";
          }
        );
        setFolderList(privateList);
      } else if (location.pathname.includes("public")) {
        let publicList = response?.folder?.sub_folders?.filter(
          (list: IFolderResponse) => {
            return list?.access === "PRIVATE";
          }
        );
        setFolderList(publicList);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (isListActive || folderSelected) {
      handleMoveFolderById();
    }
  }, [isListActive, folderSelected]);
  return (
    <Modal show={show} centered onHide={() => onHide()} size="lg">
      <Modal.Body className="p-4" style={{ height: "400px" }}>
        <Stack>
          <h5 className="fw-semibold py-2">Move folder </h5>
        </Stack>

        <div className="d-flex  mb-1 mx-2 ">
          <h6
            className="contract-title pb-1 m-0"
            onClick={() => handleRootFolder()}
          >
            All folders
          </h6>
          {folderPath?.length >= 3 ? (
            <>
              <IoMdArrowDropright color="black" fontSize={"20px"} />
              <Dropdown>
                <Dropdown.Toggle
                  variant="default"
                  id="dropdown-basic"
                  style={{ border: "none" }}
                >
                  <BsThreeDots />
                </Dropdown.Toggle>

                <Dropdown.Menu className="">
                  {folderPath?.map((path: FolderPathProps) => {
                    return (
                      <>
                        {path?.name !== "/" && (
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              setFolderSelected(path?.id);
                            }}
                          >
                            {path?.name}
                          </Dropdown.Item>
                        )}
                      </>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <>
              {folderPath?.map((path: FolderPathProps) => {
                return (
                  <>
                    {path?.name === "/" ? (
                      ""
                    ) : (
                      <>
                        <IoMdArrowDropright color="black" fontSize={"20px"} />
                        <span
                          className="contract-title pb-1 m-0"
                          onClick={() => setFolderSelected(path?.id)}
                        >
                          {path?.name}
                        </span>
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
          {context?.selectedFolderIdForMove ? (
            <>
              <IoMdArrowDropright color="black" fontSize={"20px"} />
              <span className="contract-title pb-1 m-0">{activePathName}</span>
            </>
          ) : (
            ""
          )}
        </div>

        <ListGroup className="mt-3">
          {folderList &&
          (folderList?.length >= 0 || folderList?.length >= 1) ? (
            folderList?.map((list: IFolderResponse) => {
              return (
                <>
                  {context?.selectedFolderIdForMove !== list?.id && (
                    <>
                      <ListGroup.Item
                        style={{ cursor: "pointer" }}
                        className={` ${
                          onFolderSelect === list?.id ? "move_folder_list" : ""
                        }`}
                      >
                        <div className="row">
                          <div
                            className="col-10"
                            onDoubleClick={() => {
                              setIsListACtive(list?.id);
                              setFolderSelected("");
                            }}
                            onClick={() => setOnFolderSelect(list?.id)}
                          >
                            {list.name}
                          </div>
                          {/* <div className="col-2">
                            <a
                              className="move_click"
                              onClick={() => {
                                onMoveClick(list?.id);
                                setFolderMoveClickId(list?.id);
                              }}
                            >
                              {folderMoveClickId === list?.id && loader ? (
                                <>
                                  <Spinner animation="border" />
                                </>
                              ) : (
                                "Move"
                              )}
                            </a>
                          </div> */}
                        </div>
                      </ListGroup.Item>
                    </>
                  )}
                </>
              );
            })
          ) : (
            <ListGroup.Item>No folder found!</ListGroup.Item>
          )}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelbtn me-3 " size="sm" onClick={() => onHide()}>
          Cancel
        </Button>
        <Button
          className="sharebtndanger"
          size="sm"
          onClick={() => onMoveClick(onFolderSelect)}
        >
          Move
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MoveFoldersPopup;
