import { Button, Form } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import { BsCalendarDateFill } from "react-icons/bs";
import { FaSignature, FaSuitcase, FaUser } from "react-icons/fa";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLocationOn,
  MdOutlineAlternateEmail,
} from "react-icons/md";
import { useContract } from "../Contracts/ContractProvider";
import { useEffect, useState } from "react";
import Select from "react-select";
import AddCoordinatePdfViewer from "../../components/AddCoordinatePdfViewer";
import { updateText } from "../../utils";

const DesignateField = () => {
  const context = useContract();
  const [activePage, setACtivePage]: [string, Function] = useState("all");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [pagesTypeNumber, setPagesTypeNUmber] = useState<any>([]);
  const [isNameREquired, setIsNameRequired]: [boolean, Function] =
    useState(false);
  const [isDateRequired, setIsDateRequired]: [boolean, Function] =
    useState(false);
  const [isEmailRequired, setIsEmailRequired]: [boolean, Function] =
    useState(false);
  const [isInitialRequired, setIsInitialRequired]: [boolean, Function] =
    useState(false);
  const [isDesiginationRequired, setIsDesiginationRequired] = useState(false);
  const [signatureType, setSignatureType]: [boolean, Function] =
    useState(false);
  const [locationRequired, setLocationRequired]: [boolean, Function] =
    useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const [changeSignatoryField, setChangeSignatoryField]: [any, Function] =
    useState();

  const [totalPges, setTotalPages] = useState<any>(1);
  const [saveDetailClick, setSaveDetailClick]: [boolean, Function] =
    useState(false);
  const [isCoordinateAdded, setIsCoordinateAdded]: [boolean, Function] =
    useState(false);
  const [numberOfPages, setNumberOfPages]: [any, Function] = useState();
  // const [signatoryLists, setSignatoryLists]: [any, Function] = useState([]);
  const newExternal: any = context?.data?.externalUser
    .filter((item: any) => item.access === "SIGNER")
    .map((item: any) => ({
      name: updateText(`${item?.name}`),
      label: updateText(`${item?.name}`),
      value: item?.name,
      id: item?._id,
      status: item?.status,
      email: item?.email,
      // lastName: "",
    }));
  const [signatoryLists, setSignatoryLists]: [any, Function] = useState(
    newExternal.concat(
      context?.data?.internalSingers?.map((item: any) => ({
        name: updateText(`${item?.user?.name}`),
        id: item?._id,
        label: updateText(`${item?.user?.name} `),
        value: item?.user?.name,
        status: item?.status,
        email: item?.user?.email,
        // lastName: item?.user?.last_name,
      }))
    )
  );

  useEffect(() => {
    const newExternal: any = context?.data?.externalUser
      .filter((item: any) => item.access === "SIGNER")
      .map((item: any) => ({
        name: updateText(`${item?.name}`),
        label: updateText(`${item?.name}`),
        value: item?.name,
        id: item?._id,
        status: item?.status,
        email: item?.email,
        // lastName: "",
      }));
    setSignatoryLists(
      newExternal.concat(
        context?.data?.internalSingers?.map((item: any) => ({
          name: updateText(`${item?.user?.name}`),
          id: item?._id,
          label: updateText(`${item?.user?.name} `),
          value: item?.user?.name,
          status: item?.status,
          email: item?.user?.email,
          // lastName: item?.user?.last_name,
        }))
      )
    );
  }, [context?.data?.externalUser, context?.data?.internalSingers]);

  const generateRandomColorWithout = () => {
    const stringToHexColor = (str: string) => {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const color = (hash & 0x00ffffff).toString(16).toUpperCase();
      return "#" + "00000".substring(0, 6 - color.length) + color;
    };

    const excludeColor = "#FF0000";

    const newList = signatoryLists.map((item: any) => {
      let randomColor;
      do {
        randomColor = stringToHexColor(item?.name);
      } while (randomColor === excludeColor);
      return {
        ...item,
        color: randomColor,
      };
    });

    setUpdatedList(newList);
  };

  useEffect(() => {
    generateRandomColorWithout();
  }, [
    context?.data?.externalUser,
    context?.data?.internalSingers,
    signatoryLists,
  ]);

  const populatePagesType = () => {
    if (context?.data?.pages) {
      let page: any = context?.data?.pages;

      const newPagesType: any = [];
      for (let i = 1; i <= page; i++) {
        newPagesType.push({ value: i.toString(), label: i.toString() });
      }

      if (activePage === "all") {
        setPagesTypeNUmber(newPagesType);
      } else if (activePage === "last") {
        setPagesTypeNUmber([newPagesType[newPagesType.length - 1]]);
      } else if (activePage === "custom") {
        setPagesTypeNUmber(newPagesType);
      }
    }
  };

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles: any = {
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "rgba(0, 0, 0, 0.1)"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "gray",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "rgba(0, 0, 0, 0.3)"
            : undefined,
        },
      };
    },
    input: (styles: any) => ({ ...styles, ...dot() }),
    placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(data.color),
    }),
  };

  useEffect(() => {
    populatePagesType();
  }, [activePage]);

  return (
    <div className="mb-4">
      <div className=" Approver_box_new row">
        <div className="d-flex justify-content-center px-3 py-3">
          <div className=" d-flex align-items-center">
            <div>
              <h6 className="m-0 fw-semibold">Add coordinates</h6>
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center ">
        <div className="  linemiddle"></div>
      </div>

      <div className=" row p-0 h-100 border" style={{ minHeight: "100vh" }}>
        <div className=" col-md-8 col-12 " style={{ background: "#fafafa" }}>
          <div className="d-flex  pt-5 flex-column align-items-center">
            <div
              className="  d-flex justify-content-center align-items-center"
              style={{
                width: "594.96px",
                height: "841.92px",
              }}
            >
              {context?.data?.filePath && (
                <AddCoordinatePdfViewer
                  activePage={activePage}
                  file={context?.data?.filePath}
                  color={changeSignatoryField?.color}
                  userDetail={changeSignatoryField}
                  page={totalPges}
                  saveDetailClick={saveDetailClick}
                  isNameREquired={isNameREquired}
                  setIsNameRequired={setIsNameRequired}
                  isDateRequired={isDateRequired}
                  setIsDateRequired={setIsDateRequired}
                  isInitialRequired={isInitialRequired}
                  setIsInitialRequired={setIsInitialRequired}
                  isEmailRequired={isEmailRequired}
                  setIsEmailRequired={setIsEmailRequired}
                  isDesiginationRequired={isDesiginationRequired}
                  setIsDesiginationRequired={setIsDesiginationRequired}
                  setSignatureType={setSignatureType}
                  signatureType={signatureType}
                  setLocationRequired={setLocationRequired}
                  locationRequired={locationRequired}
                  setSaveDetailClick={setSaveDetailClick}
                  selectedOptions={selectedOptions}
                  setIsCoordinateAdded={setIsCoordinateAdded}
                  isCoordinateAdded={isCoordinateAdded}
                  setNumberOfPages={setNumberOfPages}
                />
              )}
            </div>
            <div
              className=" d-flex align-items-center justify-content-between pt-3"
              style={{ minWidth: "594.96px" }}
            >
              <h6 className=" fw-light m-0">
                Go to page{" "}
                <span
                  className=" border mx-2 px-2"
                  style={{
                    background: "#fefefe",
                  }}
                >
                  <input
                    value={totalPges}
                    style={{ width: "25px", border: "none" }}
                  />
                </span>
                <span style={{ color: "#a8aab7" }}>
                  of {context?.data?.pages} pages
                </span>{" "}
              </h6>
              <div>
                <Button
                  size="sm"
                  disabled={totalPges === 1}
                  onClick={() => {
                    setTotalPages(totalPges - 1);
                  }}
                  className="pbtn p-0"
                >
                  <span className=" me-2">
                    <MdKeyboardArrowLeft color="#a8aab7" fontSize={24} />
                  </span>{" "}
                  Previous
                </Button>
                <Button
                  size="sm"
                  disabled={totalPges === numberOfPages}
                  onClick={() => {
                    setTotalPages(totalPges + 1);
                  }}
                  className="pbtn p-0 ms-4"
                >
                  Next{" "}
                  <span className=" ms-2">
                    <MdKeyboardArrowRight color="#a8aab7" fontSize={24} />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-4 col-12 p-3">
          <div>
            <h6>Signatory</h6>
            <div>
              <Select
                placeholder={"Select signatory"}
                classNamePrefix={"select-wrapper"}
                defaultValue={updatedList[0]}
                options={updatedList}
                styles={colourStyles}
                onChange={(e) => {
                  setChangeSignatoryField(e);
                  setIsCoordinateAdded(false);
                  setIsNameRequired(false);
                  setIsDateRequired(false);
                  setIsEmailRequired(false);
                  setIsInitialRequired(false);
                  setIsDesiginationRequired(false);
                  setLocationRequired(false);
                  setSignatureType(false);
                }}
              />
            </div>
          </div>

          <div className=" pt-4">
            <div className="col-md-12 col-12 mt-2">
              <h6>Pages</h6>

              <Form.Check
                type="radio"
                id={`all-${1}`}
                label="All Pages"
                checked={activePage === "all"}
                onChange={() => {
                  setACtivePage("all");
                  setTotalPages(1);
                }}
              />
              <Form.Check
                type="radio"
                id={`last-${1}`}
                label="Last Page"
                checked={activePage === "last"}
                onChange={() => {
                  setACtivePage("last");
                  setTotalPages(context?.data?.pages);
                }}
              />
              <Form.Check
                type="radio"
                id={`custom-${1}`}
                label="Select Pages"
                checked={activePage === "custom"}
                onChange={() => setACtivePage("custom")}
              />

              {activePage === "custom" && (
                <>
                  <div className="col-md-12 col-12 mt-2">
                    <Select
                      className="basic-single "
                      isMulti
                      placeholder="Select pages..."
                      defaultValue={selectedOptions[0]}
                      options={pagesTypeNumber}
                      onChange={(data: any) => {
                        setTotalPages(
                          parseInt(data?.[0]?.value ? data?.[0]?.value : 1)
                        );
                        setSelectedOptions(data);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <hr />

          <div className="pt-3">
            {context?.data?.sign_type === "e-Signature" ? (
              <>
                <h6>Drag & Drop</h6>
                <div
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  }  ${isNameREquired ? "active" : ""}  p-2 `}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                  onClick={() => {
                    setIsNameRequired(!isNameREquired);
                    setIsCoordinateAdded(false);
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1 ">
                    <FaUser color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Name</h6>
                </div>
                <div
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${signatureType ? "active" : ""} p-2 `}
                  onClick={() => {
                    setSignatureType(!signatureType);
                    setIsCoordinateAdded(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <AiFillEdit color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Signature</h6>
                </div>
                <div
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${isInitialRequired ? "active" : ""} p-2 `}
                  onClick={() => {
                    setIsInitialRequired(!isInitialRequired);
                    setIsCoordinateAdded(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <FaSignature color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Initial</h6>
                </div>
                <div
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${isDateRequired ? "active" : ""} p-2 `}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                  onClick={() => {
                    setIsDateRequired(!isDateRequired);
                    setIsCoordinateAdded(false);
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <BsCalendarDateFill color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Date</h6>
                </div>
                <div
                  className={`" d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${isEmailRequired ? "active" : ""} p-2 `}
                  onClick={() => {
                    setIsEmailRequired(!isEmailRequired);
                    setIsCoordinateAdded(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <MdOutlineAlternateEmail
                      color={changeSignatoryField?.color}
                    />
                  </div>
                  <h6 className=" ms-3 mb-0">Email Address</h6>
                </div>
                <div
                  className={`d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${isDesiginationRequired ? "active" : ""} p-2 `}
                  onClick={() => {
                    setIsDesiginationRequired(!isDesiginationRequired);
                    setIsCoordinateAdded(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <FaSuitcase color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Designation</h6>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${locationRequired ? "active" : ""} p-2 `}
                  onClick={() => {
                    setLocationRequired(!locationRequired);
                    setIsCoordinateAdded(false);
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <MdLocationOn color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Location</h6>
                </div>
              </>
            ) : (
              <>
                <div
                  className={` d-flex align-items-center mt-3 ${
                    changeSignatoryField ? "changeSignatoryField" : ""
                  } ${signatureType ? "active" : ""} p-2 `}
                  onClick={() => {
                    setSignatureType(!signatureType);
                    setIsCoordinateAdded(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: changeSignatoryField ? "gray" : "lightgray",
                  }}
                >
                  <div className=" fliedIcon d-flex justify-content-center align-items-center rounded-1">
                    <AiFillEdit color={changeSignatoryField?.color} />
                  </div>
                  <h6 className=" ms-3 mb-0">Signature</h6>
                </div>
              </>
            )}
          </div>
          <div className=" d-flex justify-content-end mt-4">
            <Button
              className="savebtn"
              // disabled={confirmed}
              onClick={() => {
                setSaveDetailClick(true);
                setIsCoordinateAdded(false);
                // setConfirmed(true);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignateField;
