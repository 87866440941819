import { useEffect } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { generatePath, Link, useLocation } from "react-router-dom";
import { Template } from "../../../lib/Template";
import { routes } from "../../../utils/routes";
import { useTemplates } from "../ListTemplatesProvider";
import RecentTemplateSkeleton from "./RecentTemplateSkeleton";
import RecentTemplatesList from "./RecentTemplatesList";
import { StatusEnum } from "../../../interfaces/StatusEnum";

function RecentTemplates({
  isCreateContractPopup,
  setIsActive,
  isAction,
  selectedTemplateId,
  setSelectedTemplateId,
  moveToFolders,
  setMoveToFolders,
  setSelectedValues,
  selectedValues,
}: {
  isCreateContractPopup?: boolean;
  setIsActive?: Function;
  isAction?: boolean;
  setSelectedTemplateId?: Function;
  selectedTemplateId?: string;
  moveToFolders: boolean;
  setMoveToFolders: Function;
  setSelectedValues: Function;
  selectedValues: Template[];
}) {
  const location = useLocation();
  const context = useTemplates();

  const onListTemplateClass = () => {
    let key = "";
    switch (key) {
      default:
        if (isMobileOnly) {
          return "d-flex template-width flex-column align-items-center";
        } else if (isTablet && isCreateContractPopup) {
          return "d-flex flex-wrap ";
        } else if (isCreateContractPopup) {
          return "d-flex flex-wrap my-4";
        } else {
          return "d-flex template-width";
        }
        return "";
    }
  };

  useEffect(() => {
    if (isCreateContractPopup) {
      context?.changeFilter({
        ...context.filterParams,
        status: StatusEnum?.published,
      });
    }
  }, []);

  const handleCheckboxChange = (value: any) => {
    if (selectedValues.includes(value)) {
      let vals = [...selectedValues];
      let idx = vals.indexOf(value);
      vals.splice(idx);
      setSelectedValues([...vals]);
    } else {
      let vals = [...selectedValues];
      vals.push(value);
      setSelectedValues([...vals]);
    }
  };

  return (
    context && (
      <>
        {!isCreateContractPopup && (
          <div className="d-flex mt-4 mb-1 mx-2 ">
            <h6 className="contract-title pb-1 m-0"> Recent Templates</h6>
          </div>
        )}

        <div className="recent-temp-div">
          {!context?.recentTemplates ? (
            <>
              <div
                className={
                  isMobileOnly
                    ? " d-flex template-width flex-column align-items-center"
                    : "d-flex template-width"
                }
              >
                {Array.from(Array(10).keys()).map((num: number) => (
                  <div className="recent-templete-skeleton" key={num}>
                    <RecentTemplateSkeleton />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={onListTemplateClass()}>
              {context?.recentTemplates.map((template: Template) =>
                !moveToFolders && !location.pathname.includes("folder") ? (
                  <Link
                    to={generatePath(routes.overview, {
                      id: template.id,
                    })}
                    className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
                    key={template.id}
                  >
                    <RecentTemplatesList
                      template={template}
                      setIsActive={setIsActive}
                      isAction={isAction}
                      setSelectedTemplateId={setSelectedTemplateId}
                      selectedTemplateId={selectedTemplateId}
                      isCreateContractPopup={isCreateContractPopup}
                      setMoveToFolders={setMoveToFolders}
                    />
                  </Link>
                ) : (
                  <span
                    key={template.id}
                    onClick={() => handleCheckboxChange(template)}
                    className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
                  >
                    <RecentTemplatesList
                      template={template}
                      setIsActive={setIsActive}
                      isAction={isAction}
                      setSelectedTemplateId={setSelectedTemplateId}
                      selectedTemplateId={selectedTemplateId}
                      isCreateContractPopup={isCreateContractPopup}
                      setMoveToFolders={setMoveToFolders}
                    />
                  </span>
                )
              )}
            </div>
          )}
        </div>
      </>
    )
  );
}

export default RecentTemplates;
