import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";
function LineChart({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
  setIsLoadingData,
  isLoadingData,
}: any) {
  const context = useAnalytics();
  const [graphData, setGraphData]: [[], Function] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();
  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const [noData, setNoData]: [boolean, Function] = useState(false);
  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const handleAvgExpiryTypeData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleAvgExpiryType(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setIsUpdate(false);
        isLoadingData[4] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          setNoData(false);
          let data = response?.data?.map((item: any) => ({
            title: item?.category,
            category: item?.category?.substr(0, 3),
            average_days: item?.average_days,
            min_days: item?.min_days,
            max_days: item?.max_days,
          }));
          setGraphData(data);
        } else {
          setNoData(true);
        }
      }
      // let res = await context?.getMatrics();
      let selectedGraphType: any = updatedValue?.find(
        (el: any) => el?.name === title
      );
      if (
        selectedGraphType?.type === "Pie" ||
        selectedGraphType?.type === "Donut" ||
        selectedGraphType?.type === "Funnel"
      ) {
        const dataWithColors = graphData.map((item: any) => ({
          ...item,
          id: item?.category?.substring(0, 3),
          color: stringToHexColor(item?.category?.substring(0, 3)),
          value: item?.count,
        }));

        setGraphData(dataWithColors);
      } else if (selectedGraphType?.type === "Diverging Stacked") {
        let data = response?.data?.map((item: any) => ({
          title: item?.category,
          category: item?.category?.substr(0, 3),
          average_days: item?.average_days,
          min_days: item?.min_days,
          max_days: item?.max_days,
        }));
        setGraphData(data);
      } else if (
        selectedGraphType?.type === "Horizontal Bar" ||
        selectedGraphType?.type === "Vertical Bar"
      ) {
      } else {
        let data = response?.data?.map((item: any) => ({
          title: item?.category,
          category: item?.category?.substr(0, 3),
          average_days: item?.average_days,
          min_days: item?.min_days,
          max_days: item?.max_days,
        }));
        setGraphData(data);
      }
      setLoader(false);
      setGraphType(selectedGraphType);
    } catch (error) {}
  };

  // const getMatrics = async () => {
  //   try {
  //     let response = await context?.getMatrics();

  //     if (response?.success) {
  //       setUpdatedMatricsList(response?.metrics);
  //     }
  //     return response;
  //   } catch (error) {}
  // };

  useEffect(() => {
    handleAvgExpiryTypeData();
    setUpdatedMatricsList(updatedValue);
    // getMatrics();
  }, [isUpdate, filterData, updatedValue]);

  return (
    <>
      {loader ? (
        <span>loading...</span>
      ) : (
        <>
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType?.type === "Diverging Stacked" ? (
                    <LineChartGraph data={graphData} />
                  ) : graphType?.type === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={graphData} />
                  ) : graphType?.type === "Pie" ? (
                    <PirChart data={graphData} />
                  ) : graphType?.type === "Donut" ? (
                    <DonutChart data={graphData} />
                  ) : graphType?.type === "Vertical Bar" ? (
                    <VerticalBarChartData data={graphData} />
                  ) : (
                    <LineChartGraph data={graphData} />
                  )}
                </>
              )}
            </>
          ) : updatedMatricsList?.filter(
              (item: any) => item.name === title && item?.is_enabled === true
            )?.length === 0 ? (
            <AnalyticsNoGraph />
          ) : (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType?.type === "Diverging Stacked" ? (
                    <LineChartGraph data={graphData} />
                  ) : graphType?.type === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={graphData} />
                  ) : graphType?.type === "Pie" ? (
                    <PirChart data={graphData} />
                  ) : graphType?.type === "Donut" ? (
                    <DonutChart data={graphData} />
                  ) : graphType?.type === "Vertical Bar" ? (
                    <VerticalBarChartData data={graphData} />
                  ) : (
                    <LineChartGraph data={graphData} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default LineChart;

const LineChartGraph = ({ data }: { data: [] }) => {
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveBar
        data={data}
        keys={["max_days", "min_days"]}
        indexBy="category"
        margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
        padding={0.5}
        colors={{ scheme: "set1" }}
        borderColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
        enableGridX={true}
        enableGridY={true}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          legend: "Categories",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          legend: "Values",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
        tooltip={CustomTooltip}
      />
    </div>
  );
};

const HorizontailBarChartData = ({ data }: { data: any }) => {
  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const dataWithColors = data?.map((item: any) => ({
    ...item,
    color: stringToHexColor(item?.category?.substring(1, 3)),
  }));

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveBar
        data={dataWithColors}
        keys={["average_days"]}
        indexBy="category"
        margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
        padding={0.5}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Average days",
          legendPosition: "middle",
          legendOffset: 40,
        }}
        groupMode="grouped"
        layout="horizontal"
        enableGridY={false}
        enableGridX={true}
        colors={(d: any) => d.data.color} // Specify the color directly from data
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Category",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: 12,
              },
            },
          },
        }}
        tooltip={CustomTooltip}
      />
    </div>
  );
};

const VerticalBarChartData = ({ data }: { data: [] }) => {
  const stringToHexColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - color.length) + color;
  };

  const dataWithColors = data.map((item: any) => ({
    ...item,
    color: stringToHexColor(item?.category?.substring(1, 3)),
  }));

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveBar
        data={dataWithColors}
        keys={["average_days"]}
        indexBy="category"
        margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
        padding={0.5}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "average_days",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        groupMode="grouped"
        enableGridY={false}
        enableGridX={true}
        colors={(d: any) => d.data.color} // Specify the color directly from data
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "category",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: 12,
              },
            },
          },
        }}
        tooltip={CustomTooltip}
      />
    </div>
  );
};

const PirChart = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                background: "#fafafa",
              }}
            >
              <strong>{datum.data.category}</strong>
              <br />
              <strong>count: {datum.data.count}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: [] }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          innerRadius={0.6}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                // datum.color,
                background: "#fafafa",
              }}
            >
              <strong>{datum.data.category}</strong>
              <br />
              <strong>count: {datum.data.count}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const CustomTooltip = ({ data }: any) => (
  <div
    style={{
      background: "white",
      padding: "10px",
      border: "1px solid #ccc",
    }}
  >
    <strong>{data.title}</strong>
    <br />
    Average days:{data.average_days}
    <br />
    Min days:{data.min_days || 0} / Max days:{data.max_days || 0}
  </div>
);
