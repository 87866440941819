import DataTable from "react-data-table-component";
import NoTemplateFound from "../../Accounts/NoDataFound";
import { useEffect, useState } from "react";
import { useAdminAnalytics } from "../AdminAnalyticsProvider";
import { updateText } from "../../../../utils";
import moment from "moment";

function SignedContractsTable(filterData: any) {
  const context = useAdminAnalytics();
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);
  const [contractsDetail, setContractsDetail]: [any, Function] = useState();
  const [metaData, setMetaDeta]: [any, Function] = useState();

  const getOrganistaion = async () => {
    try {
      let response = await context?.getSignedContracts({
        page: page,
        limit: limit,
        filterData: filterData ? filterData : "",
      });

      if (response?.success) {
        setContractsDetail(response?.contracts);
        setMetaDeta(response?.meta);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getOrganistaion();
  }, [page, limit, filterData]);

  function getMonthArray(data: any) {
    const d1: any = new Date(data?.start_date);
    const d2: any = new Date(data?.expiry_date);
    const ydiff: any = d2.getYear() - d1.getYear();
    const mdiff: any = d2.getMonth() - d1.getMonth();
    const diff = ydiff * 12 + mdiff;
    const arr: any = [];
    for (let i = 0; i <= diff; i++) {
      if (i === 0) d1.setMonth(d1.getMonth());
      else d1.setMonth(d1.getMonth() + 1);
      arr[i] = {
        month: moment(d1).format("MMMM"),
        year: moment(d1).format("YYYY"),
      };
    }
    return arr;
  }

  const columns = [
    {
      name: "Contract name",
      selector: (row: any) => updateText(row?.name?.replace("_", " ")),
    },
    {
      name: "Organization",
      selector: (row: any) => updateText(row?.created_by?.organization) || "-",
    },
    {
      name: "Term",
      selector: (row: any) =>
        `${getMonthArray(row)?.length} ${
          getMonthArray(row)?.length > 1 ? "months" : "month"
        }` || "-",
    },
    {
      name: "Values",
      selector: (row: any) =>
        parseInt(
          row?.contract_value === null ? 0 : row?.contract_value
        )?.toLocaleString("en-IN", {
          style: "currency",
          currency: row?.currency_type,
        }) || "-",
    },
    {
      name: "Expired Date",
      selector: (row: any) =>
        moment(row.expiry_date).format("DD/MM/YYYY") || "-",
    },
    {
      name: "Year of Agreement",
      selector: (row: any) => moment(row.start_date).format("YYYY") || "-",
    },
  ];

  return (
    <>
      {contractsDetail?.length > 0 ? (
        <div className="mt-4 ps-3  mx-2 px-3">
          <DataTable
            className="data-table"
            columns={columns}
            data={contractsDetail || []}
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            paginationServer
            progressComponent={<>Loading...</>}
            paginationDefaultPage={1}
            onChangeRowsPerPage={(currentRowsPerPage: number) => {
              setLimit(currentRowsPerPage);
            }}
            paginationPerPage={metaData.limit}
            onChangePage={(page: number) => {
              setPage(page);
              setLimit(10);
            }}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationTotalRows={metaData?.total_count}
          />
        </div>
      ) : (
        <NoTemplateFound />
      )}
    </>
  );
}

export default SignedContractsTable;
