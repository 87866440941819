import { useState } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { FiFilter } from "react-icons/fi";
import AnalyticsFilter from "./AnalyticsFilter";
import CustomAnalytics from "./CustomAnalytics";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../ui/FailureToast";

function AnalyticsMobileFilter({
  setIsUpdate,
  setFilterData,
  updatedValue,
  getMatricsData,
}: {
  setIsUpdate: Function;
  setFilterData: Function;
  updatedValue: any;
  getMatricsData: Function;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCustom, setOpenCustom] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const isActiveanalyticsSummary: boolean = !!matchPath(
    location.pathname,
    routes.analyticsSummary
  );

  const isActiveanalyticsContactValue: boolean = !!matchPath(
    routes.analyticsContactValue,
    location.pathname
  );
  const isActiveanalyticsTurnaround: boolean = !!matchPath(
    routes.analyticsTurnaround,
    location.pathname
  );
  const isActiveanalyticsTeams: boolean = !!matchPath(
    routes.analyticsTeams,
    location.pathname
  );

  return (
    <>
      <div className="sort-filter-mobile row m-0 sort-filters ">
        <div className=" col">
          <div className=" position-relative row">
            <div className="col p-0 d-flex justify-content-center align-items-center border-end border-2 ">
              <div
                className=" mobileFilterSortName "
                onClick={() => setOpenFilter(!openFilter)}
              >
                Filter
                <span className="ps-2">
                  <FiFilter fontSize={16} />
                </span>
              </div>

              <div
                className={`dropdowncustomMobile dropdowncustomMobileAnalytics ${
                  openFilter ? "active" : "inactive"
                }`}
              >
                <AnalyticsFilter
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  setFilterData={setFilterData}
                />
              </div>
            </div>
            <div className="col p-0 d-flex justify-content-center align-items-center position-relative">
              <div
                className=" mobileFilterSortName"
                onClick={() => setOpenCustom(!openCustom)}
              >
                Customise
                <span className="ps-2">
                  <CgMenuGridR fontSize={22} />
                </span>
              </div>
            </div>
            <div
              className={`dropdowncustomMobile dropdowncustomMobileAnalytics ${
                openCustom ? "active" : "inactive"
              }`}
            >
              <CustomAnalytics
                updatedValue={updatedValue}
                setOpenCustom={setOpenCustom}
                setBodyMessage={setBodyMessage}
                setShowSuccessToast={setShowSuccessToast}
                setShowFailureToast={setShowFailureToast}
                setIsUpdate={setIsUpdate}
                getMatricsData={getMatricsData}
                openCustom={openCustom}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "scroll" }} className=" mt-3">
        <div className="p-header mb-2" style={{ maxWidth: "450px" }}>
          <div style={{ minWidth: "90px" }}>
            <span
              className={` header-css me-4 ${
                isActiveanalyticsSummary ? " active" : ""
              }`}
              onClick={() => navigate(routes?.analyticsSummary)}
            >
              Summary
            </span>
          </div>
          <div
            style={{ minWidth: "120px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTurnaround ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsTurnaround)}
          >
            Turnaround Time
          </div>
          <div
            style={{ minWidth: "50px" }}
            className={`header-css me-4 ${
              isActiveanalyticsTeams ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsTeams)}
          >
            <span>Teams</span>
          </div>
          <div
            style={{ minWidth: "110px" }}
            className={` header-css me-4 ${
              isActiveanalyticsContactValue ? " active" : ""
            }`}
            onClick={() => navigate(routes?.analyticsContactValue)}
          >
            Contract Value
          </div>
        </div>
      </div>

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
}

export default AnalyticsMobileFilter;
