import { format } from "date-fns";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import AvatarSection from "../../../../../ui/Avatar";
import { Dropdown, Button } from "react-bootstrap";
import { useAuthentication } from "../../../../../modules/Authentication/AuthenticationProvider";
// import { BiCheckCircle } from "react-icons/bi";

export default function CommentRow({
  // k,
  comment: {
    author,
    text,
    creationTime,
    commentID,
    authorID,
    // mentions,
    // access,
    resolved,
  },
  context,
  background,
  showVerticalDots,
}: // status,
// onToggleStatus,
any) {
  const authContext = useAuthentication();
  const [showInputBox, setShowInputBox] = useState(false);
  const [commnentText, setCommentText] = useState(text);

  const updateCommentText = async () => {
    try {
      const params = {
        value: [commnentText],
      };
      await context?.updateComment(commentID, params);
      setShowInputBox(false);
    } catch (error) {}
  };
  const DeleteCommentText = async () => {
    try {
      const params = {
        is_delete: true,
      };
      await context?.updateComment(commentID, params);
    } catch (error) {}
  };

  return (
    <div className="comment-row justify-content-between pt-3 ">
      <div className=" w-100">
        <div className="d-flex">
          <div className="comment-author-photo pe-2">
            <i className="bi bi-person-circle comment-author-photo"></i>

            <AvatarSection imageData={""} name={author} />
          </div>
          <div className=" flex-grow-1 pt-2">
            <div className=" d-flex align-items-center justify-content-between ">
              <div className=" d-flex align-items-center">
                <span className="comment-author-name">{author}</span>

                {/* <span className="commentbage rounded-4 px-2 ms-2 fw-semibold"> */}
                  {/* {k === 0
                    ? access === "TEAMS"
                      ? "My Team"
                      : access === "PRIVATE"
                      ? "Only to me"
                      : "External"
                    : null} */}
                  {/* {
                      !showConnector && access === "PRIVATE" && mentions.length > 0
                      ? "My Team"
                      : authContext?.currentUser?.id === authorID &&
                        access === "PRIVATE"
                      ? "Only to me"
                      : "External"
                    } */}
                  {/* {access === "PRIVATE" && mentions.length > 0
                    ? "My Team"
                    : authContext?.currentUser?.id === authorID &&
                      access === "PRIVATE"
                    ? "Only to me"
                    : "External"} */}
                {/* </span> */}
              </div>
              <div className=" d-flex align-items-center">
                <div className="resolve">
                  <Button size="sm" variant="resolvebtn p-0 mx-2">
                    <span></span>
                  </Button>
                </div>
                <div className=" Actions ">
                  {authorID === authContext?.currentUser?.id && (
                    <Dropdown drop="end">
                      <Dropdown.Toggle
                        variant="success"
                        className="Actions_title"
                        id="dropdown-basic"
                      >
                        {!resolved && showVerticalDots && (
                          <BsThreeDotsVertical fontSize={20} color="#c5d0de" />
                        )}
                      </Dropdown.Toggle>
                      <>
                        <Dropdown.Menu className="create-dropdown-css">
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => DeleteCommentText()}
                          >
                            Delete
                          </Dropdown.Item>

                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => setShowInputBox(!showInputBox)}
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>

            <div className="comment-text  py-2 rounded-3  fw-normal">
              {showInputBox ? (
                <>
                  <textarea
                    value={commnentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    onBlur={() => updateCommentText()}
                    rows={4}
                    cols={50}
                    id="textAreaID"
                  />
                </>
              ) : (
                <>
                  <span
                    style={{
                      backgroundColor: background && "yellow",
                      display: "inline-block",
                      width: "15vw",
                    }}
                    id="output"
                  >
                    {text.split("\n").map((line: any, index: any) => (
                      <span key={index}>
                        {line.split(" ").map((word: any, index: any) =>
                          word.startsWith("@") ? (
                            <span key={index} style={{ color: "blue" }}>
                              {word + " "} 
                            </span>
                          ) : (
                            <span key={index}>{word + " "}</span>
                          )
                        )}
                        {index < text.split("\n").length - 1 && <br />}
                      </span>
                    ))}
                  </span>
                </>
              )}
            </div>
            <div className=" d-flex align-items-center pt-2">
              <span className="comment-creation-time  fw-semibold">
                {format(creationTime, "eee MM/dd/yy    H:mm")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
