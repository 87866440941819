import Select from "react-select";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { customStyles } from "../../../ui/CustomStyles";
import { useAdminAnalytics } from "./AdminAnalyticsProvider";
import AnalyticsRange from "../../Analytics/AnalyticsRange";

const AdminAnalyticsFilter = ({
  setOpenFilter,
  setFilterData,
  openFilter,
}: {
  setOpenFilter: Function;
  setFilterData: Function;
  openFilter: any;
}) => {
  const context = useAdminAnalytics();
  const [organisationList, setOrganisationList]: [any, Function] = useState();
  const [statusValue, setSatusValue]: [any, Function] = useState();
  const [organisationValue, setorganisationValue]: [any, Function] = useState(
    {}
  );
  const [minValue, setMinValue] = useState<any>(225);
  const [maxValue, setMaxValue] = useState<any>(678);
  // const categoryRef: any = useRef(null);

  const getAdminAccountList = async () => {
    try {
      let response = await context?.getOrganistaionInfo();
      if (response?.success) {
        setOrganisationList(response?.accounts);
      }
    } catch (error) {}
  };

  const status = [
    { value: "DRAFT", label: "DRAFT" },
    { value: "PUBLISHED", label: "PUBLISHED" },
    { value: "PENDING_APPROVAL", label: "PENDING_APPROVAL" },
    { value: "APPROVAL_REQUESTED", label: "APPROVAL_REQUESTED" },
    { value: "SIGN_INITIATED", label: "SIGN_INITIATED" },
    { value: "SIGNATURE_REQUESTED", label: "SIGNATURE_REQUESTED" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "STAMPED", label: "STAMPED" },
    { value: "OWNED", label: "OWNED" },
    { value: "EDITOR", label: "EDITOR" },
    { value: "VIEWER", label: "VIEWER" },
    { value: "EXPIRED", label: "EXPIRED" },
  ];

  useEffect(() => {
    getAdminAccountList();
  }, []);

  const handleAnalyticsFilter = () => {
    if (statusValue || organisationValue) {
      let filterData = {
        status: statusValue ? statusValue : "",
        organization_id: organisationValue
          ? organisationValue?.option?.value
          : "",
        contract_value_start: minValue ? minValue : "",
        contract_value_end: maxValue ? maxValue : "",
      };
      setFilterData(filterData);
      setOpenFilter(false);
    }
  };

  const reset = () => {
    setorganisationValue(null);
    setSatusValue(null);
    setFilterData(null);
  };

  return (
    <>
      {openFilter && (
        <>
          {" "}
          <div className=" py-3 ">
            <div className=" d-flex justify-content-between align-items-center border-bottom pb-4">
              <h6 className=" m-0 fw-semibold">Filter</h6>
              <h6
                className=" m-0"
                style={{
                  color: "#e15554",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => reset()}
              >
                Reset all
              </h6>
            </div>
            <div className="  pb-4 border-bottom">
              <div className=" row ">
                <div className=" mt-4 pb-4">
                  <h6 className=" fw-semibold">Contract Value</h6>
                  <div>
                    <AnalyticsRange
                      setMinValue={setMinValue}
                      setMaxValue={setMaxValue}
                      minValue={minValue}
                      maxValue={maxValue}
                    />
                  </div>
                </div>
              </div>
              <div className=" mt-4  ">
                <h6 className=" fw-semibold"> Organization</h6>
                <div>
                  <Select
                    styles={customStyles}
                    classNamePrefix="selectCustom"
                    className={` basic-multi-select `}
                    value={{
                      label: organisationValue?.option?.label,
                      value: organisationValue?.option?.value,
                    }}
                    options={(organisationList || [])?.map(
                      (organisation: any) => ({
                        label: organisation?.name,
                        value: organisation?.id,
                      })
                    )}
                    onChange={(option: any) => {
                      setorganisationValue({ option });
                    }}
                    placeholder="Select here"
                  />
                </div>
              </div>

              <div className=" mt-4  ">
                <h6 className=" fw-semibold"> Status</h6>
                <div>
                  <Select
                    styles={customStyles}
                    classNamePrefix="selectCustom"
                    className={` basic-multi-select `}
                    defaultValue={statusValue || ""}
                    value={{
                      label: statusValue,
                      value: statusValue,
                    }}
                    options={(status || [])?.map((status: any) => ({
                      label: status?.label,
                      value: status?.value,
                    }))}
                    onChange={(option: any) => {
                      setSatusValue(option?.value);
                    }}
                    placeholder="Select here"
                  />
                </div>
              </div>
            </div>

            <div className=" d-flex justify-content-end align-items-center my-5">
              <Button
                className="cancelbtn me-3 "
                size="sm"
                onClick={() => {
                  setOpenFilter(false);
                  setFilterData(null);
                }}
              >
                Cancel
              </Button>
              <Button
                className="sharebtndanger "
                size="sm"
                onClick={() => handleAnalyticsFilter()}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminAnalyticsFilter;
