import DataTable from "react-data-table-component";
import { TableColumnsInterface } from "../../../interfaces/TableColumnsInterface";
import { useAnalytics } from "../AnalyticsProvider";
import { useEffect, useState } from "react";
import moment from "moment";
// import { Dropdown } from "react-bootstrap";
// import { BsThreeDots } from "react-icons/bs";
import { IMetaResponse } from "../../../interfaces/response/IMetaResponse";
import { useLocation } from "react-router-dom";
import { updateText } from "../../../utils";

function SignedContractsTable({ filterData }: { filterData: any }) {
  const context = useAnalytics();
  const location = useLocation();
  const [metaData, setMetaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 0,
    limit: 0,
  });

  const columns: TableColumnsInterface[] = [
    {
      name: "Contract name",
      minWidth: "200px",
      selector: (row: any) => updateText(row?.name),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Contract value",
      minWidth: "200px",
      selector: (row: any) => (row?.contract_value ? row?.contract_value : "-"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Contract type",
      minWidth: "250px",
      selector: (row: any) => (row?.category ? row?.category : "-"),
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Owner",
      minWidth: "200px",
      selector: (row: any) =>
        updateText(`${row?.created_by?.name} ${row?.created_by?.last_name}`),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Last updated",
      minWidth: "200px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Contract expiry date",
      minWidth: "200px",
      selector: (row: any) =>
        moment(row?.expiry_date).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Contract term",
      minWidth: "200px",
      selector: (row: any) => updateText(row?.department) || "-",
      grow: 0,
      reorder: true,
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   width: "130px",
    //   grow: 0,
    //   reorder: true,
    //   sortable: true,
    //   cell: () => {
    //     return (
    //       <div className="pt-2 Actions">
    //         <Dropdown drop="end">
    //           <Dropdown.Toggle
    //             variant="success"
    //             className="Actions_title"
    //             id="dropdown-basic"
    //           >
    //             <BsThreeDots fontSize={24} />{" "}
    //           </Dropdown.Toggle>

    //           <Dropdown.Menu className="create-dropdown-css">
    //             <Dropdown.Item className="sort-by">View</Dropdown.Item>
    //             <Dropdown.Item className="sort-by">
    //               <a
    //                 href={""}
    //                 download
    //                 className=" text-decoration-none Action_download"
    //               >
    //                 Download
    //               </a>
    //             </Dropdown.Item>
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       </div>
    //     );
    //   },
    // },
  ];
  const [FilteredcolumnsData, setFilteredColumnsData]: [
    TableColumnsInterface[],
    Function
  ] = useState(columns);
  const [pages, setPages]: [string, Function] = useState("");
  const [limit, setLimits]: [string, Function] = useState("10");
  const handleSignedContractData = async () => {
    try {
      let response = await context?.getContractDeatils(
        filterData
          ? filterData
          : {
              sort: "-updatedAt",
              limit: limit,
              page: pages,
              status: "COMPLETED",
            }
      );
      setMetaData(response?.meta);

      setFilteredColumnsData(response?.contracts);
    } catch (error) {}
  };

  useEffect(() => {
    if (location.pathname.includes("summary")) {
      handleSignedContractData();
    }
  }, [pages, limit, filterData]);

  return (
    <DataTable
      className="data-table"
      columns={columns}
      data={FilteredcolumnsData?.filter(
        (contracts: any) => contracts?.status === "COMPLETED"
      )}
      pagination
      paginationServer
      paginationDefaultPage={1}
      paginationTotalRows={metaData?.total_count}
      onChangeRowsPerPage={(currentRowsPerPage: number) => {
        setLimits(currentRowsPerPage);
      }}
      onChangePage={(page: number) => setPages(page)}
    />
  );
}

export default SignedContractsTable;
