import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useAuthentication } from "../Authentication/AuthenticationProvider";

const ProfileHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthentication();

  const isActiveProfile: boolean = !!matchPath(
    location.pathname,
    routes.profile
  );

  const isActiveChangePassword: boolean = !!matchPath(
    routes.changePassword,
    location.pathname
  );
  const isActiveCompanyProfile: boolean = !!matchPath(
    routes.companyProfile,
    location.pathname
  );
  const isActiveMySignature: boolean = !!matchPath(
    routes.mysignature,
    location.pathname
  );
  return (
    <div style={{ overflowX: "scroll" }}>
      <div className="d-flex mb-2" style={{ maxWidth: "600px" }}>
        <div style={{ minWidth: "130px" }}>
          <Link
            to={routes?.profile}
            className={`border-0 header-css me-4 ${
              isActiveProfile ? " active" : ""
            }`}
          >
            Personal Profile
          </Link>
        </div>
        <Link
          to={routes?.companyProfile}
          style={{ minWidth: "114px" }}
          className={`border-0 d-inline-block header-css me-4 ${
            isActiveCompanyProfile ? " active" : ""
          }`}
        >
          Company Profile
        </Link>

        <div
          style={{ minWidth: "120px" }}
          className={` header-css me-4 ${
            isActiveChangePassword ? " active" : ""
          }`}
          onClick={() => navigate(routes?.changePassword)}
        >
          Change Password
        </div>
        {authContext?.currentUser?.roles?.find(
          (el: any) => el === "SIGNATORY"
        ) === "SIGNATORY" && (
          <>
            <div
              style={{ minWidth: "100px" }}
              className={`header-css me-4 ${
                isActiveMySignature ? " active" : ""
              }`}
              onClick={() => navigate(routes?.mysignature)}
            >
              <span>My Signature</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
