import { ReactNode } from "react";
import { Button } from "react-bootstrap";
interface FormikSubmitButtonProps {
  children?: ReactNode;
  isSubmitting?: boolean;
  submittingLabel?: ReactNode;
  variant?: string;
  size?: string;
  className?: string;
}
const FormikSubmitButton = ({
  children,
  isSubmitting = false,
  submittingLabel,
  variant = "primary",
  size = "lg",
  className = "",
}: FormikSubmitButtonProps) => {
  return (
    <Button
      variant={variant}
      type="submit"
      disabled={isSubmitting}
      className={`${className ? className : "px-5 rounded-0"} `}
      size={size as any}
      style={
        variant === "primary"
          ? { border: "1px solid #4a48c8" }
          : { border: "1px solid #2abf86" }
      }
    >
      {isSubmitting ? submittingLabel || "submitting..." : children || "Submit"}
    </Button>
  );
};

export default FormikSubmitButton;
