import { ICollaboratorResponse } from "../interfaces/response/ICollaboratorResponse";
import { User } from "./User";

export class Collaborator {
  user: User;
  access: string;

  constructor(params: ICollaboratorResponse) {
    this.user = new User(params.user);
    this.access = params.access;
  }
}
