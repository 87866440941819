import { useEffect, useState } from "react";
import { useAnalytics } from "../AnalyticsProvider";
import { ResponsiveTreeMap } from "@nivo/treemap";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import AnalyticsNoDataFound from "../AnalyticsNoDataFound";
import AnalyticsNoGraph from "../AnalyticsNoGraph";

function ZoomableBubbleChart({
  title,
  isUpdate,
  setIsUpdate,
  filterData,
  setIsLoadingData,
  isLoadingData,
  updatedValue,
}: {
  title: string;
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
  setIsLoadingData: any;
  isLoadingData: any;
}) {
  const context = useAnalytics();
  const [noData, setNoData]: [boolean, Function] = useState(false);
  const [data, setData] = useState([]);
  const [graphType, setGraphType]: [any, Function] = useState();

  const [updatedMatricsList, setUpdatedMatricsList]: [[], Function] = useState(
    []
  );
  const [loader, setLoader]: [boolean, Function] = useState(false);

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleContractByContractTypeData = async () => {
    setLoader(true);
    try {
      let response = await context?.handleContractByContractType(
        filterData ? filterData : ""
      );

      if (response?.success) {
        setIsUpdate(false);
        setNoData(false);
        isLoadingData[3] = true;
        setIsLoadingData([...isLoadingData]);
        if (response?.data?.length > 0) {
          let selectedGraphType: any = updatedValue?.find(
            (el: any) => el?.name === title
          );

          setGraphType(selectedGraphType?.type);

          let items: any = response?.data;

          if (
            selectedGraphType?.type === "Pie" ||
            selectedGraphType?.type === "Donut" ||
            selectedGraphType?.type === "Funnel"
          ) {
            const dataWithColors = items.map((item: any) => ({
              ...item,
              id: item?.category?.substring(0, 3),
              color: getRandomColor(),
              total_contracts: item?.total_contracts,
              value: item?.amount,
            }));

            setData(dataWithColors);
          } else if (
            selectedGraphType?.type === "Horizontal Bar" ||
            selectedGraphType?.type === "Vertical Bar" ||
            selectedGraphType?.type === "Tree Map"
          ) {
            const dataWithColors = items.map((item: any) => ({
              ...item,
              title: item?.category?.substring(0, 3),
              total_contracts: item?.total_contracts,
              category: item?.category,
              color: getRandomColor(),
            }));

            setData(dataWithColors);
          } else {
            setData(items);
          }
          setLoader(false);
        } else if (response?.data?.length === 0) {
          setNoData(true);
          setLoader(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleContractByContractTypeData();
    setUpdatedMatricsList(updatedValue);
  }, [isUpdate, filterData, updatedValue]);

  return (
    <div>
      {loader ? (
        <span>loading...</span>
      ) : (
        <>
          {" "}
          {updatedMatricsList?.length === 0 ? (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Scatter Plot" ? (
                    <ScatterPlot data={data} />
                  ) : graphType === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={data} />
                  ) : graphType === "Vertical Bar" ? (
                    <VerticalBarChartData data={data} />
                  ) : graphType === "Tree Map" ? (
                    <TreeChart data={data} />
                  ) : graphType === "Funnel" ? (
                    <FunnelChart data={data} />
                  ) : graphType === "Pie" ? (
                    <PirChart data={data} />
                  ) : graphType === "Donut" ? (
                    <DonutChart data={data} />
                  ) : (
                    <ScatterPlot data={data} />
                  )}
                </>
              )}
            </>
          ) : updatedMatricsList?.filter(
              (item: any) => item.name === title && item?.is_enabled === true
            )?.length === 0 ? (
            <AnalyticsNoGraph />
          ) : (
            <>
              {noData ? (
                <AnalyticsNoDataFound />
              ) : (
                <>
                  {graphType === "Scatter Plot" ? (
                    <ScatterPlot data={data} />
                  ) : graphType === "Horizontal Bar" ? (
                    <HorizontailBarChartData data={data} />
                  ) : graphType === "Vertical Bar" ? (
                    <VerticalBarChartData data={data} />
                  ) : graphType === "Tree Map" ? (
                    <TreeChart data={data} />
                  ) : graphType === "Funnel" ? (
                    <FunnelChart data={data} />
                  ) : graphType === "Pie" ? (
                    <PirChart data={data} />
                  ) : graphType === "Donut" ? (
                    <DonutChart data={data} />
                  ) : (
                    <ScatterPlot data={data} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ZoomableBubbleChart;

const TreeChart = ({ data }: { data: any }) => {
  let items = data;

  let dataValue = {
    category: "Top 10 Contracts by Contract Value",
    children: items?.map((item: any) => ({
      value: item.amount,
      total_contracts: item.total_contracts,
      category: item.category,
    })),
  };

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveTreeMap
          data={dataValue}
          identity="category"
          value="value"
          enableLabel={true}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          colors={{ scheme: "category10" }}
          borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
          animate={true}
          // tooltip={({ node }: any) => {

          //   return (
          //     <>
          //       <strong
          //         style={{
          //           color: node.color,
          //         }}
          //       >
          //         {node.data.category}
          //       </strong>
          //       <br />
          //       <strong
          //         style={{
          //           color: node.color,
          //         }}
          //       >
          //         {"Total contracts"}: {node.data.total_contracts}
          //       </strong>
          //       <br />
          //     </>
          //   );
          // }}
          // theme={{
          //   tooltip: {
          //     container: {
          //       background: "#333",
          //     },
          //   },
          // }}
        />
      </div>
    </>
  );
};

const HorizontailBarChartData = ({ data }: { data: any }) => {
  const CustomTooltip = ({ data }: any) => (
    <div
      style={{
        background: "white",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <strong>{data.category}</strong>
      <br />
      Value: {data.amount}
      <br />
      Total contracts:{data?.total_contracts}
    </div>
  );

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={data}
          keys={["total_contracts"]}
          indexBy="title"
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          colors={{ scheme: "category10" }}
          enableLabel={false}
          enableGridY
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
          }}
          tooltip={CustomTooltip}
          layout="horizontal"
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "value",
            legendPosition: "middle",
            legendOffset: 32,
          }}
        />
      </div>
    </>
  );
};

const VerticalBarChartData = ({ data }: { data: any }) => {
  const CustomTooltip = ({ data }: any) => (
    <div
      style={{
        background: "white",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    >
      <strong>{data.category}</strong>
      <br />
      Value: {data.amount}
      <br />
      Total contracts:{data?.total_contracts}
    </div>
  );

  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveBar
          data={data}
          keys={["total_contracts"]}
          indexBy="title"
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          colors={{ scheme: "category10" }}
          enableLabel={false}
          enableGridY
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
          }}
          tooltip={CustomTooltip}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "value",
            legendPosition: "middle",
            legendOffset: 32,
          }}
        />
      </div>
    </>
  );
};

const PirChart = ({ data }: { data: any }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                // datum.color,
                background: "#fafafa",
              }}
            >
              <strong>{datum.data.category}</strong>
              <br />
              <strong>count: {datum.data.count}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const DonutChart = ({ data }: { data: any }) => {
  return (
    <>
      <div style={{ height: "390px" }}>
        <ResponsivePie
          data={data}
          innerRadius={0.6}
          margin={{
            top: 80,
            right: 120,
            bottom: 80,
            left: 120,
          }}
          theme={{
            labels: {
              text: {
                fontFamily:
                  "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              },
            },
          }}
          tooltip={({ datum }: any) => (
            <div
              style={{
                padding: 12,
                // datum.color,
                background: "#fafafa",
              }}
            >
              <strong>{datum.data.category}</strong>
              <br />
              <strong>count: {datum.data.count}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

const FunnelChart = ({ data }: { data: any }) => {
  return (
    <>
      <div style={{ width: "100%", height: "400px" }}>
        <ResponsiveFunnel
          data={data}
          direction={"horizontal"}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          colors={{ scheme: "set3" }}
          borderWidth={20}
          enableLabel={true}
          isInteractive={true}
          tooltip={({ part }: any) => (
            <div
              style={{
                padding: 12,
                color: "#fff",
                background: "#222222",
              }}
            >
              <strong>{part.data.category}</strong>
              <br />
              <strong>Total contracts: {part.data.total_contracts}</strong>
            </div>
          )}
        />
      </div>
    </>
  );
};

function ScatterPlot({ data }: { data: any }) {
  const convertedData = data?.map((item: any) => ({
    id: item?.category,
    data: [
      {
        x: item?.amount,
        y: item?.total_contracts,
      },
    ],
  }));

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveScatterPlot
        data={convertedData}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: "linear", min: 0, max: "auto" }}
        xFormat=">-.2f"
        yScale={{ type: "linear", min: 0, max: "auto" }}
        yFormat=">-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Amount",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Total Contract",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: "left-to-right",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
