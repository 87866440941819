import AdminHeader from "../../layouts/AdminHeader";
import AccountLists from "./Accounts/AccountLists";
import { ListAdminAccountsProvider } from "./Accounts/AdminAccountProvider";

const AdminAccountsPage = () => {
  return (
    <AdminHeader>
      <>
        <ListAdminAccountsProvider>
          <AccountLists />
        </ListAdminAccountsProvider>
      </>
    </AdminHeader>
  );
};

export default AdminAccountsPage;
