import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { FormikProvider, useFormik, Form, Field } from "formik";
import FormikField from "../../ui/FormikField";
import { roleOptions } from "./Interface";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import { useEffect, useState } from "react";
import { useMember } from "./MemberProvider";
import { api } from "../../utils/api";
import Select from "react-select";

const InviteMember = ({
  show,
  setShow,
  setShowSuccessToast,
  setShowFailureToast,
  setBodyMessage,
  getListMembers,
  onHide,
  selectedMemberId,
  isView = false,
  isEdit = false,
  setIsResendInvite,
  isResendInvite,
}: {
  show: boolean;
  setShow: Function;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  getListMembers: Function;
  onHide: Function;
  selectedMemberId?: any;
  isView?: boolean;
  isEdit?: boolean;
  setIsResendInvite: Function;
  isResendInvite: any;
}) => {
  const context = useMember();

  const [membersDetail, setMembersDetail]: [any, Function] = useState();
  const [isRoleChange, setIsRoleChange]: [boolean, Function] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),

    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is required"),
    roles: Yup.array().of(Yup.string()).min(1).required(),
    department: Yup.string().required(),
  });
  const handleSubmitData = async (values: any) => {
    if (isResendInvite) {
      const response = await context?.handleResendInvite(selectedMemberId, {
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        department: values?.department,
        roles:
          typeof values?.roles === "string" ? [values?.roles] : values?.roles,
      });

      if (response?.data?.success) {
        getListMembers();
        setShowSuccessToast(true);
        setIsResendInvite(false);
        setBodyMessage(response?.data?.message);
        setIsRoleChange(false);

        formik.resetForm();
        setShow(false);
      } else {
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
      }
    } else {
      const response = isEdit
        ? await context?.handleEditMember(selectedMemberId, {
            first_name: values?.firstName,
            last_name: values?.lastName,
            department: values?.department,
            roles: isRoleChange ? [values?.roles] : values?.roles,
          })
        : await context?.handleInviteMember({
            firstName: values?.firstName?.trim().replace(/\s+/g, " "),
            lastName: values?.lastName,
            department: values?.department,
            roles: values?.roles,
            email: values?.email,
          });

      if (response?.success) {
        getListMembers();
        setShowSuccessToast(true);
        setBodyMessage(
          isEdit
            ? "Member edited successfully.."
            : "Member invited successfully.."
        );
        formik.resetForm();
        setShow(false);
      } else {
        setShowFailureToast(true);
        setBodyMessage(
          response?.error?.[0] === 409 ? "Duplicate user" : "Error"
        );
      }
    }
  };

  const getMemberDetail = async (id: any) => {
    if (isView || isEdit) {
      try {
        let response = await api?.getUserEmail(id);
        if (response) {
          setMembersDetail(response);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (selectedMemberId) {
      getMemberDetail(selectedMemberId);
    }
  }, [selectedMemberId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: isView || isEdit ? membersDetail?.email : "",
      department: isView || isEdit ? membersDetail?.department : null,
      firstName: isView || isEdit ? membersDetail?.first_name : "",
      lastName: isView || isEdit ? membersDetail?.last_name : "",
      roles: isView || isEdit ? membersDetail?.roles : [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { validateForm }: any) => {
      try {
        validateForm(values);
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });

  const {
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    setFieldValue,
  } = formik;

  return (
    <div>
      <Modal
        show={show}
        centered
        size="xl"
        onHide={() => {
          setShow(false);
          setIsResendInvite(false);
        }}
      >
        <Modal.Header closeButton>
          <div className="pt-3">
            <h6 className=" fw-semibold">
              {isView ? (
                "View Member"
              ) : isEdit ? (
                <>{isResendInvite ? "Resend invite" : "Edit Member"}</>
              ) : (
                "Invite Member"
              )}
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body className="pt-2 p-4">
          <FormikProvider value={formik}>
            <Form noValidate={true} onSubmit={handleSubmit}>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="firstName"
                    type="text"
                    validationSchema={validationSchema}
                    label="First Name"
                    placeholder="First Name"
                    errors={errors}
                    value={values?.firstName}
                    isDisabled={isView ? true : false}
                  />
                </div>{" "}
                <div className="col-md-6 col-12">
                  <FormikField
                    name="lastName"
                    type="text"
                    validationSchema={validationSchema}
                    label="Last Name"
                    placeholder="Last Name"
                    errors={errors}
                    value={values?.lastName}
                    isDisabled={isView ? true : false}
                  />
                </div>
                {isResendInvite ? (
                  <>
                    <div className="col-md-6 col-12">
                      <FormikField
                        name="email"
                        type="email"
                        validationSchema={validationSchema}
                        label="Email address "
                        placeholder="Email address "
                        errors={errors}
                        value={values?.email}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-6 col-12">
                      <FormikField
                        name="email"
                        type="email"
                        validationSchema={validationSchema}
                        label="Email address "
                        placeholder="Email address "
                        errors={errors}
                        value={values?.email}
                        isDisabled={selectedMemberId ? true : false}
                      />
                    </div>
                  </>
                )}
                {isView ? (
                  <>
                    <div className="col-md-6 col-12">
                      <label className="text-dark mt-2">Role</label>
                      <Field
                        as="select"
                        id="mySelect"
                        name="roles"
                        className="form-control mt-2"
                        disabled={true}
                      >
                        <option value="" label="Select an option" />
                        {roleOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                          >
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </>
                ) : (
                  <>
                    {isEdit ? (
                      <div className="col-md-6 col-12">
                        <label className="text-dark mt-4">
                          Role <span className="">*</span>
                        </label>
                        <Select
                          value={
                            isRoleChange
                              ? {
                                  label: values?.roles?.replace("_", " "),
                                  value: values?.roles?.replace("_", " "),
                                }
                              : {
                                  label: values?.roles?.[0]?.replace("_", " "),
                                  value: values?.roles?.[0]?.replace("_", " "),
                                }
                          }
                          placeholder="Select an option"
                          className="variable_Select"
                          options={roleOptions}
                          onChange={(e: any) => {
                            setIsRoleChange(true);
                            setFieldValue("roles", e?.value);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-md-6 col-12">
                        <label className="text-dark mt-3 mb-2">
                          Role <span className="">*</span>
                        </label>
                        <Select
                          className="variable_Select"
                          options={roleOptions}
                          isMulti={false}
                          onChange={(e: any) => {
                            setFieldValue("roles", [e?.value]);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  {isView ? (
                    <>
                      <label className="text-dark mt-2">Department</label>
                      <Field
                        as="select"
                        id="selectedOption"
                        className="form-control mt-2"
                        name="department"
                        disabled={isView ? true : false}
                      >
                        {context?.departmentList?.map((option): any => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </>
                  ) : (
                    <>
                      <label className="text-dark mt-3 mb-2">
                        Department <span className="">*</span>
                      </label>
                      <Select
                        value={
                          values?.department && {
                            label: values?.department,
                            value: values?.department,
                          }
                        }
                        placeholder="Select.."
                        className="variable_Select"
                        options={context?.departmentList?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        onChange={(e: any) => {
                          setFieldValue("department", e?.value);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              {/* ---------------------------------- */}
            </Form>
          </FormikProvider>
        </Modal.Body>
        {(!selectedMemberId || isEdit) && (
          <>
            <Modal.Footer>
              <Button
                className="cancel-hallowbtn me-3"
                onClick={() => {
                  resetForm();
                  setShow(!show);
                  onHide();
                }}
              >
                Cancel
              </Button>

              <FormikSubmitButton
                isSubmitting={isSubmitting}
                className="px-1 py-0 "
              >
                <Button
                  className="SignText"
                  size="sm"
                  disabled={
                    values?.roles?.length === 0 ||
                    values?.department === null ||
                    values?.email === "" ||
                    values?.lastName === "" ||
                    values?.firstName === ""
                  }
                  onClick={() => {
                    handleSubmitData(formik?.values);
                  }}
                >
                  {isEdit ? (
                    <>{isResendInvite ? "Send Invite" : "Update"}</>
                  ) : (
                    "Send Invite"
                  )}
                </Button>
              </FormikSubmitButton>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default InviteMember;
