import { Table } from "react-bootstrap";
import SettingsLayout from "./SettingsLayout";
import { updateText } from "../../../utils";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useAdminAuth } from "../Auth/AdminAuthenticationProvider";
import DeleteToast from "../../../ui/DeleteToast";

function AdminRolesAndAccess() {
  const userContext = useAdminAuth();
  const [features, setFeatures] = useState<any>([]);
  const [modules, setModules] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [access, setAccess] = useState<any>({});
  const [rolesAccess, setRolesAccess]: [boolean, Function] = useState(false);
  const [isRole, setIsRole]: [any, Function] = useState();
  const [isModule, setIsModule]: [any, Function] = useState();
  const [isAccess, setIsAccess]: [any, Function] = useState();
  const [isFeature, setIsFeature]: [any, Function] = useState();
  const [isDeleteToast, setIsDeleteToast]: [boolean, Function] =
    useState(false);

  const getUserDetails = async () => {
    const { success, role, modules, features } =
      await userContext?.getConfigRoles();

    if (success) {
      setFeatures(features);
      setModules(modules);
      setRoles(role);
      const res = await userContext?.getRolesDetails();
      if (res?.success) {
        setAccess(res?.roles);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getValue = async () => {
    if (!rolesAccess) {
      let currentIndex = isAccess.findIndex((data: any) => data === isFeature);
      isAccess.splice(currentIndex, 1);
    } else {
      isAccess.push(isFeature);
    }
    let params = {
      role: isRole,
      module: isModule,
      features: isAccess,
    };
    await userContext?.updateRoles(params);
    getUserDetails();
    setIsDeleteToast(false);
  };
  return (
    <SettingsLayout>
      <div className="table-responsive iq-pricing p-3 list_members">
        <Table responsive="sm table_class">
          <thead>
            <tr className="table_white_head ">
              <th className="text-center ">
                <span className="type">#</span>
              </th>
              {roles.map((role: string) => (
                <th className="text-center py-4">
                  <span className="type" key={role}>
                    {updateText(role?.replace("_", " "))}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {modules.map((module: string) => (
              <tr className="  table_white ">
                <th className="text-center " key={module}>
                  {updateText(module?.replace("_", " "))}
                </th>
                {roles?.map((role: string) => {
                  return (
                    <>
                      <td className="text-center  " key={role}>
                        {features[module].map((feature: string) => (
                          <ul className="rolelist " key={feature}>
                            <li className="list-unstyled">
                              <Formik
                                initialValues={{
                                  toggle: false,
                                  checked: [],
                                }}
                                onSubmit={() => {}}
                              >
                                <Field
                                  type="checkbox"
                                  // disabled={module !== "MEMBERS"}
                                  className="checkboxroles checkboc form-check-input"
                                  onChange={(e: any) => {
                                    setRolesAccess(e.target.checked);
                                    setIsRole(role);
                                    setIsModule(module);
                                    setIsAccess(access[role][module]);
                                    setIsFeature(feature);
                                    setIsDeleteToast(true);
                                  }}
                                  checked={
                                    access &&
                                    access[role] &&
                                    access[role][module] &&
                                    access[role][module]?.includes(feature)
                                  }
                                />
                              </Formik>
                              <p className=" ff-questrial fw-light m-0">
                                {updateText(feature?.replace("_", " "))}
                              </p>
                            </li>
                          </ul>
                        ))}
                      </td>
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {isDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => {
            getValue();
          }}
          onClose={() => setIsDeleteToast(false)}
          title=" Do you want to update this role ?"
          closeText="No"
          submitText="Yes"
        />
      )}
    </SettingsLayout>
  );
}

export default AdminRolesAndAccess;
