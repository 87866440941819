import { useTemplates } from "../ListTemplatesProvider";
import DataTable from "react-data-table-component";
import { MdAddCircle, MdCancel, MdCheckCircle } from "react-icons/md";
import { useEffect, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { TableColumnsEnum } from "../../../interfaces/TableColumnsEnum";
import { TableColumnsInterface } from "../../../interfaces/TableColumnsInterface";
import { Template } from "../../../lib/Template";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";
import { BsThreeDots } from "react-icons/bs";
import DeleteToast from "../../../ui/DeleteToast";
import SuccessToast from "../../../ui/SuccessToast";
import moment from "moment";
import UpdateName from "../../Contracts/Components/UpdateName";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { Collaborator } from "../../../lib/Collaborator";
import FailureToast from "../../../ui/FailureToast";
import { StatusEnum } from "../../../interfaces/StatusEnum";

function Templates({
  setMoveToFolders,
  setSelectedId,
}: {
  setMoveToFolders: Function;
  setSelectedId: any;
}) {
  const navigate = useNavigate();
  const context = useTemplates();
  const location = useLocation();
  const authContext = useAuthentication();
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const { changeFilter, filterParams }: any = context;
  const [templateId, setTemplateId]: [string, Function] = useState("");
  const [updateName, setUpdateName]: [boolean, Function] = useState(false);
  const [documentName, setDocumentName]: [string, Function] = useState("");
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [documentId, setDocumentId]: [string, Function] = useState("");
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);

  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();
  const [rolesRead, setRolesRead]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesUpdate(guestRole?.TEMPLATES?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.TEMPLATES?.find((el: any) => el === "DELETE"));
      setRolesRead(guestRole?.TEMPLATES?.find((el: any) => el === "READ"));
    }
  }, [authContext?.rolesAndAccess]);

  const onDelete = async (id: string) => {
    let res = await context?.deleteTemplate(id);

    if (res?.success) {
      setShowDeleteToast(false);
      setShowSuccessToast(true);
    } else if (!res?.success) {
      setBodyMessage(res?.error?.[0]);
      setShowFailureToast(true);
      setShowDeleteToast(false);
    }
  };

  const noData = (department: string) => {
    if (department) {
      return department;
    } else {
      return <span className="text-align-center">{"-"}</span>;
    }
  };

  const columns: any = [
    {
      name: "Template name",
      width: "230px",
      selector: (row: any) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.name}
          >
            {row?.name?.charAt(0)?.toUpperCase() + row?.name?.slice(1)}
          </div>
        );
      },
      // selector: (row: any) => row?.name,
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Status",
      width: "170px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        let users = row?.approvers?.find((user: Template) => {
          return user?.user?.sub === authContext?.currentUser?.sub;
        });

        return (
          <>
            <Badge
              className={` ${
                row?.status === StatusEnum?.draft
                  ? "draft-badge"
                  : row?.status === StatusEnum?.published
                  ? "publish-badge"
                  : row?.status === StatusEnum?.pending_approve
                  ? "pendingapproval-badge"
                  : row?.status === StatusEnum?.approved
                  ? "approved-badge"
                  : row?.status === StatusEnum?.rejected
                  ? "rejected_badge"
                  : row?.status === StatusEnum?.sign_initiated
                  ? "signinitiated-badge"
                  : row?.status === "COMPLETED"
                  ? "complete-badge"
                  : row?.status === StatusEnum?.stamped
                  ? "stamped_badge"
                  : row?.status === StatusEnum?.stamp_initiated
                  ? "Stamp_initiated_baddge"
                  : row?.status === "PENDING"
                  ? "pending_badge"
                  : row?.status === StatusEnum?.stamp_failed
                  ? "Stamp_failed_badge"
                  : "draft-badge"
              }`}
              bg="success"
            >
              {row?.status === StatusEnum?.rejected ? (
                "Rejected"
              ) : row?.status === StatusEnum?.draft ? (
                "Draft"
              ) : row?.status === StatusEnum?.stamped ? (
                "Stamped"
              ) : row?.status === StatusEnum?.published ? (
                "Published"
              ) : row?.status === StatusEnum?.stamp_initiated ? (
                "Stamp initiated"
              ) : row?.status === "COMPLETED" ? (
                "Signed"
              ) : row?.status === StatusEnum?.sign_initiated &&
                authContext?.currentUser?.sub === row?.createdBy?.sub ? (
                "Sign initiated"
              ) : row?.status === StatusEnum?.sign_initiated &&
                authContext?.currentUser?.sub !== row?.createdBy?.sub ? (
                "Sign required"
              ) : row?.status === StatusEnum?.stamp_failed ? (
                "E-stamp failed"
              ) : row?.status === "PENDING" ? (
                "Paused"
              ) : (
                <>
                  {users?.status === StatusEnum?.approved &&
                  authContext?.currentUser?.sub !== row?.createdBy?.sub ? (
                    "Approved"
                  ) : users?.status === StatusEnum?.rejected ? (
                    "Rejected"
                  ) : (
                    <>
                      {authContext?.currentUser?.sub !== row?.createdBy?.sub &&
                      row?.status === StatusEnum?.pending_approve
                        ? "Approval required"
                        : authContext?.currentUser?.sub !==
                            row?.createdBy?.sub &&
                          users?.status === "PENDING" &&
                          "Approval required"}
                    </>
                  )}
                </>
                // <>
                //   {users?.status === StatusEnum?.approved ? (
                //     "Approved"
                //   ) : users?.status === StatusEnum?.rejected ? (
                //     "Rejected"
                //   ) : (
                //     <>
                //       {authContext?.currentUser?.sub !== row?.createdBy?.sub &&
                //       row?.status === StatusEnum?.pending_approve
                //         ? "Approval required"
                //         : authContext?.currentUser?.sub !==
                //             row?.createdBy?.sub &&
                //           users?.status === "PENDING" &&
                //           "Approval required"}
                //     </>
                //   )}
                // </>
              )}

              {authContext?.currentUser?.sub === row?.createdBy?.sub &&
                row?.status === StatusEnum?.pending_approve &&
                "Pending for approval"}
            </Badge>
          </>
        );
      },
    },
    {
      name: "Access",
      width: "120px",
      selector: (row: any) => {
        let collab = row?.collaborators?.find(
          (coll: Collaborator) =>
            coll?.user?.sub === authContext?.currentUser?.sub
        );

        return <>{collab?.access ? collab?.access : "-"}</>;
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Department",
      width: "170px",
      selector: (row: any) =>
        noData(row?.department === "CXOs" ? "CXO" : row?.department),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Category",
      width: "200px",
      selector: (row: any) => noData(row?.category),
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        let collab = row?.collaborators?.find(
          (coll: any) => coll?.user?.sub === authContext?.currentUser?.sub
        );

        return (
          <>
            <div className="pt-2 Actions">
              <Dropdown drop="end">
                <Dropdown.Toggle
                  variant="success"
                  className="Actions_title"
                  id="dropdown-basic"
                >
                  <BsThreeDots fontSize={24} />{" "}
                </Dropdown.Toggle>

                <Dropdown.Menu className="create-dropdown-css">
                  {collab?.access !== "VIEWER" && (
                    <>
                      {rolesUpdate === "UPDATE" && (
                        <>
                          {" "}
                          <Dropdown.Item
                            className="sort-by"
                            onClick={() => {
                              setUpdateName(true);
                              setDocumentName(row?.name);
                              setDocumentId(row?.id);
                            }}
                          >
                            Rename
                          </Dropdown.Item>
                          {row?.status === StatusEnum?.draft && (
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                navigate(
                                  generatePath(routes.editTemplate, {
                                    id: row.id as string,
                                  })
                                );
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {rolesRead === "READ" && (
                    <>
                      <Dropdown.Item
                        className="sort-by"
                        onClick={() => {
                          navigate(
                            generatePath(routes.overview, {
                              id: row.id as string,
                            })
                          );
                        }}
                      >
                        View
                      </Dropdown.Item>
                    </>
                  )}

                  <Dropdown.Item className="sort-by">
                    <a
                      download
                      className=" text-decoration-none Action_download"
                      onClick={() => context?.handleTemplateDownload(row.id)}
                    >
                      Download
                    </a>
                  </Dropdown.Item>

                  {collab?.access !== "VIEWER" &&
                    collab?.access !== "EDITOR" && (
                      <>
                        {row?.department && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                setMoveToFolders(true);
                                setSelectedId(row);
                              }}
                            >
                              Move to folder
                            </Dropdown.Item>
                          </>
                        )}
                        {rolesDelete === "DELETE" && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                setTemplateId(row.id);
                                setShowDeleteToast(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        );
      },
    },

    {
      name: "Created by",
      width: "230px",
      selector: (row: any) =>
        row?.createdBy?.name?.charAt(0)?.toUpperCase() +
        row?.createdBy?.name?.slice(1) +
        " " +
        row?.createdBy?.last_name,
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Updated at",
      width: "220px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
  ];

  const [columnsData, setColumnsData]: [TableColumnsInterface[], Function] =
    useState([]);
  const [FilteredcolumnsData, setFilteredColumnsData]: [
    TableColumnsInterface[],
    Function
  ] = useState(columns);
  const [selectedTableColumn, setSelectedTableColumn]: [any[], Function] =
    useState(columns.map((columnName: any) => columnName.name).slice(0, 10));

  useEffect(() => {
    setColumnsData(columns);
  }, [context?.recentTemplates]);

  const onHandleSelectColumn = (newValue: string) => {
    if (
      selectedTableColumn?.length > 4 ||
      !selectedTableColumn.includes(newValue)
    ) {
      if (
        selectedTableColumn.includes(newValue) &&
        // newValue !== "Template name" &&
        newValue !== "Actions"
      ) {
        setSelectedTableColumn(
          selectedTableColumn.filter(
            (selectedColumn) => selectedColumn !== newValue
          )
        );
      } else {
        if (selectedTableColumn.length < 8) {
          setSelectedTableColumn([...selectedTableColumn, newValue]);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedTableColumn.length && !!columnsData.length) {
      const a = columnsData?.filter((columnName: any) => {
        return !!selectedTableColumn.includes(columnName.name);
      });
      const _index = a.map((aa: any) => aa.name).indexOf("Actions");
      const b = a.splice(_index, 1);
      a.push(b[0]);
      if (location.pathname.includes("folder")) {
        setFilteredColumnsData(a.filter((aa: any) => aa.name !== "Actions"));
      } else {
        setFilteredColumnsData(a);
      }
    }
  }, [
    selectedTableColumn,
    selectedTableColumn.length,
    columnsData,
    location.pathname.includes("folder"),
  ]);

  useEffect(() => {
    context?.changeFilter({
      ...context.filterParams,
      page: 1,
      limit: 10,
    });
  }, []);
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);

  return (
    <div className="mx-2 mb-5" style={{ background: "#fefefe" }}>
      <div className="agreement-box mt-4">
        <div className="d-flex bd-highlight ">
          <div></div>
          {showDeleteToast && (
            <DeleteToast
              onCross={() => {}}
              onSubmit={() => onDelete(templateId)}
              onClose={() => setShowDeleteToast(false)}
              title="Do you want to delete the template?"
              closeText="No, don't !"
              submitText="Yes, delete it."
            />
          )}
          {showSuccessToast && (
            <SuccessToast
              icon={
                <MdCheckCircle
                  className="text-success"
                  style={{ fontSize: "64px" }}
                />
              }
              message={"Successfully deleted!"}
              title={"Success!"}
              show={showSuccessToast}
              close={() => {
                setShowSuccessToast(false);
              }}
            />
          )}
          <div className="me-auto p-2 bd-highlight"></div>
          <div className="p-2 bd-highlight icon-fa"></div>
          <Dropdown className="add-template ">
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="default"
            >
              <span className="me-2 pointer" title="Add">
                <MdAddCircle fontSize={24} color="#b1b1b1" />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              variant="default"
              className="add-template box-shadow px-4 py-3"
            >
              <div className="d-flex flex-row mb-3">
                <span className="manage-data">Update columns</span>
              </div>
              <div className="min-allowes">
                Min 3 , and max 7 are only allowed
              </div>

              <div className="d-flex flex-row mb-3">
                <div className="p-2">
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.templateName}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.templateName
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.templateName)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.templateName}
                    >
                      {TableColumnsEnum?.templateName}
                    </label>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.status}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.status
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.status)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.status}
                    >
                      {TableColumnsEnum?.status}
                    </label>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.access}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.access
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.access)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.access}
                    >
                      {TableColumnsEnum?.access}
                    </label>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.department}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.department
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.department)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.department}
                    >
                      {TableColumnsEnum?.department}
                    </label>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.category}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.category
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.category)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.category}
                    >
                      {TableColumnsEnum?.category}
                    </label>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.createdby
                      )}
                      id={TableColumnsEnum?.createdby}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.createdby)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.createdby}
                    >
                      {TableColumnsEnum?.createdby}
                    </label>
                  </div>

                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={TableColumnsEnum?.updatedAt}
                      checked={selectedTableColumn.includes(
                        TableColumnsEnum?.updatedAt
                      )}
                      onChange={() =>
                        onHandleSelectColumn(TableColumnsEnum?.updatedAt)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={TableColumnsEnum?.updatedAt}
                    >
                      {TableColumnsEnum?.updatedAt}
                    </label>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {FilteredcolumnsData.length > 0 && (
        <DataTable
          className="data-table"
          columns={FilteredcolumnsData}
          // selectableRows
          // selectableRowsHighlight
          data={context?.recentTemplates || []}
          highlightOnHover
          progressPending={!context?.recentTemplates}
          progressComponent={<>Loading...</>}
          paginationPerPage={filterParams.limit}
          paginationTotalRows={context?.metaData?.total_count}
          paginationDefaultPage={1}
          onChangeRowsPerPage={(currentRowsPerPage: number) => {
            changeFilter({ ...filterParams, limit: currentRowsPerPage });
          }}
          onChangePage={(page: number) =>
            changeFilter({ ...filterParams, page, limit: 10 })
          }
          pagination
          paginationServer
          onRowClicked={(rowDetail: Template) => {
            location.pathname.includes("folder")
              ? setMoveToFolders(true)
              : navigate(
                  generatePath(routes.overview, {
                    id: rowDetail.id as string,
                  })
                );
          }}
        />
      )}
      {!!context && updateName && (
        <UpdateName
          show={updateName}
          onHide={() => setUpdateName(false)}
          documentName={documentName}
          context={context}
          setShowSuccessToast={setShowSuccessToast}
          setBodyMessage={setBodyMessage}
          documentId={documentId}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage ? bodyMessage : "Template deleted."}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
    </div>
  );
}

export default Templates;
