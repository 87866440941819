import { useEffect, useState } from "react";
import { FloatingLabel, Modal, ProgressBar, Stack } from "react-bootstrap";

function FileUpload({ show, onHide }: { show: boolean; onHide: Function }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      }
    }, 80);

    return () => clearInterval(interval);
  }, [progress]);
  return (
    <Modal show={show} centered onHide={() => onHide()}>
      <Modal.Body className="p-4">
        <Stack>
          <h5 className="fw-semibold py-2">File uploading </h5>
        </Stack>
        <div className="textAreafrom">
          <FloatingLabel controlId="floatingTextarea2" label="">
            <ProgressBar now={progress} label={`${progress}%`} />
          </FloatingLabel>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FileUpload;
