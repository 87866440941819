import { isMobile } from "react-device-detect";
import { Editable } from "slate-react";
import { EditorContextInterface, useEditor } from "../EditorProvider";
import { useEffect, useState } from "react";

export function MyEditable({ ...props }) {
  const [state, setState] = useState<boolean>(false);
  const context: EditorContextInterface | null = useEditor();

  useEffect(() => {
    if (props.readOnly) {
      setState(true);
    }
  }, [props.readOnly]);

  return !state ? (
    context?.editor.sharedRoot.length === 0 ? (
      <div>Loading Collaborations</div>
    ) : (
      <Editable
        placeholder={
          context?.readOnlyState ? "Empty Document" : "Start typing here..."
        }
        style={
          isMobile
            ? {
                outline: "none",
                backgroundColor: "#fefefe",
                width: "100%",
                borderColor: "#fbfbfb",
                padding: "15px",
                justifySelf: "center",
                minHeight: "100vh",
              }
            : {
                outline: "none",
                backgroundColor: "#fefefe",
                width: "100%",
                borderColor: "#fbfbfb",
                padding: "15px",
                justifySelf: "center",
                minHeight: "100vh",
              }
        }
        {...props}
        renderLeaf={props.renderLeaf}
        decorate={props.decorate}
        onPaste={props.onPaste}
      />
    )
  ) : (
    <>
      <Editable
        placeholder={
          context?.readOnlyState ? "Empty Document" : "Start typing here..."
        }
        style={
          isMobile
            ? {
                outline: "none",
                backgroundColor: "#fefefe",
                width: "100%",
                borderColor: "#fbfbfb",
                padding: "15px",
                justifySelf: "center",
                minHeight: "100vh",
              }
            : {
                outline: "none",
                backgroundColor: "#fefefe",
                width: "100%",
                borderColor: "#fbfbfb",
                padding: "15px",
                justifySelf: "center",
                minHeight: "100vh",
              }
        }
        {...props}
        renderLeaf={props.renderLeaf}
        decorate={props.decorate}
        onPaste={props.onPaste}
      />
    </>
  );
}
