import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { useEditor } from "../../editor/EditorProvider";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import CurrencyVariableInput from "./CurrencyVariableInput";
import DateVariableInput from "./DateVariableInput";
import FloatVariableInput from "./FloatVariableInput";
import TextVariableInput from "./TextVariableInput";
import {
  AVAILABLE_VARIABLE_TYPES,
  SUPPORTED_CURRENCIES,
  SUPPORTED_DATES_FORMATS,
} from "./VariableProvider";
import { useLocation } from "react-router-dom";
import moment from "moment";

const AddVariableItem = () => {
  const [name, setName] = useState("");
  const [dataType, setDataType] = useState("STRING");
  const [value, setValue] = useState("");
  const [format, setFormat] = useState("MM/DD/YYYY");
  const [currencyType, setCurrencyType] = useState("INR");
  const [isCreating, setIsCreating] = useState(false);
  const location = useLocation();

  const isTemplate: boolean = location.pathname.includes("templates");

  const editorContext = useEditor();
  const isDuplicateName = () =>
    editorContext?.variables?.some(
      (obj: IVariableResponse) => obj.name === name
    );

  const canCreate = () => {
    const canCreate: boolean =
      name.length > 0 &&
      !isDuplicateName() &&
      AVAILABLE_VARIABLE_TYPES.map(({ value }) => value).includes(dataType);

    if (dataType === "ICURRENCY") {
      return (
        canCreate &&
        currencyType.length &&
        SUPPORTED_CURRENCIES.map(({ type }) => type).includes(currencyType)
      );
    } else if (dataType === "DATE") {
      return (
        canCreate &&
        format.length &&
        SUPPORTED_DATES_FORMATS.includes(
          format === "dd/MM/yyyy" ? "DD/MM/YYYY" : "MM/DD/YYYY"
        )
      );
    }

    return canCreate;
  };

  return (
    <Stack direction="horizontal" gap={3}>
      <div className={isTemplate ? "flex-grow-1" : ""}>
        <label className="fw-bold mb-2">Name</label>
        <input
          type="text"
          className="form-control"
          autoFocus
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Variable name"
          maxLength={100}
        />
        {name.length > 0 && isDuplicateName() ? (
          <small className="text-danger">duplicate variable name</small>
        ) : (
          <small>&nbsp;</small>
        )}
      </div>
      <div>
        <label className="fw-bold mb-2">Format</label>
        <select
          className="form-control variable_Select"
          value={dataType}
          onChange={(e) => {
            setDataType(e.target.value);
            setValue("");
          }}
        >
          {AVAILABLE_VARIABLE_TYPES.map(
            (variableType: { text: string; value: string }) => (
              <option key={variableType.value} value={variableType.value}>
                {variableType.text}
              </option>
            )
          )}
        </select>
        <small className="text-danger">&nbsp;</small>
      </div>
      {!isTemplate && (
        <div className="flex-grow-1">
          <label className="fw-bold mb-2">Value</label>

          {dataType === "FLOAT" && (
            <FloatVariableInput
              value={value}
              onChange={(value: string) => setValue(value)}
            />
          )}
          {dataType === "STRING" && (
            <TextVariableInput
              value={value}
              onChange={(value: string) => {
                setValue(value);
              }}
            />
          )}
          {dataType === "DATE" && (
            <DateVariableInput
              type="AddDate"
              format={format}
              value={value}
              onChange={({
                value,
                format,
              }: {
                value: string;
                format: string;
              }) => {
                setValue(value);
                setFormat(format);
              }}
            />
          )}
          {dataType === "ICURRENCY" && (
            <CurrencyVariableInput
              currencyType={currencyType}
              value={value}
              onChange={({
                value,
                currencyType,
              }: {
                value: string;
                currencyType: string;
              }) => {
                setCurrencyType(currencyType);
                setValue(value);
              }}
            />
          )}
          <small className="text-danger">&nbsp;</small>
        </div>
      )}
      <div>
        <Button
          className="savebtn mt-1"
          disabled={isCreating || !canCreate()}
          onClick={async () => {
            setIsCreating(true);
            const params: any = {
              name: name,
              data_type: dataType,
            };

            if (!isTemplate) {
              if (dataType === "DATE") {
                const newDateString = value
                  ? moment(value).format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD");

                params.value = newDateString.toString();
              } else {
                params.value = value.toString();
              }
            }

            if (dataType === "DATE") {
              params.format =
                format === "dd/MM/yyyy" ? "DD/MM/YYYY" : "MM/DD/YYYY";
            }
            if (dataType === "ICURRENCY") {
              params.currency_type = currencyType;
            }

            await editorContext?.addVariable(params);
            await editorContext?.getVariables();
            setName("");
            setDataType("STRING");
            setValue("");
            setFormat("MM/DD/YYYY");
            setCurrencyType("INR");
            setIsCreating(false);
          }}
        >
          {isCreating ? <FaSpinner /> : <span>Add</span>}
        </Button>
      </div>
    </Stack>
  );
};

export default AddVariableItem;
