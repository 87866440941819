import AuthLayout from "../../layouts/AuthLayout";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import { Button } from "react-bootstrap";
import { routes } from "../../utils/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthentication } from "./AuthenticationProvider";
import { useState } from "react";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../ui/FailureToast";

interface changePasswordInterface {
  password: string | "";
  confirm_password: string | "";
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const context = useAuthentication();
  const [searchParams] = useSearchParams();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);
  const [confirmPasswordIcon, setConfirmPasswordIcon]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [bodyMsg, setBodyMsg]: [string, Function] = useState("");

  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password is too short- should be 8 characters  minimum")
      .matches(/^(?=.{6,})/, "Must Contain 6 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*?[!"#$%&'()*+,-./:;<=>?@_`{}~])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    confirm_password: Yup.string()
      .required("Confirmation Password is required")
      .oneOf([Yup.ref("password")], "Password doesn't match"),
  });

  const handleSubmit = async (
    values: changePasswordInterface,
    {
      setSubmitting,
      setErrors,
    }: { setSubmitting: Function; setErrors: Function }
  ) => {
    const response = await context?.resetPassword(
      { password: values?.password },
      searchParams?.get("token")
    );

    if (response?.data?.success) {
      setShowSuccessToast(true);
    } else {
      setErrors({ email: response?.data?.error });
      setShowFailureToast(true);
      setBodyMsg(response?.data?.error?.[0]);
    }
    setSubmitting(false);
  };
  return (
    <AuthLayout
      pageTitle="CLM - Reset Password"
      title="Reset Password"
      subTitle="Reset your Password here"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FormikField
              name="password"
              type={passwordIcon ? "text" : "password"}
              validationSchema={validationSchema}
              label="Password"
              errors={errors}
              autoFocus
              placeholder="Enter the password"
              isPassword={true}
              className="passwordIconHide"
              setPasswordIcon={setPasswordIcon}
              passwordIcon={passwordIcon}
            />

            <FormikField
              name="confirm_password"
              type={confirmPasswordIcon ? "text" : "password"}
              validationSchema={validationSchema}
              label="Confirm Password"
              errors={errors}
              autoFocus
              placeholder="Confirm your password"
              isPassword={true}
              className="passwordIconHide"
              setPasswordIcon={setConfirmPasswordIcon}
              passwordIcon={confirmPasswordIcon}
            />

            <div className="d-flex justify-content-end pt-4">
              {" "}
              <Button className="cancel-hallowbtn me-3" size="lg">
                Cancel
              </Button>
              <div>
                <FormikSubmitButton
                  isSubmitting={isSubmitting}
                  className="share-hallowbtn"
                  size="lg"
                >
                  Update Password
                </FormikSubmitButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="Reset password done successfully."
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
            navigate(routes.root);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMsg}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </AuthLayout>
  );
};

export default ResetPasswordPage;
