import PublishBarChart from "./TurnaroundTimeChart/PublishBarChart";
import NegotiationBarChart from "./TurnaroundTimeChart/NegotiationBarChart";
import ApproveBarChart from "./TurnaroundTimeChart/ApproveBarChart";
import ExecutionBarChart from "./TurnaroundTimeChart/ExecutionBarChart";
import ExecutionLineChart from "./TurnaroundTimeChart/ExecutionLineChart";
import SignedLineChart from "./TurnaroundTimeChart/SignedLineChart";

const AdminAnalyticsTurnaroundTime = ({ filterData }: { filterData: any }) => {
  return (
    <>
      <div className="analyticsBody px-3 pb-5">
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                {" "}
                Turnaround time in publishing a contract
              </h6>
              <PublishBarChart filterData={filterData} />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                {" "}
                Turnaround time in contract negotiations
              </h6>
              <NegotiationBarChart filterData={filterData} />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Turnaround time till contract are reviewed and approved
              </h6>
              <ApproveBarChart filterData={filterData} />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Turnaround time in contract execution
              </h6>
              <ExecutionBarChart filterData={filterData} />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Turnaround time from when the contract is shared with the signer
                till it is signed
              </h6>
              <SignedLineChart filterData={filterData} />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title mb-4">
                Overall turnaround time from contract initiation till contract
                execution
              </h6>
              <ExecutionLineChart filterData={filterData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalyticsTurnaroundTime;
