import moment from "moment";
// import { Collaborator } from "./Collaborator";
import { User } from "./User";
import { IUserResponse } from "../interfaces/response/IUserResponse";
import { Contract } from "./Contract";
import { Template } from "./Template";

export default class BaseDocument {
  id: string | undefined;
  name: string | undefined;
  description: string | undefined;
  isDeleted: boolean | undefined;
  documentId: string | undefined;
  status: string | undefined;
  accountId: string | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  createdBy: User | undefined;
  category: string | undefined;
  search: string | undefined;
  collaborators: any | undefined;
  user: IUserResponse | undefined;
  filePath?: string | null;
  workflow_status?: string | null;
  lastEditedOn?: Date | undefined;
  department?: string | null;
  access?: string | null;
  templates: Template[] | undefined;
  contracts: Contract[] | undefined;
  sub_folders: [] | undefined;
  updated_by: any;
  parent_folder: any;
  organization_id: string | undefined;
  is_optional: boolean | undefined;
  path: any;
  expiry_date?: string | undefined;
  stamp_required?: boolean | undefined;
  is_stamped?: boolean | undefined;
  sign_type?: string | undefined;
  pages?: number | undefined;
  contract_value: string | undefined;
  start_date: string | undefined;
  end_date: string | undefined;
  currency_type: string | undefined;
  last_edited_on: string | undefined;
  last_sync_at: string | undefined;
  status_reason: string | undefined;
  negotiation_owners: any;
  relationship: any;
  owner: any;

  formattedUpdatedAt(format = "D MMM YYYY "): string {
    return moment(this.updatedAt?.toString()).format(format);
  }

  formattedCreatedAt(format = "Do MMM, YYYY"): string {
    return moment(this.createdAt?.toString()).format(format);
  }

  formattedName(limit: number = 0): string {
    return this._formattedName(this.name || "", limit);
  }

  formattedcratedByName(limit: number = 0): string {
    return this._formattedName(this.createdBy?.name || "", limit);
  }

  private _formattedName(name: string, limit: number) {
    if (limit < 1) return name;

    if (name.length > limit) {
      return `${name.slice(0, limit)}...`;
    } else {
      return name;
    }
  }
}
