import { Button, Modal, Stack } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import AutoCompleteInput from "./AutoCompleteInput";
import { useState } from "react";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import TransferOwnerShipCheckbox from "./TransferOwnerShipCheckbox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import UsersList from "./UsersList";
import { Collaborator } from "../lib/Collaborator";

interface TransferOwnerShipProps {
  avatar: string;
  email: string;
  label: string;
  value: string;
}

function TransferOwnerShipPopup({
  show,
  onHide,
  context,
  setShowSuccessToastWorkFlow,
  setWorkFlowShowFailureToast,
  setWorkFlowBodyMessage,
}: {
  show: boolean;
  onHide: Function;
  setShowSuccessToastWorkFlow: Function;
  setWorkFlowShowFailureToast: Function;
  setWorkFlowBodyMessage: Function;
  context: TemplateContextInterface | ContractContextInterface | null;
}) {
  const { id } = useParams();
  const location = useLocation();
  const authContext = useAuthentication();
  const navigate = useNavigate();
  const [values, setValues]: [TransferOwnerShipProps | undefined, Function] =
    useState();
  const [collaboratorRoles, setCollaboratorRoles]: [
    Collaborator[] | undefined,
    Function
  ] = useState([]);
  const [accessOption, setAccessOption] = useState<string>("");
  const [_notifyPeople, setNotifyPeople] = useState<boolean>(false);
  const [sendMessage, setSendMessage] = useState<string>("");

  const onHandleTrasferOwnership = async () => {
    try {
      let requestBody;
      if (location.pathname.includes("contracts")) {
        requestBody = {
          contract_ids: [id],

          collaborators: accessOption
            ? {
                collaborators_id: [authContext?.currentUser?.sub],
                access: accessOption,
              }
            : {},
        };
      } else if (location.pathname.includes("templates")) {
        requestBody = {
          template_ids: [id],
        };
      }

      let response = await context?.transferOwnership(
        values?.value,
        requestBody
      );

      if (response?.data?.success) {
        if (accessOption) {
          try {
            let requestBody = {
              collaborators_id: [authContext?.currentUser?.sub],
              access: accessOption,
            };
            await context?.inviteCollaborator(id, requestBody);
          } catch (error) {}
        } else {
          if (location.pathname.includes("templates")) {
            navigate("/templates");
          } else {
            setWorkFlowBodyMessage(response?.data?.message);
            setShowSuccessToastWorkFlow(true);
            context?.refresh();
            navigate("/contracts");
            onHide();
          }
        }
        onHide();
        setWorkFlowBodyMessage(response?.data?.message);
        setShowSuccessToastWorkFlow(true);
        context?.refresh();
      } else if (!response?.data?.success) {
        setWorkFlowBodyMessage(response?.data?.error?.[0]);
        onHide();
        setWorkFlowShowFailureToast(true);
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal show={show} centered style={isMobile ? {} : { height: "100vh" }}>
        <Modal.Body className="p-4">
          <Stack>
            <h5 className="fw-semibold py-2 transfer">
              Caution - Transfer ownership
            </h5>
          </Stack>
          <div>
            <AutoCompleteInput
              values={values}
              setValues={setValues}
              context={context}
              transferOwnership={true}
            />
          </div>
          {!values?.label && (
            <>
              <Stack>
                <h6 className="fw-semibold pb-2 pt-3">
                  Existing Collaborators
                </h6>
              </Stack>
              <UsersList
                collaboratorRoles={collaboratorRoles}
                setCollaboratorRoles={setCollaboratorRoles}
                context={context}
              />
            </>
          )}

          {values?.label && (
            <TransferOwnerShipCheckbox
              accessOption={accessOption}
              setAccessOption={setAccessOption}
              setNotifyPeople={setNotifyPeople}
              sendMessage={sendMessage}
              setSendMessage={setSendMessage}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger btn_margin"
            size="sm"
            disabled={values === undefined}
            onClick={() => {
              onHandleTrasferOwnership();
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TransferOwnerShipPopup;
