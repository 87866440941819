import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useVersionHistory } from "./VersionHisoryProvider";
import { Version } from "../../lib/VersionHistory";
import { api } from "../../utils/api";

function VersionRenamePopup({
  show,
  onHide,
  versionName,
  documentDetail,
  handleDocument,
  setShowSuccessToast,
  setBodyMessage,
  documentId,
  contractDocId,
}: {
  show: boolean;
  onHide: Function;
  versionName?: string;
  documentDetail?: Version;
  handleDocument: Function;
  setShowSuccessToast: Function;
  setBodyMessage: Function;
  documentId?: string;
  contractDocId?: string;
}) {
  const context = useVersionHistory();
  const [documentName, setUpdatedName]: [string, Function] = useState("");
  const [headDocumentVersion, setHeadDocumentVersion]: [string, Function] =
    useState("");
  const [errorMsg, setErrorMsg]: [string, Function] = useState("");

  const handleRenameVersion: any = async () => {
    if (documentId && headDocumentVersion) {
      try {
        let requestBody = {
          name: documentName,
        };
        let response = await api.updateDocumentName(
          documentId,
          headDocumentVersion,
          requestBody
        );
        if (response?.data?.success) {
          setShowSuccessToast(true);
          setBodyMessage("Version name updated.");
          handleDocument();
          onHide();
        }
      } catch (error) {}
    } else {
      try {
        let requestBody = {
          name: documentName,
        };
        let response = await context?.UpdateVersionName(
          documentDetail?.document,
          documentDetail?.id,
          requestBody
        );

        if (response?.data?.success) {
          setShowSuccessToast(true);
          setBodyMessage("Version name updated.");
          handleDocument();
          onHide();
        } else {
          setErrorMsg(response?.error?.[0]);
        }
      } catch (error) {}
    }
  };

  const handleDocumentVersion = async () => {
    try {
      if (contractDocId) {
        let response = await api?.getDocument(contractDocId);
        if (response) {
          setHeadDocumentVersion(response?.head_document_version);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleDocumentVersion();
  }, []);

  return (
    <>
      <Modal show={show} onHide={() => onHide} centered>
        <Modal.Header className="modal-header-bottom">
          <div className="pt-3">
            <h6 className=" fw-semibold modal-title-name">
              Update version name
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="col-md-12 col-12">
                <label className="input_label">Name</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={versionName ? versionName : "Name this version"}
                  onChange={(e) => {
                    setUpdatedName(e?.target?.value);
                    setErrorMsg("");
                  }}
                  className="form-control"
                  placeholder="Name"
                />
              </div>
            </Form.Group>
            {errorMsg?.length > 0 && (
              <div className="counterparty_error">{errorMsg}</div>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            size="sm"
            disabled={documentName?.length === 0}
            onClick={handleRenameVersion}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VersionRenamePopup;
