import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { IApproval } from "../../interfaces/IApprovals";
import { IUserResponse } from "../../interfaces/response/IUserResponse";
import { api } from "../../utils/api";
import { IContractResponse } from "../../interfaces/response/IContractResponse";
import { Contract } from "../../lib/Contract";
import { useParams } from "react-router-dom";
import { ITemplateResponse } from "../../interfaces/response/ITemplateResponse";

interface ApprovalsContextInterface {
  defaultApprovals: IApproval[];
  handleAutoUsers: Function;
  setOnSelectApprovalId: Function;
  approvalDetail: IUserResponse[];
  handleAddApprovals: Function;
  onSelectApprovalId: IApproval[];
  getContractDetails: Function;
  handleCounterParty: Function;
  handleAddApprovalTemplates: Function;
  getTemplatesDetails: Function;
  onPublishWorkflow: Function;
  contractDetail: IContractResponse | undefined;
  templateDetail: ITemplateResponse | undefined;
  data: Contract | null;
  contract: Contract | null;
  signeeList: [];
  refresh: Function;
  onDeleteApprovers: Function;
  onDeleteCounterparty: Function;
  onDeleteSignee: Function;
  updateApprovalMendatory: Function;
  onDeleteTemplatesApprovers: Function;
  onEditContractWorkflow: Function;
  handleRefresh: Function;
  onPublishTemplateWorkflow: Function;
  onEditTemplateWorkflow: Function;
  onRejectApprovers: Function;
  updateWorkflowMendatory: Function;
}

const ApprovalsContext = React.createContext<ApprovalsContextInterface | null>(
  null
);

interface ListTemplatesProviderProps {
  children: ReactNode;
}

const ApprovalsProvider = ({ children }: ListTemplatesProviderProps) => {
  const { id } = useParams();
  const [defaultApprovals, setDefaultApprovals]: [IApproval[], Function] =
    useState([]);
  const [onSelectApprovalId, setOnSelectApprovalId]: [IApproval[], Function] =
    useState([]);
  const [approvalDetail]: [IUserResponse[], Function] = useState([]);
  const [contractDetail, setContractDetail]: [
    IContractResponse | undefined,
    Function
  ] = useState();
  const [templateDetail, setTemplateDetail]: [
    ITemplateResponse | undefined,
    Function
  ] = useState();
  const [contractDetails, setContract] = useState<Contract | null>(null);
  const [signeeList, setSigneeList]: [[], Function] = useState([]);

  const handleAutoUsers = async (params: string) => {
    try {
      let response = await api.getUsersAutocomplete(params);

      let res = response.map(
        ({
          name,
          id,
          email,
          organization,
          contact_number,
          roles,
          is_active,
        }: any) => ({
          name,
          id,
          email,
          organization,
          contact_number,
          roles,
          is_active,
        })
      );
      setDefaultApprovals(res);
      return res;
    } catch (error) {}
  };

  const handleUsersDetail = async () => {
    try {
      let response = await api.getUsers({ limit: 100 });

      let res = response?.users?.map(
        ({
          name,
          id,
          email,
          organization,
          contact_number,
          roles,
          is_active,
        }: any) => ({
          name,
          id,
          email,
          organization,
          contact_number,
          roles,
          is_active,
        })
      );
      setDefaultApprovals(res);
      return res;
    } catch (error) {}
  };

  useEffect(() => {
    handleUsersDetail();
  }, []);

  const handleCounterParty = async (id: string, params: any) => {
    try {
      let response = await api.addCounterParty(id, params);
      return response;
    } catch (error) {}
  };

  const handleAddApprovals = async (id: string, params: any) => {
    try {
      let response = await api.addApprovals(id, params);
      return response;
    } catch (error) {}
  };

  const handleAddApprovalTemplates = async (id: string, params: any) => {
    try {
      let response = await api.addApprovalsTemplates(id, params);
      return response;
    } catch (error) {}
  };

  const getContractDetails = async (id: string) => {
    try {
      let response = await api.getContractsDetails(id);
      setSigneeList(response?.contract?.internal_signer);
      setContractDetail(response?.contract);

      return response;
    } catch (error) {}
  };

  const handleRefresh = async () => {
    if (!id) return;
    const {
      success,
      contract,
    }: { success: true; contract: IContractResponse } =
      await api.getContractsDetails(id);
    if (success) {
      setContract(new Contract(contract));
    }
  };

  const getTemplatesDetails = async (id: string) => {
    try {
      let response = await api.getTemplatesDetails(id);
      setTemplateDetail(response);
      return response;
    } catch (error) {}
  };

  const updateApprovalMendatory = async (
    id: string,
    body: any,
    approvalId: string
  ) => {
    try {
      let res = await api.updateApprovalList(id, body, approvalId);
      return res;
    } catch (error) {}
  };

  const onPublishWorkflow = async (id: string, params: any) => {
    try {
      let response = await api.publishWorkflow(id, params);
      return response;
    } catch (error) {}
  };
  const onPublishTemplateWorkflow = async (id: string, params: any) => {
    try {
      let response = await api.publishTemplateWorkflow(id, params);
      return response;
    } catch (error) {}
  };

  const updateWorkflowMendatory = async (
    id: string,
    params: any,
    approvalId: string
  ) => {
    try {
      let response = await api.updateTmplateWorkflow(id, params, approvalId);
      return response;
    } catch (error) {}
  };

  const onDeleteApprovers = async (id: string, approverId: string) => {
    try {
      let response = await api.deleteApprovers(id, approverId);
      return response;
    } catch (error) {}
  };

  const onDeleteTemplatesApprovers = async (id: string, approverId: string) => {
    try {
      let response = await api.deleteTemplateApprovers(id, approverId);
      return response;
    } catch (error) {}
  };

  const onDeleteCounterparty = async (id: string, counterpartyId: string) => {
    try {
      let response = await api.deleteCounterparty(id, counterpartyId);
      return response;
    } catch (error) {}
  };
  const onDeleteSignee = async (id: string, signeeId: string) => {
    try {
      let response = await api.deleteSignee(id, signeeId);
      return response;
    } catch (error) {}
  };

  const onEditContractWorkflow = async (id: string) => {
    try {
      let response = await api.editContractWorkflow(id);
      return response;
    } catch (error) {}
  };

  const onEditTemplateWorkflow = async (id: string) => {
    try {
      let response = await api.editTemplateWorkflow(id);
      return response;
    } catch (error) {}
  };
  const onRejectApprovers = async (id: string) => {
    try {
      let response = await api.rejectTemplate(id);
      return response;
    } catch (error) {}
  };

  const value: ApprovalsContextInterface = useMemo(
    () => ({
      defaultApprovals,
      handleAutoUsers,
      setOnSelectApprovalId,
      approvalDetail,
      handleAddApprovals,
      onSelectApprovalId,
      getContractDetails,
      handleCounterParty,
      handleAddApprovalTemplates,
      getTemplatesDetails,
      onPublishWorkflow,
      contract: contractDetails,
      data: contractDetails,
      contractDetail,
      templateDetail,
      handleRefresh,
      onDeleteApprovers,
      onDeleteCounterparty,
      onDeleteSignee,
      updateApprovalMendatory,
      refresh: () => {
        if (id) {
          getContractDetails(id);
        }
      },
      signeeList,
      onDeleteTemplatesApprovers,
      onEditContractWorkflow,
      onPublishTemplateWorkflow,
      onEditTemplateWorkflow,
      onRejectApprovers,
      updateWorkflowMendatory,
    }),
    [handleAutoUsers, setOnSelectApprovalId, approvalDetail]
  );

  return (
    <ApprovalsContext.Provider value={value}>
      {children}
    </ApprovalsContext.Provider>
  );
};

const useApprovals = () => {
  return React.useContext(ApprovalsContext);
};

export { ApprovalsProvider, useApprovals };
