import LineChart from "./LineChart";
import StackedAreaChart from "./StackedAreaChart";
import ZoomableBubbleChart from "./ZoomableBubbleChart";
import OverallStats from "./OverallStats";
import SignedContractsTable from "./SignedContractsTable";
import BarChart from "./BarChart";
import { useState } from "react";
import DownloadButton from "../DownloadButton";
function Summary({
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
}: {
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: boolean;
  updatedValue: any;
}) {
  const [isLoadingData, setIsLoadingData] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  return (
    <>
      {!isLoadingData?.includes(false) && (
        <DownloadButton title={"Summary_report"} />
      )}
      <div className="analyticsBody ">
        <div id="pdf-content">
          <div className=" row">
            <div className=" col-lg-6 col-12 mt-4">
              <div
                style={{ minHeight: "505px", background: "#fefefe" }}
                className=" p-3"
              >
                <h6 className=" fw-semibold dashboard_title"> Overall Stats</h6>
                <OverallStats
                  setIsLoadingData={setIsLoadingData}
                  isLoadingData={isLoadingData}
                />
              </div>
            </div>
            <div className=" col-lg-6 col-12 mt-4">
              <div
                style={{ minHeight: "505px", background: "#fefefe" }}
                className=" p-3"
              >
                <h6 className=" fw-semibold dashboard_title"> Contract Type</h6>
                <BarChart
                  title={"Contract Type"}
                  isUpdate={isUpdate}
                  setIsUpdate={setIsUpdate}
                  filterData={filterData}
                  updatedValue={updatedValue}
                  setIsLoadingData={setIsLoadingData}
                  isLoadingData={isLoadingData}
                />
              </div>
            </div>
          </div>
          <div className=" row">
            <div className=" col-lg-6 col-12 mt-4">
              <div
                style={{ minHeight: "505px", background: "#fefefe" }}
                className=" p-3"
              >
                <h6 className=" fw-semibold dashboard_title">
                  Signed Contracts
                </h6>
                <StackedAreaChart
                  title={"Signed Contracts"}
                  isUpdate={isUpdate}
                  setIsUpdate={setIsUpdate}
                  filterData={filterData}
                  updatedValue={updatedValue}
                  setIsLoadingData={setIsLoadingData}
                  isLoadingData={isLoadingData}
                />
              </div>
            </div>
            <div className=" col-lg-6 col-12 mt-4">
              <div
                style={{ minHeight: "505px", background: "#fefefe" }}
                className=" p-3"
              >
                <h6 className=" fw-semibold dashboard_title">
                  Top 10 Contracts by Contract Value
                </h6>
                <ZoomableBubbleChart
                  title={"Top 10 Contracts by Contract Value"}
                  isUpdate={isUpdate}
                  setIsUpdate={setIsUpdate}
                  filterData={filterData}
                  updatedValue={updatedValue}
                  setIsLoadingData={setIsLoadingData}
                  isLoadingData={isLoadingData}
                />
              </div>
            </div>
          </div>
          <div className=" row">
            <div className=" col-lg-12 col-12 mt-4">
              <div
                style={{ minHeight: "470px", background: "#fefefe" }}
                className=" p-3"
              >
                <h6 className=" fw-semibold dashboard_title">
                  Average Contract Expiry Based on Each Type of Contract
                </h6>
                <LineChart
                  title={
                    "Average Contract Expiry Based on Each Type of Contract"
                  }
                  isUpdate={isUpdate}
                  setIsUpdate={setIsUpdate}
                  filterData={filterData}
                  updatedValue={updatedValue}
                  setIsLoadingData={setIsLoadingData}
                  isLoadingData={isLoadingData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-12 col-12 mt-4">
            <div
              style={{ minHeight: "470px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title mb-4">
                Signed Contracts
              </h6>
              <SignedContractsTable filterData={filterData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Summary;
