import AdminStackedChart from "../AdminOrganisation/OrganisationCharts/AdminStackedChart";
import AdminMonthlyOrganisation from "./OrganisationCharts/AdminMonthlyOrganisation";
import OrganisationBasedOnContract from "./OrganisationCharts/OrganisationBasedOnContract";
import OrganisationTable from "./OrganisationTable";

const AdminAnalyticsOrganisations = ({ filterData }: { filterData: any }) => {
  return (
    <>
      <div className="analyticsBody px-3 pb-5">
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title"> EULA acceptance</h6>
              <AdminStackedChart filterData={filterData} />
            </div>
          </div>
          <div className="  col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Monthly Active Organization
              </h6>
              <AdminMonthlyOrganisation filterData={filterData} />
            </div>
          </div>
          {/* <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Zone Wise Contract Value
              </h6>
            </div>
          </div> */}
        </div>
        {/* <div className=" row">
          <div className="  col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Monthly Active Organization
              </h6>
              <AdminMonthlyOrganisation filterData={filterData} />
            </div>
          </div>
        </div> */}
        {/* <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                State Wise Active Organisation
              </h6>
              <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                <StateWiseActiveOrganisation filterData={filterData} />
              </div>
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                City Wise Active Organisation
              </h6>
              <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
                <CityWiseActiveOrganisation filterData={filterData} />
              </div>
            </div>
          </div>
        </div> */}
        <div className=" row">
          <div className="  col-12 mt-4">
            <div
              style={{ minHeight: "470px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Top 10 organization (based on contract volume)
              </h6>
              <OrganisationBasedOnContract filterData={filterData} />
            </div>
          </div>
          <div className="  col-12 mt-4">
            <div
              style={{ minHeight: "470px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title mb-4">
                Organization
                <OrganisationTable filterData={filterData} />
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalyticsOrganisations;
