import { useSearchParams } from "react-router-dom";
import CommonReactSelect from "./CommonReactSelect";

function Status({
  context,
  setFilterParams,
  filterParams,
}: {
  context?: any;
  setFilterParams: Function;
  filterParams: any;
}) {
  const [searchParams] = useSearchParams();

  const options = context?.templateStatus?.map((item: string) => ({
    label:
      item === "DRAFT"
        ? "Draft"
        : item === "PUBLISHED"
        ? "Published"
        : item === "PENDING_APPROVAL"
        ? "Pending for approval"
        : item === "APPROVAL_REQUIRED"
        ? "Approval required"
        : item === "NEGOTIATION_ONGOING"
        ? "Negotiation ongoing"
        : item === "NEGOTIATION_INITIATED"
        ? "Negotiation initiated"
        : item === "PENDING_NEGOTIATION_APPROVAL"
        ? "Pending negotiation approval"
        : item === "APPROVED"
        ? "E-stamp required"
        : item === "STAMP_INITIATED"
        ? "Stamp initiated"
        : item === "STAMPING_FAILED"
        ? "E-stamp failed"
        : item === "STAMPED"
        ? "Stamped"
        : item === "SIGN_REQUIRED"
        ? "Sign required"
        : item === "SIGN_INITIATED"
        ? "Sign initiated"
        : item === "COMPLETED"
        ? "Signed"
        : item === "REJECTED"
        ? "Rejected"
        : item === "EXPIRED"
        ? "Expired"
        : item,

    value: item,
  }));

  return (
    <>
      <div className="row status-title mt-3">Status</div>
      <div className="my-2 mb-4">
        <CommonReactSelect
          isMulti={true}
          className={"select-wrapper"}
          options={options}
          values={
            searchParams?.get("statusLabel") ||
            filterParams?.statusLabel?.length
              ? filterParams?.statusLabel
                  ?.split(",")
                  ?.map((item: any) =>
                    options?.find((option: any) => option?.label === item)
                  )
              : ""
          }
          updateField={(option: any) => {
            setFilterParams({
              ...filterParams,
              status: option?.map((el: any) => el?.value)?.join(","),
              statusLabel: option?.map((el: any) => el?.label)?.join(","),
              limit: 10,
            });
          }}
        />
      </div>
    </>
  );
}

export default Status;
