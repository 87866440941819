import { useEffect, useRef, useState } from "react";
import AppLayout from "../../layouts/AppLayout";
import AnalyticsHeader from "./AnalyticsHeader";
import AnalyticsMobileFilter from "./AnalyticsMobileFilter";
import AnalyticsOverview from "./AnalyticsOverview";
import { AnalyticsProvider, useAnalytics } from "./AnalyticsProvider";

function Analytics() {
  return (
    <AnalyticsProvider>
      <AppLayout>
        <AnalyticsBody />
      </AppLayout>
    </AnalyticsProvider>
  );
}

export default Analytics;

const AnalyticsBody = () => {
  const context = useAnalytics();
  const componentRef = useRef(null);

  const [isUpdate, setIsUpdate]: [boolean, Function] = useState(false);
  const [filterParams, setFilterParams]: [any, Function] = useState();
  const [updatedValue, setUpdatedValue]: [any, Function] = useState();

  const getMatricsData = async () => {
    try {
      let response = await context?.getMatrics();

      if (response?.success) {
        if (response?.metrics) {
          setUpdatedValue(response?.metrics);
        }
        if (response?.metrics?.length === 0) {
          handleUpdateMatricsData();
        }
      }
    } catch (error) {}
  };

  const handleUpdateMatricsData = async () => {
    try {
      let requestBody = {
        metrics: [
          {
            name: "Contract Type",
            type: "Funnel",
            is_enabled: true,
          },
          {
            name: "Signed Contracts",
            type: "Circle Packing",
            is_enabled: true,
          },
          {
            name: "Top 10 Contracts by Contract Value",
            type: "Horizontal Bar",
            is_enabled: true,
          },
          {
            name: "Average Contract Expiry Based on Each Type of Contract",
            type: "Horizontal Bar",
            is_enabled: true,
          },
          {
            name: "Days in Negotiation",
            type: "Circle Packing",
            is_enabled: true,
          },
          {
            name: "Contract Cycle Time",
            type: "Horizontal Bar",
            is_enabled: true,
          },
          {
            name: "Turnaround Time For Approvals",
            type: "Tree Map",
            is_enabled: true,
          },
          {
            name: "Turnaround Time for Signed Contracts",
            type: "Horizontal Bar",
            is_enabled: true,
          },
          {
            name: "Contracts Signed By Each Team",
            type: "Pie",
            is_enabled: true,
          },
          {
            name: "Number of contracts requiring approval",
            type: "Donut",
            is_enabled: true,
          },
          {
            name: "Number of contracts initiated by each team",
            type: "Horizontal Bar",
            is_enabled: true,
          },
          {
            name: "Top 10 counterparties by contract amount",
            type: "Horizontal Bar",
            is_enabled: true,
          },
        ],
      };
      await context?.handleAddMatrics(requestBody);
    } catch (error) {}
  };

  useEffect(() => {
    getMatricsData();
  }, [filterParams, isUpdate]);

  return (
    <>
      <div className="p-3 ">
        <AnalyticsHeader
          setIsUpdate={setIsUpdate}
          setFilterData={setFilterParams}
          updatedValue={updatedValue}
          getMatricsData={getMatricsData}
        />
        <AnalyticsOverview
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          filterData={filterParams}
          updatedValue={updatedValue}
          componentRef={componentRef}
        />
      </div>
      <div className="d-lg-none d-md-block">
        <AnalyticsMobileFilter
          setIsUpdate={setIsUpdate}
          setFilterData={setFilterParams}
          updatedValue={updatedValue}
          getMatricsData={getMatricsData}
        />
      </div>
    </>
  );
};
