import { customStyles } from "../../ui/CustomStyles";
import Select from "react-select";
import DatePicker from "react-date-picker";
import AnalyticsRange from "./AnalyticsRange";
import { Button } from "react-bootstrap";
import { useAnalytics } from "./AnalyticsProvider";
import { Fragment, useEffect, useRef, useState } from "react";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
import moment from "moment";

const AnalyticsFilter = ({
  setOpenFilter,
  setFilterData,
  openFilter,
}: {
  setOpenFilter: Function;
  setFilterData: Function;
  openFilter: boolean;
}) => {
  const context = useAnalytics();
  const categoryRef: any = useRef(null);
  const satatusRef: any = useRef(null);
  const noOfDaysRef: any = useRef(null);

  const [defaultCategories, setDefaultCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);
  const [category, setCategory]: [any, Function] = useState(null);
  const [contractStatus, setContractStatus]: [[], Function] = useState([]);
  const [status, setStatus]: [any, Function] = useState();
  const [startValues, setStartValues] = useState<any>();
  const [endValues, setEndValues] = useState<any>();
  const [numberOfDays, setNumberOfDays]: [string, Function] = useState("");
  const [counterpartyCheckedItem, setCounterpartyCheckedItem] = useState<any>();
  const [checkedAll, setCheckedAll] = useState(false);
  const [counterpartyList, setCounterpartyList]: [[], Function] = useState([]);

  const [checkedItems, setCheckedItems] = useState(
    counterpartyList.reduce((items: any, label: any) => {
      items[label] = false;
      return items;
    }, {})
  );

  const handleContractType = async () => {
    try {
      let response = await context?.handleGetCategory();
      if (response) {
        setDefaultCategories(response);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (openFilter) {
      handleContractType();
      handleStatus();
    }
  }, [openFilter]);

  const handleStatus = async () => {
    try {
      let response = await context?.handleGetStatus();
      if (response) {
        setContractStatus(response?.data?.contractStatus);
      }
    } catch (error) {}
  };

  const noOfDays = [
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "60", label: "60" },
  ];

  const [minValue, setMinValue] = useState<any>(225);
  const [maxValue, setMaxValue] = useState<any>(678);

  const handleFilterData = async (type: string) => {
    try {
      let requestBody = {
        category: category ? category : "",
        status: status ? status : "",
        counter_parties: counterpartyCheckedItem ? counterpartyCheckedItem : "",
        start_date: startValues
          ? moment(startValues).format("YYYY-MM-DD").toString()
          : "",
        end_date: endValues
          ? moment(endValues).format("YYYY-MM-DD").toString()
          : "",
        createdAt: numberOfDays
          ? moment().subtract(numberOfDays, "days").format("YYYY-MM-DD")
          : "",
        contract_value_start: minValue ? minValue : "",
        contract_value_end: maxValue ? maxValue : "",
      };
      context?.setFilterParams(requestBody);
      let response = await context?.handleOverallStats(
        type === "apply" ? requestBody : {}
      );
      if (response) {
        setOpenFilter(false);
        setFilterData(type === "apply" ? requestBody : {});
        setCategory("");
        setStatus("");
        setStartValues("");
        setEndValues("");
        setNumberOfDays("");
        setCounterpartyCheckedItem("");
      }
    } catch (error) {}
  };
  const [searchText, setSearchText]: [string, Function] = useState("");
  const handleCounterpartyData = async () => {
    try {
      let response = await context?.handleCounterpartyList({
        search: searchText,
      });
      if (response?.success) {
        setCounterpartyList(response?.counter_parties);
      }
    } catch (error) {}
  };

  const onResetFilter = () => {
    handleRemoveSelected();
    setMinValue("");
    setCategory(undefined);
    setSearchText("");
    setStatus("");
    setStartValues("");
    setEndValues("");
    setNumberOfDays("");
    setCounterpartyCheckedItem("");
    setMaxValue("");
    setCheckedAll(false);
    setDefaultCategories([]);
    context?.setFilterParams("");
    const allCheckedItems = counterpartyList.reduce(
      (result: any, label: any) => {
        result[label] = false;
        return result;
      },
      {}
    );

    setCheckedItems(allCheckedItems);
  };

  useEffect(() => {
    if (openFilter) {
      handleCounterpartyData();
    }
  }, [searchText, openFilter]);

  const handleRemoveSelected = () => {
    if (categoryRef.current) {
      categoryRef.current.clearValue();
    }
    if (satatusRef.current) {
      satatusRef.current.clearValue();
    }
  };

  const handleRemoveFilter = () => {
    if (noOfDaysRef.current) {
      noOfDaysRef.current.clearValue();
    }
  };

  return (
    <>
      {openFilter && (
        <>
          {" "}
          <div className=" py-3 ">
            <div className=" d-flex justify-content-between align-items-center border-bottom pb-4">
              <h6 className=" m-0 fw-semibold">Filter</h6>
              <h6
                className=" m-0"
                style={{
                  color: "#e15554",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  {
                    handleFilterData("resert");
                    onResetFilter();
                    handleRemoveFilter();
                  }
                }}
              >
                Reset all
              </h6>
            </div>
            <div className=" mt-4 border-bottom pb-4">
              <h6 className=" fw-semibold">Contract Value</h6>
              <div>
                <AnalyticsRange
                  setMinValue={setMinValue}
                  setMaxValue={setMaxValue}
                  minValue={minValue}
                  maxValue={maxValue}
                />
              </div>
            </div>
            <div className=" row pb-4 border-bottom">
              <div className=" mt-4 col-lg-4 col-12">
                <h6 className=" fw-semibold"> Contract Type</h6>
                <div>
                  <Select
                    styles={customStyles}
                    classNamePrefix="selectCustom"
                    ref={categoryRef}
                    className={` basic-multi-select `}
                    value={{ label: category || "", value: category || "" }}
                    options={(defaultCategories || [])?.map(
                      (category: ITemplateCategory) => ({
                        label: category?.category,
                        value: category?.category,
                      })
                    )}
                    onChange={(option: any) => {
                      setCategory(option?.value);
                    }}
                    placeholder="Select here"
                  />
                </div>
              </div>
              <div className=" mt-4 col-lg-4 col-12">
                <h6 className=" fw-semibold">Status</h6>
                <div>
                  <Select
                    ref={satatusRef}
                    styles={customStyles}
                    classNamePrefix="selectCustom"
                    className={` basic-multi-select `}
                    value={{ label: status || "", value: status || "" }}
                    options={(contractStatus || [])?.map((item: any) => ({
                      label:
                        item?.replace("_", " ")?.charAt(0)?.toUpperCase() +
                        item?.replace("_", " ")?.slice(1).toLowerCase(),
                      value: item,
                    }))}
                    onChange={(option: any) => {
                      setStatus(option?.value);
                    }}
                    placeholder="Select here"
                  />
                </div>
              </div>
              <div className=" mt-4 col-lg-4 col-12">
                <h6 className=" fw-semibold"> No.of Days</h6>
                <div>
                  <Select
                    ref={noOfDaysRef}
                    styles={customStyles}
                    classNamePrefix="selectCustom"
                    className={` basic-multi-select `}
                    options={noOfDays}
                    onChange={(option: any) => {
                      setStatus(option?.value);
                    }}
                    placeholder="Select here"
                  />
                  {/* <Select
              ref={noOfDaysRef}
              styles={customStyles}
              classNamePrefix="selectCustom"
              className={` basic-multi-select `}
              options={noOfDays}
              placeholder="Select here"
              onChange={(option: any) => setNumberOfDays(option.value)}
            /> */}
                </div>
              </div>
            </div>
            <div className=" row pb-4 border-bottom">
              <div className=" mt-4 col-lg-6 col-12">
                <h6 className=" fw-semibold"> Start date</h6>
                <div>
                  <DatePicker
                    className="w-100 mb-2"
                    value={startValues ? moment(startValues).toDate() : ""}
                    onChange={(date: any) => {
                      setStartValues(date);
                    }}
                  />
                </div>
              </div>
              <div className=" mt-4 col-lg-6 col-12">
                <h6 className=" fw-semibold"> End date</h6>
                <div>
                  <DatePicker
                    className="w-100 mb-2"
                    value={endValues ? moment(endValues).toDate() : ""}
                    onChange={(date: any) => {
                      setEndValues(date);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className=" mt-4 border-bottom pb-4">
              <h6 className=" fw-semibold">Counterparty </h6>
              <div className="mt-3">
                <input
                  className="doc_searchQueryInput form-control"
                  type="text"
                  name="doc_searchQueryInput"
                  placeholder="Search..."
                  value={searchText}
                  maxLength={50}
                  onChange={(e) => setSearchText(e?.target?.value)}
                />
              </div>
              <CounterpartyCheckbox
                setCheckedAll={setCheckedAll}
                checkedAll={checkedAll}
                counterpartyList={counterpartyList}
                setCounterpartyCheckedItem={setCounterpartyCheckedItem}
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
              />
            </div>

            <div className=" d-flex justify-content-end align-items-center my-5">
              <Button
                className="cancelbtn me-3 "
                size="sm"
                onClick={() => {
                  setOpenFilter(false);
                  onResetFilter();
                }}
              >
                Cancel
              </Button>
              <Button
                className="sharebtndanger "
                size="sm"
                onClick={() => handleFilterData("apply")}
              >
                Apply
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AnalyticsFilter;

const CounterpartyCheckbox = ({
  counterpartyList,
  setCheckedAll,
  checkedAll,
  checkedItems,
  setCounterpartyCheckedItem,
  setCheckedItems,
}: {
  counterpartyList: [];
  setCheckedAll: Function;
  checkedAll: boolean;
  checkedItems: any;
  setCounterpartyCheckedItem: Function;
  setCheckedItems: Function;
}) => {
  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });

    const allChecked = counterpartyList.every((label) => checkedItems[label]);

    setCheckedAll(allChecked);
  };

  const handleCheckAllChange = (event: any) => {
    const checked = event.target.checked;

    setCheckedAll(checked);

    const allCheckedItems = counterpartyList.reduce(
      (result: any, label: any) => {
        result[label] = checked;
        return result;
      },
      {}
    );

    setCheckedItems(allCheckedItems);
  };

  const checkedArray = Object.keys(checkedItems).filter(
    (label) => checkedItems[label]
  );
  setCounterpartyCheckedItem(checkedArray.join(","));

  return (
    <>
      {counterpartyList?.length > 0 && (
        <div
          className=" my-3 overflow-scroll"
          style={{
            maxHeight: "15vh",
          }}
        >
          <div className=" d-flex my-3 ">
            <div className="me-3">
              <input
                type="checkbox"
                name="checkAll"
                checked={checkedAll}
                onChange={handleCheckAllChange}
              />
            </div>
            <h6 className="m-0"> All</h6>
          </div>
          {counterpartyList?.map((list: any) => {
            return (
              <Fragment key={list}>
                <div className=" d-flex my-3 ">
                  <div className="me-3">
                    <input
                      type="checkbox"
                      name={list}
                      checked={checkedItems[list]}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <h6 className="m-0"> {list}</h6>
                </div>
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};
