import { Editor, Transforms } from "slate";

function Indent(editor: any, format: string) {
  if (editor?.marks?.isIndent) {
    if (format === "indentRight") {
      Editor.addMark(editor, "indentLevel", editor.marks.indentLevel + 1);
      Transforms.setNodes(editor, {
        indentLevel: editor.marks.indentLevel + 1,
      } as any);
      // } else if (format === "indentLeft" && editor.marks.indentLevel >= 3) {
    } else if (format === "indentLeft") {
      Editor.addMark(editor, "indentLevel", editor.marks.indentLevel - 1);
      Transforms.setNodes(editor, {
        indentLevel: editor.marks.indentLevel - 1,
      } as any);
    }
  } else {
    Editor.addMark(editor, "indentLevel", 1);
    Editor.addMark(editor, "isIndent", true);
    Transforms.setNodes(editor, {
      indentLevel: 1,
    } as any);
  }

  // const red: any = Editor.nodes(editor)
  // debugger

  // if (format === "indentLeft") {
  //   if (editor.marks?.isIndentLeft) {
  //     Editor.addMark(editor, "indentLevel", editor.marks.indentLevel - 1);
  //   } else {
  //     Editor.addMark(editor, "indentLevel", 1);
  //     Editor.addMark(editor, "isIndentLeft", true);
  //   }
  // } else if (format === "indentRight") {
  //   if (editor.marks?.isIndentRight) {
  //     Editor.addMark(editor, "indentLevel", editor.marks.indentLevel + 1);
  //   } else {
  //     Editor.addMark(editor, "indentLevel", 1);
  //     Editor.addMark(editor, "isIndentRight", true);
  //   }
  // } else {
  //   return;
  // }

  return <div>Indent</div>;
}

export default Indent;
