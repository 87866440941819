import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useParams } from "react-router-dom";
import IAdmin from "../../../../interfaces/response/IAdmin";
import { IMetaResponse } from "../../../../interfaces/response/IMetaResponse";
import { adminApi } from "../../../../utils/adminApi";

export interface AdminAccountMembersFilterInterface {
  page: number;
  limit: number;
  sort: string;
  search?: string;
}

interface ListAdminAccountMembersContextInterface {
  loading: boolean;
  members: IAdmin[];
  meta: IMetaResponse;
  getMembers: Function;
  filters: AdminAccountMembersFilterInterface;
  setFilters: (params: AdminAccountMembersFilterInterface) => void;
  handleInviteMember: (params: any) => void;
  reSendInviteMember: (params: any, id: string) => void;
}

const ListAdminMembersContext =
  createContext<ListAdminAccountMembersContextInterface | null>(null);

const ListAdminAccountMembersProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { accountId } = useParams();
  const [members, setMembers] = useState<IAdmin[]>([]);
  const [meta, setMeta] = useState<IMetaResponse>({
    total_count: 0,
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<AdminAccountMembersFilterInterface>({
    page: 1,
    limit: 10,
    sort: "-createdAt",
  });

  const getMembers = async (params:any) => {
    if (accountId) {
      try {
        setLoading(true);
        const { success, meta, users } = await adminApi.getAccountMembers(
          accountId,
          params
        );
        if (success) {
          setMeta(meta);
          setMembers(users);
        }
      } finally {
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers(filters);
  }, [filters]);

  const handleInviteMember = async (params: any) => {
    setLoading(true);
    const res = await adminApi.addMember(params);
    setLoading(false);
    return res?.data;
  };

  const reSendInviteMember = async (params: any, id: string) => {
    setLoading(true);
    const res = await adminApi.resendInvitation(params, id);
    setLoading(false);
    return res?.data;
  };

  const value: ListAdminAccountMembersContextInterface = {
    members,
    meta,
    loading,
    getMembers,
    filters,
    setFilters: (params: AdminAccountMembersFilterInterface) =>
      setFilters(params),
    handleInviteMember,
    reSendInviteMember,
  };

  return (
    <ListAdminMembersContext.Provider value={value}>
      {children}
    </ListAdminMembersContext.Provider>
  );
};

const useAdminListAccountMembers = () => useContext(ListAdminMembersContext);

export { ListAdminAccountMembersProvider, useAdminListAccountMembers };
