export enum TemplateSortEnum {
  recentlyAdded = "Recently Added",
  recentlyUpdated = "Recently Updated",
  nameAtoZ = "Template Name - A to Z",
  nameZtoA = "Template Name - Z to A",
}

export enum ContractSortEnum {
  recentlyAdded = "Recently Added",
  recentlyUpdated = "Recently Updated",
  nameAtoZ = "Contract Name - A to Z",
  nameZtoA = "Contract Name - Z to A",
}

export enum MemberSortEnum {
  recentlyAdded = "Recently Added",
  recentlyUpdated = "Recently Updated",
  nameAtoZ = "Contract Name - A to Z",
  nameZtoA = "Contract Name - Z to A",
}

export enum FolderSortEnum {
  recentlyAdded = "Recently Added",
  recentlyUpdated = "Recently Updated",
  nameAtoZ = "Folder Name - A to Z",
  nameZtoA = "Folder Name - Z to A",
}
