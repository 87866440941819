import isHotkey from "is-hotkey";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CustomBaseElement,
  Editor,
  Range,
  Element as SlateElement,
  Transforms,
} from "slate";
import DocumentLayout from "../../layouts/DocumentLayout";
import { ContractContextInterface } from "../../modules/Contracts/ContractProvider";
import { TemplateContextInterface } from "../../modules/Templates/TemplateProvider";
import { useEditor } from "../EditorProvider";
import WaterMark from "../WaterMark";
import { HoveringToolbar } from "../components/HoveringToolbar";
import Toolbars from "../components/Toolbars";
import { Portal, Toolbar } from "../components/components";

import {
  getRemoteCaretsOnLeaf,
  getRemoteCursorsOnLeaf,
  useDecorateRemoteCursors,
} from "@slate-yjs/react";
import { AiFillDelete, AiOutlineLink } from "react-icons/ai";
import { BiCommentDetail, BiCopy, BiCut } from "react-icons/bi";
import { FaPaste } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { MdCheckCircle } from "react-icons/md";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Text } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
} from "slate-react";
import SuccessToast from "../../ui/SuccessToast";
import LinkToast from "../EditorToasts/LinkToast";
import { toggleMark } from "../components/ToolbarMark";
import CommentThreadPopover from "../components/comments/src/component/CommentThreadPopover";
import useAddCommentThreadCallback from "../components/comments/src/hooks/useAddCommentThreadCallback";
import useSelection from "../components/comments/src/hooks/useSelection";
import { activeCommentThreadIDAtom } from "../components/comments/src/utils/CommentState";
import { activeSuggestionThreadIDAtom } from "../components/Suggestions/utils/SuggestionState";
import {
  initializeStateWithAllCommentThreads,
  insertCommentThread,
  shouldAllowNewCommentThreadAtSelection,
} from "../components/comments/src/utils/EditorCommnentUtils";
import { CursorData, variableElement } from "../types/types";
import {
  HOTKEYS,
  HOTKEYSELEMENTS,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
} from "../utils/constants";
import { addAlpha } from "../utils/utils";
import Header from "../components/PageHeader/Header";
import Footer from "../components/PageFooter/Footer";
// import { CgScrollH } from "react-icons/cg";

import { RenderElement } from "./Element";
import { Leaf } from "./Leaf";
import { MyEditable } from "./MyEditable";
import {
  indentBlock,
  isBlockActive,
  toggleBlock,
} from "../components/ToolbarButton";
import {
  initializeStateWithAllSuggestionCommentThreads,
  insertSuggestionThread,
} from "../components/Suggestions/utils/EditorSuggestionUtils";
import SuggestionThreadPopover from "../components/Suggestions/component/SuggestionThreadPopover";
import useAddSuggestionThreadCallback from "../components/comments/src/hooks/useAddCommentThreadCallback";
import { deserialize } from "./WithHtml copy";

export const CHARACTERS = [
  "Aayla SecuraQQ",
  "Adi Gallia",
  "Admiral Dodd Rancit",
  "Admiral Firmus Piett",
  "Admiral Gial Ackbar",
];

let zoomLevel: any = 1;
let container: any;

export const zoomIn = () => {
  zoomLevel += 0.1;
  if (zoomLevel > 1.7) {
    return;
  } else {
    container.style.transformOrigin = "50% 0%";
    // container.style.transition = "0.5s";
    container.style.transform = `scale(${zoomLevel})`;
  }
};

export const zoomOut = () => {
  zoomLevel -= 0.1;
  if (zoomLevel < 0.3) {
    return;
  } else {
    // container.style.transition = "0.5s";
    container.style.transformOrigin = "50% 0%";
    container.style.transform = `scale(${zoomLevel})`;
  }
};

const ClmEditor = ({
  parentContext,
}: {
  parentContext: TemplateContextInterface | ContractContextInterface;
}) => {
  const inputRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();

  const context = useEditor();
  const [contents] = useState<any>([]);
  const [linkShow, setLinkShow] = useState<boolean>(false);

  const box = useRef(null);
  const ref = useRef<any>();
  const editor: any = useSlate();

  const [chars, setChars] = useState<any>(
    //this is not needed, will remove later..
    CHARACTERS.filter((c) =>
      c.toLowerCase().startsWith(context?.search.toLowerCase())
    ).slice(0, 10)
  );

  const editorRef: any = useRef(null);
  const [previousSelection, selection] = useSelection(editor);

  const activeCommentThreadID = useRecoilValue(activeCommentThreadIDAtom);
  const activeSuggestionThreadID = useRecoilValue(activeSuggestionThreadIDAtom);

  // const addCommentThread = useAddCommentThreadCallback();
  // const addSuggestionThread = useAddSuggestionThreadCallback();

  const editorOffsets =
    editorRef.current != null
      ? {
          x: editorRef.current.getBoundingClientRect().x,
          y: editorRef.current.getBoundingClientRect().y,
        }
      : null;

  useEffect(() => {
    location?.pathname.includes("contracts") &&
      context?.docContents?.status != "DRAFT" &&
      context?.setMode("Suggesting");
    location?.pathname.includes("templates") && context?.setMode("Editing");

    let tempCharacters = context?.variables.map((c: any) => {
      return { variableName: c?.name, variableValue: c?.value };
    });

    function filterArrayByVariableName(arr: any, search: any) {
      return arr.filter((item: any) =>
        item.variableName?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    const filteredArray = filterArrayByVariableName(
      tempCharacters,
      context?.search
    );

    setChars(filteredArray.slice(0, 10));
  }, [context?.variables, context?.search]);

  useEffect(() => {
    if (contents?.length > 0) {
      context?.setContents(contents);
    }
  }, [contents]);

  useEffect(() => {
    if (id && id?.length > 0) {
    }
  }, [id]);

  const renderElement = useCallback(
    (props: RenderElementProps) => RenderElement({ ...props }),
    []
  );

  useEffect(() => {
    if (context?.target && chars.length > 0) {
      const el: any = ref?.current;
      const domRange: any = ReactEditor?.toDOMRange(
        context?.editor,
        context?.target
      );
      const rect: any = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [
    chars.length,
    context?.editor,
    context?.index,
    context?.search,
    context?.target,
  ]);

  const insertVariable = (
    editor: Editor,
    variableName: string,
    variableValue: string
  ) => {
    const variable: variableElement | any = {
      type: "variable",
      variableName,
      variableValue,
      children: [{ text: " " }] as any,
    };
    Transforms.insertNodes(editor, variable as CustomBaseElement | any);
    Transforms.move(editor);
  };

  function renderLeaf(props: RenderLeafProps | any) {
    getRemoteCursorsOnLeaf<CursorData, any>(props.leaf).forEach((cursor) => {
      if (cursor.data) {
        props.children = (
          <span style={{ backgroundColor: addAlpha(cursor.data.color, 0.5) }}>
            {props.children}
          </span>
        );
      }
    });

    getRemoteCaretsOnLeaf<CursorData, any>(props.leaf).forEach((caret) => {
      if (caret.data) {
        props.children = (
          <span className="position-relative" spellCheck={false}>
            <span
              contentEditable={false}
              className="  w-0.5 left-[-1px] position-absolute top-0 bottom-0"
              style={{ backgroundColor: caret.data.color }}
              spellCheck={false}
            />
            <span
              contentEditable={false}
              className=" text-xs text-white left-[-1px] top-0 whitespace-nowrap rounded rounded-bl-none px-1 py-1 select-none "
              style={{
                backgroundColor: caret.data.color,
                transform: "translateY(-100%)",
                position: "absolute",
                width: "max-content",
              }}
              spellCheck={false}
            >
              {caret.data.name}
            </span>
            <span
              style={{
                backgroundColor: caret.data.color,
                position: "relative",
                border: "2px solid",
                borderLeft: "2px",
                borderColor: caret.data.color,
              }}
              spellCheck={false}
            ></span>
            {props.children}
          </span>
        );
      }
    });

    return Leaf({ ...props });
  }

  useEffect(() => {
    const handleSave = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        onSave();
      }
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        editor.insertSoftBreak();
      }
    };
    window.addEventListener("keydown", handleSave);
    return () => {
      window.removeEventListener("keydown", handleSave);
    };
  }, []);

  const decorate: any = useDecorateRemoteCursors();
  // #################### define type for the decorate #####################//

  const excludedKeys = [
    "Alt",
    "Tab",
    "CapsLock",
    "Control",
    "Shift",
    "ArrowLeft",
    "ArrowUp",
    "ArrowDown",
    "ArrowRight",
    "Backspace",
  ];

  const onKeyDown = useCallback(
    (event: any) => {
      let { selection }: any = editor;
      // let offsetAnchorFocus = Editor.range(editor, selection);
      // console.log(
      //   offsetAnchorFocus.anchor.offset - offsetAnchorFocus.focus.offset,
      //   "Transforms.select(editor, Editor.range(editor, selection))"
      // );

      // console.log(
      //   Editor.range(editor, selection),
      //   "Transforms.select(editor, Editor.range(editor, selection))"
      // );
      // console.log(
      //   Transforms.select(editor, Editor.range(editor, selection)),
      //   "Transforms.select(editor, Editor.range(editor, selection))"
      // );

      if (context?.mode?.includes("Suggestin")) {
        if (event.key === "Backspace") {
          event.preventDefault();

          const input: any = inputRef?.current;
          if (input) {
            const currentPosition = input?.selectionStart;
            if (currentPosition > 0) {
              input.setSelectionRange(currentPosition - 1, currentPosition - 1);
            }
          }
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, {
            unit: "character",
          });
          const range1 = wordBefore && Editor.range(editor, wordBefore, start);

          // For after range
          const [startAfter] = Range.edges(selection);
          const wordBeforeAfter: any = Editor.after(editor, startAfter, {
            unit: "character",
          });
          const range2 =
            wordBefore && Editor.range(editor, wordBeforeAfter, start);
          editor.selection = range2;
          let objectMarks1: any = Editor.marks(editor);
          let includesSuggestion1 = Object.keys(objectMarks1).some((key) =>
            key?.includes("suggestion")
          );
          const suggestionKeys = Object.keys(objectMarks1).filter((key) =>
            key?.startsWith("suggestion_")
          );
          if (range1) {
            editor.selection = range1;
            // if suggestion marks is not included then
            // Create suggestion mark
            // else continue with the previous mark.

            if (includesSuggestion1) {
              Editor.addMark(editor, suggestionKeys[0], true);
              Editor.addMark(editor, suggestionKeys[1], true);
              Editor.addMark(editor, "suggestionStikeThrough", true);
              Editor.addMark(editor, "SuggestionDelete", true);
            } else {
              insertSuggestionThread(editor, onInsertSuggestion);
              Editor.addMark(editor, "suggestionStikeThrough", true);
              Editor.addMark(editor, "SuggestionDelete", true);
            }
          }
        } else if (event.key === "Delete") {
          event.preventDefault();
          Transforms.select(editor, Editor.range(editor, selection));
          Editor.addMark(editor, "suggestionStikeThrough", true);
          Editor.addMark(editor, "SuggestionDelete", true);
          insertSuggestionThread(editor, onInsertSuggestion);
        } else if (!excludedKeys.includes(event.key)) {
          let objectMarks: any = Editor.marks(editor);
          let includesSuggestion = Object.keys(objectMarks).some((key) =>
            key.includes("suggestion")
          );
          if (!includesSuggestion) {
            insertSuggestionThread(editor, onInsertSuggestion);
            Editor.removeMark(editor, "suggestionStikeThrough");
            Editor.addMark(editor, "color", "green");
            Editor.addMark(editor, "SuggestionAdd", true);
          } else {
            Editor.removeMark(editor, "suggestionStikeThrough");
            Editor.addMark(editor, "color", "green");
            Editor.addMark(editor, "SuggestionAdd", true);
          }
        }
      } else {
        const marks: any = Editor.marks(editor);
        const hasSuggestionStrikeThrough = Object.keys(marks).includes(
          "suggestionStikeThrough"
        );

        const keys = Object.keys(marks);

        const hasColor = Object.keys(marks).includes("color");
        if (hasSuggestionStrikeThrough) {
          Editor.removeMark(editor, "suggestionStikeThrough");
          Editor.removeMark(editor, "SuggestionDelete");
          keys[0] && Editor.removeMark(editor, keys[0]);
          keys[1] && Editor.removeMark(editor, keys[1]);
        } else if (hasColor) {
          Editor.removeMark(editor, "color");
          Editor.removeMark(editor, "SuggestionAdd");
          keys[0] && Editor.removeMark(editor, keys[0]);
          keys[1] && Editor.removeMark(editor, keys[1]);
        }
      }

      if (event.ctrlKey && event.key.toLowerCase() === "m") {
        event.preventDefault();
        indentBlock(editor, "indentRight");
      }
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "m") {
        event.preventDefault();
        indentBlock(editor, "indentLeft");
      }

      if (event.altKey && event.shiftKey) {
        switch (event.key) {
          case "!":
            toggleBlock(context?.editor, "heading-one");
            break;
          case "@":
            toggleBlock(context?.editor, "heading-two");
            break;
          case "#":
            toggleBlock(context?.editor, "heading-three");
            break;
          case "$":
            toggleBlock(context?.editor, "heading-four");
            break;
          case "%":
            toggleBlock(context?.editor, "heading-five");
            break;
          case "^":
            toggleBlock(context?.editor, "heading-six");
            break;
          case "&":
            toggleBlock(context?.editor, "code");
            break;
          case "*":
            toggleBlock(context?.editor, "block-quote");
            break;
          default:
            toggleBlock(context?.editor, "paragraph");
            break;
        }
      }

      switch (event.key) {
        case " ":
          if (selection?.anchor?.offset === 2) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, {
              unit: "word",
            });
            const beforeRange =
              wordBefore && Editor.range(editor, wordBefore, start);
            const beforeText =
              beforeRange && Editor.string(editor, beforeRange);
            if (beforeText?.includes("1.")) {
              Transforms.removeNodes(editor, selection);
              Transforms.insertNodes(editor, {
                type: "numbered-list",
                children: [
                  {
                    type: "list-item",
                    children: [{ text: "" }],
                  },
                ],
              } as any);
            }
            if (beforeText?.includes("*.")) {
              Transforms.removeNodes(editor, selection);
              Transforms.insertNodes(editor, {
                type: "bulleted-list",
                children: [
                  {
                    type: "list-item",
                    children: [{ text: "" }],
                  },
                ],
              } as any);
            }
          }

          break;
        case "Backspace":
          // const { selection }: any = editor;
          // go inside this block only when the selection path is 0 i.e top most cursor

          if (selection?.anchor?.path[0] === 0) {
            if (selection && Range.isCollapsed(selection)) {
              const [start] = Range.edges(selection);
              const block = Editor.node(editor, start.path);
              if (block && Editor.isStart(editor, start, block[1])) {
                Transforms.unwrapNodes(editor, {
                  match: (n: any) =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    LIST_TYPES.includes((n as any).type as any) &&
                    !TEXT_ALIGN_TYPES.includes(
                      "bulleted-list" || "numbered-list"
                    ),
                  split: true,
                });
                let newProperties = {
                  type: "paragraph",
                };
                Transforms.setNodes<SlateElement>(editor, newProperties);
              }
            }
          }

          const [match]: any = Editor.nodes(editor, {
            match: (n: any) =>
              n.type === "bulleted-list" || n.type === "numbered-list",
            universal: true,
          });

          if (match) {
            let format = match[0].type;
            const isActive = isBlockActive(
              editor,
              format,
              TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
            );
            const isList = LIST_TYPES.includes(format);
            Transforms.unwrapNodes(editor, {
              match: (n: any) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes((n as any).type as any) &&
                !TEXT_ALIGN_TYPES.includes(format),
              split: true,
            });
            let newProperties: any;
            if (TEXT_ALIGN_TYPES.includes(format)) {
              newProperties = {
                align: isActive ? undefined : format,
              };
            } else {
              newProperties = {
                type: isActive ? "paragraph" : isList ? "list-item" : format,
              };
            }
            Transforms.setNodes<SlateElement>(editor, newProperties);
            if (!isActive && isList) {
              const block: any = { type: format, children: [] };
              Transforms.wrapNodes(editor, block);
            }
          }

          break;
        case "Tab":
          // ReactEditor.focus(editor);
          const listContainer: any = Editor.fragment(editor, selection);
          const [matchNumberedList]: any = Editor.nodes(editor, {
            match: (n: any) => n.type === "list-item",
          });

          if (listContainer[0].type === "numbered-list") {
            if (matchNumberedList) {
              Transforms.insertNodes(editor, {
                type: "numbered-list",
                children: [
                  {
                    type: "list-item",
                    children: [{ text: "" }],
                  },
                ],
              } as any);
            }
          }

          //--------------------------for unordered list-----------------------------------

          if (listContainer[0].type === "bulleted-list") {
            const [matchBullettedList] = Editor.nodes(editor, {
              match: (n: any) => n.type === "list-item",
            });

            if (matchBullettedList) {
              Transforms.insertNodes(editor, {
                type: "bulleted-list",
                children: [
                  {
                    type: "list-item",
                    children: [{ text: "" }],
                  },
                ],
              } as any);
            }
          }
          break;
        case "Enter":
          const marks: any = Editor.marks(editor);
          const hasComments = Object.keys(marks).includes("commentThread");
          hasComments &&
            Editor.removeMark(editor, Object.keys(marks).toString());
          break;
      }

      if (context?.target) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex =
              context?.index >= chars.length - 1 ? 0 : context?.index + 1;
            context?.setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex =
              context?.index <= 0 ? chars.length - 1 : context?.index - 1;
            context?.setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();

            Transforms.select(context?.editor, context?.target);

            insertVariable(
              context?.editor,
              chars[context?.index].variableName,
              chars[context?.index].variableValue
            );
            break;
          case "Escape":
            event.preventDefault();
            context?.setTarget(null);
            context?.setSearch(null);
            break;
          // case "Tab":
          //   event.preventDefault();
          //   Transforms.insertText(context?.editor, "        ");
          //   break;

          case "Backspace":
            break;

          default:
            break;
        }
      }

      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark: string = HOTKEYS[hotkey];
          toggleMark(context?.editor, mark);
        }
      }

      for (const hotkey in HOTKEYSELEMENTS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark: string = HOTKEYSELEMENTS[hotkey];
          toggleBlock(context?.editor, mark);
        }
      }
    },
    [context?.index, context?.target, chars, context?.editor, context?.mode]
  );

  const [contextMenuPosition, setContextMenuPosition] = useState<any>(null);

  const handleContextMenu = (event: any) => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;
    setContextMenuPosition({ x, y });
  };
  const handleCloseContextMenu = () => {
    setContextMenuPosition(null);
  };

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);

  const onSave = () => {
    context?.handleUpdateDocument();
    setShowSuccessToast(true);
    setBodyMessage("Saved");
  };

  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const setActiveSuggestionThreadID = useSetRecoilState(
    activeSuggestionThreadIDAtom
  );

  const addCommentThread = useAddCommentThreadCallback();
  const addSuggestionThread = useAddSuggestionThreadCallback();

  const onInsertComment = useCallback(() => {
    const newCommentThreadID: any = insertCommentThread(
      context?.editor,
      addCommentThread
    );
    setActiveCommentThreadID(newCommentThreadID);
  }, [context?.editor, addCommentThread, setActiveCommentThreadID]);

  const onInsertSuggestion = useCallback(() => {
    const newSuggestionThreadID: any = insertSuggestionThread(
      context?.editor,
      addSuggestionThread
    );
    setActiveSuggestionThreadID(newSuggestionThreadID);
  }, [context?.editor, addSuggestionThread, setActiveSuggestionThreadID]);

  useEffect(() => {
    let interval: any;
    initializeStateWithAllCommentThreads(
      context?.editor,
      addCommentThread,
      context?.getComments
      // context
    );

    initializeStateWithAllSuggestionCommentThreads(
      context?.editor,
      addSuggestionThread,
      context?.getComments
      // context
    );
    interval = setInterval(() => {
      initializeStateWithAllCommentThreads(
        context?.editor,
        addCommentThread,
        context?.getComments
        // context
      );
      initializeStateWithAllSuggestionCommentThreads(
        context?.editor,
        addSuggestionThread,
        context?.getComments
        // context
      );
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [context?.editor, addCommentThread, addSuggestionThread]);

  const ContextMenu = ({ x, y, onClose }: any) => {
    useEffect(() => {
      const handleDocumentClick = (event: any) => {
        const contextMenu = document.getElementById("context-menu");
        if (contextMenu && !contextMenu.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener("click", handleDocumentClick);
      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }, [onClose]);

    // const setActiveCommentThreadID = useSetRecoilState(
    //   activeCommentThreadIDAtom
    // );
    // const setActiveSuggestionThreadID = useSetRecoilState(
    //   activeSuggestionThreadIDAtom
    // );

    // const addCommentThread = useAddCommentThreadCallback();
    // const addSuggestionThread = useAddSuggestionThreadCallback();

    // const onInsertComment = useCallback(() => {
    //   const newCommentThreadID: any = insertCommentThread(
    //     context?.editor,
    //     addCommentThread
    //   );
    //   setActiveCommentThreadID(newCommentThreadID);
    // }, [context?.editor, addCommentThread, setActiveCommentThreadID]);

    // const onInsertSuggestion = useCallback(() => {
    //   const newCSuggestionThreadID: any = insertSuggestionThread(
    //     context?.editor,
    //     addSuggestionThread
    //   );
    //   setActiveSuggestionThreadID(newCSuggestionThreadID)
    // }, [context?.editor, addSuggestionThread, setActiveSuggestionThreadID]);

    return (
      <>
        <div
          id="context-menu"
          style={{
            position: "absolute",
            top: `${y}px`,
            left: `${x}px`,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            zIndex: "1",
            width: "350px",
            padding: "10px",
          }}
        >
          <div
            className="d-flex justify-content-between  m-2 cursor-pointer"
            onClick={handleCutSelectedText}
          >
            <div>
              {" "}
              <BiCut size={16} className="ms-2" />
              <span className="ms-2">Cut</span>
            </div>
            <div>CTRL+X</div>
          </div>

          <div
            className="d-flex justify-content-between  m-2 cursor-pointer"
            onClick={handleGetSelectedText}
          >
            <div>
              {" "}
              <BiCopy size={16} className="ms-2" />
              <span className="ms-2">Copy</span>
            </div>
            <div>CTRL+C</div>
          </div>
          <div
            className="d-flex justify-content-between  m-2 cursor-pointer"
            onClick={handlePasteSelectedText}
          >
            <div>
              {" "}
              <FaPaste size={16} className="ms-2" />
              <span className="ms-2">Paste</span>
            </div>
            <div>CTRL+V</div>
          </div>
          <div
            className="d-flex justify-content-between m-2 cursor-pointer"
            onClick={() => onSave()}
          >
            <div>
              {" "}
              <FiSave size={16} className="ms-2" />
              <span className="m-2">Save</span>
            </div>
            <div>CTRL+S</div>
          </div>
          <div
            className="d-flex justify-content-between m-2 cursor-pointer"
            onClick={handleDeleteSelectedText}
          >
            <div>
              {" "}
              <AiFillDelete size={16} className="ms-2" />
              <span className="m-2">Delete</span>
            </div>
            <div>Delete</div>
          </div>

          {shouldAllowNewCommentThreadAtSelection(
            context?.editor,
            context?.editor?.selection
          ) && (
            <div
              className="d-flex justify-content-between m-2 cursor-pointer"
              onMouseDown={onInsertComment}
              onClick={() =>
                !shouldAllowNewCommentThreadAtSelection(
                  context?.editor,
                  context?.editor?.selection
                )
              }
            >
              <div>
                {" "}
                <BiCommentDetail size={16} className="ms-2" />
                <span className="m-2">Comment</span>
              </div>
              <div>CTRL+M</div>
            </div>
          )}

          <div
            className="d-flex justify-content-between m-2 cursor-pointer"
            onClick={() => setLinkShow(true)}
          >
            <div>
              {" "}
              <AiOutlineLink size={16} className="ms-2" />
              <span className="m-2">Insert link</span>
            </div>
            {/* <div>CTRL + M</div> */}
          </div>
        </div>
      </>
    );
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Text copied to clipboard!");
    });
  };

  // -------------------------------------------------------------------
  function getSelectedText(editor: Editor) {
    const { selection } = editor;

    if (selection) {
      const [start, end] = Range.edges(selection);
      const selectedText = Editor.string(editor, { anchor: start, focus: end });
      handleCopyToClipboard(selectedText);
      return selectedText;
    }

    return null;
  }
  function cutSelectedText(editor: Editor) {
    const { selection }: any = editor;
    const [start, end] = Range.edges(selection);
    const selectedText = Editor.string(editor, { anchor: start, focus: end });
    handleCopyToClipboard(selectedText);
    if (selection) {
      Transforms.delete(editor, { at: selection });
    }
  }

  const handleCutSelectedText = () => {
    cutSelectedText(editor);
  };

  function deleteSelectedText(editor: Editor) {
    const { selection } = editor;
    if (selection) {
      Transforms.delete(editor, { at: selection });
    }
  }

  const handleDeleteSelectedText = () => {
    deleteSelectedText(editor);
  };

  async function pasteSelectedText(editor: Editor) {
    const text = await navigator.clipboard.readText();
    Transforms.insertText(editor, text);
  }

  const handlePasteSelectedText = () => {
    pasteSelectedText(editor);
  };

  const handleGetSelectedText = () => {
    const selectedText = getSelectedText(editor);
    console.log("Selected text:", selectedText);
  };

  // const [range, setRange] = useState<any>([]);
  // console.log(range, "dwedwedewdewdwedwedwe");
  const [, setRange] = useState<any>([]);

  const decorateSearch = useCallback(
    ([node, path]: any) => {
      const ranges: any = [];

      if (context?.searchText && Text.isText(node)) {
        const { text } = node;
        const parts = text
          .toLowerCase()
          .split(context?.searchText.toLowerCase());
        let offset = 0;

        parts.forEach((part, i) => {
          if (i !== 0) {
            ranges.push({
              anchor: { path, offset: offset - context?.searchText.length },
              focus: { path, offset },
              highlight: true,
            });
            setRange(
              ranges?.push({
                anchor: { path, offset: offset - context?.searchText.length },
                focus: { path, offset },
                highlight: true,
              })
            );
          }

          offset = offset + part.length + context?.searchText.length;
        });
      }
      return ranges;
    },
    [context?.searchText]
  );

  useEffect(() => {
    container = document.getElementById("printPage");
  }, []);

  const AddBoldProperty = (data: any) => {
    var uuids = insertSuggestionThread(editor, onInsertSuggestion);

    return data.map((paragraph: any) => {
      return {
        ...paragraph,
        children: paragraph.children.map((child: any) => ({
          ...child,
          color: "green",
          SuggestionAdd: true,
          ["suggestion_" + uuids]: true,
        })),
      };
    });
  };

  const onPaste = (data: any) => {
    data.preventDefault();
    const { clipboardData } = data;
    const html = clipboardData.getData("application/x-slate-fragment");
    if (html) {
      let atobConvSemiRaw = atob(html);
      var fullConvertedToSlateJs: any = decodeURIComponent(atobConvSemiRaw);
      let parsedJSONData = JSON.parse(fullConvertedToSlateJs);
      if (context?.mode === "Suggesting") {
        const outputData = AddBoldProperty(parsedJSONData);
        const strigifiedData = JSON.stringify(outputData, null, 2);
        Transforms.insertFragment(editor, JSON.parse(strigifiedData));
      } else {
        Transforms.insertFragment(editor, parsedJSONData);
      }
    } else {
      const { clipboardData } = data;
      const html = clipboardData.getData("text/html");
      if (html) {
        const parsed = new DOMParser().parseFromString(html, "text/html");
        const fragment = deserialize(parsed.body);
        if (context?.mode === "Suggesting") {
          const outputData = AddBoldProperty(fragment);
          const strigifiedData = JSON.stringify(outputData, null, 2);
          Transforms.insertFragment(editor, JSON.parse(strigifiedData));
        } else {
          Transforms.insertFragment(editor, fragment);
        }

        return;
      }
    }
  };

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  !isOnline && alert("Device Offline");

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>
      <div>
        <DocumentLayout
          context={parentContext}
          contents={contents}
          pageTitle="CLM - Edit Document"
          toolbar={
            <div>
              {parentContext.showToolbar && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  ref={box}
                >
                  <Toolbar className=" p-0 m-0 position-relative">
                    <Toolbars />
                  </Toolbar>
                </div>
              )}
            </div>
          }
        >
          <div
            style={{
              position: "absolute",
              top: "-20px",
              zIndex: 1,
              // width: "81%",
              width: "100%",
            }}
          >
            {/* <Header /> */}
          </div>
          <div className="waterMark">
            <WaterMark context={parentContext} />
          </div>
          <HoveringToolbar />

          <div
            className="d-flex justify-content-center flex-col"
            ref={editorRef}
            onContextMenu={handleContextMenu}
            id="printPage"
            style={{ flexDirection: "column" }}
          >
            <header>
              <Header />
            </header>
            <MyEditable
              ref={inputRef}
              onPaste={(e: any) => onPaste(e)}
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              onKeyDown={onKeyDown}
              // decorate={decorate}
              decorate={
                context?.searchText?.length > 0 ? decorateSearch : decorate
              }
              className="max-w-4xl w-full flex-col break-words pt-4 w-100"
              // readOnly={context?.accessOption}
              readOnly={
                context?.mode === "Viewing"
                  ? true
                  : context?.accessOption
                  ? true
                  : false
              }
            />
            <div>
              <Footer />
            </div>
            {contextMenuPosition && (
              <ContextMenu
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                onClose={handleCloseContextMenu}
              />
            )}

            <div className=" editorPopupSuggestion">
              {activeSuggestionThreadID != null ? (
                <>
                  {/* {alert(activeSuggestionThreadID + "------Suggestion")} */}
                  <SuggestionThreadPopover
                    editorOffsets={editorOffsets}
                    selection={selection ?? previousSelection}
                    threadID={activeSuggestionThreadID}
                  />
                </>
              ) : null}
            </div>

            <div className="editorPopup">
              {activeCommentThreadID != null ? (
                <>
                  {/* {alert(activeCommentThreadID + "+++++comment")} */}
                  <CommentThreadPopover
                    editorOffsets={editorOffsets}
                    selection={selection ?? previousSelection}
                    threadID={activeCommentThreadID}
                  />
                </>
              ) : null}
            </div>

            {context?.target && (
              <Portal>
                <div
                  ref={ref}
                  style={{
                    top: "250px",
                    left: "1299px",
                    position: "absolute",
                    zIndex: 1,
                    padding: "3px",
                    background: "#fefefe",
                    borderRadius: "4px",
                    boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                  }}
                  data-cy="mentions-portal"
                >
                  {chars.length > 0 ? (
                    chars.map((char: any, i: any) => (
                      <>
                        <div
                          key={char.variableName}
                          onClick={() => {
                            Transforms.select(context?.editor, context?.target);
                            insertVariable(
                              context?.editor,
                              char.variableName,
                              char.variableValue
                            );
                          }}
                          style={{
                            padding: "1px 3px",
                            borderRadius: "3px",
                            background:
                              i === context?.index ? "#B4D5FF" : "transparent",
                          }}
                        >
                          {char.variableName}
                        </div>
                      </>
                    ))
                  ) : (
                    <div
                      ref={ref}
                      key={"123"}
                      onClick={() => {}}
                      style={{
                        left: "-300px",
                        position: "absolute",
                        zIndex: 1,
                        padding: "3px",
                        background: "#fefefe",
                        borderRadius: "4px",
                        boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                      }}
                    >
                      No variables found
                    </div>
                  )}
                </div>
              </Portal>
            )}
            <div style={{ width: "83.3%" }}>{/* <Footer /> */}</div>
          </div>
        </DocumentLayout>
        <LinkToast setShow={setLinkShow} show={linkShow} />
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ClmEditor;
