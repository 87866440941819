export const doc1 = [
  {
    type: "paragraph",
    children: [
      { text: "This is editable " },
      { text: "rich", bold: true },
      { text: " text, " },
      { text: "much", italic: true },
      { text: " better than a " },
      { text: "<textarea>", code: true },
      { text: "!" },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: "bold", bold: true },
      {
        text: ", or add a semantically rendered block quote in the middle of the page, like this:",
      },
    ],
  },
  {
    type: "block-quote",
    children: [{ text: "A wise quote." }],
  },
  {
    type: "paragraph",
    align: "center",
    children: [{ text: "Try it out for yourself!" }],
  },
];
export const doc2 = [
  {
    type: "paragraph",
    children: [
      { text: "This is not editable But" },
      { text: "rich", bold: true },
      { text: " text, " },
      { text: "much", strikeThrough: true },
      { text: " better than a " },
      { text: "<textarea>", code: true },
      { text: "!" },
      { text: "much", bgColor: "red" },
      { text: " better than a " },
    ],
  },
  {
    type: "paragraph",
    children: [
      {
        text: "Since it's rich text, you can do things like turn a  of text ",
      },
      { text: "bold", bold: true },
      {
        text: ", or add a semantically rendered block quote in the front of the page, like this:",
      },
    ],
  },
  {
    type: "block-quote",
    children: [{ text: "A wise quote." }],
  },
  {
    type: "paragraph",
    align: "center",
    children: [{ text: "Try it out for yourself!" }],
  },
];

export const doc3 = [
  {
    type: "paragraph",
    align: "center",
    children: [{ text: "ABCDEFGHIJLKMNOPQRSTUVWXYZ" }],
  },
];

export * from "./slate-diff";
