import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import * as Yup from "yup";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import { api } from "../../utils/api";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import FailureToast from "../../ui/FailureToast";
import SettingsLayout from "./SettingsLayout";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

interface CompanyInterface {
  name?: string | "";
  email?: string | "";
  location?: string | "";
  website?: string | "";
  contact_number?: string | "";
  linkedin?: string | "";
  facebook?: string | "";
  youtube?: string | "";
  instagram?: string | "";
  twitter?: string | "";
  createdAt?: string | "";
}

const CompanyPage = () => {
  const context = useAuthentication();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const initialValues: CompanyInterface = {
    name: (context && context?.companyDetails?.name) || "",
    email:
      (context &&
        context?.companyDetails?.members?.find(
          (member: any) => member?.is_primary === "true"
        )?.email) ||
      "",
    location: (context && context?.companyDetails?.location) || null,
    website: (context && context?.companyDetails?.website) || "",
    createdAt: (context && context?.companyDetails?.createdAt) || null,
    contact_number: context?.companyDetails?.contact_number || null,
    linkedin: (context && context?.companyDetails?.linkedin) || "",
    facebook: (context && context?.companyDetails?.facebook) || "",
    youtube: (context && context?.companyDetails?.youtube) || "",
    instagram: (context && context?.companyDetails?.instagram) || "",
    twitter: (context && context?.companyDetails?.twitter) || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("first Name is required"),
    email: Yup.string().email("Invalid email address").required(),
    location: Yup.string(),
    linkedin: Yup.string().min(2, "Too Short!").max(1000),
    createdAt: Yup.string().min(2, "Too Short!").max(1000),
    website: Yup.string().min(2, "Too Short!").max(1000),
    facebook: Yup.string().min(2, "Too Short!").max(1000),
    youtube: Yup.string().min(2, "Too Short!").max(1000),
    instagram: Yup.string().min(2, "Too Short!").max(1000),
    twitter: Yup.string().min(2, "Too Short!").max(1000),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      if (context?.currentUser?.roles?.find((el: any) => el === "ADMIN")) {
      }
      const formData = new FormData();
      formData.set("name", values?.name);
      formData.set("email", values?.email);
      formData.set("location", values?.location);
      formData.set("linkedin", values?.linkedin);

      formData.set("createdAt", new Date(values?.createdAt).toISOString());
      formData.set("website", values?.website);
      formData.set("facebook", values?.facebook);
      formData.set("youtube", values?.youtube);
      formData.set("instagram", values?.instagram);
      formData.set("twitter", values?.twitter);
      formData.set("contact_number", phoneNumber ? phoneNumber : null);
      try {
        setLoader(true);
        const res = await api.updateCompanyProfile(formData);
        context?.getCompanyProfile();
        setLoader(false);
        if (res?.status === 200) {
          setShowSuccessToast(true);
          context?.setCompanyDetails(values);
          setBodyMessage("Company Profile Updated");
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    context?.getCompanyProfile();
  }, []);

  useEffect(() => {
    setPhoneNumber((context && context?.companyDetails?.contact_number) || "");
  }, [context]);
  const { errors, handleSubmit, isSubmitting, values, resetForm } = formik;

  return (
    <>
      <SettingsLayout>
        <FormikProvider value={formik}>
          <Form noValidate={true} onSubmit={handleSubmit}>
            <div className={`${isMobile ? " pt-2" : "pt-4"}`}>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="name"
                    type="text"
                    validationSchema={validationSchema}
                    label="Company Name"
                    placeholder="Company Name"
                    errors={errors}
                    autoFocus
                    value={values?.name}
                  />
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <div className="from-control w-100">
                    <div className="row status-title form-label mb-2">
                      Establishment on
                    </div>
                    <DatePicker
                      className=" w-100 from-control "
                      value={
                        values?.createdAt
                          ? moment(values?.createdAt).toDate()
                          : ""
                      }
                      disabled
                      onChange={(date: any) => {
                        formik?.setFieldValue("createdAt", date);
                      }}
                      minDate={moment().toDate()}
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6 col-12">
                  <label className="text-dark mt-3">Primary contact</label>
                  <FormGroup className="mt-2 ">
                    <div className="phone-input-css ">
                      <PhoneInput
                        value={phoneNumber}
                        placeholder="Enter contact number"
                        name="contact_number"
                        country={"in"}
                        defaultCountry="IN"
                        onChange={setPhoneNumber}
                        error={
                          phoneNumber
                            ? isValidPhoneNumber(phoneNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="email"
                    type="email"
                    validationSchema={validationSchema}
                    label="Email Address"
                    errors={errors}
                    autoFocus
                    value={values?.email}
                    isDisabled
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="location"
                    type="text"
                    validationSchema={validationSchema}
                    label="Location"
                    placeholder="location"
                    errors={errors}
                    autoFocus
                    value={values?.location}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="website"
                    type="text"
                    validationSchema={validationSchema}
                    label="Website"
                    placeholder="Website"
                    errors={errors}
                    value={values?.website}
                  />
                </div>
              </div>

              <h6 className="contract-title pb-1 my-2"> Social media</h6>

              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="linkedin"
                    type="text"
                    validationSchema={validationSchema}
                    label="Linkedin"
                    placeholder="Linkedin"
                    errors={errors}
                    value={values?.linkedin}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <FormikField
                    name="twitter"
                    type="text"
                    validationSchema={validationSchema}
                    label="Twitter"
                    placeholder="Twitter"
                    errors={errors}
                    value={values?.twitter}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="instagram"
                    type="text"
                    validationSchema={validationSchema}
                    label="Instagram"
                    placeholder="Instagram"
                    errors={errors}
                    value={values?.instagram}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="facebook"
                    type="text"
                    validationSchema={validationSchema}
                    label="Facebook"
                    placeholder="Facebook"
                    errors={errors}
                    value={values?.facebook}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="youtube"
                    type="text"
                    validationSchema={validationSchema}
                    label="Youtube"
                    errors={errors}
                    placeholder="Youtube"
                    value={values?.youtube}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end pt-4">
              <Button
                className="cancel-hallowbtn me-3"
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
              <FormikSubmitButton
                isSubmitting={isSubmitting}
                className="updatebtndanger"
                size="md"
              >
                <Button className="SignText">
                  Update Profile {loader && <span className="loader"></span>}
                </Button>
              </FormikSubmitButton>
            </div>
          </Form>
        </FormikProvider>

        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message="Invalid email or password."
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </SettingsLayout>
    </>
  );
};

export default CompanyPage;
