import React, { ReactNode, useState } from "react";
import { Contract } from "../../../../lib/Contract";
import { IMetaResponse } from "../../../../interfaces/response/IMetaResponse";
import { adminApi } from "../../../../utils/adminApi";
import { IContractResponse } from "../../../../interfaces/response/IContractResponse";
import { ITemplateFilterParams } from "../../../../interfaces/ITemplateFilterParams";

export interface ContractContextInterface {
  recentContracts: Contract[] | null;
  handleGetContract: (id: string, params: any) => void;
  metaData: IMetaResponse;
  setSearch: Function;
  filterParams: any;
  changeFilter: (params: ITemplateFilterParams) => void;
  loading: boolean;
}

const ContractContext = React.createContext<ContractContextInterface | null>(
  null
);

interface ContractProviderProps {
  children: ReactNode;
}

const ContractProvider = ({ children }: ContractProviderProps) => {
  const initialFilter = {
    sort: "-updatedAt",
    limit: 10,
    page: 1,
  };
  const [recentContracts, setRecentContracts]: [Contract[] | null, Function] =
    useState(null);
  const [metaData, setMetaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 10,
    limit: 0,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);
  console.log(search, "searchcontxt");

  const handleGetContract = async (id: string, params: any) => {
    setLoading(true);
    const res = await adminApi.getContracts(id, params);
    setRecentContracts(res?.contracts);
    setMetaData(res.meta);
    const _contracts: Contract[] = res.contracts.map(
      (templateParam: IContractResponse) => new Contract(templateParam)
    );
    setLoading(false);
    return _contracts;
  };

  const value: ContractContextInterface = {
    recentContracts,
    changeFilter: (params: ITemplateFilterParams) => setFilterParams(params),
    handleGetContract,
    metaData,
    setSearch,
    filterParams,
    loading,
  };

  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

const useAdminContext = () => {
  return React.useContext(ContractContext);
};

export { ContractProvider, useAdminContext };
