import { useState } from "react";
import DownloadButton from "../DownloadButton";
import ContractCycleTime from "./ContractCycleTime";
import DaysInNegotiation from "./DaysInNegotiation";
import TurnaroundTimeForApprovals from "./TurnaroundTimeForApprovals";
import TurnaroundTimeForSIgned from "./TurnaroundTimeForSIgned";

function TurnAroundTime({
  isUpdate,
  setIsUpdate,
  filterData,
  updatedValue,
}: {
  isUpdate: boolean;
  setIsUpdate: Function;
  filterData: any;
  updatedValue: any;
}) {
  const [isLoadingData, setIsLoadingData] = useState([
    false,
    false,
    false,
    false,
  ]);
  return (
    <>
      {!isLoadingData?.includes(false) && (
        <DownloadButton title={"Turnaround_time_report"} />
      )}
      <div className="analyticsBody " id="pdf-content" contentEditable={false}>
        <div className=" row">
          <div className=" col-lg-12 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Days in negotiation{" "}
              </h6>
              <DaysInNegotiation
                title={"Days in Negotiation"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Contract Cycle Time
              </h6>
              <ContractCycleTime
                title={"Contract Cycle Time"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Turnaround Time For Approvals
              </h6>
              <TurnaroundTimeForApprovals
                title={"Turnaround Time For Approvals"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-12 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Turnaround Time for Signed Contracts
              </h6>
              <TurnaroundTimeForSIgned
                title={"Turnaround Time for Signed Contracts"}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                filterData={filterData}
                updatedValue={updatedValue}
                setIsLoadingData={setIsLoadingData}
                isLoadingData={isLoadingData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TurnAroundTime;
