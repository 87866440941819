import { isMobileOnly } from "react-device-detect";
import { useState, useEffect } from "react";
import { useAdminAnalytics } from "../AdminAnalyticsProvider";

const AdminOverallStats = ({ filterData }: { filterData: any }) => {
  const context = useAdminAnalytics();
  const [overallStatsData, setOverallStatsData]: [any, Function] = useState();

  const handleOverAllStatsData = async () => {
    try {
      let response = await context?.handleOverallStats(
        filterData ? filterData : ""
      );
      if (response?.success) {
        setOverallStatsData(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleOverAllStatsData();
  }, [filterData]);

  return (
    <>
      {overallStatsData && (
        <>
          <div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center  my-2 flex-wrap">
              <h6 className="    m-0 my-2 "> Signed contracts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["signed_contracts"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2"> Drafts</h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["draft_contracts"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Contracts stamped and signed
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["contract_stamped_signed"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                No. of on-boarded organizations
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["total_organization"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2">
                {" "}
                Active users (Logged in the last 1 year)
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["active_users"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6 className="    m-0 my-2">
                {" "}
                Recently on-boarded organizations (Last 3 months)
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["organization_onboarded_three_months"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Inactive users (Not logged in the last 1 year)
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["incative_users"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Active organizations (Logged in the last 6 months)
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["active_organization"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Inactive organizations (Not logged in the last 6 months)
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["incative_organization"]}
              </h6>
            </div>
            <div className=" border rounded-1 px-4 d-flex justify-content-between align-items-center   my-2 flex-wrap">
              <h6
                className="    m-0 my-2"
                style={isMobileOnly ? { maxWidth: " 142px" } : {}}
              >
                {" "}
                Monthly executed contracts
              </h6>
              <h6
                className=" fw-semibold py-1 px-2 rounded-3 m-0 my-2"
                style={{ background: "#ecebfb", fontSize: "13px" }}
              >
                {overallStatsData["monthly_executed_contract"]}
              </h6>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminOverallStats;
