import CounterParty from "./CounterParty";
import Signer from "./Signer";
import Approver from "./Approver";
import { isMobileOnly } from "react-device-detect";
import { useLocation } from "react-router";
import Estamp from "./Estamp";
import CounterPartyApprover from "./CounterPartyApprover";
import Coordinate from "./Coordinate";

const WorkflowBody = ({
  setBodyMessage,
  setShowSuccessToast,
  setShowFailureToast,
  approvalsLists,
  isActiveOverView,
  approvalsListDetail,
  counterPartyLists,
  setToggleState,
  toggleState,
  approvalLength,
  setApprovalAddClick,
  setSignerClick,
  setCounterpartyClick,
  setAddTemplateApprovers,
  approvalsTempListDetail,
  setApprovalsTempListDetail,
  setApprovalsListDetail,
  setEstampclick,
  setNegotiationWorkFlowClick,
  setIsCounterpartySigner,
  isNegotiationWOrkflowOwner,
  setNegotiationClick,
  setShowFailureToastValue,
  setBodyMessageText,
  setCoordinateRequired,
  negotiationViewerLength,
}: {
  setBodyMessage: Function;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  approvalsLists: Function;
  isActiveOverView: string;
  approvalsListDetail: [];
  counterPartyLists: [];
  setToggleState: Function;
  toggleState?: string;
  approvalLength?: string;
  setApprovalAddClick: Function;
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setAddTemplateApprovers: Function;
  approvalsTempListDetail: [];
  setApprovalsTempListDetail: Function;
  setApprovalsListDetail: Function;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
  setIsCounterpartySigner: Function;
  isNegotiationWOrkflowOwner?: boolean;
  setNegotiationClick: Function;
  setShowFailureToastValue: Function;
  setBodyMessageText: Function;
  setCoordinateRequired: Function;
  negotiationViewerLength?: any;
}) => {
  const location = useLocation();
  const toggleTab = (value: string) => {
    setToggleState(value);
  };

  return (
    <>
      <div>
        <div className=" d-flex justify-content-center ">
          <div className="  linemiddle"></div>
        </div>
      </div>

      {isNegotiationWOrkflowOwner ? (
        <>
          {/* -------------------------------line up to plusIcon-------------------------- */}
          <div className=" Approver_box_new row">
            <div className=" px-3 py-3">
              <h6
                className={
                  toggleState === "negotiation-approval"
                    ? " Approveractive-content m-0 fw-semibold"
                    : "content"
                }
              >
                Negotiate Approver
              </h6>

              <h6 className="m-0 fw-semibold sub_appover_para text-black-50"></h6>
            </div>
          </div>
          {/* ----------------------------bodyheader---------------------- */}
          <div
            className=" Appover_down_box row"
            style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
          >
            <div className="col-12">
              <div className={isMobileOnly ? " row mt-2" : "d-none"}>
                <div className="col-4 p-0">
                  <div
                    className={
                      toggleState === "negotiation-approval"
                        ? " workflow_btn bgpink text-center py-2"
                        : " workflow_btn text-center py-2 border-start-0 border-end-0"
                    }
                    onClick={() => {
                      toggleTab("negotiation-approval");
                    }}
                  >
                    Negotiate Approver
                  </div>
                </div>
                {approvalLength === "greater" &&
                !location.pathname.includes("templates") ? (
                  ""
                ) : (
                  <></>
                )}
              </div>
              {/* ----------------------------------------for destop------------------------ */}
              <div className="row">
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-md-9  p-0"
                      : "col-12 col-md-9 border_right p-0"
                  }
                >
                  <div
                    className={
                      toggleState === "negotiation-approval"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Approver
                      setBodyMessageText={setBodyMessageText}
                      setNegotiationClick={setNegotiationClick}
                      isNegotiationWOrkflowOwner={isNegotiationWOrkflowOwner}
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setApprovalAddClick={setApprovalAddClick}
                      isActiveOverView={isActiveOverView}
                      setBodyMessage={setBodyMessage}
                      setShowSuccessToast={setShowSuccessToast}
                      setShowFailureToast={setShowFailureToast}
                      approvalsLists={approvalsLists}
                      approvalsListDetail={approvalsListDetail}
                      setCounterpartyClick={setCounterpartyClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                      approvalsTempListDetail={approvalsTempListDetail}
                      setApprovalsTempListDetail={setApprovalsTempListDetail}
                      setApprovalsListDetail={setApprovalsListDetail}
                      setShowFailureToastValue={setShowFailureToastValue}
                      negotiationViewerLength={negotiationViewerLength}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "counterpartyApprovers"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <CounterPartyApprover
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setApprovalAddClick={setApprovalAddClick}
                      approvalsLists={approvalsLists}
                      counterPartyLists={counterPartyLists}
                      setBodyMessage={setBodyMessage}
                      setShowFailureToast={setShowFailureToast}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "estamp"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Estamp
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setApprovalAddClick={setApprovalAddClick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                    />
                  </div>

                  <div
                    className={
                      toggleState === "signer"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Signer
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setApprovalAddClick={setApprovalAddClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                      setCounterpartyClick={setCounterpartyClick}
                      setSignerClick={setSignerClick}
                      setShowFailureToast={setShowFailureToast}
                      setBodyMessage={setBodyMessage}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "counterparty"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <CounterParty
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setApprovalAddClick={setApprovalAddClick}
                      approvalsLists={approvalsLists}
                      counterPartyLists={counterPartyLists}
                      setBodyMessage={setBodyMessage}
                      setShowFailureToast={setShowFailureToast}
                      setIsCounterpartySigner={setIsCounterpartySigner}
                    />
                  </div>
                </div>
                <div className=" col-12 col-md-3 p-0 h-100">
                  <div
                    className={
                      isMobileOnly
                        ? " d-none"
                        : "p-3 d-flex flex-column justify-content-between"
                    }
                  >
                    <div
                      className={
                        toggleState === "negotiation-approval"
                          ? "px-2 py-3 workflow_btn bgpink mb-3 "
                          : "px-2 py-3 workflow_btn mb-3"
                      }
                      onClick={() => {
                        toggleTab("negotiation-approval");
                      }}
                    >
                      Negotiate Approver
                    </div>
                    {approvalLength === "greater" ||
                    location.pathname.includes("templates") ? (
                      ""
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* -------------------------------line up to plusIcon-------------------------- */}

          {/* ----------------------------bodyheader---------------------- */}
          <div
            className=" Appover_down_box row"
            style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
          >
            <div className="col-12">
              <div className={isMobileOnly ? " row mt-2" : "d-none"}>
                <div className="col-4 p-0">
                  <div
                    className={
                      toggleState === "approvers"
                        ? " workflow_btn bgpink text-center py-2"
                        : " workflow_btn text-center py-2 border-start-0 border-end-0"
                    }
                    onClick={() => {
                      toggleTab("approvers");
                    }}
                  >
                    Reviewer <span style={{ color: "red" }}>{"*"}</span>
                  </div>
                </div>
                {approvalLength === "greater" &&
                !location.pathname.includes("templates") ? (
                  ""
                ) : (
                  <>
                    <div className="col-4 p-0">
                      <div
                        className={
                          toggleState === "counterpartyApprovers"
                            ? " workflow_btn bgpink  text-center py-2"
                            : " workflow_btn text-center py-2"
                        }
                        onClick={() => {
                          toggleTab("counterpartyApprovers");
                        }}
                      >
                        Negotiate
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div
                        className={
                          toggleState === "estamp"
                            ? " workflow_btn bgpink  text-center py-2"
                            : " workflow_btn text-center py-2"
                        }
                        onClick={() => {
                          toggleTab("estamp");
                        }}
                      >
                        E-Stamp
                      </div>
                    </div>

                    <div className="col-4 p-0">
                      <div
                        className={
                          toggleState === "signer"
                            ? " workflow_btn bgpink  text-center py-2"
                            : " workflow_btn text-center py-2"
                        }
                        onClick={() => {
                          toggleTab("signer");
                        }}
                      >
                        Signatory <span style={{ color: "red" }}>{"*"}</span>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div
                        className={
                          toggleState === "counterparty"
                            ? " workflow_btn bgpink  text-center py-2 "
                            : " workflow_btn text-center py-2 border-start-0 border-end-0"
                        }
                        onClick={() => {
                          toggleTab("counterparty");
                        }}
                      >
                        Counterparty Signatory{" "}
                        <span style={{ color: "red" }}>{"*"}</span>
                      </div>
                    </div>

                    {/* ..........................coordinate............................ */}
                    <div className="col-4 p-0">
                      <div
                        className={
                          toggleState === "coordinate"
                            ? " workflow_btn bgpink  text-center py-2 "
                            : " workflow_btn text-center py-2 border-start-0 border-end-0"
                        }
                        onClick={() => {
                          toggleTab("coordinate");
                        }}
                      >
                        <span style={{ color: "red" }}>Add Coordinate</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* ----------------------------------------for destop------------------------ */}
              <div className="row">
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-md-9  p-0"
                      : "col-12 col-md-9 border_right p-0"
                  }
                >
                  <div
                    className={
                      toggleState === "approvers"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Approver
                      setBodyMessageText={setBodyMessageText}
                      setNegotiationClick={setNegotiationClick}
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setApprovalAddClick={setApprovalAddClick}
                      isActiveOverView={isActiveOverView}
                      setBodyMessage={setBodyMessage}
                      setShowSuccessToast={setShowSuccessToast}
                      setShowFailureToast={setShowFailureToast}
                      approvalsLists={approvalsLists}
                      approvalsListDetail={approvalsListDetail}
                      setCounterpartyClick={setCounterpartyClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                      approvalsTempListDetail={approvalsTempListDetail}
                      setApprovalsTempListDetail={setApprovalsTempListDetail}
                      setApprovalsListDetail={setApprovalsListDetail}
                      setShowFailureToastValue={setShowFailureToastValue}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "counterpartyApprovers"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <CounterPartyApprover
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setApprovalAddClick={setApprovalAddClick}
                      approvalsLists={approvalsLists}
                      counterPartyLists={counterPartyLists}
                      setBodyMessage={setBodyMessage}
                      setShowFailureToast={setShowFailureToast}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "estamp"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Estamp
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setApprovalAddClick={setApprovalAddClick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                    />
                  </div>

                  <div
                    className={
                      toggleState === "signer"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Signer
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setApprovalAddClick={setApprovalAddClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                      setCounterpartyClick={setCounterpartyClick}
                      setSignerClick={setSignerClick}
                      setShowFailureToast={setShowFailureToast}
                      setBodyMessage={setBodyMessage}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "counterparty"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <CounterParty
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setApprovalAddClick={setApprovalAddClick}
                      approvalsLists={approvalsLists}
                      counterPartyLists={counterPartyLists}
                      setBodyMessage={setBodyMessage}
                      setShowFailureToast={setShowFailureToast}
                      setIsCounterpartySigner={setIsCounterpartySigner}
                    />
                  </div>
                  <div
                    className={
                      toggleState === "coordinate"
                        ? " Approveractive-content "
                        : "content"
                    }
                  >
                    <Coordinate
                      setCoordinateRequired={setCoordinateRequired}
                      setApprovalAddClick={setApprovalAddClick}
                      setSignerClick={setSignerClick}
                      setCounterpartyClick={setCounterpartyClick}
                      setAddTemplateApprovers={setAddTemplateApprovers}
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                    />
                  </div>
                </div>
                <div className=" col-12 col-md-3 p-0 h-100">
                  <div
                    className={
                      isMobileOnly
                        ? " d-none"
                        : "p-3 d-flex flex-column justify-content-between"
                    }
                  >
                    <div
                      className={
                        toggleState === "approvers"
                          ? "px-2 py-3 workflow_btn bgpink mb-3 "
                          : "px-2 py-3 workflow_btn mb-3"
                      }
                      onClick={() => {
                        toggleTab("approvers");
                      }}
                    >
                      Reviewer <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    {approvalLength === "greater" ||
                    location.pathname.includes("templates") ? (
                      ""
                    ) : (
                      <>
                        <div
                          className={
                            toggleState === "counterpartyApprovers"
                              ? "px-2 py-3 workflow_btn bgpink mb-3 "
                              : "px-2 py-3 workflow_btn mb-3"
                          }
                          onClick={() => {
                            toggleTab("counterpartyApprovers");
                          }}
                        >
                          Negotiate
                        </div>
                        <div
                          className={
                            toggleState === "estamp"
                              ? "px-2 py-3 workflow_btn bgpink mb-3 "
                              : "px-2 py-3 workflow_btn mb-3"
                          }
                          onClick={() => {
                            toggleTab("estamp");
                          }}
                        >
                          E-Stamp
                        </div>

                        <div
                          className={
                            toggleState === "signer"
                              ? "px-2 py-3 workflow_btn bgpink mb-3 "
                              : "px-2 py-3 workflow_btn mb-3"
                          }
                          onClick={() => {
                            toggleTab("signer");
                          }}
                        >
                          Signatory <span style={{ color: "red" }}>{"*"}</span>
                        </div>

                        <div
                          className={
                            toggleState === "counterparty"
                              ? "px-2 py-3 workflow_btn bgpink  mb-3"
                              : "px-2 py-3 workflow_btn mb-3"
                          }
                          onClick={() => {
                            toggleTab("counterparty");
                          }}
                        >
                          Counterparty Signatory{" "}
                          <span style={{ color: "red" }}>{"*"}</span>
                        </div>

                        <div
                          className={
                            toggleState === "coordinate"
                              ? "px-2 py-3 workflow_btn bgpink  mb-3"
                              : "px-2 py-3 workflow_btn mb-3"
                          }
                          onClick={() => {
                            toggleTab("coordinate");
                          }}
                        >
                          E-signature
                          <span style={{ color: "red" }}>{"*"}</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkflowBody;
