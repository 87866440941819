import { Dropdown } from "react-bootstrap";
import { BiDotsHorizontal } from "react-icons/bi";
import { IoIosAddCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useApprovals } from "./WorkflowProvider";
import { IApprovalResponse } from "../../interfaces/response/IApprovalResponse";
import { Fragment, useEffect, useRef, useState } from "react";
import WorkflowBody from "./WorkflowBody";
import { useParams } from "react-router";
import { MdCancel, MdDragIndicator, MdError, MdUpdate } from "react-icons/md";
import DeleteToast from "../../ui/DeleteToast";
import FailureToast from "../../ui/FailureToast";
import { useTemplate } from "../Templates/TemplateProvider";
import {
  ContractContextInterface,
  useContract,
} from "../Contracts/ContractProvider";
import { api } from "../../utils/api";
import { StatusEnum } from "../../interfaces/StatusEnum";
import { useAuthentication } from "../Authentication/AuthenticationProvider";

const WorkflowLists = ({
  toggleState,
  setShowSuccessToast,
  approvalsLists,
  isActiveOverView,
  approvalsListDetail,
  counterPartyLists,
  setToggleState,
  setApprovalAddClick,
  setCounterpartyClick,
  counterpartyClick,
  setSignerClick,
  signerClick,
  setIsWorkflowListAdded,
  setApprovalsListDetail,
  approvalsTempListDetail,
  setApprovalsTempListDetail,
  contractContext,
  estampClick,
  setEstampclick,
  setNegotiationWorkFlowClick,
  negotiationWorkFlowClick,
  isNegotiationWOrkflowOwner,
  setNegotiationClick,
  negotiationClick,
  negotiationApproval,
  setShowFailureToastValue,
  setBodyMessageText,
  setNegotiationApprovalAdd,
  negotiationApprovalAdd,
  setCoordinateRequired,
}: {
  toggleState: string;
  setShowSuccessToast: Function;
  approvalsLists: Function;
  isActiveOverView: string;
  approvalsListDetail: [];
  counterPartyLists: [];
  setToggleState: Function;
  setApprovalAddClick: Function;
  setCounterpartyClick: Function;
  counterpartyClick: boolean;
  setSignerClick: Function;
  signerClick: boolean;
  setIsWorkflowListAdded: Function;
  setApprovalsListDetail: Function;
  approvalsTempListDetail: [];
  setApprovalsTempListDetail: Function;
  contractContext: any;
  estampClick: boolean;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
  negotiationWorkFlowClick: boolean;
  isNegotiationWOrkflowOwner: boolean;
  setNegotiationClick: Function;
  negotiationClick: boolean;
  negotiationApproval: boolean;
  setShowFailureToastValue: Function;
  setBodyMessageText: Function;
  setNegotiationApprovalAdd: Function;
  negotiationApprovalAdd: boolean;
  setCoordinateRequired: Function;
}) => {
  const context = useApprovals();
  const templateContext = useTemplate();
  const authContext = useAuthentication();

  const [addTemplateApprovers, setAddTemplateApprovers]: [boolean, Function] =
    useState(false);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [showEstampDelete, setshowEstampDelete]: [boolean, Function] =
    useState(false);
  const [deleteToastClick, setDeleteToastClick]: [boolean, Function] =
    useState(false);
  const [deleteToastTitle, setDeleteToastTitle]: [string, Function] =
    useState("");
  console.log(deleteToastTitle);
  const [negotiationViewerLength, setNegotiationViewerLength] =
    useState<number>(0);

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [isshowFailureToast, setisShowFailureToast]: [boolean, Function] =
    useState(false);

  const onAddWorkflow = (type: string) => {
    if (type?.length) {
      setToggleState(type);
    }
  };

  const [roleAccess, setRoleAccess]: [boolean, Function] = useState(false);

  useEffect(() => {
    let owner: any = context?.contractDetail?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );

    if (owner?.user?.email === authContext?.currentUser?.email) {
      setRoleAccess(true);
    }
  }, [context?.contractDetail?.collaborators]);

  useEffect(() => {
    if (context?.contractDetail?.external_users) {
      setNegotiationViewerLength(
        context?.contractDetail?.external_users?.filter(
          (el: any) => el?.access === "VIEWER"
        )?.length
      );
    }
  }, [context?.contractDetail?.external_users]);

  const [ngViewwer, setNgViewer]: [boolean, Function] = useState(false);
  useEffect(() => {
    let ngViewer: any = context?.contractDetail?.counterparty_owner?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );

    if (
      ngViewer?.negotation_viewers?.length > 0 ||
      context?.contractDetail?.external_users?.find(
        (el: any) =>
          el?.access === "VIEWER" &&
          el?.email === authContext?.currentUser?.email
      )
    ) {
      setNgViewer(true);
    }
  }, [
    context?.contractDetail?.counterparty_owner,
    context?.contractDetail?.external_users,
  ]);

  return (
    <>
      {isNegotiationWOrkflowOwner || negotiationApproval ? (
        <>
          {isActiveOverView !== "templates" && (
            <>
              {ngViewwer &&
                context?.contractDetail?.external_users?.filter(
                  (el: any) => el?.access === "VIEWER"
                )?.length !== 0 && (
                  <>
                    <>
                      <div className=" d-flex justify-content-center ">
                        <div className="  linemiddle"></div>
                      </div>
                      <div className=" output_box row">
                        {isActiveOverView !== "templates" && (
                          <>
                            <NegotiationApprovalLists />
                          </>
                        )}
                      </div>

                      {!negotiationApproval &&
                        contractContext?.data?.counterparty_owner?.find(
                          (el: any) =>
                            el?.email === authContext?.currentUser?.email
                        )?.status !== "REJECTED" &&
                        isActiveOverView !== "templates" && (
                          <>
                            {
                              <>
                                {contractContext?.data?.negotiation_owners[
                                  contractContext?.data?.counterparty_owner?.find(
                                    (el: any) =>
                                      el?.email ===
                                      authContext?.currentUser?.email
                                  )?.id
                                ]?.negotiation_status !== "COMPLETED" &&
                                  contractContext?.data?.negotiation_owners[
                                    contractContext?.data?.counterparty_owner?.find(
                                      (el: any) =>
                                        el?.email ===
                                        authContext?.currentUser?.email
                                    )?.id
                                  ]?.negotiation_status !== "STARTED" && (
                                    <>
                                      <div className="last_child_disable">
                                        <div className=" d-flex justify-content-center ">
                                          <div className="  linemiddle"></div>
                                        </div>
                                        <>
                                          <div className=" d-flex justify-content-center ">
                                            <div className="fordesign">
                                              <IoIosAddCircleOutline
                                                fontSize={30}
                                                color="#c5d0de"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setApprovalAddClick(false);
                                                  setCounterpartyClick(
                                                    !counterpartyClick
                                                  );
                                                  onAddWorkflow(
                                                    "negotiation-approval"
                                                  );
                                                  setNegotiationApprovalAdd(
                                                    !negotiationApprovalAdd
                                                  );
                                                  setNegotiationClick(
                                                    !negotiationClick
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                        {/* )} */}
                                      </div>
                                    </>
                                  )}
                              </>
                            }
                          </>
                        )}

                      {negotiationClick && (
                        <WorkflowBody
                          setCoordinateRequired={setCoordinateRequired}
                          setBodyMessageText={setBodyMessageText}
                          setShowFailureToastValue={setShowFailureToastValue}
                          isNegotiationWOrkflowOwner={
                            isNegotiationWOrkflowOwner
                          }
                          setNegotiationClick={setNegotiationClick}
                          setIsCounterpartySigner={() => {}}
                          setNegotiationWorkFlowClick={
                            setNegotiationWorkFlowClick
                          }
                          setEstampclick={setEstampclick}
                          setAddTemplateApprovers={() => {}}
                          setCounterpartyClick={setCounterpartyClick}
                          setSignerClick={() => {}}
                          setApprovalAddClick={setApprovalAddClick}
                          toggleState={toggleState}
                          setToggleState={setToggleState}
                          setBodyMessage={setBodyMessage}
                          setShowSuccessToast={setShowSuccessToast}
                          setShowFailureToast={setShowFailureToast}
                          approvalsLists={approvalsLists}
                          isActiveOverView={isActiveOverView}
                          approvalsListDetail={approvalsListDetail}
                          counterPartyLists={counterPartyLists}
                          setApprovalsTempListDetail={
                            setApprovalsTempListDetail
                          }
                          approvalsTempListDetail={approvalsTempListDetail}
                          setApprovalsListDetail={setApprovalsListDetail}
                          negotiationViewerLength={negotiationViewerLength}
                        />
                      )}
                    </>
                  </>
                )}
            </>
          )}
        </>
      ) : (
        <>
          {/* .....................temlplate approvals list................... */}
          {isActiveOverView === "templates" && (
            <>
              {context?.templateDetail?.approvers?.length !== 0 && (
                <>
                  <div className=" d-flex justify-content-center ">
                    <div className="  linemiddle"></div>
                  </div>

                  <div className=" output_box row">
                    <ApprovalsTemplateLists
                      approvalsListDetail={approvalsListDetail}
                      approvalsLists={approvalsLists}
                      setIsWorkflowListAdded={setIsWorkflowListAdded}
                      setApprovalsTempListDetail={setApprovalsTempListDetail}
                      setShowDeleteToast={setShowDeleteToast}
                      deleteToastClick={deleteToastClick}
                      setDeleteToastTitle={setDeleteToastTitle}
                      setDeleteToastClick={setDeleteToastClick}
                    />
                  </div>

                  {templateContext?.template?.workflow_status !==
                    StatusEnum?.published && (
                    <>
                      <div className="last_child_disable">
                        <div className=" d-flex justify-content-center ">
                          <div className="  linemiddle"></div>
                        </div>
                        <div className=" d-flex justify-content-center ">
                          <div className="fordesign">
                            <IoIosAddCircleOutline
                              fontSize={30}
                              color="#c5d0de"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setAddTemplateApprovers(!addTemplateApprovers);
                                setToggleState("approvers");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {addTemplateApprovers && (
            <WorkflowBody
              setCoordinateRequired={setCoordinateRequired}
              setBodyMessageText={setBodyMessageText}
              setShowFailureToastValue={setShowFailureToastValue}
              setNegotiationClick={() => {}}
              setIsCounterpartySigner={() => {}}
              setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
              setEstampclick={setEstampclick}
              setAddTemplateApprovers={setAddTemplateApprovers}
              setCounterpartyClick={() => {}}
              setSignerClick={setSignerClick}
              setApprovalAddClick={setApprovalAddClick}
              toggleState={toggleState}
              setToggleState={setToggleState}
              setBodyMessage={setBodyMessage}
              setShowSuccessToast={setShowSuccessToast}
              setShowFailureToast={setShowFailureToast}
              approvalsLists={approvalsLists}
              isActiveOverView={isActiveOverView}
              approvalsListDetail={approvalsListDetail}
              counterPartyLists={counterPartyLists}
              setApprovalsTempListDetail={setApprovalsTempListDetail}
              approvalsTempListDetail={approvalsTempListDetail}
              setApprovalsListDetail={setApprovalsListDetail}
            />
          )}

          {/* ...............contracts approvals list.................... */}
          {isActiveOverView !== "templates" &&
            context?.contractDetail?.approvers?.length !== 0 && (
              <>
                <div className=" d-flex justify-content-center ">
                  <div className="  linemiddle"></div>
                </div>
                <div className=" output_box row">
                  <ApprovalsListDetail
                    approvalsLists={approvalsLists}
                    setIsWorkflowListAdded={setIsWorkflowListAdded}
                    setShowDeleteToast={setShowDeleteToast}
                    deleteToastClick={deleteToastClick}
                    setDeleteToastTitle={setDeleteToastTitle}
                    setDeleteToastClick={setDeleteToastClick}
                  />
                </div>
                {context?.contractDetail?.collaborators?.find(
                  (el: any) => el?.access === "OWNER"
                )?.user?.email === authContext?.currentUser?.email && (
                  <>
                    {" "}
                    {(context?.contractDetail?.status === "PENDING" ||
                      context?.contractDetail?.status ===
                        StatusEnum?.published) && (
                      <>
                        <div className="last_child_disable">
                          <div className=" d-flex justify-content-center ">
                            <div className="  linemiddle"></div>
                          </div>
                          {/* {roleAccess && ( */}
                          <>
                            <div className=" d-flex justify-content-center ">
                              <div className="fordesign">
                                <IoIosAddCircleOutline
                                  fontSize={30}
                                  color="#c5d0de"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSignerClick(!signerClick);
                                    setApprovalAddClick(false);
                                    setCounterpartyClick(false);
                                    onAddWorkflow("approvers");
                                  }}
                                />
                              </div>
                            </div>
                          </>
                          {/* )} */}
                        </div>
                      </>
                    )}
                  </>
                )}

                {toggleState.length > 0 && signerClick && (
                  <WorkflowBody
                    setCoordinateRequired={setCoordinateRequired}
                    setBodyMessageText={setBodyMessageText}
                    setShowFailureToastValue={setShowFailureToastValue}
                    setNegotiationClick={() => {}}
                    setIsCounterpartySigner={() => {}}
                    setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                    setEstampclick={setEstampclick}
                    setAddTemplateApprovers={() => {}}
                    setCounterpartyClick={() => {}}
                    setSignerClick={setSignerClick}
                    setApprovalAddClick={setApprovalAddClick}
                    toggleState={toggleState}
                    setToggleState={setToggleState}
                    setBodyMessage={setBodyMessage}
                    setShowSuccessToast={setShowSuccessToast}
                    setShowFailureToast={setShowFailureToast}
                    approvalsLists={approvalsLists}
                    isActiveOverView={isActiveOverView}
                    approvalsListDetail={approvalsListDetail}
                    counterPartyLists={counterPartyLists}
                    setApprovalsTempListDetail={setApprovalsTempListDetail}
                    approvalsTempListDetail={approvalsTempListDetail}
                    setApprovalsListDetail={setApprovalsListDetail}
                  />
                )}
              </>
            )}

          {/* ............................contracts signer and counterparty list.................. */}

          {/* ....................counterparty workflow......................... */}
          {isActiveOverView !== "templates" && (
            <>
              {context?.contractDetail?.counterparty_owner.length !== 0 && (
                <>
                  <div className=" d-flex justify-content-center ">
                    <div className="  linemiddle"></div>
                  </div>
                  <div className=" output_box row">
                    {isActiveOverView !== "templates" && (
                      <>
                        <NegotiationWorkflow
                          approvalsLists={approvalsLists}
                          setIsWorkflowListAdded={setIsWorkflowListAdded}
                          setShowDeleteToast={setShowDeleteToast}
                          deleteToastClick={deleteToastClick}
                          setDeleteToastTitle={setDeleteToastTitle}
                          setDeleteToastClick={setDeleteToastClick}
                        />
                      </>
                    )}
                  </div>
                  {isActiveOverView !== "templates" && (
                    <>
                      {context?.contractDetail?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.email === authContext?.currentUser?.email && (
                        <>
                          {" "}
                          {(context?.contractDetail?.status === "PENDING" ||
                            context?.contractDetail?.status ===
                              StatusEnum?.published) && (
                            <>
                              <div className="last_child_disable">
                                <div className=" d-flex justify-content-center ">
                                  <div className="  linemiddle"></div>
                                </div>
                                {/* {roleAccess && ( */}
                                <>
                                  <div className=" d-flex justify-content-center ">
                                    <div className="fordesign">
                                      <IoIosAddCircleOutline
                                        fontSize={30}
                                        color="#c5d0de"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setSignerClick(false);
                                          setApprovalAddClick(false);
                                          setNegotiationWorkFlowClick(
                                            !negotiationWorkFlowClick
                                          );
                                          onAddWorkflow("counterparty");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                                {/* )} */}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {toggleState.length > 0 && negotiationWorkFlowClick && (
                    <WorkflowBody
                      setCoordinateRequired={setCoordinateRequired}
                      setBodyMessageText={setBodyMessageText}
                      setShowFailureToastValue={setShowFailureToastValue}
                      setNegotiationClick={() => {}}
                      setIsCounterpartySigner={() => {}}
                      setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                      setEstampclick={setEstampclick}
                      setAddTemplateApprovers={() => {}}
                      setCounterpartyClick={setCounterpartyClick}
                      setSignerClick={() => {}}
                      setApprovalAddClick={setApprovalAddClick}
                      toggleState={toggleState}
                      setToggleState={setToggleState}
                      setBodyMessage={setBodyMessage}
                      setShowSuccessToast={setShowSuccessToast}
                      setShowFailureToast={setShowFailureToast}
                      approvalsLists={approvalsLists}
                      isActiveOverView={isActiveOverView}
                      approvalsListDetail={approvalsListDetail}
                      counterPartyLists={counterPartyLists}
                      setApprovalsTempListDetail={setApprovalsTempListDetail}
                      approvalsTempListDetail={approvalsTempListDetail}
                      setApprovalsListDetail={setApprovalsListDetail}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/* ....................Estamp........................ */}
          {isActiveOverView !== "templates" &&
            contractContext?.data?.stamp_required && (
              <>
                <div className=" d-flex justify-content-center ">
                  <div className="  linemiddle"></div>
                </div>
                <div className=" output_box row">
                  <>
                    <EstampList
                      contractContext={contractContext}
                      setShowDeleteToast={setShowDeleteToast}
                      setshowEstampDelete={setshowEstampDelete}
                      isshowFailureToast={isshowFailureToast}
                      setisShowFailureToast={setisShowFailureToast}
                    />
                  </>
                </div>
                {isActiveOverView !== "templates" && (
                  <>
                    {(context?.contractDetail?.status === "PENDING" ||
                      context?.contractDetail?.status ===
                        StatusEnum?.published) && (
                      <>
                        <div className="last_child_disable">
                          <div className=" d-flex justify-content-center ">
                            <div className="  linemiddle"></div>
                          </div>
                          {roleAccess && (
                            <>
                              <div className=" d-flex justify-content-center ">
                                <div className="fordesign">
                                  <IoIosAddCircleOutline
                                    fontSize={30}
                                    color="#c5d0de"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEstampclick(!estampClick);
                                      setApprovalAddClick(false);
                                      setCounterpartyClick(false);
                                      onAddWorkflow("approvers");
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}

                {toggleState.length > 0 && estampClick && (
                  <WorkflowBody
                    setCoordinateRequired={setCoordinateRequired}
                    setBodyMessageText={setBodyMessageText}
                    setShowFailureToastValue={setShowFailureToastValue}
                    setNegotiationClick={() => {}}
                    setIsCounterpartySigner={() => {}}
                    setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                    setEstampclick={setEstampclick}
                    setAddTemplateApprovers={() => {}}
                    setCounterpartyClick={setCounterpartyClick}
                    setSignerClick={() => {}}
                    setApprovalAddClick={setApprovalAddClick}
                    toggleState={toggleState}
                    setToggleState={setToggleState}
                    setBodyMessage={setBodyMessage}
                    setShowSuccessToast={setShowSuccessToast}
                    setShowFailureToast={setShowFailureToast}
                    approvalsLists={approvalsLists}
                    isActiveOverView={isActiveOverView}
                    approvalsListDetail={approvalsListDetail}
                    counterPartyLists={counterPartyLists}
                    setApprovalsTempListDetail={setApprovalsTempListDetail}
                    approvalsTempListDetail={approvalsTempListDetail}
                    setApprovalsListDetail={setApprovalsListDetail}
                  />
                )}
              </>
            )}

          {isActiveOverView !== "templates" && (
            <>
              {((context?.contractDetail?.external_users?.find(
                (el: any) => el?.access === "SIGNER"
              ) &&
                context?.contractDetail?.external_users.length !== 0) ||
                context?.contractDetail?.internal_signer.length !== 0) && (
                <>
                  <>
                    <div className=" d-flex justify-content-center ">
                      <div className="  linemiddle"></div>
                    </div>
                    <div className=" output_box row">
                      {isActiveOverView !== "templates" && (
                        <>
                          <SignerAndCounterpartyDetails
                            approvalsLists={approvalsLists}
                            setIsWorkflowListAdded={setIsWorkflowListAdded}
                            setShowDeleteToast={setShowDeleteToast}
                            deleteToastClick={deleteToastClick}
                            setDeleteToastTitle={setDeleteToastTitle}
                            setDeleteToastClick={setDeleteToastClick}
                          />
                        </>
                      )}
                    </div>

                    {isActiveOverView !== "templates" && (
                      <>
                        {(context?.contractDetail?.status === "PENDING" ||
                          context?.contractDetail?.status ===
                            StatusEnum?.published) && (
                          <>
                            <div className="last_child_disable">
                              <div className=" d-flex justify-content-center ">
                                <div className="  linemiddle"></div>
                              </div>
                              {roleAccess && (
                                <>
                                  <div className=" d-flex justify-content-center ">
                                    <div className="fordesign">
                                      <IoIosAddCircleOutline
                                        fontSize={30}
                                        color="#c5d0de"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setSignerClick(false);
                                          setApprovalAddClick(false);
                                          setCounterpartyClick(
                                            !counterpartyClick
                                          );
                                          onAddWorkflow("counterparty");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {toggleState.length > 0 && counterpartyClick && (
                      <WorkflowBody
                        setCoordinateRequired={setCoordinateRequired}
                        setBodyMessageText={setBodyMessageText}
                        setShowFailureToastValue={setShowFailureToastValue}
                        setNegotiationClick={() => {}}
                        setIsCounterpartySigner={() => {}}
                        setNegotiationWorkFlowClick={
                          setNegotiationWorkFlowClick
                        }
                        setEstampclick={setEstampclick}
                        setAddTemplateApprovers={() => {}}
                        setCounterpartyClick={setCounterpartyClick}
                        setSignerClick={() => {}}
                        setApprovalAddClick={setApprovalAddClick}
                        toggleState={toggleState}
                        setToggleState={setToggleState}
                        setBodyMessage={setBodyMessage}
                        setShowSuccessToast={setShowSuccessToast}
                        setShowFailureToast={setShowFailureToast}
                        approvalsLists={approvalsLists}
                        isActiveOverView={isActiveOverView}
                        approvalsListDetail={approvalsListDetail}
                        counterPartyLists={counterPartyLists}
                        setApprovalsTempListDetail={setApprovalsTempListDetail}
                        approvalsTempListDetail={approvalsTempListDetail}
                        setApprovalsListDetail={setApprovalsListDetail}
                      />
                    )}
                  </>
                </>
              )}
            </>
          )}

          {/* ..........................coorninates */}
          {isActiveOverView !== "templates" &&
            contractContext?.data?.sign_type && (
              <>
                <div className=" d-flex justify-content-center ">
                  <div className="  linemiddle"></div>
                </div>
                <div className=" output_box row">
                  <>
                    <Coordinate
                      contractContext={contractContext}
                      setShowDeleteToast={setShowDeleteToast}
                      setshowEstampDelete={setshowEstampDelete}
                      isshowFailureToast={isshowFailureToast}
                      setisShowFailureToast={setisShowFailureToast}
                    />
                  </>
                </div>
                {isActiveOverView !== "templates" && (
                  <>
                    {(context?.contractDetail?.status === "PENDING" ||
                      context?.contractDetail?.status ===
                        StatusEnum?.published) && (
                      <>
                        <div className="last_child_disable">
                          {roleAccess && (
                            <>
                              <div className=" d-flex justify-content-center ">
                                <div className="  linemiddle"></div>
                              </div>
                              <div className=" d-flex justify-content-center ">
                                <div className="fordesign">
                                  <IoIosAddCircleOutline
                                    fontSize={30}
                                    color="#c5d0de"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEstampclick(!estampClick);
                                      setApprovalAddClick(false);
                                      setCounterpartyClick(false);
                                      onAddWorkflow("approvers");
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}

                {toggleState.length > 0 && estampClick && (
                  <WorkflowBody
                    setCoordinateRequired={setCoordinateRequired}
                    setBodyMessageText={setBodyMessageText}
                    setShowFailureToastValue={setShowFailureToastValue}
                    setNegotiationClick={() => {}}
                    setIsCounterpartySigner={() => {}}
                    setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                    setEstampclick={setEstampclick}
                    setAddTemplateApprovers={() => {}}
                    setCounterpartyClick={setCounterpartyClick}
                    setSignerClick={() => {}}
                    setApprovalAddClick={setApprovalAddClick}
                    toggleState={toggleState}
                    setToggleState={setToggleState}
                    setBodyMessage={setBodyMessage}
                    setShowSuccessToast={setShowSuccessToast}
                    setShowFailureToast={setShowFailureToast}
                    approvalsLists={approvalsLists}
                    isActiveOverView={isActiveOverView}
                    approvalsListDetail={approvalsListDetail}
                    counterPartyLists={counterPartyLists}
                    setApprovalsTempListDetail={setApprovalsTempListDetail}
                    approvalsTempListDetail={approvalsTempListDetail}
                    setApprovalsListDetail={setApprovalsListDetail}
                  />
                )}
              </>
            )}

          {/* ..........delete toast.... */}
          {showEstampDelete && (
            <DeleteToast
              onCross={() => {}}
              onSubmit={() => {
                setisShowFailureToast(true);
              }}
              onClose={() => setshowEstampDelete(false)}
              title={`Do you want to delete this?`}
              closeText="No"
              submitText="Yes"
            />
          )}

          {showDeleteToast && (
            <DeleteToast
              onCross={() => {}}
              onSubmit={() => {
                setDeleteToastClick(true);
              }}
              onClose={() => setShowDeleteToast(false)}
              title={`You are about to delete a user from the automated workflow. Are you sure you want to proceed?`}
              closeText="No"
              submitText="Yes"
            />
          )}

          {/* ...........failure toast......... */}
          {showFailureToast && (
            <FailureToast
              icon={
                <MdCancel
                  className="text-danger"
                  style={{ fontSize: "64px" }}
                />
              }
              message={bodyMessage}
              title={"Failure!"}
              show={showFailureToast}
              close={() => {
                setShowFailureToast(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default WorkflowLists;

const ApprovalsListDetail = ({
  approvalsLists,
  setIsWorkflowListAdded,
  setShowDeleteToast,
  deleteToastClick,
  setDeleteToastTitle,
  setDeleteToastClick,
}: {
  approvalsLists: Function;
  setIsWorkflowListAdded: Function;
  setShowDeleteToast: Function;
  deleteToastClick: boolean;
  setDeleteToastTitle: Function;
  setDeleteToastClick: Function;
}) => {
  const context = useApprovals();
  const { id } = useParams();
  const authContext = useAuthentication();
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const [approvalList, setApprovalList]: [[], Function] = useState([]);
  const [deletedId, setDeletedId]: [string, Function] = useState("");

  const onDeleteApprovalList = async () => {
    try {
      if (deletedId) {
        await context?.onDeleteApprovers(id, deletedId);
        approvalsLists();
        setShowDeleteToast(false);
        setDeleteToastClick(false);
        if (approvalList?.length) {
          setIsWorkflowListAdded(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (deleteToastClick) {
      onDeleteApprovalList();
    }
  }, [deleteToastClick]);

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = async () => {
    const copyListItems: any = [...approvalList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    try {
      let requestBody = {
        priorities: copyListItems?.map((list: any) => list?._id),
      };

      let response = await api?.reorderApprover(id!, requestBody);
      if (response?.data?.success) {
        setApprovalList(response?.data?.contract?.approvers);
      }
    } catch (error) {}
  };

  const updateIsMendatory = async (approvalId: string, type: boolean) => {
    try {
      let requestBody = {
        is_optional: type,
      };
      let response = await context?.updateApprovalMendatory(
        id,
        requestBody,
        approvalId
      );
      if (response) {
        approvalsLists();
      }
    } catch (error) {}
  };

  useEffect(() => {
    setApprovalList(context?.contractDetail?.approvers);
  }, [context?.contractDetail?.approvers]);

  const [rolesDelete, setRolesDelete]: [any, Function] = useState();
  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
      setRolesUpdate(guestRole?.WORKFLOWS?.find((el: any) => el === "UPDATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      {approvalList &&
        approvalList?.map((approvers: IApprovalResponse, index) => {
          return (
            <Fragment key={approvers?._id}>
              <div
                className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox"
                style={{ cursor: "move" }}
                onDragStart={() => dragStart(index)}
                onDragEnter={() => dragEnter(index)}
                onDragEnd={drop}
                key={index}
                draggable
              >
                <div className="d-flex ">
                  <div>
                    <span className="me-3 ">
                      <MdDragIndicator
                        fontSize={15}
                        color={approvers?.is_optional === false ? "" : "green"}
                      />
                    </span>
                  </div>
                  <div>
                    <h6 className=" fw-semibold">{approvers?.user?.name}</h6>
                    <h6 className=" fw-light"> {approvers?.user?.email}</h6>
                  </div>
                </div>
                <div className=" d-flex align-items-center">
                  <h6 className=" fw-semibold m-0">Reviewer</h6>
                  <div className="mx-3 ApprovalsListDetail">
                    {context?.contractDetail?.workflow_status ===
                      StatusEnum?.draft &&
                      approvers?.status === "PENDING" && (
                        <MdError color="#e1bc29" fontSize={24} />
                      )}
                    {approvers?.status === StatusEnum?.approved && (
                      <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                    )}
                    {approvers?.status === "PENDING" &&
                      context?.contractDetail?.workflow_status !==
                        StatusEnum?.draft && (
                        <MdUpdate color="#e1bc29" fontSize={24} />
                      )}
                    {approvers?.status === StatusEnum?.rejected && (
                      <IoMdCloseCircle color="#e15554" fontSize={24} />
                    )}
                  </div>
                  {(context?.contractDetail?.status === "PENDING" ||
                    context?.contractDetail?.status ===
                      StatusEnum?.published) && (
                    <div className=" ">
                      {(rolesUpdate === "UPDATE" ||
                        rolesDelete === "DELETE") && (
                        <>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              className="Actions_title"
                              id="dropdown-basic"
                            >
                              <BiDotsHorizontal fontSize={24} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="create-dropdown-css p-3">
                              {rolesDelete === "DELETE" && (
                                <>
                                  <Dropdown.Item
                                    className=""
                                    onClick={() => {
                                      setDeleteToastTitle("approver");
                                      setTimeout(() => {
                                        setShowDeleteToast(true);
                                        setDeletedId(approvers?._id);
                                      }, 200);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </>
                              )}
                              {rolesUpdate === "UPDATE" && (
                                <>
                                  {approvers?.is_optional === true ? (
                                    <Dropdown.Item
                                      onClick={() => {
                                        updateIsMendatory(
                                          approvers?._id,
                                          false
                                        );
                                      }}
                                    >
                                      Mandatory
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => {
                                        updateIsMendatory(approvers?._id, true);
                                      }}
                                    >
                                      Non Mandatory
                                    </Dropdown.Item>
                                  )}
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
    </>
  );
};

const ApprovalsTemplateLists = ({
  approvalsListDetail,
  approvalsLists,
  setIsWorkflowListAdded,
  setApprovalsTempListDetail,
  setShowDeleteToast,
  deleteToastClick,
  setDeleteToastTitle,
  setDeleteToastClick,
}: {
  approvalsListDetail: [];
  approvalsLists: Function;
  setIsWorkflowListAdded: Function;
  setApprovalsTempListDetail: Function;
  setShowDeleteToast: Function;
  deleteToastClick: boolean;
  setDeleteToastTitle: Function;
  setDeleteToastClick: Function;
}) => {
  const context = useApprovals();
  const templateContext = useTemplate();
  const authContext = useAuthentication();
  const { id } = useParams();
  const [deletedId, setDeletedId]: [string, Function] = useState("");

  const onDeleteApprovalList = async () => {
    if (deletedId) {
      try {
        let response = await context?.onDeleteTemplatesApprovers(id, deletedId);
        if (response) {
          approvalsLists();
          setShowDeleteToast(false);
          setDeleteToastClick(false);
          templateContext?.refresh();
          if (approvalsListDetail?.length) {
            setIsWorkflowListAdded(false);
          }
        }
      } catch (error) {}
    }
  };

  const onMendatoryClick = async (approvalId: string, type: boolean) => {
    try {
      let requestBody = {
        is_optional: type,
      };
      let response = await context?.updateWorkflowMendatory(
        id,
        requestBody,
        approvalId
      );
      console.log(response, "Adsdfsdfsd");
      if (response?.data?.success) {
        templateContext?.refresh();

        setApprovalsTempListDetail(response?.data?.template?.approvers);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (deleteToastClick) {
      onDeleteApprovalList();
    }
  }, [deleteToastClick]);

  console.log(deleteToastClick);

  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      {templateContext?.data?.approvers &&
        templateContext?.data?.approvers?.map(
          (approvers: IApprovalResponse) => {
            return (
              <Fragment key={approvers?._id}>
                <div className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox">
                  <div className="d-flex ">
                    <div>
                      <span className="me-3 ">
                        <MdDragIndicator
                          fontSize={15}
                          color={
                            approvers?.is_optional === false ? "" : "green"
                          }
                        />
                      </span>
                    </div>
                    <div>
                      <h6 className=" fw-semibold">{approvers?.user?.name}</h6>
                      <h6 className=" fw-light"> {approvers?.user?.email}</h6>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center">
                    <h6 className=" fw-semibold m-0">Reviewer</h6>
                    <div className="mx-3 ApprovalsListDetail">
                      {context?.templateDetail?.workflow_status ===
                        StatusEnum?.draft &&
                        approvers?.status === "PENDING" && (
                          <MdError color="#e1bc29" fontSize={24} />
                        )}
                      {approvers?.status === StatusEnum?.approved && (
                        <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                      )}
                      {approvers?.status === "PENDING" &&
                        context?.templateDetail?.workflow_status !==
                          StatusEnum?.draft && (
                          <MdUpdate color="#e1bc29" fontSize={24} />
                        )}
                      {approvers?.status === StatusEnum?.rejected && (
                        <IoMdCloseCircle color="#e15554" fontSize={24} />
                      )}
                    </div>

                    {templateContext?.template?.workflow_status !==
                      StatusEnum?.published && (
                      <div className=" ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            className="Actions_title"
                            id="dropdown-basic"
                          >
                            <BiDotsHorizontal fontSize={24} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="create-dropdown-css p-3">
                            {rolesDelete === "DELETE" && (
                              <>
                                <Dropdown.Item
                                  className=""
                                  onClick={() => {
                                    // onDeleteApprovalList(approvers?._id);
                                    setDeleteToastTitle("approver");
                                    setTimeout(() => {
                                      setShowDeleteToast(true);
                                      setDeletedId(approvers?._id);
                                    }, 200);
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </>
                            )}

                            {approvers?.is_optional === true ? (
                              <Dropdown.Item
                                onClick={() => {
                                  onMendatoryClick(approvers?._id, false);
                                }}
                              >
                                Mandatory
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  onMendatoryClick(approvers?._id, true);
                                }}
                              >
                                Non Mandatory
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          }
        )}
    </>
  );
};

const EstampList = ({
  contractContext,
  setShowDeleteToast,
  setshowEstampDelete,
  isshowFailureToast,
  setisShowFailureToast,
}: {
  contractContext: ContractContextInterface | null;
  setShowDeleteToast: Function;
  setshowEstampDelete: Function;
  isshowFailureToast: boolean;
  setisShowFailureToast: Function;
}) => {
  const context = useApprovals();
  const { id } = useParams();
  const authContext = useAuthentication();
  const onDeleteEstamp = async () => {
    try {
      let response = await api.updateContractName(id!, {
        stamp_required: false,
        name: context?.contractDetail?.name,
      });

      if (response?.data?.success) {
        contractContext?.refresh();
        setshowEstampDelete(false);
        setShowDeleteToast(false);
        setisShowFailureToast(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isshowFailureToast) {
      onDeleteEstamp();
    }
  }, [isshowFailureToast]);

  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      <div className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox border-bottom">
        <div className="d-flex ">
          <div>
            <span className="me-3 ">
              <MdDragIndicator fontSize={15} />
            </span>
          </div>
          <div>
            <h6 className=" fw-semibold">E-Stamp</h6>
            {/* ------------------------------------------------cc signer---------------------------------- */}
          </div>
        </div>

        <div className=" d-flex align-items-center">
          <div className="mx-3">
            {context?.contractDetail?.workflow_status === StatusEnum?.draft &&
              contractContext?.data?.is_stamped === false && (
                <MdError color="#e1bc29" fontSize={24} />
              )}
            {contractContext?.data?.is_stamped ? (
              <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
            ) : (
              !contractContext?.data?.is_stamped &&
              context?.contractDetail?.workflow_status !==
                StatusEnum?.draft && <MdUpdate color="#e1bc29" fontSize={24} />
            )}
          </div>
          {(context?.contractDetail?.status === "PENDING" ||
            context?.contractDetail?.status === StatusEnum?.published) && (
            <>
              {rolesDelete === "DELETE" && (
                <>
                  <div className=" ">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="Actions_title"
                        id="dropdown-basic"
                      >
                        <BiDotsHorizontal fontSize={24} />
                      </Dropdown.Toggle>
                      {rolesDelete === "DELETE" && (
                        <>
                          <Dropdown.Menu className="create-dropdown-css p-3">
                            <Dropdown.Item
                              onClick={() => {
                                setshowEstampDelete(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </>
                      )}
                    </Dropdown>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SignerAndCounterpartyDetails = ({
  approvalsLists,
  setIsWorkflowListAdded,
  setShowDeleteToast,
  deleteToastClick,
  setDeleteToastTitle,
  setDeleteToastClick,
}: {
  approvalsLists: Function;
  setIsWorkflowListAdded: Function;
  setShowDeleteToast: Function;
  deleteToastClick: boolean;
  setDeleteToastTitle: Function;
  setDeleteToastClick: Function;
}) => {
  const context = useApprovals();
  const { id } = useParams();
  const [signerAndCounterpartyList, setSignerAndCounterpartyList]: [
    [],
    Function
  ] = useState([]);
  const [deletedId, setDeletedId]: [string, Function] = useState("");
  const [type, setType]: [string, Function] = useState("");

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = async () => {
    const copyListItems: any = [...signerAndCounterpartyList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    try {
      let requestBody = {
        priorities: copyListItems?.map((el: any) => el?._id),
      };
      let response = await api?.reorderSignatory(id!, requestBody);
      if (response?.data?.success) {
        setSignerAndCounterpartyList(response?.data?.contract?.signatory);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (context?.contractDetail) {
      setSignerAndCounterpartyList(context?.contractDetail?.signatory);
    }
  }, [context?.contractDetail]);

  const onDeleteCounterpartyAndSigner = async () => {
    if (deletedId) {
      if (type === "counterparty") {
        try {
          let res = await context?.onDeleteCounterparty(id, deletedId);
          setShowDeleteToast(false);
          setDeleteToastClick(false);

          if (context?.contractDetail?.external_users?.length) {
            setIsWorkflowListAdded(false);
          }
          if (res?.data?.success) {
            approvalsLists();
          }
        } catch (error) {}
      } else if (type === "signer") {
        try {
          let res = await context?.onDeleteSignee(id, deletedId);
          setShowDeleteToast(false);
          setDeleteToastClick(false);

          if (context?.contractDetail?.internal_signer?.length) {
            setIsWorkflowListAdded(false);
          }
          if (res?.data?.success) {
            approvalsLists();
          }
        } catch (error) {}
      }
    }
  };
  const authContext = useAuthentication();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  useEffect(() => {
    if (deleteToastClick) {
      onDeleteCounterpartyAndSigner();
    }
  }, [deleteToastClick]);

  return (
    <>
      {context?.contractDetail &&
        signerAndCounterpartyList?.map(
          (signerAndCounterparty: IApprovalResponse, index) => {
            return (
              <Fragment key={signerAndCounterparty?._id}>
                {
                  <div
                    className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox border-bottom"
                    style={{ cursor: "move" }}
                    onDragStart={() => dragStart(index)}
                    onDragEnter={() => dragEnter(index)}
                    onDragEnd={drop}
                    key={index}
                    draggable
                  >
                    <div className="d-flex ">
                      <div>
                        <span className="me-3 ">
                          <MdDragIndicator fontSize={15} />
                        </span>
                      </div>
                      <div>
                        <h6 className=" fw-semibold">
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.name
                            : signerAndCounterparty?.name}
                        </h6>
                        <h6 className=" fw-light">
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.email
                            : signerAndCounterparty?.email}
                        </h6>
                        {/* ------------------------------------------------cc signer---------------------------------- */}
                        <h6
                          className=" fw-semibold pt-3"
                          style={{ display: "none" }}
                        >
                          CC : Jebas
                        </h6>
                        <h6 className=" fw-light" style={{ display: "none" }}>
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.email
                            : signerAndCounterparty?.email}
                        </h6>
                      </div>
                    </div>

                    <div className=" d-flex align-items-center">
                      <h6 className=" fw-semibold m-0">
                        {signerAndCounterparty?.user
                          ? "Signatory"
                          : "Counterparty"}
                      </h6>
                      <div className="mx-3">
                        {context?.contractDetail?.workflow_status ===
                          StatusEnum?.draft &&
                          signerAndCounterparty?.status !==
                            StatusEnum?.rejected && (
                            <MdError color="#e1bc29" fontSize={24} />
                          )}
                        {signerAndCounterparty?.status ===
                          StatusEnum?.rejected && (
                          <IoMdCloseCircle color="#e15554" fontSize={24} />
                        )}

                        {signerAndCounterparty?.status === "SIGNED" && (
                          <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                        )}
                        {signerAndCounterparty?.status === "PENDING" &&
                          context?.contractDetail?.workflow_status !==
                            StatusEnum?.draft && (
                            <MdUpdate color="#e1bc29" fontSize={24} />
                          )}
                      </div>
                      {(context?.contractDetail?.status === "PENDING" ||
                        context?.contractDetail?.status ===
                          StatusEnum?.published) && (
                        <div className=" ">
                          {rolesDelete === "DELETE" && (
                            <>
                              {" "}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="Actions_title"
                                  id="dropdown-basic"
                                >
                                  <BiDotsHorizontal fontSize={24} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="create-dropdown-css p-3">
                                  <Dropdown.Item
                                    className=""
                                    onClick={() => {
                                      setDeleteToastTitle(
                                        signerAndCounterparty?.user
                                          ? "signer"
                                          : "counterparty"
                                      );
                                      setTimeout(() => {
                                        setShowDeleteToast(true);

                                        setDeletedId(
                                          signerAndCounterparty?._id
                                        );
                                        setType(
                                          signerAndCounterparty?.user
                                            ? "signer"
                                            : "counterparty"
                                        );
                                      }, 200);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </Fragment>
            );
          }
        )}
    </>
  );
};

const NegotiationWorkflow = ({
  approvalsLists,
  setIsWorkflowListAdded,
  setShowDeleteToast,
  deleteToastClick,
  setDeleteToastTitle,
  setDeleteToastClick,
}: {
  approvalsLists: Function;
  setIsWorkflowListAdded: Function;
  setShowDeleteToast: Function;
  deleteToastClick: boolean;
  setDeleteToastTitle: Function;
  setDeleteToastClick: Function;
}) => {
  const context = useApprovals();
  const { id } = useParams();
  const [signerAndCounterpartyList, setSignerAndCounterpartyList]: [
    [],
    Function
  ] = useState([]);
  const [deletedId, setDeletedId]: [string, Function] = useState("");
  const [type, setType]: [string, Function] = useState("");

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = async () => {
    const copyListItems: any = [...signerAndCounterpartyList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    try {
      let requestBody = {
        priorities: copyListItems?.map((el: any) => el?._id),
      };
      let response = await api?.reorderNgOwner(id!, requestBody);
      if (response?.data?.success) {
        setSignerAndCounterpartyList(
          response?.data?.contract?.counterparty_owner
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (context?.contractDetail) {
      setSignerAndCounterpartyList(context?.contractDetail?.counterparty_owner);
    }
  }, [context?.contractDetail]);

  const onDeleteCounterpartyAndSigner = async () => {
    if (deletedId) {
      if (type === "counterparty") {
        try {
          let res = await context?.onDeleteCounterparty(id, deletedId);
          setShowDeleteToast(false);
          setDeleteToastClick(false);
          let requestBody = {
            name: context?.contractDetail?.name,
            negotitaion_required: false,
          };
          await api?.updateContractDetail(id!, requestBody);

          if (context?.contractDetail?.external_users?.length) {
            setIsWorkflowListAdded(false);
          }
          if (res?.data?.success) {
            approvalsLists();
          }
        } catch (error) {}
      }
    }
  };

  useEffect(() => {
    if (deleteToastClick) {
      onDeleteCounterpartyAndSigner();
    }
  }, [deleteToastClick]);

  const authContext = useAuthentication();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      {context?.contractDetail &&
        signerAndCounterpartyList?.map(
          (signerAndCounterparty: IApprovalResponse, index) => {
            return (
              <Fragment key={signerAndCounterparty?._id}>
                {
                  <div
                    className="  d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox"
                    style={{ cursor: "move" }}
                    onDragStart={() => dragStart(index)}
                    onDragEnter={() => dragEnter(index)}
                    onDragEnd={drop}
                    key={index}
                    draggable
                  >
                    <div className="d-flex ">
                      <div>
                        <span className="me-3 ">
                          <MdDragIndicator fontSize={15} />
                        </span>
                      </div>
                      <div>
                        <h6 className=" fw-semibold">
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.name
                            : signerAndCounterparty?.name}
                        </h6>
                        <h6 className=" fw-light">
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.email
                            : signerAndCounterparty?.email}
                        </h6>
                        {/* ------------------------------------------------cc signer---------------------------------- */}
                        <h6
                          className=" fw-semibold pt-3"
                          style={{ display: "none" }}
                        >
                          CC : Jebas
                        </h6>
                        <h6 className=" fw-light" style={{ display: "none" }}>
                          {signerAndCounterparty?.user
                            ? signerAndCounterparty?.user?.email
                            : signerAndCounterparty?.email}
                        </h6>
                      </div>
                    </div>

                    <div className=" d-flex align-items-center">
                      <h6 className=" fw-semibold m-0">
                        {signerAndCounterparty?.user
                          ? "Signatory"
                          : "Negotiate"}
                      </h6>
                      <div className="mx-3">
                        {context?.contractDetail?.workflow_status ===
                        StatusEnum?.draft ? (
                          <>
                            {signerAndCounterparty?.status === "PENDING" && (
                              <>
                                {" "}
                                <MdError color="#e1bc29" fontSize={24} />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            {signerAndCounterparty?.status === "PENDING" && (
                              <MdUpdate color="#e1bc29" fontSize={24} />
                            )}
                          </>
                        )}

                        {/* {context?.contractDetail?.workflow_status ===
                          StatusEnum?.draft ? (
                            <>
                            {
                              signerAndCounterparty?.status === "PENDING" && (
                            <>
                              <MdError color="#e1bc29" fontSize={24} />
                              jhsdsjsjdfh
                            </>
          }
                          )

                            
                          ):(
                            <>
                             {signerAndCounterparty?.status === "PENDING" && (
                          <>
                            <MdUpdate color="#e1bc29" fontSize={24} />
                          </>
                        )}
                            </>
                          ) */}

                        {signerAndCounterparty?.status ===
                          StatusEnum?.rejected && (
                          <>
                            <IoMdCloseCircle color="#e15554" fontSize={24} />
                          </>
                        )}

                        {signerAndCounterparty?.status === "APPROVED" && (
                          <>
                            <RiCheckboxCircleFill
                              color="#2abf86"
                              fontSize={24}
                            />
                          </>
                        )}
                      </div>
                      {(context?.contractDetail?.status === "PENDING" ||
                        context?.contractDetail?.status ===
                          StatusEnum?.published) && (
                        <div className=" ">
                          {rolesDelete === "DELETE" && (
                            <>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="Actions_title"
                                  id="dropdown-basic"
                                >
                                  <BiDotsHorizontal fontSize={24} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="create-dropdown-css p-3">
                                  <Dropdown.Item
                                    className=""
                                    onClick={() => {
                                      setDeleteToastTitle(
                                        signerAndCounterparty?.user
                                          ? "signer"
                                          : "counterparty"
                                      );
                                      setTimeout(() => {
                                        setShowDeleteToast(true);

                                        setDeletedId(
                                          signerAndCounterparty?._id
                                        );
                                        setType(
                                          signerAndCounterparty?.user
                                            ? "signer"
                                            : "counterparty"
                                        );
                                      }, 200);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </Fragment>
            );
          }
        )}
    </>
  );
};

const NegotiationApprovalLists = () => {
  const context: any = useApprovals();
  const authContext = useAuthentication();
  const contractContext: any = useContract();
  const { id } = useParams();
  const [signerAndCounterpartyList, setSignerAndCounterpartyList]: [
    [],
    Function
  ] = useState([]);

  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const dragStart = (position: any) => {
    dragItem.current = position;
  };

  const dragEnter = (position: any) => {
    dragOverItem.current = position;
  };

  const drop = async () => {
    const copyListItems: any = [...signerAndCounterpartyList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    try {
      let requestBody = {
        priorities: copyListItems?.map((el: any) => el?._id),
      };
      let response = await api?.reorderNgOwnerViewer(
        id!,
        requestBody,
        context?.contractDetail?.counterparty_owner?.filter(
          (el: any) => el?.email === authContext?.currentUser?.email
        )?.[0]?._id
      );
      if (response) {
        setSignerAndCounterpartyList(copyListItems);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (contractContext?.data) {
      if (
        contractContext?.data?.counterparty_owner?.find(
          (el: any) => el?.email === authContext?.currentUser?.email
        )
      ) {
        setSignerAndCounterpartyList(
          contractContext?.data?.counterparty_owner?.filter(
            (el: any) => el?.email === authContext?.currentUser?.email
          )?.[0]?.negotation_viewers
        );
      } else {
        setSignerAndCounterpartyList(
          contractContext?.data?.counterparty_owner?.find(
            (el: any) =>
              el?.email ===
              contractContext?.data?.externalUser?.find(
                (el: any) => el?.email === authContext?.currentUser?.email
              )?.owner
          )?.negotation_viewers
        );
      }
    }
  }, [contractContext?.data]);

  const onDeleteCollab = async (counterpartyId: string) => {
    try {
      let response = await api.deleteCounterparty(id!, counterpartyId);

      if (response?.data?.success) {
        context?.refresh();
        contractContext?.refresh();
      }
    } catch (error) {}
  };

  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesDelete(guestRole?.WORKFLOWS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);
  const [ownerId, setOwner]: [any, Function] = useState();

  useEffect(() => {
    const ownerDetail = contractContext?.data?.counterparty_owner?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    )?._id;

    if (
      contractContext?.data?.negotiation_owners?.[ownerDetail]
        ?.negotiation_status
    ) {
      setOwner(
        contractContext?.data?.negotiation_owners?.[ownerDetail]
          ?.negotiation_status
      );
    }
  }, [
    contractContext?.data?.counterparty_owner,
    contractContext?.data?.externalUser,
    contractContext?.data?.negotiation_owners,
  ]);

  return (
    <>
      {context?.contractDetail &&
        signerAndCounterpartyList?.map((signerAndCounterparty: any, index) => {
          return (
            <Fragment key={signerAndCounterparty?._id}>
              {signerAndCounterparty?.access === "VIEWER" && (
                <div
                  className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox border-bottom"
                  style={{ cursor: "move" }}
                  onDragStart={() => dragStart(index)}
                  onDragEnter={() => dragEnter(index)}
                  onDragEnd={drop}
                  key={index}
                  draggable
                >
                  <div className="d-flex ">
                    <div>
                      <span className="me-3 ">
                        <MdDragIndicator fontSize={15} />
                      </span>
                    </div>
                    <div>
                      <h6 className=" fw-semibold">
                        {signerAndCounterparty?.user
                          ? signerAndCounterparty?.user?.name
                          : signerAndCounterparty?.name}
                      </h6>
                      <h6 className=" fw-light">
                        {signerAndCounterparty?.user
                          ? signerAndCounterparty?.user?.email
                          : signerAndCounterparty?.email}
                      </h6>
                      {/* ------------------------------------------------cc signer---------------------------------- */}
                      <h6
                        className=" fw-semibold pt-3"
                        style={{ display: "none" }}
                      >
                        CC : Jebas
                      </h6>
                      <h6 className=" fw-light" style={{ display: "none" }}>
                        jarone
                        {signerAndCounterparty?.user
                          ? signerAndCounterparty?.user?.email
                          : signerAndCounterparty?.email}
                      </h6>
                    </div>
                  </div>

                  <div className=" d-flex align-items-center">
                    <h6 className=" fw-semibold m-0">
                      {signerAndCounterparty?.user ? "Signatory" : "Reviewer"}
                    </h6>
                    <div className="mx-3">
                      {signerAndCounterparty?.status ===
                        StatusEnum?.rejected && (
                        <IoMdCloseCircle color="#e15554" fontSize={24} />
                      )}
                      {signerAndCounterparty?.status === "APPROVED" ? (
                        signerAndCounterparty?.status === "APPROVED" && (
                          <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                        )
                      ) : (
                        <>
                          {signerAndCounterparty?.status !== "SIGNED" &&
                            signerAndCounterparty?.status !==
                              StatusEnum?.rejected && (
                              <MdError color="#e1bc29" fontSize={24} />
                            )}
                        </>
                      )}
                      {signerAndCounterparty?.status === "SIGNED" && (
                        <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                      )}
                    </div>
                    {contractContext?.data?.counterparty_owner?.find(
                      (el: any) => el?.email === authContext?.currentUser?.email
                    )?.status !== "REJECTED" &&
                      ownerId === "PENDING" && (
                        <div className=" ">
                          {rolesDelete === "DELETE" && (
                            <>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="Actions_title"
                                  id="dropdown-basic"
                                >
                                  <BiDotsHorizontal fontSize={24} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="create-dropdown-css p-3">
                                  <Dropdown.Item
                                    className=""
                                    onClick={() => {
                                      onDeleteCollab(
                                        signerAndCounterparty?._id
                                      );
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
    </>
  );
};

const Coordinate = ({
  contractContext,
  setShowDeleteToast,
  setshowEstampDelete,
  isshowFailureToast,
  setisShowFailureToast,
}: {
  contractContext: ContractContextInterface | null;
  setShowDeleteToast: Function;
  setshowEstampDelete: Function;
  isshowFailureToast: boolean;
  setisShowFailureToast: Function;
}) => {
  const context = useApprovals();

  const { id } = useParams();

  const onDeleteEstamp = async () => {
    try {
      let response = await api.updateContractName(id!, {
        stamp_required: false,
        name: context?.contractDetail?.name,
      });

      if (response?.success) {
        contractContext?.refresh();
        setshowEstampDelete(false);
        setShowDeleteToast(false);
        setisShowFailureToast(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isshowFailureToast) {
      onDeleteEstamp();
    }
  }, [isshowFailureToast]);

  return (
    <>
      <div className=" d-flex justify-content-between p-3 align-content-center flex-wrap ApproverFullBox border-bottom">
        <div className="d-flex ">
          <div>
            <span className="me-3 ">
              <MdDragIndicator fontSize={15} />
            </span>
          </div>
          <h6 className=" fw-semibold">
            {contractContext?.data?.sign_type &&
              contractContext?.data?.sign_type?.charAt(0)?.toUpperCase() +
                contractContext?.data?.sign_type?.slice(1)?.toLowerCase()}
          </h6>

          {/* ------------------------------------------------cc signer---------------------------------- */}
        </div>

        <div className=" d-flex align-items-center">
          <div className="mx-3">
            {contractContext?.contract?.workflow_status === "PUBLISHED" ||
            contractContext?.contract?.workflow_status ===
              "PENDING_NEGOTIATION_APPROVAL" ||
            contractContext?.contract?.workflow_status === "COMPLETED" ? (
              contractContext?.contract?.sign_type !== undefined && (
                <>
                  <RiCheckboxCircleFill color="#2abf86" fontSize={24} />
                </>
              )
            ) : (
              <>
                {" "}
                <MdError color="#e1bc29" fontSize={24} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
