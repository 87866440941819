import { useCallback, useRef, useState } from "react";

import areEqual from "deep-equal";
import { Editor } from "slate";

export default function useSelection(editor:Editor) {
  const [selection, setSelection] = useState(editor.selection);
  
  const previousSelection:any = useRef(null);
  const setSelectionOptimized = useCallback(
    (newSelection:any) => {
      
      if (areEqual(selection, newSelection)) {
        return;
      }
      previousSelection.current = selection;
      setSelection(newSelection);
    },
    [setSelection, selection]
  );

  return [previousSelection.current, selection, setSelectionOptimized];
}
