import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IApproval } from "../../interfaces/IApprovals";
import { useApprovals } from "./WorkflowProvider";
import AddApprovals from "./AddApprovals";
import { isMobileOnly } from "react-device-detect";
import { useTemplate } from "../Templates/TemplateProvider";
import { api } from "../../utils/api";
import { useContract } from "../Contracts/ContractProvider";

function Approver({
  setSignerClick,
  setApprovalAddClick,
  setBodyMessage,
  setShowSuccessToast,
  setShowFailureToast,
  approvalsLists,
  isActiveOverView,
  approvalsListDetail,
  setCounterpartyClick,
  setAddTemplateApprovers,
  approvalsTempListDetail,
  setApprovalsTempListDetail,
  setApprovalsListDetail,
  setEstampclick,
  setNegotiationWorkFlowClick,
  isNegotiationWOrkflowOwner,
  setNegotiationClick,
  setShowFailureToastValue,
  setBodyMessageText,
  negotiationViewerLength,
}: {
  setSignerClick: Function;
  setApprovalAddClick: Function;
  setBodyMessage: Function;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  approvalsLists: Function;
  isActiveOverView: string;
  approvalsListDetail: [];
  setCounterpartyClick: Function;
  setAddTemplateApprovers: Function;
  approvalsTempListDetail: [];
  setApprovalsTempListDetail: Function;
  setApprovalsListDetail: Function;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
  isNegotiationWOrkflowOwner?: boolean;
  setNegotiationClick: Function;
  setShowFailureToastValue: Function;
  setBodyMessageText: Function;
  negotiationViewerLength?: any;
}) {
  const { id } = useParams();
  const context = useApprovals();
  const templateContext = useTemplate();
  const contractContext = useContract();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addApproval = async () => {
    try {
      let requestBody = {
        approvers: context?.onSelectApprovalId?.map(
          (approval: IApproval, index) => ({
            user_id: approval?.value,
            sequence: approvalsListDetail?.length + index + 1,
            is_optional: false,
          })
        ),
      };
      let res = await context?.handleAddApprovals(id, requestBody);
      if (res?.data?.success) {
        setApprovalAddClick(false);
        setApprovalsListDetail(res?.data?.contract?.approvers);
        setSignerClick(false);
        scrollToTop();
        setBodyMessage("Approved!");
        approvalsLists();
        setCounterpartyClick(false);
        setEstampclick(false);
        setNegotiationWorkFlowClick(false);
      } else if (!res?.data?.success) {
        setBodyMessage(res?.data?.error?.[0]);
        setShowFailureToast(true);
        setApprovalAddClick(false);
        setSignerClick(false);
        setCounterpartyClick(false);
        setAddTemplateApprovers(false);
        setEstampclick(false);
        setNegotiationWorkFlowClick(false);
      }
    } catch (error) {}
  };

  const addNegotiationApprovers = async () => {
    try {
      let requestBody = {
        counter_parties: context?.onSelectApprovalId?.map(
          (counterparty: any, index) => ({
            name: counterparty?.label,
            email: counterparty?.email,
            contact_number: counterparty?.contact_number,
            organization: counterparty?.organization,
            sequence:
              negotiationViewerLength === undefined
                ? index + 1
                : negotiationViewerLength + index + 1,

            // sequence: 1,
            is_optional: false,
          })
        ),
        access: "VIEWER",
      };

      const res = await api.AddNegotiationCollaborators(id!, requestBody);

      if (res?.data?.success) {
        context?.refresh();
        approvalsLists();
        setCounterpartyClick(false);
        setNegotiationClick(false);
        setAddTemplateApprovers(false);
        setApprovalAddClick(false);
        contractContext?.refresh();
      } else if (res?.data?.success === false) {
        // setApprovalAddClick(false);
        setNegotiationClick(false);
        setBodyMessageText(res?.data?.error?.[0]);
        setShowFailureToastValue(true);
        contractContext?.refresh();
      }
    } catch (error) {}
  };

  const addApprovalsForTemplates = async () => {
    try {
      let requestBody = {
        approvers: context?.onSelectApprovalId?.map(
          (approval: IApproval, index) => ({
            user_id: approval?.value,
            sequence: approvalsTempListDetail?.length + index + 1,
            is_optional: false,
          })
        ),
      };
      let res = await context?.handleAddApprovalTemplates(id, requestBody);

      if (res?.data?.success) {
        setApprovalsTempListDetail(res?.data?.template?.approvers);
        setAddTemplateApprovers(false);
        setApprovalAddClick(false);
        templateContext?.refresh();
        setBodyMessage("Approved!");
        approvalsLists();
        setShowSuccessToast(false);
      } else if (!res?.data?.success) {
        setShowFailureToast(true);
        setBodyMessage(res?.data?.error?.[0]);
      }
    } catch (error) {}
  };

  return (
    <div>
      <div
        className=" d-flex  p-4 flex-column justify-content-between"
        style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
      >
        <div>
          <div>
            <div className="mt-3">
              <AddApprovals placeholder={"Search for reviewers"} />
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-end">
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => {
              setApprovalAddClick(false);
              setSignerClick(false);
              setCounterpartyClick(false);
              setAddTemplateApprovers(false);
              setNegotiationClick(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            disabled={context?.onSelectApprovalId?.length === 0}
            size="sm"
            onClick={
              isNegotiationWOrkflowOwner
                ? addNegotiationApprovers
                : isActiveOverView === "templates"
                ? addApprovalsForTemplates
                : addApproval
            }
          >
            {isNegotiationWOrkflowOwner
              ? "Add negotiation approver"
              : "Add reviewers"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Approver;
