import moment from "moment";
import { isMobile } from "react-device-detect";
import { StatusEnum } from "../../../../interfaces/StatusEnum";
import { updateText } from "../../../../utils";

const ActivityActions = (item: any) => {
  var today = new Date();
  var tomorrow = new Date();
  var yesterday = new Date();
  tomorrow.setDate(today.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);

  return (
    <>
      <div className="daydate px-4 position-relative">
        <div
          style={{ cursor: "pointer" }}
          className={`${
            item?.activeId === item?.item?.id
              ? "sidebox  position-absolute"
              : "sideboxInActive  position-absolute"
          }`}
          onClick={() => item?.setActiveId(item?.item?.id)}
        ></div>
        {item?.item?.action === "CHANGED" ? (
          ""
        ) : (
          <h6 className="datefeed">
            {moment(item?.item?.createdAt?.toString()).format(
              "DD MMM YYYY, hh:mm:ss A"
            )}
          </h6>
        )}

        <div className=" fw-semibold pb-4 activity_details">
          {/* .....................................approve contract.................. */}
          {/* <approver name> from <organization name> has approved <contract name></contract> */}
          {item?.item?.action === StatusEnum?.approved && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {/* {"Review by "} */}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
              {" from "}
              {item?.item?.contract_id
                ? item?.item?.contract_id?.created_by?.organization
                : item?.item?.template_id?.created_by?.organization}
              {" has approved "}
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
            </span>
          )}

          {item?.item?.action === "STARTED" && (
            <span className=" fw-light">
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
              {" initiated the workflow"}
            </span>
          )}
          {item?.item?.action === "RE-NEGOTIATION" && (
            <span className=" fw-light">
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
              {" initiated the re-negotiation"}
            </span>
          )}
          {item?.item?.action === "STAMP_FAILED" && (
            <>
              <span className=" fw-light">E-stamp request failed</span>
            </>
          )}
          {item?.item?.action === "RESUMED" && (
            <span className=" fw-light">
              {"Workflow resumed by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
            </span>
          )}
          {item?.item?.action === "STAMP_INVITED" && (
            <>
              <span className=" fw-light">
                {updateText(
                  `${item?.item?.contract_id?.created_by?.first_name} ${item?.item?.contract_id?.created_by?.last_name}`
                )}{" "}
                {" has invited you to e-stamp the contract"}
              </span>
            </>
          )}
          {item?.item?.action === "STAMP_INITIATED" && (
            <>
              <span className=" fw-light">
                {updateText(
                  `${item?.item?.contract_id?.created_by?.first_name} ${item?.item?.contract_id?.created_by?.last_name}`
                )}{" "}
                {" has initiated an estamp request"}
              </span>
            </>
          )}
          {item?.item?.action === "DOWNLOAD" && (
            <>
              <span
                className=" fw-light"
                style={{ maxWidth: "300px", wordBreak: "break-all" }}
              >
                {item?.item?.contract_id?.name}
                {" downloaded by "}
                {updateText(
                  `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                )}{" "}
              </span>
            </>
          )}
          {item?.item?.action === "TRANSFER OWNERSHIP" && (
            <>
              <span className=" fw-light">
                {updateText(
                  `${item?.item?.contract_id?.created_by?.first_name} ${item?.item?.contract_id?.created_by?.last_name}`
                )}{" "}
                {" has transferred the ownership of the contract to "}
                {updateText(
                  `${item?.item?.invited[0]?.first_name} ${item?.item?.invited[0]?.last_name}`
                )}
              </span>
            </>
          )}

          <ul>
            <li style={{ listStyle: "none" }}>
              {item?.item?.action === "SIGN_INVITED" ? (
                <>
                  <span className="fw-light">
                    {updateText(
                      `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                    )}
                    {" invited "}

                    {item?.item?.invited
                      ?.map((el: any) =>
                        updateText(
                          `${el?.name} ${el?.last_name ? el?.last_name : ""}`
                        )
                      )
                      ?.join(", ")}
                    {" as a signer from "}
                    {item?.item?.contract_id
                      ? item?.item?.contract_id?.created_by?.organization
                      : item?.item?.template_id?.created_by?.organization}
                  </span>
                </>
              ) : (
                item?.item?.action === "INVITED" && (
                  <>
                    {item?.item?.category === "INTERNAL_SIGNERS" ? (
                      <>
                        <span className="fw-light">
                          {updateText(
                            `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                          )}
                          {" invited "}

                          {item?.item?.invited
                            ?.map((el: any) =>
                              updateText(`${el?.name} ${el?.last_name}`)
                            )
                            ?.join(", ")}

                          {" as a signer from "}

                          {/* {el?.organization} */}
                          {item?.item?.contract_id
                            ? item?.item?.contract_id?.created_by?.organization
                            : item?.item?.template_id?.created_by?.organization}
                        </span>
                      </>
                    ) : item?.item?.category === "COUNTERPARTY_SIGNERS" ? (
                      <>
                        <span className="fw-light">
                          {updateText(
                            `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                          )}
                          {" invited "}

                          {item?.item?.invited
                            ?.map((el: any) => updateText(`${el?.name}`))
                            ?.join(", ")}

                          {" as a counterparty signer from "}
                          {item?.item?.invited
                            ?.map((el: any) =>
                              updateText(`${el?.organization}`)
                            )
                            ?.join(", ")}
                        </span>
                      </>
                    ) : item?.item?.category === "APPROVERS" ? (
                      <>
                        <span className="fw-light">
                          {updateText(
                            `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                          )}
                          {" invited "}

                          {item?.item?.invited
                            ?.map((el: any) =>
                              updateText(
                                `${el?.user?.first_name} ${el?.user?.last_name}`
                              )
                            )
                            ?.join(", ")}

                          {" as a reviewer from "}
                          {item?.item?.contract_id
                            ? item?.item?.contract_id?.created_by?.organization
                            : item?.item?.template_id?.created_by?.organization}
                        </span>
                      </>
                    ) : item?.item?.category === "EXTERNAL_USERS" ? (
                      <>
                        <span className="fw-light">
                          {item?.item?.contract_id ? (
                            <>
                              {item?.item?.contract_id?.created_by?.first_name}{" "}
                              {item?.item?.contract_id?.created_by?.last_name}
                            </>
                          ) : (
                            <>
                              {item?.item?.template_id?.created_by?.first_name}{" "}
                              {item?.item?.template_id?.created_by?.last_name}
                            </>
                          )}
                          {" initiated a negotiation with "}{" "}
                          {item?.item?.invited
                            ?.map((el: any) => el?.organization)
                            ?.join(" , ")}
                          {/* {item?.item?.contract_id
                          ? item?.item?.contract_id?.created_by?.organization
                          : item?.item?.template_id?.created_by
                              ?.organization}{" "} */}
                        </span>
                        <ul className="">
                          {item?.item?.invited?.map((el: any) => {
                            return (
                              <li
                                style={{ listStyleType: "none" }}
                                className="lowerAlpha"
                              >
                                <span className="fw-light ">
                                  {"  Shared with "}
                                  {el?.name}

                                  {" at "}
                                  {el?.organization}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : item?.item?.category === "SIGNERS" ? (
                      <>
                        <span className="fw-light">
                          {"E-sign initiated."}
                          <br />
                          {"E-sign parties:"}
                          <br />
                        </span>
                        <ul className="">
                          {item?.item?.invited?.[0]?.counterparty_signers?.map(
                            (el: any) => {
                              return (
                                <li
                                  style={{ listStyleType: "none" }}
                                  className="lowerAlpha"
                                >
                                  <span className="fw-light ">
                                    {el?.name} {el?.last_name}
                                    {" from "}
                                    {el?.organization}
                                  </span>
                                </li>
                              );
                            }
                          )}{" "}
                          {item?.item?.invited?.[0]?.internal_signers?.map(
                            (el: any) => {
                              return (
                                <li
                                  style={{ listStyleType: "none" }}
                                  className="lowerAlpha"
                                >
                                  <span className="fw-light ">
                                    {el?.name} {el?.last_name}
                                    {" from "}
                                    {el?.organization}
                                  </span>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </>
                    ) : item?.item?.type === "CONTRACT NEGOTIATION" ? (
                      <>
                        <span className="fw-light">
                          {item?.item?.contract_id ? (
                            <>
                              {item?.item?.contract_id?.created_by?.first_name}{" "}
                              {item?.item?.contract_id?.created_by?.last_name}
                            </>
                          ) : (
                            <>
                              {item?.item?.template_id?.created_by?.first_name}{" "}
                              {item?.item?.template_id?.created_by?.last_name}
                            </>
                          )}
                          {" initiated a negotiation with "}{" "}
                          {item?.item?.invited
                            ?.map((el: any) => el?.organization)
                            ?.join(" , ")}
                        </span>
                        <ul className="">
                          {item?.item?.invited?.map((el: any) => {
                            return (
                              <li
                                style={{ listStyleType: "none" }}
                                className="lowerAlpha"
                              >
                                <span className="fw-light ">
                                  {"  Shared with "}
                                  {el?.name}

                                  {" at "}
                                  {el?.organization}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : item?.item?.type === "CONTRACT NEGOTIATION OWNER" ? (
                      <>
                        <span className="fw-light">
                          {item?.item?.contract_id ? (
                            <>
                              {item?.item?.contract_id?.created_by?.first_name
                                ?.charAt(0)
                                ?.toUpperCase() +
                                item?.item?.contract_id?.created_by?.first_name
                                  ?.slice(1)
                                  ?.toLowerCase()}{" "}
                              {item?.item?.contract_id?.created_by?.last_name}
                            </>
                          ) : (
                            <>
                              {item?.item?.template_id?.created_by?.first_name}{" "}
                              {item?.item?.template_id?.created_by?.last_name}
                            </>
                          )}
                          {" initiated a negotiation with "}{" "}
                          {item?.item?.invited
                            ?.map((el: any) => el?.organization)
                            ?.join(" , ")}
                        </span>
                        <ul className="">
                          {item?.item?.invited?.map((el: any) => {
                            return (
                              <li
                                style={{ listStyleType: "none" }}
                                className="lowerAlpha"
                              >
                                <span className="fw-light ">
                                  {"  Shared with "}
                                  {el?.name}

                                  {" at "}
                                  {el?.organization}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : item?.item?.type === "CONTRACT NEGOTIATION VIEWER" ? (
                      <>
                        <span className="fw-light">
                          {item?.item?.contract_id ? (
                            <>
                              {item?.item?.created_by?.name
                                ?.charAt(0)
                                ?.toUpperCase() +
                                item?.item?.created_by?.name
                                  ?.slice(1)
                                  ?.toLowerCase()}{" "}
                              {item?.item?.created_by?.last_name}
                            </>
                          ) : (
                            <>
                              {item?.item?.template_id?.created_by?.first_name}{" "}
                              {item?.item?.template_id?.created_by?.last_name}
                            </>
                          )}
                          {" initiated a negotiation with "}{" "}
                          {item?.item?.invited
                            ?.map((el: any) => el?.organization)
                            ?.join(" , ")}
                        </span>
                        <ul className="">
                          {item?.item?.invited?.map((el: any) => {
                            return (
                              <li
                                style={{ listStyleType: "none" }}
                                className="lowerAlpha"
                              >
                                <span className="fw-light ">
                                  {"  Shared with "}
                                  {el?.name}

                                  {" at "}
                                  {el?.organization}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <>
                        <span
                          className="fw-light"
                          style={{ maxWidth: "300px", wordBreak: "break-all" }}
                        >
                          {item?.item?.created_by?.first_name !== undefined && (
                            <>
                              {updateText(
                                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
                              )}
                            </>
                          )}

                          {" invited "}
                          {item?.item?.contract_id
                            ? item?.item?.contract_id?.collaborators
                                ?.filter((el: any) => el?.access !== "OWNER")
                                ?.map((el: any) =>
                                  updateText(
                                    `${el?.user?.first_name} ${el?.user?.last_name}`
                                  )
                                )
                                ?.join(", ")
                            : item?.item?.template_id?.collaborators
                                ?.filter((el: any) => el?.access !== "OWNER")
                                ?.map((el: any) =>
                                  updateText(
                                    `${el?.user?.first_name} ${el?.user?.last_name}`
                                  )
                                )
                                ?.join(", ")}
                          {" to "}
                          {item?.item?.contract_id
                            ? item?.item?.contract_id?.name
                            : item?.item?.template_id?.name}
                        </span>
                        <ul className="">
                          {item?.item?.contract_id
                            ? item?.item?.contract_id?.collaborators
                                ?.filter((el: any) => el?.access !== "OWNER")
                                ?.map((el: any) => {
                                  return (
                                    <li
                                      style={{ listStyleType: "none" }}
                                      className="lowerAlpha"
                                    >
                                      <span className="fw-light ">
                                        {el?.user?.name} {el?.user?.last_name}{" "}
                                        {" invited as an "}
                                        {el?.access?.charAt(0)?.toUpperCase() +
                                          el?.access?.slice(1)?.toLowerCase()}
                                      </span>
                                    </li>
                                  );
                                })
                            : item?.item?.template_id?.collaborators
                                ?.filter((el: any) => el?.access !== "OWNER")
                                ?.map((el: any) => {
                                  return (
                                    <li
                                      style={{ listStyleType: "none" }}
                                      className="lowerAlpha"
                                    >
                                      <span className="fw-light ">
                                        {el?.user?.name} {" invited as an "}
                                        {el?.access?.charAt(0)?.toUpperCase() +
                                          el?.access?.slice(1)?.toLowerCase()}
                                      </span>
                                    </li>
                                  );
                                })}
                        </ul>
                      </>
                    )}
                  </>
                )
              )}
            </li>
          </ul>

          {item?.item?.action === StatusEnum?.published && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
              {" published by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
            </span>
          )}
          {item?.item?.action === "WORKFLOW_STARTED" && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
              {" workflow initiated by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
            </span>
          )}

          {item?.item?.action === "UPDATED" && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.contract_id ? (
                <>{"Contract name "}</>
              ) : (
                <>{"Template name "}</>
              )}

              {" changed by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
              {" to "}
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
            </span>
          )}
          {item?.item?.action === StatusEnum?.draft && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
              {" unpublished by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
            </span>
          )}

          {item?.item?.action === "CREATED" && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
              {" created by "}
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
            </span>
          )}

          {item?.item?.action === "REJCTED" && (
            <span className="fw-light">
              {updateText(
                `${item?.item?.created_by?.first_name} ${item?.item?.created_by?.last_name}`
              )}
              {" invited "}

              {item?.item?.invited
                ?.map((el: any) =>
                  updateText(`${el?.user?.first_name} ${el?.user?.last_name}`)
                )
                ?.join(", ")}

              {" as a reviewer from "}
              {item?.item?.contract_id
                ? item?.item?.contract_id?.created_by?.organization
                : item?.item?.template_id?.created_by?.organization}
            </span>
          )}

          {item?.item?.action === "REJECTED" && (
            <>
              <span
                className="fw-light"
                style={{ maxWidth: "300px", wordBreak: "break-all" }}
              >
                {item?.item?.created_by?.user
                  ? updateText(
                      `${item?.item?.created_by?.user?.first_name} ${item?.item?.created_by?.user?.last_name}`
                    )
                  : updateText(`${item?.item?.created_by?.name}`)}
                {" from "}
                {item?.item?.contract_id
                  ? item?.item?.contract_id?.created_by?.organization
                  : item?.item?.template_id?.created_by?.organization}
                {" has rejected "}
                {item?.item?.contract_id
                  ? item?.item?.contract_id?.name
                  : item?.item?.template_id?.name}
              </span>
            </>
          )}

          {item?.item?.action === "COMPLETED" && (
            <span className=" fw-light">{" Contract signed "}</span>
          )}

          {item?.item?.action === "SIGNED" && (
            <span
              className=" fw-light"
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            >
              {item?.item?.created_by?.name}&nbsp;
              {item?.item?.created_by?.last_name}
              {" from "}
              {item?.item?.created_by?.organization}
              {" has signed "}
              {item?.item?.contract_id
                ? item?.item?.contract_id?.name
                : item?.item?.template_id?.name}
            </span>
          )}

          {item?.item?.action === StatusEnum?.stamped && (
            <span className=" fw-light">{" Stamping completed. "}</span>
          )}
        </div>

        <div className="row pb-4">
          <div className="col-lg-6 col-12  pe-md-4 ">
            <div className="feed"></div>
          </div>
          <div
            className={`${
              isMobile ? " pt-4 col-lg-6 col-12  " : "col-lg-6 col-12 ps-md-5 "
            }`}
          >
            <div className="feed"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityActions;
