function Loader() {
  return (
    <div>
      <div className="File404">
        <div className="d-flex flex-column align-items-center">
          <div className={"font404 d-flex justify-content-center"}>
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="no-data-found">loading...</div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
