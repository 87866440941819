import { Formik } from "formik";
import { useState } from "react";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FailureToast from "../../../ui/FailureToast";
import FormikField from "../../../ui/FormikField";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import SuccessToast from "../../../ui/SuccessToast";
import { useAdminAuth } from "./AdminAuthenticationProvider";
import { adminRoutes } from "../../../utils/adminRoutes";
import AdminAuthlayout from "../../../layouts/AdminAuthLayout";
import { adminApi } from "../../../utils/adminApi";

const AdminForgetPasswordPage = () => {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [resetEmail, setResetEmail]: [string, Function] = useState("");
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const context = useAdminAuth();
  const navigate = useNavigate();

  const initialValues: { email: string } = { email: "" };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required(""),
  });

  const handleSubmit = async (
    _values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    setResetEmail(_values?.email);
    onResetLink(_values?.email);

    setSubmitting(false);
  };

  const onResetLink = async (email: "string") => {
    if (resetEmail) {
      setLoader(true);
      try {
        let response = await adminApi.AdminForgetPassword({
          email: email,
        });
        setLoader(false);

        if (response?.status === 201) {
          setShowSuccessToast(true);
          setTimeout(() => {
            navigate("/admin/login");
          }, 200);
        }
      } catch (error) {}
    }
  };

  return (
    context && (
      <AdminAuthlayout
        pageTitle="Don't remember your password? worry not!"
        title="Oops"
        subTitle="Don't remember your password? worry not!"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormikField
                name="email"
                type="email"
                validationSchema={validationSchema}
                label="Email address "
                errors={errors}
                placeholder="Enter your e-mail address"
                autoFocus
              />

              <div className=" d-flex justify-content-end align-items-center">
                <FormikSubmitButton
                  isSubmitting={isSubmitting}
                  className="my-3 btn_radius w-100"
                  size="md"
                >
                  <span className="SignText">
                    Send Link {loader ? <span className="loader"></span> : ""}
                  </span>
                </FormikSubmitButton>
              </div>
              <div className="my-3">
                <Link
                  to={adminRoutes.login}
                  className="text-decoration-none text-primary d-flex justify-content-center"
                >
                  <span> I remember my password</span>
                </Link>
              </div>
            </form>
          )}
        </Formik>
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message="Email sent."
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message="Couldn't able to send email instruction."
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </AdminAuthlayout>
    )
  );
};

export default AdminForgetPasswordPage;
