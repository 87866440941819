import noData from "../../../assets/noData.png";

function NoTemplateFound() {
  return (
    <>
      <div className=" mt-2 mx-2 contract-title "></div>
      <div className={`File404  `}>
        <div className="d-flex flex-column align-items-center">
          <div className={"font404 d-flex justify-content-center"}>
            {" "}
            <img src={noData} alt="no-data" width={200} />
          </div>
          <div className="no-data-found">No data found</div>
        </div>
      </div>
    </>
  );
}

export default NoTemplateFound;
