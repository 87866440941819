import { IBaseFilterQueryParams } from "../interfaces/IFilterQueryParams";

export const routes = {
  root: "/",
  signIn: "/login",
  signUp: "/signup",
  forgetPassword: "/forgot_password",
  resetPassword: "/reset_password",
  invitation: `/invitation`,
  profile: "/profile",
  templates: "/templates",
  helpCenter: "/help_center",
  privacyPolicy: "/privacy_policy",
  roles: "/roles_and_access",
  members: "/members",
  addFolders: "/addfolders",
  templateOverview: `/templates/:id/:module`,
  contractsOverview: `/contracts/:id/:module`,

  analytics: "/analytics/summary",
  analyticsSummary: "/analytics/summary",
  analyticsTurnaround: "/analytics/turnaround",
  analyticsTeams: "/analytics/teams",
  analyticsContactValue: "/analytics/contact",

  overview: "/templates/:id/overview",
  overviewTemplateId: "/templates/:id",

  variables: "/templates/:id/variables",
  workflow: "/templates/:id/workflow",
  versionHistory: "/templates/:id/versions",
  activityFeed: "/templates/:id/activities",
  hierarchy: "/templates/:id/hierarchy",

  VersionHistoryContractDeatils:
    "/contracts/:id/versions/:versionId/:documentId",
  VersionHistoryTemplateDeatils:
    "/templates/:id/versions/:versionId/:documentId",

  coordinateContractUrl: (id: any, params: { signature: boolean }) =>
    params
      ? `/contracts/${id}/overview?${filterQueryParams(params)}`
      : `/contracts/${id}/overview`,

  overviewContractUrl: (id: any, params: { sign: boolean }) =>
    params
      ? `/contracts/${id}/overview?${filterQueryParams(params)}`
      : `/contracts/${id}/overview`,

  overviewContracts: "/contracts/:id/overview",
  overviewPDF: "/contracts/:id/overview/add_coordinate",
  overviewIdContracts: "/contracts/:id",

  variablesContracts: "/contracts/:id/variables",
  workflowContracts: "/contracts/:id/workflow",
  workflowCoordinatesViewContracts: "/contracts/:id/workflow/view",

  versionHistoryContracts: "/contracts/:id/versions",
  activityFeedContracts: "/contracts/:id/activities",
  hierarchyContracts: "/contracts/:id/hierarchy",
  editContractHierarchy: `/contracts/:id/edit/hierarchy`,

  contracts: "/contracts",
  templatesByDepartment: "/templates/:department",
  contractsByDepartment: "/contracts/:department",
  MembersByDepartment: "/members/:department",
  FolderByDepartment: "/folder/:department",

  folders: "/folder",
  templateFolder: "/folder/templates",
  contractFolder: "/folder/contracts",
  privateFolders: "/folder/private",
  publicFolder: "/folder/public",

  privatefolderId: "/folder/private/:id",
  publicfolderId: "/folder/public/:id",

  templatePrivateFolder: "/folder/private/template",
  contractPrivateFolder: "/folder/private/contract",
  templatePublicFolder: "/folder/public/template",
  contractPublicFolder: "/folder/public/contract",

  memberFiltersUrl: (params: IBaseFilterQueryParams) =>
    params ? `/members?${filterQueryParams(params)}` : "/members",

  templateFiltersUrl: (params: IBaseFilterQueryParams) =>
    params ? `/templates?${filterQueryParams(params)}` : "/templates",
  contractFiltersUrl: (params: IBaseFilterQueryParams) =>
    params ? `/contracts?${filterQueryParams(params)}` : "/contracts",
  editTemplate: `/templates/:id/edit`,
  editContract: `/contracts/:id/edit`,
  noData: "/no_data_found",
  urlEditTemplate: (id: string) => `/templates/${id}/edit`,
  urlEditContract: (id: string) => `/contracts/${id}/edit`,
  changePassword: "/profile/change_password",
  companyProfile: "/profile/company_profile",
  mysignature: "/profile/mysignature",
  editContractUrl: (id: any, params: { suggestion: boolean }) =>
    params
      ? `/contracts/${id}/edit?${filterQueryParams(params)}`
      : `/contracts/${id}/edit`,

  renewContractUrl: (id: any, params: { renew: boolean }) =>
    params
      ? `/contracts/${id}/overview?${filterQueryParams(params)}`
      : `/contracts/${id}/overview`,

  publishContractUrl: (id: any, params: { publish: boolean }) =>
    params
      ? `/contracts/${id}/overview?${filterQueryParams(params)}`
      : `/contracts/${id}/overview`,

  workflowContractInfo: (id: any, params: { contractInfo: boolean }) =>
    params
      ? `/contracts/${id}/workflow?${filterQueryParams(params)}`
      : `/contracts/${id}/workflow`,

  workflowCoordinate: (id: any, params: { coordinates: boolean }) =>
    params
      ? `/contracts/${id}/workflow?${filterQueryParams(params)}`
      : `/contracts/${id}/workflow`,

  workflowAddSignatory: (id: any, params: { signatory: boolean }) =>
    params
      ? `/contracts/${id}/workflow?${filterQueryParams(params)}`
      : `/contracts/${id}/workflow`,
};

export const adminroutes = {
  adminRoot: "/admin/login",
  adminSignIn: "/admin/login",
  adminForgetPassword: "/admin/forgot_password",
  adminResetPassword: "/admin/reset_password",
  adminInvitation: `/admin/invitation`,
};

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};
