import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { useEffect, useState } from "react";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";
import { ResponsivePie } from "@nivo/pie";

const AdminStackedChart = ({ title, filterData }: any) => {
  console.error(title, filterData);
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [[], Function] = useState([]);

  const ChartDataDetails = async () => {
    try {
      let response = await context?.stackedBarChartDetails();
      if (
        response?.data?.active !== 0 ||
        response?.data?.in_active !== 0 ||
        response?.data?.not_accepted !== 0
      ) {
        if (response?.success) {
          const dataWithColors = [
            {
              id: "Active",
              color: "green",
              value: response?.data?.active,
            },
            {
              id: "In Active",
              color: "Yellow",
              value: response?.data?.in_active,
            },
            {
              id: "Pending",
              color: "grey",
              value: response?.data?.not_accepted,
            },
          ];
          setChartData(dataWithColors);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, []);

  return (
    <>
      <div style={{ height: "390px" }}>
        {chartData?.length === 0 ? (
          <AnalyticsNoDataFound />
        ) : (
          <>
            <ResponsivePie
              data={chartData}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              //   innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.3]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              tooltip={({ datum }: any) => {
                return (
                  <div style={{ color: "black" }}>
                    <span>
                      {datum?.label}: {datum.value}
                    </span>
                  </div>
                );
              }}
              // defs={[
              //   {
              //     id: "dots",
              //     type: "patternDots",
              //     background: "inherit",
              //     color: "rgba(255, 255, 255, 0.3)",
              //     size: 4,
              //     padding: 1,
              //     stagger: true,
              //   },
              //   {
              //     id: "lines",
              //     type: "patternLines",
              //     background: "inherit",
              //     color: "rgba(255, 255, 255, 0.3)",
              //     rotation: -45,
              //     lineWidth: 6,
              //     spacing: 10,
              //   },
              // ]}
              fill={[
                {
                  match: {
                    id: "ruby",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "c",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "go",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "python",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "scala",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "lisp",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "elixir",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "javascript",
                  },
                  id: "lines",
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AdminStackedChart;
