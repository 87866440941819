const { REACT_APP_WEBSOCKET_URL } = process.env;
export const WEBSOCKET_ENDPOINT: string = REACT_APP_WEBSOCKET_URL || "";

export const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
  "mod+shift+s": "strikeThrough",
  "mod+shift+p": "superscript",
  "mod+shift+b": "subscript",
};

export const HOTKEYSELEMENTS: any = {
  "mod+shift+l": "left",
  "mod+shift+r": "right",
  "mod+shift+c": "center",
  "mod+shift+j": "justify",
  "mod+shift+8": "numbered-list",
  "mod+shift+7": "bulleted-list",
  "mod+m": "indentLeft",
  "mod+shift+m": "indentRight",
};

export const LIST_TYPES = ["numbered-list", "bulleted-list"];
export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

export const fontFamilyMap: any = {
  montserrat: "Montserrat",
  inter: "Inter",
  helvetica: "Helvetica",
  verdana: "Verdana",
  impact: "Impact",
  gill_sans: "Gill Sans",
  baskerville: "Baskerville",
  bradley_hand: "Bradley Hand",
  american_typewriter: "American Typewriter",
  Open_Sans: "Open Sans",
  Arimo: "Arimo",
  San_serif: "San serif",
  Calibri: "Calibri",
  Georgia: "Georgia",
  TimesNewRoman: "Times New Roman",
  Pacifico:"Pacifico",
  Times:"Times",
};
// export const sizeMap: any = {
//   small: "0.75em",
//   normal: "1em",
//   medium: "1.75em",
//   huge: "2.5em",
// };

export const sizeMap: any = {
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
  14: "14",
  18: "18",
  24: "24",
  30: "30",
  36: "36",
  48: "48",
  60: "60",
  72: "72",
  96: "96",
};

export const lineHeightMap: any = {
  small: "10px",
  normal: "20px",
  medium: "30px",
  huge: "40px",
};

export const suggestionNode = {
  type: "suggestionNode",
  children: [{ text: "SuggestionNode", color: "green" }],
} as any;
