import { Button, Form, FormGroup, Stack } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useApprovals } from "./WorkflowProvider";
import { api } from "../../utils/api";
import { useContract } from "../Contracts/ContractProvider";

function CounterPartyApprover({
  approvalsLists,
  counterPartyLists,
  setBodyMessage,
  setShowFailureToast,
  setSignerClick,
  setCounterpartyClick,
  setApprovalAddClick,
  setEstampclick,
  setNegotiationWorkFlowClick,
}: {
  approvalsLists: Function;
  setBodyMessage: Function;
  setShowFailureToast: Function;
  counterPartyLists: [];
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setApprovalAddClick: Function;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
}) {
  const { id } = useParams();
  const context = useApprovals();
  const contractContext = useContract();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [isRequired, setIsRequired]: [boolean, Function] = useState(false);

  const [inputCounterPartyFields, setInputCounterPartyFields] = useState([
    {
      name: "",
      email: "",
      organization: "",
      nameError: "",
      emailError: "",
      organizationError: "",
      mendatory: true,
    },
  ]);

  const handleChange = (
    evnt: any,
    index: any,
    fieldType?: any,
    currentValue?: boolean
  ) => {
    const { name, value } = evnt.target;
    const list: any = [...inputCounterPartyFields];
    if (fieldType === "checkBox") {
      list[index][name.replace(`_${index}`, "")] = !currentValue;
    } else {
      list[index][name] = value;
    }
    setInputCounterPartyFields(list);
    validateField();
  };

  const handleUpdateStamp = async (required: boolean) => {
    try {
      let response = await api.updateContractName(id!, {
        negotitaion_required: required,
        name: contractContext && contractContext?.data?.name,
      });
      if (response) {
        contractContext?.refresh();
      }
    } catch (error) {}
  };

  const onAddConterparty = async () => {
    handleUpdateStamp(isRequired);
    try {
      let requestBody = {
        counter_parties: inputCounterPartyFields?.map(
          (counterparty: any, index) => ({
            name: counterparty?.name,
            email: counterparty?.email,
            organization: counterparty?.organization,
            contact_number: phoneNumber,
            sequence: counterPartyLists?.length + 1 + index,
            is_optional: false,
          })
        ),
        access: "OWNER",
      };
      let res = await context?.handleCounterParty(id, requestBody);

      if (res?.data?.success) {
        setSignerClick(false);
        setCounterpartyClick(false);
        setApprovalAddClick(false);
        approvalsLists();
        setEstampclick(false);
        setNegotiationWorkFlowClick(false);
        contractContext?.refresh();
      } else if (!res?.data?.success) {
        setShowFailureToast(true);
        setBodyMessage(
          res?.data?.error?.[0]
            ?.replace("counter_parties.0.", "")
            ?.replace("_", " ")
        );
        setEstampclick(false);
        setNegotiationWorkFlowClick(false);
        contractContext?.refresh();
      }
    } catch (error) {}
  };

  const validateField = () => {
    let emailReg =
      /^([A-Za-z0-9_+0-9\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    let phnReg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    if (inputCounterPartyFields?.length > 0) {
      inputCounterPartyFields?.map((item: any) => {
        if (item?.name?.length > 0 && item?.name?.length <= 1) {
          item.nameError = "Please fill the Name Field";
        } else {
          item.nameError = "";
        }
        if (item?.organization?.length > 0 && item?.organization?.length <= 1) {
          item.organizationError = "Please fill the Organization Field";
        } else {
          item.organizationError = "";
        }
        if (item?.email?.length > 0 && !emailReg?.test(item?.email)) {
          item.emailError = "Please fill the email Field";
        } else {
          item.emailError = "";
        }
        if (
          item?.contact_number?.length > 0 &&
          !phnReg?.test(item?.contact_number)
        ) {
          item.phnErr = "PLease fill the contact number";
        } else {
          item.phnErr = "";
        }
        if (
          item?.contact_number?.length > 0 &&
          !phnReg?.test(item?.contact_number)
        ) {
          item.phnError = "Please fill contact number";
        }
      });
    }
  };

  let disabledArray = [];
  let emailReg =
    /^([A-Za-z0-9_+0-9\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (inputCounterPartyFields?.length > 0) {
    const allTrue = inputCounterPartyFields.every(
      (obj) =>
        obj.name?.length > 1 &&
        obj.organization?.length > 1 &&
        emailReg?.test(obj?.email)
    );
    disabledArray?.push(allTrue);
  }

  return (
    <div>
      <div
        className=" d-flex  worflow_modal position-relative p-4 flex-column justify-content-between"
        style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
      >
        <div className="">
          <Stack className="my-2">
            <label className=" fw-semibold">
              Do you want to initiate a negotiation?
            </label>
          </Stack>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <div className=" d-flex justify-content-between">
              <div className="py-3">
                <span>
                  <input
                    type="radio"
                    checked={isRequired}
                    className="form-check-input"
                    onChange={() => {
                      // handleUpdateStamp(true);
                      setIsRequired(true);
                    }}
                  />
                  <span className="ps-2 me-3">Yes</span>
                </span>
                <span>
                  <input
                    type="radio"
                    className="form-check-input"
                    checked={!isRequired}
                    onChange={() => {
                      // handleUpdateStamp(false);
                      setIsRequired(false);
                    }}
                  />
                  <span className="ps-2 me-3">No</span>
                </span>
              </div>
            </div>
          </Form.Group>
        </div>
        {isRequired && (
          <>
            {inputCounterPartyFields?.map((counterFields: any, index) => {
              return (
                <Fragment key={index}>
                  {index > 0 && (
                    <div
                      className="d-flex justify-content-end"
                      onClick={() => {
                        inputCounterPartyFields?.splice(index, 1);
                        setInputCounterPartyFields([
                          ...inputCounterPartyFields,
                        ]);
                      }}
                    >
                      <IoCloseCircleOutline className="close-icon-css" />
                    </div>
                  )}
                  <div className={`${isMobile ? " pt-4" : ""}`}>
                    <div className="row mb-3">
                      <div className="col-md-6 col-12  pe-md-4">
                        <label className="input_label">
                          Email address
                          <span style={{ color: "red" }}>{"*"}</span>
                        </label>
                        <input
                          type="email"
                          required
                          onChange={(eve) => {
                            handleChange(eve, index);
                          }}
                          name="email"
                          className="form-control"
                          placeholder="Email"
                        />
                        {counterFields?.emailError?.length > 0 && (
                          <div className="counterparty_error">
                            {counterFields?.emailError}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-12  ">
                        <label className="input_label">Negotiate owner</label>
                        <input
                          type="text"
                          required
                          disabled
                          name="access"
                          value={"Owner"}
                          className="form-control"
                          placeholder="Name"
                        />
                        {counterFields?.nameError?.length > 0 && (
                          <div className="counterparty_error">
                            {counterFields?.nameError}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6 col-12  ">
                        <label className="input_label">
                          Name<span style={{ color: "red" }}>{"*"}</span>
                        </label>
                        <input
                          type="text"
                          required
                          onChange={(eve) => {
                            handleChange(eve, index);
                          }}
                          name="name"
                          className="form-control"
                          placeholder="Name"
                        />
                        {counterFields?.nameError?.length > 0 && (
                          <div className="counterparty_error">
                            {counterFields?.nameError}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-12  pe-md-4">
                        <label className="input_label">
                          Company Name
                          <span style={{ color: "red" }}>{"*"}</span>
                        </label>
                        <input
                          required
                          type="text"
                          onChange={(eve) => {
                            handleChange(eve, index);
                          }}
                          name="organization"
                          className="form-control"
                          placeholder="Company Name"
                        />
                        {counterFields?.organizationError?.length > 0 && (
                          <div className="counterparty_error">
                            {counterFields?.organizationError}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-12">
                        <label className="input_label">Contact Number</label>
                        <FormGroup>
                          <div className="phone-input-css ">
                            <PhoneInput
                              value={phoneNumber}
                              international
                              name={"contact_number"}
                              country={"in"}
                              defaultCountry="IN"
                              countryCallingCodeEditable={false}
                              onChange={(e) => {
                                setPhoneNumber(e);
                              }}
                              error={
                                phoneNumber
                                  ? isValidPhoneNumber(phoneNumber)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                          </div>
                          <span className="field-error">
                            {phoneNumber
                              ? isValidPhoneNumber(phoneNumber)
                                ? undefined
                                : "Invalid phone number"
                              : ""}
                          </span>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}

            <div className=" d-flex justify-content-end">
              <Button
                className="cancelbtn me-3 "
                size="sm"
                onClick={() => {
                  setCounterpartyClick(false);
                  setSignerClick(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="sharebtndanger"
                size="sm"
                disabled={
                  inputCounterPartyFields?.length > 0
                    ? disabledArray?.includes(false)
                    : true
                }
                onClick={onAddConterparty}
              >
                Add negotiation counterparty
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CounterPartyApprover;
