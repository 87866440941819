import { IoMdAdd } from "react-icons/io";
import { Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useParams } from "react-router-dom";
import {
  ContractContextInterface,
  useContract,
} from "../../../Contracts/ContractProvider";

const HierarchyAddBtn = ({
  docId,
  contractHirerchyList,
  type,
}: {
  docId?: string;
  contractHirerchyList: Function;
  type: string;
}) => {
  const context: ContractContextInterface | null = useContract();

  const { id } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [contractLists, setContractLists]: [[], Function] = useState([]);
  const [isParentActive, setIsParentActive]: [string, Function] = useState("");
  const [serchText, setSearchText]: [string, Function] = useState("");
  const [activeContract, setIsActiveContract]: [string, Function] =
    useState("");

  const getAllContract = async () => {
    try {
      let response = await context?.getDocument({ search: serchText });

      if (response?.success) {
        setContractLists(response?.contracts);
      }
    } catch (error) {}
  };

  const handleContractHierarchy = async (
    parentId: string,
    type: string,
    contractName: any
  ) => {
    if (type === "Parent") {
      let requestBody = {
        name: context?.data?.name,
        parent_contract: parentId,
      };
      try {
        let response = await context?.handleUpdateContractDetail(
          id,
          requestBody
        );

        if (response?.data?.success) {
          setShow(false);
          setIsActiveContract("");
          contractHirerchyList();
        }
      } catch (error) {}
    } else if (type === "Child") {
      let requestBody = {
        name: contractName,
        parent_contract: docId,
      };
      try {
        let response = await context?.handleUpdateContractDetail(
          parentId,
          requestBody
        );

        if (response?.data?.success) {
          setShow(false);
          setIsActiveContract("");
          contractHirerchyList();
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getAllContract();
  }, [serchText]);

  return (
    <>
      <div
        className={
          context?.data?.status === "COMPLETED"
            ? "addHierarchy d-flex align-items-center justify-content-center "
            : "addHierarchyIcon d-flex align-items-center justify-content-center "
        }
      >
        <Dropdown>
          <Dropdown.Toggle
            disabled={context?.data?.status === "COMPLETED" ? false : true}
            variant="default"
            className="new-contact-btn create-template contract-css ms-1"
            id="dropdown-basic"
            style={{ border: "none", color: "red" }}
          >
            <IoMdAdd
              fontSize={24}
              color={
                context?.data?.status === "COMPLETED" ? "#a8aab7" : "lightgray"
              }
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="create-dropdown-css">
            {type === "parent" && (
              <Dropdown.Item
                className="sort-by"
                onClick={() => {
                  setShow(true);
                  setIsParentActive("Parent");
                }}
              >
                Add Parent
              </Dropdown.Item>
            )}
            {type !== "Grand child" && (
              <Dropdown.Item
                className="sort-by"
                onClick={() => {
                  setShow(true);
                  setIsParentActive("Child");
                }}
              >
                Add Child
              </Dropdown.Item>
            )}

            {/* <Dropdown.Item className="sort-by">Add Grand Child</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
        {show && (
          <>
            <Modal show={show} centered>
              <Modal.Header className=" align-items-center">
                <h6 className=" m-0">Add a {isParentActive} Contract</h6>
                <div
                  onClick={() => {
                    setShow(!show);
                  }}
                  className=" cursor-pointer"
                >
                  <TiDelete fontSize={30} color="#e15554" />
                </div>
              </Modal.Header>
              <Modal.Body className="p-4">
                <div>
                  <input
                    className="doc_searchQueryInput form-control"
                    type="text"
                    onChange={(e) => setSearchText(e?.target?.value)}
                    name="doc_searchQueryInput"
                    placeholder="Search here"
                    maxLength={50}
                  />
                </div>
                <div
                  className=" border mt-2"
                  style={{ maxHeight: "25vh", overflowY: "scroll" }}
                >
                  {contractLists?.filter(
                    (el: any) => el?.status === "COMPLETED"
                  )?.length !== 0 ? (
                    contractLists?.map((contract: any) => {
                      return (
                        <>
                          {contract?.status === "COMPLETED" &&
                            contract?.id !== id && (
                              <h6
                                className={` m-0 p-3 border-bottom ${
                                  activeContract === contract?.id
                                    ? "active_hierarchy"
                                    : ""
                                } `}
                                onClick={() => {
                                  handleContractHierarchy(
                                    contract?.id,
                                    isParentActive,
                                    contract?.name
                                  );
                                  setIsActiveContract(contract?.id);
                                }}
                              >
                                {!!contract &&
                                  contract?.name?.charAt(0)?.toUpperCase() +
                                    contract?.name?.slice(1)?.toLowerCase()}
                              </h6>
                            )}
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h6 className=" m-0 p-3 border-bottom">
                        No contract found!
                      </h6>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default HierarchyAddBtn;
