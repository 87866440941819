import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsThreeDots } from "react-icons/bs";
import { useFolders } from "../FoldersProvider";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TableColumnsInterface } from "../../../interfaces/TableColumnsInterface";
import { routes } from "../../../utils/routes";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { Folder } from "../../../lib/Folder";
import { StatusEnum } from "../../../interfaces/StatusEnum";

function AllFolders() {
  const { id } = useParams();

  const folderContext = useFolders();
  const authContext = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader]: [boolean, Function] = useState(true);
  const [templateAndContractList, setTemplateAndContractList]: [[], Function] =
    useState([]);

  const onDeleteDocument = async (document: any) => {
    if (document) {
      try {
        let requestBody;
        if (document?.type === "TEMPLATE") {
          requestBody = {
            template_ids: [document?.id],
          };

          let rootId = folderContext?.folderList?.path?.find((root: any) => {
            return root?.name === "/";
          });

          let response = await folderContext?.handleMoveToFolderTemplate(
            requestBody,
            rootId?.id
          );

          if (response?.status === 201) {
            let res = await folderContext?.handleFolderById(
              id ? id : rootId?.id
            );
            if (res?.success) {
              if (location.pathname.includes("private")) {
                let privateList = res?.folder?.sub_folders?.filter(
                  (folder: Folder) => {
                    return folder?.access === "PRIVATE";
                  }
                );

                folderContext?.setSubFolders(privateList);
                folderContext?.setFolderList(res?.folder);
              } else if (location.pathname.includes("public")) {
                let publicList = res?.folder?.sub_folders?.filter(
                  (folder: Folder) => {
                    return folder?.access === "PUBLIC";
                  }
                );
                folderContext?.setSubFolders(publicList);
                folderContext?.setFolderList(res?.folder);
              }
            }
          }
        } else if (document?.type === "CONTRACT") {
          requestBody = {
            contract_ids: [document?.id],
          };
          let rootId = folderContext?.folderList?.path?.find((root: any) => {
            return root?.name === "/";
          });

          let response = await folderContext?.handleMoveToFolderContract(
            requestBody,
            rootId?.id
          );

          if (response?.status === 201) {
            let res = await folderContext?.handleFolderById(
              id ? id : rootId?.id
            );
            if (res?.success) {
              if (location.pathname.includes("private")) {
                let privateList = res?.folder?.sub_folders?.filter(
                  (folder: Folder) => {
                    return folder?.access === "PRIVATE";
                  }
                );

                folderContext?.setSubFolders(privateList);
                folderContext?.setFolderList(res?.folder);
              } else if (location.pathname.includes("public")) {
                let publicList = res?.folder?.sub_folders?.filter(
                  (folder: Folder) => {
                    return folder?.access === "PUBLIC";
                  }
                );
                folderContext?.setSubFolders(publicList);
                folderContext?.setFolderList(res?.folder);
              }
            }
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (folderContext?.folderList) {
      let contractLists = folderContext?.folderList?.contracts?.map((item) => ({
        ...item,
        type: "CONTRACT",
      }));
      let templateList: any = folderContext?.folderList?.templates?.map(
        (item) => ({
          ...item,
          type: "TEMPLATE",
        })
      );
      let allLists = contractLists?.concat(templateList);
      setTemplateAndContractList(allLists);
    }
  }, [
    folderContext?.folderList,
    location.pathname.includes("private"),
    location.pathname.includes("public"),
  ]);

  const columns: TableColumnsInterface[] = [
    {
      name: "Document name",
      width: "200px",
      selector: (row: any) => row.name,
      sortable: true,
      grow: 0,
      reorder: true,
    },

    {
      name: "Type",
      selector: (row: any) =>
        row.type.charAt(0).toUpperCase() + row.type.slice(1).toLowerCase(),
      sortable: true,
      grow: 0,
      reorder: true,
    },

    {
      name: "Created by",
      width: "200px",
      selector: (row: any) =>
        row.created_by?.name.charAt(0).toUpperCase() +
        row?.created_by.name.slice(1).toLowerCase() +
        " " +
        row?.created_by?.last_name,
      sortable: true,
      grow: 0,
      reorder: true,
    },
    {
      name: "Department",
      width: "200px",
      selector: (row: any) => {
        return (
          <>
            {row?.department
              ? row?.department === "CXOs"
                ? "Cxo"
                : row?.department?.charAt(0).toUpperCase() +
                  row?.department?.slice(1).toLowerCase()
              : "-"}
          </>
        );
      },
      sortable: true,
      grow: 0,
      reorder: true,
    },
    {
      name: "Updated at",
      width: "200px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      sortable: true,
      grow: 0,
      reorder: true,
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        let collab = row?.collaborators?.find(
          (coll: any) => coll?.user?.sub === authContext?.currentUser?.sub
        );
        return (
          <div className="pt-2 Actions">
            <Dropdown drop="end">
              <Dropdown.Toggle
                variant="success"
                className="Actions_title"
                id="dropdown-basic"
              >
                <BsThreeDots fontSize={24} />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                {collab?.access !== "VIEWER" && (
                  <>
                    {row?.status === StatusEnum?.draft && (
                      <Dropdown.Item
                        className="sort-by"
                        onClick={() => {
                          <>
                            {row?.type === "CONTRACT"
                              ? navigate(
                                  generatePath(routes.editContract, {
                                    id: row.id as string,
                                  })
                                )
                              : navigate(
                                  generatePath(routes.editTemplate, {
                                    id: row.id as string,
                                  })
                                )}
                          </>;
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                    )}
                  </>
                )}

                <Dropdown.Item
                  className="sort-by"
                  onClick={() => {
                    <>
                      {row?.type === "CONTRACT"
                        ? navigate(
                            generatePath(routes.overviewContracts, {
                              id: row.id as string,
                            })
                          )
                        : navigate(
                            generatePath(routes.overview, {
                              id: row.id as string,
                            })
                          )}
                    </>;
                  }}
                >
                  View
                </Dropdown.Item>

                <Dropdown.Item
                  className="sort-by"
                  onClick={() => {
                    onDeleteDocument(row);
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 600);
  }, []);

  return (
    <>
      {loader ? (
        <>loading...</>
      ) : (
        <div className="mx-2 mb-5" style={{ background: "#fefefe" }}>
          <DataTable
            className="data-table"
            columns={columns}
            data={templateAndContractList || []}
            highlightOnHover
            progressComponent={<>Loading...</>}
            paginationDefaultPage={1}
            paginationPerPage={10}
            paginationTotalRows={templateAndContractList?.length}
            pagination
            paginationServer
          />
        </div>
      )}
    </>
  );
}

export default AllFolders;
