import { atom, atomFamily } from "recoil";

import React from "react";

export const SetActiveSuggestionThreadIDContext = React.createContext(null);

export const suggestionThreadsState = atomFamily({
  key: "suggestionThreads",
  default: null,
});

export const suggestionThreadIDsState = atom({
  key: "suggestionThreadIDs",
  default: new Set([]),
});

export const activeSuggestionThreadIDAtom = atom({
  key: "activeSuggestionThreadID",
  default: null,
});
