export enum StatusEnum {
  draft = "DRAFT",
  published = "PUBLISHED",
  pending_approve = "PENDING_APPROVAL",
  pending_negotitation = "PENDING_NEGOTIATION",
  pending_negotitation_approval = "PENDING_NEGOTIATION_APPROVAL",
  approved = "APPROVED",
  stamp_initiated = "STAMP_INITIATED",
  stamp_failed = "STAMPING_FAILED",
  stamped = "STAMPED",
  sign_initiated = "SIGN_INITIATED",
  completed = "COMPLETED",
  rejected = "REJECTED",
  expired = "EXPIRED",
}
