import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";

function WaterMark({
  context,
}: {
  context?: TemplateContextInterface | ContractContextInterface;
}) {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.04)",
        fontSize: `3rem`,
        fontWeight: "bold",
        textTransform: "uppercase",
        transform: "rotate(-45deg)",
        userSelect: "none",
        position: "relative",
        zIndex: 11,
      }}
    >
      {context?.waterMarkText}
    </div>
  );
}

export default WaterMark;
