import { Template } from "../../../lib/Template";
import Avatar from "react-avatar";
import { Fragment, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { Collaborator } from "../../../lib/Collaborator";
import { StatusEnum } from "../../../interfaces/StatusEnum";
import { updateText } from "../../../utils";

function RecentTemplatesList({
  template,
  setIsActive,
  isAction,
  setSelectedTemplateId,
  selectedTemplateId,
  isCreateContractPopup,
  setMoveToFolders,
}: {
  template: Template;
  setIsActive?: Function;
  isAction?: boolean;
  setSelectedTemplateId?: Function;
  selectedTemplateId?: string;
  isCreateContractPopup?: boolean;
  setMoveToFolders: Function;
}) {
  function onCardSelect(key: string | undefined) {
    switch (key) {
      case selectedTemplateId:
        return "card-active";
      default:
        if (isCreateContractPopup) {
          return "is-create-contract-popup";
        }
        return "";
    }
  }
  const location = useLocation();
  const loggedInContext = useAuthentication();
  const [approvalUserDetail, setApprovalUserDetail]: [
    Template | undefined,
    Function
  ] = useState();

  const [collaboratorsDetail, setcollaboratorsDetail]: [
    Collaborator | undefined,
    Function
  ] = useState();

  useEffect(() => {
    if (template?.approvers?.length && loggedInContext?.currentUser) {
      let users = template?.approvers?.find((user: Template) => {
        return user?.user?.sub === loggedInContext?.currentUser?.sub;
      });

      let collab = template?.collaborators?.find((coll: Collaborator) => {
        return coll?.user?.sub == loggedInContext?.currentUser?.sub;
      });

      setcollaboratorsDetail(collab);
      setApprovalUserDetail(users);
    }
  }, [template?.approvers?.length, loggedInContext?.currentUser]);

  //!!setIsActive && setIsActive(!isAction);

  return (
    <>
      <div
        className={` py-1 card-width `}
        onClick={() => {
          !!setIsActive && setIsActive(!isAction);
        }}
      >
        <div
          className={`bg-contract contract-division p-4 br-4 ${onCardSelect(
            template?.id
          )}`}
          onClick={() => {
            !!setSelectedTemplateId && setSelectedTemplateId(template?.id);
            {
              location.pathname.includes("folder") && setMoveToFolders(false);
            }
          }}
        >
          <div
            className="card-contract-name"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={template?.name}
          >
            <span>{template?.formattedName(20)}</span>
          </div>
          <div className=" py-2 d-flex justify-content-between align-items-center">
            <div className="contract-team-name m-0 p-0">
              <span className="pro-names p-0">{template?.department}</span>
            </div>
            {!isCreateContractPopup && (
              <div className="  ">
                <span className="profileAvatar mx-1">
                  {template?.collaborators &&
                    !!template?.collaborators?.length && (
                      <>
                        {template?.collaborators?.map(
                          (collaboratorsName: any, index: number) => {
                            return (
                              index <= 1 && (
                                <Fragment key={index}>
                                  <Avatar
                                    name={collaboratorsName?.user?.name?.charAt(
                                      0
                                    )}
                                    className="profile-avatars"
                                    size="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={updateText(
                                      `${collaboratorsName?.user?.name}${" "}${
                                        collaboratorsName?.user?.last_name
                                      }`
                                    )}
                                  />
                                </Fragment>
                              )
                            );
                          }
                        )}
                      </>
                    )}
                  &nbsp;&nbsp;
                  <span className="profileAvatar">
                    {template?.collaborators &&
                      template?.collaborators?.length > 2 && (
                        <>
                          {"+"}
                          {template?.collaborators?.length - 2}
                        </>
                      )}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className=" card-signature mt-2 d-flex justify-content-start">
            {template?.status === StatusEnum?.stamped ? (
              "Stamped"
            ) : template?.status === StatusEnum?.published ? (
              "Published"
            ) : template?.status === "COMPLETED" ? (
              "Signed"
            ) : template?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub === template?.createdBy?.sub ? (
              "Sign initiated"
            ) : template?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub !== template?.createdBy?.sub ? (
              "Sign required"
            ) : template?.status === StatusEnum?.stamp_initiated ? (
              "Stamp initiated"
            ) : template?.status === StatusEnum?.stamp_failed ? (
              "E-stamp failed"
            ) : template?.status === "PENDING" ? (
              "Paused"
            ) : template?.status === StatusEnum?.rejected ? (
              "Rejected"
            ) : (
              <>
                {approvalUserDetail?.status === StatusEnum?.approved &&
                loggedInContext?.currentUser?.sub !==
                  template?.createdBy?.sub ? (
                  "Approved"
                ) : (
                  <>
                    {(loggedInContext?.currentUser?.sub !==
                      template?.createdBy?.sub &&
                      approvalUserDetail?.status) === StatusEnum?.approved &&
                      "Approved"}
                    {loggedInContext?.currentUser?.sub !==
                      template?.createdBy?.sub &&
                    template?.status === StatusEnum?.pending_approve
                      ? "Approval required"
                      : loggedInContext?.currentUser?.sub !==
                          template?.createdBy?.sub &&
                        approvalUserDetail?.status === "PENDING" &&
                        "Approval required"}
                  </>
                )}
              </>
            )}

            {template?.status !== StatusEnum?.draft && collaboratorsDetail && (
              <>&nbsp;</>
            )}
            {loggedInContext?.currentUser?.sub === template?.createdBy?.sub &&
              template?.status === StatusEnum?.pending_approve &&
              "Pending for approval"}

            {template?.status === StatusEnum?.draft && "Draft"}
          </div>
          <div className="mb-2 row">
            <div className=" col-12">
              <ProgressBar
                now={
                  template?.status === StatusEnum?.draft
                    ? 0
                    : template?.status === StatusEnum?.published
                    ? 100
                    : template?.status === StatusEnum?.pending_approve
                    ? 50
                    : template?.status === StatusEnum?.approved
                    ? 70
                    : template?.status === StatusEnum?.sign_initiated
                    ? 85
                    : template?.status === "COMPLETED"
                    ? 100
                    : 0
                }
                className={`progress-bar-css ${
                  template?.status === StatusEnum?.draft
                    ? "draft_bar"
                    : template?.status === StatusEnum?.published
                    ? "publish_bar"
                    : template?.status === StatusEnum?.pending_approve
                    ? "pending_approval_bar"
                    : template?.status === StatusEnum?.approved
                    ? "approval"
                    : template?.status === StatusEnum?.sign_initiated
                    ? "sign_bar"
                    : template?.status === "COMPLETED"
                    ? "complete_bar"
                    : ""
                }`}
              />
            </div>
          </div>

          {!isCreateContractPopup && (
            <div className="row">
              <div className="card-clock  pt-3 col-12">
                <div className="card-update-date px-3 py-2 ">
                  {!isCreateContractPopup ? "Updated on : " : "Published on :"}

                  <span className="textBlack ms-1">
                    {template?.formattedUpdatedAt()}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecentTemplatesList;
