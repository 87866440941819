import { useCallback, useEffect, useRef } from "react";
import { Editor, Range } from "slate";
import { useFocused, useSlate } from "slate-react";
import { Menu, Portal } from "./components";
import { css } from "@emotion/css";
import { FormatButton } from "./ToolbarButton";
// import { Button } from "antd";
import { useSetRecoilState } from "recoil";
import { activeCommentThreadIDAtom } from "./comments/src/utils/CommentState";
import useAddCommentThreadCallback from "./comments/src/hooks/useAddCommentThreadCallback";
import { insertCommentThread } from "./comments/src/utils/EditorCommnentUtils";
import { useEditor } from "../EditorProvider";
import { BiBold } from "react-icons/bi";
import { LiaUnderlineSolid } from "react-icons/lia";
import { PiTextItalicLight } from "react-icons/pi";
import { BsCardText } from "react-icons/bs";

const { Button } = require("antd");

export const HoveringToolbar = () => {
  const ref = useRef<HTMLDivElement | null>();
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection: any = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = "1";
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  const context = useEditor();
  const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);
  const addCommentThread = useAddCommentThreadCallback();

  const onInsertComment = useCallback(() => {
    const newCommentThreadID: any = insertCommentThread(
      context?.editor,
      addCommentThread
    );
    setActiveCommentThreadID(newCommentThreadID);
  }, [context?.editor, addCommentThread, setActiveCommentThreadID]);

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: white;
          box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          transition: opacity 0.75s;
          // min-width: 235px;
        `}
        onMouseDown={(e: any) => {
          e.preventDefault();
        }}
      >
        <FormatButton format="bold" icon={<BiBold fontSize={20} />} />
        <FormatButton
          format="italic"
          icon={<PiTextItalicLight fontSize={20} />}
        />
        <FormatButton
          format="underline"
          icon={<LiaUnderlineSolid fontSize={19} />}
        />
        {/* <FormatButton
          format="comment"
          icon={<MdComment size={24} />}
          onMouseDown={onInsertComment}
        /> */}
        <Button
          icon={<BsCardText fontSize={18} />}
          // disabled={!shouldAllowNewCommentThreadAtSelection(context?.editor, context?.editor?.selection)}
          onMouseDown={onInsertComment}
          size="large"
        />
      </Menu>
    </Portal>
  );
};
