import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface StyleProp {
  width: string;
  height?: string;
}

const RecentTemplateSkeleton = () => {
  const avatarStyle: StyleProp = {
    width: "50px",
    height: "50px",
  };

  const titleStyle: StyleProp = {
    width: "100%",
  };
  return (
    <>
      <SkeletonTheme baseColor="lightgray">
        <div className="py-1 card-width ">
          <div className="bg-contract contract-division p-4 br-4">
            <div className="d-flex mb-1">
              <Skeleton style={avatarStyle} />
              <div className="flex-grow-1 ps-3">
                <Skeleton style={titleStyle} />
                <Skeleton style={titleStyle} />
              </div>
            </div>
            <div className="card-content">
              <Skeleton />
            </div>
            <div className=" card-signature">
              <Skeleton />
            </div>
            <Skeleton />
            <Skeleton />
            <div className="row">
              <div className="contract-team-name">
                <Skeleton />
              </div>
            </div>
            <div className="row">
              <Skeleton />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default RecentTemplateSkeleton;
