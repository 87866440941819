import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  Stack,
} from "react-bootstrap";
import { api } from "../../../utils/api";
import { useFolders } from "../FoldersProvider";
import { useLocation, useParams } from "react-router-dom";
import { Folder } from "../../../lib/Folder";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { FaFolder } from "react-icons/fa";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";

function MoveToFolders({
  show,
  onHide,
  isActiveMove,
  setShowFailureToast,
  setBodyMessage,
  activeFolder,
}: {
  show: boolean;
  onHide: Function;
  isActiveMove: string;
  setShowFailureToast: Function;
  setBodyMessage: Function;
  activeFolder: any;
}) {
  const folderContext = useFolders();
  const { id } = useParams();
  const location = useLocation();
  const authContext = useAuthentication();
  const [onSelectDocument, setOnSelectDocument]: [string[], Function] =
    useState([]);
  const [documentDetail, setDocumentDetail]: [[], Function] = useState([]);
  const [documentList, setDocumentList]: [[], Function] = useState([]);
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [activeFolderName, setActiveFolder]: [any, Function] = useState("");
  const [isActive, setIsActive]: [boolean, Function] = useState(false);

  const onGetTemplate = async () => {
    try {
      setLoader(true);
      let response = await api.getTemplateList();

      response = response?.template?.map(
        (item: { folder: { name: string; id: string }[] }) => ({
          name: item?.folder[0]?.name,
          id: item?.folder[0]?.id,
        })
      );

      let newset = response.filter(
        (value: any, index: any, self: any) =>
          index ===
          self.findIndex((t: any) => t.name === value.name && t.id === value.id)
      );
      setLoader(false);
      setDocumentDetail(newset);
    } catch (error) {}
  };

  const onGetContracts = async () => {
    try {
      try {
        setLoader(true);
        let response = await api.getContractList();

        response = response?.contracts?.map(
          (item: { folder: { name: string; id: string }[] }) => ({
            name: item?.folder[0]?.name,
            id: item?.folder[0]?.id,
          })
        );

        let newset = response.filter(
          (value: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (t: any) => t.name === value.name && t.id === value.id
            )
        );
        setLoader(false);

        setDocumentDetail(newset);
      } catch (error) {}
    } catch (error) {}
  };

  const onHandleMoveToFolder = async () => {
    try {
      let requestBody;
      if (isActiveMove === "template") {
        requestBody = {
          template_ids: onSelectDocument,
        };
      } else if (isActiveMove === "contract") {
        requestBody = {
          contract_ids: onSelectDocument,
        };
      }
      let response;
      if (isActiveMove === "template") {
        response = await folderContext?.handleMoveToFolderTemplate(
          requestBody,
          id ? id : folderContext?.folderList?.id
        );
        if (response?.status === 201) {
          setOnSelectDocument([]);
        }

        setOnSelectDocument([]);
      } else if (isActiveMove === "contract") {
        response = await folderContext?.handleMoveToFolderContract(
          requestBody,
          id ? id : folderContext?.folderList?.id
        );
        if (response?.status === 201) {
          setOnSelectDocument([]);
        }
      }

      if (response?.status === 201) {
        onHide();
        let res = await folderContext?.handleFolderById(id);
        if (res?.success) {
          if (location.pathname.includes("private")) {
            let privateList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PRIVATE";
              }
            );

            folderContext?.setSubFolders(privateList);
            folderContext?.setFolderList(res?.folder);
          } else if (location.pathname.includes("public")) {
            let publicList = res?.folder?.sub_folders?.filter(
              (folder: Folder) => {
                return folder?.access === "PUBLIC";
              }
            );
            folderContext?.setSubFolders(publicList);
            folderContext?.setFolderList(res?.folder);
          }
        }
      } else {
        setShowFailureToast(true);
        setBodyMessage("File should belong to one folder");
      }
    } catch (error) {}
  };

  const onGetFolderId = async (folderId: string | undefined) => {
    try {
      let response = await folderContext?.handleFolderById(folderId);

      if (response?.success) {
        if (isActiveMove === "template") {
          setDocumentList(response?.folder?.templates);
        } else if (isActiveMove === "contract") {
          setDocumentList(response?.folder?.contracts);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isActiveMove === "template") {
      onGetTemplate();
    } else if (isActiveMove === "contract") {
      onGetContracts();
    }
  }, []);

  return (
    <Modal show={show} centered onHide={() => onHide()}>
      <Modal.Body className="p-4">
        <Stack>
          <h5 className="fw-semibold py-2">Move to folder </h5>
        </Stack>
        {/* ..................................... */}
        <div className="fw-semibold py-2">
          All {isActiveMove === "template" ? "templates" : "contracts"}
        </div>
        {activeFolderName && (
          <>
            <div>
              <IoArrowBack
                fontSize={20}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  isActiveMove === "template"
                    ? onGetTemplate()
                    : onGetContracts();
                  setIsActive(false);
                  setActiveFolder("");
                  setDocumentList([]);
                }}
              />
              <span className="ms-2 fw-semibold">{activeFolderName}</span>
            </div>
            <hr className="mt-2"></hr>
          </>
        )}
        {/* folder */}
        {!isActive &&
          documentDetail?.map((list: any) => {
            return (
              <>
                <div
                  key={list?.id}
                  className="d-flex  movesc p-2 myDIV"
                  onClick={() => {
                    setIsActive(true);
                    onGetFolderId(list?.id);
                    setActiveFolder(
                      list?.name === "/"
                        ? isActiveMove === "template"
                          ? "Templates"
                          : "Contracts"
                        : list?.name
                    );
                  }}
                >
                  <div className="d-flex">
                    <div className="myDIV">
                      <FaFolder fontSize={20} />
                      <span className="ms-2">
                        {list?.name === "/" ? (
                          <>
                            {isActiveMove === "template"
                              ? "Templates"
                              : "Contracts"}
                          </>
                        ) : (
                          list?.name
                        )}
                      </span>
                    </div>

                    <div className="hide">
                      Move <MdOutlineArrowForwardIos />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {isActive &&
          documentList
            ?.filter(
              (el: any) =>
                el?.created_by?.email === authContext?.currentUser?.email
            )
            ?.map((list: any) => {
              return (
                <>
                  <div
                    key={list?.id}
                    // className="d-flex  movesc p-2"
                    onClick={() => {
                      if (onSelectDocument?.includes(list?.id)) {
                        let newArray = onSelectDocument?.filter(
                          (item) => item !== list?.id
                        );
                        setOnSelectDocument([...newArray]);
                      } else {
                        setOnSelectDocument([...onSelectDocument, list?.id]);
                      }
                    }}
                    className={`d-flex  movesc p-2 mb-2 mt-2 ${
                      onSelectDocument?.includes(list?.id)
                        ? "list_group_class "
                        : ""
                    }`}
                    style={{
                      background:
                        list?.status === "DRAFT" &&
                        list?.department == undefined
                          ? "#ebebeb"
                          : list?.department !== activeFolder?.department
                          ? "rgb(233 196 196)"
                          : "",
                      cursor:
                        list?.status === "DRAFT" &&
                        list?.department == undefined
                          ? "not-allowed"
                          : list?.department !== activeFolder?.department
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <div className="d-flex">
                      <div className="myDIV">
                        <FaFolder fontSize={20} />
                        <span className="ms-2">{list?.name}</span>
                      </div>

                      {/* <div className="hide">
                        Move <MdOutlineArrowForwardIos />
                      </div> */}
                    </div>
                  </div>
                </>
              );
            })}
        {/* ............................... */}
        <div style={{ height: "30vh", overflow: "scroll", display: "none" }}>
          {loader ? (
            <span className="loaderBtn"></span>
          ) : (
            <Accordion>
              {documentDetail?.length === 0 ? (
                <div className="no-data-found move_to_folder">
                  No Data Found
                </div>
              ) : (
                <>
                  {documentDetail?.map((list: any) => {
                    return (
                      <>
                        <Accordion.Item eventKey={list?.id}>
                          <Accordion.Header
                            onClick={() => {
                              onGetFolderId(list?.id);
                            }}
                          >
                            {list?.name === "/" ? (
                              <>
                                {isActiveMove === "template"
                                  ? "Templates"
                                  : "Contracts"}{" "}
                                {/* list */}
                              </>
                            ) : (
                              list?.name
                            )}
                          </Accordion.Header>

                          <Accordion.Body>
                            <ListGroup>
                              {documentList
                                ?.filter(
                                  (el: any) =>
                                    el?.created_by?.email ===
                                    authContext?.currentUser?.email
                                )
                                ?.map((list: any) => {
                                  return (
                                    <>
                                      <ListGroupItem>
                                        {list?.folder?.[0]?.name === "/"
                                          ? "Root folder"
                                          : list?.folder?.[0]?.name}
                                      </ListGroupItem>

                                      <ListGroupItem
                                        key={list?.id}
                                        style={{
                                          background:
                                            list?.status === "DRAFT" &&
                                            list?.department == undefined
                                              ? "#ebebeb"
                                              : list?.department !==
                                                activeFolder?.department
                                              ? "rgb(233 196 196)"
                                              : "",
                                          cursor:
                                            list?.status === "DRAFT" &&
                                            list?.department == undefined
                                              ? "not-allowed"
                                              : list?.department !==
                                                activeFolder?.department
                                              ? "not-allowed"
                                              : "pointer",
                                        }}
                                        disabled={
                                          list?.department !==
                                            activeFolder?.department ||
                                          (list?.status === "DRAFT" &&
                                            list?.department == undefined)
                                        }
                                        onClick={() => {
                                          if (
                                            onSelectDocument?.includes(list?.id)
                                          ) {
                                            let newArray =
                                              onSelectDocument?.filter(
                                                (item) => item !== list?.id
                                              );
                                            setOnSelectDocument([...newArray]);
                                          } else {
                                            setOnSelectDocument([
                                              ...onSelectDocument,
                                              list?.id,
                                            ]);
                                          }
                                        }}
                                        className={`${
                                          onSelectDocument?.includes(list?.id)
                                            ? "list_group_class "
                                            : ""
                                        }`}
                                      >
                                        <span>{list?.name}</span>
                                      </ListGroupItem>
                                    </>
                                  );
                                })}
                            </ListGroup>
                          </Accordion.Body>
                        </Accordion.Item>
                      </>
                    );
                  })}
                </>
              )}
            </Accordion>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelbtn me-3 " size="sm" onClick={() => onHide()}>
          Cancel
        </Button>
        <Button
          className="sharebtndanger"
          size="sm"
          onClick={onHandleMoveToFolder}
        >
          Move
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MoveToFolders;
