import { CustomBaseElement, Editor } from "slate";

const withVariable = (editor: Editor) => {
  const { 
    isInline, 
    isVoid, 
    markableVoid 
  } = editor;

  editor.isInline = (element: CustomBaseElement|any) => {
    return element.type === "variable" ? true : isInline(element);
  };

  editor.isVoid = (element: CustomBaseElement|any) => {
    return element.type === "variable" ? true : isVoid(element);
  };

  editor.markableVoid = (element: CustomBaseElement|any) => {
    return element.type === "variable" || markableVoid(element);
  };

  return editor;
};

export default withVariable;
