import AppLayout from "../../layouts/AppLayout";
import { MembersProvider, useMember } from "./MemberProvider";
import { useEffect, useState } from "react";
import MemberHeader from "../Members/components/MemberHeader";
import MembersTable from "../Members/components/MembersTable";
import FailureToast from "../../ui/FailureToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import SuccessToast from "../../ui/SuccessToast";
import { api } from "../../utils/api";
import InviteMember from "./InviteMember";

const ListMembers = () => {
  return (
    <AppLayout pageTitle="CLM - Members">
      <MembersProvider>
        <NoMemberData />
      </MembersProvider>
    </AppLayout>
  );
};

export default ListMembers;

const NoMemberData = () => {
  const context = useMember();
  const [memberList, setMemberList]: [[], Function] = useState([]);
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [searchText, setSearchText]: [string, Function] = useState("");
  const [limit, setLimit]: [any, Function] = useState(10);
  const [page, setPage]: [any, Function] = useState(1);
  const [metaData, setMetaData]: [any, Function] = useState();
  const [deleteId, setDeleteId] = useState();
  const [memberDetail, setMemberDetail]: [boolean, Function] = useState(false);
  const [memberDetails, setMemberDetails]: [boolean, Function] =
    useState(false);
  const [selectedMemberId, setSelectedMemberId]: [any, Function] = useState();
  const [isResendInvite, setIsResendInvite]: [boolean, Function] =
    useState(false);

  const getListMembers = async () => {
    try {
      let response = await api?.listmember({
        search: searchText ? searchText : null,
        limit: limit ? limit : null,
        page: page ? page : null,
      });
      if (response?.success) {
        setMemberList(response?.users);
        setMetaData(response?.meta);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getListMembers();
  }, [searchText, limit, page]);

  useEffect(() => {
    context?.changeFilter({ ...context.filterParams });
  }, []);

  const deleteMember = async (id: string) => {
    const res = await context?.deleteUser(id);
    if (res?.success) {
      setShowSuccessToast(true);
      setBodyMessage("Deleted successfully");
      getListMembers();
    }
  };

  return (
    <>
      <MemberHeader
        setShowFailureToast={setShowFailureToast}
        setShowSuccessToast={setShowSuccessToast}
        setBodyMessage={setBodyMessage}
        getListMembers={getListMembers}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      <MembersTable
        setMemberDetails={setMemberDetails}
        setMemberDetail={setMemberDetail}
        setSelectedMemberId={setSelectedMemberId}
        memberList={memberList}
        setLimit={setLimit}
        setPage={setPage}
        metaData={metaData}
        deleteMember={deleteMember}
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        setShowFailureToast={setShowFailureToast}
        setShowSuccessToast={setShowSuccessToast}
        setBodyMessage={setBodyMessage}
        getListMembers={getListMembers}
        setIsResendInvite={setIsResendInvite}
      />
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage ? bodyMessage : "Successfully deleted!"}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {memberDetail && (
        <InviteMember
          isView={true}
          onHide={() => {
            setMemberDetail(false);
          }}
          selectedMemberId={selectedMemberId}
          show={memberDetail}
          setShow={setMemberDetail}
          setShowSuccessToast={setShowSuccessToast}
          setShowFailureToast={setShowFailureToast}
          setBodyMessage={setBodyMessage}
          getListMembers={getListMembers}
          setIsResendInvite={setIsResendInvite}
          isResendInvite={isResendInvite}
        />
      )}
      {memberDetails && (
        <InviteMember
          isEdit={true}
          onHide={() => {
            setMemberDetail(false);
          }}
          selectedMemberId={selectedMemberId}
          show={memberDetails}
          setShow={setMemberDetails}
          setShowSuccessToast={setShowSuccessToast}
          setShowFailureToast={setShowFailureToast}
          setBodyMessage={setBodyMessage}
          getListMembers={getListMembers}
          setIsResendInvite={setIsResendInvite}
          isResendInvite={isResendInvite}
        />
      )}
    </>
  );
};
