import { useEffect, useState } from "react";
import AppLayout from "../../layouts/AppLayout";
import NoDataFound from "../../ui/NoDataFound";
import RecentTemplates from "./components/RecentTemplates";
import TemplateHeader from "./components/TemplateHeader";
import Templates from "./components/Templates";
import {
  ListTemplateContextInterface,
  ListTemplatesProvider,
  useTemplates,
} from "./ListTemplatesProvider";
import TemplateCategoryNav from "./components/TemplateCategoryNav";
import { useLocation } from "react-router-dom";
import { Template } from "../../lib/Template";
import Loader from "../Contracts/Loader";
import MoveToFolderPopup from "./components/MoveToFolderPopup";
import SuccessToast from "../../ui/SuccessToast";
import { MdCheckCircle } from "react-icons/md";
import { IFolderResponse } from "../../interfaces/response/IFolderResponse";

const ListTemplates = () => {
  return (
    <AppLayout pageTitle="CLM - Templates">
      <ListTemplatesProvider>
        <NoTemplateData />
      </ListTemplatesProvider>
    </AppLayout>
  );
};

export default ListTemplates;

const NoTemplateData = () => {
  const context: ListTemplateContextInterface | null = useTemplates();
  const location = useLocation();
  const [moveToFolders, setMoveToFolders]: [boolean, Function] =
    useState(false);
  const [selectedValues, setSelectedValues]: [Template[], Function] = useState(
    []
  );
  const [selectedId, setSelectedId]: [string, Function] = useState("");
  const [privateFolderList, setPrivateFolderList]: [Template[], Function] =
    useState([]);
  const [publicFolderList, setPublicFolderList]: [Template[], Function] =
    useState([]);

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    context?.changeFilter({ ...context.filterParams });
  }, []);

  const onHandleRootFolder = async () => {
    try {
      let response = await context?.handleRootFolder();
      if (response?.folder?.sub_folders) {
        let privateList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PRIVATE";
          }
        );
        let publicFoldersList = response?.folder?.sub_folders?.filter(
          (folder: IFolderResponse) => {
            return folder?.access === "PUBLIC";
          }
        );

        setPrivateFolderList(privateList);
        setPublicFolderList(publicFoldersList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    onHandleRootFolder();
  }, []);

  return (
    context && (
      <>
        {!context?.recentTemplates?.length ? (
          <>
            <TemplateHeader setMoveToFolders={setMoveToFolders} />
            {context?.loader ? (
              <Loader />
            ) : (
              <NoDataFound search={location.search} />
            )}
          </>
        ) : (
          <>
            {context?.recentTemplates?.length === 0 ? (
              <NoDataFound />
            ) : (
              <>
                <TemplateHeader setMoveToFolders={setMoveToFolders} />
                <RecentTemplates
                  isCreateContractPopup={false}
                  setMoveToFolders={setMoveToFolders}
                  moveToFolders={moveToFolders}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                />
                <div className="">
                  <TemplateCategoryNav context={context} />
                  <Templates
                    setMoveToFolders={setMoveToFolders}
                    setSelectedId={setSelectedId}
                  />
                </div>
              </>
            )}
          </>
        )}

        {moveToFolders && (
          <MoveToFolderPopup
            show={moveToFolders}
            // selectedValues={selectedValues}
            privateFolderList={privateFolderList}
            publicFolderList={publicFolderList}
            setShowSuccessToast={setShowSuccessToast}
            setBodyMessage={setBodyMessage}
            onHide={() => setMoveToFolders(false)}
            setPrivateFolderList={setPrivateFolderList}
            setPublicFolderList={setPublicFolderList}
            onHandleRootFolder={onHandleRootFolder}
            context={context}
            selectedId={selectedId}
          />
        )}

        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
      </>
    )
  );
};
