import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AdminHeader from "../../../layouts/AdminHeader";
import { adminRoutes } from "../../../utils/adminRoutes";
import AccountInformation from "./Accountinfotmation";
import { ListAdminAccountsProvider } from "./AdminAccountProvider";
import ListTemplatesDetail from "./Templates/ListTemplatesDetail";
import ListContractsDetail from "./Contracts/ListContractsDetail";
import AdminAccountMembersPage from "./AccountMembers/AdminAccountMembersPage";
import { ContractProvider } from "./Contracts/ContractProvider";

function AccountView() {
  const location = useLocation();
  const { accountId: id }: any = useParams();
  const [isActiveNavbar, setIsActiveNavbar]: [string, Function] =
    useState("information");

  useEffect(() => {
    if (location.pathname.includes("templates")) {
      setIsActiveNavbar("Templates");
    } else if (location.pathname.includes("folders")) {
      setIsActiveNavbar("Folders");
    } else if (location.pathname.includes("contracts")) {
      setIsActiveNavbar("Contracts");
    } else if (location.pathname.includes("accountMembers")) {
      setIsActiveNavbar("Members");
    } else if (location.pathname.includes("accountInformation")) {
      setIsActiveNavbar("information");
    }
  }, [location.pathname]);
  return (
    <AdminHeader>
      <>
        <div style={{ paddingTop: "90px" }}>
          <div style={{ overflowX: "scroll" }}>
            <div className="d-flex mb-4 ms-4" style={{ maxWidth: "600px" }}>
              <div
                style={{ minWidth: "155px" }}
                onClick={() => {
                  setIsActiveNavbar("information");
                }}
              >
                <Link
                  to={adminRoutes?.urlAdminAccountInformation(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "information" ? " active" : ""
                  }`}
                >
                  Account information
                </Link>
              </div>
              {/* <div style={{ minWidth: "130px" }}>
                <Link
                  to={adminRoutes?.urlAdminAccountMembers(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "Dashboard" ? " active" : ""
                  }`}
                  onClick={() => {
                    setIsActiveNavbar("Dashboard");
                  }}
                >
                  Dashboard
                </Link>
              </div> */}
              <div
                style={{ minWidth: "60px" }}
                onClick={() => {
                  setIsActiveNavbar("Members");
                }}
              >
                <Link
                  to={adminRoutes?.urlAdminAccountMembers(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "Members" ? " active" : ""
                  }`}
                >
                  Users
                </Link>
              </div>
              <div
                style={{ minWidth: "60px" }}
                onClick={() => {
                  setIsActiveNavbar("Contracts");
                }}
              >
                <Link
                  to={adminRoutes?.urlAdminContracts(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "Contracts" ? " active" : ""
                  }`}
                >
                  Contracts
                </Link>
              </div>
              <div
                style={{ minWidth: "90px" }}
                onClick={() => {
                  setIsActiveNavbar("Templates");
                }}
              >
                <Link
                  to={adminRoutes?.urlAdminTemplates(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "Templates" ? " active" : ""
                  }`}
                >
                  Templates
                </Link>
              </div>
              {/* <div
                style={{ minWidth: "90px" }}
                onClick={() => {
                  setIsActiveNavbar("Folders");
                }}
              >
                <Link
                  to={adminRoutes?.urlAdminFolders(id)}
                  className={`border-0 header-css me-4 ${
                    isActiveNavbar === "Folders" ? " active" : ""
                  }`}
                >
                  Folders
                </Link>
              </div> */}
            </div>
          </div>

          {/* <Nav variant="tabs " className="nasted_accounts">
            <Nav.Item>
              <Nav.Link
                eventKey="link-0"
                className={`nasted_accounts_items ml-3 ${
                  isActiveNavbar === "information" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActiveNavbar("information");
                  navigate(adminRoutes.urlAdminAccountInformation(id));
                }}
              >
                <span
                  className={` ${
                    isActiveNavbar === "information"
                      ? "nasted_accounts_lists"
                      : ""
                  }`}
                >
                  Account information
                </span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                eventKey="link-3"
                className={`nasted_accounts_items ${
                  isActiveNavbar === "Members" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActiveNavbar("Members");
                  navigate(adminRoutes.urlAdminAccountMembers(id));
                }}
              >
                <span
                  className={` ${
                    isActiveNavbar === "Members" ? "nasted_accounts_lists" : ""
                  }`}
                >
                  Members
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="link-4"
                className={`nasted_accounts_items ${
                  isActiveNavbar === "Contracts" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActiveNavbar("Contracts");
                  navigate(adminRoutes.urlAdminContracts(id));
                }}
              >
                <span
                  className={` ${
                    isActiveNavbar === "Contracts"
                      ? "nasted_accounts_lists"
                      : ""
                  }`}
                >
                  Contracts
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="link-5"
                className={`nasted_accounts_items ${
                  isActiveNavbar === "Templates" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActiveNavbar("Templates");
                  navigate(adminRoutes.urlAdminTemplates(id));
                }}
              >
                <span
                  className={` ${
                    isActiveNavbar === "Templates"
                      ? "nasted_accounts_lists"
                      : ""
                  }`}
                >
                  Templates
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="link-6"
                className={`nasted_accounts_items ${
                  isActiveNavbar === "Folders" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActiveNavbar("Folders");
                  navigate(adminRoutes.urlAdminFolders(id));
                }}
              >
                <span
                  className={` ${
                    isActiveNavbar === "Folders" ? "nasted_accounts_lists" : ""
                  }`}
                >
                  Folders
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
        </div>

        {isActiveNavbar === "Contracts" && (
          <ContractProvider>
            <ListContractsDetail />
          </ContractProvider>
        )}
        {isActiveNavbar === "Templates" && <ListTemplatesDetail />}
        {/* {isActiveNavbar === "Folders" && <Folders />} */}
        {isActiveNavbar === "Members" && <AdminAccountMembersPage />}
        {isActiveNavbar === "information" && (
          <ListAdminAccountsProvider>
            <AccountInformation />
          </ListAdminAccountsProvider>
        )}
      </>
    </AdminHeader>
  );
}

export default AccountView;
