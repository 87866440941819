import { useLocation } from "react-router-dom";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { useVariables } from "./VariableProvider";
import { useEditor } from "../../editor/EditorProvider";

const TextVariableInput = ({
  variable,
  value,
  onChange,
}: {
  variable?: IVariableResponse;
  value?: string;
  onChange?: Function;
}) => {
  const context = useVariables();
  const editorContext = useEditor();

  const selectedVariable = editorContext?.variables.find(
    ({ id }: any) => variable?.id === id
  );
  const params = useLocation();

  const triggerOnChange = (value: string) => {
    if (variable) {
      context?.update(variable.id, {
        value: value,
      });
    }
  };

  return (
    context && (
      <>
        {onChange
          ? params?.pathname?.includes("contracts") && (
              <input
                value={value || ""}
                className="form-control"
                onChange={(e) => onChange(e.target.value)}
                maxLength={250}
                disabled={params?.search === "?suggestion=true"}
              />
            )
          : params?.pathname?.includes("contracts") && (
              <input
                defaultValue={selectedVariable?.value || variable?.value || ""}
                className="form-control"
                onChange={(e) => triggerOnChange(e.target.value)}
                maxLength={250}
                disabled={params?.search === "?suggestion=true"}
              />
            )}
      </>
    )
  );
};

export default TextVariableInput;
