import "./CommentedText.css";

import {
  activeCommentThreadIDAtom,
  commentThreadsState,
} from "../utils/CommentState";
import classNames from "classnames";
import { useSlate } from "slate-react";
import { useRecoilState, useRecoilStateLoadable } from "recoil";
import { getSmallestCommentThreadAtTextNode } from "../utils/EditorCommnentUtils";
import { useAuthentication } from "../../../../../modules/Authentication/AuthenticationProvider";
import { CustomEditor } from "../../../../types/custom-types";

export default function CommentedText(props: any) {
  const editor: CustomEditor = useSlate();
  const authContext = useAuthentication();
  const { commentThreads, textNode, ...otherProps } = props;
  const [activeCommentThreadID, setActiveCommentThreadID] = useRecoilState(
    activeCommentThreadIDAtom
  );
  const [threadDataLoadable] = useRecoilStateLoadable(
    commentThreadsState(getSmallestCommentThreadAtTextNode(editor, textNode))
  );

  const onClick = () => {
    setActiveCommentThreadID(
      getSmallestCommentThreadAtTextNode(editor, textNode)
    );
  };

  return (
    <>
      {authContext?.currentUser?.id ===
        threadDataLoadable?.contents?.comments[0]?.authorID &&
      threadDataLoadable?.contents?.access === "PRIVATE" ? (
        <span
          {...otherProps}
          className={classNames({
            comment: true,
            "is-active": commentThreads.has(activeCommentThreadID),
            "is-resolved": threadDataLoadable?.contents?.status === true,
          })}
          onClick={onClick}
        >
          {props.children}
        </span>
      ) : threadDataLoadable?.contents?.access === "TEAMS" ? (
        <span
          {...otherProps}
          className={classNames({
            comment: true,
            "is-active": commentThreads.has(activeCommentThreadID),
            "is-resolved": threadDataLoadable?.contents?.status === true,
          })}
          onClick={onClick}
        >
          {props.children}
        </span>
      ) : threadDataLoadable?.contents?.access === "PUBLIC" ? (
        <span
          {...otherProps}
          className={classNames({
            comment: true,
            "is-active": commentThreads.has(activeCommentThreadID),
            "is-resolved": threadDataLoadable?.contents?.status === true,
          })}
          onClick={onClick}
        >
          {props.children}
        </span>
      ) : (
        <span {...otherProps}>{props.children}</span>
      )}
    </>
  );
}
