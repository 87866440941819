export const adminRoutes = {
  root: "/admin",
  login: "/admin/login",
  forgetPassword: "/admin/forget_password",
  resetPassword: "/admin/reset_password",
  invitation: "/admin/invitation",
  changepassword: "/admin/change_password",
  roleAndAccess: "/admin/roles_access",

  adminprofile: "/admin/profile",
  accounts: "/admin/accounts",
  members: "/admin/members",
  editMembers: (id: string): string => `/admin/members/${id}/edit`,
  adminAnalyticsSummary: "/admin/analytics/summary",
  adminAnalyticsTurnaround: "/admin/analytics/turnaround",
  adminAnalyticsOrganisations: "/admin/analytics/Organisations",
  adminAnalyticsContracts: "/admin/analytics/contracts",
  adminAccountView: "/admin/accounts/:accountId/view",
  urlAdminAccountView: (id: string) => `/admin/accounts/${id}/view`,
  adminContracts: "/admin/accounts/:accountId/contracts",
  urlAdminContracts: (id: string) => `/admin/accounts/${id}/contracts`,
  adminTemplates: "/admin/accounts/:accountId/templates",
  urlAdminTemplates: (id: string) => `/admin/accounts/${id}/templates`,
  adminFolders: "/admin/accounts/:accountId/folders",
  urlAdminFolders: (id: string) => `/admin/accounts/${id}/folders`,
  adminAccountMembers: "/admin/accounts/:accountId/accountMembers",
  urlAdminAccountMembers: (id: string) =>
    `/admin/accounts/${id}/accountMembers`,
  adminAccountInformation: "/admin/accounts/:accountId/accountInformation",
  urlAdminAccountInformation: (id: string) =>
    `/admin/accounts/${id}/accountInformation`,
  privateFolders: "/account_details/folders/private",
  publicFolders: "/account_details/folders/public",
  privatefolderId: "/account_details/folder/private/:id",
  publicfolderId: "/account_details/folder/public/:id",
  overviewContracts: "/admin/accounts/:accountId/contracts/:id/overview",
  urloverviewContracts: (accountId: string, id: string) =>
    `/admin/accounts/${accountId}/contracts/${id}/overview`,
};
