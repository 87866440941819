import moment from "moment";
import React, { ReactElement, useState } from "react";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { api } from "../../utils/api";

interface VariableProviderProps {
  children: ReactElement;
  documentId: string;
  variables: IVariableResponse[];
}

interface UpdateVariableProps {
  value: string;
  format?: string;
  currency_type?: string;
}

export const SUPPORTED_CURRENCIES = [
  {
    type: "INR",
    symbol: "₹",
    format: "₹ (INR)",
  },
  {
    type: "USD",
    symbol: "$",
    format: "$ (USD)",
  },
  {
    type: "GBP",
    symbol: "£",
    format: "£ (GBP)",
  },
  {
    type: "EUR",
    symbol: "€",
    format: "€ (EURO)",
  },
];

// export const SUPPORTED_DATE_FORMATS = ["MM/dd/yyyy", "dd/MM/yyyy"];
export const SUPPORTED_DATE_FORMATS = ["MM/DD/YYYY", "DD/MM/YYYY"];

export const SUPPORTED_DATES_FORMATS = ["MM/DD/YYYY", "DD/MM/YYYY"];

export const AVAILABLE_VARIABLE_TYPES: { text: string; value: string }[] = [
  {
    text: "Text",
    value: "STRING",
  },
  {
    text: "Date",
    value: "DATE",
  },
  {
    text: "Currency",
    value: "ICURRENCY",
  },
  {
    text: "Number",
    value: "FLOAT",
  },
];

export const getVariableType = (dataType: string) =>
  AVAILABLE_VARIABLE_TYPES.find(
    ({ value }: { value: string }) => value === dataType
  );

export interface VariableContextInterface {
  values: IVariableResponse[];
  update: (_id: string, params: UpdateVariableProps) => void;
  save: () => void;
  canSave: () => boolean;
  delete: (id: string) => void;
}

const VariablesContext = React.createContext<VariableContextInterface | null>(
  null
);

const VariablesProvider = ({
  children,
  variables,
  documentId,
}: VariableProviderProps) => {
  const [values, setValues]: [IVariableResponse[], Function] = useState([]);

  const matchValues = (val1: any, val2: any, isDate: boolean) => {
    return isDate
      ? moment(val1, "YYYY-MM-DD").toISOString() ===
          moment(val2, "YYYY-MM-DD").toISOString()
      : val1 === val2;
  };

  const deleteVariable = async (id: string) => {
    await api.removeDocumentVariables(documentId, id);
  };

  const canSave = () =>
    values.length < 1 || !values.every((val) => val?.value?.length > 0);

  const update = (_id: string, params: UpdateVariableProps) => {
    const obj: IVariableResponse | undefined = values.find(
      ({ id }: IVariableResponse) => id === _id
    );
    const variable: IVariableResponse | undefined = variables.find(
      ({ id }: IVariableResponse) => id === _id
    );

    if (obj && variable) {
      if (
        matchValues(
          variable.value,
          params.value,
          variable.data_type === "DATE"
        ) &&
        variable.format === params.format &&
        variable.currency_type === params.currency_type
      ) {
        const index = values.indexOf(obj);
        if (index > -1) {
          values.splice(index, 1);
          setValues([...values]);
        }
      } else {
        obj.value = params.value;
        obj.format = params.format || obj?.format;
        obj.currency_type = params.currency_type || obj?.currency_type;
      }
    } else {
      const _param = {
        id: _id,
        value: params.value,
        format: params.format || null,
        currency_type: params.currency_type || null,
      };
      setValues([...values, _param]);
    }
  };

  const save = async () => {
    values.map(async (obj) => {
      const _variable = variables.find(({ id }) => obj.id === id);
      if (_variable) {
        const params: any = {
          name: _variable.name,
          data_type: _variable.data_type,
          value: obj.value,
        };

        if (_variable.data_type === "DATE") {
          params.format = obj.format;
        }
        if (_variable.data_type === "ICURRENCY") {
          params.currency_type = obj.currency_type;
        }
        await api.updateDocumentVariables(documentId, obj.id, params);
      }
    });
    setValues([]);
  };

  const value: VariableContextInterface = {
    values,
    update,
    save,
    canSave,
    delete: deleteVariable,
  };

  return (
    <VariablesContext.Provider value={value}>
      {children}
    </VariablesContext.Provider>
  );
};

const useVariables = () => React.useContext(VariablesContext);

export { VariablesProvider, useVariables };
