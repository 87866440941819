import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const TransferOwnerShipCheckbox = ({
  setAccessOption,
  setNotifyPeople,
  sendMessage,
  setSendMessage,
  accessOption,
}: {
  setAccessOption: Function;
  setNotifyPeople: Function;
  sendMessage: string;
  setSendMessage: Function;
  accessOption: string;
}) => {
  return (
    <>
      <div className="mt-3">
        <h6>Owner's permission</h6>
      </div>
      <div className=" d-flex justify-content-between  pb-3">
        <div className="py-3">
          <span>
            <input
              type="radio"
              defaultChecked={true}
              checked={accessOption === "EDITOR"}
              className="form-check-input"
              onChange={(e) => {
                if (e?.target?.checked) {
                  setAccessOption("EDITOR");
                }
              }}
            />
            <span className="ps-2 me-3">Editor</span>
          </span>
          <span>
            <input
              type="radio"
              checked={accessOption === "VIEWER"}
              className="form-check-input"
              onChange={(e) => {
                if (e?.target?.checked) {
                  setAccessOption("VIEWER");
                }
              }}
            />
            <span className="ps-2 me-3">Viewer</span>
          </span>
          <span>
            <input
              type="radio"
              disabled
              defaultChecked={true}
              checked={true}
              className="form-check-input"
              onChange={(e) => {
                if (e?.target?.checked) {
                  setAccessOption("EDITOR");
                }
              }}
            />
            <span className="ps-2 me-3 transfer_ownership">
              Transfer ownership
            </span>
          </span>
        </div>
      </div>
      <div className=" d-flex align-items-center">
        <div>
          <input
            type="checkbox"
            checked={true}
            className="custom-control-input"
            onChange={(e) => {
              setNotifyPeople(e?.target?.checked);
            }}
          />
        </div>
        <h6 className="mb-1 px-2">Notify People</h6>
      </div>
      <div className="textAreafrom">
        <FloatingLabel controlId="floatingTextarea2" label="">
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            onChange={(e) => setSendMessage(e?.target?.value)}
            value={sendMessage}
          />
        </FloatingLabel>
      </div>
    </>
  );
};

export default TransferOwnerShipCheckbox;
