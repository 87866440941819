import { useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { api } from "../../utils/api";
import { useParams } from "react-router-dom";
import { useContract } from "../Contracts/ContractProvider";

function Estamp({
  setApprovalAddClick,
  setSignerClick,
  setCounterpartyClick,
  setAddTemplateApprovers,
  setEstampclick,
  setNegotiationWorkFlowClick,
}: {
  setApprovalAddClick: Function;
  setSignerClick: Function;
  setCounterpartyClick: Function;
  setAddTemplateApprovers: Function;
  setEstampclick: Function;
  setNegotiationWorkFlowClick: Function;
}) {
  const { id } = useParams();
  const context = useContract();

  const [stampRequired, setStampRequired]: [boolean, Function] =
    useState(false);

  const handleUpdateStamp = async () => {
    try {
      let response = await api.updateContractName(id!, {
        stamp_required: stampRequired,
        name: context && context?.data?.name,
      });
      if (response) {
        context?.refresh();
        setApprovalAddClick(false);
        setSignerClick(false);
        setCounterpartyClick(false);
        setAddTemplateApprovers(false);
        setEstampclick(false);
        setNegotiationWorkFlowClick(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <div>
        <div
          className=" d-flex  p-4 flex-column justify-content-between"
          style={isMobileOnly ? { minHeight: "50vh" } : { minHeight: "30vh" }}
        >
          <div className="">
            <Stack className="my-2">
              <label className="mb-2 fw-semibold">
                Is an e-stamp required for this contract?
              </label>
            </Stack>
            <Form.Group
              className="mt-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className=" d-flex justify-content-between">
                <div className="py-3">
                  <span>
                    <input
                      type="radio"
                      checked={stampRequired}
                      className="form-check-input"
                      onChange={() => setStampRequired(true)}
                    />
                    <span className="ps-2 me-3">Yes</span>
                  </span>
                  <span>
                    <input
                      type="radio"
                      className="form-check-input"
                      checked={!stampRequired}
                      onChange={() => setStampRequired(false)}
                    />
                    <span className="ps-2 me-3">No</span>
                  </span>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className=" d-flex justify-content-end">
            <Button
              className="cancelbtn me-3 "
              size="sm"
              onClick={() => {
                setApprovalAddClick(false);
                setSignerClick(false);
                setCounterpartyClick(false);
                setAddTemplateApprovers(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="sharebtndanger"
              size="sm"
              onClick={() => handleUpdateStamp()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Estamp;
