import { useEffect, useState } from "react";
import WorkflowBody from "./WorkflowBody";
import WorkflowHeader from "./WorkflowHeader";
import { useApprovals } from "./WorkflowProvider";
import WorkflowLists from "./WorkflowLists";
import { useContract } from "../Contracts/ContractProvider";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import FailureToast from "../../ui/FailureToast";
import { MdCancel } from "react-icons/md";

const WorkflowMain = ({
  setBodyMessage,
  setShowSuccessToast,
  setShowFailureToast,
  approvalsLists,
  isActiveOverView,
  approvalsListDetail,
  counterPartyLists,
  setIsWorkflowListAdded,
  setApprovalsListDetail,
  approvalsTempListDetail,
  setApprovalsTempListDetail,
  setIsCounterpartySigner,
}: {
  show?: boolean;
  setBodyMessage: Function;
  setShowSuccessToast: Function;
  setShowFailureToast: Function;
  approvalsLists: Function;
  isActiveOverView: string;
  approvalsListDetail: [];
  counterPartyLists: [];
  setIsWorkflowListAdded: Function;
  setApprovalsListDetail: Function;
  approvalsTempListDetail: [];
  setApprovalsTempListDetail: Function;
  setIsCounterpartySigner: Function;
}) => {
  const context = useApprovals();
  const contractContext = useContract();
  const authContext = useAuthentication();

  const [approversAddClick, setApprovalAddClick]: [boolean, Function] =
    useState(false);
  const [toggleState, setToggleState]: [string, Function] = useState("");
  const [signerClick, setSignerClick]: [boolean, Function] = useState(false);
  const [estampClick, setEstampclick]: [boolean, Function] = useState(false);
  const [negotiationWorkFlowClick, setNegotiationWorkFlowClick]: [
    boolean,
    Function
  ] = useState(false);
  const [negotiationApproval, setNegotiationApproval]: [boolean, Function] =
    useState(false);
  const [negotiationClick, setNegotiationClick]: [boolean, Function] =
    useState(false);
  const [counterpartyClick, setCounterpartyClick]: [boolean, Function] =
    useState(false);
  const [isNegotiationWOrkflowOwner, setIsNegotiationworkflowOwner]: [
    boolean,
    Function
  ] = useState(false);

  const [negotiationApprovalAdd, setNegotiationApprovalAdd]: [
    boolean,
    Function
  ] = useState(false);

  const [bodyMessageText, setBodyMessageText]: [string, Function] =
    useState("");

  const [showFailureToastValue, setShowFailureToastValue]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    let negotiationUser: any = contractContext?.data?.externalUser?.filter(
      (el: any) => {
        return el?.access === "OWNER";
      }
    );

    if (
      negotiationUser?.find(
        (el: any) => el?.email === authContext?.currentUser?.email
      )
    ) {
      setIsNegotiationworkflowOwner(true);
    }
  }, [contractContext?.data]);

  useEffect(() => {
    let externalUsers = contractContext?.data?.externalUser?.find((el: any) => {
      return (
        el?.email === authContext?.currentUser?.email && el?.access === "VIEWER"
      );
    });

    if (externalUsers) {
      setNegotiationApproval(true);
    }
  }, [contractContext?.data]);

  return (
    <>
      <div>
        <WorkflowHeader
          setNegotiationApprovalAdd={setNegotiationApprovalAdd}
          setCounterpartyClick={setCounterpartyClick}
          setSignerClick={setSignerClick}
          setToggleState={setToggleState}
          setEstampclick={setEstampclick}
          setApprovalAddClick={setApprovalAddClick}
          approversAddClick={approversAddClick}
          isNegotiationWOrkflowOwner={isNegotiationWOrkflowOwner}
          setNegotiationClick={setNegotiationClick}
        />
      </div>
      {isNegotiationWOrkflowOwner === false && (
        <>
          <div>
            {approversAddClick &&
              (toggleState === "approvers" ||
                toggleState === "signer" ||
                toggleState === "estamp" ||
                toggleState === "counterpartyApprovers" ||
                toggleState === "counterparty") && (
                <WorkflowBody
                  setBodyMessageText={setBodyMessageText}
                  setNegotiationClick={setNegotiationClick}
                  setEstampclick={setEstampclick}
                  setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                  setAddTemplateApprovers={() => {}}
                  setCounterpartyClick={() => {}}
                  setSignerClick={() => {}}
                  setApprovalAddClick={setApprovalAddClick}
                  approvalLength={
                    context?.contractDetail?.approvers?.length !== 0
                      ? "greater"
                      : ""
                  }
                  setToggleState={setToggleState}
                  toggleState={toggleState}
                  setBodyMessage={setBodyMessage}
                  setShowSuccessToast={setShowSuccessToast}
                  setShowFailureToast={setShowFailureToast}
                  setShowFailureToastValue={setShowFailureToastValue}
                  approvalsLists={approvalsLists}
                  isActiveOverView={isActiveOverView}
                  approvalsListDetail={approvalsListDetail}
                  counterPartyLists={counterPartyLists}
                  approvalsTempListDetail={approvalsTempListDetail}
                  setApprovalsTempListDetail={setApprovalsTempListDetail}
                  setApprovalsListDetail={setApprovalsListDetail}
                  setIsCounterpartySigner={setIsCounterpartySigner}
                  setCoordinateRequired={() => {}}
                />
              )}
          </div>
          {(contractContext?.data?.stamp_required ||
            context?.contractDetail?.approvers?.length !== 0 ||
            context?.contractDetail?.external_users?.length !== 0 ||
            context?.contractDetail?.internal_signer?.length !== 0) && (
            <div>
              <WorkflowLists
                negotiationApprovalAdd={negotiationApprovalAdd}
                setNegotiationApprovalAdd={setNegotiationApprovalAdd}
                setBodyMessageText={setBodyMessageText}
                setShowFailureToastValue={setShowFailureToastValue}
                setNegotiationClick={setNegotiationClick}
                negotiationClick={negotiationClick}
                negotiationWorkFlowClick={negotiationWorkFlowClick}
                setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                estampClick={estampClick}
                setEstampclick={setEstampclick}
                contractContext={contractContext}
                setIsWorkflowListAdded={setIsWorkflowListAdded}
                signerClick={signerClick}
                setSignerClick={setSignerClick}
                counterpartyClick={counterpartyClick}
                setCounterpartyClick={setCounterpartyClick}
                setApprovalAddClick={setApprovalAddClick}
                toggleState={toggleState}
                setToggleState={setToggleState}
                setShowSuccessToast={() => {}}
                approvalsLists={approvalsLists}
                isActiveOverView={isActiveOverView}
                approvalsListDetail={approvalsListDetail}
                counterPartyLists={counterPartyLists}
                setApprovalsListDetail={setApprovalsListDetail}
                approvalsTempListDetail={approvalsTempListDetail}
                setApprovalsTempListDetail={setApprovalsTempListDetail}
                isNegotiationWOrkflowOwner={false}
                negotiationApproval={negotiationApproval}
                setCoordinateRequired={() => {}}
              />
            </div>
          )}

          {approvalsListDetail?.length > 0 &&
            isActiveOverView === "templates" && (
              <div>
                <WorkflowLists
                  negotiationApprovalAdd={negotiationApprovalAdd}
                  setNegotiationApprovalAdd={() => {}}
                  setBodyMessageText={setBodyMessageText}
                  setShowFailureToastValue={setShowFailureToastValue}
                  setNegotiationClick={setNegotiationClick}
                  negotiationClick={negotiationClick}
                  negotiationWorkFlowClick={false}
                  setNegotiationWorkFlowClick={() => {}}
                  setEstampclick={() => {}}
                  estampClick={false}
                  contractContext={contractContext}
                  setIsWorkflowListAdded={setIsWorkflowListAdded}
                  signerClick={signerClick}
                  setSignerClick={setSignerClick}
                  counterpartyClick={counterpartyClick}
                  setCounterpartyClick={setCounterpartyClick}
                  setApprovalAddClick={setApprovalAddClick}
                  toggleState={toggleState}
                  setToggleState={setToggleState}
                  setShowSuccessToast={() => {}}
                  approvalsLists={approvalsLists}
                  isActiveOverView={isActiveOverView}
                  approvalsListDetail={approvalsListDetail}
                  counterPartyLists={counterPartyLists}
                  setApprovalsListDetail={setApprovalsListDetail}
                  approvalsTempListDetail={approvalsTempListDetail}
                  setApprovalsTempListDetail={setApprovalsTempListDetail}
                  isNegotiationWOrkflowOwner={false}
                  negotiationApproval={negotiationApproval}
                  setCoordinateRequired={() => {}}
                />
              </div>
            )}
        </>
      )}

      {isNegotiationWOrkflowOwner && (
        <div>
          <div>
            {approversAddClick &&
              (toggleState === "approvers" ||
                toggleState === "signer" ||
                toggleState === "estamp" ||
                toggleState === "counterpartyApprovers" ||
                toggleState === "counterparty" ||
                toggleState === "negotiation-approval") && (
                <WorkflowBody
                  setBodyMessageText={setBodyMessageText}
                  setNegotiationClick={() => {}}
                  isNegotiationWOrkflowOwner={isNegotiationWOrkflowOwner}
                  setEstampclick={setEstampclick}
                  setNegotiationWorkFlowClick={setNegotiationWorkFlowClick}
                  setAddTemplateApprovers={() => {}}
                  setCounterpartyClick={() => {}}
                  setSignerClick={() => {}}
                  setApprovalAddClick={setApprovalAddClick}
                  approvalLength={
                    context?.contractDetail?.approvers?.length !== 0
                      ? "greater"
                      : ""
                  }
                  setToggleState={setToggleState}
                  toggleState={toggleState}
                  setBodyMessage={setBodyMessage}
                  setShowSuccessToast={setShowSuccessToast}
                  setShowFailureToast={setShowFailureToast}
                  setShowFailureToastValue={setShowFailureToastValue}
                  approvalsLists={approvalsLists}
                  isActiveOverView={isActiveOverView}
                  approvalsListDetail={approvalsListDetail}
                  counterPartyLists={counterPartyLists}
                  approvalsTempListDetail={approvalsTempListDetail}
                  setApprovalsTempListDetail={setApprovalsTempListDetail}
                  setApprovalsListDetail={setApprovalsListDetail}
                  setIsCounterpartySigner={setIsCounterpartySigner}
                  setCoordinateRequired={() => {}}
                />
              )}
          </div>
          <WorkflowLists
            negotiationApprovalAdd={negotiationApprovalAdd}
            setNegotiationApprovalAdd={setNegotiationApprovalAdd}
            setBodyMessageText={setBodyMessageText}
            setShowFailureToastValue={setShowFailureToastValue}
            setNegotiationClick={setNegotiationClick}
            negotiationClick={negotiationClick}
            negotiationWorkFlowClick={false}
            setNegotiationWorkFlowClick={() => {}}
            setEstampclick={() => {}}
            estampClick={false}
            contractContext={contractContext}
            setIsWorkflowListAdded={setIsWorkflowListAdded}
            signerClick={signerClick}
            setSignerClick={setSignerClick}
            counterpartyClick={counterpartyClick}
            setCounterpartyClick={setCounterpartyClick}
            setApprovalAddClick={setApprovalAddClick}
            toggleState={toggleState}
            setToggleState={setToggleState}
            setShowSuccessToast={() => {}}
            approvalsLists={approvalsLists}
            isActiveOverView={isActiveOverView}
            approvalsListDetail={approvalsListDetail}
            counterPartyLists={counterPartyLists}
            setApprovalsListDetail={setApprovalsListDetail}
            approvalsTempListDetail={approvalsTempListDetail}
            setApprovalsTempListDetail={setApprovalsTempListDetail}
            isNegotiationWOrkflowOwner={isNegotiationWOrkflowOwner}
            negotiationApproval={negotiationApproval}
            setCoordinateRequired={() => {}}
          />
        </div>
      )}

      {showFailureToastValue && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessageText ? bodyMessageText : "Something went wrong!."}
          title={"Failure!"}
          show={showFailureToastValue}
          close={() => {
            setShowFailureToastValue(false);
          }}
        />
      )}
    </>
  );
};

export default WorkflowMain;
