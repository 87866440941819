import { connect, getIn } from "formik";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

interface InputTextProps {
  validationSchema: any;
  label: string;
  type?: string;
  field: any;
  isValid: boolean;
  error: string;
  autoFocus?: boolean;
  value?: any;
  isDisabled?: boolean;
  isPassword?: boolean;
  setPasswordIcon?: Function;
  passwordIcon?: boolean;
  placeholder?: string;
  className?: string;
  defaultValue?: any;
}

const isRequiredField = (validationSchema: any, name: string) => {
  let _ = name.split(".").pop();
  name = _!;

  return !!getIn(validationSchema.describe().fields, name)?.tests.find(
    (obj: { name: string }) => obj.name === "required"
  );
};

const InputText = ({
  validationSchema,
  label,
  type = "text",
  field,
  isValid,
  autoFocus,
  error,
  value,
  isDisabled = false,
  isPassword,
  setPasswordIcon,
  passwordIcon,
  placeholder,
  className,
  defaultValue,
}: InputTextProps) => {
  return (
    <FormGroup controlId={field.name} className="my-3 position-relative">
      <FormLabel className="text-dark">
        {label} {isRequiredField(validationSchema, field.name) && "*"}
      </FormLabel>
      <FormControl
        type={type}
        autoFocus={!!autoFocus}
        value={value}
        onChange={field.onChange}
        isInvalid={!isValid}
        disabled={isDisabled}
        placeholder={placeholder}
        className={className}
        defaultValue={defaultValue}
      />

      {isPassword && (
        <>
          {passwordIcon ? (
            <span
              className="eye-icon-css"
              onClick={() => setPasswordIcon && setPasswordIcon(!passwordIcon)}
            >
              <AiFillEye color="#333f52" />
            </span>
          ) : (
            <span
              className="eye-icon-css"
              onClick={() => setPasswordIcon && setPasswordIcon(!passwordIcon)}
            >
              <AiFillEyeInvisible color="#333f52" />
            </span>
          )}
        </>
      )}

      {error && (
        <small className="form-text text-danger">
          <MdCancel className="me-1" />
          {error}
        </small>
      )}
    </FormGroup>
  );
};

export default connect(InputText);
