import { FormikProvider, useFormik } from "formik";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import FormikField from "../../../ui/FormikField";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import * as Yup from "yup";
import { roleOptions } from "../../Members/Interface";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

export default function AccountFormModal({
  context,
  setShowSuccessToast,
  setBodyMessage,
  setShow,
  setShowFailureToast,
  show,
}: any) {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .required("Phone Number is required"),
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters  are allowed")
      .required("Last Name is required"),
    roles: Yup.string().required("Role is required"),
    company_name: Yup.string()
      .min(2, "Too Short!")
      .max(1000)
      .required("Company Name is required"),
    department: Yup.string().min(2, "Too Short!").max(1000),
    industry: Yup.string().min(2, "Too Short!").max(1000),
    job_title: Yup.string().min(2, "Too Short!").max(1000),
    team_size: Yup.string().min(1, "Too Short!").max(1000),
    start_date: Yup.string().min(2, "Too Short!").max(1000),
  });

  const handleSubmitData = async (values: any) => {
    const response = await context?.handleAddAccount({
      ...values,
      first_name: values?.first_name?.trim().replace(/\s+/g, " "),
    });

    if (response?.success) {
      context?.getAccounts();
      setShowSuccessToast(true);
      setBodyMessage("Account user invited successfully");
      formik.resetForm();
      setShow(false);
    } else {
      setShowFailureToast(true);
      setBodyMessage(response?.error?.[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      roles: "ADMIN" || [],
      company_name: "",
      department: "",
      industry: "",
      job_title: "",
      team_size: "",
      start_date: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      try {
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });
  const {
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    setFieldValue,
  } = formik;
  const isAllFieldsFilled =
    values.first_name &&
    values.last_name &&
    values.phone_number &&
    values.email;

  const desiginationOption = [
    {
      label: "Provider Network Representative",
      value: "Provider Network Representative",
    },
    { label: "Chief Executive Officer", value: "Chief Executive Officer" },
    { label: "Chief Operating Officer", value: "Chief Operating Officer" },
    { label: "Chief Financial Officer", value: "Chief Financial Officer" },
    { label: "Chief Technology Officer", value: "Chief Technology Officer" },
    { label: "Chief Marketing Officer", value: "Chief Marketing Officer" },
    { label: "HR Director", value: "Human Resources Director" },
    { label: "Vice President", value: "VP" },
    { label: "Manager", value: "Manager" },
    { label: "Supervisor", value: "Supervisor" },
    { label: "Analyst", value: "Analyst" },
    { label: "Specialist", value: "Specialist" },
    { label: "Coordinator", value: "Coordinator" },
    { label: "Administrator", value: "Administrator" },
    { label: "Engineer", value: "Engineer" },
    { label: "Technician", value: "Technician" },
    { label: "Sales Representative", value: "Sales Representative" },
    {
      label: "Customer Service Representative",
      value: "Customer Service Representative",
    },
    { label: "Accountant", value: "Accountant" },
    { label: "Executive Assistant", value: "Executive Assistant" },
    { label: "Intern", value: "Intern" },
    { label: "Healthcare Analyst", value: "Healthcare Analyst" },
    { label: "Underwriter", value: "Underwriter" },
    { label: "Claim adjudicator", value: "Claim adjudicator" },
    { label: "Actuary", value: "Actuary" },
    {
      label: "Insurance Agent or Broker",
      value: "Insurance Agent or Broker",
    },
    {
      label: "Healthcare Compliance Officer",
      value: "Healthcare Compliance Officer",
    },
    { label: "Product Manager", value: "Product Manager" },
    { label: "Marketing Manager", value: "Marketing Manager" },
    {
      label: "IT Specialist or Systems Administrator",
      value: "IT Specialist or Systems Administrator",
    },
    { label: "Legal Counsel", value: "Legal Counsel" },
    { label: "Financial Analyst", value: "Financial Analyst" },
    { label: "Physician", value: "Physician" },
    { label: "Nurse", value: "Nurse" },
    { label: "Surgeon", value: "Surgeon" },
    { label: "Pharmacist", value: "Pharmacist" },
    { label: "Radiologic Technologist", value: "Radiologic Technologist" },
    {
      label: "Medical Laboratory Technologist",
      value: "Medical Laboratory Technologist",
    },
    { label: "Healthcare Administrator", value: "Healthcare Administrator" },
    {
      label: "Patient Care Technician or Nurse's Aide",
      value: "Patient Care Technician or Nurse's Aide",
    },
    {
      label: "Physical Therapist or Occupational Therapist",
      value: "Physical Therapist or Occupational Therapist",
    },
    { label: "Medical Records Clerk", value: "Medical Records Clerk" },
    { label: "Social Worker", value: "Social Worker" },
    { label: "Respiratory Therapist", value: "Respiratory Therapist" },
    { label: "Nutritionist/Dietitian", value: "Nutritionist/Dietitian" },
    { label: "Hospital Chaplain", value: "Hospital Chaplain" },
    {
      label: "Facility Maintenance Staff",
      value: "Facility Maintenance Staff",
    },
    {
      label: "Billing and Coding Specialist",
      value: "Billing and Coding Specialist",
    },
    { label: "Security Officer", value: "Security Officer" },
    { label: "Account Manager", value: "Account Manager" },
  ];

  return (
    show && (
      <div>
        <Modal show={show} centered size="xl">
          <Modal.Header>
            <div className="pt-3">
              <h6 className=" fw-semibold">Add Account</h6>
            </div>
          </Modal.Header>

          <Modal.Body className="pt-2 p-4">
            <FormikProvider value={formik}>
              <Form noValidate={true} onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="company_name"
                      type="company_name"
                      validationSchema={validationSchema}
                      label="Company Name"
                      placeholder="Company Name"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="industry"
                      type="industry"
                      validationSchema={validationSchema}
                      label="Industry"
                      placeholder="Industry"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="team_size"
                      type="team_size"
                      validationSchema={validationSchema}
                      label="Team Size"
                      placeholder="Team Size"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12 mt-3">
                    <div className="from-control w-100">
                      <div className="row status-title mb-2 ">
                        Establishment On *
                      </div>{" "}
                      <DatePicker
                        className=" w-100 from-control"
                        value={
                          values?.start_date
                            ? moment(values?.start_date).toDate()
                            : ""
                        }
                        onChange={(date: any) => {
                          formik?.setFieldValue("start_date", date);
                        }}
                        // minDate={moment().toDate()}
                      />
                    </div>
                  </div>
                  <div className="py-3">
                    <h6 className=" fw-semibold m-0">User information</h6>
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="first_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="First Name"
                      errors={errors}
                    />
                  </div>{" "}
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="last_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Last Name"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="email"
                      type="email"
                      validationSchema={validationSchema}
                      label="Email address "
                      placeholder="Email address "
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">
                      Phone Number <span className="">*</span>
                    </label>
                    <FormGroup className="mt-2 ">
                      <div className="phone-input-css ">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values?.phone_number}
                          country={"in"}
                          defaultCountry="IN"
                          name="phone_number"
                          onChange={(phoneNumber: any) => {
                            setFieldValue("phone_number", phoneNumber);
                          }}
                          error={
                            values?.phone_number
                              ? isValidPhoneNumber(values?.phone_number)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                      <span className="field-error">
                        {values?.phone_number
                          ? isValidPhoneNumber(values?.phone_number)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>

                      {/* <ErrorMessage name="phone_number" /> */}
                    </FormGroup>
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">
                      Department <span className="">*</span>
                    </label>
                    <Select
                      placeholder="Select department"
                      value={
                        values?.department && {
                          label: values?.department,
                          value: values?.department,
                        }
                      }
                      className="variable_Select mt-3"
                      options={context?.departmentList?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(e: any) => {
                        setFieldValue("department", e?.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">Role</label>
                    <Select
                      isDisabled
                      value={{
                        label: "ADMIN",
                        value: "ADMIN",
                      }}
                      className="variable_Select mt-3"
                      options={roleOptions}
                      isMulti={false} // or isMulti={true} depending on your needs
                      onChange={(e: any) => {
                        setFieldValue("roles", e?.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12 mt-2">
                    <label className="text-dark mt-2">Designation</label>
                    <Select
                      className="variable_Select mt-3"
                      options={desiginationOption}
                      isMulti={false}
                      onChange={(e: any) => {
                        setFieldValue("job_title", e?.value);
                      }}
                    />
                    {/* <FormikField
                      name="job_title"
                      type="job_title"
                      validationSchema={validationSchema}
                      label="Designation"
                      placeholder="Designation"
                      errors={errors}
                    /> */}
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancel-hallowbtn me-3"
              onClick={() => {
                resetForm();
                setShow(!show);
              }}
            >
              Cancel
            </Button>

            <FormikSubmitButton
              isSubmitting={isSubmitting}
              className="updatebtndanger"
              size="md"
            >
              <Button
                className="SignText"
                size="sm"
                disabled={isSubmitting || !isAllFieldsFilled}
                onClick={() => {
                  handleSubmitData(formik?.values);
                }}
              >
                Send
              </Button>
            </FormikSubmitButton>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}
