import { TemplateProvider } from "./TemplateProvider";
import AppHeader from "../../layouts/AppHeader";
import TemplateOverviewBody from "./TemplateOverview/TemplateOverviewBody";

function TemplateOverview() {
  return (
    <TemplateProvider>
      <AppHeader>
        <TemplateOverviewBody isActiveOverView="templates" />
      </AppHeader>
    </TemplateProvider>
  );
}

export default TemplateOverview;
