import SignUpForm from "../../components/auth/SignUpForm";
import AuthLayout from "../../layouts/AuthLayout";
import { useAuthentication } from "./AuthenticationProvider";

const SignUpPage = () => {
  const context = useAuthentication();

  return (
    context && (
      <AuthLayout
        pageTitle="CLM - Sign Up"
        title="Signup!"
        subTitle="Create your account to continue using the platform"
      >
        <SignUpForm />
      </AuthLayout>
    )
  );
};

export default SignUpPage;
