import { BsArrowLeft } from "react-icons/bs";
import { Version } from "../../lib/VersionHistory";
import moment from "moment";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { routes } from "../../utils/routes";
import { Dropdown } from "react-bootstrap";
import { useVersionHistory } from "./VersionHisoryProvider";

function VersionHistoryHeader({
  versionDetail,
}: {
  versionDetail: Version | undefined;
}) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const versionHistoryContext = useVersionHistory();

  return (
    <>
      <div className="p-3">
        <div className=" d-flex align-items-center  justify-content-between">
          <div className=" d-flex align-items-center ">
            <span
              className="me-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  location.pathname.includes("contracts")
                    ? generatePath(routes.versionHistoryContracts, {
                        id: id,
                      })
                    : generatePath(routes.versionHistory, {
                        id: id,
                      })
                )
              }
            >
              <BsArrowLeft fontSize={24} />
            </span>
            <h6 className="m-0 fw-semibold">
              {versionDetail?.name ? (
                <>
                  <div
                    // className="card-contract-name"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={versionDetail?.name}
                  >
                    <span>
                      {versionDetail?.name?.length >= 15 ? (
                        <>
                          {versionDetail?.name?.charAt(0).toUpperCase() +
                            versionDetail?.name?.slice(1)?.substr(1, 15)}
                          {"..."}
                        </>
                      ) : (
                        <>
                          {versionDetail?.name?.charAt(0).toUpperCase() +
                            versionDetail?.name?.slice(1)}
                        </>
                      )}
                    </span>
                  </div>
                </>
              ) : (
                // versionDetail?.name.charAt(0).toUpperCase() +
                // versionDetail?.name?.slice(1).toLowerCase()
                <>{moment(versionDetail?.updatedAt).format("DD MMM , YYYY")}</>
              )}
            </h6>
          </div>
          <div>
            <h6 className="m-0 fw-semibold">
              {moment(versionDetail?.updatedAt).format("DD MMM, YYYY | hh:mm")}
            </h6>
          </div>
        </div>
        <div
          style={{ background: "#EDF2FA", borderRadius: "20px" }}
          className="d-flex justify-content-between align-items-center py-2 px-4 mt-4 "
        >
          <h6 className="fw-light m-0">
            {/* 100% */}
            <Dropdown>
              <Dropdown.Toggle variant="none" id="dropdown-basic">
                100%
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => versionHistoryContext?.onZoomClick(0.5)}
                >
                  50
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => versionHistoryContext?.onZoomClick(1)}
                >
                  100
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => versionHistoryContext?.onZoomClick(1.5)}
                >
                  150
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </h6>
          <div className=" d-flex align-items-center">
            <span className="px-3"></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default VersionHistoryHeader;
