import { isMobile } from "react-device-detect";
import logo from "../assets/logo12.png";

const PrivacyPolicy = () => {
  return (
    <div
      className={
        isMobile
          ? "py-5 container-fluid"
          : "py-5 container d-flex justify-content-center flex-column align-items-center overflow-scroll"
      }
    >
      <div
        className="mb-5"
        style={{
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
          background: "#fafafa",
          width: "800px",
          //   height: "1130.83px",
        }}
      >
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            These terms and conditions (“User Terms”) 1 read with the Privacy
            Policy available on the CLM Application constitutes a legal binding
            agreement (“Agreement”) between You and CLM Private Limited, a
            company incorporated under the Companies Act, 2013, having its
            registered office at No. 9, Shendge Avenue 1st Floor, 2nd Street,
            Kamaraj Road, Bengaluru – 560042, which expression shall, unless it
            be repugnant to the context or meaning thereof, be deemed to mean
            and include all its successors, affiliates and permitted assigns
            (“CLM&quot;, or “Us” or “We” or “Our”) and shall apply to and govern
            Your visit to and use, of the CLM Application (in the capacity of a
            ‘User’) and any of its products or services through a computer as
            well as to all information, recommendations and or Services provided
            to You on or through the CLM Application during your participation
            in the &#39;Beta’ (defined below) and your access to, evaluation,
            and use of the Services (defined below) made available to you by CLM
            as part of the Beta. This Agreement defines the terms and conditions
            under which you are allowed to use the CLM Application during the
            Beta and describes the manner in which We shall treat your account
            while you are registered as a member with us. These User Terms apply
            to the Services made available by CLM on the Application to Users of
            the CLM Application. Upon Your acceptance of the Agreement and / or
            the User Terms, We reserve the right to store information on a
            User’s computer ,as well as CLM’s database and servers, in the form
            of “cookie” or similar file for purposes of modifying the CLM
            Application to reflect the User’s preference. CLM shall try to
            ensure that all information that is provided on the CLM Application
            with respect to policies / products / Services are accurate in all
            respects and are kept up to date. However, CLM does not guarantee
            the timeliness, accuracy, completeness, reliability or content of
            the information and / or any changes that are made with respect to
            the same for the Beta, and a User is required to check the accuracy
            of the same with the office of CLM.
          </p>
          <p>
            CLM may introduce new services in relation to the Application from
            time to time. The existence and availability of the new services
            will be notified on the CLM Application as and when they become
            available. In addition, CLM may also publish notices of general
            nature, which are applicable to all visitors or Customers in a
            newspaper or on its CLM Application. Such notices will have the same
            effect as a notice served individually to each visitor or Customer.
          </p>
          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and the relevant rules thereunder as applicable
            and the provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000 and / or
            the relevant rules thereunder. By accessing this CLM Application,
            You are consenting to be bound by these User Terms and the Privacy
            Policy. PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THESE USER
            TERMS BEFORE YOU
          </p>
          <p>
            <b>Note to Draft:</b> Cross referencing, proofing and consistency
            edits to be finalized closer to execution.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 1</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            USE THE CLM Application. If You do not accept any of the User Terms,
            then please do not use the CLM Application or avail any of the
            services / Services being provided therein.
          </p>
          <p>
            Your acceptance of the User Terms shall be deemed to include your
            acceptance of the Privacy Policy available at the CLM Application
            Sign-up Screen. [Note to CLM: Please confirm if the sign up screen
            will be used for the Beta].
          </p>
          <p>
            <h6 className=" fw-semibold">I. DEFINTIONS</h6>
            <p>
              All of the defined and capitalized terms in these User Terms will
              have the meaning assigned to them here below:
            </p>
            <p>
              “Account” shall mean the account created by the Customer on the
              Application for availing the Services provided/facilitated by CLM.
              [Note to CLM: Please confirm/Draft whether an account will be
              created in beta phase or in case of free trials there will be
              direct access for anyone who has access to a web-link / URL for
              the Beta]
            </p>
            “Applicable Laws” shall mean and include all applicable statutes,
            enactments, acts of legislature or parliament, laws, ordinances,
            rules, by-laws, regulations, notifications, guidelines, policies,
            directions, directives and orders of any relevant and / or
            applicable Governmental Authority.
            <p>
              “Application” shall mean the contract management software program
              or application developed and provided by CLM, including all its
              components, features, functionalities, and associated
              documentation, as provided to you for the purposes of the Beta.
              [Note to CLM: Please confirm if any specific features /
              functionalities / descriptions are required to be added for the
              Application.]
            </p>
            <p>
              “Beta” shall mean the pre-release version of the software [Iris /
              0.1name / version of the software] by CLM, which is made available
              to customer for a limited period of time with the purpose of
              testing, evaluation, bug reporting, and feedback collection prior
              to the official release to the general public. The Beta version of
              the Application may contain known and unknown defects,
              limitations, and features that are subject to change based on
              feedback and the overall development progress of the Application.
              [Note to CLM: Can specify the time period for the Beta].
            </p>
          </p>
          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 2</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            “Customer”, “You”, ”User”, “Your” or “you” shall, along with any
            grammatical connotations hereto, shall mean and include, each
            individually or collectively, as the case may be, who is an
            authorized user of the CLM Application who has an [Account on the
            CLM Application] / [has been given access to the &#39;Beta’ by CLM]
          </p>
          <p>
            “Force Majeure Event” shall mean any and all events arising due to
            any cause beyond the reasonable control of CLM including, but not
            limited to, reasons of pandemics and epidemics (as categorized by
            the WHO, Governmental Authority or otherwise), or by virtue of any
            Applicable Laws (as amended).
          </p>
          <p>
            “Governmental Authority” means any nation or government or any
            province, state or any other political subdivision thereof, any
            entity, authority or body exercising executive, legislative,
            judicial, regulatory or administrative functions of or pertaining to
            government, including any government body, agency, department,
            board, commission or instrumentality of India, or any political
            subdivision thereof, or of any other applicable jurisdiction, any
            court, tribunal or arbitrator, and any securities exchange or body
            or authority regulating such securities exchange whether located in
            India or overseas;
          </p>
          <p>
            “Intellectual Property Rights” means and includes any and all
            patents, copyrighted works, underlying works, work product,
            development, inventions, discoveries, improvements, innovations
            (including all data and records pertaining thereto), trademarks,
            trade names, trade secrets, confidential information, service marks,
            domain names and URLs, logos, websites, art work, slogans, know-how,
            audio, video, audio-visual works, content, graphics, designs, user
            interfaces, visual interfaces, look and feel, utility models, tools,
            devices, media, models, methods, procedures, processes, systems,
            principles, algorithms, system codes, works of authorship,
            flowcharts, drawings, books, papers, models, sketches, formulas,
            proprietary techniques, research projects, and other confidential
            and proprietary information, works (including computer code, source
            code, software, and preparatory and design materials thereof) in
            which copyright may subsist (including all data and records
            pertaining thereto), application development, blue prints (whether
            or not registrable and whether or not design rights subsist in
            them), arrangement of contents, information, documentation,
            databases, data, documents, instruction manuals, records, memoranda,
            notes, in any mode or medium, whether or not copyrightable,
            patentable (and whether or not patent protection has been applied
            for or granted), registrable as a trademark or reduced to writing
            and all other intellectual property throughout the world, in and for
            all languages, including but not limited to computer and human
            languages developed or created from time to time, all in relation to
            the Application, Site and / or the Services forming part of these
            User Terms.
          </p>
          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 3</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            “Registration Data” shall mean and may include the present, valid,
            true and accurate name, email ID, phone number and such other
            information as may be required by CLM Application from the Customer
            from time to time for registration on the Application.
          </p>
          <p>
            “Services” shall mean the various categories of Services including
            the following made available through the CLM Application during the
            Beta.
          </p>
          <p>
            “Site” shall mean the website owned and operated by CLM or any other
            software that enables the use of the CLM Application or such other
            URL as may be specifically provided by CLM.
          </p>
          <p>
            <h6 className=" fw-semibold">II. ELIGIBILITY</h6>
            <p>
              You will be “Eligible” to use the Services only when You fulfil
              all of the following conditions: (i) You have attained at least 18
              (eighteen) years of age and / or (ii) You are competent to enter
              into this Agreement and / or duly authorized to act on behalf of
              the end User under Applicable Laws.
            </p>
            <p>
              If You reside in a jurisdiction that restricts the use of the
              Service because of age, or restricts the ability to enter into
              contracts such as this User Terms due to any Applicable Laws, You
              must abide by such limitations.
            </p>
            <h6 className=" fw-semibold">
              III. REGISTRATION/MEMBERSHIP AND MANNER OF USAGE OF ACCOUNT
            </h6>
            <p>
              You understand and acknowledge that You can register on the
              Application only after complying with the requirements of this
              clause and by entering Your Registration Data.
            </p>
            <p>
              You shall ensure that the Registration Data provided by You is
              accurate, complete, current, valid and true and is updated from
              time to time. We shall bear no liability for false, incomplete,
              old or incorrect Registration Data provided by You. [Note to CLM:
              Please confirm whether in beta phase the registration data is
              necessary for creation of an account/if in case of direct access
              what necessary procedure has to be followed for accessing the Beta
              version of the Application.]
            </p>
            <p>
              You are solely responsible for maintaining the confidentiality of
              Your Registration Data and will be liable for all activities and
              transactions that occur through Your Account, whether initiated by
              You or any third party. Your Account cannot be transferred,
              assigned or sold to a third party.
            </p>
          </p>
          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 4</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            We shall not be liable for any loss that You may incur as a result
            of someone else using Your password or Account, either with or
            without Your knowledge.
          </p>
          <p>
            We reserve the right and sole discretion to suspend or terminate
            Your Account with immediate effect and for an indefinite period, if
            We have a reason to believe that the Registration Data or any other
            data provided by You is incorrect or false, or that the security of
            Your Account has been compromised in any way, or for any other
            reason.
          </p>
          <p>
            Except for the Registration Data or any other data submitted by You
            during the use of any other service offered through Application
            (“Permitted Information”), CLM does not want You to, and You should
            not, send any confidential or proprietary information to CLM on the
            Application or otherwise, unless otherwise it is and / or would be
            required by Applicable Laws. In accepting these User Terms, You
            agree that any information or materials that You or individuals
            acting on Your behalf provide to CLM other than the Permitted
            Information will not be considered confidential or proprietary and
            CLM assumes no responsibility nor any liability in relation to the
            same.
          </p>
          <p>
            It is Your responsibility to check to ensure that You download the
            correct and updated version of the Application for Your device. We
            are not liable if You do not have a compatible laptop or desktop
            mobile device or if You download the wrong and / or outdated version
            of the Application for Your mobile device, i.e., either a laptop or
            desktop. We reserve the right to terminate the Service and the use
            of the Application should You be using the Service or Application
            with an incompatible or unauthorized device, or is a wrong and / or
            outdated version of the Application.
          </p>
          <p>
            We allow You to open only one Account in association with the
            Registration Data provided by You. In case of any unauthorized use
            of Your Account please immediately reach Us a support@ clm.in . In
            case, You are unable to access Your Account, please inform Us at
            support@clm.in and make a written request for blocking Your Account.
            We will not be liable for any unauthorised transactions made through
            Your Account prior to the expiry of 72 (seventy-two) hours after You
            have made a request in writing for blocking Your Account, and shall
            not have any liability in case of Force Majeure Events.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 5</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            [When you sign up for an account for your organization you may
            specify one or more administrators. The administrators will have the
            right to configure the Services based on your requirements and
            manage end users in your organization account. If your organization
            account is created and configured on your behalf by a third party,
            it is likely that such third party has assumed administrator role
            for your organization. Make sure that you enter into a suitable
            agreement with such third party specifying such party’s roles and
            restrictions as an administrator of your organization account. You
            are responsible for:
          </p>
          <p>
            a. Ensuring confidentiality of your organization account password,
          </p>
          <p>
            b. Appointing competent individuals as administrators for managing
            your organization account, and
          </p>
          <p>
            c. Ensuring that all activities that occur in connection with your
            organization account comply with this Agreement. You understand that
            CLM is not responsible for account administration and internal
            management of the Services for you. [Note to CLM: Please confirm if
            this provision is required to be retained.]
          </p>
          <p>
            By using the Application or the Service, You further agree that: (i)
            You will only use the Service or download the Application for Your
            sole use and will not resell or assign it to a third party; (ii) You
            will not use an account that is subject to any rights of a person
            other than You without appropriate authorization; (iii) You will not
            use the Service or Application for unlawful purposes or in
            non-compliance of any Applicable Laws; (iv) You will not try to harm
            the Service, Application, CLM or Our network in any way whatsoever;
            (v) You will provide CLM with such information and documents which
            CLM may reasonably request; (vi) You will comply with all Applicable
            Laws from Your country of domicile and residence and the country,
            state and/or city in which You are present while using the
            Application or Service; (vii) You are aware of and shall comply with
            all Applicable Laws vis-à-vis the use of the Application and / or
            the Services; and (viii) that You shall not in any way, directly or
            indirectly, alone or in concert with others, cause, express or cause
            to be expressed in a public manner, orally or in writing, any
            remarks, statements, comments or criticisms that disparage, call
            into disrepute, defame, slander or which can reasonably be construed
            to be defamatory or slanderous to CLM.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 6</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            CLM reserves the right to immediately terminate the Service and the
            use of the Application at any time during the ‘Beta’ in the event of
            non-compliance with any of the above requirements or any breach /
            non-compliance with any of the Terms of Use stated herein, or for
            any other reason including at the discretion and / or for the
            convenience of CLM. Further, CLM will store the information provided
            by You or record your calls for contacting You for all Service
            related matters including obtaining feedback in relation to the
            Services provided/facilitated through the CLM Application. You shall
            promptly inform CLM on any change, amendment, variation or
            modification in the information provided by You.
          </p>
          <p>
            You understand, acknowledge and agree that CLM reserves the right to
            cease operations vis-à-vis the Services, the Application and / or
            any part or component thereof at its sole discretion and at any time
            during the ‘Beta’.
          </p>
          <p>
            CLM or an authorised representative of CLM, shall provide
            information regarding additional services, discounts and promotions
            provided by CLM to You by way of a WhatsApp, SMS or email to Your
            registered mobile number/ registered email ID. You also have the
            option to discontinue receiving such information at any point of
            time. To discontinue receiving such information, You may at any
            point of time write to Application.support@clm.in to discontinue the
            same. [Note to CLM: Please confirm the relevant co-ordinates / email
            ID’s for any support required during the Beta phase.]
          </p>
          <p>
            You expressly agree and acknowledge that (a) CLM will be entitled to
            process and transfer your information as and when it deems fit and
            it may store or transfer your information in a server within India
            in order to perform CLM’s obligations under these user terms; and
            (b) you have reviewed and accepted the terms of the privacy policy
            and agree to remain subject to the provisions contained therein.
          </p>
          <p>
            You agree to grant CLM a non-exclusive, perpetual, irrevocable,
            royalty- free, sub- licensable (through multiple tiers) right to
            exercise the copyright, publicity, database rights or any other
            rights You have in your Permitted Information, in any media now
            known or not currently known, with respect to Your Permitted
            Information. YOU UNDERSTAND, ACKNOWLEDGE, AGREE AND PERMIT CLM TO
            SHARE YOUR INFORMATION AND/OR PERMITTED INFORMATION, WITH ANY THIRD
            PARTIES ENGAGED DIRECTLY WITH CLM OR OTHERWISE IN COMPLIANCE WITH
            APPLICABLE LAWS.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 7</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            You agree and permit CLM to share any Permitted Information provided
            by You with third parties in order to facilitate provision of
            Services including certain value-added / ancillary services offered
            by such third parties to You and / or to provide Services including
            certain value-added services to You by CLM. You hereby expressly
            consent to receive communications from CLM / third parties offering
            Services / value-added services to You through Your registered phone
            number and / or e-mail id and / or the Application. You agree and
            undertake that You will not hold CLM responsible for any such
            communications received from third parties, nor will any such
            communication amount to spam, unsolicited communication or a
            violation of Your registration on the national do not call registry.
            Further, you agree that CLM may contact you through telephone,
            email, SMS, push notifications or any other electronic means of
            communication for certain purposes limited to the use of the
            Application and availing the Services during the ‘Beta’. All
            transactions, which cannot be carried out instantaneously, shall be
            carried out during working hours either on the same day or on the
            next working day or such further period as may be determined and/or
            specified by CLM depending upon the time of logging of the
            transaction. All transactions that are carried out by and on behalf
            of the Customer shall be subject to Applicable Laws governing the
            offer of any service on the CLM
          </p>
          <p>
            <h6 className=" fw-semibold">IV. APPLICATION LICENSE</h6>
            <p>
              Subject to Your compliance with these User Terms, CLM grants You a
              limited, revocable, non-exclusive, non-transferable and
              non-sub-licensable license to download and install a copy of the
              Application on a single mobile device (i.e., either a laptop or
              desktop) that You own or control and to run such copy of the
              Application solely for Your own personal use. You understand,
              acknowledge and agree that CLM grants no express or implied
              Intellectual Property Rights. or any part thereof, by implication,
              estoppel or otherwise, and the End User shall remain subject to
              the provisions related to ‘Intellectual Property Ownership’ set
              forth in these User Terms below.
            </p>
            <p>
              You shall not (i) license, sublicense, sell, resell, transfer,
              assign, distribute or otherwise commercially exploit or make
              available to any third party the Service or Application in any
              way; (ii) modify or make derivative works based upon the Service
              or Application; (iii) create Internet “links” to the Service or
              “frame” or “mirror” the Application (or any part of the Beta
              thereof) on any other server or wireless or Internet-based device;
              (iv) reverse engineer or access the Application in order to (a)
              design or build a competitive product or service, (b) design or
              build a product using similar ideas, features, functions or
              graphics of the Service or Site, or (c) copy, reproduce, record,
              or make available to the public any ideas, features, functions or
              graphics of the Service or Site, or (v) launch an automated
              program or script, including, but not limited to, web spiders, web
              crawlers, web robots, web ants, web indexers, bots, viruses or
              worms, or any program which may make multiple server requests per
              second, or unduly burdens or hinders the operation and/or
              performance of the Service or Site.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 8</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            You shall not: (i) send spam or otherwise duplicative or unsolicited
            messages in violation of Applicable Laws; (ii) send or store or
            transmission of material that is unlawful, defamatory, insulting,
            harassing, invasive of another&#39;s privacy (including bodily
            privacy), abusive, harmful, vulgar, pornographic, paedophilic,
            harmful to children, racially or ethnically objectionable, or is
            otherwise objectionable, offends religious sentiments, promotes
            racism or that which infringes or may infringe intellectual property
            or other rights of another, obscene, threatening, libelous, or
            otherwise unlawful or tortious material, including material harmful
            to minors or violative of third party privacy rights; (iii) send or
            store material containing software viruses, worms, Trojan horses or
            other harmful computer code, files, scripts, agents or programs;
            (iv) interfere with or disrupt the integrity or performance of the
            Site, the Application or Service or the data contained therein; or
            (v) attempt to gain unauthorized access to the Site, the Application
            or Service or its related systems or networks.
          </p>
          <p>
            You agree not to use the Services for the transmission of &quot;junk
            mail&quot;, &quot;spam&quot;, &quot;chain letters&quot;, “phishing”
            or unsolicited mass distribution of email. We reserve the right to
            terminate your access to the Services if there are reasonable
            grounds to believe that you have used the Services for any illegal
            or unauthorized activity.
          </p>
          <p>
            CLM shall have the right to audit, investigate and / or prosecute
            violations of any of the above to the fullest extent of the law. CLM
            may involve and cooperate with law enforcement authorities in
            prosecuting Users who violate these User Terms. You acknowledge that
            CLM has no obligation to monitor Your access to or use of the Site,
            or posted content, but has the right to do so for the purpose of
            operating the Application and Service, to ensure Your compliance
            with these User Terms, or to comply with Applicable Law or the order
            or requirement of a court, administrative agency or other
            Governmental Authority. CLM reserves the right, at any time and
            without prior notice, to remove or disable access to any content
            that CLM, at its sole discretion, considers to be in violation of
            these User Terms or otherwise harmful to the Site, the Service or
            Application.
          </p>
          <p>
            <h6 className=" fw-semibold">V. FORCE MAJEURE</h6>
            <p>
              We shall not be liable for any failure to perform any obligations
              under this User Terms, if the performance is prevented, hindered
              or delayed by a Force Majeure Event and in such case Our
              obligations under this User Terms shall be suspended for so long
              as the Force Majeure Event continues.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 9</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">VI. INDEMNIFICATION</h6>
            <p>
              By accepting these User Terms and using the Service, You agree
              that You shall defend, indemnify and hold CLM, its affiliates,
              their licensors, and each of its officers, directors, other users,
              employees, attorneys, third party service providers and agents
              harmless from and against any and all claims (including, but not
              limited to, claims for personal injury, medical eventualities and
              property damage), costs, proceedings, damages, losses, liabilities
              and expenses, damages, charges and expenses (including attorneys’
              fees and costs) arising out of or in connection with: (a) Your
              violation or breach of any term of these User Terms or any
              Applicable Law or regulation, whether or not referenced herein;
              (b) Your violation of any rights of any third party \ or (c) Your
              use or misuse of the Application or Service; (c) misrepresentation
              of any information and / or personal data supplied to Us.
            </p>
            <h6 className=" fw-semibold">VII. NO LIABILITY</h6>
            <p>
              The information, recommendations and/or Services provided to You
              on or through the Site/ Application are for general information
              purposes only and does not constitute advice. CLM will reasonably
              keep the CLM Application and its contents correct and up to date
              but does not guarantee that (the contents of) the Application is
              free of errors, defects, malware and viruses or that the
              Application is correct, up to date and accurate. CLM shall not be
              liable, at any time, for any failure of performance, error,
              omission, interruption, deletion, defect, delay in operation or
              transmission, computer virus, communications line failure, theft
              or destruction or unauthorized access to, alteration of, or use of
              information contained at the CLM Application.
            </p>
            <p>
              You acknowledge that the Web-Based Contract Management Tool
              provided by Application is intended for general contract
              management and informational purposes only. It is not intended to
              provide legal advice or substitute for professional legal counsel.
              Application will make reasonable efforts to maintain the accuracy
              and functionality of the Tool but does not guarantee that it is
              free from errors, defects, or viruses or that it is always
              accurate and up-to-date.
            </p>
            <p>
              CLM shall not be liable for any damages resulting from the use of
              or inability to use the CLM Application, including damages caused
              by wrong usage of the Site, error in call centre number, network
              issues, malware, viruses or any incorrectness or incompleteness of
              the Information or the or Application.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 10</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>In no event shall CLM shall be liable for:</p>
          <p>
            a. any content posted, transmitted, exchanged or received by or on
            behalf of any user or other person on or through the CLM
            Application;
          </p>
          <p>
            b. any unauthorized access to or alteration of your transmissions or
            data; or
          </p>
          <p>
            c. any other matter relating to the CLM Application or the Service.
          </p>

          <p>
            If Applicable Law does not permit the exclusion of certain
            warranties or the limitation or exclusion of liability, the scope
            and duration of such warranty exclusions and the extent of the
            liability of CLM shall be the minimum permitted under Applicable
            Law.
          </p>
          <p>
            Under no circumstances, including but not limited to negligence,
            shall CLM, its employees and directors, its suppliers and its third
            party advisors be liable to You for any direct, indirect,
            incidental, special or consequential damages or any damages
            whatsoever including punitive or exemplary (including, but not
            limited to, damages caused by any virus, personal injury, loss of
            profits, data or other intangible, business interruption, loss of
            privacy, or any other pecuniary loss), arising out of or in any way
            connected with the use/delivery/performance of this CLM Application,
            with the delay or inability to use this CLM Application or any links
            or items on the CLM Application, the provision of or failure to
            provide Services, or for any information, software, products,
            services and related graphics obtained through this CLM Application,
            or otherwise arising out of the use of this CLM Application, whether
            based on contract, tort, strict liability or otherwise, or for cost
            of procurement of substitute goods and repair &amp; correction
            services or resulting from the use of this CLM Application or
            obtained or messages received or transactions entered into through
            or from the CLM Application or resulting from unauthorized access to
            or alteration of your transmissions or data, even if We have been
            advised of the possibility of such damages.
          </p>
          <p>
            CLM assumes no liability, under Applicable Laws or otherwise, over
            any action, pursuant to Your use of the Application or availing any
            Services set forth within these User Terms, and shall always remain
            subject to the provisions set forth below. CLM is just an enabler
            for the service and do not have any control over any Applicable Laws
            related to the same.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 11</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            All transactions that are carried out by and on behalf of the
            Customer shall be subject to Applicable Laws governing the offer of
            any service on the CLM Application. CLM does not guarantee or
            warranty that there will be uninterrupted access to and/or use of
            the Payment and Delivery Mechanism. CLM shall not be liable if any
            transaction does not fructify or may not be completed or for any
            failure on part of CLM to perform any of its obligations under these
            User Terms or those applicable specifically to its
            services/facilities if performance is prevented, hindered or delayed
            by a Force Majeure event (defined below) and in such case its
            obligations shall be suspended for so long as the Force Majeure
            event continues.
          </p>
          <p>
            Further, CLM shall not be liable, under any event, for any comments
            or feedback given by any of the Users in relation to the services
            provided by another User. The option of Users to give feedback
            remains at CLM’s sole discretion and may be modified or withdrawn at
            its sole discretion. CLM may moderate such feedback at any time. CLM
            shall not be obliged to act in any manner to give effect to the
            content of Users’ feedback.
          </p>
          <p>
            You understand that the Services provided by CLM or any of its
            licensors or service providers/partners is pursuant to the limited
            ‘Beta’ phase and as such, may be subject to unscheduled downtime,
            bugs, changes, modifications, suspension, or other disruptions, for
            which CLM shall not be liable for any damages, penalties,
            compensation, or any other claims arising there from. These Services
            are provided on an “as is” and “as available” basis, and without any
            warranties or conditions (express or implied, including the implied
            warranties of merchantability, accuracy, fitness for a particular
            purpose, title and non-infringement, arising by statute or otherwise
            under any Applicable Law or from a course of dealing or usage or
            trade). CLM does not provide or make any representation,
          </p>
          <p>
            warranty or guarantee, express or implied about the CLM Application
            or the Services. To the fullest extent permitted by Applicable Law,
            CLM disclaims all liability arising out of the Your use or reliance
            upon the CLM Application, the Services, representations and
            warranties made by other users or any content or information
            provided by the Users on the CLM Application.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 12</label>
          </p>
        </div>
      </div>{" "}
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">
              VIII. NO LIABILITY FOR THIRD PARTY APPLICATIONS / WEBSITES
            </h6>
            <p>
              The CLM Application may be linked to the Application Of third
              parties, affiliates and business partners. However, We are not,
              directly or indirectly, implying any approval, association,
              sponsorship, endorsement, or affiliation with any linked website,
              unless specifically stated herein. We are not responsible for
              examining or evaluating, and We do not warrant the offerings of,
              any businesses or individuals or the content of their websites. We
              do not assume any responsibility or liability for the actions,
              products, services, and content of any other third parties. You
              should carefully review the legal statements and other conditions
              of use of any Application Which you access through a link from
              this CLM Application. Your linking to any other off-Application
              Websites is at your own risk.
            </p>
            <p>
              CLM has no control over, and not liable or responsible for
              content, accuracy, validity, reliability, quality of such websites
              or made available by/through Our CLM Application. CLM assume no
              responsibility, and shall not be liable for, any damages to, or
              viruses that may infect Your equipment or access to Your account,
              use of, or browsing the CLM Application or the downloading of any
              material, data, text, images, video content, or audio content from
              the CLM Application. If You are dissatisfied with the CLM
              Application, your sole remedy is to discontinue using the CLM
              Application.
            </p>
            <p>
              CLM may employ third-party applications to facilitate
              functionalities such as Aadhaar e- sign, E-stamping (as applicable
              to the relevant state / jurisdiction) and Digital Signature
              Certificate (DSC). It is important to clarify that CLM is not
              authorized to generate verified signatures on behalf of Users. CLM
              functions primarily as a contract management application and does
              not execute contract signings on behalf of Users. Furthermore, You
              shall be liable for ensuring the adequacy of the stamp duty /
              E-stamp required to be paid / deposited for any contract executed
              vis-à-vis the Application in accordance with Applicable Laws. CLM
              acts solely in the capacity of a facilitator to procure the
              E-stamp required for the contract at the request of the User.
            </p>
            <p>
              CLM reserves the rights to display sponsored ads on the CLM
              Application. Without prejudice to the status of other content, CLM
              shall not be liable for the accuracy of information or the claims
              made in the Sponsored Listings. CLM does not encourage the Users
              to visit the Sponsored Listings page or to avail any services from
              them. CLM will not be liable for the services of the providers of
              the Sponsored Listings. You represent and warrant that you will
              use these Services in accordance with applicable law. Any
              contravention of applicable law as a result of your use of these
              Services is your sole responsibility, and CLM accept no liability
              for the same.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 13</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">
              IX. OBLIGATIONS OF THE CUSTOMER / USER
            </h6>
            <p>
              The Customer, including its representatives, authorized Users, and
              employees, shall provide all necessary cooperation to facilitate
              the provision of the Services by CLM as may be required by CLM
              from time to time.
            </p>
            <p>The Customer shall:</p>
            <p>
              a. Be responsible for all activities and transmissions solely
              attributable to it and within its control that occur through its
              accounts used to access and use the Application and any Services
              provided through the Application.
            </p>
            <p>
              b. Employ, in respect of all equipment that it controls, screening
              and security procedures and other safeguards necessary on its part
              to securely administer the distribution and use of all access
              credentials and protect against any unauthorized access to or use
              of the Application, as well as the security of its information
              systems used to access and use the Application.
            </p>
            <p>
              c. Not access the Application in excess of the right, prevent any
              third party, including representatives, contractors, and
              employees, from accessing or using the Application, not share
              passwords and/or usernames, and designate system administrator(s)
              to ensure that multiple users do not share a password or username.
            </p>
            <p>
              d. Retain primary responsibility for all data collected from
              individuals for uploading on the Application, including its use;
              all information, instructions, and materials provided in
              connection with the Application; and the accuracy and legality of
              all data.
            </p>
            <p>
              e. Not access or use the Application to store or transmit
              malicious code, files, scripts, agents, or programs intended to do
              harm, including viruses, worms, time bombs, and Trojan horses, and
              be responsible for any security vulnerabilities and the
              consequences of such vulnerabilities arising solely from the
              nature of data, including without limitation any viruses, Trojan
              horses, worms, or other programming routines contained in data
              that could harm or limit the functionality of a computer or that
              could damage, intercept, or expropriate data.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 14</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            f. Not interfere with or disrupt the integrity or performance of the
            Application; not circumvent, bypass, delete, or remove any form of
            protection, or usage, functionality, or technical restrictions or
            limitations, or enable functionality disabled by CLM in connection
            with the Application; not use the Application without the key, if
            provided, or attempt to develop or develop any means or technology
            which would enable Customers to bypass the use of the key to operate
            the Application; not interface or link or include the Application
            with any other systems or applications or services without prior
            express written permission; and not perform or disclose any security
            testing, including penetration testing, remote access testing,
            network discovery, vulnerability scanning, password cracking, etc.
            of the Application, or network the Application or make it available
            for concurrent use without prior express written permission.
          </p>
          <p>
            g. Not directly or indirectly copy, violate, tamper, decompile,
            reverse engineer, or gain unauthorized access, modify, combine with
            other programs, screen scratch, decrypt, ghost, export, make, or
            produce any derivative works, or disassemble the Application or any
            part thereof in any way nor otherwise attempt to create or derive
            the source code related thereto; not use the Application and/or
            CLM&#39;s Confidential Information to enable, support, or otherwise
            aid itself or a third party to develop any product, software, or
            service competitive with any of CLM’s products or services.
          </p>
          <p>
            h. Not remove, alter, modify, deface, conceal, or otherwise render
            illegible any product identification, copyright, proprietary,
            intellectual property notices, or other marks, or proprietary or
            confidentiality markings that may be placed on the Application or
            components thereof or documentation provided to Customers hereunder;
            not rent, lease, lend, sublicense, distribute, publish, display,
            post, transmit, timeshare, host, frame, resell, reproduce, assign,
            transfer, or otherwise incorporate the Application, in whole or in
            part, provide application service provider services or business
            processing services or as a service bureau or software as a service
            on a commercial basis or not, or otherwise provide commercial
            hosting services with the Application; and not sell, attempt to sell
            or transfer, sublicense, encumber the right to use in relation to
            the Application.
          </p>
          <p>
            i. Not intentionally interfere with other CLM&#39;s customers&#39;
            access to, or use of, the Application or the cloud Application on
            which the Application is hosted, or with its security; not
            facilitate the attack or disruption of the Application or the cloud
            on which it is hosted, including a denial of service attack,
            unauthorized access, penetration testing, crawling, or distribution
            of malware (including viruses, trojan horses, worms, time bombs,
            spyware, adware, and cancelbots); not cause an unusual spike or
            increase in its use of the cloud Application that negatively impacts
            the cloud service’s operation; or not submit any information that is
            not contemplated in the applicable documentation.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 15</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">X. INTELLECTUAL PROPERTY OWNERSHIP</h6>
            <p>
              CLM alone (and its licensors, where applicable) shall own all
              right, title and interest, including all related Intellectual
              Property Rights, in and to (i) the Site, Application, product,
              Service and any suggestions, ideas, enhancement requests,
              feedback, recommendations or any other offering; (ii) text,
              graphics, user interfaces, visual interfaces, photographs,
              trademarks, logos, sounds, music, artwork and computer code; or
              (iii) other information provided by You or any other party
              relating to the Site, Application or the Service. Third party
              trademarks may appear on this Site/ Application and all rights
              therein are reserved to the registered owners of those trademarks.
              For use of any third party’s intellectual property, You need to
              get permission directly from the owner of the intellectual
              property for any use.
            </p>
            <p>
              These User Terms do not constitute a sale and do not convey to You
              any rights of ownership in or related to the Site, the Application
              or the Services, or any Intellectual Property Rights owned by CLM.
              You shall be solely responsible for any violations of any
              Applicable Laws and for any infringements of any intellectual
              property rights caused by use of the Services or the Site/
              Application.
            </p>
            <h6 className=" fw-semibold">
              XI. TERM AND TERMINATION OF USER TERMS
            </h6>
            <p>
              Unless terminated, the Agreement between CLM and You is perpetual
              in nature upon downloading the Application and availing the
              Services through the CLM Application.
            </p>
            <p>
              CLM reserves the right to terminate these User Terms and the
              access to the Application during the Beta at any time, which may
              result in the discontinuation of the Services and the deletion of
              any user data. Users should not rely on the continued availability
              of the Services and / or Application during the Beta.
            </p>
            <p>
              Users should be aware that they do not possess the ability to
              independently terminate their CLM account. Requests for account
              termination can be initiated by contacting CLM&#39;s customer
              support team, and such requests will be evaluated based on the
              terms and conditions stipulated in the Agreement.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 16</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            You agree that We shall not be liable for any damages arising from
            interruption, suspension or termination of the CLM Application,
            including but not limited to direct, indirect, incidental, special,
            consequential or exemplary damages, whether such interruption,
            suspension or termination was justified or not, negligent or
            intentional, inadvertent or advertent. Applicable law may not allow
            the limitation or exclusion of liability or incidental or
            consequential damages CLM reserves the right to suspend or terminate
            a User’s access to the CLM Application and the Services with or
            without notice and to exercise any other remedy available under any
            Applicable Laws, in cases where,
          </p>
          <p>
            a. Such User breaches any terms and conditions of the Agreement, is
            in contravention of any Applicable Law, or CLM has reasonable ground
            to suspect such breach or violation of the Application’s security
            protocols;
          </p>
          <p>
            b. A third party reports violation of any of its right as a result
            of your use of the Services.
          </p>
          <p>
            c. CLM is unable to verify or authenticate any information provided
            to CLM by a User;
          </p>
          <p>
            d. CLM has reasonable grounds for suspecting any illegal, fraudulent
            or abusive activity on part of such User; or;
          </p>
          <p>
            e. CLM believes in its sole discretion that User’s actions may cause
            legal liability for such User, other Users or for CLM or are
            contrary to the interests of the CLM or the CLM Application.
          </p>
          <p>
            Once temporarily suspended, indefinitely suspended or terminated,
            the User may not continue to use the CLM Application under the same
            account, a different account or re- register under a new account. On
            termination of an account due to the reasons mentioned herein, such
            User shall no longer have access to any data, messages, files and
            other material kept on the CLM Application by such User.
          </p>
          <p>
            Termination of this agreement will not prejudice accrued rights of
            CLM and / or the disclaimers provided under these User Terms or
            otherwise.
          </p>
          <p>
            Clauses pertaining to Indemnification, No Liability, Intellectual
            Property Ownership, Term and Termination, Notice, Disclaimer and
            Governing Law and Dispute Resolution and such provisions which are
            intended to survive the termination of this Agreement, shall survive
            the in the manner as intended to give effect to such provisions as
            if such User Terms are in effect after such termination.
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 17</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">
              XII. INVALIDITY OF ONE OR MORE PROVISIONS
            </h6>

            <p>
              The invalidity of any term of these User Terms shall not affect
              the validity of the other provisions of these User Terms. If and
              to the extent that any provision of these User Terms is invalid,
              or is unacceptable in the given circumstances, a provision shall
              apply between the parties instead that is acceptable considering
              all the circumstances, taking into account the content and the
              purpose of these User Terms.
            </p>
            <p>
              CONFLICT- In the event of any contradiction or inconsistency
              between this User Terms and any other agreement executed between
              You and CLM, the terms of the User Terms shall prevail unless the
              exception has been expressly agreed to in writing by making
              reference to the relevant Clause sought to be modified under this
              User Terms.
            </p>
            <h6 className=" fw-semibold">
              XIII. IP ADDRESS VERIFICATION AND SERVICE RESTRICTION
            </h6>
            <p>
              To access and use our services, you must not mask or manipulate
              your internet protocol (IP) address during the sign-up process or
              while using our application. Our services are exclusively intended
              for users located in India, and your state/region/country is
              determined based on your IP address. If, at any time, it is
              discovered that your actual region/country differs from the
              region/country in our records due to IP address masking or
              manipulation, your account may be subject to immediate
              termination. Additionally, you may not be eligible to use any of
              our services if you are located outside of India.[Note to CLM:
              Please confirm whether this clause shall also be substantiated
              with user account and administrators of the said account/will
              differ in case of direct access registrations ]
            </p>

            <h6 className=" fw-semibold">XIV. USER GENERATED CONTENT</h6>
            <p>
              .You are responsible for ensuring that you do not accidentally
              make any private content publicly available. Any content that you
              may receive from other users of the Services, is provided to you
              AS IS for your information and personal use only and you agree not
              to use, copy, reproduce, distribute, transmit, broadcast, display,
              sell, license or otherwise exploit such content for any purpose,
              without the express written consent of the person who owns the
              rights to such content. In the course of using any of the
              Services, if you come across any content with copyright notice(s)
              or any copy protection feature(s), you agree not to remove such
              copyright notice(s) or disable such copy protection feature(s) as
              the case may be. By making any copyrighted/copyrightable content
              available on any of the Services you affirm that you have the
              consent, authorization or permission, as the case may be from
              every person who may claim any rights in such content to make such
              content available in such manner. Further, by making any content
              available in the manner aforementioned, you expressly agree that
              CLM will have the right to block access to or remove such content
              made available by you if CLM receives complaints concerning any
              illegality or infringement of third party rights in such content.
              By using any of the Services and transmitting or publishing any
              content using such Service, you expressly consent to determination
              of questions of illegality or infringement of third party rights
              in such content by the agent designated by CLM for this purpose.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 18</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">XV. DISCLAIMER</h6>

            <p>
              CLM does not make any representations or warranties vis-à-vis the
              Application or the Services, express, implied, or statutory,
              including, without limitation, any implied warranties of
              merchantability, quality, fitness for a particular purpose, system
              integration, accuracy, reliability, error-free operation,
              uninterrupted operation, support, correction, or repair (unless
              otherwise agreed). CLM does not guarantee that the Application or
              the Services provided hereunder will operate flawlessly in every
              combination or environment desired by the Customer, nor that using
              the Application with any data, devices, computer systems, or
              programs of the Customer&#39;s choice will be without issues.
              Furthermore, the CLM does not guarantee that the remedying of one
              program error will not result in the occurrence of other program
              errors. All such representations and warranties are hereby
              disclaimed.
            </p>
            <p>
              Users participating in the Beta program, may have access to
              confidential information, this includes, but is not limited to,
              scientific or technical information such as data, drawings,
              designs, concepts, specifications, patent applications (whether in
              draft or final form), software, interfaces, plans, layouts,
              components, models, features, prototypes, processes, procedures,
              trade secrets, know-how, processes, methodologies, samples,
              components, analyses, compilations, guides, and other information
              or documents prepared by CLM, its subsidiaries, affiliates, and/or
              their officers, servants, agents, representatives, employees, or
              advisers that contain or are generated from CLM proprietary
              information related to the Application. Users agree not to
              disclose, share, or disseminate any confidential information
              encountered during the beta testing phase. Violation of this
              provision may result in legal action.
            </p>

            <p>
              Although all efforts are made to ensure that information and
              content provided as part of the application are correct at the
              time of inclusion, there is no guarantee of the accuracy of the
              information. Users must independently determine the suitability of
              the information for their purposes and use it at their own risk.
            </p>

            <p>
              This application is designed solely as a web-based contract
              management tool intended for the convenience, management and
              organization of contracts and related information. It does not
              possess the capability to independently create, sign, or execute
              contracts. All actions related to contract creation, signing, and
              execution are the sole responsibility of the Users utilizing the
              Application. All costs associated with the execution of any
              contract vis-à-vis the Application / Services, including (without
              limitation) the procurement of stamp paper / payment of stamp
              duty, shall be solely attributable to You.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 19</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <p>
              CLM, including its developers, operators, and affiliates, hereby
              disclaims any and all liability in the event of misfunctioning,
              errors, inaccuracies, or any adverse consequences that may arise
              from the use of this application. Users understand and acknowledge
              that the application may not be error-free, and CLM cannot
              guarantee its flawless performance or the correctness of the data
              and information processed through it.
            </p>
            <p>
              Users of this application assume full responsibility for the
              accuracy, legality, and validity of any contracts or information
              managed using this tool. Users are strongly encouraged to seek
              independent legal counsel and review contracts and related
              materials before signing or executing them. This application is
              not a substitute for professional legal advice, and its use does
              not replace the need for careful legal consideration in
              contractual matters. By using this Beta version of the Application
              (and the Services thereto), Users acknowledge and accept these
              disclaimers and limitations. Your participation in the beta
              testing program is voluntary, and you agree to use the application
              at your own risk. Your feedback and insights are highly
              appreciated and will contribute to the improvement of the final
              release version.
            </p>
            <p>
              CLM does not make representations or warranties as to the
              fairness, completeness, or accuracy of information on the
              application. There is no commitment to update or correct any
              information that appears on the Internet or within the
              application. Information is supplied upon the condition that the
              persons receiving the same will make their own determination as to
              its suitability for their purposes prior to use or in connection
              with the making of any decision. Any use of the application or the
              information is at your own risk.
            </p>
            <h6 className=" fw-semibold">XVI. AUTHORITY TO CLM</h6>
            <p>
              The Customer/You hereby authorizes CLM to carry out his
              instructions, effecting such transactions as may be permitted by
              CLM from time to time, in accordance with these User Terms and
              such other terms as may be specified by.
            </p>
            <h6 className=" fw-semibold">XVII. EVIDENCE OF TRANSACTIONS</h6>
            <p>
              For any transaction which may be permitted by CLM from time to
              time, CLM’s own records of such transactions maintained through
              computer systems or otherwise, shall be accepted as conclusive and
              binding for all purposes. The record of the transaction as
              generated from the systems of CLM shall be conclusive proof of the
              genuineness and accuracy of such transactions.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 20</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">XVIII. RESTRICTIONS ON USE</h6>
            <p>
              CLM owns and holds all the rights for the information, contents,
              audio, video, logos and trademarks contained in this CLM
              Application or related to the Services. Any reproduction,
              modification, creation of derivate works, distribution,
              transmission, copying, selling, displaying, publishing or using
              any of the information, contents, audio, video, logos and
              trademarks contained in this CLM Application (or the Services
              thereto) for any purpose whatsoever, whether electronically or
              otherwise, without the prior written permission of CLM is strictly
              prohibited. Any violation of this provision would be strictly
              dealt with. You may download material displayed on this CLM
              Application for your use only, provided that you also retain the
              clauses pertaining to all copyright and other proprietary notices
              contained in the materials.
            </p>
            <p>You acknowledge and affirms that You shall not:</p>
            <p>
              a. Engage in fraudulent, abusive or illegal activity, including
              but not limited to any communication or solicitation designed or
              intended to fraudulently obtain the password or any private
              information of any use.
            </p>
            <p>
              b. Use the CLM Application to violate the security of any computer
              network, crack passwords or security encryption codes, transfer or
              store illegal material including threatening or obscene material
              or engage in any kind of illegal activity.
            </p>
            <p>
              Although CLM may, from time to time, monitor or review any
              facilities, if established or otherwise offered at the CLM
              Application for discussions, chats, postings, transmissions,
              bulletin boards, and the like on the CLM Application, CLM is under
              no obligation to do so and assumes no responsibility or liability
              arising from the content of any such locations nor for any error,
              defamation, libel, slander, omission, falsehood, obscenity,
              pornography, profanity, danger, or inaccuracy contained in any
              information contained within such locations on the CLM
              Application. You are prohibited from posting or transmitting any
              unlawful, threatening, libelous, defamatory, obscene, scandalous,
              inflammatory, pornographic, or profane material or any material
              that could constitute or encourage conduct that would be
              considered a criminal offense, give rise to civil liability, or
              otherwise violate any law. CLM will fully cooperate with any law
              enforcement authorities or court order requesting or directing CLM
              to disclose the identity of anyone posting any such information or
              materials.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 21</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">XIX. USE OF INFORMATION</h6>
            <p>
              CLM maintains the right to securely store and manage User
              signatures and data with the primary aim of enhancing the overall
              user experience and for conducting data analytics. This is done in
              compliance with applicable data protection laws.
            </p>
            <p>
              User data may be used by CLM to improve service quality,
              personalize user experiences, meet legal and regulatory
              obligations, and for other purposes, all of which are detailed in
              CLM&#39;s Privacy Policy.
            </p>
            <h6 className=" fw-semibold">XX. RESERVATION OF RIGHTS</h6>
            <p>
              We reserve the right to change, modify, add to, or remove
              discounts, portions of these terms of use at any time, subject to
              IRDAI regulations as amended time to time &amp; CLM’s policy.
            </p>
            <h6 className=" fw-semibold">XXI. PRIVACY POLICY</h6>
            <p>
              In the process of using this CLM Application, the Privacy Policy
              as set forth and made available at will be applicable.
            </p>
            <h6 className=" fw-semibold">
              {" "}
              XXII. MODIFICATION OF THE SERVICE AND USER TERMS
            </h6>
            <p>
              CLM reserves the right, at its sole discretion, to modify or
              replace, in part or full, any of these User Terms, or change,
              suspend, block, discontinue or restrict Your use to all or any
              feature of the Service or CLM Application at any time.
            </p>
            <p>
              CLM shall not be required to notify You of any changes made to
              these User Terms. The revised User Terms shall be made available
              on the Site. You are requested to regularly visit the Application
              to view the most current User Terms. It shall be Your
              responsibility to check these User Terms periodically for changes.
              Your continued use of the CLM Application constitutes acceptance
              of the changes and an Agreement to be bound by User Term, as
              amended.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 22</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">XXIII. NOTICE</h6>
            <p>
              Notices under these User Terms may be given by CLM to the Customer
              in writing by delivering them to the last address given by the
              Customer. Customer may give notice to CLM by delivering them to
              the address mentioned on CLM Application. In the case of hand
              delivery, cable, telex, facsimile notices or electronic
              communication notices or instructions will be deemed served 7
              (Seven) days after posting or upon receipt as the case may be.
              Documents, which may be sent by electronic communication between
              the parties, may be in the form of an electronic mail, an
              electronic mail attachment, or in the form of an available
              download from the CLM Application. CLM shall be deemed to have
              fulfilled any legal obligation to deliver to the Customer if any
              such document is sent via electronic delivery.
            </p>
            <h6 className=" fw-semibold">XXIV. EXCLUSIVE AGREEMENT</h6>
            <p>
              You agree that these User Terms (read with the Privacy Policy) are
              the complete and exclusive statement of agreement supersede any
              proposal or prior agreement, oral or written, and any other
              communications between you and CLM relating to the subject matter
              of these User Terms. These User Terms, as the same may be amended
              from time to time, will prevail over any subsequent oral
              communications between you and the CLM Application and/or CLM.
            </p>
            <h6 className=" fw-semibold">XXV. ASSIGNMENT</h6>
            <p>
              You shall not assign Your rights under these User Terms without
              prior written approval of CLM. CLM can assign its rights under the
              User Terms to any affiliate or other third parties.
            </p>
            <h6 className=" fw-semibold">XXVI. NO WAIVER</h6>
            <p>
              CLM or any of its third party service providers etc. shall not be
              deemed to have waived any of its / their rights or remedies
              hereunder, unless such waiver is in writing. No delay or omission
              on the part of CLM or service providers, in exercising any rights
              or remedies shall operate as a waiver of such rights or remedies
              or any other rights or remedies. A waiver on any one occasion
              shall not be construed as a bar or waiver of any rights or
              remedies on future occasions.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 23</label>
          </p>
        </div>
      </div>
      <div className="mb-5 termOfUsePage">
        <div
          className={` d-flex align-items-center justify-content-end  ${
            isMobile ? " py-0" : "pt-4 pe-5"
          }`}
        >
          <a href="https://provider.clm.in" target="_blank">
            <img
              src={logo}
              alt="logo"
              width={80}
              className="logo"
              style={{ cursor: "pointer" }}
              onClick={() => {}}
            />
          </a>
        </div>
        <h6 className=" fw-bold text-center pb-3 termsColor">TERMS OF USE</h6>
        <div className=" container px-5 termsOfUse">
          <p>
            <h6 className=" fw-semibold">
              XXVII. GOVERNING LAW AND DISPUTE RESOLUTION
            </h6>
            <p>
              These User Terms are subject to and shall be governed by the laws
              of India. Any dispute, claim or controversy arising out of or
              relating to these User Terms or the breach, termination,
              enforcement, interpretation or validity thereof or the use of the
              Site, the Service or the Application (collectively, “Disputes”)
              the parties shall attempt to settle the same amicably, through
              negotiation and consultation at such offices of CLM as CLM may
              designate. In the event the dispute is not resolved internally
              between after at least 30 (thirty) days of negotiation, in good
              faith, the same shall be subject to binding and final arbitration
              in accordance with the Arbitration and Conciliation Act, 1996 as
              amended from time to time or in case the Arbitration and
              Conciliation Act, 1996 is no longer in force, as per any law
              relating to arbitration in force at the time of such reference.
              The reference shall be made to a sole arbitrator appointed
              mutually by the parties. The place of the arbitration shall be
              Bengaluru, Karnataka, unless otherwise mutually agreed by CLM and
              You in writing. Subject to the above, any Dispute will be subject
              to the exclusive jurisdiction of courts in Bangalore, India.
            </p>
            <h6 className=" fw-semibold">XXVIII. JURISDICTIONAL ISSUES</h6>
            <p>
              Unless otherwise expressly set forth herein, CLM makes no
              representation that materials on this CLM Application are
              appropriate or available for use in any location. Those who choose
              to access this CLM Application do so on their own initiative and
              are responsible for compliance with Applicable Laws.
            </p>
            <h6 className=" fw-semibold">XXIX. CONTACT</h6>
            <p>
              You hereby provide Your express consent so as to enable and / or
              authorize CLM to send SMS, push notifications and email on the
              registered email ID (made available by you on Our CLM Application)
              and offer you information and services for the product/Services
              you have opted for. You authorize Us to send SMS, push
              notification and email You for the mentioned purpose, irrespective
              of whether you register with the NDNC Registry. CLM may from time
              to time send by e-mail or otherwise, information relating to
              products and services offered by its Affiliates, general
              information related to financial and other services, life and
              general insurance products. For sake of clarification,
              abovementioned right of CLM shall continue to be in effect
              irrespective of Your termination of Agreement/deletion of Your
              Account. [Note to CLM: Please confirm.]
            </p>
            <h6 className=" fw-semibold">XXX. GRIEVANCES</h6>
            <p>
              For grievances, please see Grievance Redressal mechanism and / or
              contact us at ___________. During the Beta, CLM will offer email
              support to Users. This support is intended to assist Users with
              inquiries, address any issues they encounter, and gather valuable
              feedback related to the Application performance and functionality.
            </p>
          </p>

          <p className=" d-flex justify-content-between">
            <label>
              Confidential; ©2023, CLM Private Limited. All rights reserved
            </label>
            <label>Page 24</label>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
