import React from "react";
import { useState } from "react";
import { Form, FormGroup, Button } from "react-bootstrap";
// import { useBootStrapLayout } from "../../../context/BootstrapLayoutProvider"
import { useSlate } from "slate-react";
import { insertLink } from "../Link";
import styles from "./LinkToast.module.css";

const LinkToast = ({ show, setShow }: any) => {
  return (
    <Toast trigger={show}>
      <ToastBody setShow={setShow} />
    </Toast>
  );
};

export default LinkToast;

const ToastBody = ({ setShow }: any) => {
  const [value, setValue] = useState("");
  const [text, setText] = useState("");
  const editor = useSlate();

  const handleLink = () => {
    if (text) {
      insertLink(editor, value, text);
    } else {
      insertLink(editor, value, value);
    }
    setShow(false);
  };

  return (
    <React.Fragment>
      <div
        className=" p-4"
        style={{
          background: "#fefefe",
          boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
          minWidth: "350px",
        }}
      >
        <FormGroup className="pb-3 roleForm position-relative">
          <Form.Label>Text</Form.Label>
          <Form.Control
            value={text}
            onChange={(e: any) => setText(e.target.value)}
            name="text"
            type="text"
            className="form-control"
          />
        </FormGroup>
        <FormGroup className="pb-3 roleForm position-relative">
          <Form.Label>Link</Form.Label>
          <Form.Control
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            name="value"
            type="text"
            className="form-control"
          />
        </FormGroup>
        <div className="d-flex justify-content-end pt-3">
          <Button className="cancelbtn me-3" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            disabled={!value}
            className="saveBtn "
            onClick={() => handleLink()}
          >
            Apply
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const Toast = ({ trigger, children }: any) => {
  // const { isMobile } = useBootStrapLayout()

  return trigger ? (
    <React.Fragment>
      <div className={styles.popupLink}>
        <div className={styles.toastInner + "p-4"}>{children}</div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
