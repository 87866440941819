import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { useContract } from "../Contracts/ContractProvider";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { RiSubtractFill } from "react-icons/ri";
import { routes } from "../../utils/routes";
import html2canvas from "html2canvas";

function SignaturePopup({
  show,
  onHide,
  handleSign,
  setInitialName,
  getUserSignature,
  setSignatureDetail,
  isActiveSignature,
  setIsActiveSignature,
  setAddSignature,
  addSignature,
  initialName,
  setLocation,
  desigination,
  setDesigination,
  locations,
  setIsLocationActive,
  setShowFailureToast,
  setShowSuccessToast,
  setBodyMessage,
}: // uploadFile,
any) {
  const authContext = useAuthentication();
  const context = useContract();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  let initialRequired: any =
    context?.data?.internalSingers?.find(
      (el: any) => el?.user?.sub === authContext?.currentUser?.sub
    ) ||
    context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );

  const [sign, setSign] = useState<any>();

  const [isActiveNavbar, setIsActiveNavbar]: [string, Function] =
    useState("type");
  const [typeSignatureValue, setTypeSignatureValue]: [string, Function] =
    useState("");
  const [fieldRequiredArray, setFieldRequiredArray]: [any, Function] = useState(
    []
  );
  const [fileName, setFileName]: [any, Function] = useState();
  const [formDataUpload, setFormDataUpload]: [any, Function] = useState();
  const [isAcceptCheckBox, setAcceptCheckBox]: [boolean, Function] =
    useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState("");
  const handleGenerate = () => {
    handleSign({
      filePath:
        initialRequired?.co_ordinates?.filter(
          (item: any) => item?.type === "SIGN"
        )?.length === 0
          ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
          : sign?.getTrimmedCanvas().toDataURL("image/png"),
      type: "accept",
      location: locations,
    });
    onHide();
  };

  const handleClear = () => {
    handleSign({
      type: "reject",
    });
    sign.clear();
  };

  useEffect(() => {
    setInitialName(authContext?.currentUser?.name);
  }, []);

  const handleUploadSignaturebyType = async () => {
    try {
      const image = new Image();

      if (textRef.current) {
        html2canvas(textRef.current).then(async (canvas) => {
          image.src = canvas.toDataURL("image/png");
          const formData = new FormData();

          formData.set("file", base64ImageToBlob(image.src));
          formData.set("type", isActiveNavbar?.toUpperCase());
          formData.set("accept", "true");
          formData.set("initials", initialName);
          formData.set(
            "designation",
            desigination &&
              fieldRequiredArray?.co_ordinates
                ?.map((el: any) => el?.type)
                ?.includes("DESIGNATION")
              ? desigination
              : ""
          );
          formData.set(
            "adress",
            locations &&
              fieldRequiredArray?.co_ordinates
                ?.map((el: any) => el?.type)
                ?.includes("ADRESS")
              ? locations
              : ""
          );

          let response = await context?.handleDocumentSign(id, formData);

          if (response?.data?.success) {
            navigate(
              generatePath(routes.overviewContracts, {
                id: id,
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 400);
          }
          document.body.appendChild(image);
        });
      }
    } catch (error) {}
  };

  const handleUserSignatureUpload = async (e: any) => {
    if (location.pathname.includes("overview")) {
      try {
        const formData = new FormData();
        formData.set("file", e.target.files[0]);
        formData.set("type", isActiveNavbar?.toUpperCase());
        formData.set("accept", "true");
        formData.set("initials", initialName);
        formData.set(
          "adress",
          locations &&
            fieldRequiredArray?.co_ordinates
              ?.map((el: any) => el?.type)
              ?.includes("ADRESS")
            ? locations
            : ""
        );
        setFileName(e.target.files[0]?.name);
        setFormDataUpload(formData);
        return;
        let response = await context?.handleDocumentSign(id, formData);
        if (response?.data?.success) {
          navigate(
            generatePath(routes.overviewContracts, {
              id: id,
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      } catch (error) {}
    } else {
      try {
        const formData = new FormData();
        formData.set("file", e.target.files[0]);
        formData.set("type", isActiveNavbar?.toUpperCase());

        let response = await authContext?.handleUploadUserSignature(formData);

        if (response?.data?.success) {
          onHide();
          getUserSignature();
          setShowSuccessToast(true);
          setBodyMessage("File uploaded successfully.");
        } else {
          setShowFailureToast(true);
          setBodyMessage(response?.data?.error?.[0]);
        }
      } catch (error) {}
    }
  };

  const handleFileUpload = async () => {
    try {
      let response = await context?.handleDocumentSign(id, formDataUpload);

      if (response?.data?.success) {
        navigate(
          generatePath(routes.overviewContracts, {
            id: id,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 400);
      } else {
        setErrorMessage(response?.data?.error?.[0]);
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
      }
    } catch (error) {}
  };

  const base64ImageToBlob = (str: string) => {
    let pos = str.indexOf(";base64,");
    let type = str.substring(5, pos);
    let b64 = str.substr(pos + 8);
    let imageContent = atob(b64);
    let buffer = new ArrayBuffer(imageContent.length);
    let view = new Uint8Array(buffer);
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    let blob = new Blob([buffer], { type: type });

    return blob;
  };

  const textRef = useRef(null);

  const handleUploadSignature = async () => {
    try {
      if (isActiveNavbar === "type") {
        const image = new Image();

        if (textRef.current) {
          html2canvas(textRef.current).then(async (canvas) => {
            image.src = canvas.toDataURL("image/png");
            const formData = new FormData();

            formData.set("file", base64ImageToBlob(image.src));
            formData.set("type", isActiveNavbar?.toUpperCase());
            let response = await authContext?.handleUploadUserSignature(
              formData
            );
            if (response?.data?.success) {
              onHide();
              getUserSignature();
            }
            document.body.appendChild(image);
          });
        }
      } else if (isActiveNavbar === "draw") {
        const formData = new FormData();
        formData.set("type", isActiveNavbar?.toUpperCase());

        formData.set(
          "file",
          base64ImageToBlob(sign?.getTrimmedCanvas().toDataURL("image/png"))
        );

        let response = await authContext?.handleUploadUserSignature(formData);
        if (response?.data?.success) {
          onHide();
          getUserSignature();
        }
      }
    } catch (error) {}
  };

  const [signatureList, setSignatureList]: [any, Function] = useState([]);

  const handlegetUserSignature = async () => {
    try {
      let response = await authContext?.handleGetUserSinature();
      if (response?.success) {
        setSignatureList(response?.sign);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handlegetUserSignature();
  }, []);
  useEffect(() => {
    let fieldRequired: any =
      context?.data?.internalSingers?.find(
        (el: any) => el?.user?.email === authContext?.currentUser?.email
      ) ||
      context?.data?.externalUser?.find(
        (el: any) => el?.email === authContext?.currentUser?.email
      );

    setFieldRequiredArray(fieldRequired);
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes("overview")) {
      let signatureListLength: any = signatureList?.find(
        (el: any) => el?.type === "DRAW"
      );
      let uploadSignatureLength: any = signatureList?.find(
        (el: any) => el?.type === "UPLOAD"
      );
      let signatureLength: any = signatureList?.find(
        (el: any) => el?.type === "TYPE"
      );

      if (
        isActiveNavbar === "type" &&
        (signatureLength?.signatures?.length === 0 ||
          signatureLength?.length == undefined)
      ) {
        setAddSignature(true);
      }
      if (
        isActiveNavbar === "draw" &&
        (uploadSignatureLength?.signatures?.length === 0 ||
          uploadSignatureLength?.length == undefined)
      ) {
        setAddSignature(true);
      }
      if (
        isActiveNavbar === "upload" &&
        (signatureListLength?.signatures?.length === 0 ||
          signatureListLength?.length == undefined)
      ) {
        setAddSignature(true);
      }
    }
  }, [signatureList, isActiveNavbar]);

  useEffect(() => {
    if (
      fieldRequiredArray?.co_ordinates
        ?.map((el: any) => el?.type)
        ?.includes("ADRESS")
    ) {
      setIsLocationActive(true);
    }
  }, [fieldRequiredArray]);

  const clearSignature = () => {
    if (sign) {
      sign.clear(); // Clear the signature data
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => onHide()} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className=" fw-bold ">Signature</h6>
            {
              <>
                <ul className="d-flex justify-content-between  pt-2 m-0">
                  <li className="me-4 list_style_none">
                    <div
                      className={` header-css ${
                        isActiveNavbar === "type" ? "  active" : ""
                      }`}
                      onClick={() => {
                        setIsActiveNavbar("type");
                        setAcceptCheckBox(false);
                      }}
                      style={{ fontSize: "16px" }}
                    >
                      Type
                    </div>
                  </li>
                  <li className="me-4 list_style_none">
                    <div
                      className={` header-css ${
                        isActiveNavbar === "draw" ? "  active" : ""
                      }`}
                      onClick={() => {
                        setIsActiveNavbar("draw");
                        setAcceptCheckBox(false);
                      }}
                      style={{ fontSize: "16px" }}
                    >
                      Draw
                    </div>
                  </li>

                  <li className="me-4 list_style_none">
                    <div
                      className={` header-css ${
                        isActiveNavbar === "upload" ? "  active" : ""
                      }`}
                      onClick={() => {
                        setIsActiveNavbar("upload");
                        setAcceptCheckBox(false);
                      }}
                      style={{ fontSize: "16px" }}
                    >
                      Upload
                    </div>
                  </li>
                </ul>
              </>
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-4">
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="col-md-12 col-12 ">
                <label className="input_label">Name</label>
                <input
                  type="text"
                  name="name"
                  disabled
                  defaultValue={authContext?.currentUser?.name}
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              {isActiveNavbar === "type" &&
                location.pathname.includes("profile") && (
                  <>
                    <div className="col-md-12 col-12 mt-3 ">
                      <label className="input_label">Type your signature</label>
                      <input
                        ref={textRef}
                        type="text"
                        name="name"
                        defaultValue={
                          typeSignatureValue
                            ? typeSignatureValue
                            : authContext?.currentUser?.name
                        }
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) =>
                          setTypeSignatureValue(e?.target?.value)
                        }
                      />
                    </div>
                  </>
                )}

              {isActiveNavbar === "upload" &&
                location?.pathname?.includes("profile") && (
                  <div className="col-md-12 col-12 mt-3 ">
                    <label className="input_label">Upload File</label>
                    <div
                      className="drag_and_drop_signature d-flex justify-content-center align-items-center flex-column "
                      style={{ border: "2px dashed #3c3ada9c" }}
                    >
                      <div className=" fw-semibold"> Drag and drop file</div>
                      <div className=" fw-semibold text-center">Or</div>
                      <div
                        className=" position-relative overflow-hidden px-3 py-2 fw-semibold"
                        style={{
                          background: "#3c3ada",
                          color: "#fefefe",
                          width: "120px",
                        }}
                      >
                        Select a file
                        <div className="signatureChooseFile">
                          <input
                            type="file"
                            onChange={handleUserSignatureUpload}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {isActiveNavbar === "draw" &&
                location?.pathname?.includes("profile") && (
                  // ||
                  //   location.pathname.includes("overview"))
                  <div className="col-md-12 col-12 mt-3 ">
                    <label className="input_label">Draw Your Signature</label>
                    <div className="popup-css">
                      <div className="row">
                        <div className="col">
                          <SignatureCanvas
                            canvasProps={{
                              className: "sigCanvas w-206",
                            }}
                            ref={(data: any) => {
                              setSign(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {isActiveNavbar === "stamp" && (
                <>
                  <div className="col-md-12 col-12 mt-3 ">
                    <label className="input_label">Price</label>
                    <input
                      type="text"
                      name="price"
                      className="form-control"
                      placeholder="Type here"
                    />
                  </div>
                  <div className="col-md-12 col-12 mt-3 ">
                    <label className="input_label">Lorem ipsum</label>
                    <input
                      type="text"
                      name="price"
                      className="form-control"
                      placeholder="Type here"
                    />
                  </div>
                  <div className="col-md-12 col-12 mt-3 ">
                    <label className="input_label">Lorem ipsum</label>
                    <input
                      type="text"
                      name="price"
                      className="form-control"
                      placeholder="Type here"
                    />
                  </div>
                </>
              )}

              {!location.pathname.includes("profile") && (
                <>
                  {fieldRequiredArray?.co_ordinates
                    ?.map((el: any) => el?.type)
                    ?.includes("INITIAL") && (
                    <>
                      <div className="col-md-12 col-12 mt-3">
                        <label className="input_label">Initials</label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={authContext?.currentUser?.name}
                          className="form-control"
                          placeholder="Name"
                          onChange={(e) => setInitialName(e?.target?.value)}
                        />
                      </div>
                    </>
                  )}

                  {fieldRequiredArray?.co_ordinates
                    ?.map((el: any) => el?.type)
                    ?.includes("ADRESS") && (
                    <>
                      <div className="col-md-12 col-12 mt-3">
                        <label className="input_label">Location</label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={locations}
                          className="form-control"
                          placeholder="Please enter your location"
                          onChange={(e) => setLocation(e?.target?.value)}
                        />
                      </div>
                    </>
                  )}
                  {fieldRequiredArray?.co_ordinates
                    ?.map((el: any) => el?.type)
                    ?.includes("DESIGNATION") && (
                    <>
                      <div className="col-md-12 col-12 mt-3">
                        <label className="input_label">Designation</label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={desigination}
                          className="form-control"
                          placeholder="Please enter your designation"
                          onChange={(e) => setDesigination(e?.target?.value)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {addSignature && signatureList?.length === 0 && (
                <>
                  {isActiveNavbar === "type" && (
                    <>
                      <div className="col-md-12 col-12  mt-3 ">
                        <label className="input_label">
                          Type your signature
                        </label>
                        <input
                          ref={textRef}
                          type="text"
                          name="name"
                          defaultValue={
                            typeSignatureValue
                              ? typeSignatureValue
                              : authContext?.currentUser?.name
                          }
                          className="form-control"
                          placeholder="Name"
                          onChange={(e) =>
                            setTypeSignatureValue(e?.target?.value)
                          }
                        />
                      </div>
                    </>
                  )}
                  {isActiveNavbar === "draw" && (
                    <>
                      <div className="col-md-12 col-12 mt-3 ">
                        <div className=" d-flex justify-content-between align-items-center pb-3">
                          <label className=" fw-semibold ">
                            Draw Your Signature
                          </label>
                          <div
                            onClick={clearSignature}
                            className=" text-danger cursor-pointer"
                          >
                            Clear
                          </div>
                        </div>

                        <div className="popup-css">
                          <div className="row">
                            <div className="col">
                              <SignatureCanvas
                                canvasProps={{
                                  className: "sigCanvas w-206",
                                }}
                                ref={(data: any) => {
                                  setSign(data);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {isActiveNavbar === "upload" && (
                    <>
                      <div className="col-md-12 col-12 mt-3 ">
                        <label className="input_label">
                          Upload File{" "}
                          {fileName && (
                            <span className="file_name">{fileName}</span>
                          )}
                        </label>
                        <div
                          className="drag_and_drop_signature d-flex justify-content-center align-items-center flex-column "
                          style={{ border: "2px dashed #3c3ada9c" }}
                        >
                          <div className=" fw-semibold">
                            {" "}
                            Drag and drop file
                          </div>
                          <div className=" fw-semibold text-center">Or</div>
                          <div
                            className=" position-relative overflow-hidden px-3 py-2 fw-semibold"
                            style={{
                              background: "#3c3ada",
                              color: "#fefefe",
                              width: "120px",
                            }}
                          >
                            Select a file
                            <div className="signatureChooseFile">
                              <input
                                type="file"
                                accept=".jpg,.png,.peg"
                                onChange={handleUserSignatureUpload}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {signatureList?.length > 0 &&
                !location?.pathname?.includes("profile") && (
                  <>
                    <div>
                      <label className=" fw-semibold mt-3 d-flex justify-content-between align-items-center">
                        {signatureList?.filter(
                          (list: any) =>
                            list?.type === isActiveNavbar?.toUpperCase()
                        )?.length !== 0 &&
                        signatureList?.find(
                          (list: any) =>
                            list?.type === isActiveNavbar?.toUpperCase()
                        )?.signatures?.length ? (
                          <>
                            {
                              <>
                                {!addSignature ? (
                                  <>Select Signature</>
                                ) : (
                                  <>&nbsp;</>
                                )}

                                <span
                                  style={{
                                    color: "#3c3ada",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setAddSignature(!addSignature)}
                                >
                                  {addSignature ? (
                                    <MdAdd fontSize={15} />
                                  ) : (
                                    <RiSubtractFill fontSize={15} />
                                  )}
                                  Add Signature
                                </span>
                              </>
                            }
                          </>
                        ) : (
                          <></>
                        )}
                      </label>

                      {!addSignature ? (
                        <>
                          <div
                            className="row "
                            style={{ maxHeight: "25vh", overflowY: "scroll" }}
                          >
                            {signatureList?.map((list: any) => {
                              return (
                                <>
                                  {list?.type ===
                                    isActiveNavbar?.toUpperCase() && (
                                    <>
                                      {list?.signatures?.map(
                                        (el: any, index: any) => {
                                          return (
                                            <>
                                              <div
                                                className="col-md-4 col-12 mt-3"
                                                onClick={() => {
                                                  setIsActiveSignature(index);
                                                  setSignatureDetail(el);
                                                }}
                                              >
                                                <div
                                                  className={`border p-2 signBox ${
                                                    isActiveSignature === index
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <h6 className=" fw-semibold">
                                                    Signature Name
                                                  </h6>

                                                  <div className=" d-flex justify-content-center align-items-center signImage ">
                                                    <img src={el} alt="list" />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          {isActiveNavbar === "type" && (
                            <>
                              <div className="col-md-12 col-12">
                                <label className="input_label">
                                  Type your signature
                                </label>
                                <input
                                  ref={textRef}
                                  type="text"
                                  name="name"
                                  defaultValue={
                                    typeSignatureValue
                                      ? typeSignatureValue
                                      : authContext?.currentUser?.name
                                  }
                                  className="form-control"
                                  placeholder="Name"
                                  onChange={(e) =>
                                    setTypeSignatureValue(e?.target?.value)
                                  }
                                />
                              </div>
                            </>
                          )}
                          {isActiveNavbar === "draw" && (
                            <>
                              <div className="col-md-12 col-12 mt-3 ">
                                <label className="input_label">
                                  Draw Your Signature
                                </label>
                                <div className="popup-css">
                                  <div className="row">
                                    <div className="col">
                                      <SignatureCanvas
                                        canvasProps={{
                                          className: "sigCanvas w-206",
                                        }}
                                        ref={(data: any) => {
                                          setSign(data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {isActiveNavbar === "upload" && (
                            <>
                              <div className="col-md-12 col-12 mt-3 ">
                                <label className="input_label">
                                  Upload File
                                </label>
                                <div
                                  className="drag_and_drop_signature d-flex justify-content-center align-items-center flex-column "
                                  style={{ border: "2px dashed #3c3ada9c" }}
                                >
                                  <div className=" fw-semibold">
                                    {" "}
                                    Drag and drop file
                                  </div>
                                  <div className=" fw-semibold text-center">
                                    Or
                                  </div>
                                  <div
                                    className=" position-relative overflow-hidden px-3 py-2 fw-semibold"
                                    style={{
                                      background: "#3c3ada",
                                      color: "#fefefe",
                                      width: "120px",
                                    }}
                                  >
                                    Select a file
                                    <div className="signatureChooseFile">
                                      <input
                                        type="file"
                                        onChange={handleUserSignatureUpload}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
            </Form.Group>
          </Form>
          {location.pathname.includes("overview") && (
            <>
              <div className="d-flex sign_upload">
                <input
                  type="checkbox"
                  id="vehicle1 "
                  checked={isAcceptCheckBox}
                  name="vehicle1"
                  className="checkbox_upload_img"
                  onChange={() => setAcceptCheckBox(!isAcceptCheckBox)}
                />
                <label htmlFor="vehicle1">
                  I understand that by clicking the "Yes, sign the contract"
                  button I will be electronically signing the document. I have
                  read and understood the document. I agree to electronically
                  sign where it is required in the document and agree to be
                  bound by them.{" "}
                </label>
              </div>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </>
          )}

          {location.pathname.includes("profile") ? (
            <>
              <div className=" d-flex justify-content-end align-items-center pt-4">
                <Button className="cancelbtn me-3" size="sm" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  className="sharebtndanger"
                  size="sm"
                  onClick={
                    location?.pathname?.includes("profile")
                      ? handleUploadSignature
                      : handleGenerate
                  }
                >
                  Add Signature
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className=" d-flex justify-content-end align-items-center pt-4">
                <Button
                  className="cancelbtn me-3"
                  size="sm"
                  onClick={handleClear}
                >
                  No, do not sign it
                </Button>
                <Button
                  className="sharebtndanger"
                  size="sm"
                  disabled={
                    !(
                      isAcceptCheckBox &&
                      (!!fileName ||
                        sign?.getTrimmedCanvas().toDataURL("image/png") ||
                        (isActiveNavbar === "type" &&
                          authContext?.currentUser?.name))
                    )
                  }
                  onClick={() =>
                    location.pathname.includes("overview") &&
                    isActiveNavbar === "upload"
                      ? handleFileUpload()
                      : isActiveNavbar === "type"
                      ? handleUploadSignaturebyType()
                      : handleGenerate()
                  }
                >
                  Yes, sign the contract
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignaturePopup;
