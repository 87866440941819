import { ReactElement, useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { BsBuildings } from "react-icons/bs";
import { FaSignature } from "react-icons/fa";
import { FiHelpCircle, FiUserCheck } from "react-icons/fi";
import { MdLogout, MdPassword } from "react-icons/md";
import { RiMenu3Line, RiProfileLine } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo12.png";
import sigin_bg from "../assets/sigin_bg.jpg";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import { capitalize } from "../utils";
import { routes } from "../utils/routes";
import HeaderPopup from "./HeaderPopup";

import { Dropdown } from "react-bootstrap";
import HeaderNotification from "../components/notifications/HeaderNotification";

interface AppLayoutProps {
  children: ReactElement;
}

const AppHeader = ({ children }: AppLayoutProps) => {
  const [open, setOpen] = useState(false);
  const context = useAuthentication();
  const navigate = useNavigate();

  const [showHeaderPopup, setShowHeaderPopup]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    context?.getCurrentUser();
  }, []);

  return (
    context && (
      <div>
        <header className="header  px-3 py-2 border-bottom ">
          <div className="d-flex align-items-center">
            {isDesktop && (
              <>
                <div className="logoSide px-4 " style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      navigate(routes.contracts);
                    }}
                  >
                    <img src={logo} alt="logo" width={60} />
                  </span>
                </div>

                <div className=" d-flex justify-content-end align-items-center flex-grow-1">
                  <div className=" d-flex align-items-center position-relative ">
                    <div className="d-flex align-items-center position-relative">
                      <h5 className="fw-bold m-0 organizationName me-1">
                        {context.currentUser?.organizationName} {""}-{""}
                      </h5>
                      <div
                        className=" fs-6 text-center fw-normal me-3"
                        style={{ cursor: "default" }}
                      >
                        <span>
                          {" "}
                          {context?.currentUser?.name?.length &&
                          context?.currentUser?.name.length > 15
                            ? `${context?.currentUser?.name.slice(0, 14)}...`
                            : context?.currentUser?.name}
                        </span>{" "}
                        |{" "}
                        <span>
                          {capitalize(context.currentUser?.department || "")}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className=" Actions ">
                        <HeaderNotification />
                      </div>
                    </div>
                    <div className="memberimageshapeNav">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          variant="default"
                          style={{ border: "none" }}
                        >
                          <div
                            className="memberimageshape   me-3"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={
                                context?.currentUser?.avatar!
                                  ? context?.currentUser?.avatar
                                  : sigin_bg
                              }
                              alt="logo"
                              width={40}
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="create-dropdown-css">
                          <div
                            className={`dropdown_menu active`}
                            style={{ top: 0 }}
                          >
                            <ul className="px-3 pt-2">
                              <li className="d-flex align-items-center px-3">
                                <NavLink
                                  to={routes.profile}
                                  onClick={() => setOpen(false)}
                                  className="text-decoration-none"
                                >
                                  <span className="me-3">
                                    <RiProfileLine fontSize={20} />
                                  </span>
                                  Personal profile
                                </NavLink>
                              </li>
                              <li className="d-flex align-items-center px-3">
                                <NavLink
                                  to={routes.companyProfile}
                                  onClick={() => setOpen(false)}
                                  className="text-decoration-none"
                                >
                                  <span className="me-3">
                                    <BsBuildings fontSize={20} />
                                  </span>
                                  Company Profile
                                </NavLink>
                              </li>
                              <li className="d-flex align-items-center px-3">
                                <NavLink
                                  to={routes.changePassword}
                                  onClick={() => setOpen(false)}
                                  className="text-decoration-none"
                                >
                                  <span className="me-3">
                                    <MdPassword fontSize={20} />
                                  </span>
                                  Change Password
                                </NavLink>
                              </li>
                              {/* <li className="d-flex align-items-center px-3">
                                <NavLink
                                  to={""}
                                  onClick={() => setOpen(false)}
                                  className="text-decoration-none"
                                >
                                  <span className="me-3">
                                    <MdOutlineNotificationsActive
                                      fontSize={20}
                                    />
                                  </span>
                                  Notifications{" "}
                                </NavLink>
                              </li> */}
                              {/* {context?.currentUser?.roles?.find(
                                (el) => el === "ADMIN"
                              ) && (
                                <>
                                  <li className="d-flex align-items-center px-3">
                                    <NavLink
                                      to={routes.roles}
                                      onClick={() => setOpen(false)}
                                      className="text-decoration-none"
                                    >
                                      <span className="me-3">
                                        <FiUserCheck fontSize={20} />
                                      </span>
                                      Roles And Access
                                    </NavLink>
                                  </li>
                                </>
                              )} */}
                              {context?.currentUser?.roles?.find(
                                (el: any) => el === "SIGNATORY"
                              ) && (
                                <>
                                  <li className="d-flex align-items-center px-3">
                                    <NavLink
                                      to={routes.mysignature}
                                      onClick={() => setOpen(false)}
                                      className="text-decoration-none"
                                    >
                                      <span className="me-3">
                                        <FaSignature fontSize={20} />
                                      </span>
                                      Signature
                                    </NavLink>
                                  </li>
                                </>
                              )}

                              <li className="d-flex align-items-center px-3">
                                <NavLink
                                  to={routes.helpCenter}
                                  onClick={() => setOpen(false)}
                                  className="text-decoration-none"
                                >
                                  <span className="me-3">
                                    <FiHelpCircle fontSize={20} />
                                  </span>
                                  Help Center
                                </NavLink>
                              </li>
                              <li
                                className="d-flex align-items-center justify-content-center px-3 border-bottom-0 rounded-1 mt-3"
                                style={{
                                  background: "#3c3adae8",
                                }}
                              >
                                <NavLink
                                  to={""}
                                  onClick={() => {
                                    setOpen(false);
                                    context.logout();
                                  }}
                                  className="text-decoration-none"
                                  style={{
                                    color: "#fefefe",
                                  }}
                                >
                                  <span className="me-3">
                                    <MdLogout fontSize={20} />
                                  </span>
                                  Logout
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* -----------------------------------profile drop down------------------------------- */}
                    <div
                      className={`dropdown_menu ${
                        open ? "active" : "inactive"
                      }`}
                    >
                      <ul className="px-3 pt-2">
                        <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={routes.profile}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <RiProfileLine fontSize={20} />
                            </span>
                            Personal profile
                          </NavLink>
                        </li>
                        <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={routes.companyProfile}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <BsBuildings fontSize={20} />
                            </span>
                            Company Profile
                          </NavLink>
                        </li>
                        <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={routes.changePassword}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <MdPassword fontSize={20} />
                            </span>
                            Change Password
                          </NavLink>
                        </li>
                        {/* <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={""}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <MdOutlineNotificationsActive fontSize={20} />
                            </span>
                            Notifications{" "}
                          </NavLink>
                        </li> */}
                        {context?.currentUser?.roles?.find(
                          (el) => el === "ADMIN"
                        ) && (
                          <>
                            <li className="d-flex align-items-center px-3">
                              <NavLink
                                to={routes.roles}
                                onClick={() => setOpen(false)}
                                className="text-decoration-none"
                              >
                                <span className="me-3">
                                  <FiUserCheck fontSize={20} />
                                </span>
                                Roles And Access
                              </NavLink>
                            </li>
                          </>
                        )}

                        <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={routes.mysignature}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <FaSignature fontSize={20} />
                            </span>
                            Signature
                          </NavLink>
                        </li>
                        <li className="d-flex align-items-center px-3">
                          <NavLink
                            to={routes.helpCenter}
                            onClick={() => setOpen(false)}
                            className="text-decoration-none"
                          >
                            <span className="me-3">
                              <FiHelpCircle fontSize={20} />
                            </span>
                            Help Center
                          </NavLink>
                        </li>
                        <li
                          className="d-flex align-items-center justify-content-center px-3 border-bottom-0 rounded-1 mt-3"
                          style={{
                            background: "#4a48c8",
                          }}
                        >
                          <NavLink
                            to={""}
                            onClick={() => {
                              setOpen(false);
                              context.logout();
                            }}
                            className="text-decoration-none"
                            style={{
                              color: "#fefefe",
                            }}
                          >
                            <span className="me-3">
                              <MdLogout fontSize={20} />
                            </span>
                            Logout
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* --------------------------------------------------------------profile header new ------------------------------ */}
              </>
            )}
          </div>

          <div className=" d-flex justify-content-between align-items-center">
            {isMobile && (
              <div>
                <img src={logo} alt="logo" width={80} />
              </div>
            )}
            <div className={isMobile ? " " : "d-none"}>
              {isMobile && !showHeaderPopup && (
                <RiMenu3Line
                  onClick={() => {
                    setShowHeaderPopup(true);
                  }}
                  className="baslist"
                  color="#3c3ada"
                  fontSize={24}
                />
              )}
            </div>

            {showHeaderPopup && (
              <HeaderPopup
                show={showHeaderPopup}
                onHide={() => setShowHeaderPopup(false)}
                setShowHeaderPopup={setShowHeaderPopup}
              />
            )}
          </div>
        </header>
        {children}
      </div>
    )
  );
};

export default AppHeader;
