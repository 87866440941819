import { ErrorMessage, Formik, Field } from "formik";
import { useState, useEffect } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import FailureToast from "../../../ui/FailureToast";
import FormikField from "../../../ui/FormikField";
import SuccessToast from "../../../ui/SuccessToast";
import SettingsLayout from "./SettingsLayout";
import { adminApi } from "../../../utils/adminApi";
import { roleOptions } from "../../Admin/Members/Interface";
import React from "react";
import { useAdminAuth } from "../Auth/AdminAuthenticationProvider";
import Select from "react-select";

interface ProfileInterface {
  first_name?: string | "";
  last_name?: string | "";
  contact_number?: string | "";
  department?: string | "";
  roles?: [] | "";
}
const ProfilePage: React.FC = () => {
  const context = useAdminAuth();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [data, setAdminData]: [any, Function] = useState("");

  const initialValues: ProfileInterface = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    contact_number: data?.contact_number || "",
    department: data?.department || "",
    roles: data?.roles || "",
  };
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("first Name is required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters  are allowed")
      .required("last Name is required"),
    contact_number: Yup.string()
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .typeError("That doesn't look like a phone number"),
    department: Yup.string().required("Department is required"),
  });

  const handleSubmit = async (values: any) => {
    setLoader(true);
    let res = await adminApi?.adminUpdateprofile({ ...values });
    setLoader(false);
    if (res?.data?.success) {
      setShowSuccessToast(true);
      setBodyMessage("Profile Updated");
    }
  };

  const getAdminProfile = async () => {
    let response = await adminApi?.currentAdmin();
    setAdminData(response);
  };
  useEffect(() => {
    getAdminProfile();
  }, []);
  return (
    <SettingsLayout>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={`${isMobile ? " pt-2" : "pt-4"}`}>
                <div className="row ">
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="first_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="First Name"
                      errors={errors}
                      autoFocus
                      value={values?.first_name}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <FormikField
                      name="last_name"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Last Name"
                      errors={errors}
                      autoFocus
                      value={values?.last_name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">
                      Contact Number <span className="">*</span>
                    </label>
                    <FormGroup className="mt-2 ">
                      <div className="phone-input-css ">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values?.contact_number}
                          name="contact_number"
                          country={"in"}
                          onChange={(phoneNumber: any) => {
                            setFieldValue("contact_number", phoneNumber);
                          }}
                          error={
                            values?.contact_number
                              ? isValidPhoneNumber(values?.contact_number)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>

                      {errors?.contact_number && (
                        <small className="form-text text-danger">
                          <MdCancel className="me-1" />
                          {errors?.contact_number}
                        </small>
                      )}
                      <span className="field-error">
                        {values?.contact_number
                          ? isValidPhoneNumber(values?.contact_number)
                            ? undefined
                            : "Invalid phone number"
                          : ""}
                      </span>

                      <ErrorMessage name="contact_number" />
                    </FormGroup>
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-3">Department</label>
                    <Select
                      placeholder="Department"
                      value={{
                        label: values?.department,
                        value: values?.department,
                      }}
                      className="variable_Select mt-2"
                      options={context?.departmentList?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(e: any) => {
                        setFieldValue("department", e?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <label className="text-dark mt-2">Role</label>
                    <Field
                      as="select"
                      id="mySelect"
                      name="roles"
                      className="form-control mt-2"
                      disabled
                    >
                      <option value="" label="Select an option" />
                      {roleOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          label={option.label}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                {" "}
                <Button
                  className="cancel-hallowbtn me-3"
                  onClick={() => resetForm()}
                >
                  Cancel
                </Button>
                <Button
                  className="updatebtndanger"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Update Profile {loader && <span className="loader"></span>}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </SettingsLayout>
  );
};

export default ProfilePage;
