import AdminHeader from "../../../../layouts/AdminHeader";
import ListAdminAccountMembers from "./ListAdminAccountMembers";
import { ListAdminAccountMembersProvider } from "./ListAdminAccountMembersProvider";

const AdminAccountMembersPage = () => {
  return (
    <AdminHeader>
      <ListAdminAccountMembersProvider>
        <ListAdminAccountMembers />
      </ListAdminAccountMembersProvider>
    </AdminHeader>
  );
};

export default AdminAccountMembersPage;
