import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
// import DocumentFilter from "../../../ui/DocumentFilter";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFolders } from "../FoldersProvider";
import { Folder } from "../../../lib/Folder";
import { BiSortAlt2 } from "react-icons/bi";
import { FolderSortEnum } from "../../../interfaces/SortEnum";
import FolderFilters from "./FolderFilters";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

function FolderHeader({
  setAddFolderPopup,
  setMoveToFolders,
  setIsActiveMove,
}: {
  setAddFolderPopup: Function;
  setMoveToFolders: Function;
  setIsActiveMove: Function;
}) {
  const context = useFolders();
  const { id } = useParams();
  const location = useLocation();
  const [searchText, setSearchText]: [string, Function] = useState("");
  const [selectedValue, setSelectedValue] = useState<string>("Recently Added");

  const SortEnum = FolderSortEnum;

  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();
  const [updateRoles, setUpdateRoles]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.FOLDERS?.find((el: any) => el === "CREATE"));
      setUpdateRoles(guestRole?.FOLDERS?.find((el: any) => el === "UPDATE"));
    }
  }, [authContext?.rolesAndAccess]);

  const onSearchSubmit = async (e: any) => {
    if (e.keyCode === 13) {
      let response = await context?.handleFolderById(context?.folderList?.id, {
        search: searchText,
      });
      if (response?.success) {
        if (location.pathname.includes("private")) {
          let privateList = response?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PRIVATE";
            }
          );

          context?.setSubFolders(privateList);
          context?.setFolderList(response?.folder);
        } else if (location.pathname.includes("public")) {
          let publicList = response?.folder?.sub_folders?.filter(
            (folder: Folder) => {
              return folder?.access === "PUBLIC";
            }
          );
          context?.setSubFolders(publicList);
          context?.setFolderList(response?.folder);
        }
      }
    }
  };

  const filterDocument = async (filteredValue: string | null) => {
    let response = await context?.handleFolderById(context?.folderList?.id, {
      sort: filteredValue,
    });
    if (response?.success) {
      if (location.pathname.includes("private")) {
        let privateList = response?.folder?.sub_folders?.filter(
          (folder: Folder) => {
            return folder?.access === "PRIVATE";
          }
        );

        context?.setSubFolders(privateList);
        context?.setFolderList(response?.folder);
      } else if (location.pathname.includes("public")) {
        let publicList = response?.folder?.sub_folders?.filter(
          (folder: Folder) => {
            return folder?.access === "PUBLIC";
          }
        );
        context?.setSubFolders(publicList);
        context?.setFolderList(response?.folder);
      }
    }
  };

  return (
    <div className="d-flex flex-wrap">
      <div className="pt-2 flex-fill">
        <InputGroup className="br-4 search-css focus-input">
          <AiOutlineSearch className="search-icon-css" />
          <Form.Control
            id="doc_searchQueryInput1"
            placeholder="Search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            className="ps-3 fs-14 search-input"
            onChange={(e) => {
              setSearchText(e?.target?.value);
            }}
            onKeyDown={onSearchSubmit}
          />
        </InputGroup>
      </div>
      <div className="pt-2">
        <Dropdown
          onSelect={async (e) => {
            filterDocument(e);
          }}
          className="sort-by-filter"
        >
          <Dropdown.Toggle
            className="filter-css ms-3 me-3 me-md-0"
            id="dropdown-basic"
          >
            {selectedValue}
            <span className="px-2">
              <BiSortAlt2 />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sort-dropdown-css">
            <Dropdown.Item
              eventKey={"-createdAt"}
              className="sort-by"
              onClick={() => setSelectedValue("Recently Added")}
            >
              {FolderSortEnum?.recentlyAdded}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"-updatedAt"}
              className="sort-by"
              onClick={() => setSelectedValue(" Recently Updated")}
            >
              {SortEnum?.recentlyUpdated}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"name"}
              className="sort-by"
              onClick={() => setSelectedValue("Folder - A to Z")}
            >
              {SortEnum?.nameAtoZ}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"-name"}
              className="sort-by"
              onClick={() => setSelectedValue("Folder - Z to A")}
            >
              {SortEnum?.nameZtoA}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="pt-2 ">
        <FolderFilters />
      </div>
      {/* {roles === "CREATE" && ( */}
      {(roles === "CREATE" ||
        (updateRoles === "UPDATE" && id !== undefined)) && (
        <>
          <div className="pt-2 bd-highlight">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="new-contact-btn create-template contract-css ms-3 "
                id="dropdown-basic"
              >
                Organize
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                {roles === "CREATE" && (
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => setAddFolderPopup(true)}
                    >
                      Create new folder
                    </Dropdown.Item>
                    {id !== undefined && <Dropdown.Divider />}
                  </>
                )}

                {updateRoles === "UPDATE" && (
                  <>
                    {id && (
                      <>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            setMoveToFolders(true);
                            setIsActiveMove("template");
                          }}
                        >
                          Add templates
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            setMoveToFolders(true);
                            setIsActiveMove("contract");
                          }}
                        >
                          Add contracts
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      )}

      {/* )} */}
    </div>
  );
}

export default FolderHeader;
