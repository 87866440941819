import { Navigate, Route, Routes } from "react-router-dom";
import Analytics from "./modules/Analytics/Analytics";
import ForgetPasswordPage from "./modules/Authentication/ForgetPasswordPage";
import InvitationPage from "./modules/Authentication/InvitationPage";
import ResetPasswordPage from "./modules/Authentication/ResetAndChangePasswordPage";
import SignInPage from "./modules/Authentication/SignInPage";
import SignUpPage from "./modules/Authentication/Signup";
import ContractOverview from "./modules/Contracts/ContractOverview";
import EditContract from "./modules/Contracts/EditContract";
import ListContracts from "./modules/Contracts/ListContracts";
import Folders from "./modules/Folders/Components/Folders";
import ListMembers from "./modules/Members/ListMembers";
import EditTemplate from "./modules/Templates/EditTemplate";
import ListTemplates from "./modules/Templates/ListTemplates";
import TemplateOverview from "./modules/Templates/TemplateOverview";
import ChangePassword from "./modules/User/ChangePassword";
import CompanyPage from "./modules/User/CompanyPage";
import MySignature from "./modules/User/MySignature";
import ProfilePage from "./modules/User/ProfilePage";
import VersionHistoryDeatils from "./modules/VersionHistory/VersionHistoryDeatils";
import HelpCenter from "./ui/HelpCenter";
import PrivacyPolicy from "./ui/PrivacyPolicy";
import RolesAndAccess from "./ui/RolesAndAccess";
import { routes } from "./utils/routes";

const NotFound = () => <h1>Page Not found</h1>;

const UserRoutes = () => {
  let authToken: string = localStorage.getItem("auth-token") || "";

  return (
    <>
      {" "}
      <Routes>
        <Route path={routes.signIn} element={<SignInPage />} />
        <Route path={routes.signUp} element={<SignUpPage />} />

        <Route path={routes.forgetPassword} element={<ForgetPasswordPage />} />
        <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
        <Route path={routes.invitation} element={<InvitationPage />} />

        <Route path={routes.helpCenter} element={<HelpCenter />} />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />

        <Route path={routes.roles} element={<RolesAndAccess />} />
        <Route
          path={routes.VersionHistoryTemplateDeatils}
          element={<VersionHistoryDeatils />}
        />
        <Route
          path={routes.VersionHistoryContractDeatils}
          element={<VersionHistoryDeatils />}
        />

        <Route
          path={routes.root}
          element={
            <Navigate
              replace
              to={!authToken ? routes.signIn : routes.analyticsSummary}
            />
          }
        />
        <Route path={routes.templates} element={<ListTemplates />} />
        <Route path={routes.analyticsContactValue} element={<Analytics />} />
        <Route path={routes.analyticsSummary} element={<Analytics />} />
        <Route path={routes.analyticsTeams} element={<Analytics />} />
        <Route path={routes.analyticsTurnaround} element={<Analytics />} />

        <Route path={routes.members} element={<ListMembers />} />
        <Route path={routes.templateOverview} element={<TemplateOverview />} />
        <Route
          path={routes.overviewTemplateId}
          element={<TemplateOverview />}
        />

        <Route path={routes.contractsOverview} element={<ContractOverview />} />
        <Route
          path={routes.overviewIdContracts}
          element={<ContractOverview />}
        />

        <Route
          path={routes.workflowCoordinatesViewContracts}
          element={<ContractOverview />}
        />

        <Route path={routes.contracts} element={<ListContracts />} />
        <Route
          path={routes.templatesByDepartment}
          element={<ListTemplates />}
        />
        <Route
          path={routes.contractsByDepartment}
          element={<ListContracts />}
        />
        <Route path={routes.MembersByDepartment} element={<ListMembers />} />
        <Route path={routes.editTemplate} element={<EditTemplate />} />
        <Route path={routes.editContract} element={<EditContract />} />
        <Route path={routes.profile} element={<ProfilePage />} />
        <Route path={routes.changePassword} element={<ChangePassword />} />
        <Route path={routes.companyProfile} element={<CompanyPage />} />
        <Route path={routes.mysignature} element={<MySignature />} />
        <Route path={routes.folders} element={<Folders />} />
        <Route path={routes.templateFolder} element={<ListTemplates />} />
        <Route path={routes.contractFolder} element={<ListContracts />} />
        <Route path={routes.privateFolders} element={<Folders />} />
        <Route path={routes.publicFolder} element={<Folders />} />
        <Route path={routes.privatefolderId} element={<Folders />} />
        <Route path={routes.publicfolderId} element={<Folders />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default UserRoutes;
