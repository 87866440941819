export enum TableColumnsEnum {
  status = "Status",
  contactName = "Contract name",
  templateName = "Template name",
  name = "Name",
  createdby = "Created by",
  email = "Email",
  department = "Department",
  updatedAt = "Updated at",
  createdAt = "Created at",
  category = "Category",
  expiry_date = "Expiry date",
  access = "Access",
  actions = "Actions",
}
