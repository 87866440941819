import { isMobileOnly } from "react-device-detect";
import { generatePath, Link, useLocation } from "react-router-dom";
import { routes } from "../../../utils/routes";
import RecentTemplateSkeleton from "../../Templates/components/RecentTemplateSkeleton";
import { useContract } from "../ListContractProvider";
import RecentContractList from "./RecentContractList";
import { Contract } from "../../../lib/Contract";

function RecentContracts({
  setSelectedValues,
  moveToFolders,
  selectedValues,
}: {
  setSelectedValues: Function;
  moveToFolders: boolean;
  selectedValues: Contract[];
}) {
  const context = useContract();
  const location = useLocation();

  const handleCheckboxChange = (value: any) => {
    if (selectedValues.includes(value)) {
      let vals = [...selectedValues];
      let idx = vals.indexOf(value);
      vals.splice(idx);
      setSelectedValues([...vals]);
    } else {
      let vals = [...selectedValues];
      vals.push(value);
      setSelectedValues([...vals]);
    }
  };

  return (
    <>
      <div className="d-flex mt-4 mb-1 mx-2 ">
        <h6 className="contract-title pb-1 m-0"> Recent Contracts</h6>
      </div>
      <div className="recent-temp-div">
        {!context?.recentContracts ? (
          <>
            <div
              className={
                isMobileOnly
                  ? " d-flex template-width flex-column align-items-center"
                  : "d-flex template-width"
              }
            >
              {Array.from(Array(10).keys()).map((num: number) => (
                <div className="recent-templete-skeleton" key={num}>
                  <RecentTemplateSkeleton />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div
            className={
              isMobileOnly
                ? " d-flex template-width flex-column align-items-center"
                : "d-flex template-width"
            }
          >
            {context?.recentContracts?.map((contract: any) =>
              !moveToFolders && !location.pathname.includes("folder") ? (
                <Link
                  to={generatePath(routes.overviewContracts, {
                    id: contract.id,
                  })}
                  className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
                  key={contract.id}
                >
                  <RecentContractList contract={contract} />
                </Link>
              ) : (
                <span
                  key={contract?.id}
                  onClick={() => handleCheckboxChange(contract)}
                  className="recent-templete-skeleton text-dark text-decoration-none recent-temp"
                >
                  <RecentContractList contract={contract} />
                </span>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default RecentContracts;
