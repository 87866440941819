import { Editable, Slate } from "slate-react";
import { useEffect, useMemo, useState } from "react";
import { useVersionHistory } from "./VersionHisoryProvider";
import { useParams } from "react-router-dom";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { RenderElement } from "../../editor/Editable/Element";

// import { CustomRenderLeafProps } from "slate-react";
// import { css } from "@emotion/css";
// import {
//   fontFamilyMap,
//   lineHeightMap,
//   sizeMap,
// } from "../../editor/utils/constants";
import { Leaf } from "../../editor/Editable/Leaf";

function VersionHistoryEditorSection() {
  const context = useVersionHistory();
  const authContext = useAuthentication();
  const { documentId, versionId } = useParams();
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [currentAndLastUpdate, setCurrentAndLastUpadte] = useState<{
    current: any;
    lastUpdated: any;
  }>();

  const { decorate } = authContext?.editor;
  const handleGetSelectedVersion = async () => {
    setLoader(true);
    try {
      authContext?.setLoading(true);
      let response = await context?.handleVersionById(documentId, versionId);
      if (response?.success) {
        authContext?.setLoading(false);
        let res = await context?.getCurrentVersion(documentId);
        setLoader(false);
        if (res) {
          setCurrentAndLastUpadte({
            current: response?.document_version.body,
            lastUpdated: res?.body,
          });
          authContext?.setCurrentVersionValue(res?.body);
          authContext?.setSelectedVersionValue(response?.document_version.body);
          authContext?.setLoading(false);
        }
      }
    } catch (error) {}
  };

  useMemo(() => {
    if (versionId) {
      handleGetSelectedVersion();
    }
  }, [versionId]);

  useEffect(() => {
    if (currentAndLastUpdate?.current && currentAndLastUpdate?.lastUpdated) {
      authContext?.getDiff(
        currentAndLastUpdate?.current,
        currentAndLastUpdate?.lastUpdated
      );
    }
  }, [currentAndLastUpdate]);

  return (
    <>
      <div
        style={{
          maxHeight: "88vh",
          overflowY: "scroll",
        }}
        className="p-3"
        id={"versionHistoryZoomContainer"}
      >
        {loader ? (
          <span className="loader"></span>
        ) : (
          <>
            {authContext?.loading === false &&
              authContext?.selectedVersionValue && (
                <div>
                  <Slate
                    editor={authContext?.editor}
                    initialValue={authContext?.selectedVersionValue}
                  >
                    <Editable
                      renderElement={RenderElement}
                      renderLeaf={Leaf}
                      decorate={decorate}
                      readOnly={true}
                      autoFocus={false}
                    />
                  </Slate>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
}

export default VersionHistoryEditorSection;

