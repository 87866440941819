import { Button, Form, Modal, Stack } from "react-bootstrap";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../utils/routes";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useContract } from "./ContractProvider";

function CoordinatePopup({
  show,
  onHide,
}: {
  show: boolean;
  onHide: Function;
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const context = useContract();

  const [signatureType, setSignatureType]: [string, Function] = useState("");
  const [pageType, setPageType]: [string, Function] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pages, setPages]: [any, Function] = useState(1);
  const [filteredArrays, setfilteredArray]: [any, Function] = useState([1]);
  const [, setCoordinates]: [any, Function] = useState();

  const handlePageChange = (event: any) => {
    let page = event?.target?.value.split(/[-,]+/);
    setPages(page);

    const maxNumber = Math.max(...page);

    if (maxNumber > context?.data?.pages!!) {
      setErrorMessage("Page out of bounds");
    } else {
      const filteredSet = new Set(page.filter((item: any) => item !== ""));
      const filteredArray = Array.from(filteredSet);
      setfilteredArray(filteredArray);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    let z = filteredArrays?.map((item: any) => ({
      page: parseInt(item),

      // x: 462,
      // y: 16.91998000000001,
      // height: 25,
      // width: 100,
    }));

    const b = context?.coordinate?.map((item: any) => ({
      ...item,
      page: z,
    }));
    setCoordinates(b);

    const modifiedData = b.map((entry: any) => {
      const page = entry?.page[0].page;
      const newCoordinates = entry.co_ordinates.map((coord: any) => ({
        ...coord,
        page: page,
      }));

      return {
        co_ordinates: newCoordinates,
        user: entry.user,
      };
    });

    context?.setCoordinate(modifiedData);
  }, [pages, filteredArrays]);

  const signatureTypes = [
    { value: "sign with initials", label: "With initials" },
    { value: "sign with sign", label: "Without initials" },
  ];

  const pagesType = [
    { value: "all", label: "All" },
    { value: "custom", label: "Custom" },
  ];

  const handleConfirmCoordinate = async () => {
    try {
      let response = await context?.handleAddCoordinate(id, {
        signing: newArray,
      });
      if (response?.data?.success) {
        navigate(
          generatePath(routes.workflowContracts, {
            id: id!,
          })
        );
        context?.refresh();
        onHide();
      }
    } catch (error) {}
  };

  const newArray = context?.coordinate.map((item: any) => {
    const modifiedCoOrdinates = item.co_ordinates.map((coordinate: any) => ({
      ...coordinate,
      initial_required: signatureType === "sign with initials" ? true : false,
      name_required: true,
      date_required: true,
      designation_required: true,
    }));

    return {
      ...item,
      co_ordinates: modifiedCoOrdinates,
    };
  });

  return (
    <>
      <Modal show={show} centered onHide={() => onHide()}>
        <Modal.Body className="p-4">
          <Stack>
            <h5 className="fw-semibold py-2">Signature </h5>
          </Stack>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="col-md-12 col-12 ">
                <label className="input_label">Signature type</label>
                <Select
                  className="basic-single "
                  options={signatureTypes}
                  onChange={(data: any) => setSignatureType(data?.value)}
                  defaultValue={signatureTypes[1]}
                />
              </div>
              {/* {signatureType && ( */}
              <>
                <div className="col-md-12 col-12 mt-2">
                  <label className="input_label">Pages</label>
                  <Select
                    className="basic-single "
                    defaultValue={pagesType[0]}
                    options={pagesType}
                    onChange={(data: any) => setPageType(data?.value)}
                  />
                </div>
              </>
              {/* )} */}

              {pageType === "custom" && (
                <>
                  <input
                    type="text"
                    className="form-control mt-3"
                    defaultValue={`1-4`}
                    placeholder="e.g. 1-5, 8 , 11-13"
                    onChange={handlePageChange}
                  />
                  <div>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                  </div>
                </>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbtn me-3 "
            size="sm"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            className="sharebtndanger"
            size="sm"
            onClick={() => {
              handleConfirmCoordinate();
            }}
          >
            Redirect to workflow
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CoordinatePopup;
