// import AdminQuarterWiseTrend from "../AdminQuarterWiseTrend";
import AdminContractsVolume from "./AdminContractCharts/AdminContractsVolume";
import ContractValueMOM from "./AdminContractCharts/ContractValueMOM";
import ContractVolumeMOM from "./AdminContractCharts/ContractVolumeMOM";
import LineContractChart from "./AdminContractCharts/LineContractChart";
import AdminContractStats from "./AdminContractStats";
import SignedContractsTable from "./SignedContractsTable";

const AdminAnalyticsContract = ({ filterData }: { filterData: any }) => {
  return (
    <div className="analyticsBody px-3 pb-5">
      <div className=" row">
        <div className=" col-lg-6 col-12 mt-4">
          <div
            style={{ minHeight: "505px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title"> Overall Stats</h6>
            <AdminContractStats filterData={filterData} />
          </div>
        </div>
        <div className=" col-lg-6 col-12 mt-4">
          <div
            style={{ minHeight: "505px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title"> Monthly Trend</h6>
            <LineContractChart filterData={filterData} />
          </div>
        </div>
      </div>
      {/* <div className=" row">
        <div className="  col-12 mt-4">
          <div
            style={{ minHeight: "505px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title">Quarter Wise Trend</h6>
            <AdminQuarterWiseTrend />
          </div>
        </div>
      </div> */}
      <div className=" row">
        <div className=" col-lg-6 col-12 mt-4">
          <div
            style={{ minHeight: "505px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title">
              Contract Volume Overall
            </h6>
            <AdminContractsVolume filterData={filterData} isShowValue={false} />
          </div>
        </div>
        <div className=" col-lg-6 col-12 mt-4">
          <div
            style={{ minHeight: "505px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title">
              Contract Value Overall
            </h6>
            <AdminContractsVolume filterData={filterData} isShowValue={true} />
          </div>
        </div>
      </div>
      <div className=" row">
        <div className="  col-12 mt-4">
          <div
            style={{ minHeight: "470px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title">
              Contract Volume M-o-M
            </h6>
            <ContractVolumeMOM filterData={filterData} />
          </div>
        </div>
        <div className="  col-12 mt-4">
          <div
            style={{ minHeight: "470px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title mb-4">
              Contract Value M-o-M
            </h6>
            <ContractValueMOM filterData={filterData} />
          </div>
        </div>
        <div className="  col-12 mt-4">
          <div
            style={{ minHeight: "470px", background: "#fefefe" }}
            className=" p-3"
          >
            <h6 className=" fw-semibold dashboard_title mb-4">
              Signed Contracts
              <SignedContractsTable filterData={filterData} />
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAnalyticsContract;
