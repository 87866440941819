import { Editor, Range, Text } from "slate";
import { v4 as uuidv4 } from "uuid";
const SUGGESTION_THREAD_PREFIX = "suggestion_";

export function shouldAllowNewSuggestionThreadAtSelection(
  editor: Editor,
  selection: any
) {
  if (selection == null || Range.isCollapsed(selection)) {
    return false;
  }

  const textNodeIterator = Editor.nodes(editor, {
    at: selection,
    mode: "lowest",
  });

  let nextTextNodeEntry = textNodeIterator.next().value;
  const textNodeEntriesInSelection = [];
  while (nextTextNodeEntry != null) {
    textNodeEntriesInSelection.push(nextTextNodeEntry);
    nextTextNodeEntry = textNodeIterator.next().value;
  }

  if (textNodeEntriesInSelection.length === 0) {
    return false;
  }

  return textNodeEntriesInSelection.some(
    ([textNode]) => getSuggestionThreadsOnTextNode(textNode).size === 0
  );
}

export function insertSuggestionThread(
  editor: Editor,
  addSuggestionCommentThreadToState: any
) {
  const threadID = uuidv4();
  const newSuggestionCommentThread = {
    comments: [],
    creationTime: new Date(),
    status: false,
  };
  addSuggestionCommentThreadToState(threadID, newSuggestionCommentThread);
  Editor.addMark(editor, getMarkForSuggestionThreadID(threadID), true);
  return threadID;
}

export function getMarkForSuggestionThreadID(threadID: any) {
  return `${SUGGESTION_THREAD_PREFIX}${threadID}`;
}

export function getSuggestionThreadIDFromMark(mark: any) {
  return mark.replace(SUGGESTION_THREAD_PREFIX, "");
}

export function isSuggestionThreadIDMark(mayBeMark: any) {
  return mayBeMark.indexOf(SUGGESTION_THREAD_PREFIX) === 0;
}

export function getSuggestionThreadsOnTextNode(textNode: any) {
  // if (textNode == null) {
  //   debugger;
  // }
  return new Set(
    Object.keys(textNode)
      .filter(isSuggestionThreadIDMark)
      .map(getSuggestionThreadIDFromMark)
  );
}

export function getSmallestSuggestionThreadAtTextNode(
  editor: Editor,
  textNode: any
) {
  const suggestionThreads: any = getSuggestionThreadsOnTextNode(textNode);
  const suggestionThreadsAsArray = [...suggestionThreads];

  let newActiveSuggestionThreadID = suggestionThreadsAsArray[0];

  const reverseTextNodeIterator = (slateEditor: Editor, nodePath: any) =>
    Editor.previous(slateEditor, {
      at: nodePath,
      mode: "lowest",
      match: Text.isText,
    });

  const forwardTextNodeIterator = (slateEditor: Editor, nodePath: any) =>
    Editor.next(slateEditor, {
      at: nodePath,
      mode: "lowest",
      match: Text.isText,
    });

  if (suggestionThreads.size > 1) {
    const suggestionThreadsLengthByID: any = new Map(
      suggestionThreadsAsArray.map((id) => [id, textNode.text.length])
    );

    updateSuggestionThreadLengthMap(
      editor,
      suggestionThreads,
      reverseTextNodeIterator,
      suggestionThreadsLengthByID
    );

    updateSuggestionThreadLengthMap(
      editor,
      suggestionThreads,
      forwardTextNodeIterator,
      suggestionThreadsLengthByID
    );

    let minLength = Number.POSITIVE_INFINITY;

    for (let [threadID, length] of suggestionThreadsLengthByID) {
      if (length < minLength) {
        newActiveSuggestionThreadID = threadID;
        minLength = length;
      }
    }
  }

  return newActiveSuggestionThreadID;
}

function updateSuggestionThreadLengthMap(
  editor: any,
  suggestionThreads: any,
  nodeIterator: any,
  map: any
) {
  let nextNodeEntry = nodeIterator(editor);
  while (nextNodeEntry != null) {
    const nextNode = nextNodeEntry[0];
    const suggestionThreadsOnNextNode: any =
      getSuggestionThreadsOnTextNode(nextNode);
    const intersection = [...suggestionThreadsOnNextNode].filter((x) =>
      suggestionThreads.has(x)
    );
    // All suggestion threads we're looking for have already ended.
    if (intersection.length === 0) {
      break;
    }

    for (let i = 0; i < intersection.length; i++) {
      map.set(intersection[i], map.get(intersection[i]) + nextNode.text.length);
    }

    nextNodeEntry = nodeIterator(editor, nextNodeEntry[1]);
  }

  return map;
}

export async function initializeStateWithAllSuggestionCommentThreads(
  editor: any,
  setCommentThreadData: any,
  getComments: any,
  // editorContext: any
) {
  const textNodesWithComments = Editor.nodes(editor, {
    at: [],
    mode: "lowest",
    match: (n) => Text.isText(n) && getSuggestionThreadsOnTextNode(n).size > 0,
  });

  const comm: any = await getComments();

  const commentSuggestionThreads = new Set();

  let textNodeEntry: any = textNodesWithComments.next().value;
  while (textNodeEntry != null) {
    [...(getSuggestionThreadsOnTextNode(textNodeEntry[0]) as any)].forEach(
      (threadID) => {
        commentSuggestionThreads.add(threadID);
      }
    );
    textNodeEntry = textNodesWithComments.next().value;
  }

  const obj = comm?.comments.reduce(function (rv: any, x: any) {
    (rv[x["ref_id"]] = rv[x["ref_id"]] || []).push(x);
    return rv;
  }, {});

  Object.keys(obj).forEach((_obj: any) => {
    const aggregatedComments = obj[_obj].map((_ob: any) => {
      return {
        authorID: _ob?.created_by?.sub,
        author: _ob?.created_by?.name,
        text: _ob?.value?.[0],
        creationTime: new Date(_ob?.createdAt),
        commentID: _ob.id,
        resolved: _ob.is_resolved,
        access: _ob.access,
        mentions: _ob.mentions,
      };
    });
    setCommentThreadData(_obj, {
      comments: aggregatedComments,
      status: aggregatedComments[0]?.resolved,
      access: aggregatedComments[0]?.access,
    });
  });
}
