import React, { ReactNode } from "react";
import { isMobile } from "react-device-detect";
import AdminHeader from "./AdminHeader";
import AdminAppSideBar from "./AdminAppSidebar";

interface AppLayoutProps {
  pageTitle?: string;
  children: ReactNode;
}

const AdminAppLayout = ({ pageTitle, children }: AppLayoutProps) => {
  React.useEffect(() => {
    document.title = pageTitle || "CLM";
  }, [pageTitle]);

  return (
    <AdminHeader>
      <div>
        {/* <div>{!isMobile && !isXS && <AppSideBar />}</div> */}
        <div className={isMobile ? "d-none" : ""}>
          <AdminAppSideBar>
            <div className=" row">
              <div className="col-12"> {children}</div>
            </div>
          </AdminAppSideBar>
        </div>

        <div className="pb-5 row" style={{ paddingTop: "60px" }}>
          <div className="col-12"> {children}</div>
        </div>
      </div>
    </AdminHeader>
  );
};

export default AdminAppLayout;
