import HierarchyTabs from "./HierarchyTabs";
import HierarchyAddBtn from "./HierarchyAddBtn";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../../../utils/api";

const HierarchyTree = () => {
  const { id } = useParams();
  const location = useLocation();
  const [parentData, setParentData]: [any, Function] = useState();
  const [contractHirerchy, setContractHirerchy]: [[], Function] = useState([]);

  const contractHirerchyList = async () => {
    try {
      let response = await api?.getContractHirerchy(id!);

      if (response?.success) {
        setParentData(response?.data);
        setContractHirerchy([response?.data]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      location.pathname.includes("hierarchy") ||
      location.pathname.includes("edit")
    ) {
      contractHirerchyList();
    }
  }, [location, id]);

  return (
    <>
      <div className={"treeview js-treeview w-100"}>
        <ul className="w-100 p-0">
          {contractHirerchy?.map((el: any) => {
            return (
              <>
                <li className="w-100">
                  <div
                    className="treeview__level w-100 d-flex align-items-center position-relative"
                    data-level="GarentParent"
                  >
                    {/* {context?.data?.status === "COMPLETED" && ( */}
                    <HierarchyAddBtn
                      docId={el?.id}
                      contractHirerchyList={contractHirerchyList}
                      type={"parent"}
                    />
                    {/* )} */}

                    <HierarchyTabs
                      name={parentData?.name}
                      batch={
                        el?.child
                          ?.map((el: any) => el)
                          ?.find((el: any) => el?.child) === undefined
                          ? "Primary"
                          : "Secondary"
                      }
                      contractDetail={el}
                      contractHirerchyList={contractHirerchyList}
                    />
                  </div>

                  <ul className="w-100">
                    {el?.child?.map((el: any) => {
                      return (
                        <>
                          <li className="w-100">
                            <div
                              className="treeview__level w-100 position-relative d-flex align-items-center"
                              data-level="Parent"
                            >
                              {/* {context?.data?.status === "COMPLETED" && ( */}
                              <HierarchyAddBtn
                                docId={el?.id}
                                contractHirerchyList={contractHirerchyList}
                                type={"child"}
                              />
                              {/* )} */}

                              <HierarchyTabs
                                contractDetail={el}
                                name={el?.name}
                                batch={
                                  el?.child?.length > 0 &&
                                  el?.child === undefined
                                    ? "Tertiary"
                                    : "Secondary"
                                }
                                contractHirerchyList={contractHirerchyList}
                                // contractId={el?.id}
                              />
                            </div>
                            <ul className="w-100">
                              {el?.child?.map((el: any) => {
                                return (
                                  <>
                                    <li className="w-100">
                                      <div
                                        className="treeview__level w-100 position-relative d-flex align-items-center"
                                        data-level="Children"
                                      >
                                        <HierarchyAddBtn
                                          docId={el?.id}
                                          contractHirerchyList={
                                            contractHirerchyList
                                          }
                                          type={"child"}
                                        />
                                        <HierarchyTabs
                                          contractDetail={el}
                                          name={el?.name}
                                          batch={"Tertiary"}
                                          contractHirerchyList={
                                            contractHirerchyList
                                          }
                                          // contractId={el?.id}
                                        />
                                      </div>
                                      <ul className="w-100">
                                        {el?.child?.map((el: any) => {
                                          return (
                                            <>
                                              <li className="w-100">
                                                <div
                                                  className="treeview__level w-100 position-relative d-flex align-items-center"
                                                  data-level="GrandChildren"
                                                >
                                                  <HierarchyAddBtn
                                                    docId={el?.id}
                                                    contractHirerchyList={
                                                      contractHirerchyList
                                                    }
                                                    type={"Grand child"}
                                                  />

                                                  <HierarchyTabs
                                                    contractDetail={el}
                                                    name={el?.name}
                                                    batch={"Quaternary"}
                                                    contractHirerchyList={
                                                      contractHirerchyList
                                                    }
                                                    // contractId={el?.id}
                                                  />
                                                </div>
                                              </li>
                                            </>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </li>
                        </>
                      );
                    })}

                    {/* <li>
                <div
                  className="treeview__level w-100 position-relative d-flex align-items-center"
                  data-level="Parent"
                >
                  <HierarchyAddBtn />
                  <HierarchyTabs name={""} />
                </div>
              </li> */}
                  </ul>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default HierarchyTree;
