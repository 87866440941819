import DataTable from "react-data-table-component";
import { columns } from "./columns";
import { Form, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import MemberFormModal from "./memberFormModal";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useAdminListAccountMembers } from "./ListAdminAccountMembersProvider";
import FailureToast from "../../../../ui/FailureToast";
import SuccessToast from "../../../../ui/SuccessToast";
import { AiOutlineSearch } from "react-icons/ai";
import NoTemplateFound from "../NoDataFound";

const ListAdminAccountMembers = () => {
  const context: any = useAdminListAccountMembers();
  const [show, setShow]: [boolean, Function] = useState(false);
  return (
    context && (
      <div className="px-4">
        <PageMenu context={context} show={show} setShow={setShow} />
        {context?.members?.length > 0 ? (
          <div className="my-4" style={{ background: "#fefefe" }}>
            <DataTable
              columns={columns}
              data={context?.members || []}
              pagination={true}
              paginationPerPage={10} // Set the number of rows per page
              paginationRowsPerPageOptions={[10, 20, 30]} // Set the options for rows per page
              paginationTotalRows={context?.meta.total_count} // Set the total number of rows
              paginationServer={true} // Enable server-side pagination
              paginationDefaultPage={
                parseInt(context?.meta.page.toString()) || 1
              } // Set the default page
              onChangePage={(page) => {
                context.setFilters({ ...context?.filters, page });
              }}
              onSort={(column, sortDirection) => {
                context.setFilters({
                  ...context?.filters,
                  sort: `${sortDirection === "desc" ? "-" : ""}${column?.name
                    ?.toLocaleString()
                    ?.toLocaleLowerCase()}`,
                });
              }}
              highlightOnHover
              responsive
            />
          </div>
        ) : (
          <NoTemplateFound />
        )}
      </div>
    )
  );
};

export default ListAdminAccountMembers;

const PageMenu = ({ context, show, setShow }: any) => {
  const [search, setSearch] = useState("");
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);

  const params = {
    search: search ? search : null,
  };

  useEffect(() => {
    context?.getMembers(params);
  }, [search]);

  return (
    context && (
      <div className="">
        <div className="d-flex">
          <div className="d-flex w-100">
            <div className="py-2 flex-fill">
              <InputGroup className="br-4 search-css focus-input">
                <AiOutlineSearch
                  className="search-icon-css"
                  onClick={() => {
                    context?.setFilters({ ...context.filters, search });
                  }}
                />
                <Form.Control
                  id="doc_searchQueryInput1"
                  placeholder="Search by Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  className="ps-3 fs-14 search-input"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  // onKeyDown={onSearchSubmit}
                />
              </InputGroup>
            </div>
          </div>
          <MemberFormModal
            context={context}
            setShowSuccessToast={setShowSuccessToast}
            setBodyMessage={setBodyMessage}
            setShow={setShow}
            setShowFailureToast={setShowFailureToast}
            show={show}
          />
          {showSuccessToast && (
            <SuccessToast
              icon={
                <MdCheckCircle
                  className="text-success"
                  style={{ fontSize: "64px" }}
                />
              }
              message={bodyMessage}
              title={"Success!"}
              show={showSuccessToast}
              close={() => {
                setShowSuccessToast(false);
              }}
            />
          )}
          {showFailureToast && (
            <FailureToast
              message={bodyMessage}
              title={"Failure!"}
              show={showFailureToast}
              close={() => {
                setShowFailureToast(false);
              }}
              icon={
                <MdCancel
                  className="text-danger"
                  style={{ fontSize: "64px" }}
                />
              }
            />
          )}
        </div>
        <div className="d-flex pt-4">
          <h6 className="contract-title"> All Users</h6>
        </div>
      </div>
    )
  );
};
