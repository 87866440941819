import { Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { routes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import { api } from "../../utils/api";
import FailureToast from "../../ui/FailureToast";

interface SignUpFormProps {}

const SignUpForm = () => {
  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showFailureToast, setShowFailureToast] = useState<boolean>(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const navigate = useNavigate();

  const initialValues: SignUpFormProps = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    jobTitle: "",
    industry: "",
    team: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First name is required")
      .trim()
      .matches(/^(\S+$)/g, "This field cannot contain spaces"),
    lastName: Yup.string().required("Last name is required").trim(),
    email: Yup.string().email("Invalid email address").required(),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .test(
        "isValidPass",
        "Password should contain one uppercase, one number and one speicial charecter",
        (value) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSymbol = /[!@#%&]/.test(value);

          let validConditions = 0;
          const numberOfMustBeValidConditions = 4;
          const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }
      )
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });
  const handleSubmit = async (
    values: any,
    {
      setSubmitting,
      validateForm,
    }: { setSubmitting: Function; validateForm: Function }
  ) => {
    validateForm(values);
    const res = await api?.signUp({
      first_name: values?.firstName,
      last_name: values?.lastName,
      email: values?.email,
      job_title: values?.jobTitle,
      industry: values?.industry,
      department: values?.industry,
      company_name: values?.team,
      password: values?.password,
    });
    if (res?.success) {
      setShowSuccessToast(true);
    } else {
      setShowFailureToast(true);
      setBodyMessage(res?.message[0]);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ errors, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormikField
                name="firstName"
                type="text"
                validationSchema={validationSchema}
                label="First Name"
                placeholder="First Name"
                errors={errors}
                autoFocus
              />
              <FormikField
                name="lastName"
                type="text"
                validationSchema={validationSchema}
                label="Last Name"
                placeholder="Last Name"
                errors={errors}
              />
              <FormikField
                name="email"
                type="email"
                validationSchema={validationSchema}
                label="Email address "
                placeholder="E-mail address"
                errors={errors}
              />

              <FormikField
                name="jobTitle"
                type="text"
                validationSchema={validationSchema}
                label="Job Title"
                placeholder="Job Title"
                errors={errors}
              />
              <FormikField
                name="team"
                type="text"
                validationSchema={validationSchema}
                label="Team"
                placeholder="Team"
                errors={errors}
              />
              <FormikField
                name="industry"
                type="text"
                validationSchema={validationSchema}
                label="Industry"
                placeholder="Industry"
                errors={errors}
              />

              <FormikField
                name="password"
                type={passwordIcon ? "text" : "password"}
                validationSchema={validationSchema}
                label="Password"
                placeholder="Password"
                errors={errors}
                isPassword={true}
                setPasswordIcon={setPasswordIcon}
                passwordIcon={passwordIcon}
              />
              <div className=" d-flex justify-content-end">
                <FormikSubmitButton
                  isSubmitting={isSubmitting}
                  className="my-3 w-100"
                  size="md"
                  submittingLabel={<span>Logging in...</span>}
                >
                  <span className="SignText">Login</span>
                </FormikSubmitButton>
              </div>
            </form>
          );
        }}
      </Formik>
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message="Your account created successfully! Please login to continue."
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
            navigate(routes.root);
          }}
        />
      )}

      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};

export default SignUpForm;
