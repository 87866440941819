import { useEffect, useState } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FailureToast from "../../ui/FailureToast";
import SuccessToast from "../../ui/SuccessToast";
import { ApprovalsProvider, useApprovals } from "./WorkflowProvider";
import { IApprovalResponse } from "../../interfaces/response/IApprovalResponse";
import { Button, Container, Form, InputGroup, Stack } from "react-bootstrap";
import WorkflowMain from "./WorkflowMain";
import { useContract } from "../Contracts/ContractProvider";
import { useTemplate } from "../Templates/TemplateProvider";
import { StatusEnum } from "../../interfaces/StatusEnum";
import Select from "react-select";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
import DatePicker from "react-date-picker";
import moment from "moment";
import DesignateField from "./DesignateField";
import { routes } from "../../utils/routes";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import { customStyles } from "../../ui/CustomStyles";

const Approval = ({
  isActiveOverView,
  startWorkFlow,
  setStartWorkflow,
  setShowSuccessToastWorkFlow,
  setWorkFlowBodyMessage,
  setWorkFlowShowFailureToast,
  setIsWorkflowListAdded,
  startFromBegnning,
  resumeBtnClick,
  setResumeWorkflowIsActive,
  setRejectStatus,
  startTemplateWorkflow,
  editWorkflow,
  setEditWorkflowStarted,
  SetEditWorkflow,
  setIsCounterpartySigner,
}: {
  isActiveOverView: string;
  startWorkFlow: boolean;
  setStartWorkflow: Function;
  setShowSuccessToastWorkFlow: Function;
  setWorkFlowBodyMessage: Function;
  setWorkFlowShowFailureToast: Function;
  setIsWorkflowListAdded: Function;
  startFromBegnning?: boolean;
  resumeBtnClick?: boolean;
  setResumeWorkflowIsActive: Function;
  setRejectStatus: Function;
  startTemplateWorkflow: boolean;
  editWorkflow: boolean;
  setEditWorkflowStarted: Function;
  SetEditWorkflow: Function;
  setIsCounterpartySigner: Function;
}) => {
  return (
    <ApprovalsProvider>
      <WorkFlowSection
        editWorkflow={editWorkflow}
        isActiveOverView={isActiveOverView}
        startWorkFlow={startWorkFlow}
        setStartWorkflow={setStartWorkflow}
        setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
        setWorkFlowBodyMessage={setWorkFlowBodyMessage}
        setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
        setIsWorkflowListAdded={setIsWorkflowListAdded}
        resumeBtnClick={resumeBtnClick}
        startFromBegnning={startFromBegnning}
        setResumeWorkflowIsActive={setResumeWorkflowIsActive}
        setRejectStatus={setRejectStatus}
        startTemplateWorkflow={startTemplateWorkflow}
        setEditWorkflowStarted={setEditWorkflowStarted}
        SetEditWorkflow={SetEditWorkflow}
        setIsCounterpartySigner={setIsCounterpartySigner}
      />
    </ApprovalsProvider>
  );
};

export default Approval;

const WorkFlowSection = ({
  isActiveOverView,
  startWorkFlow,
  setStartWorkflow,
  setShowSuccessToastWorkFlow,
  setWorkFlowBodyMessage,
  setWorkFlowShowFailureToast,
  setIsWorkflowListAdded,
  startFromBegnning,
  resumeBtnClick,
  setResumeWorkflowIsActive,
  setRejectStatus,
  startTemplateWorkflow,
  editWorkflow,
  setEditWorkflowStarted,
  SetEditWorkflow,
  setIsCounterpartySigner,
}: {
  isActiveOverView: string;
  startWorkFlow: boolean;
  setStartWorkflow: Function;
  setShowSuccessToastWorkFlow: Function;
  setWorkFlowBodyMessage: Function;
  setWorkFlowShowFailureToast: Function;
  setIsWorkflowListAdded: Function;
  startFromBegnning?: boolean;
  resumeBtnClick?: boolean;
  setResumeWorkflowIsActive: Function;
  setRejectStatus: Function;
  startTemplateWorkflow: boolean;
  editWorkflow: boolean;
  setEditWorkflowStarted: Function;
  SetEditWorkflow: Function;
  setIsCounterpartySigner: Function;
}) => {
  const { id } = useParams();
  const context = useApprovals();
  const authContext = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const contractContext = useContract();
  const templateContract = useTemplate();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [category, setCategory]: [string, Function] = useState("");
  const [department, setDepartment]: [string, Function] = useState("");
  const [contractValue, setContractValue]: [string, Function] = useState("");
  const [days]: [string, Function] = useState("");

  const [approvalsListDetail, setApprovalsListDetail]: [[], Function] =
    useState([]);
  const [approvalsTempListDetail, setApprovalsTempListDetail]: [[], Function] =
    useState([]);
  const [counterPartyLists, setCounterPartyLists]: [[], Function] = useState(
    []
  );
  const [contractIcon, setContractIcon]: [string, Function] = useState("INR");
  const [startValues, setStartValues] = useState<any>();
  const [endValues, setEndValues] = useState<any>();
  const [negotiationOwner, setNigotiationOwner] = useState<boolean>(false);
  const [viewerOwner, setViewerOwner] = useState<boolean>(false);

  const approvalsLists = async () => {
    try {
      if (isActiveOverView === "templates") {
        let response = await context?.getTemplatesDetails(id);

        if (response) {
          setApprovalsTempListDetail(response?.approvers);
        }
      } else if (isActiveOverView === "contracts") {
        let response = await context?.getContractDetails(id);

        if (response) {
          setCounterPartyLists(response?.contract?.external_users);
          setApprovalsListDetail(response?.contract?.approvers);
        }
      }
    } catch (error) {}
  };

  const onResetWorkflow = async () => {
    try {
      let requestBody = {
        restart: false,
      };
      let res = await context?.onPublishWorkflow(id, requestBody);
      setResumeWorkflowIsActive(false);
      setEditWorkflowStarted(true);
      context?.refresh();
      navigate(routes.contracts);

      let reject = res?.data?.contract?.approvers?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );
      if (reject?.length > 0) {
        setResumeWorkflowIsActive(true);
      }

      context?.getContractDetails(id);
      setWorkFlowBodyMessage(res?.data?.error?.[0]);
      setWorkFlowShowFailureToast(false);
    } catch (error) {}
  };

  const onResetTemplateWorkflow = async () => {
    try {
      let requestBody = {
        restart: false,
      };
      let res = await context?.onPublishTemplateWorkflow(id, requestBody);
      templateContract?.refresh();
      setResumeWorkflowIsActive(false);
      setEditWorkflowStarted(true);

      let reject = res?.data?.contract?.approvers?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );
      if (reject?.length > 0) {
        setResumeWorkflowIsActive(true);
      }

      setWorkFlowBodyMessage(res?.data?.error?.[0]);
      setWorkFlowShowFailureToast(false);
    } catch (error) {}
  };

  const onStartFromBegning = async () => {
    try {
      let requestBody = {
        restart: true,
      };
      let res = await context?.onPublishWorkflow(id, requestBody);
      setResumeWorkflowIsActive(true);
      setRejectStatus(false);
      context?.refresh();
      navigate(routes.contracts);
      context?.getContractDetails(id);
      setWorkFlowBodyMessage(res?.data?.error?.[0]);
      setWorkFlowShowFailureToast(false);
    } catch (error) {}
  };

  const onStartFromBegningForTmplate = async () => {
    try {
      let requestBody = {
        restart: true,
      };
      let res = await context?.onPublishTemplateWorkflow(id, requestBody);

      setResumeWorkflowIsActive(true);
      setRejectStatus(false);
      templateContract?.refresh();
      setWorkFlowBodyMessage(res?.data?.error?.[0]);
      setWorkFlowShowFailureToast(false);
    } catch (error) {}
  };

  const onStartWorkflowForTemplate = () => {};
  useEffect(() => {
    if (startTemplateWorkflow) {
      onStartWorkflowForTemplate();
    }
  }, [startTemplateWorkflow]);

  useEffect(() => {
    if (startFromBegnning && isActiveOverView !== "templates") {
      onStartFromBegning();
    }
  }, [startFromBegnning]);
  useEffect(() => {
    if (startFromBegnning && isActiveOverView === "templates") {
      onStartFromBegningForTmplate();
    }
  }, [startFromBegnning]);

  useEffect(() => {
    if (resumeBtnClick && isActiveOverView !== "templates") {
      onResetWorkflow();
    }
  }, [resumeBtnClick]);

  useEffect(() => {
    if (resumeBtnClick && isActiveOverView === "templates") {
      onResetTemplateWorkflow();
    }
  }, [resumeBtnClick]);

  const onStartWorkflow = async () => {
    if (isActiveOverView === "templates") {
      try {
        let requestBody = {
          restart: true,
        };

        let res = await context?.onPublishTemplateWorkflow(id, requestBody);

        if (res?.data?.success) {
          SetEditWorkflow(false);
          setEditWorkflowStarted(true);
          templateContract?.refresh();
          setStartWorkflow(false);
          setIsWorkflowListAdded(false);
        } else if (res?.data?.success === false) {
          setStartWorkflow(false);
          setEditWorkflowStarted(false);
          setShowFailureToast(true);
          setBodyMessage(res?.data?.error?.[0]);
        }
      } catch (error) {}
    }
    if (isActiveOverView !== "templates") {
      try {
        let requestBody = {
          restart: true,
        };
        let res = await context?.onPublishWorkflow(id, requestBody);

        if (res?.data?.success) {
          context?.getContractDetails(id);
          contractContext?.handleRefresh();
          setWorkFlowBodyMessage("Workflow started");
          setShowSuccessToastWorkFlow(true);
          SetEditWorkflow(false);
          setEditWorkflowStarted(true);
          setStartWorkflow(true);
          navigate(
            routes?.workflowAddSignatory(id, {
              signatory: true,
            })
          );

          context?.refresh();
        } else {
          setStartWorkflow(false);
          setEditWorkflowStarted(false);
          setShowFailureToast(true);
          setBodyMessage(res?.data?.error?.[0]);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (startWorkFlow) {
      onStartWorkflow();
    }
  }, [startWorkFlow]);

  useEffect(() => {
    if (location.pathname.includes("workflow")) {
      approvalsLists();
    }
  }, [location.pathname.includes("workflow")]);

  const onEditWorkflow = async () => {
    try {
      let response = await context?.onEditContractWorkflow(id);
      if (response) {
        context?.getContractDetails(id);
        setIsWorkflowListAdded(true);
        setStartWorkflow(false);
        contractContext?.handleRefresh();
      }
    } catch (error) {}
  };

  const onEditTemplateWorkflow = async () => {
    try {
      await context?.onEditTemplateWorkflow(id);
      setIsWorkflowListAdded(true);
      templateContract?.refresh();
    } catch (error) {}
  };
  useEffect(() => {
    if (isActiveOverView === "templates" && editWorkflow) {
      onEditTemplateWorkflow();
    }
  }, [editWorkflow]);

  useEffect(() => {
    if (isActiveOverView !== "templates" && editWorkflow) {
      onEditWorkflow();
    }
  }, [editWorkflow]);

  useEffect(() => {
    if (
      (approvalsListDetail?.length &&
        counterPartyLists?.length &&
        counterPartyLists?.find((el: any) => el?.access === "SIGNER") &&
        context?.signeeList?.length !== 0) ||
      (approvalsTempListDetail?.length > 0 && isActiveOverView === "templates")
    ) {
      setIsWorkflowListAdded(true);
    }
  }, [
    approvalsListDetail,
    counterPartyLists,
    context?.signeeList,
    approvalsTempListDetail,
  ]);

  const handleSubmitContractInfo = async () => {
    try {
      let requestBody = {
        name: !!contractContext?.data && contractContext?.data?.name,
        category: category ? category : contractContext?.data?.category,
        department: department ? department : contractContext?.data?.department,
        start_date: startValues
          ? startValues
          : moment(contractContext?.data?.start_date).toDate(),
        end_date: endValues
          ? endValues
          : moment(contractContext?.data?.end_date).toDate(),
        currency_type: contractIcon ? contractIcon : "",
        contract_value: contractValue
          ? parseInt(contractValue)
          : contractContext?.data?.contract_value,
        reminder: days ? parseInt(days) : null,
      };
      const response = await contractContext?.publishDocument(id, requestBody);
      if (response) {
        context?.refresh();
        setItems([
          {
            title: "Contract Information",
            selected: false,
          },
          { title: "Workflow", selected: true },
          { title: "Add coordinates", selected: false },
        ]);
      }
    } catch (error) {}
  };

  const [items, setItems]: [any[], Function] = useState([
    {
      title: "Contract Information",
      selected: true,
    },
    { title: "Workflow", selected: false },
    { title: "Add coordinates", selected: false },
  ]);

  useEffect(() => {
    if (location?.search === "?contractInfo=true") {
      setItems([
        {
          title: "Contract Information",
          selected: true,
        },
        {
          title: "Workflow",
          selected: false,
        },
        {
          title: "Add coordinates",
          selected: false,
        },
      ]);
    } else if (location?.search === "?signatory=true") {
      setItems([
        {
          title: "Contract Information",
          selected: false,
        },
        {
          title: "Workflow",
          selected: true,
        },
        {
          title: "Add coordinates",
          selected: false,
        },
      ]);
    } else if (location?.search === "?coordinates=true") {
      setItems([
        {
          title: "Contract Information",
          selected: false,
        },
        {
          title: "Workflow",
          selected: false,
        },
        {
          title: "Add coordinates",
          selected: true,
        },
      ]);
    }
  }, [location.search]);
  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setContractValue(numericValue);
  };

  useEffect(() => {
    if (contractContext?.data?.contract_value) {
      setContractValue(contractContext?.data?.contract_value);
    }
  }, [contractContext?.data?.contract_value]);

  useEffect(() => {
    let owner: any = contractContext?.data?.externalUser?.find(
      (el: any) => el?.access === "OWNER"
    );

    let viwer: any = contractContext?.data?.externalUser
      ?.filter((el: any) => el?.access === "VIEWER")
      ?.find((el: any) => el?.email === authContext?.currentUser?.email);

    if (owner?.email === authContext?.currentUser?.email) {
      setNigotiationOwner(true);
    }

    if (viwer?.email === authContext?.currentUser?.email) {
      setViewerOwner(true);
    }
  }, [contractContext?.data?.externalUser]);

  return (
    <>
      <Container>
        {(location.pathname.includes("templates") ||
          negotiationOwner ||
          viewerOwner) && (
          <Stack className=" ">
            <div className="" style={{ minHeight: "50vh" }}>
              <WorkflowMain
                setIsWorkflowListAdded={setIsWorkflowListAdded}
                isActiveOverView={isActiveOverView}
                setBodyMessage={setBodyMessage}
                setShowSuccessToast={setShowSuccessToast}
                setShowFailureToast={setShowFailureToast}
                approvalsLists={approvalsLists}
                approvalsListDetail={approvalsListDetail}
                counterPartyLists={counterPartyLists}
                setApprovalsListDetail={setApprovalsListDetail}
                approvalsTempListDetail={approvalsTempListDetail}
                setApprovalsTempListDetail={setApprovalsTempListDetail}
                setIsCounterpartySigner={setIsCounterpartySigner}
              />
            </div>
          </Stack>
        )}

        {!negotiationOwner && !viewerOwner && (
          <>
            {location.pathname.includes("contracts") && (
              <>
                {" "}
                <Stack direction="horizontal" className="form-wizard my-3">
                  {items.map((item: any, index: number) => {
                    return (
                      <>
                        <Stack
                          direction="vertical"
                          key={item.title.toLowerCase().split(" ").join("-")}
                          className=" justify-content-center align-items-center"
                        >
                          <Stack
                            direction="horizontal"
                            className={`form-wizard-item justify-content-center align-items-center ${
                              !!item.selected && "active"
                            }`}
                          >
                            {index > 0 ? <hr /> : <div className="w-100"></div>}
                            <Stack
                              className="p-2 px-3  rounded-circle"
                              style={{
                                border: "1px solid #3d70b2",
                                color: item.selected ? "#fefefe" : "#3d70b2",
                                background: item.selected
                                  ? "#3d70b2"
                                  : "transparent",
                              }}
                            >
                              {index + 1}
                            </Stack>
                            {index < items.length - 1 ? (
                              <hr />
                            ) : (
                              <div className="w-100"> </div>
                            )}
                          </Stack>
                          <div
                            className={` py-2 wizardTitle ${
                              !!item.selected && "fw-semibold"
                            }`}
                            style={{ width: "150px" }}
                          >
                            {item.title}
                          </div>
                        </Stack>
                      </>
                    );
                  })}
                </Stack>
              </>
            )}
            {items?.[0]?.selected === true &&
              location?.search === "?contractInfo=true" && (
                <Stack>
                  <div className=" Approver_box_new row">
                    <div className="d-flex justify-content-center px-3 py-3">
                      <div className=" d-flex align-items-center">
                        <div>
                          <h6 className="m-0 fw-semibold">
                            Contract Information
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className=" d-flex justify-content-center ">
                      <div className="  linemiddle"></div>
                    </div>
                  </div>
                  <div
                    className={"Approveractive-content "}
                    style={{ border: "1px solid #f1f1f5" }}
                  >
                    <div
                      className=" d-flex position-relative p-4 flex-column justify-content-between"
                      style={{ minHeight: "30vh" }}
                    >
                      <div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12  pe-md-4">
                            <label className="input_label">Department</label>

                            <Select
                              classNamePrefix="select"
                              defaultValue={{
                                label: contractContext?.data?.department,
                                value: contractContext?.data?.department,
                              }}
                              className={`basic-multi-select `}
                              options={contractContext?.departmentList?.map(
                                (item: any) => ({
                                  label: item,
                                  value: item,
                                })
                              )}
                              onChange={(e) => setDepartment(e?.value)}
                            />
                          </div>
                          <div className="col-md-6 col-12  ">
                            <label className="input_label">Category</label>
                            <Select
                              defaultValue={{
                                label: contractContext?.data?.category,
                                value: contractContext?.data?.category,
                              }}
                              classNamePrefix="select"
                              className={`basic-multi-select `}
                              options={(
                                contractContext?.defaultCategories || []
                              )?.map((category: ITemplateCategory) => ({
                                label: category?.category,
                                value: category?.category,
                              }))}
                              onChange={(e) => setCategory(e?.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12  pe-md-4">
                            <div className="from-control w-100">
                              <Form.Group>
                                <div className="input_label">Start date</div>
                                <DatePicker
                                  className=" w-100 from-control"
                                  format="dd/MM/yyyy"
                                  value={
                                    startValues
                                      ? moment(startValues).toDate()
                                      : moment(
                                          contractContext?.data?.start_date
                                        ).toDate()
                                  }
                                  onChange={(date: any) => {
                                    setStartValues(date);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="col-md-6 col-12  ">
                            <div className="from-control">
                              <Form.Group>
                                <div className="input_label ">End date</div>
                                <DatePicker
                                  minDate={moment(startValues).toDate()}
                                  className="from-control w-100 mb-2"
                                  format="dd/MM/yyyy"
                                  value={
                                    endValues
                                      ? moment(endValues).toDate()
                                      : moment(
                                          contractContext?.data?.expiry_date
                                        ).toDate()
                                  }
                                  onChange={(date: any) => {
                                    setEndValues(date);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-6 col-12  pe-md-4">
                            <label className="input_label">
                              Contract Value
                            </label>

                            <InputGroup className="mb-3 pb-5">
                              <InputGroup.Text>
                                <Select
                                  placeholder="Select.."
                                  styles={customStyles}
                                  defaultValue={{
                                    label: contractContext?.data?.currency_type
                                      ? contractContext?.data?.currency_type
                                      : contractContext?.variableTypes?.[0],
                                    value: contractContext?.data?.currency_type
                                      ? contractContext?.data?.currency_type
                                      : contractContext?.variableTypes?.[0],
                                  }}
                                  classNamePrefix="select"
                                  className={`basic-multi-select contract_value `}
                                  options={contractContext?.variableTypes?.map(
                                    (el: any) => ({ label: el, value: el })
                                  )}
                                  onChange={(e: any) =>
                                    setContractIcon(e?.label)
                                  }
                                />
                              </InputGroup.Text>

                              <Form.Control
                                aria-label="Amount (to the nearest dollar)"
                                value={contractValue}
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {context?.contractDetail?.status !== "EXPIRED" && (
                    <>
                      <div className="d-flex justify-content-end mt-4">
                        <Button
                          onClick={() => {
                            handleSubmitContractInfo();
                            navigate(
                              routes?.workflowAddSignatory(id, {
                                signatory: true,
                              })
                            );
                            contractContext?.refresh();
                          }}
                          className="updatebtndanger"
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </Stack>
              )}
            {items?.[1]?.selected === true &&
              location?.search === "?signatory=true" && (
                <Stack className=" ">
                  <div className="" style={{ minHeight: "50vh" }}>
                    <WorkflowMain
                      setIsWorkflowListAdded={setIsWorkflowListAdded}
                      isActiveOverView={isActiveOverView}
                      setBodyMessage={setBodyMessage}
                      setShowSuccessToast={setShowSuccessToast}
                      setShowFailureToast={setShowFailureToast}
                      approvalsLists={approvalsLists}
                      approvalsListDetail={approvalsListDetail}
                      counterPartyLists={counterPartyLists}
                      setApprovalsListDetail={setApprovalsListDetail}
                      approvalsTempListDetail={approvalsTempListDetail}
                      setApprovalsTempListDetail={setApprovalsTempListDetail}
                      setIsCounterpartySigner={setIsCounterpartySigner}
                    />
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      onClick={() => {
                        navigate(
                          routes?.workflowContractInfo(id, {
                            contractInfo: true,
                          })
                        );
                        setItems([
                          {
                            title: "Contract Information",
                            selected: true,
                          },
                          { title: "Workflow", selected: false },
                          { title: "Add coordinates", selected: false },
                        ]);
                      }}
                    >
                      Back
                    </Button>
                    {contractContext?.data?.workflow_status !== "PUBLISHED" &&
                      contractContext?.data?.workflow_status !== "COMPLETED" &&
                      contractContext?.data?.workflow_status !==
                        "workflow_status" &&
                      contractContext?.data?.workflow_status !==
                        "PENDING_NEGOTIATION_APPROVAL" &&
                      contractContext?.data?.workflow_status !==
                        "NEGOTIATION_STARTED" &&
                      contractContext?.data?.sign_type !== undefined &&
                      contractContext?.data?.collaborators?.find(
                        (el: any) => el?.access === "OWNER"
                      )?.user?.email === authContext?.currentUser?.email &&
                      contractContext?.data?.internalSingers?.length !== 0 &&
                      contractContext?.data?.externalUser?.filter(
                        (el: any) => el?.access === "SIGNER"
                      )?.length !== 0 && (
                        <>
                          <Button
                            onClick={() => {
                              contractContext?.refresh();
                              navigate(
                                routes?.workflowCoordinate(id, {
                                  coordinates: true,
                                })
                              );
                              setItems([
                                {
                                  title: "Contract Information",
                                  selected: false,
                                },
                                { title: "Workflow", selected: false },
                                { title: "Add coordinates", selected: true },
                              ]);
                            }}
                          >
                            Next
                          </Button>
                        </>
                      )}
                  </div>
                </Stack>
              )}
            {items?.[2]?.selected === true && (
              <>
                <DesignateField />
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={() => {
                      navigate(
                        routes?.workflowAddSignatory(id, {
                          signatory: true,
                        })
                      );
                      setItems([
                        {
                          title: "Contract Information",
                          selected: false,
                        },
                        { title: "Workflow", selected: true },
                        { title: "Add coordinates", selected: false },
                      ]);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Container>

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={""}
          title={"Approver added!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage ? bodyMessage : "Something went wrong!."}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};
