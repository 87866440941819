import { ReactNode, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  MdArticle,
  MdAssignmentAdd,
  MdBusiness,
  MdKey,
  MdLogout,
  // MdNotificationsNone,
  MdPeople,
  MdPersonOutline,
} from "react-icons/md";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../utils/routes";
import logo from "../assets/logo12.png";
import {
  BsArrowRight,
  BsGraphUp,
  BsArrowDownCircle,
  BsFileEarmarkRichtextFill,
} from "react-icons/bs";

function HeaderPopup({
  show,
  onHide,
  setShowHeaderPopup,
}: {
  show: boolean;
  onHide: Function;
  setShowHeaderPopup: Function;
}) {
  const MenuItem = ({
    url,
    icon,
    text,
    active,
  }: {
    url: string;
    icon: ReactNode;
    text: string;
    active?: boolean;
  }) => (
    <Link
      to={url}
      className={`menu-item text-decoration-none ${!!active && "active"}`}
    >
      <li className="d-flex align-items-center p-3 text-white">
        {icon && <span className="me-2">{icon}</span>}
        {text}
      </li>
    </Link>
  );
  const location = useLocation();
  const navigate = useNavigate();

  // const navigate = useNavigate();
  const [isProfileActive, setProfileActive]: [boolean, Function] =
    useState(true);

  const isActiveAnalytics: boolean = !!matchPath(
    location.pathname,
    routes.analytics
  );
  const [open, setOpen] = useState(true);
  const [privateFolderActive, setPrivateFolderACtive]: [boolean, Function] =
    useState(false);
  const [publicFolderActive, setPublicFolderACtive]: [boolean, Function] =
    useState(false);

  return (
    <>
      <Modal
        className="headerpopup"
        show={show}
        onHide={() => {
          onHide();
          setShowHeaderPopup(false);
        }}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <div className=" d-flex align-items-center flex-grow-1">
            <div className="pp ">
              <img src={logo} alt="logo" width={80} />
            </div>
            <div className=" d-flex flex-grow-1 align-items-center justify-content-evenly">
              <div
                className="header-css"
                onClick={() => setProfileActive(true)}
              >
                Home
              </div>
              <div
                className="header-css"
                onClick={() => setProfileActive(false)}
              >
                Profile
              </div>
            </div>
          </div>
        </Modal.Header>

        {isProfileActive ? (
          <ul className="bg-primary text-white  list-unstyled flex-grow-1 headerli m-0">
            <div
              className="Home-css"
              onClick={() => navigate(routes.analytics)}
            >
              <MenuItem
                url={routes.analytics}
                active={isActiveAnalytics}
                icon={<BsGraphUp />}
                text={"Analytics"}
              />
              <BsArrowRight className="arrow-css" />
            </div>

            <div
              className="Home-css"
              onClick={() => navigate(routes.contracts)}
            >
              <MenuItem
                url={routes.contracts}
                icon={<MdArticle />}
                text={"Contracts"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div
              className="Home-css"
              onClick={() => navigate(routes.templates)}
            >
              <MenuItem
                url={routes.templates}
                icon={<MdAssignmentAdd />}
                text={"Templates"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div className="Home-css" onClick={() => navigate(routes.members)}>
              <MenuItem
                url={routes.members}
                icon={<MdPeople />}
                text={"Members"}
              />{" "}
              <BsArrowRight className="arrow-css" />
            </div>
            <div className="Home-css">
              <div
                className={`menu-item text-decoration-none  flex-grow-1
      
        `}
              >
                <div
                  className={open ? "sidebar-item open   " : "sidebar-item  "}
                  style={{ cursor: "default" }}
                >
                  <div className="sidebar-title-mobile d-flex justify-content-between align-items-center p-3 ">
                    <div>
                      <span className="me-2">
                        <BsFileEarmarkRichtextFill
                          color="#fefefe"
                          fontSize={17}
                        />
                      </span>
                      <span className="sidebar_text">Folders</span>
                    </div>

                    <div
                      className=" toggle-btn mt-1"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <BsArrowDownCircle />
                    </div>
                  </div>
                </div>
                {open && (
                  <>
                    <Link
                      to={routes.privateFolders}
                      className={`menu-item text-decoration-none ${
                        (!!matchPath(
                          location.pathname,
                          routes.privateFolders
                        ) ||
                          !!matchPath(
                            routes.privatefolderId,
                            location.pathname
                          )) &&
                        "nav-active"
                      }
        `}
                    >
                      <div
                        className={
                          privateFolderActive
                            ? "sidebar-item open   "
                            : "sidebar-item  "
                        }
                      >
                        <div
                          className=" d-flex justify-content-between align-items-center p-4 "
                          onClick={() =>
                            setPrivateFolderACtive(!privateFolderActive)
                          }
                        >
                          <div className="inner-sidebar">
                            <span className="me-2">
                              <BsFileEarmarkRichtextFill
                                color="#fefefe"
                                fontSize={17}
                              />
                            </span>
                            <span className="sidebar_text">Private </span>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to={routes.publicFolder}
                      className={`menu-item text-decoration-none ${
                        (!!matchPath(location.pathname, routes.publicFolder) ||
                          !!matchPath(
                            routes.publicfolderId,
                            location.pathname
                          )) &&
                        "nav-active"
                      }
        `}
                    >
                      <div
                        className={
                          publicFolderActive
                            ? "sidebar-item open   "
                            : "sidebar-item  "
                        }
                      >
                        <div
                          className=" d-flex justify-content-between align-items-center p-4 "
                          onClick={() =>
                            setPublicFolderACtive(!publicFolderActive)
                          }
                        >
                          <div className="inner-sidebar">
                            <span className="me-2">
                              <BsFileEarmarkRichtextFill
                                color="#fefefe"
                                fontSize={17}
                              />
                            </span>
                            <span className="sidebar_text">Public </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </ul>
        ) : (
          <ul className="bg-primary text-white  list-unstyled flex-grow-1 headerli m-0">
            <div className="Home-css" onClick={() => navigate(routes.profile)}>
              <MenuItem
                url={routes.profile}
                icon={<MdPersonOutline />}
                text={" Personal Profile"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div
              className="Home-css"
              onClick={() => navigate(routes.companyProfile)}
            >
              <MenuItem
                url={routes.companyProfile}
                icon={<MdBusiness />}
                text={" Company Profile"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            <div
              className="Home-css"
              onClick={() => navigate(routes.changePassword)}
            >
              <MenuItem
                url={routes.changePassword}
                icon={<MdKey />}
                text={"Change Password"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
            {/* <div className="Home-css">
              <MenuItem
                icon={<MdNotificationsNone />}
                text={"Notifications"}
                url={""}
              />
              <BsArrowRight className="arrow-css" />
            </div> */}
            {/* <div className="Home-css">
              <MenuItem
                icon={<MdNotificationsNone />}
                text={"Signature"}
                url={routes.mysignature}
              />
              <BsArrowRight className="arrow-css" />
            </div> */}
            <div className="Home-css" onClick={() => navigate(routes?.root)}>
              <MenuItem
                url={routes?.root}
                icon={<MdLogout />}
                text={"Logout"}
              />
              <BsArrowRight className="arrow-css" />
            </div>
          </ul>
        )}
      </Modal>
    </>
  );
}

export default HeaderPopup;
