import { useEffect, useState } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsThreeDots } from "react-icons/bs";
import { MdAddCircle, MdCancel, MdCheckCircle } from "react-icons/md";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { TableColumnsEnum } from "../../../interfaces/TableColumnsEnum";
import { TableColumnsInterface } from "../../../interfaces/TableColumnsInterface";
import { Contract } from "../../../lib/Contract";
import { routes } from "../../../utils/routes";
import { useContract } from "../ListContractProvider";
import DeleteToast from "../../../ui/DeleteToast";
import SuccessToast from "../../../ui/SuccessToast";
import moment from "moment";
import UpdateName from "./UpdateName";
import FailureToast from "../../../ui/FailureToast";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { Collaborator } from "../../../lib/Collaborator";
import { StatusEnum } from "../../../interfaces/StatusEnum";

function Contracts({
  setMoveToFolders,
  setSelectedId,
}: {
  setMoveToFolders: Function;
  setSelectedId: Function;
}) {
  const context = useContract();
  const navigate = useNavigate();
  const location = useLocation();
  const loggedInContext = useAuthentication();

  const { changeFilter, filterParams }: any = context;
  const [columnsData, setColumnsData]: [TableColumnsInterface[], Function] =
    useState([]);
  const [contractId, setContractId]: [string, Function] = useState("");
  const [updateName, setUpdateName]: [boolean, Function] = useState(false);
  const [documentName, setDocumentName]: [string, Function] = useState("");
  const [documentId, setDocumentId]: [string, Function] = useState("");
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();
  const [rolesRead, setRolesRead]: [any, Function] = useState();

  useEffect(() => {
    let role: any = loggedInContext?.currentUser?.roles?.[0];
    if (loggedInContext?.rolesAndAccess) {
      const guestRole = loggedInContext?.rolesAndAccess[role];

      setRolesUpdate(guestRole?.CONTRACTS?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.CONTRACTS?.find((el: any) => el === "DELETE"));
      setRolesRead(guestRole?.CONTRACTS?.find((el: any) => el === "READ"));
    }
  }, [loggedInContext?.rolesAndAccess]);

  const noData = (department: string) => {
    if (department) {
      return department;
    } else {
      return <span className="text-align-center">{"-"}</span>;
    }
  };

  const onDelete = async (id: string) => {
    let res = await context?.deleteContract(id);

    if (res?.success) {
      context?.getRecentDocuments();
      setShowSuccessToast(true);
      setShowDeleteToast(false);
      setBodyMessage(res?.message);
    }
    if (!res?.success) {
      setBodyMessage(res?.message);
      setShowFailureToast(true);
      setShowDeleteToast(false);
    }
  };

  const columns: any = [
    {
      name: "Contract name",
      minWidth: "200px",
      selector: (row: any) => {
        return (
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.name}
          >
            {row?.name?.charAt(0)?.toUpperCase() + row?.name?.slice(1)}
          </div>
        );
      },
      // selector: (row: any) =>
      //   row?.name?.charAt(0)?.toUpperCase() + row?.name?.slice(1),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Status",
      grow: 0,
      reorder: true,
      minWidth: "200px",
      sortable: true,
      cell: (row: any) => {
        let users = row?.approvers?.find((user: Contract) => {
          return user?.user?.sub === loggedInContext?.currentUser?.sub;
        });

        return (
          <Badge
            className={` ${
              row?.status === "DRAFT"
                ? "draft-badge"
                : row?.status === StatusEnum?.published
                ? "publish-badge"
                : row?.status === StatusEnum?.pending_approve
                ? "pendingapproval-badge"
                : row?.status === StatusEnum?.approved &&
                  row?.stamp_required === true &&
                  loggedInContext?.currentUser?.sub !== row?.owner?.sub
                ? "approved-badge"
                : row?.status === StatusEnum?.approved &&
                  row?.stamp_required === false &&
                  loggedInContext?.currentUser?.sub !== row?.owner?.sub
                ? "signinitiated-badge"
                : row?.status === StatusEnum?.approved &&
                  row?.stamp_required === false &&
                  loggedInContext?.currentUser?.sub === row?.owner?.sub
                ? "signinitiated-badge"
                : row?.status === StatusEnum?.approved &&
                  row?.stamp_required === true &&
                  loggedInContext?.currentUser?.sub === row?.owner?.sub
                ? "approved-badge"
                : row?.status === StatusEnum?.rejected
                ? "rejected_badge"
                : row?.status === StatusEnum?.sign_initiated
                ? "stamped_badge"
                : row?.status === "COMPLETED"
                ? "complete-badge"
                : row?.status === StatusEnum?.stamped
                ? "stamped_badge"
                : row?.status === "PENDING"
                ? "pending_badge"
                : row?.status === StatusEnum?.stamp_failed
                ? "Stamp_failed_badge"
                : row?.status === StatusEnum?.pending_negotitation
                ? "pending_negotiation_badge"
                : "draft-badge"
            }`}
            bg="success"
          >
            {row?.status === StatusEnum?.pending_negotitation_approval ? (
              "Pending negotiation approval"
            ) : row?.status === "EXPIRED" ? (
              "Expired"
            ) : row?.status === StatusEnum?.approved &&
              row?.stamp_required === true &&
              loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
              "E-stamp initiated"
            ) : row?.status === StatusEnum?.approved &&
              row?.stamp_required === false &&
              loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
              "Sign required"
            ) : row?.status === StatusEnum?.approved &&
              row?.stamp_required === false &&
              loggedInContext?.currentUser?.sub === row?.owner?.sub ? (
              "Sign initiated"
            ) : row?.status === StatusEnum?.approved &&
              row?.stamp_required === true &&
              loggedInContext?.currentUser?.sub === row?.owner?.sub ? (
              "E-stamp required"
            ) : row?.status === StatusEnum?.rejected ? (
              "Rejected"
            ) : row?.status === "DRAFT" ? (
              "Draft"
            ) : row?.status === StatusEnum?.stamped &&
              loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
              "Stamped"
            ) : row?.status === StatusEnum?.stamped &&
              loggedInContext?.currentUser?.sub === row?.owner?.sub ? (
              "Sign initiated"
            ) : row?.status === StatusEnum?.published ? (
              "Published"
            ) : row?.status === StatusEnum?.stamp_initiated ? (
              "Stamp initiated"
            ) : row?.status === "COMPLETED" ? (
              "Signed"
            ) : row?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub === row?.owner?.sub ? (
              "Sign initiated"
            ) : row?.status === StatusEnum?.pending_negotitation &&
              loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
              "Negotiation ongoing"
            ) : row?.status === StatusEnum?.pending_negotitation &&
              loggedInContext?.currentUser?.sub === row?.owner?.sub ? (
              "Negotiation initiated"
            ) : row?.status === StatusEnum?.sign_initiated &&
              loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
              "Sign required"
            ) : row?.status === StatusEnum?.stamp_failed ? (
              "E-stamp failed"
            ) : row?.status === "PENDING" ? (
              "Paused"
            ) : (
              <>
                {users?.status === StatusEnum?.approved &&
                loggedInContext?.currentUser?.sub !== row?.owner?.sub ? (
                  "Approved"
                ) : users?.status === StatusEnum?.rejected ? (
                  "Rejected"
                ) : (
                  <>
                    {loggedInContext?.currentUser?.sub !== row?.owner?.sub &&
                    row?.status === StatusEnum?.pending_approve
                      ? "Approval required"
                      : loggedInContext?.currentUser?.sub !== row?.owner?.sub &&
                        users?.status === "PENDING" &&
                        "Approval required"}
                  </>
                )}
              </>
            )}

            {loggedInContext?.currentUser?.sub === row?.owner?.sub &&
              row?.status === StatusEnum?.pending_approve &&
              "Pending for approval"}
          </Badge>
        );
      },
    },
    {
      name: "Access",
      width: "120px",
      selector: (row: any) => {
        let collab = row?.collaborators?.find(
          (coll: Collaborator) =>
            coll?.user?.sub === loggedInContext?.currentUser?.sub
        );

        return <span>{collab?.access ? collab?.access : "-"}</span>;
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Expiry date",
      width: "130px",
      selector: (row: any) => moment(row?.expiry_date).format("DD MMM YYYY"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        let collab = row?.collaborators?.find(
          (coll: Collaborator) =>
            coll?.user?.sub === loggedInContext?.currentUser?.sub
        );

        return (
          <div className="pt-2 Actions">
            <Dropdown drop="end">
              <Dropdown.Toggle
                variant="success"
                className="Actions_title"
                id="dropdown-basic"
              >
                <BsThreeDots fontSize={24} />
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                {(row?.status === "DRAFT" ||
                  row?.status === StatusEnum?.published) && (
                  <>
                    {collab?.access !== "VIEWER" && (
                      <>
                        {rolesUpdate === "UPDATE" && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                setUpdateName(true);
                                setDocumentName(row?.name);
                                setDocumentId(row?.id);
                              }}
                            >
                              Rename
                            </Dropdown.Item>

                            {row?.status === "DRAFT" && (
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  navigate(
                                    generatePath(routes.editContract, {
                                      id: row.id,
                                    })
                                  );
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {rolesRead === "READ" && (
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        navigate(
                          generatePath(routes.overviewContracts, {
                            id: row?.id || "",
                          })
                        );
                      }}
                    >
                      View
                    </Dropdown.Item>
                  </>
                )}

                {loggedInContext?.currentUser?.roles?.[0] !== "GUEST" && (
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => context?.handleContractDownload(row?.id)}
                    >
                      <a
                        href={""}
                        download
                        className=" text-decoration-none Action_download"
                      >
                        Download
                      </a>
                    </Dropdown.Item>
                    {(row?.status === "DRAFT" ||
                      row?.status === StatusEnum?.published) && (
                      <>
                        {collab?.access !== "VIEWER" &&
                          collab?.access !== "EDITOR" && (
                            <>
                              {row?.department && (
                                <>
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      setMoveToFolders(true);
                                      setSelectedId(row);
                                    }}
                                  >
                                    Move to folder
                                  </Dropdown.Item>
                                </>
                              )}
                              {rolesDelete === "DELETE" && (
                                <>
                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      setContractId(row.id);
                                      setShowDeleteToast(true);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </>
                              )}
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
    {
      name: "Created by",
      width: "150px",
      selector: (row: any) =>
        row?.createdBy?.name?.charAt(0)?.toUpperCase() +
        row?.createdBy?.name?.slice(1) +
        " " + // Add a space character
        row?.createdBy?.last_name,

      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Department",
      width: "140px",
      selector: (row: any) =>
        noData(row?.department === "CXOs" ? "CXO" : row?.department),
      grow: 0,
      reorder: true,
      sortable: true,
    },

    {
      name: "Category",
      width: "200px",
      selector: (row: any) => noData(row?.category),
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Updated at",
      width: "210px",
      selector: (row: any) =>
        moment(row?.updatedAt).format("DD MMM YYYY, h:mm:ss A"),
      grow: 0,
      reorder: true,
      sortable: true,
    },
  ];

  const [FilteredcolumnsData, setFilteredColumnsData]: [
    TableColumnsInterface[],
    Function
  ] = useState(columns);
  const [selectedTableColumn, setSelectedTableColumn]: [any[], Function] =
    useState(columns.map((columnName: any) => columnName.name).slice(0, 10));
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const [showDeleteToast, setShowDeleteToast] = useState(false);

  const onHandleSelectColumn = (newValue: string) => {
    if (
      selectedTableColumn?.length > 4 ||
      !selectedTableColumn?.includes(newValue)
    ) {
      if (
        selectedTableColumn.includes(newValue)
        //  &&
        // newValue !== "Contract name"
      ) {
        setSelectedTableColumn(
          selectedTableColumn.filter(
            (selectedColumn) => selectedColumn !== newValue
          )
        );
      } else {
        if (selectedTableColumn.length < 9) {
          setSelectedTableColumn([...selectedTableColumn, newValue]);
        }
      }
    }
  };

  useEffect(() => {
    setColumnsData(columns);
  }, [context?.recentContracts]);

  useEffect(() => {
    if (selectedTableColumn.length && !!columnsData.length) {
      const a = columnsData?.filter((selectedColumn: TableColumnsInterface) => {
        return !!selectedTableColumn.includes(selectedColumn.name);
      });
      const _index = a.map((aa: any) => aa.name).indexOf("Actions");
      const b = a.splice(_index, 1);
      a.push(b[0]);
      if (location.pathname.includes("folder")) {
        setFilteredColumnsData(a.filter((aa: any) => aa.name !== "Actions"));
      } else {
        setFilteredColumnsData(a);
      }
    }
  }, [selectedTableColumn, selectedTableColumn.length, columnsData]);

  return (
    <div className="mx-2 mb-5" style={{ background: "#fefefe" }}>
      {!location.pathname.includes("folder") && (
        <div className="agreement-box mt-4">
          <div className="d-flex bd-highlight justify-content-end ">
            {showDeleteToast && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  onDelete(contractId);
                }}
                onClose={() => setShowDeleteToast(false)}
                title="Do you want to delete the contract?"
                closeText="No, don't !"
                submitText="Yes, delete it."
              />
            )}

            <Dropdown className="add-template">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="default"
              >
                <span className="me-3 pointer">
                  <MdAddCircle fontSize={24} color="#b1b1b1" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                variant="default"
                className="add-template box-shadow px-4 py-3"
              >
                <div className="d-flex flex-row mb-3">
                  <span className="manage-data">Manage table data</span>
                </div>
                <div className="min-allowes">
                  Min 3 , and max 8 are only allowed
                </div>
                <div className="d-flex flex-row mb-3">
                  <div className="p-2">
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.contactName}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.contactName
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.contactName)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.contactName}
                      >
                        {TableColumnsEnum?.contactName}
                      </label>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.status}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.status
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.status)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.status}
                      >
                        {TableColumnsEnum?.status}
                      </label>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.expiry_date}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.expiry_date
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.expiry_date)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.expiry_date}
                      >
                        {TableColumnsEnum?.expiry_date}
                      </label>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.access}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.access
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.access)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.access}
                      >
                        {TableColumnsEnum?.access}
                      </label>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.department}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.department
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.department)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.department}
                      >
                        {TableColumnsEnum?.department}
                      </label>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.createdby}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.createdby
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.createdby)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.createdby}
                      >
                        {TableColumnsEnum?.createdby}
                      </label>
                    </div>

                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.category}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.category
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.category)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.category}
                      >
                        {TableColumnsEnum?.category}
                      </label>
                    </div>

                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={TableColumnsEnum?.updatedAt}
                        checked={selectedTableColumn.includes(
                          TableColumnsEnum?.updatedAt
                        )}
                        onChange={() =>
                          onHandleSelectColumn(TableColumnsEnum?.updatedAt)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={TableColumnsEnum?.updatedAt}
                      >
                        {TableColumnsEnum?.updatedAt}
                      </label>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}

      {!!context?.recentContracts && context?.recentContracts?.length > 0 && (
        <DataTable
          className="data-table"
          columns={FilteredcolumnsData}
          data={context.recentContracts}
          highlightOnHover
          progressPending={!context?.contractTableLists}
          progressComponent={<>Loading...</>}
          paginationPerPage={filterParams.limit}
          paginationTotalRows={context?.metaData?.total_count}
          paginationDefaultPage={1}
          onChangeRowsPerPage={(currentRowsPerPage: number) => {
            changeFilter({ ...filterParams, limit: currentRowsPerPage });
          }}
          onChangePage={(page: number) =>
            changeFilter({ ...filterParams, page })
          }
          pagination
          paginationServer
          onRowClicked={(rowDetail: Contract) => {
            location.pathname.includes("folder")
              ? setMoveToFolders(true)
              : navigate(
                  generatePath(routes.overviewContracts, {
                    id: rowDetail?.id || "",
                  })
                );
          }}
        />
      )}

      {!!context && updateName && (
        <UpdateName
          show={updateName}
          onHide={() => setUpdateName(false)}
          documentName={documentName}
          context={context}
          setShowSuccessToast={setShowSuccessToast}
          setBodyMessage={setBodyMessage}
          documentId={documentId}
        />
      )}

      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage ? bodyMessage : "Successfully deleted!"}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
    </div>
  );
}

export default Contracts;
