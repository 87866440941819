import { useEffect, useState } from "react";
import AppLayout from "../../../layouts/AppLayout";
import AllFolders from "./AllFolders";
import FolderHeader from "./FolderHeader";
import RecentFolders from "./RecentFolders";
import CreateFolderPopup from "./CreateFolderPopup";
import { FoldersProvider, useFolders } from "../FoldersProvider";
import SuccessToast from "../../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import MoveToFolders from "./MoveToFolders";
import FailureToast from "../../../ui/FailureToast";
import MoveFoldersPopup from "./MoveFoldersPopup";
import ShareFolderPopup from "./ShareFolderPopup";

function Folders() {
  return (
    <>
      <AppLayout>
        <FoldersProvider>
          <FoldersDetail />
        </FoldersProvider>
      </AppLayout>
    </>
  );
}

export default Folders;

const FoldersDetail = () => {
  const context = useFolders();
  const [addFolderPopup, setAddFolderPopup]: [boolean, Function] =
    useState(false);
  const [onFolderCLick, setFolderClick] = useState<any>([]);
  const { id } = useParams();
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [activeFolder, setACtiveFolder]: [[], Function] = useState([]);
  const [movetoFolder, setMoveToFolders]: [boolean, Function] = useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [isActiveMove, setIsActiveMove]: [string, Function] = useState("");
  const [moveFolders, setmoveFolders]: [boolean, Function] = useState(false);
  const [shareFolder, setShareFolder]: [boolean, Function] = useState(false);

  useEffect(() => {
    if (context?.folderList) {
      setACtiveFolder(context?.folderList);
    }
  }, [context?.folderList]);

  return (
    <>
      <FolderHeader
        setAddFolderPopup={setAddFolderPopup}
        setMoveToFolders={setMoveToFolders}
        setIsActiveMove={setIsActiveMove}
      />
      <RecentFolders
        setACtiveFolder={setACtiveFolder}
        setFolderClick={(data: any) => setFolderClick(data)}
        onFolderCLick={onFolderCLick}
        setAddFolderPopup={setAddFolderPopup}
        setmoveFolders={setmoveFolders}
        setShareFolder={setShareFolder}
        setMoveToFolders={setMoveToFolders}
        setIsActiveMove={setIsActiveMove}
      />

      {id! && activeFolder?.length === 0 ? (
        ""
      ) : (
        <>
          <>
            {id &&
              (context?.folderList?.contracts?.length !== 0 ||
                context?.folderList?.templates?.length !== 0) && (
                <>
                  <div className="d-flex mt-4 mb-3 mx-2 ">
                    <h6 className="contract-title pb-1 m-0">All Documents</h6>
                  </div>

                  <AllFolders />
                </>
              )}
          </>
        </>
      )}

      {addFolderPopup && (
        <>
          <CreateFolderPopup
            setShowFailureToast={setShowFailureToast}
            show={addFolderPopup}
            setACtiveFolder={setACtiveFolder}
            onHide={() => setAddFolderPopup(false)}
            setAddFolderPopup={setAddFolderPopup}
            setBodyMessage={setBodyMessage}
            setShowSuccessToast={setShowSuccessToast}
          />
        </>
      )}

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}

      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}

      {movetoFolder && (
        <MoveToFolders
          activeFolder={activeFolder}
          show={movetoFolder}
          onHide={() => setMoveToFolders(false)}
          isActiveMove={isActiveMove}
          setShowFailureToast={setShowFailureToast}
          setBodyMessage={setBodyMessage}
        />
      )}

      {moveFolders && (
        <MoveFoldersPopup
          show={moveFolders}
          onHide={() => setmoveFolders(false)}
        />
      )}

      {shareFolder && (
        <ShareFolderPopup
          show={shareFolder}
          onHide={() => setShareFolder(false)}
          setShowFailureToast={setShowFailureToast}
          setBodyMessage={setBodyMessage}
          setShowSuccessToast={setShowSuccessToast}
        />
      )}
    </>
  );
};
