import { useLocation } from "react-router-dom";
import AdminAnalyticsOrganisations from "./AdminOrganisation/AdminAnalyticsOrganisations";
import AdminAnalyticsSummary from "./AdminSummary/AdminAnalyticsSummary";
import AdminAnalyticsContract from "./AdminContracts/AdminAnalyticsContract";
import AdminAnalyticsTurnaroundTime from "./AdminTurnaroundTime/AdminAnalyticsTurnaroundTime";

const AdminAnalyticsOverview = ({ filterData }: { filterData: boolean }) => {
  const location = useLocation();
  return (
    <>
      {location.pathname.includes("/admin/analytics/summary") && (
        <AdminAnalyticsSummary filterData={filterData} />
      )}
      {location.pathname.includes("/admin/analytics/Organisations") && (
        <AdminAnalyticsOrganisations filterData={filterData} />
      )}
      {location.pathname.includes("/admin/analytics/contracts") && (
        <AdminAnalyticsContract filterData={filterData} />
      )}
      {location.pathname.includes("/admin/analytics/turnaround") && (
        <AdminAnalyticsTurnaroundTime filterData={filterData} />
      )}
    </>
  );
};

export default AdminAnalyticsOverview;
