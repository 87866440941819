import { Fragment, useEffect, useState } from "react";
import SignaturePopup from "./SignaturePopup";
import { Dropdown } from "react-bootstrap";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import SettingsLayout from "./SettingsLayout";
import FailureToast from "../../ui/FailureToast";
import SuccessToast from "../../ui/SuccessToast";
import { MdCancel, MdCheckCircle } from "react-icons/md";

const MySignature = () => {
  const authContext = useAuthentication();
  const [isPOpupActive, setIsPopupActive]: [boolean, Function] =
    useState(false);

  const [signatureList, setSignatureList]: [[], Function] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showFailureToast, setShowFailureToast] = useState<boolean>(false);
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");

  const getUserSignature = async () => {
    try {
      let response = await authContext?.handleGetUserSinature();

      if (response?.success) {
        setSignatureList(response?.sign);
      }
    } catch (error) {}
  };

  const handleDeleteSignature = async (url: any) => {
    try {
      let response = await authContext?.handleDeleteUserSignature({
        signatures: [url],
      });
      if (response) {
        getUserSignature();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserSignature();
  }, []);

  return (
    <SettingsLayout>
      <>
        <div className="pt-4">
          <div className=" d-flex justify-content-between align-items-center">
            <h6 className=" fw-semibold text-black"></h6>
            <div className="p-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="new-contact-btn create-template contract-css ms-lg-3"
                  id="dropdown-basic"
                  onClick={() => setIsPopupActive(true)}
                >
                  Add Signature
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </div>

          <div className=" row">
            {signatureList?.length === 0 ? (
              <div className="text-center">No data found</div>
            ) : (
              signatureList?.map((list: any, index: any) => {
                return (
                  <Fragment key={index}>
                    {list?.signatures?.map((el: any, index: any) => {
                      return (
                        <Fragment key={index}>
                          <div className="col-md-3 col-12 p-0">
                            <div className=" p-3 varibles_card me-4 mt-4">
                              <h6 className="Varible_name fw-semibold pb-3 signature_name">
                                Signature Name
                              </h6>
                              <div className="d-flex justify-content-between align-items-center pb-3">
                                <h6 className="Varible_name  ">
                                  <span className="signature_type">
                                    {list?.type}
                                  </span>
                                </h6>
                              </div>
                              <div className=" d-flex justify-content-center align-items-center signImage ">
                                <img src={el} alt="list" />
                              </div>
                              <div className="d-flex align-items-center mt-3 ">
                                <h6
                                  className="signature_edit_remove_btn"
                                  onClick={() => handleDeleteSignature(el)}
                                >
                                  Remove
                                </h6>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })
            )}
          </div>

          {isPOpupActive && (
            <SignaturePopup
              show={isPOpupActive}
              onHide={() => setIsPopupActive(false)}
              handleSign={() => {}}
              setInitialName={() => {}}
              initialName={""}
              getUserSignature={getUserSignature}
              setBodyMessage={setBodyMessage}
              setShowSuccessToast={setShowSuccessToast}
              setShowFailureToast={setShowFailureToast}
            />
          )}
          {showSuccessToast && (
            <SuccessToast
              icon={
                <MdCheckCircle
                  className="text-success"
                  style={{ fontSize: "64px" }}
                />
              }
              message={bodyMessage}
              title={"Success!"}
              show={showSuccessToast}
              close={() => {
                setShowSuccessToast(false);
              }}
            />
          )}

          {showFailureToast && (
            <FailureToast
              icon={
                <MdCancel
                  className="text-danger"
                  style={{ fontSize: "64px" }}
                />
              }
              message={bodyMessage}
              title={"Failure!"}
              show={showFailureToast}
              close={() => {
                setShowFailureToast(false);
              }}
            />
          )}
        </div>
      </>
    </SettingsLayout>
  );
};
export default MySignature;
