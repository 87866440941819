import AdminHeader from "../../../layouts/AdminHeader";
import ListAdminMembers from "./ListAdminMembers";
import { ListAdminMembersProvider } from "./ListAdminMembersProvider";

const AdminMembersPage = () => {
  return (
    <AdminHeader>
      <div className="px-3">
        <ListAdminMembersProvider>
          <ListAdminMembers />
        </ListAdminMembersProvider>
      </div>
    </AdminHeader>
  );
};

export default AdminMembersPage;
