export const customStyles = {
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#d5d5fb"
        : isFocused
        ? "#d5d5fb"
        : undefined,
      color: isSelected ? "#000" : isFocused ? "#000" : undefined,
    };
  },
};
