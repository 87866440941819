import { Formik } from "formik";
import { useState } from "react";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layouts/AuthLayout";
import FailureToast from "../../ui/FailureToast";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import SuccessToast from "../../ui/SuccessToast";
import { routes } from "../../utils/routes";
import { useAuthentication } from "./AuthenticationProvider";

const ForgetPasswordPage = () => {
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);

  const context = useAuthentication();
  const navigate = useNavigate();

  const initialValues: { email: string } = { email: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
  });

  const handleSubmit = async (
    _values: any,
    {
      setSubmitting,
      setErrors,
    }: { setSubmitting: Function; setErrors: Function }
  ) => {
    setSubmitting(false);
    const response = await context?.forgetPassword(_values);
    if (response?.data?.success) {
      setShowSuccessToast(true);
    } else {
      setErrors({ email: response?.data?.error });
    }
  };

  return (
    context && (
      <AuthLayout
        pageTitle="CLM - Sign In"
        title="Trouble logging in"
        subTitle="Enter your email address and we will send you a link to get back into your account"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormikField
                name="email"
                type="email"
                validationSchema={validationSchema}
                label="E-mail address"
                placeholder="Email address"
                errors={errors}
                autoFocus
              />
              <div className="my-3">
                <Link
                  to={routes.signIn}
                  className="text-decoration-none text-primary"
                >
                  <span>Log in with password</span>
                </Link>
              </div>

              <div className=" d-flex justify-content-end align-items-center">
                <FormikSubmitButton
                  isSubmitting={isSubmitting}
                  className="my-3 btn_radius w-100"
                  size="md"
                >
                  <span className="SignText">Send Reset Link</span>
                </FormikSubmitButton>
              </div>
            </form>
          )}
        </Formik>
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message="Reset password instructions sent."
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
              navigate(routes.root);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message="Couldn't able to send email instruction."
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </AuthLayout>
    )
  );
};

export default ForgetPasswordPage;
