import AdminOverallStats from "./AdminOverallStats";
import AdminBarChart from "./AdminSummaryCharts/AdminBarChart";
import AdminPieChart from "./AdminSummaryCharts/AdminPieChart";
import AdminWeeklyTrendChart from "./AdminSummaryCharts/AdminWeeklyTrendChart";
import AdminMonthlyTrendChart from "./AdminSummaryCharts/AdminMonthlyTrendChart";

const AdminAnalyticsSummary = ({ filterData }: { filterData: any }) => {
  return (
    <>
      <div className="analyticsBody px-3 pb-5">
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">Overall Stats</h6>
              <AdminOverallStats filterData={filterData} />
            </div>
          </div>

          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Contract Stage-wise Volume
              </h6>
              <AdminPieChart filterData={filterData} />
            </div>
          </div>
        </div>
        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "505px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Contract Stage-wise Percentage
              </h6>
              <AdminBarChart filterData={filterData} />
            </div>
          </div>
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "470px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title">
                Contract Weekly Trend
              </h6>
              <AdminWeeklyTrendChart filterData={filterData} />
            </div>
          </div>
        </div>

        <div className=" row">
          <div className=" col-lg-6 col-12 mt-4">
            <div
              style={{ minHeight: "470px", background: "#fefefe" }}
              className=" p-3"
            >
              <h6 className=" fw-semibold dashboard_title mb-4">
                Contract Monthly Trend
              </h6>
              <AdminMonthlyTrendChart filterData={filterData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalyticsSummary;
