import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Button, Modal, Nav, NavDropdown, Stack } from "react-bootstrap";
import {
  AiFillCaretRight,
  AiOutlineOrderedList,
  AiOutlineStrikethrough,
} from "react-icons/ai";
import { BiCommentDetail, BiFullscreen, BiTable } from "react-icons/bi";
import { BsCheckLg, BsThreeDotsVertical } from "react-icons/bs";
import { FcDatabase } from "react-icons/fc";
import { HiOutlineHashtag } from "react-icons/hi";
import {
  MdCancel,
  MdCheckCircle,
  MdContentCopy,
  MdContentCut,
  MdContentPaste,
  MdDeleteOutline,
  MdDownload,
  MdDriveFileRenameOutline,
  MdFindReplace,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatUnderlined,
  MdInsertPageBreak,
  MdOutlineBrandingWatermark,
  MdOutlineFormatBold,
  MdOutlineFormatItalic,
  MdOutlineInsertPhoto,
  MdOutlineKeyboardCommandKey,
  MdRedo,
  MdSave,
  MdSelectAll,
  MdSubscript,
  MdSuperscript,
  MdUndo,
} from "react-icons/md";
import { TbFileText } from "react-icons/tb";
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import logo from "../assets/logo12.png";
import DocumentCollaborators from "../components/DocumentCollaborators";
import WaterMarkInput from "../editor/WaterMarkInput";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
import {
  ContractContextInterface,
  // ContractContextInterface,
  ContractProvider,
} from "../modules/Contracts/ContractProvider";
import {
  // TemplateContextInterface,
  useTemplate,
} from "../modules/Templates/TemplateProvider";
import DeleteToast from "../ui/DeleteToast";
import FailureToast from "../ui/FailureToast";
import SuccessToast from "../ui/SuccessToast";
import { slugify } from "../utils";
import { routes } from "../utils/routes";
import PublishDocumentPopup from "./PublishDocumentPopup";

import { asBlob } from "html-docx-js-typescript";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FaClockRotateLeft } from "react-icons/fa6";
import { RiHistoryFill } from "react-icons/ri";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Editor, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import VariablesModal from "../components/variables/VariablesModal";
import { useEditor } from "../editor/EditorProvider";
import { insertImage } from "../editor/Image";
import { toggleBlock } from "../editor/components/ToolbarButton";
import { toggleMark } from "../editor/components/ToolbarMark";
import { StatusEnum } from "../interfaces/StatusEnum";
import { Collaborator } from "../lib/Collaborator";
import RejectPopup from "../modules/Contracts/RejectPopup";
import VersionRenamePopup from "../modules/VersionHistory/VersionRenamePopup";
import { api } from "../utils/api";
import UpdateDocumentName from "./UpdateDocumentName";
import HierarchyTree from "../modules/Templates/TemplateOverview/hierarchy/HierarchyTree";
import { Text } from "slate";
import { TemplateContextInterface } from "../modules/Admin/Accounts/Templates/TemplateProvider";
// import { insertTable } from "../editor/components/Table/CreateTable";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface NavMenuItemProps {
  name: string;
  label: ReactElement;
  callback?: Function;
  disabled?: boolean;
  keyboardLabel?: ReactElement;
}

export const NavMenuItem = ({
  name,
  callback,
  label,
  disabled,
  keyboardLabel,
}: NavMenuItemProps) => (
  <NavDropdown.Item
    eventKey={slugify(name)}
    disabled={disabled}
    onClick={() => callback && callback()}
    active={false}
  >
    <Stack
      direction="horizontal"
      className="justify-content-between align-items-center"
    >
      <span className={disabled ? "" : "text-dark"}>{label}</span>
      <span className={disabled ? "" : "text-dark"}>{keyboardLabel}</span>
    </Stack>
  </NavDropdown.Item>
);

const NavItem = ({
  title,
  menuItems,
  children,
  disabled,
}: // id,
{
  title: string;
  children?: ReactElement;
  menuItems?: NavMenuItemProps[];
  disabled?: boolean;
  // id?: string;
}) => (
  <NavDropdown
    title={title}
    id={`nav-${slugify(title)}`}
    // id={id}
    className="bg-white text-dark"
    renderMenuOnMount
    disabled={disabled}
  >
    {menuItems?.map(
      ({ name, label, callback, keyboardLabel }: NavMenuItemProps) => (
        <NavMenuItem
          name={name}
          label={label}
          callback={callback}
          key={slugify(name)}
          keyboardLabel={keyboardLabel}
        />
      )
    )}
    {children}
  </NavDropdown>
);

const DocumentHeader = ({
  context,
  contents,
}: {
  context: TemplateContextInterface | ContractContextInterface | any;
  contents: any;
}) => {
  const { id } = useParams();
  const location = useLocation();
  const editorContext = useEditor();
  const navigate = useNavigate();
  const editor: any = useSlate();
  const templateContext = useTemplate();
  const authContext = useAuthentication();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [showPublishPopup, setShowPublishpopup]: [boolean, Function] =
    useState(false);
  const [department, setDepartment] = useState<any>(
    context?.data && context?.data?.department
  );
  const [isOwner, setIsOwner]: [any, Function] = useState();

  const [category, setCategory] = useState<any>(
    context?.data && context?.data?.category
  );
  const [showWaterMarkModal, setShowWaterMarkModal]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [isDepartmentAdded, setIsDepartmentAdded]: [boolean, Function] =
    useState(false);
  const [showVariablesModal, setVariablesModal] = useState<boolean>(false);
  const [onAddDepartmentActive, setOnAddDepartmentActive]: [boolean, Function] =
    useState(false);
  const [expiryDate, setExpiryDate] = useState(null);

  const [selectedDate, setSelectedDate]: [boolean, Function] = useState(false);
  const [printPage, setPrintPage] = useState<any | null>();
  const [unPublishDocument, setUnPublishedDocument]: [boolean, Function] =
    useState(false);
  const [exitToast, setOnExitToast]: [boolean, Function] = useState(false);
  const [isHierarchy, setIsHierarchy]: [boolean, Function] = useState(false);

  const [updatedVersionNamePopup, setUpdatedVersionNamePopup]: [
    boolean,
    Function
  ] = useState(false);

  const [documentName, setDcumentName]: [string | undefined, Function] =
    useState(context?.data?.name);

  const [rejectApproval, setRejectApproval]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    if (!!context?.data && context?.data?.category) {
      setIsDepartmentAdded(true);
    }
  }, [context?.data?.category]);

  useEffect(() => {
    setElem(document.getElementById("fullScreen"));
    setPrintPage(document.getElementById("printPage")?.innerHTML);
  }, []);

  const [elem, setElem] = useState<any>();
  const [updateName, setUpdateName]: [boolean, Function] = useState(false);
  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];
      if (location?.pathname?.includes("contracts")) {
        setRolesUpdate(
          guestRole?.CONTRACTS?.find((el: any) => el === "UPDATE")
        );
      } else {
        setRolesUpdate(
          guestRole?.TEMPLATES?.find((el: any) => el === "UPDATE")
        );
      }
    }
  }, [authContext?.rolesAndAccess]);

  const onUpdateDocument = async (type: string) => {
    try {
      setLoader(true);

      let requestBody = {
        status:
          type !== "update document"
            ? StatusEnum?.published
            : StatusEnum?.draft,
        name: !!context?.data && context?.data?.name,
        category: category ? category : "",
        department: department ? department : "",
        expiry_date: selectedDate
          ? moment(expiryDate).format("YYYY-MM-DD")
          : "",
      };

      const response = await context.publishDocument(id, requestBody);

      if (response?.data?.success) {
        setShowPublishpopup(false);
        context?.refresh();
        setLoader(false);
        setShowPublishpopup(false);
        if (!onAddDepartmentActive) {
          setShowDeleteToast(true);
        }
        setBodyMessage("Document published.");
      } else {
        setShowFailureToast(true);
        setBodyMessage("Something went wrong!");
        setLoader(false);
      }
    } catch (error) {}
  };

  const onPublishDocument = async () => {
    try {
      let response = await context.handlePublishDoc(id);

      if (response?.data?.success) {
        navigate("/contracts");
        context?.refresh();
        setShowPublishpopup(false);
        setShowDeleteToast(false);
        setLoader(false);
        setShowSuccessToast(true);
        setBodyMessage("Document published.");
      } else {
        setShowFailureToast(true);
        setLoader(false);
        setBodyMessage("Something went wrong!");
        setShowPublishpopup(false);
      }
    } catch (error) {}
  };

  const onPublishTempDocument = async () => {
    try {
      let requestBody = {
        name: !!context?.data && context?.data?.name,

        status: StatusEnum?.published,
      };
      const response = await context.publishDocument(id, requestBody);

      if (response?.data?.success) {
        navigate("/templates");
        context?.refresh();
        setShowPublishpopup(false);
        setShowDeleteToast(false);
        setLoader(false);
        setShowSuccessToast(true);
        setBodyMessage("Document published.");
      } else {
        setShowFailureToast(true);
        setLoader(false);
        setBodyMessage("Something went wrong!");
        setShowPublishpopup(false);
      }
    } catch (error) {}
  };

  const [commentsLength, setCommentsLength]: [number | undefined, Function] =
    useState();

  const handleComments = async () => {
    try {
      let response = await api.getComments(context?.data?.documentId!, {
        is_resolved: false,
      });
      if (response?.success) {
        setCommentsLength(response?.comments?.length);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleComments();
  }, [context?.data?.documentId]);

  const onUnPublishedDocument = async () => {
    try {
      let requestBody = {
        status: StatusEnum?.draft,
        name: !!context?.data && context?.data?.name,
      };
      const response = await context.publishDocument(id, requestBody);
      if (response?.data?.success) {
        context?.refresh();
        setUnPublishedDocument(false);
        setLoader(false);
        setShowSuccessToast(true);
        setBodyMessage("Document unpublished.");
        navigate("/contracts");
      }
      if (!response?.data?.success) {
        setShowFailureToast(true);
        setLoader(false);
        setBodyMessage("Something went wrong!");
        setUnPublishedDocument(false);
      }
    } catch (error) {}
  };

  const handleCut = () => {
    const { selection } = editor;

    if (selection) {
      const fragment: any = editor.fragment(selection);
      const text = fragment[0]?.children[0].text;
      navigator.clipboard.writeText(text);
      Transforms.delete(editor, {
        at: selection,
      });
    }
  };

  const handleCopy = () => {
    const { selection } = editor;
    if (selection) {
      const text = Editor.string(editor, selection);
      navigator.clipboard.writeText(text);
    }
  };

  const handlePaste = () => {
    ReactEditor.focus(editor);
    navigator.clipboard.readText().then((text) => {
      Transforms.insertText(editor, text, {});
    });
  };

  // let elem = document.getElementById("fullScreen");

  const openFullscreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    document.getElementById("focusButton")?.addEventListener("click", () => {
      document.getElementById("myTextField")?.focus();
    });
  });

  const onSave = () => {
    setShowSuccessToast(true);
    setBodyMessage("Saved");
    editorContext?.setDocContents(editorContext?.editor?.children);
    editorContext?.handleUpdateDocument();
  };

  const printDiv = () => {
    let bool =
      editor?.children?.[0]?.children?.length >= 3
        ? editor?.children?.[0]?.children?.length >= 3
          ? false
          : true
        : editor?.children?.filter((el: any) => el?.children?.[0]?.text === "")
            ?.length < editor?.children?.length
        ? false
        : true;
    if (bool) {
      alert("Empty page");
      return;
    } else {
      var iframe: any = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      var iframeDocument = iframe.contentWindow.document;
      iframeDocument.write(
        "<html><head><style>@media print {@page {margin: 0 !important;size: portrait;}body {margin: 1cm !important;} page-break-before: always;}</style></head><body>"
      );
      iframeDocument.write(printPage);
      iframeDocument.write("</body></html>");
      iframeDocument.close();
      iframe.contentWindow.print();
    }
  };

  const handleGetDocument = async () => {
    try {
      let response = await editorContext?.getDocumentDetails(
        context?.data?.documentId
      );
      if (response) {
        navigate(
          location.pathname.includes("contracts")
            ? generatePath(routes.VersionHistoryContractDeatils, {
                id: id,
                versionId: response?.head_document_version,
                documentId: authContext?.documentId,
              })
            : generatePath(routes.VersionHistoryTemplateDeatils, {
                id: id,
                versionId: response?.head_document_version,
                documentId: authContext?.documentId,
              })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (context?.data) {
      authContext?.setDocumentId(context?.data?.documentId);
    }
  }, [context?.data]);

  useEffect(() => {
    if (context?.data?.collaborators) {
      let ownerDetail = context?.data?.collaborators?.filter(
        (owner: Collaborator) => {
          return owner?.user?.sub === authContext?.currentUser?.sub;
        }
      );
      setIsOwner(ownerDetail);
    }
  }, [context?.data?.collaborators]);

  const reNegotiation = async (type: boolean) => {
    try {
      let requestBody = {
        approve: type,
        message: "string",
        suggest: false,
      };
      let response = await api?.renegotiation(id!, requestBody);
      if (response?.data?.success) {
        if (type === true) {
          setShowSuccessToast(true);
          setBodyMessage("Contract approved.");
          navigate(routes.contracts);
        } else {
          setShowSuccessToast(true);
          setBodyMessage("Negotiation re-initiated.");
          navigate(routes.contracts);
        }
      }
    } catch (error) {}
  };

  const handleReNegotiation = async () => {
    try {
      let response = await api?.reNegtiation(id!);
      if (response?.data?.success) {
        setShowSuccessToast(true);
        setBodyMessage("Contract approved.");
        navigate(routes.contracts);
      } else {
        setShowSuccessToast(true);
        setBodyMessage("Negotiation re-initiated.");
        navigate(routes.contracts);
      }
    } catch (error) {}
  };

  const selectAll = () => {
    ReactEditor.focus(editor);
    Transforms.select(editor, {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    });
    navigator.clipboard.writeText(
      Editor.string(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      })
    );
  };

  const serializeToHtml = (jsonObj: any, isPdf: boolean) => {
    let htmlString = "";
    function parseNode(node: any) {
      switch (node.type) {
        case "paragraph":
          htmlString += `<p style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</p>`;
          break;
        case "heading-one":
          htmlString += `<h1 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h1>`;
          break;
        case "heading-two":
          htmlString += `<h2 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h2>`;
          break;
        case "heading-three":
          htmlString += `<h3 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h3>`;
          break;
        case "heading-four":
          htmlString += `<h4 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h4>`;
          break;
        case "heading-five":
          htmlString += `<h5 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h5>`;
          break;
        case "heading-six":
          htmlString += `<h6 style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</h6>`;
          break;
        case "normal":
          htmlString += `<p style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</p>`;
          break;
        case "list-item":
          htmlString += `<li style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</li>`;
          break;
        case "image":
          htmlString += isPdf
            ? ""
            : `<img src="${node.url}" style="${
                isPdf ? "" : getStyle(node.style)
              }"/>`;
          break;
        case "table":
          htmlString += parseTable(node);
          break;
        case "link":
          htmlString += `<a style="${
            isPdf ? "" : getStyle(node.style)
          }"  href=${node.url}>${parseChildren(node?.children)}</a>`;
          break;
        case "quote":
          htmlString += `<blockquote style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</blockquote>`;
          break;
        case "bulleted-list":
          htmlString += `<ul style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</ul>`;
          break;
        case "numbered-list":
          htmlString += `<ol style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</ol>`;
          break;
        case "span":
          htmlString += `<span style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</span>`;
          break;
        case "colgroup":
          htmlString += `<colgroup style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</colgroup>`;
          break;
        case "col":
          htmlString += `<colgroup style="${
            isPdf ? "" : getStyle(node.style)
          }" />`;
          break;
        case "sup":
          htmlString += `<sup style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</sup>`;
          break;
        case "sub":
          htmlString += `<sub style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</sub>`;
          break;
        case "text":
          let text = node.text;
          if (node.bold) {
            text = `<strong style="${
              isPdf ? "" : getStyle(node.style)
            }">${text}</strong>`;
          }
          if (node.italic) {
            text = `<em style="${
              isPdf ? "" : getStyle(node.style)
            }">${text}</em>`;
          }
          if (text.trim() === "") {
            htmlString += "<br/>";
          } else {
            htmlString += text;
          }
          break;
        case "variable":
          htmlString += `<span style="${isPdf ? "" : getStyle(node?.style)}">${
            node?.variableValue || node?.variableName
          }</span>`;
          break;
        default:
          htmlString += `<${node.type} style="${
            isPdf ? "" : getStyle(node.style)
          }">${parseChildren(node?.children)}</${node.type}>`;
      }
    }

    function getStyle(inlineStyles: Record<string, string>) {
      let cssString = "";
      for (const key in inlineStyles) {
        if (inlineStyles.hasOwnProperty(key)) {
          const kebabCaseKey = key
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .toLowerCase();
          if (isPdf && kebabCaseKey === "font-family") {
            cssString += "";
          } else {
            cssString += `${kebabCaseKey}: ${inlineStyles[key]};\n`;
          }
        }
      }
      return cssString;
    }

    function parseChildren(children: any) {
      let parsedChildren = "";

      if (children) {
        children.forEach((child: any) => {
          switch (child?.type) {
            case undefined:
              parsedChildren += child.text;
              break;
            case "paragraph":
              parsedChildren += `<p style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</p>`;
              break;
            case "heading-one":
              parsedChildren += `<h1 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h1>`;
              break;
            case "heading-two":
              parsedChildren += `<h2 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h2>`;
              break;
            case "heading-three":
              parsedChildren += `<h3 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h3>`;
              break;
            case "heading-four":
              parsedChildren += `<h4 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h4>`;
              break;
            case "heading-five":
              parsedChildren += `<h5 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h5>`;
              break;
            case "heading-six":
              parsedChildren += `<h6 style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</h6>`;
              break;
            case "normal":
              parsedChildren += `<p style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</p>`;
              break;
            case "list-item":
              parsedChildren += `<li style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</li>`;
              break;
            case "image":
              parsedChildren += isPdf
                ? ""
                : `<img src="${child?.url}" style="${
                    isPdf ? "" : getStyle(child?.style)
                  }"/>`;
              break;
            case "span":
              htmlString += `<span style="${
                isPdf ? "" : getStyle(child.style)
              }">${parseChildren(child?.children)}</span>`;
              break;
            case "colgroup":
              htmlString += `<colgroup style="${
                isPdf ? "" : getStyle(child.style)
              }">${parseChildren(child?.children)}</colgroup>`;
              break;
            case "col":
              htmlString += `<colgroup style="${
                isPdf ? "" : getStyle(child.style)
              }" />`;
              break;
            case "table":
              parsedChildren += parseTable(child);
              break;
            case "link":
              parsedChildren += `<a style="${
                isPdf ? "" : getStyle(child?.style)
              }"  href=${child?.url}>${parseChildren(child?.children)}</a>`;
              break;
            case "quote":
              parsedChildren += `<blockquote style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</blockquote>`;
              break;
            case "bulleted-list":
              parsedChildren += `<ul style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</ul>`;
              break;
            case "numbered-list":
              parsedChildren += `<ol style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</ol>`;
              break;
            case "sup":
              parsedChildren += `<sup style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</sup>`;
              break;
            case "sub":
              parsedChildren += `<sub style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</sub>`;
              break;
            case "text":
              let text = child?.text;
              if (child?.bold) {
                text = `<strong style="${
                  isPdf ? "" : getStyle(child?.style)
                }">${text}</strong>`;
              }
              if (child?.italic) {
                text = `<em style="${
                  isPdf ? "" : getStyle(child?.style)
                }">${text}</em>`;
              }
              if (text.trim() === "") {
                parsedChildren += "<br/>";
              } else {
                parsedChildren += text;
              }
              break;
            case "variable":
              parsedChildren += `<span style="${
                isPdf ? "" : getStyle(child?.style)
              }">${child?.variableValue || child?.variableName}</span>`;
              break;
            default:
              parsedChildren += `<${child?.type} style="${
                isPdf ? "" : getStyle(child?.style)
              }">${parseChildren(child?.children)}</${child?.type}>`;
          }
        });
      }
      return parsedChildren;
    }

    function parseTable(table: any) {
      let tableHTML = '<table style="border: 1px solid black;max-width: 90%;">';
      table.children.forEach((row: any) => {
        if (row.children) {
          row.children.forEach((cell: any) => {
            tableHTML += `<tr>`;
            cell.children.forEach((content: any) => {
              if (content.type === "table-cell") {
                if (content.children[0].type === "paragraph") {
                  tableHTML += `<td style="border: 1px solid black; padding: 4px;">`;
                  tableHTML += parseChildren(content.children[0].children);
                  tableHTML += "</td>";
                }
              }
            });
            tableHTML += "</tr>";
          });
        }
      });
      tableHTML += "</table>";
      return tableHTML;
    }

    jsonObj.forEach((node: any) => {
      parseNode(node);
    });

    return htmlString;
  };

  const downloadAsPdf = async () => {
    const htmlContent: any = serializeToHtml(editor?.children, true);
    const content = htmlToPdfmake(htmlContent);
    const docDefinition = { content };
    console.log(docDefinition);
    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.download("fileName" + ".pdf");
  };

  const downloadAsDocx = async () => {
    const htmlContent: any = serializeToHtml(editor?.children, false);
    const docxBuffer: any = await asBlob(htmlContent);
    const docxUrl = URL.createObjectURL(docxBuffer);
    const link = document.createElement("a");
    link.href = docxUrl;
    link.download = `${"fileName"}.docx`;
    link.click();
  };

  const [roleAccess, setRoleAccess]: [boolean, Function] = useState(false);

  useEffect(() => {
    let owner: any = context?.data?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );

    if (owner?.user?.email === authContext?.currentUser?.email) {
      setRoleAccess(true);
    }
  }, [context?.data?.collaborators]);

  const imageIconClick = () => {
    const url = window.prompt("Enter the URL of the image:");

    url && insertImage(editor, url);
  };

  const onPublishClick = () => {
    if (context?.data?.name?.split(",")?.[0]?.includes("Untitled Contract")) {
      setUpdateName(true);
    } else {
      editorContext?.handleUpdateDocument();
      isDepartmentAdded ? setShowDeleteToast(true) : setShowPublishpopup(true);
    }
  };

  const [isShortcutPressed, setIsShortcutPressed] = useState(false);
  const targetDivRef: any = useRef(null);
  // useEffect(() => {
  //   setPrintPage(document.getElementById("printPage")?.innerHTML);
  //   const handleKeyPress = (event: any) => {
  //     if (
  //       (event.metaKey || event.ctrlKey) &&
  //       event.shiftKey &&
  //       event.key.toLowerCase() === "i"
  //     ) {
  //       setIsShortcutPressed(true);
  //       setTimeout(() => {
  //         setIsShortcutPressed(false);
  //       }, 2000);
  //       if (targetDivRef.current) {
  //         targetDivRef.current.focus();
  //       }
  //     }
  //     if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "p") {
  //       event.preventDefault();
  //       printDiv();
  //     }

  //     if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
  //       event.preventDefault();
  //       pageBreak();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key.toLowerCase() === "i"
      ) {
        setIsShortcutPressed(true);
        setTimeout(() => {
          setIsShortcutPressed(false);
        }, 2000);
        if (targetDivRef.current) {
          targetDivRef.current.focus();
        }
      }
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "p") {
        event.preventDefault();

        let bool =
          editor?.children?.[0]?.children?.length >= 3
            ? editor?.children?.[0]?.children?.length >= 3
              ? false
              : true
            : editor?.children?.filter(
                (el: any) => el?.children?.[0]?.text === ""
              )?.length < editor?.children?.length
            ? false
            : true;
        if (bool) {
          alert("Empty page");
          return;
        } else {
          setPrintPage(document.getElementById("printPage")?.innerHTML);
          let b = document.getElementById("printPage")?.innerHTML;
          var iframe: any = document.createElement("iframe");
          iframe.style.display = "none";
          document.body.appendChild(iframe);
          var iframeDocument = iframe.contentWindow.document;
          iframeDocument.write(
            "<html><head><style>@media print {@page {margin: 0 !important;size: portrait;}body {margin: 1cm !important;} page-break-before: always;}</style></head><body>"
          );
          iframeDocument.write(b);
          iframeDocument.write("</body></html>");
          iframeDocument.close();
          iframe.contentWindow.print();
        }
      }

      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        const node: any = Editor.fragment(editor, editor?.selection);
        switch (node[0]?.type) {
          case "table":
            return;
          case "bulleted-list":
            toggleBlock(editor, "bulleted-list");
            pageBreak();
            return;
          case "numbered-list":
            toggleBlock(editor, "numbered-list");
            pageBreak();
            return;
          default:
            pageBreak();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const sectionBreak = () => {
    // const currentFragment: any = Editor.fragment(editor, editor?.selection);
    // if (currentFragment[0].type != "table") {
    ReactEditor.focus(editor);
    Editor.insertBreak(editor);
    // }
  };

  const pageFragment = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    } as any,
    {
      type: "pageBreak",
      children: [{ text: "" }],
    } as any,
    {
      type: "paragraph",
      children: [{ text: "" }],
    } as any,
  ];

  const pageBreak = () => {
    const currentFragment: any = Editor.fragment(editor, editor?.selection);
    if (currentFragment[0].type != "table") {
      ReactEditor.focus(editor);
      Editor.insertSoftBreak(editor);
      Editor.insertFragment(editor, pageFragment);
      Editor.insertSoftBreak(editor);
    }
  };

  const [isViewerAccess, setIsViewerAccess]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    let viewer: any = context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );

    if (viewer?.access === "VIEWER") {
      setIsViewerAccess(true);
    }
  }, [context?.data?.externalUser]);

  const onHandleSuggestion = async () => {
    debugger;
    if (isViewerAccess) {
      try {
        if (!id) return;
        const response = await api.negotiationViewerApprove(id, {
          approve: false,
          message: "string",
          suggest: false,
        });

        if (response) {
          navigate(generatePath(routes.overviewContracts, { id: id }));
          context?.refresh();
        }
      } catch (error) {}
    } else {
      if (context?.data?.status === StatusEnum?.pending_negotitation) {
        try {
          if (!id) return;
          const response = await api.UpdateNegotiationWorkflow(id, {
            approve: true,
            message: "",
            suggest: true,
          });
          if (response) {
            navigate(generatePath(routes.overviewContracts, { id: id }));
            context?.refresh();
          }
        } catch (error) {}
      } else {
        if (!id) return;
        const res = await api.approveContract(id, {
          approve: false,
          message: "string",
          suggest: false,
        });

        if (res?.data?.success) {
          navigate(generatePath(routes.overviewContracts, { id: id }));

          context?.refresh();
        }
        // }
      }
    }
  };

  const onAcceptSuggestion = () => {
    const a = Array.from(
      Editor.nodes(editor, {
        at: [],
        match: (node) =>
          (Text.isText(node) && (node as any).SuggestionDelete === true) ||
          (node as any).SuggestionAdd === true,
      })
    );
    a.map((item: any) => {
      if (Object.keys(item[0]).includes("SuggestionAdd")) {
        const keysWithSuggestion = Object.keys(item[0]).filter((key) =>
          key.includes("suggestion_")
        );
        Transforms.unsetNodes(
          editor,
          [
            keysWithSuggestion[0],
            keysWithSuggestion[1],
            "color",
            "suggestionStikeThrough",
            "SuggestionAdd",
            "SuggestionDelete",
          ],
          {
            at: item[1],
          }
        );
      } else if (Object.keys(item[0]).includes("SuggestionDelete")) {
        Transforms.removeNodes(editor, { at: item[1] });
      }
    });
    navigate(generatePath(routes.overviewContracts, { id: id }));
    context?.refresh();
  };

  const onDeclineSuggestion = () => {
    const a = Array.from(
      Editor.nodes(editor, {
        at: [],
        match: (node) =>
          (Text.isText(node) && (node as any).SuggestionDelete === true) ||
          (node as any).SuggestionAdd === true,
      })
    );
    a.map((item: any) => {
      if (Object.keys(item[0]).includes("SuggestionAdd")) {
        Transforms.removeNodes(editor, { at: item[1] });
      } else if (Object.keys(item[0]).includes("SuggestionDelete")) {
        const keysWithSuggestion = Object.keys(item[0]).filter((key) =>
          key.includes("suggestion_")
        );
        Transforms.unsetNodes(
          editor,
          [
            keysWithSuggestion[0],
            keysWithSuggestion[1],
            "color",
            "suggestionStikeThrough",
            "SuggestionAdd",
            "SuggestionDelete",
          ],
          {
            at: item[1],
          }
        );
      }
    });
    navigate(generatePath(routes.overviewContracts, { id: id }));
    context?.refresh();
  };

  const [suggestionCount, setSuggestionCount] = useState<any>(0);

  const suggestionCountFunction = (data: any) => {
    let count = 0;
    data.forEach((paragraph: any) => {
      paragraph.children.forEach((child: any) => {
        if (child.SuggestionDelete || child.SuggestionAdd) {
          count++;
        }
      });
    });
    setSuggestionCount(count);
  };

  useEffect(() => {
    if (
      editorContext &&
      editorContext.editor &&
      editorContext.editor.children
    ) {
      suggestionCountFunction(editorContext.editor.children);
    }
  }, [editorContext?.editor?.children]);

  return (
    <div id="fullScreen">
      {context && (
        <>
          <div className=" d-lg-none">
            <div
              style={{ background: "#fefefe" }}
              className="p-3 d-flex  align-items-center border-top   flex-wrap"
            >
              <div style={{ minWidth: "90px" }}>
                <Link to={routes.contracts}>
                  <img src={logo} alt="logo" width={80} />
                </Link>
              </div>
              <div className=" d-flex justify-content-between align-items-center flex-grow-1 flex-wrap">
                <div tabIndex={1}>
                  <input
                    id="myTextField"
                    style={{
                      minWidth: "300px",
                    }}
                    className="m-0 d-inline-block px-2 border h6 fw-normal py-1 text-title"
                    value={documentName}
                    onChange={(e: any) => {
                      setDcumentName(e?.target?.value);
                    }}
                    onBlur={() => {
                      context?.updateName(context.data.id, documentName);
                      context?.refresh();
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <div className="dropdownHeader">
                      <span className="ps-2">
                        {" "}
                        <BsThreeDotsVertical fontSize={24} color="#c5d0de" />
                      </span>
                      <div>
                        <a href={"w"}>File</a>
                        <a href={"w"}>Edit</a>
                        <a href={"w"}>View</a>
                        <a href={"w"}>Format</a>
                        <a href={"w"}>Variables</a>
                        <div className="submenu sub1">
                          <a href={"w"}>Long Link 1</a>
                          <div className="sub2">
                            <a href={"w"}>Long Link 3</a>
                            <div className="submenu sub3">
                              <a href={"w"}>Long Link 4</a>
                              <div>
                                <a href={"w"}>Long Link 6</a>
                                <a href={"w"}>Long Link 7</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Dropdown drop="end">
                  <Dropdown.Toggle
                    variant="success"
                    className="Actions_title"
                    id="dropdown-basic"
                  >
                    <BsThreeDotsVertical fontSize={24} color="#c5d0de" />
                  </Dropdown.Toggle>
                  <>
                    <Dropdown.Menu className="create-dropdown-css">
                      <Dropdown.Item className="dropdownHeader">
                        <span className=" fw-semibold">File</span>
                        <div>
                          <a href="#">Link 1</a>
                          <div className="submenu">
                            <a href="#">Link 2</a>
                            <div>QWERT</div>
                          </div>

                          <div className="submenu">
                            <a href="#">Long Link 1</a>
                            <div>
                              <a href="#">Long Link 3</a>
                              <div className="submenu">
                                <a href="#">Long Link 4</a>
                                <div>
                                  <a href="#">Long Link 6</a>
                                  <a href="#">Long Link 7</a>
                                </div>
                              </div>
                              <div className="submenu">
                                <a href="#">Long Link 5</a>
                                <div>
                                  <a href="#">Long Link 8</a>
                                  <div className="submenu">
                                    <a href="#">Long Link 9</a>
                                    <div>
                                      <a href="#">Long Link 10</a>
                                      <a href="#">Long Link 11</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <a href="#">Long Link 2</a>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                </Dropdown> */}
                  </div>
                  <div className="me-3 position-relative">
                    <FaClockRotateLeft
                      fontSize={24}
                      color="#c5d0de"
                      data-tooltip-id="infoToolTip"
                    />
                    <ReactTooltip id="infoToolTip" place="bottom">
                      Last edited on &nbsp;
                      {moment(contents?.updatedAt?.toString()).format(
                        "Do MMM YYYY hh:mm A"
                      )}
                    </ReactTooltip>
                  </div>
                  <div className="me-3">
                    <BiCommentDetail fontSize={24} color="#c5d0de" />
                  </div>
                  <div className="addCollab d-flex justify-content-center align-items-center d-lg-none">
                    <DocumentCollaborators context={context} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-block ">
            <header className="d-flex bg-white border-top px-3 py-2 justify-content-end">
              <div className=" d-flex align-items-center flex-grow-1 ">
                <div style={{ minWidth: "90px" }}>
                  <Link to={routes.contracts}>
                    <img src={logo} alt="logo" width={80} />
                  </Link>
                </div>

                <div className="mx-2">
                  <div tabIndex={1}>
                    <input
                      tabIndex={0}
                      ref={targetDivRef}
                      id="myTextField"
                      style={{
                        minWidth: "500px",
                        backgroundColor: isShortcutPressed ? "yellow" : "white",
                      }}
                      className="m-0 d-inline-block px-2 border h6 fw-normal py-1 text-title"
                      value={documentName}
                      onChange={(e: any) => {
                        setDcumentName(e?.target?.value);
                      }}
                      onBlur={async () => {
                        try {
                          let response = await context?.updateName(
                            context.data.id,
                            documentName
                          );
                          if (response?.data?.success) {
                            context?.refresh();
                          } else {
                            setDcumentName(context?.data?.name);
                            setShowFailureToast(true);
                            setBodyMessage(response?.error?.[0]);
                          }
                        } catch (error) {}
                      }}
                    />
                  </div>

                  <div className=" d-flex align-items-center">
                    <Nav variant="pills" className=" d-flex p-0 m-0 flex-wrap">
                      <NavItem title="File">
                        <>
                          {/* <NavMenuItem
                            callback={() => {}}
                            name={"Rename"}
                            label={
                              <>
                                <button
                                  id="focusButton"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    marginLeft: "-5px",
                                  }}
                                >
                                  <MdDriveFileRenameOutline className="h6 fw-normal m-0 me-1" />{" "}
                                  Rename
                                </button>
                              </>
                            }
                          /> */}
                          <NavMenuItem
                            disabled={true}
                            name={"Move"}
                            label={
                              <>
                                <MdRedo className="h6 fw-normal m-0 me-1" />{" "}
                                Move
                              </>
                            }
                          />
                          <NavMenuItem
                            callback={() => {
                              onSave();
                            }}
                            name={"Save"}
                            label={
                              <>
                                <MdSave className="h6 fw-normal m-0 me-1" />{" "}
                                Save
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ S</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            disabled={true}
                            name={"Download"}
                            label={
                              <>
                                <MdDownload className="h6 fw-normal m-0 me-1" />{" "}
                                Download
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ J</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            callback={() => {
                              editorContext?.setAccessOption(
                                !editorContext?.accessOption
                              );
                            }}
                            name={"Mode"}
                            label={
                              <>
                                <button
                                  id="focusButton"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    marginLeft: "-5px",
                                  }}
                                >
                                  <MdDriveFileRenameOutline className="h6 fw-normal m-0 me-1" />{" "}
                                  ReadOnly
                                </button>
                              </>
                            }
                            keyboardLabel={
                              <>
                                <div className="checkp">
                                  <input
                                    type="checkbox"
                                    checked={!!editorContext?.accessOption}
                                  />
                                </div>
                              </>
                            }
                          />
                          <NavDropdown.Divider />
                          <NavDropdown
                            key={"format-print"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <MdContentPaste className="h6 fw-normal m-0 me-1" />{" "}
                                Export As{" "}
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavMenuItem
                              callback={() => downloadAsPdf()}
                              keyboardLabel={
                                <>
                                  {navigator.userAgent.indexOf("Mac OS X") !=
                                  -1 ? (
                                    <MdOutlineKeyboardCommandKey />
                                  ) : (
                                    "Ctrl"
                                  )}

                                  <span>+ P</span>
                                </>
                              }
                              name={"PDF"}
                              label={
                                <>
                                  <MdFormatListBulleted className="h6 fw-normal m-0 me-1" />{" "}
                                  PDF
                                </>
                              }
                            />

                            <NavMenuItem
                              disabled={true}
                              name={"XML"}
                              label={
                                <>
                                  <AiOutlineOrderedList className="h6 fw-normal m-0 me-1" />{" "}
                                  XML
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => downloadAsDocx()}
                              name={"DOCX"}
                              label={
                                <>
                                  <AiOutlineOrderedList className="h6 fw-normal m-0 me-1" />{" "}
                                  DOCX
                                </>
                              }
                            />
                          </NavDropdown>

                          <NavDropdown.Divider />
                          <NavDropdown
                            key={"format-version-history"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <RiHistoryFill className="h6 fw-normal m-0 me-1" />{" "}
                                Version History
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavDropdown.Item eventKey="1">
                              <Stack
                                direction="horizontal"
                                className="justify-content-between align-items-center"
                              >
                                <span
                                  className={"text-dark"}
                                  onClick={() => {
                                    setUpdatedVersionNamePopup(true);
                                  }}
                                >
                                  {"Name current version"}
                                </span>
                                <span className={"text-dark"}>{""}</span>
                              </Stack>
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              eventKey="2"
                              onClick={handleGetDocument}
                            >
                              <Stack
                                direction="horizontal"
                                className="justify-content-between align-items-center"
                              >
                                <span className={"text-dark"}>
                                  {"See Version History"}
                                </span>
                                <span className={"text-dark"}>{""}</span>
                              </Stack>
                            </NavDropdown.Item>
                          </NavDropdown>

                          {isOwner?.[0]?.access === "OWNER" && (
                            <>
                              <NavMenuItem
                                callback={() => {
                                  setOnAddDepartmentActive(true);
                                  setShowPublishpopup(true);
                                }}
                                name={"Update documents"}
                                label={
                                  <>
                                    <MdSave className="h6 fw-normal m-0 me-1" />{" "}
                                    Update documents
                                  </>
                                }
                              />
                            </>
                          )}
                        </>
                      </NavItem>
                      <NavItem title="Edit">
                        <>
                          <NavMenuItem
                            callback={() => {
                              editorContext?.editor?.undo();
                            }}
                            name={"Undo"}
                            label={
                              <>
                                <MdUndo className="h6 fw-normal m-0 me-1" />{" "}
                                Undo
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ Z</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            callback={() => {
                              editorContext?.editor?.redo();
                            }}
                            name={"Redo"}
                            label={
                              <>
                                <MdRedo className="h6 fw-normal m-0 me-1" />{" "}
                                Redo
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ Y</span>
                              </>
                            }
                          />
                          <NavDropdown.Divider />
                          <NavMenuItem
                            // disabled={selection ? false : true}
                            callback={() => {
                              handleCut();
                            }}
                            name={"Cut"}
                            label={
                              <>
                                <MdContentCut className="h6 fw-normal m-0 me-1" />{" "}
                                Cut
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ X</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            callback={() => {
                              handleCopy();
                            }}
                            // disabled={selection ? false : true}
                            name={"Copy"}
                            label={
                              <>
                                <MdContentCopy className="h6 fw-normal m-0 me-1" />{" "}
                                Copy
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ C</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            // disabled={selection ? false : true}
                            callback={() => {
                              handlePaste();
                            }}
                            name={"Paste"}
                            label={
                              <>
                                <MdContentPaste className="h6 fw-normal m-0 me-1" />{" "}
                                Paste
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ V</span>
                              </>
                            }
                          />
                          <NavDropdown.Divider />
                          <NavMenuItem
                            callback={() => {
                              selectAll();
                            }}
                            // disabled={true}
                            name={"Select All"}
                            label={
                              <>
                                <MdSelectAll className="h6 fw-normal m-0 me-1" />{" "}
                                Select All
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}

                                <span>+ A</span>
                              </>
                            }
                          />
                          <NavMenuItem
                            name={"Delete"}
                            callback={() => {
                              handleCut();
                            }}
                            label={
                              <>
                                <MdDeleteOutline className="h6 fw-normal m-0 me-1" />
                                Delete
                              </>
                            }
                          />
                          <NavDropdown.Divider />
                          <NavMenuItem
                            callback={() =>
                              editorContext?.showFindReplacePopup(
                                !editorContext?.findReplacePopup
                              )
                            }
                            // disabled={true}
                            name={"Find and Replace"}
                            label={
                              <>
                                <MdFindReplace className="h6 fw-normal m-0 me-1" />
                                Find and Replace
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}
                                <span> + SHIFT </span>
                                <span>+ V</span>
                              </>
                            }
                          />
                        </>
                      </NavItem>
                      <NavItem title="View">
                        <>
                          {/* <NavMenuItem
                        disabled={true}
                        name={"Mode"}
                        label={
                          <>
                            <RiPencilLine className="h6 fw-normal m-0 me-1" />{" "}
                            Mode
                          </>
                        }
                        keyboardLabel={
                          <>
                            {navigator.userAgent.indexOf("Mac OS X") != -1 ? (
                              <MdOutlineKeyboardCommandKey />
                            ) : (
                              "Ctrl"
                            )}
                            <span>+ M</span>
                          </>
                        }
                      />
                      <NavDropdown.Divider /> */}
                          <NavMenuItem
                            callback={() => printDiv()}
                            name={"Show print layout"}
                            label={
                              <>
                                <BsCheckLg className="h6 fw-normal m-0 me-1" />{" "}
                                Show print layout
                              </>
                            }
                            keyboardLabel={
                              <>
                                {navigator.userAgent.indexOf("Mac OS X") !=
                                -1 ? (
                                  <MdOutlineKeyboardCommandKey />
                                ) : (
                                  "Ctrl"
                                )}
                                <span>+ P</span>
                              </>
                            }
                          />

                          <NavMenuItem
                            callback={() => {
                              context.showToolbar
                                ? context.setShowToolbar(false)
                                : context.setShowToolbar(true);
                            }}
                            name={"Show Toolbar"}
                            label={
                              <>
                                <BsCheckLg className="h6 fw-normal m-0 me-1" />
                                {context.showToolbar
                                  ? " Hide Toolbar"
                                  : " Show Toolbar"}
                              </>
                            }
                          />
                          <NavDropdown.Divider />

                          <NavMenuItem
                            callback={() => openFullscreen()}
                            name={"Full Screen"}
                            label={
                              <>
                                <BiFullscreen className="h6 fw-normal m-0 me-1" />{" "}
                                Full Screen
                              </>
                            }
                          />
                        </>
                      </NavItem>
                      <NavItem title="Insert">
                        <>
                          <NavMenuItem
                            // callback={() => insertTable(editor)}
                            callback={() =>
                              editor.insertTable({ row: 3, col: 3 })
                            }
                            name={"Insert Table"}
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <BiTable className=" me-1 h6 mt-1 " />
                                  Table
                                </div>
                              </div>
                            }
                          />

                          <NavMenuItem
                            callback={() => imageIconClick()}
                            name={"Insert Image"}
                            label={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <MdOutlineInsertPhoto
                                    className=" fw-normal m-0 me-1"
                                    fontSize={18}
                                  />
                                  Insert Image
                                </div>
                              </div>
                            }
                          />

                          <NavMenuItem
                            callback={() => setShowWaterMarkModal(true)}
                            name={"Insert WaterMark"}
                            label={
                              <>
                                <MdOutlineBrandingWatermark className=" me-1 h6" />{" "}
                                Insert WaterMark
                              </>
                            }
                          />

                          <NavMenuItem
                            callback={() =>
                              editorContext?.setShowHeaderEditingOption(true) &
                              editorContext?.setShowFooterEditingOption(true)
                            }
                            name={"Insert Header"}
                            label={
                              <>
                                <MdOutlineBrandingWatermark className=" me-1 h6" />{" "}
                                Insert Header & Footer
                              </>
                            }
                          />

                          <NavDropdown
                            key={"Breaks"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <MdInsertPageBreak className="h6 fw-normal m-0 me-1" />{" "}
                                Breaks{" "}
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavMenuItem
                              callback={() => pageBreak()}
                              name={"Page Break"}
                              label={
                                <>
                                  <MdInsertPageBreak className="h6 fw-normal m-0 me-1" />{" "}
                                  Page Break
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => sectionBreak()}
                              name={"Section Break (Continuous)"}
                              label={
                                <>
                                  <MdInsertPageBreak className="h6 fw-normal m-0 me-1" />{" "}
                                  Section Break (Continuous)
                                </>
                              }
                            />
                            <NavMenuItem
                              callback={() => pageBreak()}
                              name={"Section Break (Next Page)"}
                              label={
                                <>
                                  <MdInsertPageBreak className="h6 fw-normal m-0 me-1" />{" "}
                                  Section Break (Next Page)
                                </>
                              }
                            />
                          </NavDropdown>
                        </>
                      </NavItem>
                      <NavItem title="Format">
                        <>
                          <NavDropdown
                            key={"format-text"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <TbFileText className="h6 fw-normal m-0 me-1" />
                                Text{" "}
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "bold");
                              }}
                              name={"Bold"}
                              label={
                                <>
                                  {/* <MarkButton
                                    format="bold"
                                    icon={<MdOutlineFormatBold size={24} />}
                                  /> */}
                                  <MdOutlineFormatBold className="h6 fw-normal m-0 me-1" />
                                  Bold
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "italic");
                              }}
                              name={"Italic"}
                              label={
                                <>
                                  <MdOutlineFormatItalic className="h6 fw-normal m-0 me-1" />
                                  Italic
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "underline");
                              }}
                              name={"Underline"}
                              label={
                                <>
                                  <MdFormatUnderlined className="h6 fw-normal m-0 me-1" />
                                  Underline
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "strikeThrough");
                              }}
                              name={"Strike-Through"}
                              label={
                                <>
                                  <AiOutlineStrikethrough className="h6 fw-normal m-0 me-1" />
                                  Strike-Through
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "superscript");
                              }}
                              name={"superscript"}
                              label={
                                <>
                                  <MdSuperscript className="h6 fw-normal m-0 me-1" />
                                  Superscript
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleMark(editor, "subscript");
                              }}
                              name={"Subscript"}
                              label={
                                <>
                                  <MdSubscript className="h6 fw-normal m-0 me-1" />
                                  Subscript
                                </>
                              }
                            />
                          </NavDropdown>

                          {/* <NavDropdown
                            key={"format-indent"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <BsTextIndentLeft className="h6 fw-normal m-0 me-1" />{" "}
                                Indent{" "}
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavMenuItem
                              name={"Indent"}
                              label={
                                <>
                                  <BsTextIndentLeft className="h6 fw-normal m-0 me-1" />{" "}
                                  Indent
                                </>
                              }
                            />

                            <NavMenuItem
                              name={"Outdent"}
                              label={
                                <>
                                  <BsTextIndentRight className="h6 fw-normal m-0 me-1" />{" "}
                                  Outdent
                                </>
                              }
                            />
                          </NavDropdown> */}

                          <NavDropdown
                            key={"format-bullet-numbering"}
                            id={`dropdown-button-drop-end`}
                            drop={"end"}
                            title={
                              <>
                                <MdFormatListNumbered className="h6 fw-normal m-0 me-1" />{" "}
                                Bullets and numbering{" "}
                                <AiFillCaretRight
                                  color="#c5d0de"
                                  className="h6 fw-normal m-0 me-1 float-end"
                                />
                              </>
                            }
                          >
                            <NavMenuItem
                              callback={() => {
                                toggleBlock(editor, "bulleted-list");
                              }}
                              name={"ELEMENT_UL"}
                              label={
                                <>
                                  <MdFormatListBulleted className="h6 fw-normal m-0 me-1" />{" "}
                                  Bullet List
                                </>
                              }
                            />

                            <NavMenuItem
                              callback={() => {
                                toggleBlock(editor, "numbered-list");
                              }}
                              name={"Outdent"}
                              label={
                                <>
                                  <AiOutlineOrderedList className="h6 fw-normal m-0 me-1" />{" "}
                                  Ordered List
                                </>
                              }
                            />
                          </NavDropdown>

                          {/* <NavMenuItem
                            disabled={true}
                            name={"line spacing"}
                            label={
                              <>
                                <MdFormatLineSpacing className="h6 fw-normal m-0 me-1" />{" "}
                                line spacing
                              </>
                            }
                          /> */}
                          <NavDropdown.Divider />
                          <NavMenuItem
                            disabled={true}
                            name={"Header and Footer"}
                            label={
                              <>
                                <FcDatabase className="h6 fw-normal m-0 me-1" />{" "}
                                Header and Footer
                              </>
                            }
                          />
                          <NavMenuItem
                            disabled={true}
                            name={"Page Numbers"}
                            label={
                              <>
                                <HiOutlineHashtag className="h6 fw-normal m-0 me-1" />{" "}
                                Page Numbers
                              </>
                            }
                          />
                        </>
                      </NavItem>
                      <VariablesModal
                        show={showVariablesModal}
                        setShow={setVariablesModal}
                      />
                      <a
                        className="nav-link icon-fa"
                        onClick={() => setVariablesModal(true)}
                      >
                        Variables
                      </a>
                      <a
                        className="nav-link icon-fa"
                        onClick={() => setIsHierarchy(true)}
                        // href={generatePath(routes.editContractHierarchy, {
                        //   id: id,
                        // })}
                      >
                        Hierarchy
                      </a>
                    </Nav>
                    {/* <a
                      className="text-dark small fw-bold ms-3 mt-1 mb-1 d-none d-xl-block "
                      style={{ cursor: "default" }}
                    >
                      Last edited on &nbsp;
                      {moment(contents?.updatedAt?.toString()).format(
                        "Do MMM YYYY hh:mm A"
                      )}
                    </a> */}
                  </div>
                </div>
              </div>
              <div className=" d-flex align-items-center">
                <div className="me-3 position-relative">
                  <FaClockRotateLeft
                    fontSize={24}
                    color="#c5d0de"
                    data-tooltip-id="infoToolTip"
                  />
                  <ReactTooltip id="infoToolTip" place="bottom">
                    Last edited on &nbsp;
                    {moment(contents?.updatedAt?.toString()).format(
                      "Do MMM YYYY hh:mm A"
                    )}
                  </ReactTooltip>
                </div>
                {isOwner?.[0]?.access === "OWNER" &&
                  context?.data?.status === StatusEnum?.draft && (
                    <div
                      className="add_coll_doc_header d-none d-lg-block"
                      style={{ cursor: "pointer" }}
                    >
                      <DocumentCollaborators context={context} />
                      {/* <Dropdown>
                <Dropdown.Toggle
                  variant="default"
                  className="new-contact-btn create-template contract-css ms-lg-3 mt-2"
                  id="dropdown-basic"
                  style={{ border: "none" }}
                >
                  <BiDotsVertical color="gray" fontSize={20} />
                </Dropdown.Toggle>

                <Dropdown.Menu className="create-dropdown-css">
                  <Dropdown.Item
                    className="sort-by"
                    onClick={() => {
                      setOnAddDepartmentActive(true);
                      setShowPublishpopup(true);
                    }}
                  >
                    Update Document
                  </Dropdown.Item>
                  <Dropdown.Item className="sort-by">
                    <DocumentCollaborators context={context} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
                    </div>
                  )}
              </div>

              {/* publish document */}
              {/* <Stack
            direction="horizontal"
            className="align-items-center px-2 mx-1"
          >
            <HiClipboardDocumentList
              className="icon-fa"
              fontSize={24}
              color="#b1b1b1"
              onClick={() => {
                setOnAddDepartmentActive(true);
                setShowPublishpopup(true);
              }}
              id="Update Document"
            />
            <ReactTooltip
              anchorId="Update Document"
              place="top"
              content="Update Document"
            />
          </Stack> */}

              {isOwner?.[0]?.access !== "OWNER" && exitToast && (
                <DeleteToast
                  onCross={() => {}}
                  onSubmit={() => {
                    onHandleSuggestion();
                  }}
                  onClose={() => setOnExitToast(false)}
                  title={
                    "Please select “continue” to confirm your suggestions. The contract owner will be notified to review the changes."
                  }
                  closeText="Cancel"
                  submitText="Continue"
                  loader={loader}
                />
              )}

              {isOwner?.[0]?.access === "OWNER" &&
                exitToast &&
                suggestionCount > 0 && (
                  <DeleteToast
                    onCross={() => {}}
                    onReject={() => {
                      onDeclineSuggestion();
                    }}
                    onSubmit={() => {
                      onAcceptSuggestion();
                    }}
                    onClose={() => setOnExitToast(false)}
                    title={"Unsolved Suggestions"}
                    subTitle={`You have ${suggestionCount} unsolved suggestions. Please resolve or reject all open suggestions before proceeding.`}
                    closeText="Reject All"
                    submitText="Resolve All"
                    showThirdButton={true}
                    type={"suggestion"}
                    showThirdButtonText={"Review Again"}
                    loader={loader}
                  />
                )}

              {rejectApproval && (
                <RejectPopup
                  show={rejectApproval}
                  onHide={() => setRejectApproval(false)}
                  isApprovedActive={"Reject"}
                  setShowSuccessToastWorkFlow={() => {}}
                  setWorkFlowBodyMessage={() => {}}
                  setWorkFlowShowFailureToast={() => {}}
                  setIsApproved={() => {}}
                />
              )}
              {location?.search === "?suggestion=true" &&
                suggestionCount > 0 && (
                  <>
                    <Stack
                      direction="horizontal"
                      className="align-items-center"
                      onClick={() => setOnExitToast(true)}
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                      >
                        Confirm
                      </Button>
                    </Stack>
                  </>
                )}

              {location?.search === "?suggestion=true" &&
                suggestionCount === 0 && (
                  <>
                    <Stack
                      direction="horizontal"
                      className="align-items-center"
                      onClick={() =>
                        navigate(
                          generatePath(routes.overviewContracts, { id: id })
                        )
                      }
                    >
                      <Button
                        variant="danger"
                        size="sm"
                        className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                      >
                        Exit
                      </Button>
                    </Stack>
                  </>
                )}

              {context?.data?.status ===
                StatusEnum?.pending_negotitation_approval && (
                <>
                  <Stack direction="horizontal" className="align-items-center">
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                      onClick={() => handleReNegotiation()}
                    >
                      Re-negotiate
                    </Button>
                  </Stack>
                  <Stack direction="horizontal" className="align-items-center">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                      onClick={() => reNegotiation(true)}
                    >
                      Approve
                    </Button>
                  </Stack>
                </>
              )}

              {/* <DocumentCollaborators context={context} /> */}
              {isOwner?.[0]?.access === "OWNER" &&
                context?.data?.status === "DRAFT" && (
                  <>
                    {rolesUpdate === "UPDATE" && (
                      <>
                        <div className=" d-flex align-items-center">
                          <Button
                            variant="primary"
                            size="sm"
                            className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                            // onClick={() => {
                            //   isDepartmentAdded
                            //     ? setShowDeleteToast(true)
                            //     : setShowPublishpopup(true);
                            //     editorContext?.handleUpdateDocument
                            // }}
                            disabled={
                              false
                              // editor?.children?.[0]?.children?.length >= 3
                              //   ? editor?.children?.[0]?.children?.length >= 3
                              //     ? false
                              //     : true
                              //   : editor?.children?.filter(
                              //       (el: any) => el?.children?.[0]?.text === ""
                              //     )?.length < editor?.children?.length
                              //   ? false
                              //   : true
                            }
                            onClick={() => {
                              onPublishClick();
                            }}
                          >
                            Publish
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}

              {isOwner?.[0]?.access === "OWNER" &&
                context?.data?.status === StatusEnum?.published && (
                  <>
                    {roleAccess && (
                      <>
                        <Stack
                          direction="horizontal"
                          className="align-items-center"
                        >
                          <Button
                            variant="primary"
                            size="sm"
                            className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                            onClick={() => {
                              setUnPublishedDocument(true);
                            }}
                          >
                            Unpublish
                          </Button>
                        </Stack>
                      </>
                    )}
                  </>
                )}

              {unPublishDocument && (
                <DeleteToast
                  onCross={() => {}}
                  onSubmit={() => {
                    onUnPublishedDocument();
                  }}
                  onClose={() => setUnPublishedDocument(false)}
                  title="Do you want to unpublish this document?"
                  closeText="No"
                  submitText="Yes"
                  loader={loader}
                />
              )}

              {showDeleteToast && (
                <DeleteToast
                  onCross={() => {}}
                  onSubmit={() => {
                    context?.isTemplate
                      ? onPublishTempDocument()
                      : onPublishDocument();

                    setShowPublishpopup(false);
                  }}
                  onClose={() => setShowDeleteToast(false)}
                  title={
                    commentsLength !== 0
                      ? "There are unresolved comments are you sure you want to publish?"
                      : "Do you want to publish this document?"
                  }
                  subTitle={
                    location?.pathname?.includes("templates") &&
                    context?.data?.workflow_status === undefined
                      ? "(The workflow won't work post-publish)"
                      : ""
                  }
                  closeText="No"
                  submitText="Yes"
                  loader={loader}
                />
              )}

              {showSuccessToast && (
                <SuccessToast
                  icon={
                    <MdCheckCircle
                      className="text-success"
                      style={{ fontSize: "64px" }}
                    />
                  }
                  message={bodyMessage}
                  title={"Success!"}
                  show={showSuccessToast}
                  close={() => {
                    setShowSuccessToast(false);
                  }}
                />
              )}
              {showFailureToast && (
                <FailureToast
                  icon={
                    <MdCancel
                      className="text-danger"
                      style={{ fontSize: "64px" }}
                    />
                  }
                  message={bodyMessage}
                  title={"Failure!"}
                  show={showFailureToast}
                  close={() => {
                    setShowFailureToast(false);
                  }}
                />
              )}
            </header>
          </div>
        </>
      )}

      {showPublishPopup && (
        <PublishDocumentPopup
          show={showPublishPopup}
          onHide={() => {
            setShowPublishpopup(false);
            templateContext?.refresh();
          }}
          setDepartment={setDepartment}
          setCategory={setCategory}
          department={department}
          category={category}
          onPublishDocument={onUpdateDocument}
          loader={loader}
          setExpiryDate={setExpiryDate}
          expiryDate={expiryDate}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      )}
      {/* {updateName && (
        <UpdateName
          show={updateName}
          onHide={() => setUpdateName(false)}
          documentName={context?.data?.name!}
          context={context}
          setShowSuccessToast={setShowSuccessToast}
          setBodyMessage={setBodyMessage}
          documentId={context?.data?.documentId!}
        />
      )} */}
      {
        <WaterMarkInput
          showWaterMarkModal={showWaterMarkModal}
          setShowWaterMarkModal={setShowWaterMarkModal}
          context={context}
        />
      }

      {updateName && (
        <UpdateDocumentName
          show={updateName}
          context={context}
          onHide={() => setUpdateName(false)}
          setDcumentName={setDcumentName}
        />
      )}

      {updatedVersionNamePopup && (
        <VersionRenamePopup
          show={updatedVersionNamePopup}
          onHide={() => setUpdatedVersionNamePopup(false)}
          handleDocument={() => {}}
          setShowSuccessToast={setShowSuccessToast}
          setBodyMessage={setBodyMessage}
          documentId={authContext?.documentId}
          contractDocId={context?.data && context?.data?.documentId}
        />
      )}

      {isHierarchy && (
        <>
          <Hierarchy show={isHierarchy} onHide={() => setIsHierarchy(false)} />
        </>
      )}
    </div>
  );
};

export default DocumentHeader;

const Hierarchy = ({ show, onHide }: { show: boolean; onHide: Function }) => {
  return (
    <>
      {show && (
        <>
          <Modal show={show} onHide={() => onHide} animation size="lg" centered>
            <Modal.Header
              className="modal-header-bottom-hirerchy"
              closeButton
              style={{ cursor: "pointer" }}
              onClick={() => onHide()}
            >
              <div className="pt-3" style={{ cursor: "pointer" }}>
                <h6 className=" fw-semibold modal-title-name">Hierarchy</h6>
              </div>
            </Modal.Header>

            <Modal.Body>
              <ContractProvider>
                <div className="hierarchy ">
                  <div className=" d-flex align-items-center">
                    <HierarchyTree />
                  </div>
                </div>
              </ContractProvider>
            </Modal.Body>

            {/* <Modal.Footer>
              <Button
                className="cancelbtn me-3 "
                size="sm"
                onClick={() => {
                  onHide();
                }}
              >
                Cancel
              </Button>
              <Button className="sharebtndanger" size="sm">
                Save
              </Button>
            </Modal.Footer> */}
          </Modal>
        </>
      )}
    </>
  );
};
