import React, { ReactNode, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Template } from "../../../../lib/Template";
import { ITemplateFilterParams } from "../../../../interfaces/ITemplateFilterParams";
import { IMetaResponse } from "../../../../interfaces/response/IMetaResponse";
import { adminApi } from "../../../../utils/adminApi";
import { ITemplateResponse } from "../../../../interfaces/response/ITemplateResponse";

interface TemplateContextInterface {
  handleGetTemplates: (id: string, params: any) => void;
  recentTemplates: Template[] | null;
  filterParams: ITemplateFilterParams;
  changeFilter: (params: ITemplateFilterParams) => void;
  metaData: IMetaResponse;
  loading: boolean;
}

const TemplateContext = React.createContext<TemplateContextInterface | null>(
  null
);

interface TemplateProviderProps {
  children: ReactNode;
}

const TemplateProvider = ({ children }: TemplateProviderProps) => {
  const { department } = useParams();
  const initialFilter: ITemplateFilterParams = useMemo(() => {
    const params: ITemplateFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    };
    if (department) {
      params.department = department;
    }
    return params;
  }, [department]);
  const [recentTemplates, setRecentTemplates]: [Template[] | null, Function] =
    useState(null);
  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);
  const [loading, setLoading] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 0,
    limit: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetTemplates = async (id: string, params: any) => {
    setLoading(true);
    const res = await adminApi.getTemplates(id, params);
    setRecentTemplates(res?.template);
    setMetaData(res.meta);
    const _templates: Template[] = res.template.map(
      (templateParam: ITemplateResponse) => new Template(templateParam)
    );
    setLoading(false);
    return _templates;
  };

  const value: TemplateContextInterface = useMemo(
    () => ({
      recentTemplates,
      handleGetTemplates,
      filterParams,
      changeFilter: (params: ITemplateFilterParams) => setFilterParams(params),
      resetFilter: () => setFilterParams(initialFilter),
      metaData,
      loading,
    }),
    [recentTemplates, handleGetTemplates, filterParams, initialFilter, metaData]
  );

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};

const useAdminTemplateContext = () => {
  return React.useContext(TemplateContext);
};

export {
  TemplateProvider,
  useAdminTemplateContext,
  type TemplateContextInterface,
};
