import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { useState } from "react";

function WaterMarkInput({
  showWaterMarkModal,
  setShowWaterMarkModal,
  context,
}: Readonly<{
  showWaterMarkModal: boolean;
  setShowWaterMarkModal: Function;
  context?: TemplateContextInterface | ContractContextInterface;
}>) {
  const handleClose = () => setShowWaterMarkModal(false);
  const handleSubmit = () => {
    context?.setWaterMarktext(value);
    setShowWaterMarkModal(false);
  };
  const handleRemove = () => {
    context?.setWaterMarktext("");
    setShowWaterMarkModal(false);
  };

  const [value, setValue] = useState("");

  return (
    <Modal show={showWaterMarkModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Insert WaterMark</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Text : </Form.Label>
            <Form.Control
              type="text"
              placeholder="Watermark"
              autoFocus
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleRemove} className="me-auto">
          Remove Watermark
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WaterMarkInput;
