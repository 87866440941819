import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layouts/AuthLayout";
import FailureToast from "../../ui/FailureToast";
import FormikField from "../../ui/FormikField";
import FormikSubmitButton from "../../ui/FormikSubmitButton";
import SuccessToast from "../../ui/SuccessToast";
import { useAuthentication } from "./AuthenticationProvider";
import { FormGroup } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import sigin_bg from "../../assets/sigin_bg.jpg";
import { api } from "../../utils/api";
import { routes } from "../../utils/routes";

interface InvitationProps {
  firstName: string | "";
  lastName: string | "";
  password: string | "";
  confirm_password: "";
  phone_number: string | "";
}
const InvitationPage = () => {
  let [searchParams] = useSearchParams();
  const autnContext = useAuthentication();
  const token = searchParams.get("token");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [bodyMessage, setBodyMessage] = useState("");
  const [loader, setLoader]: [boolean, Function] = useState(false);

  const [passwordIcon, setPasswordIcon]: [boolean, Function] = useState(false);
  const [passwordConfirmIcon, setPasswordConfirmIcon]: [boolean, Function] =
    useState(false);
  const [userDetail, setUserDetail]: [any, Function] = useState();
  const context = useAuthentication();
  const navigate = useNavigate();

  const initialValues: InvitationProps = {
    firstName: userDetail?.first_name,
    lastName: userDetail?.last_name,
    password: "",
    confirm_password: "",
    phone_number: userDetail?.contact_number,
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("name is required"),
    lastName: Yup.string().required("name is required"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .test(
        "isValidPass",
        "Password should contain one uppercase, one number and one special character",
        (value) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSymbol = /[!@#%&]/.test(value);

          let validConditions = 0;
          const numberOfMustBeValidConditions = 4;
          const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }
      )
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Password doesn't match"),
    phone_number: Yup.string()
      .typeError("That doesn't look like a phone number")
      // .min(8, "Contact Number must be atleast 8 characters")
      .required(""),
  });

  const handleSubmitData = async (values: InvitationProps) => {
    const user = await api?.verifyTokenpost(token, {
      ...values,
      department: userDetail ? userDetail?.department : null,
      roles: userDetail ? userDetail?.roles : null,
      password: values?.password,
      email: userDetail ? userDetail?.email : null,
    });

    if (user?.status === 201) {
      setShowSuccessToast(true);
      setBodyMessage("Complete invitation done!.");
      navigate(routes.root);
    } else if (user?.status === 400) {
      setBodyMessage(user?.data?.error?.[0]?.replace("_", " "));
      setShowFailureToast(true);
    }
  };

  const getUserDetail = async () => {
    setLoader(true);
    try {
      let response = await autnContext?.handleVerifyToken(token);
      if (response) {
        setLoader(false);
        setUserDetail(response);
      }
    } catch (error: any) {
      setLoader(false);
      setBodyMessage(error?.response?.data?.error?.[0]);
    }
  };
  useEffect(() => {
    getUserDetail();
  }, [token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: InvitationProps) => {
      try {
        handleSubmitData(values);
      } catch (err) {}
      formik.setFieldValue("", []);
    },
  });

  const { errors, handleSubmit, isSubmitting, values } = formik;

  return (
    context && (
      <AuthLayout
        pageTitle={bodyMessage === "Invalid token" ? "" : "CLM - Invitation"}
        title={
          bodyMessage === "Invalid token"
            ? "Invalid token"
            : "You are invited here!"
        }
        subTitle={
          bodyMessage === "Invalid token" ? "" : "Complete the invitation"
        }
      >
        {loader ? (
          <>loading...</>
        ) : (
          bodyMessage !== "Invalid token" && (
            <>
              <div className=" d-flex align-items-center py-4 justify-content-between">
                <div className="d-flex align-items-center">
                  <div
                    className="memberimageshape   me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={sigin_bg} alt="logo" width={40} />
                  </div>
                  <div>
                    <h6 className=" fw-semibold m-0 userEmail mb-2">
                      {userDetail?.email ? userDetail?.email : ""}
                    </h6>
                    <h6 className=" fw-light m-0 invite">
                      {userDetail?.roles?.[0] === "AUTHORIZED_SIGNATORIES"
                        ? "Authorized Signatory "
                        : userDetail?.roles?.[0]?.charAt(0)?.toUpperCase() +
                          userDetail?.roles?.[0]?.slice(1)?.toLowerCase()}
                      {userDetail?.department && "|"}
                      {userDetail?.department?.charAt(0)?.toUpperCase() +
                        userDetail?.department?.slice(1)?.toLowerCase()}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "48vh",
                  overflowY: "scroll",
                  paddingRight: "5px",
                }}
              >
                <FormikProvider value={formik}>
                  <Form noValidate={true} onSubmit={handleSubmit}>
                    <FormikField
                      name="firstName"
                      type="text"
                      validationSchema={validationSchema}
                      label="First Name"
                      placeholder="First Name"
                      errors={errors}
                      autoFocus
                      value={values?.firstName}
                    />
                    <FormikField
                      name="lastName"
                      type="text"
                      validationSchema={validationSchema}
                      label="Last Name"
                      placeholder="Last name"
                      errors={errors}
                      value={values?.lastName}
                      // autoFocus
                    />
                    <div>
                      <label className="text-dark ">
                        Contact Number <span className="">*</span>
                      </label>
                      <FormGroup className="mt-2 ">
                        <div className="phone-input-css ">
                          <PhoneInput
                            placeholder="Enter contact number"
                            value={userDetail?.contact_number}
                            name="phone_number"
                            country={"in"}
                            defaultCountry="IN"
                            onChange={(e: any) =>
                              formik.setFieldValue("phone_number", e)
                            }
                            error={
                              formik?.values?.phone_number
                                ? isValidPhoneNumber(
                                    formik?.values?.phone_number
                                  )
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                        </div>
                        <span className="field-error">
                          {formik?.values?.phone_number
                            ? isValidPhoneNumber(formik?.values?.phone_number)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"}
                        </span>

                        <ErrorMessage name="phone_number" />
                      </FormGroup>
                    </div>
                    <FormikField
                      name="password"
                      type={passwordIcon ? "text" : "password"}
                      validationSchema={validationSchema}
                      label="Password"
                      placeholder="Password"
                      errors={errors}
                      isPassword={true}
                      setPasswordIcon={setPasswordIcon}
                      passwordIcon={passwordIcon}
                    />
                    <FormikField
                      name="confirm_password"
                      type={passwordConfirmIcon ? "text" : "password"}
                      validationSchema={validationSchema}
                      label="Confirm Password"
                      errors={errors}
                      // value={values?.confirm_password}
                      autoFocus
                      placeholder="Enter confirm your password"
                      isPassword={true}
                      setPasswordIcon={setPasswordConfirmIcon}
                      passwordIcon={passwordConfirmIcon}
                      className="passwordIconHide"
                    />

                    <div className=" d-flex justify-content-end">
                      <FormikSubmitButton
                        isSubmitting={
                          !isSubmitting && values?.confirm_password === ""
                        }
                        className="my-3 w-100"
                        size="md"
                        submittingLabel={<span>Complete Invitation</span>}
                      >
                        <span
                          className="SignText"
                          onClick={() => {
                            handleSubmitData(formik?.values);
                          }}
                        >
                          Complete Invitation
                        </span>
                      </FormikSubmitButton>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            </>
          )
        )}

        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message="Invitation data is successfully filled"
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToast(false);
            }}
          />
        )}
        {showFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message={bodyMessage ? bodyMessage : "Invalid email or password."}
            title={"Failure!"}
            show={showFailureToast}
            close={() => {
              setShowFailureToast(false);
            }}
          />
        )}
      </AuthLayout>
    )
  );
};

export default InvitationPage;
