import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
import { ITemplateFilterParams } from "../../interfaces/ITemplateFilterParams";
import { IContractResponse } from "../../interfaces/response/IContractResponse";
import { IUserResponse } from "../../interfaces/response/IUserResponse";
import { Contract } from "../../lib/Contract";
import { User } from "../../lib/User";
import { api } from "../../utils/api";
import { ActivityFeed } from "../../lib/ActivityFeed";
import { IActivityFeedResponse } from "../../interfaces/response/IActivityFeedResponse";
import { IInviteCollaborator } from "../../interfaces/IInviteCollaborators";
import { IMetaResponse } from "../../interfaces/response/IMetaResponse";

export interface ListContractContextInterface {
  filterParams: ITemplateFilterParams;
  getRecentDocuments: () => Promise<Contract[]>;
  changeFilter: (params: ITemplateFilterParams) => void;
  resetFilter: () => void;
  categoriesAutocomplete: (search: string) => Promise<string[]>;
  templatesAutocomplete: (search: string) => Promise<string[]>;
  defaultCategories: ITemplateCategory[];
  contractTableLists: Contract[] | null;
  recentContracts: Contract[] | null;
  collaborators: User[];
  templateStatus: [];
  departmentList: [];
  createDocuments: Function;
  contractActivities: Array<ActivityFeed> | null;
  setContractActivity: Function;
  handleFolderById: Function;

  show: boolean;
  setShow: Function;
  values: Array<{}>;
  setValues: Function;
  accessOption: string;
  setAccessOption: Function;
  notifyPeople: boolean;
  setNotifyPeople: Function;
  sendMessage: string;
  setSendMessage: Function;
  showSuccessToast: boolean;
  setShowSuccessToast: Function;
  showFailureToast: boolean;
  setShowFailureToast: Function;
  inviteCollaborator: Function;
  setInitialValue: Function;
  getContractDetails: Function;
  name: string;
  setName: Function;
  handleContractDownload: Function;
  deleteContract: Function;
  isActiveProvider: string;
  updateDocumentName: Function;
  metaData: IMetaResponse;
  handleRootFolder: Function;
  handleMoveToFolderTemplate: Function;
  loader: boolean;
  handleGetStatus: Function;
  handleUploadContract: Function;
  allData: any;
}

const ListContractContext =
  React.createContext<ListContractContextInterface | null>(null);

interface ListContractsProviderProps {
  children: ReactNode;
}

const ListContractsProvider = ({ children }: ListContractsProviderProps) => {
  const { department } = useParams();
  const initialFilter: ITemplateFilterParams = useMemo(() => {
    const params: ITemplateFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
      status: "",
    };
    if (department) {
      params.department = department;
    }
    return params;
  }, [department]);

  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);

  const [defaultCategories, setDefaultCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);

  const [isActiveProvider]: [string, Function] = useState("contracts");

  // .......collaborators list array...............
  const [collaborators, setCollaborators]: [User[], Function] = useState([]);

  // .................template status..........................
  const [templateStatus, setTemplateStatus]: [[], Function] = useState([]);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);

  const [recentContracts, setRecentContracts]: [Contract[] | null, Function] =
    useState(null);
  const [loader, setLoader]: [boolean, Function] = useState(true);
  const [metaData, setMetaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 0,
    limit: 0,
  });
  const [contractTableLists, setContractTableLists]: [
    Contract[] | null,
    Function
  ] = useState(null);
  const [contractActivities, setContractActivity]: [
    ActivityFeed | null,
    Function
  ] = useState(null);
  const [show, setShow] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      handleTemplateActivities(id);
      getContractDetails(id);
    }
  }, [id]);

  const handleMoveToFolderTemplate = async (params: any, id: string) => {
    try {
      let response = await api.moveToFolderContract(params, id);
      return response;
    } catch (error) {}
  };

  const handleFolderById = async (id: string, params: any) => {
    try {
      let response = await api.getFolderById(id, params);

      return response;
    } catch (error) {}
  };

  const handleTemplateActivities = async (id: string) => {
    let response = await api.getContractActivities(id);
    const contractActivities: ActivityFeed[] = response.activities.map(
      (activityLogs: IActivityFeedResponse) => new ActivityFeed(activityLogs)
    );
    setContractActivity(contractActivities);
  };

  const handleCategoriesAutocomplete = useCallback(
    async (search: string): Promise<string[]> =>
      await api.getCategoriesAutoComplete({ search: search }),
    []
  );

  const handleTemplatesAutocomplete = useCallback(
    async (search: string): Promise<string[]> =>
      await api.getTemplatesCategoriesAutocomplete({ search: search }),
    []
  );

  const getContracts = async (filterParams: any) => {
    setLoader(true);
    const res = await api.getContract(filterParams);
    let _contracts: any;
    if (res) {
      setLoader(false);
      setMetaData(res?.meta);
      _contracts = res?.contracts.map(
        (templateParam: IContractResponse) => new Contract(templateParam)
      );
    }

    return _contracts;
  };

  const [allData, setAllData]: [any, Function] = useState();
  const getContractsData = async () => {
    const res = await api.getContract({ limit: 10, sort: "-updatedAt" });

    if (res) {
      setAllData(res?.contracts);
    }
  };
  useEffect(() => {
    getContractsData();
  }, []);

  const deleteContract = async (id: string) => {
    try {
      return await api.deleteContract(id);
    } catch (error) {}
  };

  const handleRecentContracts = useCallback(async () => {
    const contracts = await getContracts({
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    });

    setRecentContracts(contracts);
    setContractTableLists(contracts);
    return contracts;
  }, []);

  // ................collaborators..............

  const getCollaborators = async () => {
    const res = await api.getCollaborators({ limit: 100 });
    const _collaborators: User[] = res?.users?.map(
      (collaboratorsParams: IUserResponse) => new User(collaboratorsParams)
    );

    setCollaborators(_collaborators);
    return _collaborators;
  };

  // ...................Department..............
  const getDepartments = async () => {
    const res = await api.getStatus();

    setTemplateStatus(res?.data?.contractStatus);
    setDepartmentList(res?.data?.departments);
  };

  const getDepartmentList = async () => {
    const res = await api.getDepartments();
    setDepartmentList(res?.data?.departments);
  };

  // .................. create contract ..................

  const createDocuments = useCallback(
    async (params: {
      name: string;
      description: string;
      template: string;
    }): Promise<Contract | null> => {
      try {
        const { data } = await api.createContract(params);

        const { success, contract } = data;
        if (success) {
          return new Contract(contract);
        }
        getContracts(initialFilter);
        return null;
      } catch (e) {
        return null;
      }
    },
    [initialFilter]
  );

  const updateDocumentName = async (id: string, params: any) => {
    try {
      let res = await api.updateContractName(id, params);
      return res;
    } catch (error) {}
  };

  useEffect(() => {
    const loadData = async () => {
      const categories = await handleCategoriesAutocomplete("");
      setDefaultCategories(categories);
    };
    loadData();
  }, [handleCategoriesAutocomplete]);

  useEffect(() => {
    const loadData = async () => {
      const categories = await handleTemplatesAutocomplete("");
      setDefaultCategories(categories);
    };
    loadData();
  }, [handleTemplatesAutocomplete]);

  useEffect(() => {
    const loadData = async () => handleRecentContracts();
    if (!!handleRecentContracts) {
      loadData();
    }
  }, [handleRecentContracts]);

  const laodData = async () => {
    let _params = filterParams;
    if (department) {
      _params.department = department.toUpperCase();
    }
    const _contracts = await getContracts(_params);
    setRecentContracts(_contracts);
    setContractTableLists(_contracts);
  };

  useEffect(() => {
    laodData();
  }, [filterParams, department]);

  useEffect(() => {
    getCollaborators();
    getDepartments();
    getDepartmentList();
  }, []);

  const [values, setValues] = useState([]);
  const [sendMessage, setSendMessage] = useState<string>("");
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false);
  const [accessOption, setAccessOption] = useState<string>("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [name, setName] = useState<string>("");
  const setInitialValue = () => {
    setShow(false);
    setAccessOption("");
    setNotifyPeople(false);
    setSendMessage("");
    setValues([]);
  };

  const handleInviteCollaborator = async (id: string) => {
    try {
      const response = await api.inviteCollaborators(id, {
        collaborators_id: values.map((item: IInviteCollaborator) => {
          return item?.value;
        }),
        message: sendMessage,
        notify: notifyPeople,
        access: accessOption,
      });
      response.success ? setShowSuccessToast(true) : setShowFailureToast(true);
      return response;
    } catch (error) {}
  };

  const getContractDetails = async (id: string) => {
    try {
      let response = await api.getContractsDetails(id);
      if (response.success) {
        setName(response.contract.name);
      }
      return response;
    } catch (error) {}
  };

  const handleRootFolder = async () => {
    try {
      let response = await api.getrootFolder();
      return response;
    } catch (error) {}
  };

  const handleContractDownload = async (id: string) => {
    try {
      let response = await api.downloadContract(id);
      fetch(response.file_path)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "downloaded-pdf.pdf";
          link.click();
        });
      return response;
    } catch (error) {}
  };

  const handleGetStatus = async () => {
    try {
      let response = await api.getStatus();

      return response;
    } catch (error) {}
  };

  const handleUploadContract = async (params: any) => {
    try {
      let response = await api.uploadContract(params);
      handleRecentContracts();
      return response;
    } catch (error) {}
  };

  const value: ListContractContextInterface = useMemo(
    () => ({
      filterParams,
      changeFilter: (params: ITemplateFilterParams) => setFilterParams(params),
      resetFilter: () => setFilterParams(initialFilter),
      categoriesAutocomplete: handleCategoriesAutocomplete,
      templatesAutocomplete: handleTemplatesAutocomplete,
      getRecentDocuments: handleRecentContracts,
      defaultCategories,
      recentContracts,
      initialFilter,
      contractTableLists,
      collaborators,
      templateStatus,
      departmentList,
      createDocuments,
      contractActivities,
      setContractActivity,
      deleteContract,
      updateDocumentName,
      handleRootFolder,
      handleMoveToFolderTemplate,
      handleUploadContract,
      handleFolderById,
      allData,

      show,
      setShow,
      values,
      setValues,
      accessOption,
      setAccessOption,
      notifyPeople,
      setNotifyPeople,
      sendMessage,
      setSendMessage,
      showSuccessToast,
      setShowSuccessToast,
      showFailureToast,
      setShowFailureToast,
      inviteCollaborator: handleInviteCollaborator,
      setInitialValue,
      getContractDetails,
      name,
      setName,
      handleContractDownload,
      isActiveProvider,
      metaData,
      loader,
      handleGetStatus,
    }),
    [
      filterParams,
      defaultCategories,
      handleRecentContracts,
      recentContracts,
      handleCategoriesAutocomplete,
      handleTemplatesAutocomplete,
      initialFilter,
      contractTableLists,
      collaborators,
      templateStatus,
      departmentList,
      createDocuments,
      contractActivities,
      setContractActivity,
      showSuccessToast,
      setShowSuccessToast,
      showFailureToast,
      setShowFailureToast,
    ]
  );

  return (
    <ListContractContext.Provider value={value}>
      {children}
    </ListContractContext.Provider>
  );
};

const useContract = () => {
  return React.useContext(ListContractContext);
};

export { ListContractsProvider, useContract };
