import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { ListFoldersContextInterface, useFolders } from "../FoldersProvider";
import DateFilter from "./DateFilter";
import { useParams } from "react-router-dom";

function FolderFilters() {
  const { id } = useParams();
  const context: ListFoldersContextInterface | null = useFolders();
  const [filterActive, setFilterActive]: [boolean, Function] = useState(false);
  const [startValues, setStartValues] = useState<any>();
  const [endValues, setEndValues] = useState<any>();

  const [parentId, setParentId]: [string, Function] = useState("");
  const [filterParams, setFilterParams]: [{}, Function] = useState({
    collaborators: "",
    department: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    if (context?.folderList) {
      setParentId(context?.folderList?.id);
    }
  }, [context?.folderList]);

  const handleSubmit = async (type: string) => {
    try {
      let response = await context?.handleFolderById(
        id ? id : parentId,
        type == "reset" ? { start_date: "", end_date: "" } : filterParams
      );
      if (response?.success) {
        context?.setSubFolders(response?.folder?.sub_folders);
      }
    } catch (error) {}
  };

  return (
    context && (
      <>
        <Dropdown
          className="filter-dropdown"
          onClick={() => {
            setFilterActive(!filterActive);
          }}
        >
          <Dropdown.Toggle
            className="filter-css ms-lg-3"
            id="dropdown-basic"
            // onClick={() => setIsFilterActive(!isfilterActive)}
          >
            Filter By
            <span className="px-2">
              <FiFilter />
            </span>
          </Dropdown.Toggle>
          {/* {filterActive && ( */}
          <>
            <Dropdown.Menu className="filter-dropdown-menu">
              <DateFilter
                setStartValues={setStartValues}
                startValues={startValues}
                setEndValues={setEndValues}
                endValues={endValues}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
              />

              <div className="d-flex justify-content-start my-2">
                <Button
                  variant="outline-danger"
                  className="px-4 bg-white text-danger me-3"
                  size="sm"
                  onClick={() => {
                    setEndValues("");
                    setStartValues("");

                    setFilterParams({
                      collaborators: "",
                      department: "",
                      start_date: "",
                      end_date: "",
                    });

                    handleSubmit("reset");
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="success"
                  className="text-white px-4"
                  disabled={
                    startValues === undefined || endValues === undefined
                  }
                  onClick={() => handleSubmit("apply")}
                >
                  Apply
                </Button>
              </div>
            </Dropdown.Menu>
          </>
          {/* )} */}
        </Dropdown>
      </>
    )
  );
}

export default FolderFilters;
