import { Badge } from "react-bootstrap";
import { ContractProvider } from "./ContractProvider";
import AdminHeader from "../../../../layouts/AdminHeader";

function ContractOverview() {
  return (
    <>
      <AdminHeader>
        <ContractProvider>
          <ContractBody />
        </ContractProvider>
      </AdminHeader>
    </>
  );
}
export default ContractOverview;

const ContractBody = () => {
  return (
    <>
      <div className=" d-flex justify-content-between align-content-center pt-4 flex-wrap">
        <div style={{ cursor: "default" }}>
          <h5 className=" fw-bold me-3 mb-0">{"Untitled Document"}</h5>

          <div className="text-dark small fw-lighter  LastEdited ">
            Last edited on &nbsp;by&nbsp;
            <span className="ms-1 LastEdited">{"name"}</span>
            &nbsp;&nbsp;
            <Badge className="publish-badge" bg="success">
              {"Owner"}
            </Badge>
          </div>
        </div>
      </div>
    </>
  );
};
