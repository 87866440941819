import { Button, Form, Modal } from "react-bootstrap";
import {
  ListTemplatesProvider,
  useTemplates,
} from "../modules/Templates/ListTemplatesProvider";
import { ITemplateCategory } from "../interfaces/ITemplateCategory";
import { useEffect, useState } from "react";
import { useTemplate } from "../modules/Templates/TemplateProvider";
import { useContract } from "../modules/Contracts/ContractProvider";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import CreatableSelect from "react-select/creatable";
import { useLocation } from "react-router";
import moment from "moment";
import Select from "react-select";

type IPublishDocumentPopup = {
  show: boolean;
  onHide: Function;
  setDepartment: Function;
  setCategory: Function;
  department: string;
  category: string;
  onPublishDocument: Function;
  loader: boolean;
  setExpiryDate: Function;
  expiryDate: Date | null;
  setSelectedDate: Function;
  selectedDate: boolean;
};

function PublishDocumentPopup(props: IPublishDocumentPopup) {
  return (
    <>
      <ListTemplatesProvider>
        <PublishData {...props} />
      </ListTemplatesProvider>
    </>
  );
}

export default PublishDocumentPopup;

const PublishData = ({
  show,
  onHide,
  setCategory,
  setDepartment,
  department,
  category,
  onPublishDocument,
  loader,
  setExpiryDate,
  expiryDate,
  setSelectedDate,
  selectedDate,
}: {
  show: boolean;
  onHide: Function;
  setCategory: Function;
  setDepartment: Function;
  department: string;
  category: string;
  onPublishDocument: Function;
  loader: boolean;
  setExpiryDate: Function;
  expiryDate: Date | null;
  setSelectedDate: Function;
  selectedDate: boolean;
}) => {
  const context = useTemplates();
  const templateContext = useTemplate();
  const contractContext = useContract();
  const [isPublishActive, setIsPublishActive]: [boolean, Function] =
    useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("contracts")) {
      if (category && selectedDate === true) {
        setIsPublishActive(true);
      }
    } else if (location.pathname.includes("templates")) {
      if (category) {
        setIsPublishActive(true);
      }
    }
  }, [department, category, selectedDate]);

  useEffect(() => {
    if (contractContext?.data?.expiry_date) {
      setExpiryDate(moment(contractContext?.data?.expiry_date).toDate());
    }
  }, [
    contractContext?.data?.expiry_date,
    contractContext?.data?.stamp_required,
  ]);

  return (
    <>
      <Modal show={show} onHide={() => onHide} centered>
        <Modal.Header closeButton onClick={() => onHide()}>
          <div className="pt-3">
            <h6 className=" fw-semibold"> Update document</h6>
          </div>
        </Modal.Header>

        <Modal.Body className="pt-2 p-4">
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="row status-title mb-3 mt-3">Department</div>
              <Select
                defaultValue={
                  templateContext
                    ? {
                        label: templateContext?.template.department!,
                        value: templateContext?.template.department!,
                      }
                    : {
                        label: contractContext?.contract.department!,
                        value: contractContext?.contract.department!,
                      }
                }
                className={"select-wrapper"}
                options={context?.departmentList?.map((item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(option: any) => {
                  setDepartment(option?.value);
                }}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <div className="row status-title mb-3 mt-3">Category</div>
              <CreatableSelect
                options={(context?.defaultCategories || [])?.map(
                  (category: ITemplateCategory) => ({
                    label: category?.category,
                    value: category?.category,
                  })
                )}
                defaultValue={
                  templateContext
                    ? {
                        label: templateContext?.template.category!,
                        value: templateContext?.template.category!,
                      }
                    : {
                        label: contractContext?.contract.category!,
                        value: contractContext?.contract.category!,
                      }
                }
                onChange={(option: any) => {
                  setCategory(option?.value);
                }}
              />
            </Form.Group>

            {!location.pathname.includes("templates") && (
              <>
                <Form.Group>
                  <div className="row status-title mb-3 mt-3">Expiry Date</div>
                  <DatePicker
                    className="date_picker_css"
                    value={expiryDate ? moment(expiryDate).toDate() : ""}
                    onChange={(date: any) => {
                      setExpiryDate(date);
                      setSelectedDate(true);
                    }}
                    minDate={moment().toDate()}
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            size="sm"
            variant={"none"}
            className={`me-3  px-2 py-1 fw-semibold cancelbtn `}
            onClick={() => {
              onHide();
              templateContext?.refresh();
              contractContext?.refresh();
              setExpiryDate(null);
              setSelectedDate(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className=" updatebtndanger  me-2"
            variant="primary"
            size="sm"
            disabled={!isPublishActive}
            onClick={() => {
              onPublishDocument("update document");
              // templateContext?.refresh();
              // contractContext?.refresh();
            }}
          >
            Save
            {loader ? (
              <>
                <span className="btn_loader"></span>
              </>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
