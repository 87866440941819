import React, { ReactNode, useMemo, useState } from "react";
import { adminApi } from "../../../utils/adminApi";

interface ListAdminAnalyticsContextInterface {
  filterParams: Boolean;
  setFilterParams: Function;
  handleOverallStats: Function;
  barChartDataDetails: Function;
  pieChartDataDetails: Function;
  bulletChartDataDetailsWeek: Function;
  bulletChartDataDetailsMonth: Function;
  stackedBarChartDetails: Function;
  monthlyStackedBarChartDetails: Function;
  loadStateWiseActiveOrganisation: Function;
  loadCityWiseActiveOrganisation: Function;
  organisationBasedOnContractDetails: Function;
  contractStatsData: Function;
  volumeMOMBarDetails: Function;
  lineChartDataDetails: Function;
  dountChartDataDetails: Function;
  publishBarDetails: Function;
  negoBarDetails: Function;
  approveBarDetails: Function;
  executionBarDetails: Function;
  valueMOMBarDetails: Function;
  signedChartDataDetails: Function;
  excutionLineChartDataDetails: Function;
  getOrganistaionInfo: Function;
  getSignedContracts: Function;
}

const AdminAnalyticsContext =
  React.createContext<ListAdminAnalyticsContextInterface | null>(null);

interface AdminAnalyticsProviderProps {
  children: ReactNode;
}

const AdminAnalyticsProvider = ({ children }: AdminAnalyticsProviderProps) => {
  const [filterParams, setFilterParams]: [any, Function] = useState();

  const handleOverallStats = async (params: any) => {
    try {
      let response = await adminApi.getAdminOverallStats(params);
      return response;
    } catch (error) {}
  };

  const contractStatsData = async (params: any) => {
    try {
      let response = await adminApi.getAdminContractStats(params);
      return response;
    } catch (error) {}
  };

  const barChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.ChartData(params);
      return response;
    } catch (error) {}
  };

  const pieChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.pieChartData(params);
      return response;
    } catch (error) {}
  };

  const bulletChartDataDetailsWeek = async (params: any) => {
    try {
      let response = await adminApi.bulletChartData(params);
      return response;
    } catch (error) {}
  };
  const bulletChartDataDetailsMonth = async (params: any) => {
    try {
      let response = await adminApi.bulletChartDataMonth(params);
      return response;
    } catch (error) {}
  };

  const stackedBarChartDetails = async (params: any) => {
    try {
      let response = await adminApi.stackedChartData(params);
      return response;
    } catch (error) {}
  };
  const volumeMOMBarDetails = async (params: any) => {
    try {
      let response = await adminApi.volumeChartData(params);
      return response;
    } catch (error) {}
  };

  const valueMOMBarDetails = async (params: any) => {
    try {
      let response = await adminApi.valueChartData(params);
      return response;
    } catch (error) {}
  };

  const monthlyStackedBarChartDetails = async (params: any) => {
    try {
      let response = await adminApi.monthlyStackedChartData(params);
      return response;
    } catch (error) {}
  };

  const loadStateWiseActiveOrganisation = async (params: any) => {
    try {
      let response = await adminApi.loadStateWiseActiveOrganisationData(params);
      return response;
    } catch (error) {}
  };

  const loadCityWiseActiveOrganisation = async (params: any) => {
    try {
      let response = await adminApi.loadCityWiseActiveOrganisationData(params);
      return response;
    } catch (error) {}
  };

  const organisationBasedOnContractDetails = async (params: any) => {
    try {
      let response = await adminApi.organisationBasedOnContractData(params);
      return response;
    } catch (error) {}
  };

  const lineChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.lineChartData(params);
      return response;
    } catch (error) {}
  };

  const dountChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.dountChartData(params);
      return response;
    } catch (error) {}
  };

  const publishBarDetails = async (params: any) => {
    try {
      let response = await adminApi.publishChartData(params);
      return response;
    } catch (error) {}
  };

  const negoBarDetails = async (params: any) => {
    try {
      let response = await adminApi.negoChartData(params);
      return response;
    } catch (error) {}
  };

  const approveBarDetails = async (params: any) => {
    try {
      let response = await adminApi.approveChartData(params);
      return response;
    } catch (error) {}
  };

  const executionBarDetails = async (params: any) => {
    try {
      let response = await adminApi.executionChartData(params);
      return response;
    } catch (error) {}
  };

  const signedChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.signedChartData(params);
      return response;
    } catch (error) {}
  };

  const excutionLineChartDataDetails = async (params: any) => {
    try {
      let response = await adminApi.executionLineChartData(params);
      return response;
    } catch (error) {}
  };

  const getOrganistaionInfo = async (params: any) => {
    try {
      let response = await adminApi?.getAccounts(params);
      return response;
    } catch (error) {}
  };

  const getSignedContracts = async (params: any) => {
    try {
      let response = await adminApi?.getSignedContracts(params);
      return response;
    } catch (error) {}
  };

  const value: ListAdminAnalyticsContextInterface = useMemo(
    () => ({
      filterParams,
      setFilterParams,
      handleOverallStats,
      barChartDataDetails,
      pieChartDataDetails,
      bulletChartDataDetailsWeek,
      bulletChartDataDetailsMonth,
      stackedBarChartDetails,
      monthlyStackedBarChartDetails,
      loadStateWiseActiveOrganisation,
      loadCityWiseActiveOrganisation,
      organisationBasedOnContractDetails,
      contractStatsData,
      lineChartDataDetails,
      dountChartDataDetails,
      publishBarDetails,
      negoBarDetails,
      approveBarDetails,
      executionBarDetails,
      volumeMOMBarDetails,
      valueMOMBarDetails,
      signedChartDataDetails,
      excutionLineChartDataDetails,
      getOrganistaionInfo,
      getSignedContracts,
    }),

    []
  );

  return (
    <AdminAnalyticsContext.Provider value={value}>
      {children}
    </AdminAnalyticsContext.Provider>
  );
};

const useAdminAnalytics = () => {
  return React.useContext(AdminAnalyticsContext);
};

export {
  AdminAnalyticsProvider,
  useAdminAnalytics,
  type ListAdminAnalyticsContextInterface,
};
