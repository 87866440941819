import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTemplate } from "../TemplateProvider";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { IApprovalResponse } from "../../../interfaces/response/IApprovalResponse";
import { StatusEnum } from "../../../interfaces/StatusEnum";

export const ApproveButton = ({
  setRejectApproval,
  setIsApprovedACtive,
}: {
  setRejectApproval: Function;
  setIsApprovedACtive: Function;
}) => {
  const authContext = useAuthentication();
  const context = useTemplate();
  const [loading] = useState(false);
  const [approved, setApproved]: [boolean, Function] = useState(false);

  useEffect(() => {
    const x: any = context?.template?.approvers.find(
      (el: IApprovalResponse) => {
        return el?.user?.sub === authContext?.currentUser?.sub;
      }
    );
    if (x?.status === StatusEnum?.approved) {
      setApproved(true);
    }
  }, [context?.template]);

  const currentUserAccess = () => {
    let data: any;
    const currentUserIndex: any = context?.template?.approvers?.findIndex(
      (item: IApprovalResponse) =>
        item?.status === "PENDING" &&
        item?.user?.sub === authContext?.currentUser?.sub
    );

    if (currentUserIndex > -1) {
      data =
        currentUserIndex === 0
          ? context?.template?.approvers[currentUserIndex]
          : context?.template?.approvers[currentUserIndex - 1];
    }
    const firstPendingUserIndex: any = context?.template?.approvers?.findIndex(
      (item: IApprovalResponse) => item?.status === "PENDING"
    );
    return firstPendingUserIndex > -1 && currentUserIndex === 0
      ? data?.status === "PENDING"
      : getPastUpdatedStatus(data);
  };

  const getPastUpdatedStatus = (data: any) => {
    if (
      !!context?.template?.approvers?.filter(
        (item: IApprovalResponse) => item?.status === StatusEnum?.approved
      )?.length
    ) {
      return data?.status === StatusEnum?.approved
        ? true
        : data?.status === "PENDING" && data?.is_optional;
    } else if (
      !!context?.template?.approvers?.filter(
        (item: IApprovalResponse) => item?.status === StatusEnum?.rejected
      )?.length
    ) {
      return false;
    }
  };

  return (
    authContext &&
    context && (
      <>
        {context?.template?.workflow_status !== "DRAFT" &&
          context?.template?.approvers.some(
            ({ user_id }: { user_id: string }) =>
              user_id === authContext?.currentUser?.id
          ) &&
          !approved &&
          currentUserAccess() && (
            <>
              <Button
                variant="outline-danger"
                className="cancelbtn me-3 "
                onClick={() => {
                  setRejectApproval(true);
                  setIsApprovedACtive("Reject");
                }}
              >
                Reject
              </Button>
              <Button
                variant="success"
                className="sharebtndanger"
                onClick={() => {
                  setRejectApproval(true);
                  setIsApprovedACtive("Approve");
                }}
                disabled={loading}
              >
                Approve
              </Button>
            </>
          )}
      </>
    )
  );
};
