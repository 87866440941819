import axios from "axios";
import { IContractFilterParams } from "../interfaces/IContractFilterParams";
import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";

const { REACT_APP_BASE_URL } = process.env;
export const BASE_URL = REACT_APP_BASE_URL;

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};

const generateAuthHeader = (config: any = {}) => {
  const token: string | null = localStorage.getItem("auth-token");
  if (token && token.length > 0) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return config;
};

// export const _delete = async (url: string, _config: any = {}) => {
//   const config: any = generateAuthHeader({ ..._config });
//   return await axios.delete(url, { ...config }).then((res: any) => res.data);
// };

const _delete = async (url: string, params?: any, _config: any = {}) => {
  let config = {};
  config = generateAuthHeader({ ..._config });
  // if (token) {
  //   config.headers = { Authorization: `Bearer ${token}` };
  // }
  return await axios
    .delete(url, { ...config, data: params })
    .then((res: any) => {
      if (res.status) {
        return res.data;
      } else {
        return res.data;
      }
    })
    .catch(({ response }: any) => {
      return response.data;
    });
};

const post = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .post(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const put = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .put(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        return response.data;
      }
      return response;
    });
};

const get = async (url: string, _config: any = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return await axios.get(url, { ...config }).then((res: any) => res.data);
};

export const api = {
  signUp: async (params: {
    first_name: string;
    last_name?: string;
    email: string;
    job_title?: string;
    industry?: string;
    department?: string;
    company_name?: string;
    team?: string;
    password: string;
  }) => {
    try {
      const { data } = await post(`${BASE_URL}/users/sign_up`, params);
      if (data?.success) {
        return data;
      }
      return {
        error: false,
        message: data?.error,
      };
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },
  signIn: async (params: { username: string; password: string }) => {
    try {
      const res = await post(`${BASE_URL}/auth/login`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      } else if (res?.statusCode === 401) {
        return {
          error: true,
          message: ["Incorrect Email Address or password."],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  signOut: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/auth/logout`, params);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getUser: () => get(`${BASE_URL}/users/me`),
  forgetPassword: (params: any) =>
    post(`${BASE_URL}/users/forget_password`, params),
  resetPassword: (params: any, token: string) =>
    post(`${BASE_URL}/users/${token}/reset_password`, params),
  deleteUser: (id: string) => _delete(`${BASE_URL}/users/${id}`),
  getCompanyDetails: () => get(`${BASE_URL}/account`),

  uploadFiles: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/account/logo`, params);
      return response;
    } catch ({ response }: any) {}
  },

  getDocument: (id: string) => get(`${BASE_URL}/documents/${id}`),
  getTemplateList: () => {
    return get(`${BASE_URL}/templates`);
  },
  getTemplates: (params: ITemplateFilterParams) => {
    return get(`${BASE_URL}/templates?${filterQueryParams(params)}`);
  },

  getTemplatesCategoriesAutocomplete: (params: Object) => {
    return get(
      filterQueryParams(params)
        ? `${BASE_URL}/templates/categories/auto_complete?${filterQueryParams(
            params
          )}`
        : `${BASE_URL}/templates/categories/auto_complete`
    );
  },

  getCategoriesAutoComplete: (params: Object) => {
    return get(
      filterQueryParams(params)
        ? `${BASE_URL}/contracts/categories/auto_complete?${filterQueryParams(
            params
          )}`
        : `${BASE_URL}/contracts/categories/auto_complete`
    );
  },

  deleteTemplate: (id: string) => {
    try {
      return _delete(`${BASE_URL}/templates/${id}`);
    } catch (error) {
      return "";
    }
  },

  deleteContract: (id: string) => {
    try {
      return _delete(`${BASE_URL}/contracts/${id}`);
    } catch (error) {
      return "";
    }
  },

  createTemplate: async (params: IContractFilterParams) => {
    try {
      const response = await post(`${BASE_URL}/templates`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ............................................contract .......................................................
  getContract: (params: IContractFilterParams) => {
    let res = get(`${BASE_URL}/v1/contracts?${filterQueryParams(params)}`);
    return res;
  },

  getContractList: () => {
    let res = get(`${BASE_URL}/v1/contracts`);
    return res;
  },

  createContract: async (params: any) => {
    try {
      return await post(`${BASE_URL}/contracts`, params);
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  UploadVersion: async (id: any, params: any) => {
    try {
      return await post(`${BASE_URL}/documents/${id}/upload`, params);
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  negotiationWorkflow: async (id: any, params: any) => {
    try {
      return await post(
        `${BASE_URL}/v1/contracts/${id}/start_negotitation`,
        params
      );
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // .....................................collaborators.....................................................
  getCollaborators: (params: any) =>
    get(`${BASE_URL}/users?${filterQueryParams(params)}`),
  getUsers: (params: any) =>
    get(`${BASE_URL}/users?${filterQueryParams(params)}`),

  updateCollaboratorsinTemplate: (id: string, body: any) =>
    put(`${BASE_URL}/templates/${id}/updateCollaborators`, body),
  updateCollaboratorsinContract: (id: string, body: any) =>
    put(`${BASE_URL}/contracts/${id}/updateCollaborators`, body),

  removeCollaboratorAccessinTemplate: (
    template_id: string,
    collaborator_id: string
  ) =>
    _delete(
      `${BASE_URL}/templates/${template_id}/removeColaborator/${collaborator_id}`
    ),
  removeCollaboratorAccessinContract: (
    contract_id: string,
    collaborator_id: string
  ) =>
    _delete(
      `${BASE_URL}/contracts/${contract_id}/removeColaborator/${collaborator_id}`
    ),

  getDepartments: () => get(`${BASE_URL}/templates/config`),

  publishDocuments: (id: string, params: ITemplateFilterParams) =>
    put(`${BASE_URL}/templates/${id}`, params),

  publishContractDocument: (id: string, params: ITemplateFilterParams) =>
    put(`${BASE_URL}/contracts/${id}`, params),

  // ...................................profiles api........................................................
  updateprofile: async (params: any) => {
    try {
      const response = await put(`${BASE_URL}/users/me`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  updateCompanyProfile: async (params: any) => {
    try {
      const response = await put(`${BASE_URL}/account`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  updateProfileimage: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/users/avatar`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  changepassword: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/auth/changePassword`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // .........................activites...........
  getTemplateActivities: async (id: string) => {
    try {
      return await get(`${BASE_URL}/templates/${id}/activities`);
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  getContractActivities: async (id: string) => {
    try {
      return await get(`${BASE_URL}/contracts/${id}/activities`);
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // ........................................Templates................................................................

  inviteCollaborators: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/inviteCollaborators`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  inviteContractCollaborators: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/inviteCollaborators`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  removeCollaborators: async (id: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/inviteCollaborators`,
        id
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getTemplate: async (id: string) => {
    try {
      return await get(`${BASE_URL}/templates/${id}`);
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getUsersAutocomplete: (params: any) => {
    return get(
      params?.search !== undefined
        ? `${BASE_URL}/users/autoComplete?${filterQueryParams(params)}`
        : `${BASE_URL}/users/autoComplete`
    );
  },

  getUsersDetail: () => {
    return get(`${BASE_URL}/users/autoComplete`);
  },

  // .....................variables.............
  getDocumentVariables: async (id: string, params: Object) => {
    try {
      return await get(
        `${BASE_URL}/documents/${id}/variables?${filterQueryParams(params)}`
      );
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  documentVariables: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/documents/${id}/variables`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  getVariableTypes: () => get(`${BASE_URL}/variables/config`),
  createDocumentVariable: async (docId: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/documents/${docId}/variables`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  removeDocumentVariables: async (docId: string, varId: string) => {
    try {
      const response = await _delete(
        `${BASE_URL}/documents/${docId}/variables/${varId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  updateDocumentVariables: async (
    docId: string,
    VarId: string,
    params: any
  ) => {
    try {
      const response = await put(
        `${BASE_URL}/documents/${docId}/variables/${VarId}`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // -----------------------------------------------------------------Comments---------------
  createDocumentComment: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/documents/${id}/comments`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  documentComments: async (id: string) => {
    try {
      const response = await get(`${BASE_URL}/documents/${id}/comments`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  documentDeleteComment: async (id: string, commentID: string) => {
    try {
      const response = await _delete(
        `${BASE_URL}/documents/${id}/comments/${commentID}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  documentUpdateCommentResolve: async (
    id: string,
    commentRefID: string,
    params: any
  ) => {
    try {
      const response = await put(
        `${BASE_URL}/documents/${id}/comments/${commentRefID}/resolve`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  documentUpdateCommentValue: async (
    id: string,
    commentId: string,
    params: any
  ) => {
    try {
      const response = await put(
        `${BASE_URL}/documents/${id}/comment/${commentId}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // /documents/{id}/comments/{commentId}

  // ------------------------------------------Editor POST document
  UpdateDocument: async (id: string, params: any) => {
    try {
      const response = await put(`${BASE_URL}/documents/${id}`, params);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ....................delete contract.........................

  // ..................................user detail.......................................................

  getUserEmail: (id: string) => {
    return get(`${BASE_URL}/users/${id}`);
  },

  addApprovals: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/add/approvers`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  addApprovalsTemplates: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/add/approvers`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getContractsDetails: (id: string) => {
    return get(`${BASE_URL}/contracts/${id}`);
  },

  updateContractDetail: async (id: string, params: any) => {
    try {
      const response = await put(`${BASE_URL}/contracts/${id}`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getTemplatesDetails: (id: string) => {
    return get(`${BASE_URL}/templates/${id}`);
  },

  addCounterParty: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/add/counter_party`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  addSigner: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/add/signer`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  approveContract: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/approve_contract `,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ................... approves template...............................
  approveTemplate: async (id: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/approve/template `
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .............reorder approver.................................
  reorderApprover: async (id: string, params: any) => {
    try {
      const response = await put(
        `${BASE_URL}/contracts/${id}/approvers`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  reorderNgOwner: async (id: string, params: any) => {
    try {
      const response = await put(
        `${BASE_URL}/contracts/${id}/negotation_owners`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  reorderNgOwnerViewer: async (
    id: string,
    params: any,
    negotation_owner: any
  ) => {
    try {
      const response = await put(
        `${BASE_URL}/contracts/${id}/negotation_owners/${negotation_owner}/negotation_viewer`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  reorderSignatory: async (id: string, params: any) => {
    try {
      const response = await put(`${BASE_URL}/contracts/${id}/signer`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .................reject approvers.............................................
  rejectTemplate: async (id: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/reject/template `
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .............................publish workflow...................................................
  publishWorkflow: async (id: string, params: string) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/publish/work_flow`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // .............................Change template name...................................................
  // updateTemplateName: async (id: string, params: any) => {
  //   try {
  //     const response = await put(`${BASE_URL}/templates/${id}`, params);
  //     return response.data;
  //   } catch (error) {
  //     return {
  //       error: true,
  //       message: error,
  //     };
  //   }
  // },

  updateTemplateName: async (id: string, params: any) => {
    let res = await put(`${BASE_URL}/templates/${id}`, params);
    return res;
  },

  // .............................Change contract name...................................................
  // updateContractName: async (id: string, params: any) => {
  //   try {
  //     const response = await put(`${BASE_URL}/contracts/${id}`, params);
  //     return response.data;
  //   } catch (error) {
  //     return {
  //       error: true,
  //       message: error,
  //     };
  //   }
  // },

  updateContractName: async (id: string, params: any) => {
    let res = await put(`${BASE_URL}/contracts/${id}`, params);
    return res;
  },

  // -----------------------------DownLoad
  downloadContract: (id: string) => {
    return get(`${BASE_URL}/contracts/${id}/download`);
  },

  downloadTemplate: (id: string) => {
    return get(`${BASE_URL}/templates/${id}/download`);
  },

  // .....................................stamp contract...............................
  stampContracts: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/stamp`,
        params
      );
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ........................remove approval.............
  deleteApprovers: async (id: string, approverId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/remove/approver/${approverId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ........................remove templates.............
  deleteTemplateApprovers: async (id: string, approverId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/remove/approver/${approverId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  deleteCounterparty: async (id: string, counterpartyId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/remove/counterparty/${counterpartyId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  deleteSignee: async (id: string, signeeId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/remove/signee/${signeeId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // -----------------------------------------------------------------Members-----------------------------

  listmember: (params: any) =>
    get(`${BASE_URL}/users?${filterQueryParams(params)}`),

  inviteMember: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/users/invite`, params);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  editMember: async (id: string, params: any) => {
    try {
      const response = await put(`${BASE_URL}/users/${id}`, params);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // verifyToken: (token: any) => get(`${BASE_URL}/users/${token}/info`),

  verifyToken: (token: any) => {
    return get(`${BASE_URL}/users/${token}/info`);
  },

  // verifyToken: async (token: any) =>
  //   get(`${BASE_URL}/users/${token}/info`, null),
  verifyTokenpost: (token: any, values: any) =>
    post(`${BASE_URL}/users/${token}/accept_invitation`, values),

  // ...............................publish template workflow...........................

  publishTemplateWorkflow: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/${id}/publish/work_flow`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ....................contract edit workflow...................................
  editContractWorkflow: async (id: string) => {
    try {
      const response = await put(`${BASE_URL}/contracts/${id}/workflow`);
      return response.data;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ........................contract to template api....................
  contractToTemplateClone: async (id: string) => {
    try {
      const response = await post(`${BASE_URL}/contracts/${id}/clone/template`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ...................update the workflow for template..........................

  updateTmplateWorkflow: async (id: string, params: any, approveid: string) => {
    try {
      const response = await put(
        `${BASE_URL}/templates/${id}/approver/${approveid}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ...................edit workflow.............................................
  editTemplateWorkflow: async (id: string) => {
    try {
      const response = await post(`${BASE_URL}/templates/${id}/edit/work_flow`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ..........................is mendatory approver api..........................
  updateApprovalList: (id: string, body: any, approveid: string) =>
    put(`${BASE_URL}/contracts/${id}/approver/${approveid}`, body),

  // .................contract status update............

  UpdatePublishContract: (id: string) =>
    put(`${BASE_URL}/v1/contracts/${id}/publish`),

  // ...............folders......................................................

  getListFolder: async () => {
    try {
      const response = await get(`${BASE_URL}/folders/root_folder`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  addFolders: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/folders`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ......................move template to folder.........................
  moveToFolderTemplate: async (params: any, folderId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/move/folder/${folderId}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ......................move template to folder.........................
  moveToFolderContract: async (params: any, folderId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/move/folder/${folderId}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  moveContractToFolder: async (params: any, folderId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/move/folder/${folderId}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getFolderById: async (id: string, params: any) => {
    try {
      let response = await get(
        `${BASE_URL}/folders/${id}?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {}
  },
  // ........................root folder.....................................

  getrootFolder: async () => {
    try {
      const response = await get(`${BASE_URL}/folders/root_folder`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .....................delete folder.........................
  deleteFolder: async (id: string) => {
    try {
      const response = await _delete(`${BASE_URL}/folders/${id}`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ...........................move folder.................
  moveFolder: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/folders/moveFolder`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ....................share folder...........................
  shareFolder: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/folders/${id}/add_users`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  // .......................update folder.......................
  updateFolder: (id: string, params: any) =>
    put(`${BASE_URL}/folders/${id}`, params),

  // ...............................................version history...............................................
  getDocumentVersion: async (id: string, params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/documents/${id}/document_versions?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  updateDocumentName: async (id: string, versionId: string, params: any) => {
    try {
      const response = await put(
        `${BASE_URL}/documents/${id}/document_versions/${versionId}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  restoreDocumentVersion: async (id: string, version_id: string) => {
    try {
      const response = await post(
        `${BASE_URL}/documents/${id}/rollback/document_version/${version_id}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  currentVersion: async (id: string) => {
    try {
      const response = await get(`${BASE_URL}/documents/${id}`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getVersionById: async (id: string, version_id: string) => {
    try {
      const response = await get(
        `${BASE_URL}/documents/${id}/document_versions/${version_id}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  CreateNewVersionForTemplate: async (versionId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/templates/create/new/${versionId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  CreateNewVersionForContract: async (versionId: string) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/create/new/${versionId}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ..........................transfer ownership....................................
  ContractTransferOwnerShip: (userId: string, params: any) =>
    post(`${BASE_URL}/contracts/tranfer/ownership/${userId}`, params),

  TemplateTransferOwnerShip: (userId: string, params: any) =>
    post(`${BASE_URL}/templates/tranfer/ownership/${userId}`, params),

  // ...........................negotiation workflow.....................................
  UpdateNegotiationWorkflow: (id: string, params: any) =>
    post(`${BASE_URL}/v1/contracts/${id}/approve/negotitation`, params),
  AddNegotiationCollaborators: (id: string, params: any) =>
    post(`${BASE_URL}/contracts/${id}/add/counter_party`, params),

  // ........................comments....................................
  getComments: async (id: string, params: ITemplateFilterParams) => {
    try {
      return await get(
        `${BASE_URL}/documents/${id}/comments?${filterQueryParams(params)}`
      );
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  renegotiation: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/owner/approveNegotiation`,
        params
      );
      return response;
    } catch ({ response }: any) {}
  },

  reNegtiation: async (id: string) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/re-negotiation`
      );
      return response;
    } catch ({ response }: any) {}
  },

  negotiationViewerApprove: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/viewer/negotitation`,
        params
      );
      return response;
    } catch ({ response }: any) {}
  },

  // ...........last edited on....

  lastedUpdatedDocument: async (id: string) => {
    try {
      const response = await put(`${BASE_URL}/contracts/${id}/editor`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .............edit negotiation workflow...............
  editNgWorkflow: (id: any) =>
    put(`${BASE_URL}/v1/contracts/${id}/start_negotitation`),
  // ...............add coordinate.................................
  addCoordinate: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/contracts/${id}/addCoOrdinates`,
        params
      );
      return response;
    } catch ({ response }: any) {}
  },

  documentSign: async (id: string, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/v1/contracts/${id}/initiate/signing`,
        params
      );
      return response;
    } catch ({ response }: any) {}
  },

  // .............................dashboard............................................
  getOverallStats: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/overall_stats?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getContractType: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/contract_type?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getCOuntSignedContractByType: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/count_signed_contracts_by_type?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getContractByContractValue: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/top_valued_contracts_by_type?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getDaysInNegotiation: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/days_in_negotitation?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getContractCycleTime: async () => {
    try {
      const response = await get(`${BASE_URL}/stats/contract_cycle_time`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getSignContractTurnaround: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/signed_contract_tat?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getSignedCountContractByTeams: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/count_signed_contracts_by_team?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getcountRequiringApproval: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/count_requiring_approval?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  getContractInitiatedByEachTeam: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/teams_number_of_contracts?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getContractsByCounterparty: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/top_valued_contracts_by_counter_party?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getTurnaroundTimeForApproval: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/turnaround_time_for_approvals?${filterQueryParams(
          params
        )}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getAvgExpiryType: async (params: any) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/avg_expiry_type?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  addMatrics: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/stats/add/metrics`, params);
      return response;
    } catch ({ response }: any) {}
  },

  getMatrics: async () => {
    try {
      const response = await get(`${BASE_URL}/stats/metrics`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  updateMatrics: async (params: any) => {
    try {
      const response = await put(`${BASE_URL}/stats/metrics`, params);
      return response;
    } catch ({ response }: any) {}
  },
  getStatus: async () => {
    try {
      const response = await get(`${BASE_URL}/contracts/config`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
  getCounterpartyList: async (params: ITemplateFilterParams) => {
    try {
      const response = await get(
        `${BASE_URL}/stats/counter_parties?${filterQueryParams(params)}`
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  deleteMetrics: async (params: any) => {
    try {
      return _delete(`${BASE_URL}/stats/Metrics`, params);
    } catch (error) {
      return "";
    }
  },

  getContractHirerchy: async (id: string) => {
    try {
      const response = await get(`${BASE_URL}/contracts/${id}/hierarchy`);
      return response;
    } catch (error) {}
  },

  deleteContractHirerchy: async (id: string) => {
    try {
      return _delete(`${BASE_URL}/contracts/${id}/parentContract`);
    } catch (error) {
      return "";
    }
  },

  // ...........signature............................................
  getUserSignature: async () => {
    try {
      const response = await get(`${BASE_URL}/users/sign`);
      return response;
    } catch (error) {}
  },

  uploadUserSignature: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/users/signature`, params);
      return response;
    } catch ({ response }: any) {}
  },

  removeMySignature: async (params: any) => {
    try {
      return _delete(`${BASE_URL}/users/signature`, params);
    } catch (error) {
      return "";
    }
  },
  // .......................notification...............................
  UpdateDeviceToken: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/users/fcm`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  getNotificationData: async (page = 1) => {
    try {
      const response = await get(`${BASE_URL}/notification?page=${page}`);
      return response;
    } catch (error) {}
  },

  updateNotificationData: async (params: any) => {
    try {
      const response = await put(`${BASE_URL}/notification`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  uploadContract: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/contracts/upload`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  uploadTemplate: async (params: any) => {
    try {
      const response = await post(`${BASE_URL}/templates/upload`, params);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // .........................resend invite members.........
  resendInviteMember: async (id: any, params: any) => {
    try {
      const response = await post(
        `${BASE_URL}/users/resend-invite/${id}`,
        params
      );
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  // ...............roles and access...........
  getUserRoles: (role: string) => get(`${BASE_URL}/roles/${role}`),

  getUserRolesConfig: () => get(`${BASE_URL}/roles/config`),
  getRolesDetails: () => get(`${BASE_URL}/roles`),
  updateRoles: (params: any) => put(`${BASE_URL}/roles`, params),

  memberBlock: async (id: any) => {
    try {
      const response = await post(`${BASE_URL}/users/${id}/block`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },

  memberUnBlock: async (id: any) => {
    try {
      const response = await post(`${BASE_URL}/users/${id}/unblock`);
      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  },
};

export const adminApi = {
  adminSignIn: async (params: { username: string; password: string }) => {
    try {
      const res = await post(`${BASE_URL}/admin-auth/login`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      } else if (res?.statusCode === 401) {
        return {
          error: true,
          message: ["Incorrect Email Address or password."],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  adminforgetPassword: (params: any) =>
    post(`${BASE_URL}/admin/forget_password`, params),

  adminresetPassword: (params: any, token: string) =>
    post(`${BASE_URL}/admin/${token}/reset_password`, params),
};
