import { ITemplateFilterParams } from "../interfaces/ITemplateFilterParams";
import { ListContractContextInterface } from "../modules/Contracts/ListContractProvider";
import { ListFoldersContextInterface } from "../modules/Folders/FoldersProvider";
import { MemberContextInterface } from "../modules/Members/MemberProvider";
import { ListTemplateContextInterface } from "../modules/Templates/ListTemplatesProvider";
import CommonAsyncSelect from "./CommonAsyncSelect";

function Department({
  context,
  setFilterParams,
  filterParams,
}: {
  context?:
    | ListContractContextInterface
    | ListTemplateContextInterface
    | MemberContextInterface
    | ListFoldersContextInterface;
  setFilterParams: Function;
  filterParams: ITemplateFilterParams;
}) {
  return (
    <>
      {/* Department */}
      <div className="row status-title mb-1 mt-2">Department</div>

      <CommonAsyncSelect
        className={"select-wrapper"}
        options={context?.departmentList?.map((item) => ({
          label: item,
          value: item,
        }))}
        value={
          {
            label: filterParams?.department || "",
            value: filterParams?.department || "",
          } || ""
        }
        updateField={(option: any) => {
          setFilterParams({
            ...filterParams,
            department: option.value,
          });
        }}
        isMulti={false}
        loadOptionValue={undefined}
      />
    </>
  );
}

export default Department;
