import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import SuccessToast from "../../../ui/SuccessToast";
import FailureToast from "../../../ui/FailureToast";
import FormikField from "../../../ui/FormikField";
import FormikSubmitButton from "../../../ui/FormikSubmitButton";
import { useParams } from "react-router-dom";
import { adminApi } from "../../../utils/adminApi";
import { useAdminAccounts } from "./AdminAccountProvider";

interface CompanyInterface {
  name?: string | "";
  logo?: any | "";
  location?: string | "";
  website?: string | "";
  start_date?: string | "";
  contact_person?: string | "";
  contact_number?: string | "";
  linkedin?: string | "";
  facebook?: string | "";
  youtube?: string | "";
  instagram?: string | "";
  twitter?: string | "";
  team_size?: string | "";
  industry?: string | "";
}

const AccountInformation = () => {
  const context: any = useAdminAccounts();
  const { accountId }: any = useParams();
  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);

  // [TODO] Role support needed from backend
  const initialValues: CompanyInterface = {
    name: (context && context?.accountInfo?.name) || "",
    logo: (context && context?.accountInfo?.logo) || "",
    location:
      context?.accountInfo?.members?.find(
        (member: any) => member?.is_primary === "true"
      )?.location || "",
    website: (context && context?.accountInfo?.website) || "",
    start_date: (context && context?.accountInfo?.start_date) || "",
    contact_person:
      context?.accountInfo?.members?.find(
        (member: any) => member?.is_primary === "true"
      )?.email || "",
    contact_number:
      context?.accountInfo?.members?.find(
        (member: any) => member?.is_primary === "true"
      )?.contact_number || "",
    linkedin: (context && context?.accountInfo?.linkedin) || "",
    facebook: (context && context?.accountInfo?.facebook) || "",
    youtube: (context && context?.accountInfo?.youtube) || "",
    instagram: (context && context?.accountInfo?.instagram) || "",
    twitter: (context && context?.accountInfo?.twitter) || "",
    team_size: (context && context?.accountInfo?.team_size) || "",
    industry: (context && context?.accountInfo?.industry) || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("first Name is required"),
    logo: Yup.string().min(2, "Too Short!").max(1000),
    location: Yup.string().min(2, "Too Short!").max(1000),
    linkedin: Yup.string().min(2, "Too Short!").max(1000),
    start_date: Yup.string().min(2, "Too Short!").max(1000),
    contact_person: Yup.string().min(2, "Too Short!").max(1000),
    contact_number: Yup.string().min(2, "Too Short!").max(1000),
    website: Yup.string().min(2, "Too Short!").max(1000),
    facebook: Yup.string().min(2, "Too Short!").max(1000),
    youtube: Yup.string().min(2, "Too Short!").max(1000),
    instagram: Yup.string().min(2, "Too Short!").max(1000),
    twitter: Yup.string().min(2, "Too Short!").max(1000),
    team_size: Yup.string().min(2, "Too Short!").max(1000),
    industry: Yup.string().min(2, "Too Short!").max(1000),
  });
  // const obj = context?.accountInfo?.members?.find(
  //   (member: any) => member?.is_primary === "true"
  // );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const formData = new FormData();
      formData.set("name", values?.name);
      formData.set("location", values?.location);
      formData.set("linkedin", values?.linkedin);
      formData.set("start_date", new Date(values?.start_date).toISOString());
      formData.set("contact_person", values?.contact_person);
      formData.set("website", values?.website);
      formData.set("facebook", values?.facebook);
      formData.set("youtube", values?.youtube);
      formData.set("instagram", values?.instagram);
      formData.set("twitter", values?.twitter);
      formData.set("team_size", values?.team_size);
      formData.set("industry", values?.industry);
      formData.set("contact_number", phoneNumber);
      if (typeof values?.logo === "object") {
        formData.append("file", values?.logo);
      } else {
        formData.set("logo", values?.logo);
      }
      try {
        setLoader(true);
        const res = await adminApi.updateAccountInfo(accountId, formData);
        context?.getAccountInfo();
        setLoader(false);
        if (res?.status === 200) {
          setShowSuccessToast(true);
          setBodyMessage("Account Info Updated");
        }
      } catch (error) {}
    },
  });

  useEffect(() => {
    setPhoneNumber(
      (context &&
        context?.accountInfo?.members?.find(
          (member: any) => member?.is_primary === "true"
        )?.contact_number) ||
        ""
    );
  }, [context]);
  const { errors, handleSubmit, isSubmitting, values, resetForm } = formik;
  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate={true} onSubmit={handleSubmit}>
          <div className=" mx-3 p-3 " style={{ background: "#fefefe" }}>
            <div className={`${isMobile ? " pt-2" : "pt-4"}`}>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="name"
                    type="text"
                    validationSchema={validationSchema}
                    label="Company Name"
                    placeholder="Company Name"
                    errors={errors}
                    autoFocus
                    value={values?.name}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <div className="row">
                    <div
                      className="d-flex justify-content-between align-item-center"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <label className="text-dark mt-3">Logo</label>
                      </div>
                      {context?.accountInfo?.logo && (
                        <div
                          className="position-relative fw-semibold mt-3 curser-pointer"
                          onClick={() =>
                            window.open(context?.accountInfo?.logo)
                          }
                        >
                          View Logo
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type="file"
                    className="form-control mt-2"
                    onChange={(e: any) =>
                      formik?.setFieldValue("logo", e.target.files[0])
                    }
                    accept="image/png, image/jpeg, image/jpg"
                    // disabled={isEdit}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="team_size"
                    type="text"
                    validationSchema={validationSchema}
                    label="Team Size"
                    errors={errors}
                    placeholder="Team Size"
                    value={values?.team_size}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="contact_person"
                    type="text"
                    validationSchema={validationSchema}
                    label="Contact Person"
                    placeholder="Contact Person"
                    errors={errors}
                    value={values?.contact_person}
                    isDisabled
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="contact_number"
                    type="text"
                    validationSchema={validationSchema}
                    label="Contact Person"
                    placeholder="Contact Person"
                    errors={errors}
                    value={values?.contact_number}
                    isDisabled
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="location"
                    type="text"
                    validationSchema={validationSchema}
                    label="Location"
                    placeholder="Location"
                    errors={errors}
                    value={values?.location}
                    isDisabled
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12 mt-3">
                  <div className="from-control w-100">
                    <div className="row status-title mb-2 ">
                      Establishment on
                    </div>
                    <DatePicker
                      className=" w-100 from-control"
                      value={
                        values?.start_date
                          ? moment(values?.start_date).toDate()
                          : ""
                      }
                      onChange={(date: any) => {
                        formik?.setFieldValue("start_date", date);
                      }}
                      // minDate={moment().toDate()}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="industry"
                    type="text"
                    validationSchema={validationSchema}
                    label="Industry"
                    placeholder="Industry"
                    errors={errors}
                    value={values?.industry}
                  />
                </div>
              </div>

              <div className="py-3">
                <h6 className=" fw-semibold m-0">Social media</h6>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="linkedin"
                    type="text"
                    validationSchema={validationSchema}
                    label="Linkedin"
                    placeholder="Linkedin"
                    errors={errors}
                    value={values?.linkedin}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="facebook"
                    type="text"
                    validationSchema={validationSchema}
                    label="Facebook"
                    placeholder="Facebook"
                    errors={errors}
                    value={values?.facebook}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="youtube"
                    type="text"
                    validationSchema={validationSchema}
                    label="Youtube"
                    errors={errors}
                    placeholder="Youtube"
                    value={values?.youtube}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="instagram"
                    type="text"
                    validationSchema={validationSchema}
                    label="Instagram"
                    placeholder="Instagram"
                    errors={errors}
                    value={values?.instagram}
                  />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 col-12">
                  <FormikField
                    name="website"
                    type="text"
                    validationSchema={validationSchema}
                    label="Website"
                    placeholder="Website"
                    errors={errors}
                    value={values?.website}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <FormikField
                    name="twitter"
                    type="text"
                    validationSchema={validationSchema}
                    label="Twitter"
                    placeholder="Twitter"
                    errors={errors}
                    value={values?.twitter}
                  />
                </div>
              </div>
            </div>
            {/* {context?.companyDetails?.logo && (
            <>
              <div
                className="position-relative fw-semibold mt-3 curser-pointer"
                onClick={() => window.open(context?.companyDetails?.logo)}
              >
                View Logo
              </div>
              <div
                style={{
                  color: "#000",
                  borderRight: "2px solid #305B61",
                }}
              >
                {context?.companyDetails?.logo &&
                  decodeURIComponent(context?.companyDetails?.logo)}
              </div>
            </>
          )} */}
            <div className="d-flex justify-content-end pt-4">
              {" "}
              <Button
                className="cancel-hallowbtn me-3"
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
              <FormikSubmitButton
                isSubmitting={isSubmitting}
                className="updatebtndanger"
                size="md"
              >
                <span className="SignText">
                  Update Profile {loader && <span className="loader"></span>}
                </span>
              </FormikSubmitButton>
            </div>
          </div>
        </Form>
      </FormikProvider>

      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
          message="Invalid email or password."
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
        />
      )}
    </>
  );
};

export default AccountInformation;
