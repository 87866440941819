import "../../comments/src/component/CommentThreadPopover.css";
import { ReactEditor, useSlate } from "slate-react";
import {
  activeSuggestionThreadIDAtom,
  suggestionThreadIDsState,
} from "../utils/SuggestionState";
import { useCallback, useEffect, useState } from "react";
import { useRecoilStateLoadable, useSetRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import CommentRow from "../../comments/src/component/CommentRow";
import { Editor, Text, Transforms } from "slate";
import NodePopover from "../../comments/src/component/NodePopover";
import { getFirstTextNodeAtSelection } from "../../comments/src/utils/EditorUtils";
import { getSuggestionThreadsOnTextNode } from "../utils/EditorSuggestionUtils";
import { useEditor as context } from "../../../EditorProvider";
import { useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiSolidSend } from "react-icons/bi";
import AvatarSection from "../../../../ui/Avatar";
import { MentionsInput } from "react-mentions";
import { Mention } from "react-mentions";
import { IUserResponse } from "../../../../interfaces/response/IUserResponse";
import { useAuthentication } from "../../../../modules/Authentication/AuthenticationProvider";
import { Icon } from "../../components";
import { RxCross1 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { commentThreadsState } from "../../comments/src/utils/CommentState";

export default function SuggestionThreadPopover({
  editorOffsets,
  selection,
  threadID,
}: any) {
  const editor = useSlate();
  const { id } = useParams();
  const auth = useAuthentication();
  const editorContext = context();
  const authContext = useAuthentication();
  const [currentAccess, setCurrentAccess] = useState("PUBLIC");
  const [notify, setNotify] = useState(false);
  const [accessShow, setAccessShow] = useState(false);
  const [collaboratorsLoading, setCollaboratorsLoading] = useState(false);
  const [collaborators, setCollaborators] = useState<any>([{}]);
  const [externalUsers, setExternalUsers] = useState<any>([{}]);
  const [owner, setOwner] = useState();

  useEffect(() => {
    getCollaboratorsFromContract();
  }, []);

  const [mentionsCollaboration, setMentionsCollaboration] = useState<
    IUserResponse | undefined | any
  >();

  const getCollaboratorsFromContract = async () => {
    setCollaboratorsLoading(true);
    const res = await editorContext?.getContractCollaborators(id);
    const { contract, success } = res || {};
    if (success) {
      // setOwner(contract?.created_by?.sub);
      setOwner(contract?.owner?.sub);
      const combinedArray = [
        ...contract?.collaborators,
        ...contract?.approvers,
        ...contract?.external_users,
        // ...contract?.internal_signer,
        // ...contract?.verifiers,
      ];
      let combinedMapArray = combinedArray?.map((user: any) => ({
        id: user?.user?.sub,
        display: user?.user?.name,
      }));
      setCollaborators(contract?.collaborators);
      setExternalUsers(contract?.external_users);

      uniqueArray(combinedMapArray);
    }
    setCollaboratorsLoading(false);
  };

  const uniqueArray = (users: any) => {
    let uniqueArrays = users.filter((obj: any, index: number) => {
      return index === users.findIndex((o: any) => obj.id === o.id);
    });
    setMentionsCollaboration(uniqueArrays);
    setMentionsUUID(mentionsCollaboration?.map((item: any) => item?.id));
  };

  var mentions = mentionsCollaboration?.map((user: any) => ({
    id: user.id,
    display: user.display,
  }));

  const textNode: any = getFirstTextNodeAtSelection(editor, selection);
  const setActiveSuggestionThreadID = useSetRecoilState(
    activeSuggestionThreadIDAtom
  );
  const [threadDataLoadable, setCommentThreadData] = useRecoilStateLoadable(
    commentThreadsState(threadID)
  );
  const [threadIds, setThreadIds] = useRecoilStateLoadable(
    suggestionThreadIDsState
  );
  const [commentText, setCommentText] = useState("");
  const [mentionsUUID, setMentionsUUID] = useState<(string | undefined)[]>();
  const [cleanedText, setCleanedText] = useState("");

  useEffect(() => {
    getUUIDFromCommentText();
    filterOnlyNameFromCommentedText();
    threadData?.comments.length === 0
      ? setAccessShow(true)
      : setAccessShow(false);

    threadData?.comments[0]?.access === "TEAMS" && setCurrentAccess("MYTEAM");

    threadData?.comments[0]?.access === "PUBLIC" &&
      setCurrentAccess("EXTERNAL");

    threadData?.comments[0]?.access === "PRIVATE" && setCurrentAccess("ME");
  }, [commentText]);

  const getUUIDFromCommentText = () => {
    const text = commentText;
    const uuidRegex = /\(([^)]+)\)/g;

    const uuids = [];
    let match;
    while ((match = uuidRegex.exec(text))) {
      const uuid = match[1];
      uuids.push(uuid);
    }
    uuids.push(auth?.currentUser?.id);
    setMentionsUUID(uuids);
  };

  const filterOnlyNameFromCommentedText = () => {
    const cleanedText1 = commentText.replace(/\[|\]|\([^)]+\)/g, "");
    setCleanedText(cleanedText1);
  };

  const onClick = () => {
    onCommentAdd();
    setCommentThreadData((threadData: any) => ({
      ...threadData,
      comments: [
        ...threadData?.comments,
        {
          text: commentText,
          author: auth?.currentUser?.name,
          creationTime: new Date(),
        },
      ],
    }));
    setCommentText("");
  };

  const onCommentAdd = async () => {
    console.log(mentionsUUID, "uuidsuuids");
    try {
      const params = {
        value: [cleanedText],
        ref_id: threadID,
        parent_id: threadID,
        access: "PUBLIC",
        // mentions: currentAccess === "ME" ? [] : mentionsUUID,
        mentions: mentionsUUID,
        is_notify: notify,
      };
      await editorContext?.addComment(params);
      setNotify(false);
    } catch (error) {}
  };

  useEffect(() => {}, [threadIds]);

  const commentDelete = async () => {
    setActiveSuggestionThreadID(null);

    setThreadIds((prevState: any) => {
      const arrayFromSet = [...prevState];
      const filteredArray = arrayFromSet.filter((item) => item !== threadID);
      return new Set(filteredArray as any);
    });
    Editor.removeMark(editorContext?.editor, "commentThread_" + threadID);
    try {
      await editorContext?.deleteComment(threadID);
    } catch (error) {}
  };

  const onCommentResolved = async () => {
    try {
      const params = {
        is_resolved: true,
      };
      await editorContext?.resolveComment(threadID, params);
    } catch (error) {}
  };

  const onCommentReOpen = async () => {
    try {
      const params = {
        is_resolved: false,
      };
      await editorContext?.resolveComment(threadID, params);
    } catch (error) {}
  };

  const onToggleStatus = useCallback(() => {
    const currentStatus = threadDataLoadable.contents.status;
    setCommentThreadData((threadData: any) => ({
      ...threadData,
      status: currentStatus === false ? true : false,
    }));
    currentStatus === false && onCommentResolved();
    currentStatus === true && onCommentReOpen();
  }, [setCommentThreadData, threadDataLoadable?.contents?.status]);

  const onCommentTextChange = useCallback(
    (event: any) => setCommentText(event.target.value),

    [setCommentText]
  );

  const onClickOutside = useCallback(
    (event: any) => {
      const slateDOMNode = event.target.hasAttribute("data-slate-node")
        ? event.target
        : event.target.closest(`[data-slate-node]`);

      // The click event was somewhere outside the Slate hierarchy

      // if (slateDOMNode == null) {
      //   setActiveSuggestionThreadID(null);
      //   return;
      // }

      const slateNode = ReactEditor.toSlateNode(editor, slateDOMNode);

      // Click is on another commented text node => do nothing.
      if (
        Text.isText(slateNode) &&
        getSuggestionThreadsOnTextNode(slateNode).size > 0
      ) {
        return;
      }

      setActiveSuggestionThreadID(null);
    },
    [editor, setActiveSuggestionThreadID]
  );

  const hasThreadData = threadDataLoadable.state === "hasValue";
  const threadData = threadDataLoadable?.contents;

  return (
    <>
      {collaboratorsLoading ? (
        <span>Loading...</span>
      ) : (
        <NodePopover
          editorOffsets={editorOffsets}
          isBodyFullWidth={true}
          node={textNode}
          className={""}
          header={
            <Header
              setActiveSuggestionThreadID={setActiveSuggestionThreadID}
              node={textNode}
              action={
                Object?.keys(textNode)?.includes("SuggestionDelete")
                  ? "Delete"
                  : "Add"
              }
              text={textNode?.text}
              externalUsers={externalUsers}
              collaborators={collaborators}
              status={threadData?.status ?? null}
              shouldAllowStatusChange={
                hasThreadData && threadData?.comments?.length > 0
              }
              onToggleStatus={onToggleStatus}
              currentCommentID={threadID}
              context={editorContext}
              commentDelete={commentDelete}
              currentAccess={currentAccess}
              setCurrentAccess={setCurrentAccess}
              accessShow={accessShow}
              authContext={authContext}
              owner={threadData?.comments[0]?.authorID}
              contractOwner={owner}
            />
          }
          onClickOutside={onClickOutside}
        >
          <div className="comment-list ">
            <div
              className=" w-100 px-2"
              style={{
                maxHeight: "40vh",
                zIndex: 1,
                overflowY: "scroll",
                paddingBottom: "90px",
              }}
            >
              {threadData?.comments?.map((comment: any, index: any) => {
                return (
                  <div>
                    <CommentRow
                      k={index}
                      count={10}
                      comment={comment}
                      threadID={threadID}
                      context={editorContext}
                      authContext={authContext}
                      mentions={mentions}
                      status={threadData?.status}
                      onToggleStatus={onToggleStatus}
                      commentDelete={commentDelete}
                      showVerticalDots={true}
                    />
                  </div>
                  // )
                );
              })}
            </div>
            {!threadData?.status && (
              <div className=" commentBoxSnd px-3 border-top">
                <div className="  d-flex  align-items-center py-3 position-relative">
                  <div className="me-3">
                    <AvatarSection
                      imageData={""}
                      name={authContext?.currentUser?.name}
                    />
                  </div>

                  <div
                    className=" d-flex align-items-center flex-grow-1"
                    style={{ width: "290px" }}
                  >
                    {currentAccess === "ME" ? (
                      <>
                        <textarea
                          className="doc_searchQueryInput form-control"
                          name="doc_searchQueryInput"
                          value={commentText}
                          onChange={onCommentTextChange}
                          placeholder="Enter your text"
                          maxLength={2048}
                          style={{ minHeight: "10px" }}
                        />
                      </>
                    ) : (
                      <div className=" w-100 mb-4">
                        <MentionsInput
                          className="  mentionsInput form-control"
                          type="text"
                          bsPrefix={"comment-input"}
                          value={commentText}
                          onChange={onCommentTextChange}
                          placeholder="Enter your text"
                          a11ySuggestionsListlabel={"Suggested Mentions"}
                        >
                          <Mention data={mentions} trigger={"@"} />
                        </MentionsInput>
                      </div>
                    )}
                  </div>
                  <div className=" resolve commentSndBtn ms-2 ">
                    <Button
                      className="resolvebtn p-0"
                      size="sm"
                      disabled={commentText.length === 0}
                      onClick={onClick}
                    >
                      <BiSolidSend color="" fontSize={24} />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {hasThreadData ? (
            <>
              <div></div>
            </>
          ) : (
            "Loading"
          )}
        </NodePopover>
      )}
    </>
  );
}

function Header({
  node,
  action,
  text,
  setActiveSuggestionThreadID,
  commentDelete,
  authContext,
  contractOwner,
}: any) {
  const editor = useSlate();
  const suggestionKeys = Object.keys(node).filter((key) =>
    key.startsWith("suggestion_")
  );
  const uuids = suggestionKeys.map((key) => key.replace("suggestion_", ""));

  const onClickApprove = () => {
    if (action === "Add") {
      Transforms.unsetNodes(
        editor,
        [
          "suggestion_" + uuids[0],
          "suggestion_" + uuids[1],
          "color",
          "suggestionStikeThrough",
          "SuggestionAdd",
          "SuggestionDelete",
        ],
        {
          match: (n) =>
            Text?.isText(n) && getSuggestionThreadsOnTextNode(n)?.has(uuids[0]),
        }
      );
      setActiveSuggestionThreadID(null);
    } else if (action === "Delete") {
      Transforms.removeNodes(editor, {
        match: (n) =>
          Text?.isText(n) && getSuggestionThreadsOnTextNode(n)?.has(uuids[0]),
      });
      setActiveSuggestionThreadID(null);
    }
  };
  const onClickDelete = () => {
    if (action === "Add") {
      Transforms.removeNodes(editor, {
        match: (n) =>
          Text?.isText(n) && getSuggestionThreadsOnTextNode(n)?.has(uuids[0]),
      });
      setActiveSuggestionThreadID(null);
    } else if (action === "Delete") {
      Transforms.unsetNodes(
        editor,
        [
          "suggestion_" + uuids[0],
          "suggestion_" + uuids[1],
          "color",
          "suggestionStikeThrough",
          "SuggestionAdd",
          "SuggestionDelete",
        ],
        {
          match: (n) =>
            Text?.isText(n) && getSuggestionThreadsOnTextNode(n)?.has(uuids[0]),
        }
      );
      setActiveSuggestionThreadID(null);
    }
  };
  return (
    <div className="suggestion-thread-popover-header d-flex justify-content-between">
      <div>
        {/* <strong>{action}</strong> :<i> "{text}" </i> */}
        <strong>{action}</strong> :
        <i> "{text.length > 10 ? text.substring(0, 10) + "..." : text}" </i>
      </div>
      <div className="d-flex">
        {authContext?.currentUser?.id === contractOwner && (
          <>
            <Icon>
              <TiTick size={30} onClick={() => onClickApprove()} />
            </Icon>
            <Icon className="ms-2 me-2">
              <RxCross1 onClick={() => onClickDelete()} />
            </Icon>

            {/* {authContext?.currentUser?.id === owner && ( */}
            <div className=" Actions">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="Actions_title"
                  id="dropdown-basic"
                >
                  <BsThreeDotsVertical fontSize={20} color="#c5d0de" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="create-dropdown-css">
                  <>
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => commentDelete()}
                    >
                      Delete
                    </Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </div>

      {/* )} */}
    </div>
  );
}
