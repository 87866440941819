import React, { ReactNode } from "react";
import { isMobile } from "react-device-detect";
import AppHeader from "./AppHeader";
import "./AppLayout.scss";
import AppSideBar from "./AppSidebar";

interface AppLayoutProps {
  pageTitle?: string;
  children: ReactNode;
}

const AppLayout = ({ pageTitle, children }: AppLayoutProps) => {
  React.useEffect(() => {
    document.title = pageTitle || "CLM";
  }, [pageTitle]);

  return (
    <AppHeader>
      <div>
        <div className={isMobile ? "d-none" : ""}>
          <AppSideBar>
            <div className=" row">
              <div className="col-12"> {children}</div>
            </div>
          </AppSideBar>
        </div>

        <div className="pb-5 row d-lg-none" style={{ paddingTop: "60px" }}>
          <div className="col-12"> {children}</div>
        </div>
      </div>
    </AppHeader>
  );
};

export default AppLayout;
