import { Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { TableColumnsInterface } from "../../../interfaces/TableColumnsInterface";

import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import NoDataFound from "../../../ui/NoDataFound";
import DeleteToast from "../../../ui/DeleteToast";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";
import { updateText } from "../../../utils/index";

function MembersTable({
  memberList,
  setPage,
  setLimit,
  metaData,
  deleteMember,
  setDeleteId,
  deleteId,
  setMemberDetail,
  setSelectedMemberId,
  setMemberDetails,
  setShowFailureToast,
  setShowSuccessToast,
  setBodyMessage,
  getListMembers,
  setIsResendInvite,
}: any) {
  const authContext = useAuthentication();
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();
  const [rolesRead, setRolesRead]: [any, Function] = useState();
  const [alertToast, setAlertToast]: [any, Function] = useState();

  useEffect(() => {
    let role: any =
      authContext?.currentUser?.roles?.[0] === "Admin"
        ? "ADMIN"
        : authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesRead(guestRole?.MEMBERS?.find((el: any) => el === "READ"));
      setRolesUpdate(guestRole?.MEMBERS?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.MEMBERS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  const [bloackId, setBlockId]: [any, Function] = useState();
  const [unblockId, setUnBlockId]: [any, Function] = useState();

  const handleMemberBlock = async () => {
    try {
      let response = await authContext?.handleMemberBlock(bloackId);

      if (response?.data?.success) {
        setShowSuccessToast(true);
        setBodyMessage(response?.data?.Message);
        getListMembers();
        setAlertToast(false);
      } else {
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
      }
    } catch (error) {}
  };

  const handleMemberUnBlock = async () => {
    try {
      let response = await authContext?.handleMemberUnBlock(unblockId);
      if (response?.data?.success) {
        setShowSuccessToast(true);
        setBodyMessage(response?.data?.Message);
        setAlertToast(false);

        getListMembers();
      } else {
        setShowFailureToast(true);
        setBodyMessage(response?.data?.error?.[0]);
      }
    } catch (error) {}
  };

  const columns: TableColumnsInterface[] = [
    {
      name: "Avatar",
      minWidth: "100px",
      cell: (row: any) => {
        return (
          <Avatar
            name={row?.name}
            src={row?.avatar}
            size="50"
            className="avatar_css_members"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row?.email?.charAt(0)}
          />
        );
      },
      grow: 0,
      reorder: true,
      sortable: true,
    },
    {
      name: "Name",
      width: "200px",
      selector: (row: any) =>
        updateText(`${row?.first_name} ${row?.last_name}`),
      sortable: true,
      grow: 0,
      reorder: true,
    },

    {
      name: "Email address",
      width: "250px",
      selector: (row: any) => row.email,
      sortable: true,
      grow: 0,
      reorder: true,
    },
    {
      name: "Role",
      width: "150px",
      selector: (row: any) =>
        row.roles
          ? row.roles?.map((el: any) => updateText(el?.replace("_", " ")))
          : "-",
      sortable: true,
      grow: 0,
      reorder: true,
    },
    {
      name: "Department",
      width: "150px",
      selector: (row: any) => {
        return (
          <>
            {updateText(row?.department === "CXOs" ? "CXO" : row?.department) ||
              "-"}
          </>
        );
      },
      sortable: true,
      grow: 0,
      reorder: true,
    },
    {
      name: "Updated on",
      width: "200px",
      selector: (row: any) =>
        moment(row?.createdTimestamp).format("DD MMM YYYY, h:mm:ss A"),

      sortable: true,
      grow: 0,
      reorder: true,
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="pt-2 Actions">
            <Dropdown drop="end">
              <Dropdown.Toggle
                variant="success"
                className="Actions_title"
                id="dropdown-basic"
              >
                <BsThreeDots fontSize={24} />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu className="create-dropdown-css">
                {
                  // authContext?.currentUser?.roles?.find(
                  //   (el: any) => el === "ADMIN"
                  // ) === "ADMIN" && (

                  <>
                    {rolesUpdate === "UPDATE" && (
                      <>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            setMemberDetails(true);
                            setSelectedMemberId(row?.id);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        {row?.emailVerified === "false" && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                setMemberDetails(true);
                                setIsResendInvite(true);
                                setSelectedMemberId(row?.id);
                              }}
                            >
                              Resend
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    )}
                  </>
                  // )
                }
                {rolesRead === "READ" && (
                  <>
                    {" "}
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        setMemberDetail(true);
                        setSelectedMemberId(row?.id);
                      }}
                    >
                      View
                    </Dropdown.Item>
                  </>
                )}

                {
                  // authContext?.currentUser?.roles?.find(
                  //   (el: any) => el === "ADMIN"
                  // ) === "ADMIN" && (
                  <>
                    {authContext?.currentUser?.email !== row?.email && (
                      <>
                        {" "}
                        {rolesUpdate === "UPDATE" && (
                          <>
                            {row?.is_active === "true" && (
                              <>
                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => {
                                    setAlertToast(true);
                                    setBlockId(row?.id);
                                    setUnBlockId(null);
                                  }}
                                >
                                  Block
                                </Dropdown.Item>
                              </>
                            )}
                            {row?.is_active === "false" && (
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  setAlertToast(true);
                                  setUnBlockId(row?.id);
                                  setBlockId(null);
                                }}
                              >
                                Unblock
                              </Dropdown.Item>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {authContext?.currentUser?.email !== row?.email && (
                      <>
                        {rolesDelete === "DELETE" && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                setDeleteId(row?.id);
                                setShowDeleteToast(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </>
                        )}
                      </>
                    )}
                  </>
                  // )
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <div className=" mb-5">
      <div className="memberlist">
        <div className="d-flex mt-4 mb-1  mx-ms-2">
          <h6 className="contract-title pb-1 m-0"> Recent Members</h6>
        </div>
        <div className=" pt-4">
          {memberList.length > 0 ? (
            <DataTable
              className="data-table"
              columns={columns}
              data={memberList}
              highlightOnHover
              progressComponent={<>Loading...</>}
              paginationDefaultPage={1}
              paginationPerPage={10}
              paginationTotalRows={metaData?.totalCount}
              pagination
              paginationServer
              onChangeRowsPerPage={(currentRowsPerPage: number) => {
                setLimit(currentRowsPerPage);
              }}
              onChangePage={(page: number) => {
                setPage(page);
                setLimit(10);
              }}
            />
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {alertToast && (
        <>
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => {
              if (bloackId) {
                handleMemberBlock();
              } else {
                handleMemberUnBlock();
              }
            }}
            title={`Are you sure you want to ${
              bloackId ? "block" : "unblock"
            } this member?`}
            closeText="No"
            submitText="Yes"
            onClose={() => {
              setAlertToast(false);
            }}
          />
        </>
      )}
      {showDeleteToast && (
        <DeleteToast
          onCross={() => {}}
          onSubmit={() => {
            deleteMember(deleteId);
            setTimeout(() => {
              setShowDeleteToast(false);
            }, 200);
          }}
          onClose={() => setShowDeleteToast(false)}
          title="Do you want to delete this member?"
          closeText="No, don't !"
          submitText="Yes, delete it."
        />
      )}
    </div>
  );
}

export default MembersTable;
