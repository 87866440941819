import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { IActivityFeedResponse } from "../../interfaces/response/IActivityFeedResponse";
import { IContractResponse } from "../../interfaces/response/IContractResponse";
import { ActivityFeed } from "../../lib/ActivityFeed";
import { Contract } from "../../lib/Contract";
import { api } from "../../utils/api";
import { Variable } from "../../lib/Variable";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import { ITemplateFilterParams } from "../../interfaces/ITemplateFilterParams";
import { Collaborator } from "../../lib/Collaborator";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
export interface ContractContextInterface {
  isTemplate: boolean;
  data: Contract;
  contract: Contract;
  updateName: Function;
  activities: Array<ActivityFeed> | null;
  getActivities: Function;
  inviteCollaborator: Function;
  getUserDetails: Function;
  refresh: Function;
  publishDocument: Function;
  showToolbar: boolean;
  setShowToolbar: Function;
  getUser: Function;
  handleStampContract: Function;
  variables: Array<Variable> | null;
  setVariables: Function;
  filterParams: ITemplateFilterParams;
  changeFilter: (params: ITemplateFilterParams) => void;
  template?: any;
  setDocumentId: Function;
  waterMarkText: string;
  setWaterMarktext: Function;
  getTemplateOwnerId: () => void;
  isOwner: (id: string) => boolean;
  updateCollaboratorsAccess: (collaborator_access: any) => Promise<void>;
  handleContractDownload: Function;
  removeAccess: (collaborator_id: string) => Promise<void>;
  handleDeleteContract: Function;
  getDocument: Function;
  handleRefresh: Function;
  onContracToTemplateClone: Function;
  transferOwnership: Function;
  updateData: Function;
  departmentList: [];
  categoriesAutocomplete: Function;
  defaultCategories: ITemplateCategory[];
  coordinate: any;
  setCoordinate: Function;
  handleAddCoordinate: Function;
  documentId: string;
  downloadStatus: boolean;
  handleDocumentSign: Function;
  handleUpdateContractDetail: Function;
  handleContractHirerchy: Function;
  handleDeleteContractHirerchy: Function;
  getVariablesType: Function;
  variableTypes: any;
  updateDocumentName: Function;
  getRecentDocuments: Function;
  handlePublishDoc: Function;
  categoryList: any;
}
const ContractContext = React.createContext<ContractContextInterface | null>(
  null
);
interface TemplatesProviderProps {
  children: ReactNode;
}
const ContractProvider = ({ children }: TemplatesProviderProps) => {
  const { id } = useParams();
  const location = useLocation();
  const [documentId, setDocumentId]: [string, Function] = useState("");
  const [waterMarkText, setWaterMarktext]: [string, Function] = useState("");
  const [contractDetail, setContract] = useState<Contract | null>(null);
  const [activities, setActivities] = useState<ActivityFeed[]>([]);
  const [showToolbar, setShowToolbar] = useState<boolean>(true);
  const [variables, setVariables]: [Variable | null, Function] = useState(null);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [variableTypes, setvariableTypes]: [any, Function] = useState();
  const [categoryList, setCategoryList]: [any, Function] = useState();

  const [defaultCategories, setDefaultCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);

  const [coordinate, setCoordinate]: [any, Function] = useState();

  const initialFilter: ITemplateFilterParams = useMemo(() => {
    const params: ITemplateFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    };
    return params;
  }, [id]);
  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);

  const isOwner = (id: string) => {
    if (!contractDetail) return false;
    return id === contractDetail?.createdBy?.sub ? true : false;
  };

  const getTemplateOwnerId = () => {
    if (!contractDetail) return;
    if (
      contractDetail.collaborators?.length === 1 &&
      !!contractDetail.collaborators?.length
    ) {
      return contractDetail.collaborators[0].user.sub;
    } else {
      return contractDetail.collaborators?.find(
        (s: Collaborator) => s.user.sub === contractDetail.createdBy?.sub
      );
    }
  };

  const handleCategoriesAutocomplete = useCallback(
    async (search: string): Promise<string[]> =>
      await api.getTemplatesCategoriesAutocomplete({ search: search }),
    []
  );

  const autoCategory = async () => {
    const res = await api.getCategoriesAutoComplete("");
    if (res) {
      setCategoryList(res);
    }
  };

  useEffect(() => {
    autoCategory();
  }, []);

  useEffect(() => {
    if (
      location?.search === "?publish=true" ||
      location.pathname.includes("workflow")
    ) {
      const loadData = async () => {
        const categories = await handleCategoriesAutocomplete("");

        setDefaultCategories(categories);
      };
      loadData();
    }
  }, [location?.search]);

  const updateCollaboratorsAccess = async (collaborator_access: any) => {
    await api.updateCollaboratorsinContract(id!, {
      collaborator_access,
    });
  };

  const removeAccess = async (collaborator_id: string) => {
    await api.removeCollaboratorAccessinContract(id!, collaborator_id);
    handleRefresh();
  };

  const getVariablesType = async () => {
    const res = await api.getVariableTypes();
    if (res.success) {
      setvariableTypes(res?.currency_type);
    }
  };

  const handleUpdateContractDetail = async (id: string, params: any) => {
    try {
      let response = await api.updateContractDetail(id, params);
      return response;
    } catch (error) {}
  };

  let docID: any = contractDetail?.documentId;
  const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

  useEffect(() => {
    if (docID) {
      getDocumentVariables(docID, filterParams);
    }
  }, [docID, filterParams]);
  useEffect(() => {
    const loadData = async () => handleDocumentVariables(docID);
    if (!!handleDocumentVariables && docID) {
      loadData();
    }
  }, [docID, initialFilter]);
  const getDocumentVariables = async (
    id: string,
    filterParams: ITemplateFilterParams
  ) => {
    let response = await api.getDocumentVariables(id, {
      ...filterParams,
      name: filterParams?.name ? filterParams?.name : "",
    });
    const _variable: Variable[] = response?.variables?.map(
      (variables: IVariableResponse) => new Variable(variables)
    );
    response.success && setVariables(_variable);
    return _variable;
  };
  const handleDocumentVariables = useCallback(
    async (id: string) => {
      const variable = await getDocumentVariables(id, {
        ...initialFilter,
        limit: 10,
        sort: "-updatedAt",
        name: initialFilter?.name ? initialFilter?.name : "",
      });
      setVariables(variable);
      return variable;
    },
    [initialFilter]
  );

  useEffect(() => {
    if (id) {
      handleRefresh();
      getVariablesType();
    }
  }, [id]);

  const handleActivities = async (id: string) => {
    let response = await api.getContractActivities(id);
    const activities: ActivityFeed[] = response.activities.map(
      (activityLogs: IActivityFeedResponse) => new ActivityFeed(activityLogs)
    );
    setActivities(activities);
  };

  const getUser = async () => {
    let res = await api.getUser();
    return res;
  };

  const handleInviteCollaborator = async (
    id: string,
    params: {
      collaborators_id: [];
      message: string;
      notify: boolean;
      access: string;
    }
  ) => {
    try {
      const response = await api.inviteContractCollaborators(id, params);
      return response;
    } catch (error) {}
  };

  const handleUpdateName = async (id: string, name: string) => {
    const response = await api.updateContractName(id, {
      name,
    });

    if (response?.data?.success) {
      setContract(response?.contract);
    }
    return response;
  };

  const handlePublishDoc = async (id: string) => {
    const response = await api.UpdatePublishContract(id);
    return response;
  };

  const getUserDetails = async () => {
    return await api?.getCollaborators({ limit: 100 });
  };

  const handleRefresh = async () => {
    if (!id) return;
    const {
      success,
      contract,
    }: { success: true; contract: IContractResponse } =
      await api.getContractsDetails(id);
    if (success) {
      setContract(new Contract(contract));
    }
  };

  const publishDocument = async (id: string, params: ITemplateFilterParams) => {
    const response = await api.publishContractDocument(id!, params);
    return response;
  };

  const updateDocumentName = async (id: string, params: any) => {
    try {
      let res = await api.updateContractName(id, params);
      return res;
    } catch (error) {}
  };

  const getDocument = async (filterParams: ITemplateFilterParams) => {
    const res = await api.getContract(filterParams);
    return res;
  };

  const handleStampContract = async (id: string, params: any) => {
    const res = await api.stampContracts(id, params);
    return res;
  };

  function downloadBlob(blob: any, name = "file.txt") {
    const link = document.createElement("a");
    link.href = blob;
    link.download = name;
    document.body.appendChild(link);
    window.open(blob, "_blank");
    document.body.removeChild(link);
    setDownloadStatus(false);
  }

  const handleContractDownload = async (id: string) => {
    try {
      setDownloadStatus(true);
      let response = await api.downloadContract(id);
      downloadBlob(response.file_path, "myfile.pdf");
      return response;
    } catch (error) {}
  };

  const handleDeleteContract = async (id: string) => {
    return await api.deleteContract(id);
  };

  // ...................Department..............
  const getDepartments = async () => {
    const res = await api.getDepartments();

    setDepartmentList(res?.data?.departments);
  };

  // ...................add coordinate for signature.........................
  const handleAddCoordinate = async (id: string, params: any) => {
    try {
      let response = await api?.addCoordinate(id, params);
      return response;
    } catch (error) {}
  };

  const handleDocumentSign = async (id: string, params: any) => {
    try {
      let response = await api?.documentSign(id, params);
      return response;
    } catch (error) {}
  };

  useEffect(() => {
    // if (location?.search === "?publish=true") {
    getDepartments();
    // }
  }, [location?.search]);

  const onContracToTemplateClone = async (id: string) => {
    try {
      let res = await api.contractToTemplateClone(id);
      return res;
    } catch (error) {}
  };

  const transferOwnership = async (userId: string, params: any) => {
    try {
      let response = await api.ContractTransferOwnerShip(userId, params);
      return response;
    } catch (error) {}
  };

  const handleContractHirerchy = async (id: string) => {
    try {
      let response = await api.getContractHirerchy(id);
      return response;
    } catch (error) {}
  };

  const handleDeleteContractHirerchy = async (id: string) => {
    try {
      let response = await api.deleteContractHirerchy(id);
      return response;
    } catch (error) {}
  };
  const getRecentDocuments = () => {};
  useEffect(() => {
    getRecentDocuments();
  }, []);

  const value: ContractContextInterface | null = !!contractDetail
    ? {
        isTemplate: false,
        contract: contractDetail,
        data: contractDetail,
        updateName: handleUpdateName,
        activities,
        getActivities: handleActivities,
        inviteCollaborator: handleInviteCollaborator,
        categoriesAutocomplete: handleCategoriesAutocomplete,
        getVariablesType,
        updateDocumentName,
        getRecentDocuments,

        getUserDetails,
        refresh: handleRefresh,
        publishDocument,
        showToolbar,
        setShowToolbar,
        getUser,
        handleStampContract,
        variables,
        setVariables,
        filterParams,
        changeFilter: (params: ITemplateFilterParams) =>
          setFilterParams(params),
        setDocumentId,
        waterMarkText,
        setWaterMarktext,
        handleContractDownload,
        getTemplateOwnerId,
        isOwner,
        updateCollaboratorsAccess,
        removeAccess,
        handleDeleteContract,
        getDocument,
        handleRefresh,
        onContracToTemplateClone,
        transferOwnership,
        updateData: setContract,
        departmentList,
        defaultCategories,
        coordinate,
        setCoordinate,
        handleAddCoordinate,
        documentId,
        downloadStatus,
        handleDocumentSign,
        handleUpdateContractDetail,
        handleContractHirerchy,
        handleDeleteContractHirerchy,
        variableTypes,
        handlePublishDoc,
        categoryList,
      }
    : null;
  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};
const useContract = () => {
  return React.useContext(ContractContext);
};
export { ContractProvider, useContract };
