import moment from "moment";
import { useEffect, useState } from "react";
import { CustomRenderElementProps, useFocused, useSelected } from "slate-react";
import { useEditor } from "../../EditorProvider";
import { useLocation, useParams } from "react-router-dom";
import { useVersionHistory } from "../../../modules/VersionHistory/VersionHisoryProvider";

const Variable = ({
  attributes,
  children,
  element,
}: CustomRenderElementProps) => {
  const context = useEditor();

  const [variable, setVariable] = useState<any>(null);
  const selected = useSelected();
  const focused = useFocused();
  const location = useLocation();
  const isContract: boolean = location.pathname.includes("contracts");
  const versionHistorycontext = useVersionHistory();

  useEffect(() => {
    if (location?.pathname?.includes("versions")) {
      getvariables();
    }
    let temp: any = context?.variables.find(
      (item: any) => item.name === element.variableName
    );

    if (temp) {
      setVariable(temp);
    }
  }, [context?.variables]);

  const { documentId } = useParams();
  const getvariables = async () => {
    try {
      let response = await versionHistorycontext?.getVariables(documentId);
      if (response?.success) {
        setVariable(response?.variables);
        let temp: any = response?.variables.find(
          (item: any) => item.name === element.variableName
        );
        if (temp) {
          setVariable(temp);
        }
      }
    } catch (error) {}
  };

  const formatValue = (variable: any) => {
    switch (variable?.data_type) {
      case "DATE":
        return moment(new Date(variable?.value)).format(variable?.format);
      case "ICURRENCY":
        return Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: variable?.currency_type,
        }).format(variable?.value);
      default:
        return variable?.value;
    }
  };

  const style: React.CSSProperties = {
    fontWeight: element.children[0].bold ? "bold" : "normal",
    fontStyle: element.children[0].italic ? "italic" : "normal",
    textDecorationLine: element.children[0].underline ? "underline" : "none",
    textDecoration: element.children[0].strikeThrough ? "line-through" : "none",
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: variable?.value === null ? "#eee" : "transparent",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
  };

  return (
    <span
      title={variable?.name}
      {...attributes}
      suppressContentEditableWarning={true}
      contentEditable={false}
      data-cy={`mention-${element.character}`}
      style={style}
    >
      {isContract && variable?.value != null
        ? formatValue(variable)
        : `$${variable?.name}`}
      {children}
    </span>
  );
};

export default Variable;
