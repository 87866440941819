import { Editor, Transforms, Text } from "slate";

function FindAndReplace(editor: Editor, findText: string, replace: string) {
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });

  const matches: { path: number[]; offset: number; length: number }[] = [];
  for (const [node, path] of Editor.nodes(editor as any) as any) {
    if (Text.isText(node)) {
      const { text } = node;
      let offset = 0;
      let index = 0;

      while (true) {
        index = text.indexOf(findText, offset);
        if (index === -1) {
          break;
        }
        matches.push({ path, offset: index, length: findText.length });
        offset = index + findText.length;
      }
    }
  }
  // reverses the match and fixes it once for all related to the search found.
  for (const match of matches.reverse()) {
    Transforms.delete(editor, {
      at: {
        anchor: { path: match.path, offset: match.offset },
        focus: { path: match.path, offset: match.offset + match.length },
      },
    });
    if (replace !== "") {
      Transforms.insertText(editor, replace, {
        at: { path: match.path, offset: match.offset },
      });
    }
  }

  return <div>FindAndReplace</div>;
}
export default FindAndReplace;
