import { useState } from "react";
import { Button } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useEditor } from "../../editor/EditorProvider";
import { IVariableResponse } from "../../interfaces/response/IVariableResponse";
import CurrencyVariableInput from "./CurrencyVariableInput";
import DateVariableInput from "./DateVariableInput";
import FloatVariableInput from "./FloatVariableInput";
import TextVariableInput from "./TextVariableInput";
import { AVAILABLE_VARIABLE_TYPES, useVariables } from "./VariableProvider";
import { useLocation } from "react-router-dom";

const VariableItem = ({ variable }: { variable: IVariableResponse }) => {
  const context = useVariables();
  const editorContext = useEditor();
  const [isDeleting, setIsDeleting] = useState(false);
  const location = useLocation();

  const isTemplate: boolean = location.pathname.includes("templates");

  return (
    <tr>
      <td
        className="border-0"
        style={{ maxWidth: "200px", overflow: "scroll" }}
      >
        {variable.name}
      </td>
      <td className="border-0" colSpan={isTemplate ? 2 : 1}>
        {
          AVAILABLE_VARIABLE_TYPES.find(
            ({ value }) => value === variable.data_type
          )?.text
        }
      </td>
      {!isTemplate && (
        <td className="border-0">
          {variable.data_type === "FLOAT" && (
            <FloatVariableInput variable={variable} />
          )}
          {variable.data_type === "STRING" && (
            <TextVariableInput variable={variable} />
          )}
          {variable.data_type === "DATE" && (
            <DateVariableInput variable={variable} />
          )}
          {variable.data_type === "ICURRENCY" && (
            <CurrencyVariableInput variable={variable} />
          )}
        </td>
      )}
      {location?.search !== "?suggestion=true" && (
        <>
          <td className="border-0" style={{ width: "1%" }}>
            <Button
              variant="none"
              className="border-0"
              disabled={isDeleting}
              onClick={async () => {
                setIsDeleting(true);
                await context?.delete(variable.id);
                await editorContext?.getVariables();
                setIsDeleting(false);
              }}
            >
              {isDeleting ? (
                <FaSpinner />
              ) : (
                <MdDelete color="#C5D0DE" fontSize={20} />
              )}
            </Button>
          </td>
        </>
      )}
    </tr>
  );
};

export default VariableItem;
