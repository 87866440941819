import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { customStyles } from "./CustomStyles";
import { api } from "../utils/api";
import { useAuthentication } from "../modules/Authentication/AuthenticationProvider";
const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="border-0">
        <div className="p-0">
          <div className=" d-flex align-items-center justify-content-between  ">
            <div className=" d-flex align-items-center">
              <div className="me-3">
                {props?.data?.avatar !== "" &&
                props?.data?.avatar !== null &&
                props?.data?.avatar !== undefined ? (
                  <img
                    src={props?.data?.avatar}
                    alt=""
                    width={40}
                    className="form_img_collb"
                  />
                ) : (
                  <Avatar
                    name={props?.data?.label.charAt(0)}
                    className="profile-avatars"
                    size="30"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={props?.data?.label}
                  />
                )}
              </div>
              <div style={{ paddingInlineStart: ".7rem" }}>
                <h6 className="fw-semibold m-0 ">{props?.data?.label}</h6>
                <h6 className="fw-lighter m-0 ">{props?.data?.email}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </components.Option>
  );
};
const AutoCompleteInput = ({
  values,
  setValues,
  context,
  transferOwnership,
}: any) => {
  const authContext = useAuthentication();
  const [initialOptions, setInitialOptions] = useState<any>([]);
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const res = await context?.getUserDetails();

    if (res) {
      setInitialOptions(
        res?.users?.filter(
          (el: any) =>
            el?.email !== authContext?.currentUser?.email &&
            el?.is_active === "true"
        )
      );
    }
  };
  const loadOptionValue = async (search: string) => {
    let res = await api?.getUsersAutocomplete({ search: search });

    const users = res
      ?.filter(
        (el: any) =>
          el?.email !== authContext?.currentUser?.email &&
          el?.is_active === "true"
      )
      ?.map((item: any) => ({
        label: `${item?.name}${" "}${item?.last_name}`,
        value: item?.id,
        email: item?.email,
        avatar: item?.avatar,
        contact_number: item?.contact_number,
        organization: item?.organization,
      }));
    return users;
  };

  return (
    <div>
      <AsyncSelect
        classNamePrefix="custommultiselect"
        isMulti={transferOwnership ? false : true}
        styles={customStyles}
        value={values}
        components={{ Option }}
        defaultOptions={initialOptions
          ?.filter((el: any) => el?.is_active === "true")
          ?.map((item: any) => ({
            label: `${item?.name}${" "}${item?.last_name}`,
            value: item?.id,
            email: item?.email,
            avatar: item?.avatar,
            contact_number: item?.contact_number,
            organization: item?.organization,
          }))}
        cacheOptions={false}
        loadOptions={loadOptionValue}
        onChange={(e: any) => {
          setValues(e);
        }}
        placeholder="Add People"
      />
    </div>
  );
};
export default AutoCompleteInput;
