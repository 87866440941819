import { isMobile } from "react-device-detect";
import AvatarSection from "./Avatar";
import { TemplateContextInterface } from "../modules/Templates/TemplateProvider";
import { ContractContextInterface } from "../modules/Contracts/ContractProvider";
import { useEffect } from "react";

const UsersList = ({
  context,
  setCollaboratorRoles,
  collaboratorRoles,
}: {
  context: TemplateContextInterface | ContractContextInterface | null;
  setCollaboratorRoles: Function;
  collaboratorRoles: any;
}) => {
  useEffect(() => {
    if (context?.data?.collaborators?.length) {
      setCollaboratorRoles(context?.data?.collaborators);
    }
  }, [context?.data?.collaborators]);

  return (
    context && (
      <ul
        className="list-unstyled overflow-scroll m-0 p-0"
        style={isMobile ? { height: "40dvh" } : { height: "20vh" }}
      >
        {collaboratorRoles?.map((collaborator: any) => {
          return (
            <li key={collaborator.user.sub}>
              <div className="border-0">
                <div className="p-0">
                  <div className=" d-flex align-items-center justify-content-between colabBody py-3 ">
                    <div className=" d-flex align-items-center">
                      <div>
                        <AvatarSection
                          imageData={""}
                          name={collaborator && collaborator?.user?.name}
                        />
                      </div>
                      <div className="ms-3">
                        <h6 className="fw-semibold m-0 ">
                          {collaborator.user.name}
                        </h6>
                        <h6 className="fw-lighter m-0 ">
                          {collaborator.user.email}
                        </h6>
                      </div>
                    </div>
                    <div>
                      <h6 className="fw-lighter m-0">
                        {collaborator?.access}
                        {/* {collaborator?.access === "OWNER" ? (
                          "Owner"
                        ) : (
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            defaultValue={{
                              label: collaborator?.access,
                              value: collaborator?.access,
                            }}
                            options={accessOptions}
                            onChange={(e) => {
                              const collaboratorIndex: any =
                                collaboratorRoles?.findIndex(
                                  (value: any) =>
                                    value?.user?.id === collaborator?.user?.id
                                );
                            

                              if (collaboratorIndex > -1) {
                                collaboratorRoles[collaboratorIndex] =
                                  new Collaborator({
                                    ...collaboratorRoles[collaboratorIndex],
                                    access: e?.value,
                                  });
                              }
                              setCollaboratorRoles([...collaboratorRoles]);
                            }}
                          />
                        )} */}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    )
  );
};
export default UsersList;
