import { useEditorConfig } from "@shabs21/slate-plugin-sample";
import { Image } from "../Image";
import Variable from "../components/Variables/Variable";
import { useSlate } from "slate-react";


export const RenderElement = ({
  element,
  attributes,
  children,
}: // styles,
any) => {
  const editor = useSlate();
  const { RenderTable } = useEditorConfig(editor);
  

  const style: any = {
    textAlign: element.align,
    ...element?.style,
    marginTop: "1px",
    width: "100%",
    paddingLeft: element.indentLevel * 40,
  };

  switch (element.type) {
    case "code":
      return (
        <code style={style} {...attributes}>
          {children}
        </code>
      );
    case "quote":
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul
          style={style}
          {...attributes}
          id="ul-editor"
          className="unorderedList-editor"
        >
          {children}
        </ul>
      );
    case "numbered-list":
      return (
        <div className="orderedList-editor">
          <ol style={style} {...attributes}>
            {children}
          </ol>
        </div>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={{ ...style }} {...attributes} className="wrapTextList">
          {children}
        </li>
      );
    case "image":
      return (
        <Image
          attributes={attributes}
          style={style}
          children={children}
          element={element}
        />
      );
    case "link":
      style["cursor"] = "pointer";
      return (
        <a
          {...attributes}
          style={style}
          href={element.href || element?.url}
          className="slate-element"
          onClick={() => {
            window.open(element.href || element?.url, "_blank", "");
          }}
        >
          {children}
        </a>
      );

    case "pageBreak":
      return (
        <div
          {...attributes}
          // // style={[...style]}
          style={{
            height: "1px",
            width: "100%",
            breakAfter: "page",
            borderStyle: "dotted",
            borderWidth: "thin",
            marginBottom: "1px",
          }}
          // style={{
          //   // ...style,
          //   height: "1px",
          //   width: "100%",
          //   breakAfter: "page",
          //   borderStyle: "dotted",
          //   borderWidth: "thin",
          //   marginBottom: "1px",
          // }}
          className="pagebreak"
          contentEditable="false"
        >
          {children}
        </div>
      );
    // ------------------------------------------------------------------------------

    case "sup":
      return (
        <sup style={style} {...attributes}>
          {children}
        </sup>
      );
    case "sub":
      return (
        <sub style={style} {...attributes}>
          {children}
        </sub>
      );
    case "div":
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      );
    case "nav":
      return (
        <nav style={style} {...attributes}>
          {children}
        </nav>
      );
    case "span":
      return (
        <span style={style} {...attributes}>
          {children}
        </span>
      );
    case "colgroup":
      return (
        <colgroup style={style} {...attributes}>
          {children}
        </colgroup>
      );
    case "col":
      return <col style={style} {...attributes} />;
    case "variable":
      return (
        <Variable attributes={attributes} element={element}>
          {children}
        </Variable>
      );

    case "table":
    case "table-head":
    case "table-body":
    case "table-row":
    case "header-cell":
    case "table-cell":
      return RenderTable({ element, attributes, children });
    default:
      return (
        <div className="pBottomMargin">
          <p style={style} {...attributes} c>
            {children}
          </p>
        </div>
      );
  }
};
