import { ChangeEvent } from "react";
import { Editor, Transforms } from "slate";
import { CustomEditor } from "../types/custom-types";

export const addMarkData = (
  editor: CustomEditor,
  data: { format: any; value: any }
) => {
  Editor.addMark(editor, data.format, data.value);
};

export const activeMark = (editor: any, format: string | number) => {
  const defaultMarkData: any = {
    color: "black",
    bgColor: "black",
    // fontSize: "normal",
    fontSize: "14",
    fontFamily: "sans",
    fontHeight: "5px",
  };
  const marks: any = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};

export const changeMarkData = (
  event: ChangeEvent<HTMLSelectElement>,
  format: any,
  editor: CustomEditor
) => {
  event.preventDefault();
  const value = event.target.value || event;
  addMarkData(editor, { format, value });
};

export const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const toggleFormat = (editor: Editor, format: string): void => {
  Transforms.setNodes(editor, {
    format,
  } as any);
};
