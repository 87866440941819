import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAdminAnalytics } from "../../AdminAnalyticsProvider";
import { stringToHexColor, updateText } from "../../../../../utils";
import AnalyticsNoDataFound from "../../../../Analytics/AnalyticsNoDataFound";

const AdminPieChart = ({ filterData }: { filterData: any }) => {
  const context = useAdminAnalytics();
  const [chartData, setChartData]: [any, Function] = useState();
  const ChartDataDetails = async () => {
    try {
      let response = await context?.pieChartDataDetails(
        filterData ? filterData : ""
      );
      if (response?.success) {
        var data = response?.data.map(function (obj: any) {
          if (obj.category === null || obj.category === "") {
            obj.category = "No category";
          }
          return obj;
        });
        const dataWithColors = data.map((item: any) => ({
          ...item,
          id:
            item?.category?.charAt(0)?.toUpperCase()?.replace(/_/g, " ") +
            item?.category?.slice(1)?.toLowerCase()?.replace(/_/g, " "),
          color: stringToHexColor(item?.category),
          value: item?.count,
        }));
        setChartData(dataWithColors);
      }
    } catch (error) {}
  };

  useEffect(() => {
    ChartDataDetails();
  }, [filterData]);
  return <>{chartData && <PieChart data={chartData} />}</>;
};

export default AdminPieChart;

const PieChart = ({ data }: any) => {
  return (
    <>
      <div style={isMobile ? { height: "350px" } : { height: "450px" }}>
        {data?.length === 0 ? (
          <AnalyticsNoDataFound />
        ) : (
          <>
            {" "}
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.3]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              tooltip={({ datum }: any) => {
                return (
                  <div style={{ color: "black" }}>
                    <span>
                      {updateText(datum?.data?.category?.replace("_", " "))}:{" "}
                      {datum.value}
                    </span>
                  </div>
                );
              }}
              fill={[
                {
                  match: {
                    id: "ruby",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "c",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "go",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "python",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "scala",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "lisp",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "elixir",
                  },
                  id: "lines",
                },
                {
                  match: {
                    id: "javascript",
                  },
                  id: "lines",
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};
