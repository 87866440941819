import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ITemplateCategory } from "../../interfaces/ITemplateCategory";
import { ITemplateFilterParams } from "../../interfaces/ITemplateFilterParams";
import { ITemplateResponse } from "../../interfaces/response/ITemplateResponse";
import { Template } from "../../lib/Template";
import { api } from "../../utils/api";
import { IMetaResponse } from "../../interfaces/response/IMetaResponse";
import { IUserResponse } from "../../interfaces/response/IUserResponse";
import { User } from "../../lib/User";
// import { StatusEnum } from "../../interfaces/StatusEnum";

interface ListTemplateContextInterface {
  getRecentDocuments: () => Promise<Template[]>;
  recentTemplates: Template[] | null;
  templates: Template[];
  templateCategories: ITemplateCategory[];
  filterParams: ITemplateFilterParams;
  changeFilter: (params: ITemplateFilterParams) => void;
  resetFilter: () => void;
  categoriesAutocomplete: (search: string) => Promise<string[]>;
  defaultCategories: ITemplateCategory[];
  deleteTemplate: Function;
  collaborators: IUserResponse[];
  templateStatus: [];
  departmentList: [];
  metaData: IMetaResponse;
  onPublishDocument: Function;
  isActiveProvider: string;
  updateDocumentName: Function;
  handleRootFolder: Function;
  handleMoveToFolderTemplate: Function;
  updateWorkflowMendatory: Function;
  loader: boolean;
  handleFolderById: Function;

  createDocuments: (params: {
    name: string;
    description: string;
  }) => Promise<Template | null>;
  handleTemplateDownload: Function;
  downloadStatus: boolean;
  setDownloadStatus: Function;
  handleUploadTemplate: Function;
}

const ListTemplatesContext =
  React.createContext<ListTemplateContextInterface | null>(null);

interface ListTemplatesProviderProps {
  children: ReactNode;
}

const ListTemplatesProvider = ({ children }: ListTemplatesProviderProps) => {
  const { department } = useParams();
  const initialFilter: ITemplateFilterParams = useMemo(() => {
    const params: ITemplateFilterParams = {
      sort: "-updatedAt",
      limit: 10,
      page: 1,
    };
    if (department) {
      params.department = department;
    }
    return params;
  }, [department]);
  const [recentTemplates, setRecentTemplates]: [Template[] | null, Function] =
    useState(null);
  const [templates, setTemplates]: [Template[], Function] = useState([]);
  const [loader, setLoader]: [boolean, Function] = useState(true);

  // ..........................filter data...............................
  const [filterParams, setFilterParams]: [ITemplateFilterParams, Function] =
    useState(initialFilter);
  const [defaultCategories, setDefaultCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);
  const [isActiveProvider]: [string, Function] = useState("templates");

  // ..........................template filter category data......................
  const [templateCategories, setTemplateCategories]: [
    ITemplateCategory[],
    Function
  ] = useState([]);

  // .......template total list count.....
  const [metaData, setMetaData] = useState<IMetaResponse>({
    total_count: 0,
    page: 0,
    limit: 0,
  });

  // .......collaborators list array...............
  const [collaborators, setCollaborators]: [IUserResponse[], Function] =
    useState([]);

  // .................template status..........................
  const [templateStatus, setTemplateStatus]: [[], Function] = useState([]);
  const [departmentList, setDepartmentList]: [[], Function] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

  const getTemplates = async (filterParams: ITemplateFilterParams) => {
    setLoader(true);
    const res = await api.getTemplates({
      ...filterParams,
      department: filterParams?.department
        ? filterParams?.department?.toUpperCase()
        : "",
    });

    if (res) {
      setLoader(false);
    }

    setMetaData(res.meta);
    const _templates: Template[] = res.template.map(
      (templateParam: ITemplateResponse) => new Template(templateParam)
    );

    return _templates;
  };

  const handleFolderById = async (id: string, params: any) => {
    try {
      let response = await api.getFolderById(id, params);

      return response;
    } catch (error) {}
  };

  const handleRecentTemplates = useCallback(async () => {
    const templates = await getTemplates({
      ...initialFilter,
      limit: 10,
      sort: "-updatedAt",
      department: initialFilter?.department
        ? initialFilter?.department?.toUpperCase()
        : "",
    });

    setRecentTemplates(templates);

    return templates;
  }, [initialFilter]);

  const handleCategoriesAutocomplete = useCallback(
    async (search: string): Promise<string[]> =>
      await api.getTemplatesCategoriesAutocomplete({ search: search }),
    []
  );

  const handleDeleteTemplate = async (id: string) => {
    try {
      const res = await api.deleteTemplate(id);
      if (res) {
        handleRecentTemplates();
      }
      return res;
    } catch (error) {}
  };

  const handleCreateTemplate = useCallback(
    async (params: {
      name: string;
      description: string;
    }): Promise<Template | null> => {
      try {
        const { data } = await api.createTemplate(params);
        const { success, template } = data;
        if (success) {
          return new Template(template);
        }
        return null;
      } catch (e) {
        return null;
      }
    },
    []
  );

  const handleUploadTemplate = async (params: any) => {
    try {
      let response = await api.uploadTemplate(params);
      handleRecentTemplates();
      return response;
    } catch (error) {}
  };

  useEffect(() => {
    const loadData = async () => handleRecentTemplates();
    if (!!handleRecentTemplates) {
      loadData();
    }
  }, [handleRecentTemplates]);

  useEffect(() => {
    const laodData = async () => {
      let _params = filterParams;
      if (department) {
        _params.department = department.toUpperCase();
      }
      const _templates = await getTemplates(_params);
      setRecentTemplates(_templates);
      setTemplates(_templates);
    };
    laodData();
  }, [filterParams, department]);

  useEffect(() => {
    const loadData = async () => {
      const categories = await handleCategoriesAutocomplete("");
      setDefaultCategories(categories);
    };
    loadData();
  }, [handleCategoriesAutocomplete]);

  useEffect(() => {
    const loadData = async () => {
      const _categories = await handleCategoriesAutocomplete("");

      setTemplateCategories(
        _categories.map((cateogry) => ({
          name: cateogry,
          count: 0,
        }))
      );
    };
    loadData();
  }, [handleCategoriesAutocomplete]);

  // ................collaborators..............

  const getCollaborators = async () => {
    const res = await api.getCollaborators({ limit: 100 });

    const _collaborators: User[] = res?.users.map(
      (collaboratorsParams: IUserResponse) => new User(collaboratorsParams)
    );

    setCollaborators(_collaborators);
    return _collaborators;
  };

  // ......................publish document..................
  const onPublishDocument = async (
    id: string,
    params: ITemplateFilterParams
  ) => {
    await api.publishDocuments(id, params);
  };

  // ...................Department..............
  const getDepartments = async () => {
    const res = await api.getDepartments();

    setTemplateStatus(res?.data?.templateStatus);
    setDepartmentList(res?.data?.departments);
  };

  useEffect(() => {
    getCollaborators();
    getDepartments();
  }, []);

  const handleTemplateDownload = async (id: string) => {
    try {
      let response = await api.downloadTemplate(id);
      fetch(response.file_path)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "downloaded-pdf.pdf";
          link.click();
        });
      return response;
    } catch (error) {}
  };

  const updateDocumentName = async (id: string, params: any) => {
    try {
      let res = await api.updateTemplateName(id, params);
      return res;
    } catch (error) {}
  };

  const handleRootFolder = async () => {
    try {
      let response = await api.getrootFolder();
      return response;
    } catch (error) {}
  };

  const updateWorkflowMendatory = async (
    id: string,
    params: any,
    approvalId: string
  ) => {
    try {
      let response = await api.updateTmplateWorkflow(id, params, approvalId);
      return response;
    } catch (error) {}
  };

  const handleMoveToFolderTemplate = async (params: any, id: string) => {
    try {
      let response = await api.moveToFolderTemplate(params, id);
      return response;
    } catch (error) {}
  };

  const value: ListTemplateContextInterface = useMemo(
    () => ({
      getRecentDocuments: handleRecentTemplates,
      recentTemplates,
      templates,
      templateCategories,
      filterParams,
      changeFilter: (params: ITemplateFilterParams) => setFilterParams(params),
      resetFilter: () => setFilterParams(initialFilter),
      deleteTemplate: handleDeleteTemplate,
      categoriesAutocomplete: handleCategoriesAutocomplete,
      createDocuments: handleCreateTemplate,
      onPublishDocument,
      defaultCategories,
      collaborators,
      templateStatus,
      departmentList,
      metaData,
      handleTemplateDownload,
      isActiveProvider,
      updateDocumentName,
      downloadStatus,
      setDownloadStatus,
      handleRootFolder,
      handleMoveToFolderTemplate,
      updateWorkflowMendatory,
      loader,
      handleUploadTemplate,
      handleFolderById,
    }),
    [
      recentTemplates,
      templates,
      templateCategories,
      filterParams,
      handleRecentTemplates,
      initialFilter,
      handleCategoriesAutocomplete,
      handleCreateTemplate,
      defaultCategories,
      collaborators,
      handleDeleteTemplate,
      departmentList,
      templateStatus,
      metaData,
    ]
  );

  return (
    <ListTemplatesContext.Provider value={value}>
      {children}
    </ListTemplatesContext.Provider>
  );
};

const useTemplates = () => {
  return React.useContext(ListTemplatesContext);
};

export {
  ListTemplatesProvider,
  useTemplates,
  type ListTemplateContextInterface,
};
