import { useState } from "react";
import { Accordion } from "react-bootstrap";
import DeleteToast from "../../../../ui/DeleteToast";
import moment from "moment";
import { useContract } from "../../../Contracts/ContractProvider";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../../utils/routes";

const HierarchyTabs = ({
  name,
  batch,
  contractDetail,
  contractHirerchyList,
}: {
  name: any;
  batch?: string;
  contractDetail?: any;
  contractHirerchyList: Function;
}) => {
  const contract = useContract();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showDeleteHierarchyToast, setShowDeleteHierarchyToast]: [
    boolean,
    Function
  ] = useState(false);

  const deleteContract = async () => {
    try {
      let response = await contract?.handleDeleteContractHirerchy(
        contractDetail?.id
      );
      if (response?.success) {
        setShowDeleteHierarchyToast(false);
        contractHirerchyList();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="list_questions w-100">
        <Accordion className="w-100">
          <Accordion.Item key={""} eventKey="" className=" border-0 w-100">
            <div className=" border-bottom w-100 position-relative">
              <Accordion.Header
                className="w-100"
                style={{
                  borderLeft:
                    name === contract?.data?.name
                      ? "4px solid #3944D8"
                      : "4px solid gray",
                }}
              >
                <div className="grandParent  flex-grow-1">
                  <h6 className="fw-semibold">
                    {name &&
                      name?.charAt(0)?.toUpperCase() +
                        name?.slice(1)?.toLowerCase()}
                    <span
                      style={{ background: "#f1f1f5", fontSize: "11px" }}
                      className="fw-semibold rounded-5 py-1 px-2 ms-2"
                    >
                      {batch}
                    </span>
                  </h6>
                  <div className=" d-flex align-items-center mt-3">
                    <h6
                      style={{ color: "#a8aab7", fontSize: "12px" }}
                      className="m-0 pe-3"
                    >
                      Status: Active
                    </h6>
                    <h6
                      style={{ color: "#a8aab7", fontSize: "12px" }}
                      className="pe-3 m-0"
                    >
                      End Date:
                      {contractDetail?.expiry_date
                        ? moment(contractDetail?.expiry_date).format(
                            "MM/DD/yyy"
                          )
                        : "-"}
                    </h6>
                    <h6
                      style={{ color: "#a8aab7", fontSize: "12px" }}
                      className="m-0"
                    >
                      Value:{" "}
                      {contractDetail?.contract_value
                        ? contractDetail?.contract_value
                        : "-"}
                    </h6>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="text-wrap border-top hierarchyBodyAccordion ">
                <div>
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Owner by:</h6>
                    <h6 className=" fw-semibold">
                      {contractDetail?.created_by?.name}
                    </h6>
                  </div>
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Type:</h6>
                    <h6 className=" fw-light">
                      {contractDetail?.category
                        ? contractDetail?.category
                        : "-"}
                    </h6>
                  </div>{" "}
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Department:</h6>
                    <h6 className=" fw-light"> {contractDetail?.department}</h6>
                  </div>{" "}
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Start date:</h6>
                    <h6 className=" fw-light">
                      {contractDetail?.start_date
                        ? moment(contractDetail?.start_date).format(
                            "MM/DD/YYYY"
                          )
                        : "-"}
                    </h6>
                  </div>
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Updated on:</h6>
                    <h6 className=" fw-light">
                      {contractDetail?.updatedAt
                        ? moment(contractDetail?.updatedAt).format("MM/DD/YYYY")
                        : "-"}
                    </h6>
                  </div>{" "}
                  <div className=" d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h6 style={{ color: "#a8aab7" }}>Updated by:</h6>
                    <h6 className=" fw-light">
                      {contractDetail?.updated_by?.name}
                    </h6>
                  </div>
                  <div className=" d-flex justify-content-end align-items-center p-3 ">
                    {batch !== "Primary" && id !== contractDetail?.id && (
                      <h6
                        style={{ color: "#e15554" }}
                        className=" cursor-pointer"
                        onClick={() => {
                          setShowDeleteHierarchyToast(true);
                        }}
                      >
                        Delete
                      </h6>
                    )}

                    <h6
                      className=" ms-3 cursor-pointer"
                      style={{ color: "#3c3ada" }}
                      onClick={() => {
                        navigate(
                          generatePath(routes.overviewContracts, {
                            id: contractDetail.id,
                          })
                        );
                        // window.location.reload();
                      }}
                    >
                      View
                    </h6>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>

        {showDeleteHierarchyToast && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => deleteContract()}
            onClose={() => setShowDeleteHierarchyToast(false)}
            title="Do you want to delete the Hierarchy?"
            closeText="No, don't !"
            submitText="Yes, delete it."
          />
        )}
      </div>
    </>
  );
};

export default HierarchyTabs;
