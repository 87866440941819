import { useEffect, useState } from "react";
import { Button, Dropdown, Modal, Stack } from "react-bootstrap";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineArrowDropDown,
} from "react-icons/md";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AppHeader from "../../layouts/AppHeader";
import DeleteToast from "../../ui/DeleteToast";
import { routes } from "../../utils/routes";
import { ContractProvider, useContract } from "./ContractProvider";
import OverviewNestedBar from "../Templates/TemplateOverview/TemplateNavBar";
import { useAuthentication } from "../Authentication/AuthenticationProvider";
import DocumentCollaborators from "../../components/DocumentCollaborators";
import { isMobile, isMobileOnly } from "react-device-detect";
import SuccessToast from "../../ui/SuccessToast";
import FailureToast from "../../ui/FailureToast";
import * as Yup from "yup";
import { Formik } from "formik";
import { Badge } from "react-bootstrap";
import RejectPopup from "./RejectPopup";
import moment from "moment";
import { api } from "../../utils/api";
import Select from "react-select";
import { IApprovalResponse } from "../../interfaces/response/IApprovalResponse";
import { BiCommentEdit, BiDotsVertical } from "react-icons/bi";
import TransferOwnerShipPopup from "../../ui/TransferOwnerShipPopup";
import { Collaborator } from "../../lib/Collaborator";
import PublishDocuentPopup from "./Components/PublishDocuentPopup";
import SignaturePopup from "../User/SignaturePopup";
import { StatusEnum } from "../../interfaces/StatusEnum";
// import UpdateDocumentName from "../../layouts/UpdateDocumentName";
import EsignPopup from "../WorkFlow/EsignPopup";
import CoordinatePopup from "./CoordinatePopup";
import PdfPopup from "./PdfPopup";
import CreateContractPopup from "./Components/CreateContractPopup";
import { FaArrowLeft } from "react-icons/fa";
import UpdateDocumentName from "../../layouts/UpdateDocumentName";

function ContractOverview() {
  return (
    <ContractProvider>
      <AppHeader>
        <ContractBody />
      </AppHeader>
    </ContractProvider>
  );
}

export default ContractOverview;
const ContractBody = () => {
  const location = useLocation();
  const context: any = useContract();

  const navigate = useNavigate();
  const authContext = useAuthentication();

  const [show, setShow] = useState(false);
  const [startWorkFlow, setStartWorkflow]: [boolean, Function] =
    useState(false);
  const [bodyMessage, setWorkFlowBodyMessage]: [string, Function] =
    useState("");
  const [showSuccessToast, setShowSuccessToastWorkFlow]: [boolean, Function] =
    useState(false);
  const [showSuccessStampToast, setShowSuccessStampToastWorkFlow]: [
    boolean,
    Function
  ] = useState(false);
  const [showWorkflowFailureToast, setWorkFlowShowFailureToast]: [
    boolean,
    Function
  ] = useState(false);
  const [locations, setLocation]: [string, Function] = useState("");
  const [desigination, setDesigination]: [string, Function] = useState("");

  const [expiryDate, setExpiryDate]: [any, Function] = useState();

  const [isEditorAccess, setIsEditorAccess]: [string, Function] = useState("");

  const [isLoggedInUser, setIsLoggedInUser]: [boolean, Function] =
    useState(false);
  const [stampDone, SetStampDone]: [boolean, Function] = useState(false);
  const [isWorkflowListAdded, setIsWorkflowListAdded]: [boolean, Function] =
    useState(false);

  const [rejectApproval, setRejectApproval]: [boolean, Function] =
    useState(false);
  const [isApprovedActive, setIsApprovedACtive]: [string, Function] =
    useState("");
  const [isApproved, setIsApproved]: [boolean, Function] = useState(false);
  const [showDeleteToast, setShowDeleteToast]: [boolean, Function] =
    useState(false);
  const [showDeleteTemplateToast, setShowDeleteTemplateToast]: [
    boolean,
    Function
  ] = useState(false);
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const { id }: any = useParams();
  const [resumeBtnClick, setResumeBtnClick]: [boolean, Function] =
    useState(false);
  const [startFromBegnning, setStartFRomBegnning]: [boolean, Function] =
    useState(false);

  const [resumeWorkflowIsActive, setResumeWorkflowIsActive]: [
    boolean,
    Function
  ] = useState(false);
  const [editWorkflowStarted, setEditWorkflowStarted]: [boolean, Function] =
    useState(false);
  const [signatureDetail, setSignatureDetail]: [any, Function] = useState();
  const [isActiveSignature, setIsActiveSignature]: [any, Function] = useState();
  const [addSignature, setAddSignature]: [boolean, Function] = useState(false);

  const [editWorkflow, SetEditWorkflow]: [boolean, Function] = useState(false);
  const [rejectStatus, setRejectStatus]: [boolean, Function] = useState(false);
  const [contractAsTemplate, setContractAsTemplate]: [boolean, Function] =
    useState(false);
  const [publishPopup, setPublishPopup]: [boolean, Function] = useState(false);
  const [isActiveTransferOwnerShip, setIsActiveTransferOwnership]: [
    boolean,
    Function
  ] = useState(false);
  const [isActiveCoordinate, setIsActiveCoordinate]: [boolean, Function] =
    useState(false);
  const [pdfPopup, setpdfPopup]: [boolean, Function] = useState(false);
  const [suggeestion, setSuggeestion]: [boolean, Function] = useState(false);
  const [ownerDetail, setOwnerDetail]: [Collaborator | undefined, Function] =
    useState();
  const [isOwner, setIsOwner]: [any, Function] = useState();
  const [confirmCoordinatePopup, setConfirmCoordinatePopup]: [
    boolean,
    Function
  ] = useState(false);
  const [isFileUpload, setIsFileUpload]: [boolean, Function] = useState(false);
  const [uploadFile, setUploadFile]: [boolean, Function] = useState(false);
  const [confirmCoordinate, setConfirmCoordinate]: [boolean, Function] =
    useState(false);
  const [isEsignActive, setIsEsignActive]: [boolean, Function] =
    useState(false);
  const [isEsignApproved, setIsEsignApproved]: [boolean, Function] =
    useState(false);
  const [renamePopup, setRenamePopup]: [boolean, Function] = useState(false);
  const [initialName, setInitialName]: [string, Function] = useState("");
  const [isViewer, setIsViewer]: [boolean, Function] = useState(false);
  const [isLocationActive, setIsLocationActive]: [boolean, Function] =
    useState(false);
  const [negotiationOwnerEmail, setNegotiationOwnerEmail]: [boolean, Function] =
    useState(false);
  const [isNegotiationWorkflow, setIsNegotiationWorkflow]: [boolean, Function] =
    useState(false);

  const handleDelete = async () => {};

  const currentUserDetail = async () => {
    try {
      if (
        authContext?.currentUser?.email ===
        context?.data?.collaborators?.find((el: any) => el?.access === "OWNER")
          ?.user?.email
      ) {
        setIsLoggedInUser(true);
        setIsEditorAccess("EDITOR");
      } else {
        setIsLoggedInUser(false);
      }
      if (authContext?.currentUser?.sub !== context?.data?.createdBy?.id) {
        let x = context?.data?.collaborators?.filter((el: Collaborator) => {
          return el?.user?.id === authContext?.currentUser?.sub;
        });
        setIsEditorAccess(x?.[0]?.access);
      }
    } catch (error) {}
  };

  const onPublishDocument = async () => {
    try {
      let response = await context.handlePublishDoc(id);

      if (response?.data?.success) {
        context?.refresh();
        setShowSuccessStampToastWorkFlow(true);
        setShowDeleteToast(false);
        setWorkFlowBodyMessage("Document published.");
        setShowDeleteToast(false);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {}
  };

  const deleteContract = async () => {
    const res = await context?.handleDeleteContract(id);

    if (res.success) {
      setShowDeleteTemplateToast(false);
      navigate(generatePath(routes.root));
    }
  };

  useEffect(() => {
    if (context?.contract?.approvers) {
      let reject = context?.contract?.approvers?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );

      if (reject?.length === 0) {
        setResumeWorkflowIsActive(true);
      } else {
        setResumeWorkflowIsActive(false);
      }
    }

    let approvalList = context?.contract?.approvers?.filter((el: any) => {
      return (
        el?.user?.sub === authContext?.currentUser?.sub &&
        (el?.status === StatusEnum?.rejected ||
          el?.status === StatusEnum?.approved)
      );
    });

    if (context?.data?.status === StatusEnum?.pending_negotitation) {
      setIsApproved(true);
    }

    if (approvalList?.length !== 0) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }

    if (context?.contract?.internalSingers) {
      let rejectedItem = context?.contract?.internalSingers?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );
      if (rejectedItem?.length > 0) {
        setRejectStatus(true);
      }
    }
    if (context?.data?.status === StatusEnum?.rejected) {
      setRejectStatus(true);
    }

    if (context?.contract?.externalUser) {
      let rejectedList = context?.contract?.externalUser?.filter(
        (list: IApprovalResponse) => {
          return list?.status === StatusEnum?.rejected;
        }
      );

      if (rejectedList?.length > 0) {
        setRejectStatus(true);
      }
    }
  }, [context?.contract]);

  useEffect(() => {
    if (
      isOwner?.[0]?.access === "OWNER" &&
      (context?.data?.status === StatusEnum?.pending_approve ||
        context?.data?.status === StatusEnum?.approved ||
        context?.data?.status === StatusEnum?.pending_negotitation ||
        context?.data?.status === StatusEnum?.pending_negotitation_approval ||
        context?.data?.status === StatusEnum?.rejected ||
        context?.data?.status === StatusEnum?.sign_initiated)
    ) {
      setEditWorkflowStarted(true);
    }
  }, [context?.data, isOwner]);

  const onContractToTemplateConversion = async () => {
    try {
      let response = await context?.onContracToTemplateClone(id);

      if (response?.data?.success) {
        setShowSuccessToastWorkFlow(true);
        setWorkFlowBodyMessage("Contract cloned.");
        setContractAsTemplate(false);
      } else {
        setWorkFlowShowFailureToast(true);
        setWorkFlowBodyMessage("Failed.");
        setContractAsTemplate(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (context?.data?.collaborators) {
      let ownerDetail = context?.data?.collaborators?.filter(
        (owner: Collaborator) => {
          return owner?.user?.sub === authContext?.currentUser?.sub;
        }
      );

      setIsOwner(ownerDetail);
      setOwnerDetail(ownerDetail?.[0]);
    }
  }, [context?.data?.collaborators]);

  const [commentsLength, setCommentsLength]: [number | undefined, Function] =
    useState();

  const handleComments = async () => {
    if (context?.data?.documentId) {
      try {
        let response = await api.getComments(context?.data?.documentId!, {
          is_resolved: false,
        });
        if (response?.success) {
          setCommentsLength(response?.comments?.length);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    handleComments();
  }, [context?.data?.documentId]);

  const [resumeWorkflow, setResumeWorkflow]: [any, Function] = useState();
  useEffect(() => {
    let resumeBtn = (
      context?.data?.internalSingers || context?.data?.externalUser
    )?.find((el: any) => el?.status === StatusEnum?.rejected);
    if (resumeBtn) {
      setResumeWorkflow(resumeBtn);
    }
  }, [context?.data]);
  const [unPublishDocument, setUnPublishedDocument]: [boolean, Function] =
    useState(false);

  const [rolesUpdate, setRolesUpdate]: [any, Function] = useState();
  const [rolesDelete, setRolesDelete]: [any, Function] = useState();

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRolesUpdate(guestRole?.CONTRACTS?.find((el: any) => el === "UPDATE"));
      setRolesDelete(guestRole?.CONTRACTS?.find((el: any) => el === "DELETE"));
    }
  }, [authContext?.rolesAndAccess]);

  const onUnPublishedDocument = async () => {
    try {
      setLoader(true);
      let requestBody = {
        status: StatusEnum?.draft,
        name: !!context?.data && context?.data?.name,
      };
      const response = await context?.publishDocument(id, requestBody);

      if (response?.data?.success) {
        context?.refresh();
        navigate(routes.contracts);
        setUnPublishedDocument(false);
        setLoader(false);
        setShowSuccessToastWorkFlow(true);
        setWorkFlowBodyMessage("Document unpublished.");
      }
      if (!response?.data?.success) {
        setWorkFlowShowFailureToast(true);
        setLoader(false);
        setWorkFlowBodyMessage("Something went wrong!");
        setUnPublishedDocument(false);
      }
    } catch (error) {}
  };

  const onUnPublishedRejectWorkflow = async () => {
    try {
      setLoader(true);
      let requestBody = {
        status: StatusEnum?.draft,
        name: !!context?.data && context?.data?.name,
      };
      const response = await context?.publishDocument(id, requestBody);

      if (response?.data?.success) {
        context?.refresh();
        setIsUnpublish(false);
        setUnPublishedDocument(false);
        setLoader(false);
        setShowSuccessToastWorkFlow(true);
        setWorkFlowBodyMessage("Document unpublished.");
        // navigate(routes.contracts);
        navigate(
          generatePath(routes.overviewContracts, {
            id: id as string,
          })
        );
      }
      if (!response?.data?.success) {
        setWorkFlowShowFailureToast(true);
        setLoader(false);
        setWorkFlowBodyMessage("Something went wrong!");
        setUnPublishedDocument(false);
      }
    } catch (error) {}
  };

  const handleConfirmCoordinate = async () => {
    try {
      // let response = await context?.handleAddCoordinate(id, {
      //   signing: context?.coordinate,
      // });
      // if (response?.data?.success) {
      setConfirmCoordinatePopup(false);
      setConfirmCoordinate(true);
      // navigate(
      //   generatePath(routes.workflowContracts, {
      //     id: id!,
      //   })
      // );
      // context?.refresh();
      // setConfirmCoordinatePopup(false);
      // }
    } catch (error) {}
  };

  useEffect(() => {
    currentUserDetail();
  }, [context?.data?.collaborators]);

  const base64ImageToBlob = (str: string) => {
    let pos = str.indexOf(";base64,");
    let type = str.substring(5, pos);
    let b64 = str.substr(pos + 8);
    let imageContent = atob(b64);
    let buffer = new ArrayBuffer(imageContent.length);
    let view = new Uint8Array(buffer);
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    let blob = new Blob([buffer], { type: type });
    return blob;
  };

  const handleSign = async (
    type: string,
    filePath?: string,
    location?: string
  ) => {
    let response: any;
    try {
      if (!addSignature && type !== "reject") {
        const formData = new FormData();
        if (filePath) {
          formData.set("file", base64ImageToBlob(filePath));
        } else {
          formData.set("url", signatureDetail);
        }
        formData.set("accept", "true");
        formData.set("initials", initialName);
        formData.set("adress", locations && isLocationActive ? locations : "");
        formData.set("designation", desigination ? desigination : "");

        response = await context?.handleDocumentSign(id, formData);
        if (response?.data?.success) {
          setIsEsignActive(false);
          setShowSuccessStampToastWorkFlow(true);
          setWorkFlowBodyMessage("Sign successfully.");
          navigate(
            generatePath(routes.overviewContracts, {
              id: id,
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      } else {
        if (type === "reject") {
          response = await context?.handleDocumentSign(id, {
            accept: false,
            initials: initialName
              ? initialName
              : authContext?.currentUser?.name,
            location: location,
          });

          if (response?.data?.success) {
            setIsEsignActive(false);
            setShowSuccessStampToastWorkFlow(true);
            setWorkFlowBodyMessage("Rejected!.");
            navigate(
              generatePath(routes.overviewContracts, {
                id: id,
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 400);
          }
        } else {
          if (filePath) {
            const formData = new FormData();
            formData.set("file", base64ImageToBlob(filePath));
            formData.set("accept", "true");
            formData.set("initials", initialName);
            formData.set(
              "adress",
              locations && isLocationActive ? locations : ""
            );
            formData.set("designation", desigination ? desigination : "");

            response = await context?.handleDocumentSign(id, formData);
          }
          if (response?.data?.success) {
            setShowSuccessStampToastWorkFlow(true);
            setWorkFlowBodyMessage("Sign successfully.");
            navigate(
              generatePath(routes.overviewContracts, {
                id: id,
              })
            );

            setTimeout(() => {
              window.location.reload();
            }, 400);
          }
        }
      }
    } catch (error) {}
  };

  const [isCoordinatePresent, setIsCoordinatePresent]: [boolean, Function] =
    useState(false);
  const [roleAccess, setRoleAccess]: [boolean, Function] = useState(false);
  const [isUnPublish, setIsUnpublish]: [boolean, Function] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate]: [boolean, Function] =
    useState(false);

  useEffect(() => {
    const jsonArray: any = context?.data?.internalSingers?.concat(
      context?.data?.externalUser?.filter((el: any) => el?.access === "SIGNER")
    );
    const filteredArray = jsonArray?.filter(
      (obj: any) => obj?.co_ordinates?.length !== 0
    );

    if (
      jsonArray?.length !==
      filteredArray?.filter((el: any) => el?.co_ordinates)?.length
    ) {
      setIsCoordinatePresent(false);
    } else {
      setIsCoordinatePresent(true);
    }
  }, [context?.data]);

  let proceedToSign: any =
    context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    ) ||
    context?.data?.internalSingers?.find(
      (el: any) => el?.user?.name === authContext?.currentUser?.name
    );

  useEffect(() => {
    let viwer: any = context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );
    if (viwer?.access === "VIEWER") {
      setIsViewer(true);
    }
  }, [context?.data?.externalUser]);

  useEffect(() => {
    let owner: any = context?.data?.collaborators?.find(
      (el: any) => el?.access === "OWNER"
    );
    if (owner?.user?.email === authContext?.currentUser?.email) {
      setRoleAccess(true);
    }
  }, [context?.data?.collaborators]);

  // const downloadPdf = (pdfUrl: any, fileName: any) =>
  //   fetch(pdfUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = fileName || "downloaded-pdf.pdf";
  //       link.click();
  //     });

  const [cloneBtn, setCloneBtn]: [boolean, Function] = useState(false);

  const onCreateContract = async (type: any) => {
    setCloneBtn(true);
    try {
      let requestBody;
      if (type === "clone") {
        requestBody = {
          name: "Untitled Contract",
          description: "",
          parent_contract: id,
          is_renewal: true,
          is_clone: true,
        };
      } else {
        requestBody = {
          name: "Untitled Contract",
          description: "",
          parent_contract: id,
          is_renewal: true,
        };
      }

      let response = await api?.createContract(requestBody);

      if (response?.data?.success) {
        setCloneBtn(false);
        navigate(
          generatePath(routes.editContract, {
            id: response?.data?.contract?.id,
          })
        );
      } else {
        // setBodyMessage("Contract not created!");
        // setShowFailureToast(true);
      }
    } catch (error) {}
  };

  const [ngWorkFlowStareted, setNgWorkFlowStareted]: [boolean, Function] =
    useState(false);

  console.log(
    context?.data?.counterparty_owner?.find(
      (el: any) =>
        el?.email === authContext?.currentUser?.email && el?.access === "OWNER"
    )?.status,
    context?.data?.counterparty_owner
      ?.find(
        (el: any) =>
          el?.email === authContext?.currentUser?.email &&
          el?.access === "OWNER"
      )
      ?.negotation_viewers?.filter((el: any) => el?.status === "PENDING")
      ?.length !== 0,
    "jdasdjksdjkasjdj"
  );

  const handleNegotiationWorkflow = async () => {
    try {
      let response = await api?.negotiationWorkflow(id, {
        restart: true,
      });
      if (response?.status === 201) {
        setNegotiationOwnerEmail(false);
        setNgWorkFlowStareted(true);
        context?.refresh();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      context?.data?.externalUser?.filter((el: any) => el?.access === "VIEWER")
        ?.length
    ) {
      setNegotiationOwnerEmail(true);
    }
  }, [context?.data?.externalUser]);

  const lastUpdatedOn = async () => {
    try {
      let response = await api?.lastedUpdatedDocument(id);

      if (response?.data?.success) {
        navigate(
          generatePath(routes.editContract, {
            id: context.contract.id,
          })
        );
      }
    } catch (error) {}
  };

  const handleEditWorkflow = async () => {
    try {
      let response = await api?.editNgWorkflow(id);
      if (response?.data) {
        context?.refresh();
        setNgWorkFlowStareted(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    let ng =
      context?.data?.negotiation_owners[
        context?.data?.counterparty_owner?.find(
          (el: any) => el?.email === authContext?.currentUser?.email
        )?.id
      ];

    if (ng?.negotiation_status === "STARTED") {
      setNgWorkFlowStareted(true);
    }
  }, [context?.data?.negotiation_owners]);

  const handleResetWorkflow = async (type: boolean) => {
    try {
      let requestBody = {
        restart: type,
      };
      let response = await api?.negotiationWorkflow(id, requestBody);
      if (response?.status === 201) {
        context?.refresh();
      }
    } catch (error) {}
  };

  return (
    context && (
      <div className=" container-fluid " style={{ marginTop: "60px" }}>
        <div className=" d-flex justify-content-between align-content-center pt-4 flex-wrap">
          <div
            style={{ cursor: "default" }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={context.contract?.name}
          >
            {" "}
            <span>
              <FaArrowLeft
                fontSize={15}
                style={{ marginBottom: "-45px", cursor: "pointer" }}
                onClick={() => navigate(routes.contracts)}
              />
            </span>
            <h5 className=" fw-bold me-3 mb-0 px-4">
              {context.contract.formattedName(61) || "Untitled Document"}
            </h5>
            <div className="text-dark small fw-lighter  LastEdited px-4">
              Last edited on&nbsp;
              {moment(context?.contract?.last_sync_at).format(
                "DD MMMM YYYY, h:mm:ss A"
              )}
              &nbsp;by
              <span className=" ms-1 LastEdited">
                {ownerDetail?.user?.name
                  ? ownerDetail?.user?.name
                  : context?.data?.collaborators?.find(
                      (el: any) => el?.access === "OWNER"
                    )?.user?.name}{" "}
                {ownerDetail?.user?.name
                  ? ownerDetail?.user?.last_name
                  : context?.data?.collaborators?.find(
                      (el: any) => el?.access === "OWNER"
                    )?.user?.last_name}
              </span>
              &nbsp;&nbsp;
              {/* <Badge className="publish-badge" bg="success">
                {
                  context?.contract?.collaborators?.find(
                    (el: any) =>
                      el?.user?.email === authContext?.currentUser?.email
                  )?.access
                }{" "}
              </Badge> */}
              {isLoggedInUser ? (
                <Badge className="publish-badge" bg="success">
                  {ownerDetail?.access}
                </Badge>
              ) : (
                <>
                  <Badge className="publish-badge" bg="success">
                    {isEditorAccess
                      ? isEditorAccess
                      : context?.data?.collaborators?.find(
                          (el: any) => el?.access === "OWNER"
                        )?.access}
                  </Badge>
                  {/* <Badge className="publish-badge" bg="success">
                    {
                      context?.contract?.collaborators?.find(
                        (el: any) =>
                          el?.user?.email === authContext?.currentUser?.email
                      )?.access
                    }{" "}
                  </Badge> */}
                </>
              )}
            </div>
            {/* )} */}
          </div>

          <div className={isMobile ? " d-none" : "d-flex align-items-center"}>
            {!window.location.pathname.includes("workflow") && (
              <>
                <Dropdown drop="down-centered">
                  <Dropdown.Toggle
                    variant="default"
                    className="new-contact-btn create-template contract-css ms-lg-3"
                    id="dropdown-basic"
                    style={{ border: "none" }}
                  >
                    <BiDotsVertical color="gray" fontSize={24} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="create-dropdown-css">
                    {/* <Dropdown.Item
                      className="sort-by"
                      onClick={() => {
                        // downloadPdf(context?.data?.filePath, "document.pdf");
                        context?.handleContractDownload(id);
                      }}
                    >
                      Download
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      className="sort-by"
                      onClick={() => context?.handleContractDownload(id)}
                    >
                      <a
                        href={""}
                        download
                        className=" text-decoration-none Action_download"
                      >
                        Download
                      </a>
                    </Dropdown.Item>
                    {!window.location.pathname.includes("workflow") &&
                      context?.data?.is_stamped !== true &&
                      ![
                        StatusEnum?.published,
                        StatusEnum?.pending_approve,
                        StatusEnum?.sign_initiated,
                        "COMPLETED",
                        StatusEnum?.pending_negotitation,
                        StatusEnum?.approved,
                        "EXPIRED",
                      ].includes(context?.data?.status || "") && (
                        <>
                          {isOwner?.[0]?.access === "OWNER" && (
                            <>
                              {context?.data?.status === StatusEnum?.draft && (
                                <>
                                  {rolesUpdate === "UPDATE" && (
                                    <>
                                      {/* <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => {
                                          // navigate(
                                          //   routes?.publishContractUrl(id, {
                                          //     publish: true,
                                          //   })
                                          // );

                                          context?.data?.name
                                            ?.split(",")?.[0]
                                            ?.includes("Untitled Contract")
                                            ? setRenamePopup(true)
                                            : context?.data?.department
                                            ? setShowDeleteToast(true)
                                            : setPublishPopup(true);
                                        }}
                                      >
                                        Publish
                                      </Dropdown.Item> */}
                                      <Dropdown.Item
                                        className="sort-by"
                                        onClick={() => setRenamePopup(true)}
                                      >
                                        Rename
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {renamePopup && (
                            <UpdateDocumentName
                              show={renamePopup}
                              onHide={() => setRenamePopup(false)}
                              context={context}
                              setDcumentName={() => {}}
                            />
                          )}
                          {context?.data?.status !==
                            StatusEnum?.stamp_initiated &&
                            context?.data?.status !==
                              StatusEnum?.stamp_failed && (
                              <>
                                {context?.data?.status !==
                                  StatusEnum?.stamped && (
                                  <>
                                    {isOwner?.[0]?.access === "OWNER" &&
                                      rolesDelete === "DELETE" && (
                                        <Dropdown.Item
                                          className="sort-by"
                                          onClick={() => {
                                            setShowDeleteTemplateToast(true);
                                          }}
                                        >
                                          Delete
                                        </Dropdown.Item>
                                      )}
                                  </>
                                )}
                              </>
                            )}
                        </>
                      )}
                    {context?.data?.is_stamped !== true &&
                      isOwner?.[0]?.access === "OWNER" &&
                      context?.data?.status !== "EXPIRED" && (
                        <>
                          {rolesUpdate === "UPDATE" && (
                            <>
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() => {
                                  setContractAsTemplate(true);
                                }}
                              >
                                Contract as template
                              </Dropdown.Item>
                            </>
                          )}
                        </>
                      )}
                    {/* {context?.data?.status !== "PUBLISHED" && (
                      <> */}
                    {context?.data?.is_stamped !== true &&
                      authContext?.currentUser?.sub ===
                        isOwner?.[0]?.user?.sub &&
                      isOwner?.[0]?.access === "OWNER" &&
                      context?.data?.status !== "EXPIRED" && (
                        <>
                          {rolesUpdate === "UPDATE" && (
                            <>
                              <Dropdown.Item
                                className="sort-by"
                                onClick={() =>
                                  setIsActiveTransferOwnership(true)
                                }
                              >
                                Transfer ownership
                              </Dropdown.Item>
                            </>
                          )}
                        </>
                      )}
                    {/* </>
                    )} */}

                    {isOwner?.[0]?.access === "OWNER" &&
                      (context?.data?.status === StatusEnum?.published ||
                        context?.data?.status ===
                          StatusEnum?.pending_negotitation ||
                        context?.data?.status === StatusEnum?.rejected) && (
                        <>
                          {roleAccess && (
                            <>
                              {context?.data?.workflow_status !==
                                "PUBLISHED" && (
                                <Dropdown.Item
                                  className="sort-by"
                                  onClick={() => {
                                    setUnPublishedDocument(true);
                                  }}
                                >
                                  Unpublish
                                </Dropdown.Item>
                              )}
                            </>
                          )}
                        </>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
                {context?.data?.status !== StatusEnum?.stamp_initiated &&
                  context?.data?.status !== StatusEnum?.stamp_failed &&
                  context?.data?.status !== StatusEnum?.stamped &&
                  context?.data?.is_stamped !== true && (
                    <>
                      {!window.location.pathname.includes("workflow") &&
                        ![
                          StatusEnum?.published,
                          StatusEnum?.pending_approve,
                          StatusEnum?.sign_initiated,
                          "COMPLETED",
                          "REJECTED",
                          StatusEnum?.pending_negotitation,
                          StatusEnum?.approved,
                          "EXPIRED",
                          "PENDING_NEGOTIATION_APPROVAL",
                        ].includes(context?.data?.status || "") && (
                          <>
                            {isOwner?.[0]?.access === "OWNER" && (
                              <>
                                {rolesUpdate === "UPDATE" && (
                                  <>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className=" d-none d-lg-block"
                                    >
                                      <DocumentCollaborators
                                        context={context}
                                        outline
                                      />
                                    </span>
                                    <div className="addCollab d-flex justify-content-center align-items-center d-lg-none me-3">
                                      <DocumentCollaborators
                                        context={context}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
              </>
            )}
            {!window.location.pathname.includes("workflow") &&
              ![
                StatusEnum?.published,
                StatusEnum?.pending_approve,
                StatusEnum?.sign_initiated,
                "COMPLETED",
              ].includes(context?.data?.status || "") && (
                <>
                  <>
                    <div></div>
                  </>
                </>
              )}
            {context?.data?.createdBy?.sub === authContext?.currentUser?.sub &&
              (context?.data?.status === StatusEnum?.pending_approve ||
                context?.data?.status === StatusEnum?.pending_negotitation) && (
                <>
                  <div
                    className=" d-flex align-items-center me-3"
                    style={{ cursor: "pointer", color: "#337ab7" }}
                    onClick={() => {
                      navigate(
                        routes?.editContractUrl(id, { suggestion: true })
                      );
                    }}
                  >
                    <div className="me-1">
                      <BiCommentEdit id="Add Collaborators" fontSize={20} />
                    </div>
                    <h6
                      className=" m-0 fw-semibold"
                      style={{ fontSize: "14px", cursor: "pointer" }}
                    >
                      {/* Add suggestion */}
                      {context?.data?.createdBy?.sub ===
                      authContext?.currentUser?.sub ? (
                        <span>Review Suggestion</span>
                      ) : (
                        <span>Add suggestion </span>
                      )}
                    </h6>
                  </div>
                </>
              )}
            {(window.location.pathname.includes("overview") ||
              window.location.pathname.includes("variables") ||
              window.location.pathname.includes("activities") ||
              window.location.pathname.includes("hierarchy") ||
              window.location.pathname.includes("version")) &&
              ![
                StatusEnum?.published,
                StatusEnum?.pending_approve,
                StatusEnum?.sign_initiated,
                "COMPLETED",
                StatusEnum?.stamped,
                StatusEnum?.stamp_initiated,
                StatusEnum?.stamp_failed,
                StatusEnum?.rejected,
                StatusEnum?.pending_negotitation,
                StatusEnum?.approved,
                StatusEnum?.pending_negotitation_approval,
                "EXPIRED",
                "PENDING",
              ].includes(context?.data.status || "") && (
                <>
                  {
                    // isEditorAccess === "EDITOR"
                    (isOwner?.[0]?.access === "OWNER" ||
                      isOwner?.[0]?.access === "EDITOR") && (
                      <>
                        {rolesUpdate === "UPDATE" && (
                          <>
                            <Stack
                              direction="horizontal"
                              className="align-items-center"
                            >
                              {context &&
                                context.contract &&
                                context.contract.id && (
                                  <a
                                    // href={}
                                    onClick={() => {
                                      lastUpdatedOn();
                                    }}
                                    className=" rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white btn btn-primary btn-sm"
                                  >
                                    Go to editor
                                  </a>
                                )}
                            </Stack>
                          </>
                        )}
                      </>
                    )
                  }
                </>
              )}
            {context?.data?.internalSingers?.[0]?.status !== "REJECTED" &&
              context?.data?.status_reason === "SIGN_REJECTED" && (
                <>
                  {" "}
                  <div className="">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between bg-trans-dropdown"
                        id="dropdown-basic"
                        disabled={cloneBtn}
                        onClick={() => {
                          onCreateContract("clone");
                        }}
                      >
                        Clone Contract
                      </Dropdown.Toggle>
                    </Dropdown>
                    &nbsp; &nbsp;
                  </div>
                </>
              )}
            {(location?.search === "?renew=true" ||
              context?.data?.status === "COMPLETED" ||
              context?.data?.status === "EXPIRED") && (
              <>
                {authContext?.currentUser?.email ===
                  context?.data?.collaborators?.find(
                    (el: any) => el?.access === "OWNER"
                  )?.user?.email && (
                  <div className="">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between bg-trans-dropdown"
                        id="dropdown-basic"
                      >
                        Renew Contract
                        <span className="ps-2">
                          <MdOutlineArrowDropDown fontSize={18} />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="create-dropdown-css">
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            onCreateContract("new");
                          }}
                        >
                          New Contract
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => setShowCreateTemplate(true)}
                        >
                          View Templates
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            onCreateContract("clone");
                          }}
                        >
                          Clone Contract
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    &nbsp; &nbsp;
                  </div>
                )}
              </>
            )}

            {showCreateTemplate && (
              <CreateContractPopup
                show={showCreateTemplate}
                onHide={() => setShowCreateTemplate(false)}
              />
            )}

            {context?.data?.internalSingers?.filter(
              (item: any) =>
                item?.user?.sub === authContext?.currentUser?.sub &&
                item?.status === "PENDING"
            )?.length ||
            context?.data?.externalUser?.filter(
              (el: any) =>
                el?.email === authContext?.currentUser?.email &&
                el?.status === "PENDING"
            )?.length ? (
              <>
                {window.location.pathname.includes("overview") &&
                  (location?.search === "?sign=true" ||
                    (context?.data?.status === "SIGN_INITIATED" &&
                      !isViewer)) && (
                    <>
                      {context?.data?.sign_type === "e-Signature" ? (
                        <>
                          <Stack
                            direction="horizontal"
                            className="align-items-center mx-3"
                          >
                            {context &&
                              context.contract &&
                              context.contract.id && (
                                <a
                                  className=" rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white btn btn-primary btn-sm"
                                  onClick={() => {
                                    setIsEsignActive(true);
                                  }}
                                >
                                  {proceedToSign &&
                                  proceedToSign?.status === "PENDING"
                                    ? "Proceed to esign"
                                    : !isViewer
                                    ? "Proceed to esign"
                                    : "You Signed!"}
                                </a>
                              )}
                          </Stack>
                        </>
                      ) : (
                        <div className=" d-flex align-items-center ">
                          <div className="me-1"></div>
                          <h6
                            className=" m-0 fw-semibold"
                            style={{
                              fontSize: "15px",
                              minWidth: "145px",
                              color: "#3c3ada",
                            }}
                          >
                            Check mail to Sign
                          </h6>
                        </div>
                      )}
                    </>
                  )}
              </>
            ) : (
              ""
            )}

            {isEsignApproved && (
              <SignaturePopup
                setSignatureDetail={setSignatureDetail}
                isActiveSignature={isActiveSignature}
                setIsActiveSignature={setIsActiveSignature}
                setInitialName={setInitialName}
                initialName={initialName}
                setAddSignature={setAddSignature}
                addSignature={addSignature}
                show={isEsignApproved}
                setLocation={setLocation}
                desigination={desigination}
                setDesigination={setDesigination}
                locations={locations}
                setIsLocationActive={setIsLocationActive}
                uploadFile={uploadFile}
                onHide={() => {
                  setIsEsignApproved(false);
                  setIsLocationActive(false);
                }}
                handleSign={({
                  type,
                  filePath,
                  location,
                }: {
                  type: string;
                  filePath: string;
                  location: string;
                }) => handleSign(type, filePath, location)}
              />
            )}
            {isFileUpload && (
              <>
                <DeleteToast
                  onSubmit={() => {
                    setIsFileUpload(false);
                  }}
                  title="Are you sure you want to upload this file ?"
                  closeText="No"
                  submitText="Yes"
                  onCross={() => {}}
                  onClose={() => {
                    setUploadFile(true);
                  }}
                />
              </>
            )}

            {isEsignActive && (
              <DeleteToast
                onSubmit={() => {
                  setIsEsignApproved(true);
                  setIsEsignActive(false);
                }}
                title="Are you sure you want to sign this document?"
                closeText="No"
                submitText="Yes"
                onCross={() => {
                  setIsEsignActive(false);
                }}
                onClose={() => {
                  setIsEsignActive(false);

                  handleSign("reject");
                }}
              />
            )}

            {location?.search === "?signature=true" && (
              <>
                <Stack direction="horizontal" className="align-items-center">
                  <Button
                    size="sm"
                    disabled={editWorkflow}
                    className="me-3  px-3 py-2 fw-semibold d-flex text-align-center sharebtn "
                    onClick={() => setConfirmCoordinatePopup(true)}
                  >
                    Confirm co-ordinates
                  </Button>
                </Stack>
              </>
            )}
            {confirmCoordinatePopup && (
              <DeleteToast
                onSubmit={handleConfirmCoordinate}
                onCross={() => {}}
                title="Are you sure you want to confirm this co-ordinates ?"
                closeText="No"
                submitText="Yes"
                onClose={() => setConfirmCoordinatePopup(false)}
              />
            )}
            {confirmCoordinate && (
              <CoordinatePopup
                show={confirmCoordinate}
                onHide={() => setConfirmCoordinate(false)}
              />
            )}

            {suggeestion && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  navigate(routes?.editContractUrl(id, { suggestion: true }));
                }}
                onClose={() => setSuggeestion(false)}
                title={`Alert! Adding suggestions to this contract will automatically imply a rejection. Please select "continue" if you want to proceed.`}
                closeText="Cancel"
                submitText="Continue"
                loader={loader}
              />
            )}
            {(context?.data.status === StatusEnum?.pending_approve ||
              (context?.data?.status === StatusEnum?.pending_negotitation &&
                !isApproved)) && (
              <>
                <ApproveButton
                  setIsNegotiationWorkflow={setIsNegotiationWorkflow}
                  setSuggeestion={setSuggeestion}
                  setRejectApproval={setRejectApproval}
                  setIsApprovedACtive={setIsApprovedACtive}
                />
              </>
            )}
            {isLoggedInUser &&
              context?.data?.internalSingers?.filter(
                (user: any) => user?.status === "SIGNED"
              )?.length === 0 &&
              context?.data?.externalUser?.filter(
                (user: any) => user?.status === "APPROVED"
              )?.length === 0 &&
              window.location.pathname.includes("workflow") &&
              editWorkflowStarted && (
                <>
                  {context?.data?.is_stamped !== true && (
                    <>
                      <Stack
                        direction="horizontal"
                        className="align-items-center"
                      >
                        <Button
                          size="sm"
                          disabled={editWorkflow}
                          className="me-3  px-3 py-2 fw-semibold d-flex text-align-center sharebtn "
                          onClick={() => SetEditWorkflow(true)}
                        >
                          Edit Workflow
                        </Button>
                      </Stack>
                    </>
                  )}
                </>
              )}
            {(context?.contract?.status === StatusEnum?.stamp_failed ||
              context?.contract?.status === StatusEnum?.approved) && (
              <>
                {!stampDone &&
                  context?.data?.stamp_required === true &&
                  context?.data?.createdBy?.email ===
                    authContext?.currentUser?.email && (
                    <StampButton
                      setShowSuccessStampToastWorkFlow={
                        setShowSuccessStampToastWorkFlow
                      }
                      SetStampDone={SetStampDone}
                      setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
                      setWorkFlowBodyMessage={setWorkFlowBodyMessage}
                    />
                  )}
              </>
            )}
            {window.location.pathname.includes("workflow") &&
              isLoggedInUser &&
              context?.data?.internalSingers?.filter(
                (user: any) => user?.status === "SIGNED"
              )?.length === 0 &&
              ((!resumeWorkflowIsActive &&
                window.location.pathname.includes("workflow")) ||
                rejectStatus) && (
                <>
                  {context?.data?.is_stamped !== true && (
                    <>
                      {context?.data?.status !== "EXPIRED" &&
                        context?.data?.status === "REJECTED" &&
                        // context?.data?.internalSingers?.[0]?.status !==
                        //   "REJECTED" &&
                        context?.data?.approvers?.[0]?.status !== "REJECTED" &&
                        context?.data?.externalUser?.filter(
                          (user: any) => user?.status === "APPROVED"
                        )?.length === 0 && (
                          <>
                            <Stack
                              direction="horizontal"
                              className="align-items-center"
                            >
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  className="me-3  px-3 fw-semibold d-flex text-align-center sharebtn"
                                  id="dropdown-basic"
                                >
                                  Reset Workflow
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="create-dropdown-css">
                                  <>
                                    <Dropdown.Item
                                      className="sort-by"
                                      onClick={() => {
                                        setResumeBtnClick(true);
                                      }}
                                    >
                                      Resume
                                    </Dropdown.Item>
                                  </>
                                  {/* )} */}

                                  <Dropdown.Item
                                    className="sort-by"
                                    onClick={() => {
                                      setStartFRomBegnning(true);
                                      setIsApproved(true);
                                      context?.refresh();
                                    }}
                                  >
                                    From beginning
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Stack>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            {unPublishDocument && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  onUnPublishedDocument();
                }}
                onClose={() => setUnPublishedDocument(false)}
                title="Do you want to unpublish this document?"
                closeText="No"
                submitText="Yes"
                loader={loader}
              />
            )}
            {context?.data?.status ===
              StatusEnum?.pending_negotitation_approval &&
              context?.data?.collaborators?.find(
                (user: any) => user?.access === "OWNER"
              )?.user?.name === authContext?.currentUser?.name && (
                <>
                  <Stack direction="horizontal" className="align-items-center">
                    <Button
                      variant="primary"
                      size="sm"
                      className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                      onClick={() => {
                        navigate(
                          generatePath(routes.editContract, {
                            id: context.contract.id,
                          })
                        );
                      }}
                    >
                      Review
                    </Button>
                  </Stack>
                </>
              )}

            {isActiveCoordinate && (
              <EsignPopup
                show={isActiveCoordinate}
                onHide={() => setIsActiveCoordinate(false)}
                setpdfPopup={setpdfPopup}
              />
            )}

            {pdfPopup && (
              <PdfPopup
                show={pdfPopup}
                onHide={() => setpdfPopup(false)}
                viewCoordinates={false}
              />
            )}
            {
              <>
                <>
                  {isLoggedInUser && isWorkflowListAdded && (
                    <>
                      {window.location.pathname.includes("workflow") &&
                        ![
                          StatusEnum?.pending_approve,
                          StatusEnum?.sign_initiated,
                          StatusEnum?.completed,
                          StatusEnum?.stamped,
                          StatusEnum?.stamp_initiated,
                          StatusEnum?.rejected,
                          StatusEnum?.stamp_failed,
                          StatusEnum?.approved,
                          StatusEnum?.pending_negotitation,
                          StatusEnum?.pending_negotitation_approval,
                          "EXPIRED",
                        ].includes(context?.data.status || "") && (
                          <>
                            {!startWorkFlow && isCoordinatePresent && (
                              <Stack
                                direction="horizontal"
                                className="align-items-center"
                              >
                                {roleAccess && (
                                  <>
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                                      onClick={() => {
                                        context?.data?.externalUser?.filter(
                                          (el: any) => el?.access === "SIGNER"
                                        )?.length ===
                                          context?.data?.externalUser?.filter(
                                            (el: any) => el?.co_ordinates
                                          )?.length &&
                                        context?.data?.internalSingers?.filter(
                                          (el: any) => el?.co_ordinates
                                        )?.length ===
                                          context?.data?.internalSingers?.length
                                          ? setStartWorkflow(true)
                                          : setIsActiveCoordinate(true);
                                      }}
                                    >
                                      Start Workflow
                                    </Button>
                                  </>
                                )}
                              </Stack>
                            )}
                          </>
                        )}
                    </>
                  )}
                </>
                {/* )} */}
              </>
            }
            {context?.data?.workflow_status !== "DRAFT" &&
              context?.data?.counterparty_owner?.find(
                (el: any) => el?.email === authContext?.currentUser?.email
              )?.status !== "REJECTED" &&
              context?.data?.counterparty_owner?.find(
                (el: any) => el?.email === authContext?.currentUser?.email
              )?.negotation_viewers?.length > 0 && (
                <>
                  {context?.data?.negotiation_owners[
                    context?.data?.counterparty_owner?.find(
                      (el: any) => el?.email === authContext?.currentUser?.email
                    )?._id
                  ]?.negotiation_status !== "COMPLETED" &&
                    context?.data?.negotiation_owners[
                      context?.data?.counterparty_owner?.find(
                        (el: any) =>
                          el?.email === authContext?.currentUser?.email
                      )?._id
                    ]?.negotiation_status !== "STARTED" && (
                      <>
                        {negotiationOwnerEmail && (
                          <>
                            <Stack
                              direction="horizontal"
                              className="align-items-center"
                            >
                              <>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                                  onClick={() => handleNegotiationWorkflow()}
                                >
                                  Start Workflow
                                </Button>
                              </>
                            </Stack>
                          </>
                        )}
                      </>
                    )}
                </>
              )}

            {/* .................................................ng owner........................ */}
            {context?.data?.counterparty_owner?.find(
              (el: any) =>
                el?.email === authContext?.currentUser?.email &&
                el?.access === "OWNER"
            ) &&
              context?.data?.counterparty_owner?.find(
                (el: any) =>
                  el?.email === authContext?.currentUser?.email &&
                  el?.access === "OWNER"
              )?.status !== "REJECTED" &&
              context?.data?.counterparty_owner
                ?.find(
                  (el: any) =>
                    el?.email === authContext?.currentUser?.email &&
                    el?.access === "OWNER"
                )
                ?.negotation_viewers?.filter(
                  (el: any) => el?.status === "PENDING"
                )?.length !== 0 && (
                <>
                  {ngWorkFlowStareted && (
                    <>
                      <Stack
                        direction="horizontal"
                        className="align-items-center"
                      >
                        <Button
                          size="sm"
                          disabled={editWorkflow}
                          className="me-3  px-3 py-2 fw-semibold d-flex text-align-center sharebtn "
                          onClick={() => handleEditWorkflow()}
                        >
                          Edit Workflow
                        </Button>
                      </Stack>
                    </>
                  )}
                </>
              )}

            {context?.data?.counterparty_owner?.find(
              (el: any) =>
                el?.email === authContext?.currentUser?.email &&
                el?.access === "OWNER"
            ) &&
              context?.data?.counterparty_owner?.find(
                (el: any) =>
                  el?.email === authContext?.currentUser?.email &&
                  el?.access === "OWNER"
              )?.negotation_viewers?.[1]?.status === "REJECTED" && (
                <>
                  {" "}
                  <Stack direction="horizontal" className="align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="me-3  px-3 fw-semibold d-flex text-align-center sharebtn"
                        id="dropdown-basic"
                      >
                        Reset Workflow
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="create-dropdown-css">
                        {resumeWorkflow?.status !== StatusEnum?.rejected && (
                          <>
                            <Dropdown.Item
                              className="sort-by"
                              onClick={() => {
                                handleResetWorkflow(false);
                              }}
                            >
                              Resume
                            </Dropdown.Item>
                          </>
                        )}

                        <Dropdown.Item
                          className="sort-by"
                          onClick={() => {
                            handleResetWorkflow(true);
                          }}
                        >
                          From beginning
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Stack>
                </>
              )}

            {/* )} */}

            {/* {isLoggedInUser && context?.data?.status === "REJECTED" && (
              <>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
                  onClick={() => setIsUnpublish(true)}
                >
                  Unpublish
                </Button>
              </>
            )} */}
            {isUnPublish && (
              <DeleteToast
                onCross={() => {}}
                onSubmit={() => {
                  onUnPublishedRejectWorkflow();
                }}
                title="Unpublishing the contract will reset the workflow. Please proceed if you are sure."
                closeText="No"
                submitText="Yes"
                onClose={() => setIsUnpublish(false)}
                loader={loader}
              />
            )}
          </div>
        </div>
        <div
          className={
            isMobileOnly
              ? "d-flex align-items-center justify-content-end py-3"
              : "d-none"
          }
        >
          <DocumentCollaborators context={context} outline />

          <Stack direction="horizontal" className="align-items-center">
            <Button
              variant="success"
              size="sm"
              className="mx-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
            >
              Publish
            </Button>
          </Stack>
        </div>
        <div>
          {context?.contract.documentId && (
            <OverviewNestedBar
              isActiveOverView="contracts"
              startWorkFlow={startWorkFlow}
              setStartWorkflow={setStartWorkflow}
              documentId={context?.contract.documentId}
              context={context}
              setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
              setWorkFlowBodyMessage={setWorkFlowBodyMessage}
              setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
              setIsWorkflowListAdded={setIsWorkflowListAdded}
              resumeBtnClick={resumeBtnClick}
              startFromBegnning={startFromBegnning}
              setResumeWorkflowIsActive={setResumeWorkflowIsActive}
              setRejectStatus={setRejectStatus}
              startTemplateWorkflow={false}
              editWorkflow={editWorkflow}
              SetEditWorkflow={SetEditWorkflow}
              setEditWorkflowStarted={setEditWorkflowStarted}
              setIsCounterpartySigner={() => {}}
            />
          )}
        </div>
        {contractAsTemplate && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => onContractToTemplateConversion()}
            title="Do you want to clone the contract as template ?"
            closeText="No,don't"
            submitText="Yes, clone it"
            onClose={() => setContractAsTemplate(false)}
          />
        )}
        {show && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={handleDelete}
            title="Are you sure you want to delete this ?"
            closeText="No"
            submitText="Yes"
            onClose={() => setShow(false)}
          />
        )}
        {showDeleteToast && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => {
              onPublishDocument();
            }}
            onClose={() => setShowDeleteToast(false)}
            title={
              commentsLength !== 0
                ? "There are unresolved comments are you sure you want to publish?"
                : "Do you want to publish this document?"
            }
            closeText="No"
            submitText="Yes"
            loader={loader}
          />
        )}
        {showDeleteTemplateToast && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => deleteContract()}
            onClose={() => setShowDeleteTemplateToast(false)}
            title="Are you sure you want to delete this contract?"
            closeText="No"
            submitText="Yes"
          />
        )}
        {showSuccessStampToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessStampToast}
            close={() => {
              setShowSuccessStampToastWorkFlow(false);
            }}
          />
        )}
        {showSuccessToast && (
          <SuccessToast
            icon={
              <MdCheckCircle
                className="text-success"
                style={{ fontSize: "64px" }}
              />
            }
            message={bodyMessage}
            title={"Success!"}
            show={showSuccessToast}
            close={() => {
              setShowSuccessToastWorkFlow(false);
            }}
          />
        )}
        {showWorkflowFailureToast && (
          <FailureToast
            icon={
              <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
            }
            message={bodyMessage}
            title={"Failure!"}
            show={showWorkflowFailureToast}
            close={() => {
              setWorkFlowShowFailureToast(false);
            }}
          />
        )}
        {rejectApproval && (
          <RejectPopup
            show={rejectApproval}
            onHide={() => setRejectApproval(false)}
            isApprovedActive={isApprovedActive}
            setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
            setWorkFlowBodyMessage={setWorkFlowBodyMessage}
            setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
            setIsApproved={setIsApproved}
          />
        )}

        {isNegotiationWorkflow && (
          <DeleteToast
            onCross={() => {}}
            onSubmit={() => {
              setRejectApproval(true);
              setIsApprovedACtive("Approve");
              setIsNegotiationWorkflow(false);
            }}
            onClose={() => setIsNegotiationWorkflow(false)}
            title={`This is an automated workflow. Once receiving the required approvals, the contract will be shared directly with the signatories. Please select "proceed" if you are sure.`}
            closeText="Cancel"
            submitText="Proceed"
          />
        )}
        {isActiveTransferOwnerShip && (
          <TransferOwnerShipPopup
            show={isActiveTransferOwnerShip}
            onHide={() => setIsActiveTransferOwnership(false)}
            context={context}
            setShowSuccessToastWorkFlow={setShowSuccessToastWorkFlow}
            setWorkFlowBodyMessage={setWorkFlowBodyMessage}
            setWorkFlowShowFailureToast={setWorkFlowShowFailureToast}
          />
        )}

        {publishPopup && (
          <PublishDocuentPopup
            show={publishPopup}
            onHide={() => setPublishPopup(false)}
            context={context}
            setExpiryDate={setExpiryDate}
            expiryDate={expiryDate}
            setShowPublishToast={setShowDeleteToast}
          />
        )}
      </div>
    )
  );
};

const ApproveButton = ({
  setRejectApproval,
  setIsApprovedACtive,
  setSuggeestion,
  setIsNegotiationWorkflow,
}: {
  setRejectApproval: Function;
  setIsApprovedACtive: Function;
  setSuggeestion: Function;
  setIsNegotiationWorkflow: Function;
}) => {
  const authContext = useAuthentication();
  const context = useContract();
  const [loading] = useState(false);
  const { id } = useParams();

  const [isApproved, setIsApproved]: [boolean, Function] = useState(false);
  const [isCounterparty, setIsCounterparty]: [boolean, Function] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      context?.data?.status === StatusEnum?.pending_approve ||
      context?.data?.status === StatusEnum?.pending_negotitation
    ) {
      const isActive = context?.data?.approvers.some(
        ({ user_id }: { user_id: string }) =>
          user_id === authContext?.currentUser?.id
      );

      setIsApproved(isActive);
    }

    if (context?.data?.status === StatusEnum?.pending_negotitation) {
      if (
        context?.data?.approvers?.filter((el: any) => el?.is_optional === true)
      ) {
        setIsApproved(true);
      } else {
        const isActive = context?.data?.externalUser.some(
          ({ email }: { email: string }) =>
            email === authContext?.currentUser?.email
        );

        setIsApproved(isActive);
      }
    }
  }, []);

  const currentUserAccess = () => {
    let data: any;

    const currentUserIndex: any = context?.data?.approvers?.findIndex(
      (item: IApprovalResponse) =>
        item?.status === "PENDING" &&
        item?.user?.sub === authContext?.currentUser?.sub
    );

    if (currentUserIndex > -1) {
      data =
        currentUserIndex === 0
          ? context?.data?.approvers[currentUserIndex]
          : context?.data?.approvers[currentUserIndex - 1];
    }
    const firstPendingUserIndex: any = context?.data?.approvers?.findIndex(
      (item: IApprovalResponse) => item?.status === "PENDING"
    );

    return firstPendingUserIndex > -1 && currentUserIndex === 0
      ? data?.status === "PENDING"
      : getPastUpdatedStatus(data);
  };

  const getPastUpdatedStatus = (data: any) => {
    if (
      !!context?.data?.approvers?.filter(
        (item: IApprovalResponse) => item?.status === StatusEnum?.approved
      )?.length
    ) {
      return data?.status === StatusEnum?.approved
        ? true
        : data?.status === "PENDING" && data?.is_optional;
    } else if (
      !!context?.data?.approvers?.filter(
        (item: IApprovalResponse) => item?.status === StatusEnum?.rejected
      )?.length
    ) {
      return false;
    }
  };

  const [viwerNegotiation, setviwerNegotiation]: [any, Function] = useState();
  const [ownerNegotiation, setOwnerNegotiation]: [any, Function] = useState();

  useEffect(() => {
    let viwer: any = context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );
    setviwerNegotiation(viwer);

    let owner: any = context?.data?.externalUser?.find((el: any) => {
      return el?.email === authContext?.currentUser?.email;
    });
    if (owner?.status === "PENDING") {
      setOwnerNegotiation(true);
    }
  }, []);

  useEffect(() => {
    let viewer: any = context?.data?.externalUser?.find(
      (el: any) => el?.email === authContext?.currentUser?.email
    );

    if (
      viewer?.access === "SIGNER" &&
      context?.data?.approvers?.filter((el: any) => el?.status === "PENDING")
        ?.length !== 0
    ) {
      setIsCounterparty(true);
    }
  }, [context?.data?.externalUser]);

  const [ngViewerStatus, setNgViewerStatus]: [any, Function] = useState();

  useEffect(() => {
    let ngViewer: any = context?.data?.externalUser?.find(
      (el: any) =>
        el?.email === authContext?.currentUser?.email && el?.access === "VIEWER"
    );
    if (ngViewer) {
      setNgViewerStatus(ngViewer?.status);
    }
  }, []);

  return (
    authContext &&
    context && (
      <>
        {
          // isOptional ||
          // isOptional ||
          ((isApproved &&
            viwerNegotiation?.access !== "VIEWER" &&
            currentUserAccess()) ||
            (ownerNegotiation && ngViewerStatus !== "APPROVED")) && (
            <>
              {context?.data?.createdBy?.sub !==
                authContext?.currentUser?.sub && (
                <>
                  <div
                    className=" d-flex align-items-center me-3"
                    style={{ cursor: "pointer", color: "#337ab7" }}
                    onClick={() => {
                      setSuggeestion(false);
                      navigate(
                        routes?.editContractUrl(id, { suggestion: true })
                      );
                    }}
                  >
                    <div className="me-1">
                      <BiCommentEdit id="Add Collaborators" fontSize={20} />
                    </div>
                    <h6
                      className=" m-0 fw-semibold"
                      style={{ fontSize: "14px", cursor: "pointer" }}
                    >
                      {context?.data?.createdBy?.sub ===
                      authContext?.currentUser?.sub ? (
                        <span>Review Suggestion</span>
                      ) : (
                        <span>Add suggestion </span>
                      )}
                      {/* Add suggestion */}
                    </h6>
                  </div>
                </>
              )}
              {
                <>
                  {!isCounterparty && (
                    <>
                      <Button
                        variant="outline-danger"
                        className="cancelbtn me-3 "
                        onClick={() => {
                          setRejectApproval(true);
                          setIsApprovedACtive("Reject");
                        }}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="success"
                        className="sharebtndanger "
                        onClick={() => {
                          let loggedInDetail: any =
                            context?.data?.externalUser?.find(
                              (el: any) =>
                                el?.email === authContext?.currentUser?.email
                            );

                          if (loggedInDetail?.access === "VIEWER") {
                            setRejectApproval(true);
                            setIsApprovedACtive("Approve");
                          } else {
                            context?.data?.status === "PENDING_NEGOTIATION" &&
                            context?.data?.externalUser?.filter(
                              (el: any) => el?.access === "VIEWER"
                            ) &&
                            context?.data?.workflow_status !==
                              "PENDING_NEGOTIATION_APPROVAL"
                              ? setIsNegotiationWorkflow(true)
                              : setRejectApproval(true);
                            setIsApprovedACtive("Approve");
                          }
                        }}
                        disabled={loading}
                      >
                        Approve
                      </Button>
                    </>
                  )}
                  &nbsp;&nbsp;
                </>
              }
            </>
          )
        }
      </>
    )
  );
};

const StampButton = ({
  setShowSuccessStampToastWorkFlow,
  setWorkFlowBodyMessage,
  SetStampDone,
  setWorkFlowShowFailureToast,
}: {
  setShowSuccessStampToastWorkFlow: Function;
  setWorkFlowBodyMessage: Function;
  SetStampDone: Function;
  setWorkFlowShowFailureToast: Function;
}) => {
  const context = useContract();
  const { id } = useParams();
  const navigate = useNavigate();
  const authContext = useAuthentication();
  const initialValues: { email: string } = { email: "" };
  const validationSchema = Yup.object({
    name: Yup.string().required("Frist party name is required"),
  });
  const [loader, setLoader]: [boolean, Function] = useState(false);
  // const [isstampLocation, setStampLocation]: [any, Function] = useState();
  const [secondPartyName, setSecondPartyName]: [string, Function] =
    useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e: any) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputValue));
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [paidValue, setPaidValue] = useState("");

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidSecondParty, setIsValidSecondParty]: [boolean, Function] =
    useState(true);

  const handleFirstNameChange = (e: any) => {
    const inputValue = e.target.value;
    setFirstName(inputValue);
    setIsValidFirstName(inputValue.trim() !== "");
  };

  const handleLastNameChange = (e: any) => {
    const inputValue = e.target.value;
    setLastName(inputValue);
    setIsValidLastName(inputValue.trim() !== "");
  };

  const handleSubmit = async (
    _values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    setSubmitting(false);
  };
  const [show, setShow] = useState(false);
  const onStampClick = async () => {
    setLoader(true);
    try {
      let requestBody = {
        first_party_name:
          authContext && authContext?.currentUser?.organizationName,
        second_party_name: secondPartyName,
        stamp_duty_paid_by: firstName + " " + lastName,
        stamp_duty_value: parseInt(paidValue),
        location: "bangalore",
      };
      let res = await context?.handleStampContract(id, requestBody);

      if (res?.success) {
        setLoader(false);
        SetStampDone(true);
        navigate(routes.contracts);
        setWorkFlowBodyMessage("Stamp done!");
        setShowSuccessStampToastWorkFlow(true);
        setShow(false);
      } else {
        setLoader(false);
        setWorkFlowShowFailureToast(true);
        setWorkFlowBodyMessage(res?.error?.[0]);
        setShow(false);
      }
    } catch (error) {}
  };

  const stampLocation = [
    { value: "89ad9c1d-17e1-43ba-8403-591726f35c13", label: "KARNATAKA" },

    { value: "c51c49fc-b485-4a98-ac87-09cffe217f00", label: "ANDHRA-PRADESH" },
    {
      value: "b2aec117-502f-4649-add6-1fbdebf638b7",
      label: "ARUNACHAL-PRADESH",
    },
    { value: "c86e8962-2163-4f53-9b7d-5956e4fedeb1", label: "ASSAM" },
    { value: "afe39dfb-19bb-4bf4-a744-f31c90272322", label: "BIHAR" },
    { value: "4861c0c5-262c-4d60-b79d-05dd54e59a6a", label: "CHHATTISGARH" },
    { value: "6feb3737-7708-47b2-b035-9743e8a3d7cf", label: "GOA" },
    { value: "ac701ef3-fc1b-4ccb-a058-1de671a910dc", label: "GUJARAT" },
    { value: "b7af2b9b-234d-419e-8845-42c5025de446", label: "HARYANA" },
    {
      value: "8cbcddb2-6936-4de7-a40a-e9534a727e62",
      label: "HIMACHAL-PRADESH",
    },
    { value: "62e89d2c-e655-48d1-bf40-a0f696d72cb0", label: "JHARKHAND" },
    { value: "f0c4a7ba-aa1f-4ba3-b437-c5c3f27b902c", label: "KERALA" },
    { value: "bd62c3b6-1c09-4474-bb23-847ff7b096be", label: "MADHYA-PRADESH" },
    { value: "7aa628e9-5b8c-48a7-9fb2-c2961acf1fa3", label: "MAHARASHTRA" },
    { value: "6c4d808a-9688-4c5c-a93b-db9389288367", label: "MANIPUR" },
    { value: "249be9af-baa9-4807-b9e2-36a306407e8b", label: "MEGHALAYA" },
    { value: "b98bd236-33ff-4cef-9d3a-962794807209", label: "MIZORAM" },
    { value: "f7904a67-740b-4d73-80b2-4f2a764cba5a", label: "NAGALAND" },
    { value: "e0e76968-569e-4f88-b63c-329ea1cc1bb6", label: "ODISHA" },
    { value: "6777dcb1-e066-469a-9466-ee0a87b489c5", label: "PUNJAB" },
    { value: "d1466d6a-f2ad-4744-a991-2b54bad61c41", label: "RAJASTHAN" },
    { value: "1f8ed698-7096-4be3-9260-f6aecc0a8111", label: "SIKKIM" },
    { value: "6290c25b-369c-43a3-b68d-afe1f1baf5d8", label: "TAMIL-NADU" },
    { value: "c709aebd-45b3-49bd-99a4-c265b80b4c5a", label: "TELANGANA" },
    { value: "cbdb6278-f6cf-4f75-8feb-3fdbe89b6e0b", label: "TRIPURA" },
    { value: "b35b6a4e-36bf-4599-9cb2-538bb2f9b96a", label: "UTTARAKHAND" },
    { value: "5b7b0e05-11d2-4cd7-bca8-2b03d69d5dcc", label: "UTTAR-PRADESH" },
    { value: "271d6702-7675-47bd-a8d3-915a0c6792bf", label: "WEST-BENGAL" },
    {
      value: "431badb6-c048-4ed8-837f-837c5db8af7d",
      label: "ANDAMAN-AND-NICOBAR-ISLANDS",
    },
    { value: "0faa0b3a-733c-4023-890b-aa4248f503ea", label: "CHANDIGARH" },
    {
      value: "d6026c5e-6488-4810-ab51-68a26ba737b8",
      label: "DADRA-AND-NAGAR-HAVELI-AND",
    },
    { value: "c46b1238-7ab6-40ed-ad3f-46891a3ee26c", label: "DAMAN-&-DIU" },
    {
      value: "bf944c1e-c4cf-4f77-9413-24eb4cff1bd0",
      label: "THE-GOVERNMENT-OF-NCT-OF-DELHI",
    },
    { value: "2d5a7b0d-7c3a-454c-a305-6afe3205070d", label: "JAMMU-&-KASHMIR" },
    { value: "aa939a28-990b-401c-b820-f993794a07a7", label: "LADAKH" },
    { value: "763f6f39-7397-46a9-b563-959dc7b6e1ab", label: "LAKSHADWEEP" },
    { value: "a0aab095-5617-4029-9a79-6d46defd8a39", label: "PUDUCHERRY" },
    { value: "28dab947-e744-45ad-828b-d8196c40bbf5", label: "MAHARASHTRA-2" },
  ];
  const handleValidAmountChange = (e: any) => {
    const inputValue = e.target.value;
    setPaidValue(inputValue);

    setIsValidNumber(/^\d+$/.test(inputValue));
  };

  const isValidPartyName = (name: any) => {
    return name.trim() !== "";
  };

  const checkConditions = () => {
    return (
      isValidPartyName(firstName) &&
      isValidPartyName(lastName) &&
      isValidPartyName(secondPartyName) &&
      isValidPartyName(paidValue)
    );
  };

  return (
    <>
      <Stack direction="horizontal" className="align-items-center">
        <Button
          variant="success"
          size="sm"
          className="me-3 rounded-0 px-3 py-2 fw-semibold d-flex text-align-center text-white"
          onClick={() => {
            setShow(true);
          }}
        >
          Stamp
        </Button>
      </Stack>
      {show && (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          animation
          size="lg"
          centered
        >
          <Modal.Header closeButton onHide={() => setShow(false)}>
            <div className=" d-flex justify-content-between align-items-center ">
              <h6 className=" fw-bold">Stamp</h6>
            </div>
          </Modal.Header>
          <Modal.Body className="p-4 mb-2 ">
            <div
              style={{
                maxHeight: "66vh",
                overflowY: "scroll",
                overflowX: "hidden",
                marginInlineEnd: "10px",
              }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} style={{ marginRight: "20px" }}>
                    {authContext && (
                      <>
                        <div className="input_label">First Party</div>
                        <input
                          className={
                            "select-wrapper basic-multi-select org_name_stamp"
                          }
                          value={authContext?.currentUser?.organizationName}
                          disabled
                        />
                      </>
                    )}
                    <div className="input_label">Second Party</div>
                    <input
                      type="text"
                      className="form-control mb-3"
                      value={secondPartyName}
                      onChange={(e) => {
                        setSecondPartyName(e?.target?.value);
                        setIsValidSecondParty(e?.target?.value.trim() !== "");
                      }}
                    />
                    {!isValidSecondParty && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please enter a second party name.
                      </p>
                    )}
                    <hr />
                    <div className="input_label">Stamp Duty Paid By </div>
                    <div className="row">
                      <div className="col-6">
                        {" "}
                        <div className="input_label">First Name</div>
                        <input
                          type="text"
                          className="form-control mb-3"
                          id="firstName"
                          value={firstName}
                          onChange={handleFirstNameChange}
                        />
                        {!isValidFirstName && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            Please enter a valid first name.
                          </p>
                        )}
                      </div>
                      <div className="col-6">
                        <div className="input_label">Last Name</div>
                        <input
                          type="text"
                          id="lastName"
                          className="form-control mb-3"
                          value={lastName}
                          onChange={handleLastNameChange}
                        />
                        {!isValidLastName && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            Please enter a valid last name.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input_label">Email Address</div>

                    <input
                      type="email"
                      id="email"
                      disabled
                      className="form-control"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {!isValidEmail && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please enter a valid email address.
                      </p>
                    )}

                    <hr />

                    <div className="input_label mt-3">Stamp Duty Amount</div>
                    <input
                      className="form-control"
                      type="number"
                      value={paidValue}
                      onChange={handleValidAmountChange}
                    />
                    {!isValidNumber && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please enter a amount.
                      </p>
                    )}

                    <div className="input_label mt-3">Location</div>
                    <Select
                      options={stampLocation}
                      defaultValue={stampLocation[0]}
                      isDisabled={true}
                      className={"select-wrapper basic-multi-select "}
                      // onChange={(e: any) => setStampLocation(e?.value)}
                    />

                    <Modal.Footer className="footer-bottom-0">
                      <div className=" d-flex justify-content-end align-items-center pt-4">
                        <Button
                          className="cancelbtn me-3"
                          size="sm"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="sharebtndanger"
                          size="sm"
                          onClick={() => {
                            onStampClick();
                          }}
                          disabled={!checkConditions()}
                        >
                          Stamp it
                          {loader ? <span className="btn_loader"></span> : ""}
                        </Button>
                      </div>
                    </Modal.Footer>
                  </form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
