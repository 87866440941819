import { useEffect, useRef, useState } from "react";
import { FaParagraph } from "react-icons/fa";
import {
  MdCode,
  MdFindReplace,
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatColorFill,
  MdFormatColorText,
  MdFormatIndentDecrease,
  MdFormatIndentIncrease,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
  MdLink,
  // MdLooksOne,
  MdOutlineFormatBold,
  MdOutlineLinkOff,
  MdStrikethroughS,
  MdSubscript,
  MdSuperscript,
  MdTableChart,
} from "react-icons/md";
import {
  TbColumnInsertLeft,
  TbColumnInsertRight,
  TbDeviceTabletUp,
  TbTableDown,
  TbTableExport,
  TbTableMinus,
  TbTableOff,
} from "react-icons/tb";
import { RiTable2 } from "react-icons/ri";
import {
  HiOutlineMagnifyingGlassMinus,
  HiOutlineMagnifyingGlassPlus,
} from "react-icons/hi2";
import { EditorContextInterface, useEditor } from "../EditorProvider";
import LinkToast from "../EditorToasts/LinkToast";
import { InsertImageButton } from "../Image";
import { isLinkActive } from "../Link";
import {
  BlockButton,
  DropdownButton,
  HistoryButton,
  IndentButton,
  LinkButton,
  MarkButton,
  renderTooltip,
} from "./ToolbarButton";
import { EditorDropdown } from "./ToolbarDropdown";
import { Toolbar } from "./components";

import SearchBar from "../EditorToasts/SearchBar";
import Addcolor from "../Editable/AddColor";
import { zoomIn, zoomOut } from "../Editable/Editor";
import { activeMark } from "./ToolbarMark";
import { BiMinus, BiPlus } from "react-icons/bi";
import { Editor } from "slate";
import { ReactEditor } from "slate-react";
import { CirclePicker } from "react-color";
import { OverlayTrigger } from "react-bootstrap";
import { AiOutlineDeleteColumn } from "react-icons/ai";

const { Button } = require("antd");
const Toolbars = () => {
  const context: EditorContextInterface | null = useEditor();
  const [linkShow, setLinkShow] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showPickerBg, setShowPickerBg] = useState(false);
  const [color] = useState("#000000");

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "f") {
        event.preventDefault();
        context?.showFindReplacePopup(!context?.findReplacePopup);
      }
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "+"
      ) {
        event.preventDefault();
        zoomIn();
      }
      if ((event.metaKey || event.ctrlKey) && event.key === "-") {
        event.preventDefault();
        zoomOut();
      }
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "<"
      ) {
        event.preventDefault();
        decreaseFontSize();
      }
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === ">"
      ) {
        event.preventDefault();
        increaseFontSize();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const editor = context?.editor;

  function decreaseFontSize() {
    ReactEditor.focus(context?.editor);
    let value: any = activeMark(editor, "fontSize");
    if (parseInt(value) >= 1) {
      let incr = parseInt(value) - 1;
      Editor.addMark(editor, "fontSize", incr.toString());
    }
  }

  function increaseFontSize() {
    ReactEditor.focus(context?.editor);
    let value: any = activeMark(editor, "fontSize");
    if (parseInt(value) <= 99) {
      let decr = parseInt(value) + 1;
      Editor.addMark(editor, "fontSize", decr.toString());
    }
  }

  function changeFontSize(e: any) {
    let value = e.target.value;
    Editor.addMark(editor, "fontSize", value.toString());
  }

  const chnageColor = (color: any) => {
    Editor.addMark(editor, "color", color);
  };

  const chnageHighlight = (color: any) => {
    Editor.addMark(editor, "bgColor", color);
  };

  const removeMark = (format: string) => {
    Editor.removeMark(editor, format);
    setShowPicker(false);
    setShowPickerBg(false);
  };

  const colorPickerRef: any = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowPicker(false);
        setShowPickerBg(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPickerRef]);

  return (
    <div className="rounded-5 mx-2">
      <Toolbar className="editorTabs p-0 my-2 mx-2">
        {context?.docContents?.status === "DRAFT" && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip("Modes")}
          >
            <DropdownButton
              format="Mode"
              options={["Editing", "Suggesting", "Viewing"]}
              context={context}
            />
          </OverlayTrigger>
        )}

        {/* <Button
          key={"insertTable"}
          style={{
            //need to think about how to handle getActiveStyles
            backgroundColor: 0 ? "#e8f0fe" : "#fefefe",
            margin: 3,
          }}
          onMouseDown={(e: any) => {
            e.preventDefault();
            editor.insertTable({ row: 3, col: 3 });
          }}
        >
          <strong>insert Table</strong>
        </Button> */}

        <HistoryButton format="undo" />
        <HistoryButton format="redo" />
        <EditorDropdown
          key={"3"}
          editor={context?.editor}
          {...{
            id: 1,
            format: "fontFamily",
            type: "dropdown",
            title: "Font Family",
            className: "border-end ps-3",
            options: [
              // {
              //   text: "American Typewriter",
              //   value: "american_typewriter",
              // },
              // { text: "Baskerville", value: "baskerville" },
              // { text: "Bradley Hand", value: "bradley_hand" },
              // { text: "Gill sans", value: "gill_sans" },
              // { text: "Helvetica", value: "helvetica" },
              // { text: "Impact", value: "impact" },
              // { text: "Inter", value: "inter" },
              // { text: "Montserrat", value: "montserrat" },
              { text: "Verdana", value: "verdana" },
              { text: "Open Sans", value: "Open_Sans" },
              { text: "Arial", value: "Arimo" },
              { text: "San serif", value: "San_serif" },
              { text: "Calibri", value: "Calibri" },
              { text: "Georgia", value: "Georgia" },
              { text: "Times New Roman", value: "TimesNewRoman" },
              { text: "Pacifico", value: "Pacifico" },
              { text: "Times", value: "Times" },
            ],
          }}
        />

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Headings")}
        >
          <DropdownButton
            format="heading"
            options={[
              "One",
              "Two",
              "Three",
              "Four",
              "Five",
              "Six",
              "Paragraph",
            ]}
          />
        </OverlayTrigger>

        <EditorDropdown
          key={"14"}
          editor={context?.editor}
          {...{
            id: 2,
            format: "lineHeight",
            type: "dropdown",
            title: "Line Height",
            className: "border-end ps-3 pe-2",
            options: [
              { text: "1.0", value: "13" },
              { text: "1.5", value: "15" },
              { text: "2.0", value: "20" },
              { text: "2.5", value: "25" },
              { text: "3.0", value: "30" },
              { text: "3.5", value: "35" },
              { text: "4.0", value: "40" },
              { text: "4.5", value: "45" },
            ],
          }}
        />
        <EditorDropdown
          key={"15"}
          editor={context?.editor}
          {...{
            id: 2,
            format: "letterSpacing",
            type: "dropdown",
            title: "Line Height",
            className: "border-end ps-3 pe-2",
            options: [
              { text: "Normal", value: "0" },
              { text: "1.5", value: "15" },
              { text: "2.0", value: "20" },
              { text: "2.5", value: "25" },
              { text: "3.0", value: "30" },
              { text: "3.5", value: "35" },
              { text: "4.0", value: "40" },
              { text: "4.5", value: "45" },
            ],
          }}
        />
        <div
          className="  border-end px-2  d-flex align-items-center "
          // style={{ backgroundColor: "black" }}
        >
          <div
            style={{
              width: "1px",
              position: "relative",
              left: "45px",
              top: "4px",
              cursor: "pointer",
            }}
            className="fontSizeHover"
          >
            <EditorDropdown
              key={"4"}
              editor={context?.editor}
              {...{
                id: 2,
                format: "fontSize",
                type: "dropdown",
                title: "Font Size",
                className: "border-end ps-1 pe-1 ",
                options: [
                  { text: "8", value: "8" },
                  { text: "9", value: "9" },
                  { text: "10", value: "10" },
                  { text: "11", value: "11" },
                  { text: "12", value: "12" },
                  { text: "14", value: "14" },
                  { text: "18", value: "18" },
                  { text: "24", value: "24" },
                  { text: "30", value: "30" },
                  { text: "36", value: "36" },
                  { text: "48", value: "48" },
                  { text: "60", value: "60" },
                  { text: "72", value: "72" },
                  { text: "96", value: "96" },
                ],
              }}
            />
          </div>
          <div className=" cursor-pointer">
            <BiMinus
              color="#333f52"
              fontSize={20}
              onClick={() => decreaseFontSize()}
            />
          </div>
          <div
            className="px-2  mx-2 rounded-1 cursor-pointer inputToolbarApperence"
            style={{ border: "1px solid #516173" }}
          >
            <input
              type="number"
              id="fontSize"
              name="fontSize"
              min="1"
              max="200"
              value={activeMark(editor, "fontSize")}
              className=" bg-transparent border-0  m-0"
              onChange={(e: any) => changeFontSize(e)}
            ></input>
          </div>
          <div className=" cursor-pointer">
            <BiPlus
              color="#333f52"
              fontSize={20}
              onClick={() => increaseFontSize()}
            />
          </div>
        </div>
        <MarkButton format="bold" icon={<MdOutlineFormatBold size={24} />} />
        <MarkButton format="italic" icon={<MdFormatItalic size={24} />} />
        <MarkButton
          format="underline"
          icon={<MdFormatUnderlined size={24} />}
        />
        <MarkButton
          format="strikeThrough"
          icon={<MdStrikethroughS size={24} />}
        />

        <MarkButton format="subscript" icon={<MdSubscript />} />
        <MarkButton format="superscript" icon={<MdSuperscript />} />
        <MarkButton format="code" icon={<MdCode size={24} />} />
        {/* <BlockButton format="heading-one" icon={<MdLooksOne />} /> */}
        <BlockButton format="paragraph" icon={<FaParagraph />} />
        <BlockButton format="block-quote" icon={<MdFormatQuote />} />
        <BlockButton format="numbered-list" icon={<MdFormatListNumbered />} />
        <BlockButton format="bulleted-list" icon={<MdFormatListBulleted />} />
        <IndentButton format="indentLeft" icon={<MdFormatIndentDecrease />} />
        <IndentButton format="indentRight" icon={<MdFormatIndentIncrease />} />
        <BlockButton format="left" icon={<MdFormatAlignLeft />} />
        <BlockButton format="center" icon={<MdFormatAlignCenter />} />
        <BlockButton format="right" icon={<MdFormatAlignRight />} />
        <BlockButton format="justify" icon={<MdFormatAlignJustify />} />

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Insert Table")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.insertTable({ row: 3, col: 3 });
            }}
            className=" cursor-pointer px-3"
          >
            <MdTableChart size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Delete Table")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.removeTable();
            }}
            className=" cursor-pointer px-1"
          >
            <TbTableOff size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Merge Table")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.mergeTable();
            }}
            className=" cursor-pointer px-1"
          >
            <RiTable2 size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Split Table")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.splitTable();
            }}
            className=" cursor-pointer px-1"
          >
            <TbTableExport size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Insert row above")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.insertRow({ before: true });
            }}
            className=" cursor-pointer px-1"
          >
            <TbDeviceTabletUp size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Insert row below")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.insertRow();
            }}
            className=" cursor-pointer px-1"
          >
            <TbTableDown size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Delete row")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.removeRow();
            }}
            className=" cursor-pointer px-1"
          >
            <TbTableMinus size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Insert column left")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.insertColumn({ before: true });
            }}
            className=" cursor-pointer px-1"
          >
            <TbColumnInsertLeft size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Insert column Right")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.insertColumn();
            }}
            className=" cursor-pointer px-1"
          >
            <TbColumnInsertRight size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Delete column")}
        >
          <div
            onMouseDown={(e: any) => {
              e.preventDefault();
              editor.removeColumn();
            }}
            className=" cursor-pointer px-1"
          >
            <AiOutlineDeleteColumn size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Font Color")}
        >
          <div
            onClick={() => setShowPicker(!showPicker)}
            className=" cursor-pointer px-3"
          >
            <MdFormatColorText size={20} />
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Highlight Color")}
        >
          <div
            onClick={() => setShowPickerBg(!showPickerBg)}
            className=" cursor-pointer px-3"
          >
            <MdFormatColorFill size={20} />
          </div>
        </OverlayTrigger>
        <InsertImageButton />
        <LinkButton
          format="link"
          icon={<MdLink />}
          className="border-end pe-3 br-0"
          setLinkShow={setLinkShow}
        />
        {isLinkActive(context?.editor) && (
          <LinkButton format="remove-link" icon={<MdOutlineLinkOff />} />
        )}

        <div className="toolbar"></div>
        <Button
          icon={<MdFindReplace />}
          title="Find and Replace"
          onClick={() =>
            context?.showFindReplacePopup(!context?.findReplacePopup)
          }
        />
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Zoom In")}
        >
          <Button variant="link" className="text-dark" onClick={zoomIn}>
            <HiOutlineMagnifyingGlassPlus />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip("Zoom Out")}
        >
          <Button variant="link" className="text-dark" onClick={zoomOut}>
            <HiOutlineMagnifyingGlassMinus />
          </Button>
        </OverlayTrigger>
      </Toolbar>
      <LinkToast setShow={setLinkShow} show={linkShow} />
      <Addcolor />
      <SearchBar
        setShow={context?.showFindReplacePopup}
        show={context?.findReplacePopup}
      />

      {showPicker && (
        <div
          className="p-4"
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "white",
            right: "250px",
          }}
          ref={colorPickerRef}
          id="colorPicker"
        >
          <CirclePicker
            color={color}
            onChange={(color: any) => chnageColor(color.hex)}
          />{" "}
          <div>
            <div className="d-flex justify-content-between mt-3">
              <input
                type="color"
                id="color1"
                name="color1"
                value="#ff0000"
                onChange={(color) => chnageColor(color.target.value)}
              ></input>
              <button
                onClick={() => removeMark("color")}
                style={{ cursor: "pointer" }}
              >
                Remove Mark
              </button>
            </div>
          </div>
        </div>
      )}

      {showPickerBg && (
        <div
          className="p-4"
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "white",
            right: "260px",
          }}
          ref={colorPickerRef}
          id="colorPicker"
        >
          <CirclePicker
            color={color}
            onChange={(color: any) => chnageHighlight(color.hex)}
          />{" "}
          <div className="d-flex justify-content-between mt-3">
            <input
              type="color"
              id="color"
              name="color"
              value="#ff0000"
              onChange={(color) => chnageHighlight(color.target.value)}
            ></input>
            <button
              onClick={() => removeMark("bgColor")}
              style={{ cursor: "pointer" }}
            >
              Remove Mark
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbars;
