import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { api } from "../../utils/api";
// import { ITemplateFilterParams } from "../../interfaces/ITemplateFilterParams";

interface ListAnalyticsContextInterface {
  handleOverallStats: Function;
  handleContractType: Function;
  handleCountSignedContractByType: Function;
  handleContractByContractType: Function;
  handlegetDaysInNegotiation: Function;
  handleContractCycleTime: Function;
  handleSignedContractTurnaround: Function;
  handleSignedCountContractByTeams: Function;
  handleCountRequiringApproval: Function;
  handleContractInitiatedByEachTeam: Function;
  handlegetContractsByCounterparty: Function;
  handleTurnaroundTimeForApproval: Function;
  handleAvgExpiryType: Function;
  setSummaryGraphName: Function;
  summaryGraphNames: [];
  setTurnaroundTimeGraphName: Function;
  turnaroundTimeGraphName: [];
  setTeamsGraphName: Function;
  teamsGraphName: [];
  setContractValueGraphName: Function;
  contractValueGraphName: [];
  handleAddMatrics: Function;
  getMatrics: Function;
  updatedMatricsList: [];
  updateMatricsData: Function;
  getContractDeatils: Function;
  handleGetCategory: Function;
  handleGetStatus: Function;
  setFilterParams: Function;
  filterParams: any;
  handleCounterpartyList: Function;
  handleDeleteMatrics: Function;
}

const AnalyticsContext =
  React.createContext<ListAnalyticsContextInterface | null>(null);

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const [updatedMatricsList, setUpdatedMatricsList]: [any, Function] =
    useState();
  const [filterParams, setFilterParams]: [any, Function] = useState();

  const [summaryGraphNames, setSummaryGraphName]: [any, Function] = useState([
    "Contract Type",
    "Signed Contracts",
    "Top 10 Contracts by Contract Value",
    "Average Contract Expiry Based on Each Type of Contract",
  ]);
  const [turnaroundTimeGraphName, setTurnaroundTimeGraphName]: [any, Function] =
    useState([
      "Days in Negotiation",
      "Contract Cycle Time",
      "Turnaround Time For Approvals",
      "Turnaround Time for Signed Contracts",
    ]);
  const [teamsGraphName, setTeamsGraphName]: [any, Function] = useState([
    "Contracts Signed By Each Team",
    "Number of contracts requiring approval",
    "Number of contracts initiated by each team",
  ]);
  const [contractValueGraphName, setContractValueGraphName]: [any, Function] =
    useState(["Top 10 counterparties by contract amount"]);
  const handleOverallStats = async (params: any) => {
    try {
      let response = await api.getOverallStats(params);
      return response;
    } catch (error) {}
  };

  const handleContractType = async (params: any) => {
    try {
      let response = await api.getContractType(params);
      return response;
    } catch (error) {}
  };

  const handleCountSignedContractByType = async (params: any) => {
    try {
      let response = await api.getCOuntSignedContractByType(params);
      return response;
    } catch (error) {}
  };

  const handleContractByContractType = async (params: any) => {
    try {
      let response = await api.getContractByContractValue(params);
      return response;
    } catch (error) {}
  };

  const handleContractCycleTime = async () => {
    try {
      let response = await api.getContractCycleTime();
      return response;
    } catch (error) {}
  };

  const handlegetDaysInNegotiation = async (params: any) => {
    try {
      let response = await api.getDaysInNegotiation(params);
      return response;
    } catch (error) {}
  };
  const handleSignedContractTurnaround = async (params: any) => {
    try {
      let response = await api.getSignContractTurnaround(params);
      return response;
    } catch (error) {}
  };

  const handleSignedCountContractByTeams = async (params: any) => {
    try {
      let response = await api.getSignedCountContractByTeams(params);
      return response;
    } catch (error) {}
  };

  const handleCountRequiringApproval = async (params: any) => {
    try {
      let response = await api.getcountRequiringApproval(params);
      return response;
    } catch (error) {}
  };
  const handleContractInitiatedByEachTeam = async (params: any) => {
    try {
      let response = await api.getContractInitiatedByEachTeam(params);
      return response;
    } catch (error) {}
  };

  const handlegetContractsByCounterparty = async (params: any) => {
    try {
      let response = await api.getContractsByCounterparty(params);
      return response;
    } catch (error) {}
  };

  const handleTurnaroundTimeForApproval = async (params: any) => {
    try {
      let response = await api.getTurnaroundTimeForApproval(params);
      return response;
    } catch (error) {}
  };

  const handleAvgExpiryType = async (params: any) => {
    try {
      let response = await api.getAvgExpiryType(params);
      return response;
    } catch (error) {}
  };

  const handleAddMatrics = async (params: any) => {
    try {
      let response = await api.addMatrics(params);
      return response;
    } catch (error) {}
  };

  const getMatrics = async () => {
    try {
      let response = await api.getMatrics();

      if (response?.success) {
        setUpdatedMatricsList(response?.metrics);
      }
      return response;
    } catch (error) {}
  };

  const updateMatricsData = async (params: any) => {
    try {
      let response = await api.updateMatrics(params);

      return response;
    } catch (error) {}
  };

  const getContractDeatils = async (filterParams: any) => {
    const res = await api.getContract(filterParams);
    return res;
  };

  const handleGetCategory = async (search: string) => {
    try {
      let response = await api.getCategoriesAutoComplete({
        search: search ? search : "",
      });
      return response;
    } catch (error) {}
  };

  const handleGetStatus = async () => {
    try {
      let response = await api.getStatus();

      return response;
    } catch (error) {}
  };

  const handleCounterpartyList = async (params: any) => {
    try {
      let response = await api.getCounterpartyList(params);
      return response;
    } catch (error) {}
  };

  const handleDeleteMatrics = async (params: any) => {
    try {
      let response = await api.deleteMetrics(params);
      return response;
    } catch (error) {}
  };

  useEffect(() => {
    getMatrics();
  }, []);

  const value: ListAnalyticsContextInterface = useMemo(
    () => ({
      handleOverallStats,
      handleContractType,
      handleCountSignedContractByType,
      handleContractByContractType,
      handlegetDaysInNegotiation,
      handleContractCycleTime,
      handleSignedContractTurnaround,
      handleSignedCountContractByTeams,
      handleCountRequiringApproval,
      handleContractInitiatedByEachTeam,
      handlegetContractsByCounterparty,
      handleTurnaroundTimeForApproval,
      handleAvgExpiryType,
      setSummaryGraphName,
      summaryGraphNames,
      turnaroundTimeGraphName,
      setTurnaroundTimeGraphName,
      setTeamsGraphName,
      teamsGraphName,
      contractValueGraphName,
      setContractValueGraphName,
      handleAddMatrics,
      getMatrics,
      updateMatricsData,
      getContractDeatils,
      updatedMatricsList,
      handleGetCategory,
      handleGetStatus,
      setFilterParams,
      filterParams,
      handleCounterpartyList,
      handleDeleteMatrics,
    }),

    []
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = () => {
  return React.useContext(AnalyticsContext);
};

export { AnalyticsProvider, useAnalytics, type ListAnalyticsContextInterface };
