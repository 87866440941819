import { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { isMobileOnly, isTablet } from "react-device-detect";
import { AiOutlineSearch } from "react-icons/ai";
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineArrowDropDown,
  MdOutlineFileUpload,
} from "react-icons/md";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import DocumentFilter from "../../../ui/DocumentFilter";
import DocumentSortBy from "../../../ui/DocumentSortBy";
import FailureToast from "../../../ui/FailureToast";
import SuccessToast from "../../../ui/SuccessToast";
import { routes } from "../../../utils/routes";
import MobileTemplateFooter from "../../Templates/components/MobileTemplateFooter";
import {
  ListContractContextInterface,
  useContract,
} from "../ListContractProvider";
import CreateContractPopup from "./CreateContractPopup";
import FileUpload from "./FileUpload";
import { useAuthentication } from "../../Authentication/AuthenticationProvider";

const initialValue = { name: "Untitled Contract", description: "" };

function ContractHeader({ setMoveToFolders }: { setMoveToFolders: Function }) {
  const navigate = useNavigate();

  const context: ListContractContextInterface | null = useContract();
  const [loader, setLoader]: [boolean, Function] = useState(false);
  const [showCreateTemplate, setShowCreateTemplate]: [boolean, Function] =
    useState(false);

  const [bodyMessage, setBodyMessage]: [string, Function] = useState("");
  const [showSuccessToast, setShowSuccessToast]: [boolean, Function] =
    useState(false);
  const [showFailureToast, setShowFailureToast]: [boolean, Function] =
    useState(false);
  const [uploadFile, setUploadFile]: [boolean, Function] = useState(false);

  const handleCreateContracts = async () => {
    try {
      setLoader(true);
      let response = await context?.createDocuments(initialValue);
      if (response) {
        setBodyMessage("Contract created successfully! ");
        setShowSuccessToast(true);
        navigate(
          generatePath(routes.editContract, {
            id: response?.id,
          })
        );
      } else {
        setBodyMessage("Contract not created!");
        setShowFailureToast(true);
      }
    } catch (error) {}
  };

  const UploadContract = async (file: any) => {
    const formData = new FormData();
    setUploadFile(true);
    formData.set("file", file);
    try {
      let response = await context?.handleUploadContract(formData);

      if (response?.status === 201) {
        setUploadFile(false);
        setBodyMessage("Contract uploaded successfully! ");
        setShowSuccessToast(true);

        navigate(
          generatePath(routes.editContract, {
            id: response?.data?._id,
          })
        );
      } else {
        setUploadFile(false);
        setBodyMessage(response?.data?.error[0]);
        setShowFailureToast(true);
      }
    } catch (error) {}
  };

  if (!context) {
    return <></>;
  }

  return (
    <>
      {isTablet && (
        <TabletContractHeader
          // onSearchClick={onSearchClick}
          context={context}
          // setSearchText={setSearchText}
          // searchText={searchText}
          setShowCreateTemplate={setShowCreateTemplate}
          handleCreateContracts={handleCreateContracts}
          UploadContract={UploadContract}
        />
      )}
      {isMobileOnly && (
        <MobileContractHeader
          // onSearchClick={onSearchClick}
          // setSearchText={setSearchText}
          context={context}
          // searchText={searchText}
          showCreateTemplate={showCreateTemplate}
          setShowCreateTemplate={setShowCreateTemplate}
          handleCreateContracts={handleCreateContracts}
          UploadContract={UploadContract}
        />
      )}
      {!isTablet && !isMobileOnly && (
        <DesktopContractHeader
          // onSearchClick={onSearchClick}
          context={context}
          // setSearchText={setSearchText}
          // searchText={searchText}
          handleCreateContracts={handleCreateContracts}
          loader={loader}
          setShowCreateTemplate={setShowCreateTemplate}
          setMoveToFolders={setMoveToFolders}
          UploadContract={UploadContract}
        />
      )}

      {showCreateTemplate && (
        <CreateContractPopup
          show={showCreateTemplate}
          onHide={() => setShowCreateTemplate(false)}
        />
      )}
      {showSuccessToast && (
        <SuccessToast
          icon={
            <MdCheckCircle
              className="text-success"
              style={{ fontSize: "64px" }}
            />
          }
          message={bodyMessage}
          title={"Success!"}
          show={showSuccessToast}
          close={() => {
            setShowSuccessToast(false);
          }}
        />
      )}
      {showFailureToast && (
        <FailureToast
          message={bodyMessage}
          title={"Failure!"}
          show={showFailureToast}
          close={() => {
            setShowFailureToast(false);
          }}
          icon={
            <MdCancel className="text-danger" style={{ fontSize: "64px" }} />
          }
        />
      )}
      {uploadFile && (
        <FileUpload show={uploadFile} onHide={() => setUploadFile(false)} />
      )}
    </>
  );
}

export default ContractHeader;

const TabletContractHeader = ({
  context,
  // setSearchText,
  // searchText,
  handleCreateContracts,
  setShowCreateTemplate,
  // onSearchClick,
  UploadContract,
}: {
  context: ListContractContextInterface;
  // setSearchText: Function;
  // searchText: string;
  handleCreateContracts: Function;
  setShowCreateTemplate: Function;
  // onSearchClick: Function;
  UploadContract: Function;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();

  const onSearchSubmit = async (searchText: any) => {
    // if (e.keyCode === 13) {
    navigate(routes?.contractFiltersUrl({ search: searchText }));
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
    // }
  };
  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.CONTRACTS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="p-2 ">
          <DocumentSortBy context={context} enumType={"ContractSortEnum"} />
        </div>
        <div className="pt-2">
          <DocumentFilter
            params={context.filterParams}
            changeFilter={context.changeFilter}
            resetFilter={context.resetFilter}
            categoriesAutocomplete={context.categoriesAutocomplete}
            defaultCategories={context.defaultCategories}
            context={context}
            status={true}
          />
        </div>
        {roles === "CREATE" && (
          <>
            {!location.pathname.includes("folder") && (
              <>
                <div className="p-2">
                  <div className="input-file-wrap ms-3 d-flex align-items-center justify-content-center">
                    <span>
                      Upload Contract
                      <span className=" ps-2">
                        <MdOutlineFileUpload fontSize={18} />
                      </span>
                    </span>
                    <input
                      type="file"
                      className=" uploadContractFile position-absolute"
                      onChange={(e) => UploadContractFile(e)}
                    />
                  </div>
                </div>
                <div className="p-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between"
                      id="dropdown-basic"
                    >
                      New Contract
                      <span className="ps-2">
                        <MdOutlineArrowDropDown fontSize={18} />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="create-dropdown-css">
                      <Dropdown.Item
                        onClick={() => handleCreateContracts()}
                        className="sort-by"
                      >
                        New Contract
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowCreateTemplate(true)}
                        className="sort-by"
                      >
                        View Templates
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              // onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Contract"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
              // onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
    </>
  );
};

const MobileContractHeader = ({
  // setSearchText,
  context,
  // searchText,
  handleCreateContracts,
  setShowCreateTemplate,
  showCreateTemplate,
  // onSearchClick,
  UploadContract,
}: {
  // setSearchText: Function;
  context: ListContractContextInterface;
  // searchText: string;
  handleCreateContracts: Function;
  setShowCreateTemplate: Function;
  showCreateTemplate?: boolean;
  // onSearchClick: Function;
  UploadContract: Function;
}) => {
  const navigate = useNavigate();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();
  const onSearchSubmit = async (searchText: any) => {
    navigate(routes?.contractFiltersUrl({ search: searchText }));
    // if (e.keyCode === 13) {
    await context?.changeFilter({
      ...context.filterParams,
      search: searchText,
    });
    // }
  };

  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  useEffect(() => {
    let role: any = authContext?.currentUser?.roles?.[0];
    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.CONTRACTS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      {/* {location.pathname.includes("folder") && ( */}
      <div className="d-flex justify-content-between px-2 py-3">
        {roles === "CREATE" && (
          <>
            <div>
              <div className="input-file-wrap ms-3 d-flex align-items-center justify-content-center">
                <span>
                  Upload Contract
                  <span className=" ps-2">
                    <MdOutlineFileUpload fontSize={18} />
                  </span>
                </span>
                <input
                  type="file"
                  className=" uploadContractFile position-absolute"
                  onChange={(e) => UploadContractFile(e)}
                />
              </div>
            </div>
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between"
                  id="dropdown-basic"
                >
                  New Contract
                  <span className="ps-2">
                    <MdOutlineArrowDropDown fontSize={18} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="create-dropdown-css">
                  <Dropdown.Item
                    onClick={() => handleCreateContracts()}
                    className="sort-by"
                  >
                    New Contract
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShowCreateTemplate(true)}
                    className="sort-by"
                  >
                    View Templates{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </div>
      {/* )} */}

      <div className="d-flex">
        <div className="p-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch
              className="search-icon-css"
              // onClick={() => onSearchClick()}
            />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search Contract"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
              // onKeyDown={onSearchSubmit}
            />
          </InputGroup>
        </div>
      </div>
      {!showCreateTemplate && (
        <MobileTemplateFooter enumType={"ContractSortEnum"} context={context} />
      )}
    </>
  );
};

const DesktopContractHeader = ({
  context,
  handleCreateContracts,
  setShowCreateTemplate,
  setMoveToFolders,
  UploadContract,
}: {
  context: ListContractContextInterface;
  handleCreateContracts: Function;
  loader: boolean;
  setShowCreateTemplate: Function;
  setMoveToFolders: Function;
  UploadContract: Function;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthentication();
  const [roles, setRoles]: [any, Function] = useState();
  const onSearchSubmit = async (searchText: any) => {
    if (searchText?.length >= 2) {
      navigate(routes?.contractFiltersUrl({ search: searchText }));
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    } else if (searchText?.length === 0) {
      navigate(routes?.contractFiltersUrl({ search: "" }));
      await context?.changeFilter({
        ...context.filterParams,
        search: searchText,
      });
    }
  };

  const UploadContractFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (e.target.files[0]) {
      UploadContract(selectedFile);
    }
  };

  useEffect(() => {
    let role: any =
      authContext?.currentUser?.roles?.[0] === "Admin"
        ? "ADMIN"
        : authContext?.currentUser?.roles?.[0];

    if (authContext?.rolesAndAccess !== undefined) {
      const guestRole =
        authContext?.rolesAndAccess[
          role === "AUTHORIZED SIGNATORIES" ? role?.replace(" ", "_") : role
        ];

      setRoles(guestRole?.CONTRACTS?.find((el: any) => el === "CREATE"));
    }
  }, [authContext?.rolesAndAccess]);

  return (
    <>
      <div className="d-flex ">
        <div className="pt-2 flex-fill">
          <InputGroup className="br-4 search-css focus-input">
            <AiOutlineSearch className="search-icon-css" />
            <Form.Control
              id="doc_searchQueryInput1"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              className="ps-3 fs-14 search-input"
              onChange={(e) => {
                onSearchSubmit(e?.target?.value);
              }}
            />
          </InputGroup>
        </div>
        <div className="pt-2">
          <DocumentSortBy context={context} enumType={"ContractSortEnum"} />
        </div>
        <div className="pt-2">
          <DocumentFilter
            params={context.filterParams}
            changeFilter={context.changeFilter}
            resetFilter={context.resetFilter}
            categoriesAutocomplete={context.categoriesAutocomplete}
            defaultCategories={context.defaultCategories}
            context={context}
            status={true}
          />
        </div>
        {roles === "CREATE" && (
          <>
            {!location.pathname.includes("folder") && (
              <>
                <div className="pt-2">
                  <div className="input-file-wrap ms-3 d-flex align-items-center justify-content-center">
                    <span>
                      {" "}
                      Upload Contract{" "}
                      <span className=" ps-2 ">
                        <MdOutlineFileUpload fontSize={18} />
                      </span>
                    </span>
                    <input
                      type="file"
                      accept=".docx,.pdf"
                      className=" uploadContractFile position-absolute"
                      onChange={(e) => UploadContractFile(e)}
                    />
                  </div>
                </div>
                <div className="pt-2 bd-highlight">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      className="new-contact-btn create-template contract-css ms-lg-3 d-flex  align-items-center ps-3 justify-content-between"
                      id="dropdown-basic"
                    >
                      New Contract{" "}
                      <span className="ps-2">
                        <MdOutlineArrowDropDown fontSize={18} />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="create-dropdown-css">
                      <Dropdown.Item
                        onClick={() => handleCreateContracts()}
                        className="sort-by"
                      >
                        New Contract
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowCreateTemplate(true)}
                        className="sort-by"
                      >
                        View Templates
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            )}
          </>
        )}

        {location.pathname.includes("folder") && (
          <>
            <div className="pt-2 bd-highlight">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="new-contact-btn create-template contract-css ms-lg-3"
                  id="dropdown-basic"
                  onClick={() => setMoveToFolders(true)}
                >
                  Move to folders&nbsp;
                </Dropdown.Toggle>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </>
  );
};
